/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useGetAllIssueMutation, useGetIssueByIdMutation, useGetIssueJsonQuery } from '../../../services/services';
import ReadMoreLess from '../../../components/common/ReadMoreLess';
import InnerLoader from '../../../components/InnerLoader';
import PaginationComponenet from '../../../components/common/Pagination';
import useSearchFilter from '../../../hooks/useSearchFilter';
import {
  addBusinessCapabilitiesData,
  addCapabilitiesData,
  addCustomerCapabilitiesData,
  addIssueCapabilitiesReadOnly,
  addIssueResPeople, addIssueVals, addProcessCapabilitiesData, addProductCapabilitiesData, addVendorCapabilitiesData, setCorrectionList, setImpactList,
} from '../../../services/issueSlice';
import { addDocsInfo } from '../../../services/docInfoSlice';
import { truncateName } from '../../../utils/form/form';

const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);
function IssueListComp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('issue');
  const [reqIssue, resIssue] = useGetAllIssueMutation();
  const [reqIssueById, resIssueById] = useGetIssueByIdMutation();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 5;
  const [totalCount, setTotalCount] = useState(0);
  const [TBLData, setTBLData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const header = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'description', name: 'Description' },
    { type: '', field: 'issue_type', name: 'Issue Type' },
    { type: '', field: 'status', name: 'Status' },
  ];

  console.log('issue list location', location);
  console.log('TBLData', TBLData);

  useEffect(() => {
    if (tableData && Array.isArray(tableData) && tableData?.length > 0) {
      setTotalCount(tableData.length);
    } else {
      setTotalCount(0);
    }
  }, [TBLData]);

  useEffect(() => {
    reqIssue({
      id: location?.state?.id,
      type: location?.pathname === '/readModeChange' ? 'Change' : location?.state?.label,
    });
  }, []);

  useEffect(() => {
    if (resIssue?.isSuccess) {
      console.log('ressssssssssssss', resIssue?.data);
      setTableData(resIssue?.data);
    }
  }, [resIssue?.isSuccess]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (tableData && Array.isArray(tableData) && tableData?.length > 5) {
      const reversedArray = [];
      tableData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (tableData && Array.isArray(tableData) && tableData?.length > 0) {
      const reversedArray = [];
      tableData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [tableData, currentPage]);

  const { filter, setFilter, filterArray } = useSearchFilter(tableData || []);
  useEffect(() => {
    if (tableData && Array.isArray(tableData) && tableData?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [tableData, filter, filterArray]);

  const handleNavigateToReadReview = (e, tData) => {
    console.log('tData', tData);
    e.preventDefault();
    reqIssueById({ id: tData?.id });
    console.log('tData', tData);
  };

  useEffect(() => {
    if (resIssueById?.isSuccess && resIssueById?.data) {
      console.log('resIssueById?.data', resIssueById?.data);
      dispatch(addIssueVals(resIssueById?.data));
      // if (resIssueById?.data?.documents && Array.isArray(resIssueById?.data?.documents) && resIssueById?.data?.documents?.length > 0) {
      //   dispatch(addDocsInfo(resIssueById?.data?.documents));
      // }
      if (resIssueById?.data?.responsible && Array.isArray(resIssueById?.data?.responsible) && resIssueById?.data?.responsible?.length > 0) {
        dispatch(addIssueResPeople(resIssueById?.data?.responsible));
      }
      if (resIssueById?.data?.impacts && Array.isArray(resIssueById?.data?.impacts) && resIssueById?.data?.impacts?.length > 0) {
        dispatch(setImpactList(resIssueById?.data?.impacts));
      }
      if (resIssueById?.data?.corrections && Array.isArray(resIssueById?.data?.corrections) && resIssueById?.data?.corrections?.length > 0) {
        dispatch(setCorrectionList(resIssueById?.data?.corrections));
      }
      if ((resIssueById?.data?.object_type === 'Change' || resIssueById?.data?.object_type === 'Epic') && resIssueById?.data?.dependencies && Array.isArray(resIssueById?.data?.dependencies) && resIssueById?.data?.dependencies?.length > 0) {
        dispatch(addIssueCapabilitiesReadOnly({
          processCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'process' || el?.key === 'Process'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          appCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'application' || el?.key === 'Application'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          businessCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'business' || el?.key === 'Business'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          productCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'product' || el?.key === 'Product'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          vendorCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'vendor' || el?.key === 'Vendor'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          customerCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'customer' || el?.key === 'Customer'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
        }));
      } else {
        dispatch(addIssueCapabilitiesReadOnly({
          processCaps: [],
          appCaps: [],
          businessCaps: [],
          productCaps: [],
          vendorCaps: [],
          customerCaps: [],
        }));
      }
      if ((resIssueById?.data?.object_type === 'Epic') && resIssueById?.data?.dependencies && Array.isArray(resIssueById?.data?.dependencies) && resIssueById?.data?.dependencies?.length > 0) {
        dispatch(addCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addBusinessCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProductCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
        dispatch(addVendorCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addCustomerCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
      } else {
        dispatch(addCapabilitiesData([]));
        dispatch(addProcessCapabilitiesData([]));
        dispatch(addBusinessCapabilitiesData([]));
        dispatch(addProductCapabilitiesData([]));
        dispatch(addVendorCapabilitiesData([]));
        dispatch(addCustomerCapabilitiesData([]));
      }
      navigate(
        '/issue-read-review',
        {
          state: {
            issueName: resIssueById?.data?.name,
            id: resIssueById?.data?.object_id,
            type: resIssueById?.data?.object_type,
            from: location?.pathname,
            state: location?.state,
            // type: location?.pathname === '/readModeChange' ? 'Change' : location?.state?.label,
          },
        },
      );
    }
  }, [resIssueById?.isSuccess]);

  return (
    <div className="container-fluid">
      <h2 className="mb-2 d-flex justify-content-between">
        Issue List
      </h2>
      <div className="table-responsive box-shadow">
        <table className="table table-hover">
          <thead>
            <tr className="heading-row">
              {header
                && header?.length > 0
                && Array.isArray(header)
                && header.map((ele) => <th key={ele?.name}>{ele.name}</th>)}
            </tr>
            <tr className="filter-row">
              {header
                && header?.length > 0
                && Array.isArray(header)
                && header.map((ele) => (
                  <th key={ele?.field}>
                    {ele?.type === '' && ele?.type !== 'action' && (
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        name={ele?.field}
                        disabled={
                          ele?.field !== 'name'
                          && ele?.field !== 'issue_type'
                          && ele?.field !== 'description'
                          && ele?.field !== 'status'
                          && ele?.field !== 'owners'
                          && ele?.field !== 'managers'
                        }
                        onChange={(e) => setFilter({
                          ...filter,
                          [e.target.name]: e.target.value,
                        })}
                      />
                    )}
                    {ele?.type === 'date' && (
                      <DatePicker
                        className="form-control"
                        placeholderText="DD/MM/YYYY"
                        portalId="root-portal"
                        disabled={
                          ele?.field !== 'name' && ele?.field !== 'status'
                        }
                      />
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {TBLData && Array.isArray(TBLData) && TBLData?.length > 0 ? (
              TBLData?.map((tdt, i) => (
                <tr key={i}>
                  {header
                    && header?.length > 0
                    && Array.isArray(header)
                    && header?.map((th) => (th?.field === 'owner' || th?.field === 'owners' ? (
                      <td key={th?.field} className="text-capitalize">
                        {tdt[th?.field]
                          && tdt[th?.field]?.length > 0
                          && Array.isArray(tdt[th?.field])
                          ? tdt[th?.field]?.map((ow) => (
                            <Fragment key={ow?.id}>
                              {ow
                                ? `${ow?.first_name || ''}  ${
                                  ow?.last_name || ''
                                }`
                                : '-'}
                            </Fragment>
                          ))
                          : '-'}
                      </td>
                    ) : th.field === 'manager'
                        || th.field === 'managers'
                        || th.field === 'assignees' ? (
                          <td key={th?.field} className="text-capitalize">
                            {tdt[th?.field]
                          && Array.isArray(tdt[th?.field])
                          && tdt[th?.field]?.length > 0
                              ? tdt[th.field]?.map((mn) => (
                                <Fragment key={mn?.id}>
                                  {mn
                                    ? `${mn?.first_name || ''}  ${
                                      mn?.last_name || ''
                                    }`
                                    : '-'}
                                </Fragment>
                              ))
                              : '-'}
                          </td>
                      ) : th.field === 'step_mappings' ? (
                        <td key={th?.field}>
                          {tdt[th?.field]
                          && Array.isArray(tdt[th?.field])
                          && tdt[th?.field]?.length > 0
                            ? tdt[th.field]?.length
                            : '-'}
                        </td>
                      ) : th?.field === 'name' ? (
                        <td key={th?.field}>
                          <Button
                            // href="#!"
                            // target="_blank"
                            onClick={(e) => handleNavigateToReadReview(e, tdt)}
                            variant="link"
                            style={{ textDecoration: 'none' }}
                          >
                            {truncateName(tdt[th?.field], 30) || '-'}
                          </Button>
                        </td>
                      ) : th?.type === 'textarea' && tdt[th?.field] ? (
                        <td key={th?.field}>
                          <ReadMoreLess text={tdt[th?.field]} />
                        </td>
                      ) : th?.field === 'description' && tdt[th?.field] ? (
                        <td key={th?.field}>
                          <div dangerouslySetInnerHTML={{ __html: tdt[th?.field] }} />
                        </td>
                      ) : th?.type === 'date' && tdt[th?.field] ? (
                        tdt[th?.field] && (
                          <td key={th?.field}>
                            {dayjs(tdt[th?.field]).format(
                              userData?.tenant_info?.date_format,
                            ) || '-'}
                          </td>
                        )
                      ) : th?.field === 'responsibility_id' ? (
                        <td className="text-capitalize" key={th?.field}>
                          {`${tdt[th?.field]?.first_name} ${
                            tdt[th?.field]?.last_name
                          }` || '-'}
                        </td>
                      ) : th?.type === 'action' ? (
                        <td>
                          <OverlayTrigger placement="bottom" overlay={renderTooltip('Insights')}>
                            <Link
                              className="insight-link"
                              to={{
                                pathname: `/review/program/${tdt?.id}`,
                                search: 'insights',
                              }}
                              target="_blank"
                            >
                              <span className="icon-zoomIn" />
                            </Link>
                          </OverlayTrigger>
                        </td>
                      ) : (
                        <td className="text-capitalize" key={th?.field}>
                          {tdt[th?.field] || '-'}
                        </td>
                      )))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={
                    header
                    && header?.length
                    && Array.isArray(header)
                    && header?.length
                  }
                  className="text-center"
                >
                  {(isLoading) ? <InnerLoader /> : 'No Data To Display'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
        && (
          <PaginationComponenet
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
            TBLData={TBLData}
          />
        )}
      </div>

    </div>
  );
}

export default IssueListComp;
