/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSearchFilter from '../../hooks/useSearchFilter';
import PaginationComponenet from '../common/Pagination';
import { addDependentRequirement, addSelectedEpicRequirement } from '../../services/requirementSlice';
import ReadMoreLess from '../common/ReadMoreLess';
import { useMyPageRequirementMutation } from '../../services/services';

function RequirementModal({
  modal, handleClose, label,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const location = useLocation();
  const dispatch = useDispatch();
  const [reqFetchData, fetchData] = useMyPageRequirementMutation();

  const requirementData = useSelector((state) => state.requirementData.selectedRequirement);
  const selectedEpicRequirement = useSelector((state) => state.requirementData.selectedEpicRequirement);
  const dependentRequirement = useSelector((state) => state.requirementData.dependentRequirement);
  console.log('dependentRequirement', dependentRequirement);

  const [dataTable, setDataTable] = useState([]);
  const [TBLData, setTBLData] = useState(dataTable || []);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRequirement, setSelectedRequirement] = useState([]);
  console.log('selectedRequirement', selectedRequirement);
  console.log('requirementData', TBLData);

  useEffect(() => {
    reqFetchData({ id: userData?.id });
  }, [label]);

  // useEffect(() => {
  //   if (requirementData && Array.isArray(requirementData) && requirementData?.length > 0) {
  //     setDataTable(requirementData);
  //   } else {
  //     setDataTable([]);
  //   }
  // }, [requirementData]);

  useEffect(() => {
    if (fetchData?.isSuccess && Array.isArray(fetchData?.data) && fetchData?.data?.length > 0) {
      setDataTable([...fetchData.data]);
    } else {
      setDataTable([]);
    }
  }, [fetchData?.isSuccess]);

  useEffect(() => {
    if (location.pathname === '/requirement') {
      if (dependentRequirement && Array.isArray(dependentRequirement) && dependentRequirement?.length > 0) {
        setSelectedRequirement(dependentRequirement);
      } else {
        setSelectedRequirement([]);
      }
    } else if (selectedEpicRequirement && Array.isArray(selectedEpicRequirement) && selectedEpicRequirement?.length > 0) {
      setSelectedRequirement(selectedEpicRequirement);
    } else {
      setSelectedRequirement([]);
    }
  }, [location, selectedEpicRequirement, dependentRequirement]);

  const { filter, setFilter, filterArray } = useSearchFilter(dataTable || []);

  useEffect(() => {
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0) {
      setTotalCount(dataTable.length);
    } else {
      setTotalCount(0);
    }
  }, [dataTable]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 10) {
      const reversedArray = [];
      dataTable?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0) {
      const reversedArray = [];
      dataTable?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [dataTable, currentPage]);

  useEffect(() => {
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [dataTable, filter, filterArray]);

  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (selectedRequirement?.some((v) => v.id === d.id)) {
      setSelectedRequirement(selectedRequirement.filter((val) => val?.id !== d?.id));
    } else {
      setSelectedRequirement([...selectedRequirement, d]);
    }
  };

  const removeHandler = (e, name) => {
    e.stopPropagation();
    setSelectedRequirement(selectedRequirement?.filter((value) => value?.id !== name?.id));
  };

  const getCheckedVal = (nameVal) => selectedRequirement?.some((el) => el?.id === nameVal);

  const onSelect = () => {
    if (location.pathname === '/requirement') {
      dispatch(addDependentRequirement(selectedRequirement));
    } else {
      dispatch(addSelectedEpicRequirement(selectedRequirement));
    }
    handleClose();
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal
      show={modal.show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Requirements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
          ? (
            <div className="card">
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionDark"
              >
                <div className="card-body">
                  <h5 className="font-weight-normal" style={{ textTransform: 'capitalize' }}>
                    {`${modal.title}`}
                  </h5>
                  <div className="chip-wrapper">
                    <div className="md-chips">
                      {(selectedRequirement && Array.isArray(selectedRequirement) && selectedRequirement?.length > 0) && selectedRequirement?.map((val) => (
                        <div key={label + val.id} className="md-chip">
                          <span>{`${val.name}`}</span>
                          <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, val)}>
                            <span className="icon-close" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <h2>{`Select ${modal.title}s`}</h2>
                  <div className="table-responsive box-shadow mb-0">
                    <table className="table table-hover">
                      <thead>
                        <tr className="heading-row">
                          <th />
                          <th>Name</th>
                          <th>Description</th>
                          <th>Status</th>
                        </tr>
                        <tr className="filter-row">
                          <th className="check-field">&nbsp;</th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="name"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="description"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="status"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          {/* <th /> */}
                        </tr>
                      </thead>
                      <tbody>
                        {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0) ? TBLData?.map((ele) => (
                          <tr key={ele?.id}>
                            <td className="check-field">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type={label === 'epic' ? 'radio' : 'checkbox'}
                                  className="custom-control-input"
                                  checked={getCheckedVal(ele?.id)}
                                  onChange={(e) => checkHandler(e, ele)}
                                  value={ele?.name}
                                  id={modal.key + ele.id}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={modal.key + ele.id}
                                  aria-label="select"
                                />
                              </div>
                            </td>
                            <td><Button href={`/review/requirement/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name || '-'}</Button></td>
                            <td>
                              <ReadMoreLess text={ele?.description} />
                            </td>
                            <td>{ele?.status || '-'}</td>
                          </tr>
                        ))
                          : <tr className="text-center"><td colSpan={4}>No Data To Display</td></tr>}
                      </tbody>
                    </table>
                    {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
       && (
       <PaginationComponenet
         currentPage={currentPage}
         totalCount={totalCount}
         pageSize={PageSize}
         onPageChange={(page) => setCurrentPage(page)}
         TBLData={TBLData}
       />
       )}
                  </div>
                </div>
              </div>
            </div>
          )
          : `No ${modal.title} Found`}
      </Modal.Body>
      {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
      && (
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
        <Button variant="primary" onClick={() => onSelect()}>Select</Button>
      </Modal.Footer>
      )}
    </Modal>
  );
}

export default RequirementModal;
