import React from 'react';
import LoaderQ from '../images/QLorem_Logo_01.svg';

export default function LoaderComp() {
  return (
    <div className="loader">
      <img src={LoaderQ} alt="loading" />
    </div>
  );
}
