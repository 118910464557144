/* eslint-disable max-len */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTaskByUserQuery } from '../../services/services';
import AccessDenied from './AccessDenied';

export default function CheckAuth({ children, label }) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  console.log('label sss', label, userData);

  const location = useLocation();
  const authed = () => {
    if (label.some((lb) => lb === 'dashboard' || lb === 'readmode')) {
      return true;
    } if (userData.permissions.some((pr) => label.includes(pr))) {
      return true;
    }
    return false;

    // if (label === 'dashboard' || label === 'readmode' || label === 'view:appTwin' || label === 'update:processTwin' || label === 'view:process') {
    //   return true;
    // } if (label === 'view:change') {
    //   if (location?.state?.view === true) {
    //     return true;
    //   }
    // } if (label === 'create:application') {
    //   if (userData.permissions?.includes('update:application') && (location?.state?.update === true || userData.permissions?.includes('create:application'))) {
    //     return true;
    //   }
    // } else {
    //   return !!userData.permissions.includes(label);
    // }
  };
  console.log('auht', authed());
  return (
    authed() === true ? children : <AccessDenied />
  );
}
