/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import * as isToday from 'dayjs/plugin/isToday';
import * as utc from 'dayjs/plugin/utc';

import { useLocation } from 'react-router-dom';
import isJson from '../../utils/isJson';

dayjs.extend(isToday);
dayjs.extend(utc);

export default function DatePickComponent({
  fld, errors, t, label, handleChange, values, editMode, draftMode,
  epicInputDisable, changeInputDisable, inputDisable,
}) {
  const location = useLocation();
  const relatedProgram = useSelector((state) => state.formJson.relatedProgData);
  const relatedProjectDate = useSelector((state) => state.rcData.relatedProjectDate);
  const relatedChanges = useSelector((state) => state.formJson.relatedChangeData);
  const selectedEpicRequirement = useSelector((state) => state.requirementData.selectedEpicRequirement);
  const [includeStar, setIncludeStart] = useState(null);
  const [includeEnd, setIncludeEnd] = useState(null);
  const epicDate = location?.state?.data?.dfVals;
  const changeDate = location?.state?.data?.dfVals;
  const requirementValues = useSelector((state) => state.requirementData.requirementValues);

  console.log('epicDates', epicDate?.start_date, epicDate?.end_date, 'label', label);
  console.log('includeStar', includeStar, 'includeEnd', includeEnd);
  console.log('selectedEpicRequirement', selectedEpicRequirement);
  console.log('requirementValues', requirementValues);

  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const handleDatehange = (name, date) => {
    const target = { name, value: date };
    handleChange({ target });
  };

  useEffect(() => {
    if (label === 'initiative' && (relatedProgram && Array.isArray(relatedProgram) && relatedProgram?.length > 0) && relatedProjectDate?.startDate && relatedProjectDate?.endDate) {
      setIncludeStart(new Date(dayjs(relatedProjectDate?.startDate)));
      setIncludeEnd(new Date(dayjs(relatedProjectDate?.endDate)));
    } else if (label === 'epic' && (relatedChanges && Array.isArray(relatedChanges) && relatedChanges?.length > 0) && relatedChanges?.some((rc) => rc?.start_date !== rc?.end_date)) {
      setIncludeStart(new Date(dayjs(relatedChanges?.map((rc) => rc?.start_date))));
      setIncludeEnd(new Date(dayjs(relatedChanges?.map((rc) => rc?.end_date))));
    } else if (label === 'epic:epic_task' && epicDate?.start_date && epicDate?.end_date) {
      setIncludeStart(new Date(epicDate?.start_date));
      setIncludeEnd(new Date(epicDate?.end_date));
    } else if (label === 'change' && relatedProjectDate?.startDate && relatedProjectDate?.endDate) {
      setIncludeStart(new Date(dayjs(relatedProjectDate?.startDate)));
      setIncludeEnd(new Date(dayjs(relatedProjectDate?.endDate)));
    } else if (label === 'requirement' && changeDate?.start_date && changeDate?.end_date) {
      setIncludeStart(new Date(dayjs(changeDate?.start_date).subtract(1, 'day').utc()));
      setIncludeEnd(new Date(dayjs(changeDate?.end_date).add(1, 'day').utc()));
    }
  }, [label, relatedProgram, relatedChanges, relatedProjectDate, changeDate]);

  useEffect(() => {
    if (label === 'epic' && selectedEpicRequirement && Array.isArray(selectedEpicRequirement) && selectedEpicRequirement?.length > 0) { const minDateArr = []; }
    const minDateArr = [];
    const maxDateArr = [];
    selectedEpicRequirement?.forEach((vl) => {
      minDateArr.push(vl?.start_date);
    });
    selectedEpicRequirement?.forEach((vl) => {
      maxDateArr.push(vl?.end_date);
    });

    const minDate = (minDateArr && Array.isArray(minDateArr) && minDateArr?.length > 0) ? minDateArr?.reduce((first, second) => (first < second ? first : second)) : null;
    const maxDate = (maxDateArr && Array.isArray(maxDateArr) && maxDateArr?.length > 0) ? maxDateArr?.reduce((first, second) => (first > second ? first : second)) : null;

    if (minDate && maxDate) {
      setIncludeStart(new Date(dayjs(minDate)));
      setIncludeEnd(new Date(dayjs(maxDate)));
    } else if (label === 'epic' && (relatedChanges && Array.isArray(relatedChanges) && relatedChanges?.length > 0) && relatedChanges?.some((rc) => rc?.start_date !== rc?.end_date)) {
      setIncludeStart(new Date(dayjs(relatedChanges?.map((rc) => rc?.start_date))));
      setIncludeEnd(new Date(dayjs(relatedChanges?.map((rc) => rc?.end_date))));
    }
  }, [label, selectedEpicRequirement, relatedChanges]);

  const minDate = (name, value) => {
    switch (name) {
      case 'end_date':
        return (label === 'change' || label === 'initiative') ? (value?.start_date && dayjs(value?.start_date).isBefore(new Date()) ? new Date() : value?.start_date && dayjs(value?.start_date).isAfter(new Date()) ? value?.start_date : new Date()) : (label === 'epic' && value?.start_date) ? value?.start_date : (label === 'requirement' && value?.start_date) ? value?.start_date : (label === 'epic' && !value?.start_date) ? new Date() : value?.start_date ? value?.start_date : null;
        // return label === 'change' ? (value?.start_date && dayjs(value?.start_date).isBefore(new Date()) ? new Date() : value?.start_date && dayjs(value?.start_date).isAfter(new Date()) ? value?.start_date : new Date()) : label === 'epic' ? (value?.test_end_date && dayjs(value?.test_end_date).isBefore(new Date()) ? new Date() : value?.test_end_date && dayjs(value?.test_end_date).isAfter(new Date()) ? value?.test_end_date : new Date()) : value?.start_date ? value?.start_date : null;
      case 'tracking_date2':
        return value?.tracking_date1 ? value?.tracking_date1 : new Date();
      case 'tracking_date3':
        return value?.tracking_date2 ? value?.tracking_date2 : new Date();
      case 'approval_due_date':
        return value?.start_date && value?.end_date ? value?.start_date : new Date();
      case 'realisation_completion_date':
        return value?.realisation_start_date ? value?.realisation_start_date : new Date();
      case 'spec_end_date':
        return !value?.start_date ? new Date() : value?.start_date ? value?.start_date : null;
      case 'dev_end_date':
        return value?.spec_end_date && value?.test_end_date && !value?.dev_end_date ? value?.spec_end_date : !value?.start_date && !value?.dev_end_date && value?.test_end_date ? new Date() : value?.start_date && !value?.spec_end_date ? value?.start_date : value?.spec_end_date ? value?.spec_end_date : null;
      case 'test_end_date':
        return !value?.dev_end_date && value?.spec_end_date ? value?.spec_end_date : value?.dev_end_date ? value?.dev_end_date : null;
      default:
        return null;
    }
  };

  const maxDate = (name, value) => {
    switch (name) {
      case 'start_date':
        return !value?.spec_end_date && value?.test_end_date ? value?.test_end_date : !value?.start_date && !value?.spec_end_date && value?.dev_end_date ? value?.dev_end_date : value?.spec_end_date ? value?.spec_end_date : value?.end_date ? value?.end_date : value?.approval_due_date ? value?.approval_due_date : null;
      case 'approval_due_date':
        return value?.start_date && value?.end_date ? value?.end_date : null;
      case 'tracking_date1':
        return value?.tracking_date2 ? value?.tracking_date2 : value?.tracking_date3 ? value?.tracking_date3 : null;
      case 'tracking_date2':
        return value?.tracking_date3 ? value?.tracking_date3 : value?.tracking_date2 ? value?.tracking_date2 : null;
      case 'realisation_start_date':
        return value?.realisation_completion_date ? value?.realisation_completion_date : null;
      case 'spec_end_date':
        return value?.end_date ? value?.end_date : value?.start_date && !value?.dev_end_date && value?.test_end_date ? value?.test_end_date : !value?.start_date && value?.dev_end_date && value?.test_end_date ? value?.dev_end_date : !value?.start_date && value?.test_end_date ? value?.test_end_date : value?.dev_end_date ? value?.dev_end_date : null;
      case 'dev_end_date':
        return value?.end_date ? value?.end_date : value?.spec_end_date && value?.test_end_date && !value?.dev_end_date ? value?.test_end_date : !value?.start_date && !value?.dev_end_date && value?.test_end_date ? value?.test_end_date : value?.test_end_date ? value?.test_end_date : null;
      case 'test_end_date':
        return value?.end_date ? value?.end_date : null;

      default:
        return null;
    }
  };

  const dateRangeMinDate = (name, value) => {
    switch (name) {
      case 'start_date':
        return (label === 'epic' && includeStar) ? includeStar : (value?.start_date && value?.end_date) ? includeStar : value?.end_date ? includeStar : includeStar;
      case 'end_date':
        // return label === 'epic' ? (value?.test_end_date && dayjs(value?.test_end_date).isBefore(new Date()) ? new Date() : value?.test_end_date && dayjs(value?.test_end_date).isAfter(new Date()) ? value?.test_end_date : new Date()) : value?.start_date ? value?.start_date : includeStar;
        return (label === 'epic:epic_task' && value?.start_date) ? value?.start_date : label === 'requirement' ? (dayjs(value?.start_date || requirementValues?.start_date).isBefore(new Date()) ? new Date() : new Date(dayjs(value?.start_date || requirementValues?.start_date))) : (label === 'epic' && value?.start_date) ? value?.start_date : (label === 'initiative' && value?.start_date) ? value?.start_date : (label === 'change' && value?.start_date) ? value?.start_date : includeStar;
      case 'approval_due_date':
        return (value?.start_date && value?.end_date) ? value?.start_date : value?.start_date ? value?.start_date : value?.end_date ? value?.end_date : includeStar;
      case 'spec_end_date':
        return !value?.start_date ? includeStar : value?.start_date ? value?.start_date : includeStar;
      case 'dev_end_date':
        return value?.spec_end_date && value?.test_end_date && !value?.dev_end_date ? value?.spec_end_date : !value?.start_date && !value?.dev_end_date && value?.test_end_date ? includeStar : value?.start_date && !value?.spec_end_date ? value?.start_date : value?.spec_end_date ? value?.spec_end_date : includeStar;
      case 'test_end_date':
        return value?.dev_end_date && value?.spec_end_date ? value?.dev_end_date : !value?.dev_end_date && value?.spec_end_date ? value?.spec_end_date : value?.dev_end_date ? value?.dev_end_date : includeStar;
      default:
        return new Date();
    }
  };

  const dateRangeMaxDate = (name, value) => {
    switch (name) {
      case 'start_date':
        return (label === 'epic' && includeEnd) ? includeEnd : (label === 'requirement' && new Date(dayjs(value?.end_date || (requirementValues?.end_date || '')))) ? new Date(dayjs(value?.end_date || (requirementValues?.end_date || ''))) : value?.spec_end_date ? value?.spec_end_date : (value?.start_date && value?.end_date) ? value?.end_date : value?.end_date ? value?.end_date : includeEnd;
      case 'end_date':
        return value?.start_date ? includeEnd : includeEnd;
      case 'approval_due_date':
        return (value?.start_date && value?.end_date) ? value?.end_date : value?.start_date ? value?.start_date : value?.end_date ? value?.end_date : includeEnd;
      case 'spec_end_date':
        return value?.dev_end_date ? value?.dev_end_date : value?.end_date ? value?.end_date : value?.start_date && !value?.dev_end_date && value?.test_end_date ? value?.test_end_date : !value?.start_date && value?.dev_end_date && value?.test_end_date ? value?.dev_end_date : !value?.start_date && value?.test_end_date ? value?.test_end_date : value?.dev_end_date ? value?.dev_end_date : includeEnd;
      case 'dev_end_date':
        return value?.test_end_date ? value?.test_end_date : value?.end_date ? value?.end_date : value?.spec_end_date && value?.test_end_date && !value?.dev_end_date ? value?.test_end_date : !value?.start_date && !value?.dev_end_date && value?.test_end_date ? value?.test_end_date : value?.test_end_date ? value?.test_end_date : includeEnd;
      case 'test_end_date':
        return value?.end_date ? value?.end_date : includeEnd;
      default:
        return null;
    }
  };

  const [df, setDf] = useState('');
  useEffect(() => {
    const dformat = userData?.tenant_info?.date_format;
    if (dformat === 'DD/MM/YYYY') {
      setDf('dd/MM/yyyy');
    }
    if (dformat === 'MM/DD/YYYY') {
      setDf('MM/dd/yyyy');
    }
    if (dformat === 'YYYY/MM/DD') {
      setDf('yyyy/MM/dd');
    }
    if (dformat === 'YYYY/DD/MM') {
      setDf('yyyy/dd/MM');
    }
  }, [userData?.tenant_info?.date_format]);

  const [isSameDate, setIsSameDate] = useState(false);

  useEffect(() => {
    if (label === 'epic' && (relatedChanges && Array.isArray(relatedChanges) && relatedChanges?.length > 0)) {
      if (relatedChanges?.some((rc) => rc?.start_date === rc?.end_date)) {
        setIsSameDate(true);
      }
    } else {
      setIsSameDate(false);
    }
  }, [label, relatedChanges]);

  const [epicDateDisabled, setEpicDateDiabled] = useState(['start_date', 'end_date']);

  // useEffect(() => {
  //   if (label === 'epic' && values?.start_date) {
  //     setEpicDateDiabled(['start_date', 'spec_end_date']);
  //   } if (label === 'epic' && values?.spec_end_date) {
  //     setEpicDateDiabled(['start_date', 'spec_end_date', 'dev_end_date']);
  //   } if (label === 'epic' && values?.dev_end_date) {
  //     setEpicDateDiabled(['start_date', 'spec_end_date', 'dev_end_date', 'test_end_date']);
  //   } if (label === 'epic' && values?.test_end_date) {
  //     setEpicDateDiabled(['start_date', 'spec_end_date', 'dev_end_date', 'test_end_date', 'end_date']);
  //   }
  // }, [label, values]);

  return (
    <div className={(fld?.field_name === 'tracking_date1' || fld?.field_name === 'tracking_date2' || fld?.field_name === 'tracking_date3') ? 'col-6' : 'col-6 col-lg-4'} key={fld.field_name}>
      <div className="form-group">
        <label htmlFor={fld.field_name}>
          {label === 'addStep' ? t(`${fld.field_name}.name`) : t(t(`${label}:${fld.field_name}`).name)}
          {fld.field_value_option.validation && (
          <sup>*</sup>
          )}
        </label>
        <DatePicker
          className="form-control"
          id={`test-id-${fld.field_name}`}
          placeholderText={`${userData?.tenant_info?.date_format}`}
          dateFormat={df}
          showYearDropdown
          showMonthDropdown
          onChange={(date) => handleDatehange(fld.field_name, date)}
          selected={df && values[fld.field_name] ? new Date(values[fld.field_name]) : ''}
          minDate={(includeStar && includeEnd) ? dateRangeMinDate(fld.field_name, values) : minDate(fld.field_name, values)}
          maxDate={(includeStar && includeEnd) ? dateRangeMaxDate(fld.field_name, values) : isSameDate ? new Date(relatedChanges[0]?.end_date) : maxDate(fld.field_name, values)}
          portalId="root-portal"
          // readOnly={editMode && !draftMode && (label === 'change')}
          disabled={label === 'epic' ? !epicDateDisabled?.some((epd) => epd === fld?.field_name) : (editMode && !draftMode && (label === 'epic')) || epicInputDisable || changeInputDisable || inputDisable || fld?.isDisabled}
          includeDateIntervals={
            (label === 'initiative' && (relatedProgram && Array.isArray(relatedProgram) && relatedProgram?.length > 0) && relatedProjectDate?.startDate && relatedProjectDate?.endDate)
              ? [
                { start: new Date(dayjs(relatedProjectDate?.startDate)), end: new Date(dayjs(relatedProjectDate?.endDate)) },
              ]
              : (label === 'change' && relatedProjectDate?.startDate && relatedProjectDate?.endDate)
                ? [
                  { start: new Date(dayjs(relatedProjectDate?.startDate)), end: new Date(dayjs(relatedProjectDate?.endDate)) },
                ] : (label === 'epic:epic_task' && epicDate?.start_date && epicDate?.end_date)
                  ? [
                    { start: new Date(dayjs(epicDate?.start_date)), end: new Date(dayjs(epicDate?.end_date)) },
                  ] : (label === 'requirement' && changeDate?.start_date && changeDate?.end_date)
                    ? [
                      { start: new Date(dayjs(changeDate?.start_date).subtract(1, 'day').utc()), end: new Date(dayjs(changeDate?.end_date).add(1, 'day').utc()) },
                    ] : ''
          }
          autoComplete="off"
        />
        {errors && errors[fld.field_name] && (
        <div className="invalid-feedback">
          <span className="icon-error-red" />
          {errors[fld.field_name]}
        </div>
        )}
      </div>
    </div>
  );
}
