/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { addCapsMapping } from '../../services/applicationSlice';
import {
  useTwinCapsMutation,
} from '../../services/services';
import ExpandEpicCapsCardElement from '../../components/capabalities/ExpandEpicCaps';
import { addEpicIssueCapsMapping } from '../../services/issueSlice';
import ExpandIssueEpicCapsCardElement from './ExpandIssueEpicCapabilitySelection';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function EpicCapabilitySelection() {
  const dispatch = useDispatch();
  const { t } = useTranslation('capability');
  const capabilitiesData = useSelector((state) => state.issueData.capabilitiesData);
  const processCapabilitiesData = useSelector((state) => state.issueData.processCapabilitiesData);
  const businessCapabilitiesData = useSelector((state) => state.issueData.businessCapabilitiesData);
  const vendorCapabilitiesData = useSelector((state) => state.issueData.vendorCapabilitiesData);
  const customerCapabilitiesData = useSelector((state) => state.issueData.customerCapabilitiesData);
  const productCapabilitiesData = useSelector((state) => state.issueData.productCapabilitiesData);
  console.log('productCapabilitiesData', productCapabilitiesData);

  const relatedChange = useSelector((state) => state.formJson.relatedChangeData);
  const [reqTwinCaps, resDynDtoData] = useTwinCapsMutation();
  console.log('processCapabilitiesData', processCapabilitiesData);

  const [expanded, setExpanded] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [tableCapabilitiesData, setTableCapabilitiesData] = useState([]);
  const [capsInfo, setCapsInfo] = useState('');

  useEffect(() => {
    dispatch(addEpicIssueCapsMapping([{
      key: 'application',
      value: capabilitiesData,
    },
    {
      key: 'process',
      value: processCapabilitiesData,
    },
    {
      key: 'business',
      value: businessCapabilitiesData,
    },
    {
      key: 'vendor',
      value: vendorCapabilitiesData,
    },
    {
      key: 'customer',
      value: customerCapabilitiesData,
    },
    {
      key: 'product',
      value: productCapabilitiesData,
    },
    ]));
  }, [capabilitiesData, processCapabilitiesData, businessCapabilitiesData, vendorCapabilitiesData, customerCapabilitiesData, productCapabilitiesData]);

  useEffect(() => {
    if (relatedChange && Array.isArray(relatedChange) && relatedChange?.length > 0) {
      const [changeDetail] = relatedChange;
      reqTwinCaps(({ id: changeDetail?.id }));
    }
  }, [relatedChange]);

  useEffect(() => {
    if (resDynDtoData?.isSuccess && resDynDtoData?.data?.dto_twin_data?.length > 0) {
      const fsmActual = document.createElement('div');
      fsmActual.setAttribute('id', 'fsm_actual');
      const wrap = document.getElementById('fsm_container');
      wrap.appendChild(fsmActual);
    }
  }, [resDynDtoData?.isSuccess && resDynDtoData?.data?.dto_twin_data?.length > 0]);

  useEffect(() => {
    if (resDynDtoData?.isSuccess && Array.isArray(resDynDtoData?.data?.dto_twin_data) && resDynDtoData?.data?.dto_twin_data?.length > 0 && capsInfo?.title === 'Process') {
      const res1 = resDynDtoData?.data?.dto_twin_data?.filter((el) => (el?.key === 'process' || el?.key === 'Process'))?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      setTableCapabilitiesData(res1 && Array.isArray(res1) && res1?.length > 0 ? res1 : []);
    } else if (resDynDtoData?.isSuccess && Array.isArray(resDynDtoData?.data?.dto_twin_data) && resDynDtoData?.data?.dto_twin_data?.length > 0 && capsInfo?.title === 'Application') {
      const res1 = resDynDtoData?.data?.dto_twin_data?.filter((el) => (el?.key === 'application' || el?.key === 'Application'))?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      setTableCapabilitiesData(res1 && Array.isArray(res1) && res1?.length > 0 ? res1 : []);
    } else if (resDynDtoData?.isSuccess && Array.isArray(resDynDtoData?.data?.dto_twin_data) && resDynDtoData?.data?.dto_twin_data?.length > 0 && capsInfo?.title === 'Business') {
      const res1 = resDynDtoData?.data?.dto_twin_data?.filter((el) => (el?.key === 'business' || el?.key === 'Business'))?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      setTableCapabilitiesData(res1 && Array.isArray(res1) && res1?.length > 0 ? res1 : []);
    } else if (resDynDtoData?.isSuccess && Array.isArray(resDynDtoData?.data?.dto_twin_data) && resDynDtoData?.data?.dto_twin_data?.length > 0 && capsInfo?.title === 'Vendor') {
      const res1 = resDynDtoData?.data?.dto_twin_data?.filter((el) => (el?.key === 'vendor' || el?.key === 'Vendor'))?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      setTableCapabilitiesData(res1 && Array.isArray(res1) && res1?.length > 0 ? res1 : []);
    } else if (resDynDtoData?.isSuccess && capsInfo?.title === 'Customer') {
      const res1 = resDynDtoData?.data?.dto_twin_data?.filter((el) => (el?.key === 'customer' || el?.key === 'Customer'))?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      setTableCapabilitiesData(res1 && Array.isArray(res1) && res1?.length > 0 ? res1 : []);
    } else if (resDynDtoData?.isSuccess && capsInfo?.title === 'Product') {
      const res1 = resDynDtoData?.data?.dto_twin_data?.filter((el) => (el?.key === 'product' || el?.key === 'Product'))?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      setTableCapabilitiesData(res1 && Array.isArray(res1) && res1?.length > 0 ? res1 : []);
    }
  }, [capsInfo, resDynDtoData.isSuccess,
  ]);

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  return (
    <div>
      <hr />
      <h2>
        Select Capabilities
        {(capsInfo?.title && expanded) ? ` : ${capsInfo?.title}` : ''}
      </h2>
      <div id="expand-demo">
        <div id="wrpp" className="capability-cards">
          <div id="fsm_container" className="fsm-container">

            {/*  porcess */}
            <div className="fsm">
              {(processCapabilitiesData && Array.isArray(processCapabilitiesData) && processCapabilitiesData?.length > 0)
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-value-chain" />
                      {/* {t('epic_process_title')} */}
                      Process
                      {' '}
                      {`(${processCapabilitiesData?.length})`}
                      {processCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'process')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>{t('process_table_header_name')}</th>
                            <th>{t('process_table_header_date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(processCapabilitiesData && processCapabilitiesData.length > 0 && Array.isArray(processCapabilitiesData)) && processCapabilitiesData?.slice(0, 5)?.map((ele) => (
                            <tr key={ele?.name}>
                              <td className="sm-col">
                                <span className="icon-value-chain" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/process/${ele?.digital_twin_data?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{ele?.digital_twin_data?.delivery_date ? dayjs(ele?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {processCapabilitiesData.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'process')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-value-chain" />
                      {/* {t('epic_process_title')} */}
                      Process
                      {processCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'process')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      <a
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'process')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          {/* {t('epic_select_card_process_title')} */}
                          Select Process/s
                        </i>
                      </a>
                    </div>
                  </div>
                )}
            </div>

            {/*  application */}
            <div className="fsm">
              {capabilitiesData && Array.isArray(capabilitiesData) && capabilitiesData.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-application" />
                      {/* {t('epic_application_title')} */}
                      Application
                      {' '}
                      {`(${capabilitiesData.length})`}
                      {capabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'application')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>{t('application_table_header_name')}</th>
                            <th>{t('application_table_header_date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(capabilitiesData && capabilitiesData.length > 0 && Array.isArray(capabilitiesData)) && capabilitiesData?.slice(0, 5)?.map((ele) => (
                            <tr key={ele?.name}>
                              <td className="sm-col">
                                <span className="icon-application" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/application/${ele?.digital_twin_data?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{ele?.digital_twin_data?.delivery_date ? dayjs(ele?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {capabilitiesData?.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'application')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-application" />
                      {/* {t('epic_application_title')} */}
                      Application
                      {capabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'application')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      <a
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'application')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          {/* {t('epic_select_card_application_title')} */}
                          Select Application/s
                        </i>
                      </a>
                    </div>
                  </div>
                )}
            </div>

            {/*  business start */}
            <div className="fsm">
              {businessCapabilitiesData && Array.isArray(businessCapabilitiesData) && businessCapabilitiesData.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-business" />
                      Business
                      {' '}
                      {`(${businessCapabilitiesData.length})`}
                      {businessCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'business')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Business Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(businessCapabilitiesData && businessCapabilitiesData.length > 0 && Array.isArray(businessCapabilitiesData)) && businessCapabilitiesData?.slice(0, 5)?.map((ele) => (
                            <tr key={ele?.name}>
                              <td className="sm-col">
                                <span className="icon-business" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/business/${ele?.digital_twin_data?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{ele?.digital_twin_data?.delivery_date ? dayjs(ele?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {businessCapabilitiesData?.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'business')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-business" />
                      Business
                      {businessCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'business')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      <a
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'business')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Business/s
                        </i>
                      </a>
                    </div>
                  </div>
                )}
            </div>
            {/*  business end */}

            {/*  vendor start */}
            <div className="fsm">
              {vendorCapabilitiesData && Array.isArray(vendorCapabilitiesData) && vendorCapabilitiesData.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-supplier" />
                      Vendor
                      {' '}
                      {`(${vendorCapabilitiesData.length})`}
                      {vendorCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'vendor')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Vendor Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(vendorCapabilitiesData && vendorCapabilitiesData.length > 0 && Array.isArray(vendorCapabilitiesData)) && vendorCapabilitiesData?.slice(0, 5)?.map((ele) => (
                            <tr key={ele?.name}>
                              <td className="sm-col">
                                <span className="icon-supplier" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/vendor/${ele?.digital_twin_data?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{ele?.digital_twin_data?.delivery_date ? dayjs(ele?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {vendorCapabilitiesData?.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'vendor')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-supplier" />
                      Vendor
                      {vendorCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'vendor')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      <a
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'vendor')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Vendor/s
                        </i>
                      </a>
                    </div>
                  </div>
                )}
            </div>
            {/*  vendor end */}

            {/*  customer start */}
            <div className="fsm">
              {customerCapabilitiesData && Array.isArray(customerCapabilitiesData) && customerCapabilitiesData.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-consumer" />
                      Customer
                      {' '}
                      {`(${customerCapabilitiesData.length})`}
                      {customerCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'customer')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Customer Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(customerCapabilitiesData && customerCapabilitiesData.length > 0 && Array.isArray(customerCapabilitiesData)) && customerCapabilitiesData?.slice(0, 5)?.map((ele) => (
                            <tr key={ele?.name}>
                              <td className="sm-col">
                                <span className="icon-consumer" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/customer/${ele?.digital_twin_data?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{ele?.digital_twin_data?.delivery_date ? dayjs(ele?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {customerCapabilitiesData?.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'customer')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-consumer" />
                      Customer
                      {customerCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'customer')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      <a
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'customer')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Customer/s
                        </i>
                      </a>
                    </div>
                  </div>
                )}
            </div>
            {/*  customer end */}

            {/*  product start */}
            <div className="fsm">
              {productCapabilitiesData && Array.isArray(productCapabilitiesData) && productCapabilitiesData.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-toolbar" />
                      Product
                      {' '}
                      {`(${productCapabilitiesData.length})`}
                      {productCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'product')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Product Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(productCapabilitiesData && productCapabilitiesData.length > 0 && Array.isArray(productCapabilitiesData)) && productCapabilitiesData?.slice(0, 5)?.map((ele) => (
                            <tr key={ele?.name}>
                              <td className="sm-col">
                                <span className="icon-toolbar" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/product/${ele?.digital_twin_data?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{ele?.digital_twin_data?.delivery_date ? dayjs(ele?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {productCapabilitiesData?.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'product')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-toolbar" />
                      Product
                      {productCapabilitiesData?.length > 0 && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'product')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      <a
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'product')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Product/s
                        </i>
                      </a>
                    </div>
                  </div>
                )}
            </div>
            {/*  product end */}

            <div id="fsm_actual">
              {expanded
              && (
                <ExpandIssueEpicCapsCardElement
                  capabilitiesData={capabilitiesData}
                  processCapabilitiesData={processCapabilitiesData}
                  businessCapabilitiesData={businessCapabilitiesData}
                  vendorCapabilitiesData={vendorCapabilitiesData}
                  customerCapabilitiesData={customerCapabilitiesData}
                  productCapabilitiesData={productCapabilitiesData}
                  dispatch={dispatch}
                  setExpanded={setExpanded}
                  tableData={tableCapabilitiesData || []}
                  capsInfo={capsInfo}
                  size={size}
                  position={position}
                />
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default EpicCapabilitySelection;
