/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
// @ts-nocheck
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);
function StepFooter({
  steps, activeStep, setModalShow, handleBack, componentHandler, isDraft, isLoading, isError, label,
  isSuccess, actions,
  nextDisabled,
  labelTwin,
  twinMode,
}:any): JSX.Element {
  const [mode, setMode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [changeDisable, setChangeDisable] = useState(false);
  const [onHover, setOnHover] = useState(false);
  console.log('onHover', onHover);

  const draftMode = useSelector((state) => state.formJson.draftMode);
  const editMode = useSelector((state) => state.editData.editMode);
  const selectedRequirement = useSelector((state) => state.requirementData.selectedRequirement);
  console.log('selectedRequirement', selectedRequirement, selectedRequirement?.some((el) => el?.mode === 'draft'), label);

  // const twinMode = useSelector((state) => state.editData.twinMode);

  const saveAsDraft = (e) => {
    setMode('draft');
    componentHandler(e, 'draft');
  };

  const saveAsSubmit = (e) => {
    setMode('submitted');
    componentHandler(e, 'submitted');
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  const { t } = useTranslation('button');

  // useEffect(() => {
  //   if (mode === 'draft' || mode === 'submitted') {
  //     setDisabled(true);
  //   }
  // }, [mode]);
  useEffect(() => {
    if (label === 'Change') {
      if ((mode === 'draft' || mode === 'submitted') && !actions) {
        setChangeDisable(true);
      } else {
        setChangeDisable(false);
      }
    }
  }, [label, actions]);

  useEffect(() => {
    if (label === 'Change' && nextDisabled) {
      setChangeDisable(true);
    } else {
      setChangeDisable(false);
    }
  }, [label, nextDisabled]);

  const handleActionButton = (e) => {
    componentHandler(e, 'submitted');
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  return (
    <div className="button-wrapper d-flex align-items-center justify-content-end w-100">
      <div className="secondary-btns d-flex align-items-center">
        <button
          onClick={() => setModalShow(true)}
          type="button"
          className="btn btn-dark"

        >
          {t('button:cancel')}
        </button>
      </div>
      <div className="primary-btns d-flex align-items-center">
        <button
          disabled={label !== 'Change' && (disabled || steps[0].key === activeStep.key)}
          onClick={handleBack}
          type="button"
          className="btn btn-outline-primary"
        >
          {t('button:back')}
        </button>

        {isDraft
        && (
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={(e) => saveAsDraft(e)}
          disabled={(label !== 'Change' ? (disabled || isSuccess) : changeDisable) || (editMode && !draftMode && !twinMode)}
        >
          { (isLoading || isError) && mode === 'draft' ? <Spinner animation="border" variant="info" />
            : t('button:saveDraft')}
        </button>
        )}

        {activeStep.key === 'step1'
          ? (
            <button
              type="button"
              className="btn btn-primary"
              data-test-id={steps[steps.length - 1].key !== activeStep.key ? 'btn-next' : 'btn-submit'}
              onClick={(e) => handleActionButton(e)}
            >
              {steps[steps.length - 1].key !== activeStep.key ? t('button:next') : t('button:submit')}
            </button>
          )
          : (label === 'Change' && activeStep.key === 'step7'
              && (selectedRequirement && Array.isArray(selectedRequirement) && selectedRequirement?.length > 0
              && selectedRequirement?.some((el) => el?.mode === 'draft'))) ? (
                // <OverlayTrigger placement="top" overlay={renderTooltip('To submit a change all requirement(s) need to submitted')}>
                <span title="To submit a change all requirement(s) need to submitted">
                  <Button
                    type="button"
                    data-test-id={steps[steps.length - 1].key !== activeStep.key ? 'btn-next' : 'btn-submit'}
                    className="btn btn-primary"
                    disabled
                  >
                    {t('button:submit')}
                  </Button>
                </span>
          // </OverlayTrigger>
            ) : (
              <button
                type="button"
                data-test-id={steps[steps.length - 1].key !== activeStep.key ? 'btn-next' : 'btn-submit'}
                className="btn btn-primary"
                disabled={(label && label !== 'Change') ? (disabled || isSuccess || nextDisabled) : changeDisable}
                onClick={(e) => saveAsSubmit(e)}
              >
                {
              (isLoading || isError) && mode === 'submitted' ? <Spinner animation="border" variant="info" />
                : steps[steps.length - 1].key !== activeStep.key ? t('button:next') : t('button:submit')
             }

              </button>
            )}

      </div>
    </div>
  );
}
export default StepFooter;
