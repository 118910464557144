import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jsonData: [],
  sectionData: [],
  riskValues: {},
  selectedRisk: [],
  riskMitihgationData: [],
};

export const riskSlice = createSlice({
  name: 'risk',
  initialState,
  reducers: {
    addJson: (state, { payload }) => {
      state.jsonData = payload;
    },
    addSection: (state, { payload }) => {
      state.sectionData = payload;
    },
    addTranslation: (state, { payload }) => {
      state.sectionData = payload;
    },
    addRiskVals: (state, { payload }) => {
      state.riskValues = payload;
    },
    addRiskMitigationData: (state, { payload }) => {
      const uniqueRiskMitihgationData = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.riskMitihgationData = uniqueRiskMitihgationData;
    },
    addRisk: (state, { payload }) => {
      state.selectedRisk = payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  addJson, addSection, addRiskVals, addRiskMitigationData, addRisk,
} = riskSlice.actions;

export default riskSlice.reducer;
