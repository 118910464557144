/* eslint-disable no-shadow */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-const */
/* eslint-disable func-names */
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import GaugeChart from 'react-gauge-chart';

function GuageChart() {
  // const drawChart = () => {
  //   let name = '35% (Aug 2022)';
  //   let value = 50;
  //   let gaugeMaxValue = 100;
  //   // donn?es ? calculer
  //   let percentValue = value / gaugeMaxValue;
  //   console.log('percentValue', percentValue);
  //   /// /////////////////////
  //   let needleClient;

  //   let barWidth;
  //   let chart;
  //   let chartInset;
  //   let degToRad;
  //   let repaintGauge;
  //   let height;
  //   let margin;
  //   let numSections;
  //   let padRad;
  //   let percToDeg;
  //   let percToRad;
  //   let percent;
  //   let radius;
  //   let sectionIndx;
  //   let svg;
  //   let totalPercent;
  //   let width;
  //   let sectionPerc;
  //   percent = percentValue;
  //   numSections = 1;
  //   sectionPerc = 1 / numSections / 2;
  //   padRad = 0.025;
  //   chartInset = 10;
  //   // Orientation of gauge:
  //   totalPercent = 0.75;
  //   // el = d3.select('.chart-gauge');
  //   margin = {
  //     top: 20,
  //     right: 20,
  //     bottom: 30,
  //     left: 20,
  //   };
  //   width = document.getElementById('gaugeChart').offsetWidth
  //     - margin.left
  //     - margin.right;
  //   height = (width / 2) + margin.top;
  //   radius = Math.min(width, height) / 2;
  //   barWidth = (40 * width) / 300;
  //   // Utility methods
  //   percToDeg = function (perc) {
  //     return perc * 360;
  //   };
  //   percToRad = function (perc) {
  //     return degToRad(percToDeg(perc));
  //   };
  //   degToRad = function (deg) {
  //     return (deg * Math.PI) / 180;
  //   };
  //   // Create SVG element
  //   svg = d3
  //     .select('#gaugeChart')
  //     .append('svg')
  //     .attr('width', width + margin.left + margin.right)
  //     .attr('height', height + margin.top + margin.bottom);
  //   // Add layer for the panel
  //   chart = svg
  //     .append('g')
  //     .attr(
  //       'transform',
  //       `translate(${
  //         (width + margin.left) / 2
  //       }, ${
  //         height - margin.top
  //       })`,
  //     );
  //   chart.append('path').attr('class', 'arc chart-first');
  //   chart.append('path').attr('class', 'arc chart-second');
  //   chart.append('path').attr('class', 'arc chart-third');

  //   let arcGenerator = d3.arc();

  //   let pathDataArc1 = arcGenerator({
  //     startAngle: -0.5 * Math.PI,
  //     endAngle: -0.17 * Math.PI,
  //     innerRadius: 115,
  //     outerRadius: 160,
  //   });
  //   let pathDataArc2 = arcGenerator({
  //     startAngle: -0.17 * Math.PI,
  //     endAngle: 0.17 * Math.PI,
  //     innerRadius: 115,
  //     outerRadius: 160,
  //   });
  //   let pathDataArc3 = arcGenerator({
  //     startAngle: 0.17 * Math.PI,
  //     endAngle: 0.5 * Math.PI,
  //     innerRadius: 115,
  //     outerRadius: 160,
  //   });

  //   chart.select('.chart-first').attr('d', pathDataArc1);
  //   chart.select('.chart-second').attr('d', pathDataArc2);
  //   chart.select('.chart-third').attr('d', pathDataArc3);

  //   let dataset = [{ metric: name, value }];
  //   let texts = svg.selectAll('text').data(dataset).enter();
  //   texts
  //     .append('text')
  //     .text(() => dataset[0].metric)
  //     .attr('id', 'Name')
  //     .attr(
  //       'transform',
  //       `translate(${
  //         (width + margin.left) / 3
  //       }, ${
  //         height + margin.top + margin.top
  //       })`,
  //     )
  //     .attr('font-size', 15)
  //     .style('fill', '#000000');
  //   let trX = 180 - 210 * Math.cos(percToRad(percent / 2));
  //   let trY = 195 - 210 * Math.sin(percToRad(percent / 2));
  //   // (180, 195) are the coordinates of the center of the gauge.
  //   console.log('dataset', dataset);
  //   // const displayValue = () => {
  //   //   texts
  //   //     .append('text')
  //   //     .text(() => dataset[0].value)
  //   //     .attr('id', 'Value')
  //   //     .attr('transform', `translate(${trX}, ${trY})`)
  //   //     .attr('font-size', 18)
  //   //     .style('fill', '#000000');
  //   // };
  //   texts
  //     .append('text')
  //     .text(() => 0)
  //     .attr('id', 'scale0')
  //     .attr(
  //       'transform',
  //       `translate(${
  //         (width + margin.left) / 100
  //       }, ${
  //         (height + margin.top) / 2
  //       })`,
  //     )
  //     .attr('font-size', 15)
  //     .style('fill', '#000000');
  //   texts
  //     .append('text')
  //     .text(() => gaugeMaxValue / 2)
  //     .attr('id', 'scale10')
  //     .attr(
  //       'transform',
  //       `translate(${
  //         (width + margin.left) / 2.15
  //       }, ${
  //         (height + margin.top) / 30
  //       })`,
  //     )
  //     .attr('font-size', 15)
  //     .style('fill', '#000000');
  //   texts
  //     .append('text')
  //     .text(() => gaugeMaxValue)
  //     .attr('id', 'scale20')
  //     .attr(
  //       'transform',
  //       `translate(${
  //         (width + margin.left) / 1.03
  //       }, ${
  //         (height + margin.top) / 2
  //       })`,
  //     )
  //     .attr('font-size', 15)
  //     .style('fill', '#000000');
  //   // setTimeout(displayValue, 1350);
  // };
  // useEffect(() => {
  //   drawChart();
  // }, []);

  // const chartStyle = {
  //   height: 80,
  //   width: 160,
  // };
  return (
    <>
      <h2 className="text-center">Overall risk Index</h2>
      <GaugeChart
        id="gauge-chart1"
        textColor="#000"
        arcPadding={0}
        cornerRadius={0}
        arcWidth={0.3}
        arcsLength={[0.5, 0.5, 0.5]}
        colors={['#22EE22', '#FF9D00', '#FF3333']}
        percent={0.35}
        formatTextValue={(value) => `${value}% (Aug 2022)`}
        animate
      />

      <div id="gaugeChart" />
      <div className="chart-notations no-color">
        <ul className="list-unstyled mb-0 justify-content-center">
          <li>
            <span className="chart-note green" />
            Low
          </li>
          <li>
            <span className="chart-note orange" />
            Medium
          </li>
          <li>
            <span className="chart-note red" />
            High
          </li>
        </ul>
      </div>

    </>
  );
}

export default GuageChart;
