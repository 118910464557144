/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addBusinessCapabilitiesData, addCapabilitiesData, addCustomerCapabilitiesData, addIssueCapabilitiesReadOnly, addIssueResPeople, addIssueVals, addProcessCapabilitiesData, addProductCapabilitiesData, addVendorCapabilitiesData, setCorrectionList, setImpactList, setIssueReadReviewTab,
} from '../../services/issueSlice';
import { useGetIssueByIdMutation } from '../../services/services';
import IssueComp from './Issue/IssueList';
import IssueReview from './IssueReview';
import ImpactListComp from './Impact/ImpactList';
import CorrectionListComp from './Correcttion/CorrectionList';
import { addDocsInfo } from '../../services/docInfoSlice';

function IssueReadReview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = useSelector((state) => state.issueData.issueReadReviewTab);
  const issueValues = useSelector((state) => state.issueData.issueValues);

  const [reqIssueById, resIssueById] = useGetIssueByIdMutation();

  const handleTab = (tab) => {
    dispatch(setIssueReadReviewTab(tab));
  };

  useEffect(() => {
    if (issueValues?.id) {
      reqIssueById({ id: issueValues?.id });
    }
  }, []);

  useEffect(() => {
    if (resIssueById?.isSuccess && resIssueById?.data) {
      console.log('resIssueById?.data', resIssueById?.data);
      dispatch(addIssueVals(resIssueById?.data));
      // if (resIssueById?.data?.documents && Array.isArray(resIssueById?.data?.documents) && resIssueById?.data?.documents?.length > 0) {
      //   dispatch(addDocsInfo(resIssueById?.data?.documents));
      // }
      if (resIssueById?.data?.responsible && Array.isArray(resIssueById?.data?.responsible) && resIssueById?.data?.responsible?.length > 0) {
        dispatch(addIssueResPeople(resIssueById?.data?.responsible));
      }
      if (resIssueById?.data?.impacts && Array.isArray(resIssueById?.data?.impacts) && resIssueById?.data?.impacts?.length > 0) {
        dispatch(setImpactList(resIssueById?.data?.impacts));
      }
      if (resIssueById?.data?.corrections && Array.isArray(resIssueById?.data?.corrections) && resIssueById?.data?.corrections?.length > 0) {
        dispatch(setCorrectionList(resIssueById?.data?.corrections));
      }
      if ((resIssueById?.data?.object_type === 'Change' || resIssueById?.data?.object_type === 'Epic') && resIssueById?.data?.dependencies && Array.isArray(resIssueById?.data?.dependencies) && resIssueById?.data?.dependencies?.length > 0) {
        dispatch(addIssueCapabilitiesReadOnly({
          processCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'process' || el?.key === 'Process'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          appCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'application' || el?.key === 'Application'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          businessCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'business' || el?.key === 'Business'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          productCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'product' || el?.key === 'Product'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          vendorCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'vendor' || el?.key === 'Vendor'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          customerCaps: resIssueById?.data?.dependencies?.filter((el) => (el?.key === 'customer' || el?.key === 'Customer'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
        }));
      } else {
        dispatch(addIssueCapabilitiesReadOnly({
          processCaps: [],
          appCaps: [],
          businessCaps: [],
          productCaps: [],
          vendorCaps: [],
          customerCaps: [],
        }));
      }
      if ((resIssueById?.data?.object_type === 'Epic') && resIssueById?.data?.dependencies && Array.isArray(resIssueById?.data?.dependencies) && resIssueById?.data?.dependencies?.length > 0) {
        dispatch(addCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addBusinessCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProductCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
        dispatch(addVendorCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addCustomerCapabilitiesData(resIssueById?.data?.dependencies?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
      } else {
        dispatch(addCapabilitiesData([]));
        dispatch(addProcessCapabilitiesData([]));
        dispatch(addBusinessCapabilitiesData([]));
        dispatch(addProductCapabilitiesData([]));
        dispatch(addVendorCapabilitiesData([]));
        dispatch(addCustomerCapabilitiesData([]));
      }
    }
  }, [resIssueById?.isSuccess]);

  return (
    <div className="content-wrapper">
      <div className="card">
        <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>
            Issue
            {' '}
            {location?.state?.issueName || ''}
          </h1>
          {(location?.state?.from === '/readReview' || location?.state?.from === '/readModeChange')
            && <Button className="text-decoration-none" type="button" onClick={() => navigate(location?.state?.from, { state: location?.state?.state })}>Back</Button>}

        </div>
        <div className="card-body">

          {/* <IssueComp /> */}

          <div className="pills-tabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(k) => handleTab(k)}
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <IssueReview label={location?.state?.type} />
              </Tab>
              <Tab eventKey="impact" title="Impact">
                <ImpactListComp />
              </Tab>
              <Tab eventKey="correction" title="Correction">
                <CorrectionListComp />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IssueReadReview;
