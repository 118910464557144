import React from 'react';
import LoaderQ from '../images/QLorem_Logo_01.svg';

export default function InnerLoader() {
  return (
    <div className="inner-loader m-2">
      <img src={LoaderQ} alt="loading" />
    </div>
  );
}
