/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import {
  useGetAllProgsQuery,
  useSaveDocsMutation,
  useSubmitFormMutation,
  useGetIssueJsonQuery,
  useGetIssueRelatedObjectMutation,
  useSubmitIssueMutation,
  useGetImpactJsonQuery,
  useGetCorrectionJsonQuery,
  useSubmitCorrectionMutation,
} from '../../../services/services';
import AlertMessage from '../../../SuperComponents/AlertMessage';
import LoaderComp from '../../../components/LoderQ';
import ErrorModal from '../../../components/common/ErrorModal';
import IssueFromBuilder from '../IssueFromBuilder';
import { addDocsInfo, clearDocsInfo } from '../../../services/docInfoSlice';
import { addCorrectionResPeople } from '../../../services/issueSlice';
import { addIsDirty } from '../../../services/navSlice';

function CreatesCorrection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('correction');
  const location = useLocation();
  console.log('issue location', location);

  // Queries & Mutation
  const [saveDocs, docRes] = useSaveDocsMutation();
  const [submitForm, res] = useSubmitCorrectionMutation();
  const { isSuccess, isLoading, isError } = res;
  const formJson = useGetCorrectionJsonQuery();
  // Redux State
  const issueValues = useSelector((state) => state.issueData.issueValues);
  const correctionEditMode = useSelector(
    (state) => state.issueData.correctionEditMode,
  );
  console.log('correctionEditMode', correctionEditMode);
  const correctionValues = useSelector(
    (state) => state.issueData.correctionValues,
  );
  // const issueResPeople = useSelector((state) => state.issueData.issueResPeople);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const issueCapabilitiesReadOnly = useSelector(
    (state) => state.issueData.issueCapabilitiesReadOnly,
  );
  const epicIssueCapsMapping = useSelector(
    (state) => state.issueData.epicIssueCapsMapping,
  );
  const correctionResPeople = useSelector(
    (state) => state.issueData.correctionResPeople,
  );

  console.log(
    'docsInfo',
    docsInfo,
    docsInfo.filter((item, index) => docsInfo.indexOf(item) === index),
  );
  console.log('correctionValues', correctionValues, formJson?.data?.fields);

  const [jsonTemplate, setJsonTemplate] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const cancelTitle = 'Correction creation';

  console.log('jsonTemplate', jsonTemplate);

  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (formJson?.isSuccess) {
      if (correctionValues?.status && correctionValues?.status !== 'New') {
        const data = Array.isArray(formJson?.data?.fields)
          && formJson?.data?.fields?.length > 0
          && formJson?.data?.fields?.map((rs) => (rs.field_name === 'status'
            ? {
              ...rs,
              field_value_option: {
                ...rs.field_value_option,
                options: rs?.field_value_option?.options?.filter(
                  (up) => up?.name === 'Open'
                        || up?.name === 'Created'
                        || up?.name === 'Approved'
                        || up?.name === 'On-hold'
                        || up?.name === 'Closed',
                ),
              },
              value: {
                label: correctionValues?.status,
                value: correctionValues?.status,
              },
            }
            : { ...rs }));
        setJsonTemplate({ fields: data, sections: formJson?.data?.sections });
      } else {
        setJsonTemplate(formJson.data);
      }
    }
  }, [formJson?.isSuccess, correctionValues]);

  // Setting Docments on edit
  useEffect(() => {
    if (correctionEditMode) {
      if (
        correctionValues?.documents
        && Array.isArray(correctionValues?.documents)
        && correctionValues?.documents?.length > 0
      ) {
        dispatch(addDocsInfo(correctionValues?.documents));
      }
    }
  }, [correctionEditMode]);
  // Save Doc Res to State
  useEffect(() => {
    if (
      docRes.isSuccess
      && docRes.data.docData
      && docRes.data.docData?.length > 0
      && Array.isArray(docRes.data.docData)
    ) {
      const docsData = docRes?.data?.docData?.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addDocsInfo(docsData));
    }
  }, [docRes.isSuccess]);

  // Reset All steps
  const stepReset = () => {
    dispatch({ type: 'RESET_APP' });
    navigate('/dashboard', {
      state: {
        selected: 'Programs',
      },
    });
  };

  // Function to set State while clicking on Next
  const onSubmit = (values) => {
    console.log('values', values);
    const finalData = {
      ...values,
      status: values?.status === 'New' ? 'Open' : values?.status,
      created: correctionEditMode
        ? correctionValues?.created
        : dayjs().format('YYYY-MM-DD hh:mm:ss'),
      created_by: {
        id: userData?.id,
      },
      updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      updated_by: {
        id: userData?.id,
      },
      deleted: false,
      documents:
        docsInfo?.filter((item, index) => docsInfo?.indexOf(item) === index)
        || null,
      mode: 'Submitted',
      issue: {
        ...issueValues,
      },
      dependencies:
        epicIssueCapsMapping
        && Array.isArray(epicIssueCapsMapping)
        && epicIssueCapsMapping.length > 0
          ? epicIssueCapsMapping?.filter((ele) => ele?.value?.length !== 0)
          : issueCapabilitiesReadOnly
            && Array.isArray(issueCapabilitiesReadOnly)
            && issueCapabilitiesReadOnly?.length
            ? issueCapabilitiesReadOnly
              ?.map((el) => ({ key: el.key, value: el.value }))
              ?.filter((ele) => ele?.value?.length !== 0)
            : null,
      responsible:
        correctionResPeople
        && correctionResPeople?.length > 0
        && Array.isArray(correctionResPeople)
          ? correctionResPeople?.map((el) => ({
            id: el.id,
            email: el.email,
            first_name: el?.first_name,
            last_name: el?.last_name,
            designation_id: {
              id: el?.designation_id?.id,
              name: el?.designation_id?.name,
            },
            department_id: {
              id: el?.department_id?.id,
              name: el?.department_id?.name,
            },
          }))
          : [],
      // dependencies: (issueCapabilitiesReadOnly && Array.isArray(issueCapabilitiesReadOnly) && issueCapabilitiesReadOnly?.length) ? issueCapabilitiesReadOnly?.map((el) => ({ key: el.key, value: el.value }))?.filter((ele) => ele?.value?.length !== 0) : null,
    };
    if (
      location?.state?.type === 'Program'
      || location?.state?.type === 'Project'
    ) {
      delete finalData.dependencies;
    }
    submitForm(finalData);
    console.log('finalData', finalData);
    // handleNext();
  };

  useEffect(() => {
    if (!correctionEditMode) {
      dispatch(addCorrectionResPeople([]));
      dispatch(addDocsInfo([]));
    }
  }, [correctionEditMode]);

  // Remove Success Message
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    }
  }, [successMessage]);

  // After Submit Redirect & Success Message
  useEffect(() => {
    if (res?.isSuccess) {
      setSuccessMessage(
        `Correction ${correctionEditMode ? 'Saved' : 'Submitted'} Successfully`,
      );
      dispatch(addIsDirty(false));
      setTimeout(() => {
        if (location?.state?.from === '/correction-read-review') {
          navigate(location?.state?.from, {
            state: {
              ...location?.state?.superParentState,
              parentState: {
                ...location?.state?.superParentState?.parentState,
                state: location?.state?.superParentState?.parentState?.state,
              },
            },
          });
        } else if (location?.state?.from === '/issue-read-review') {
          navigate(location?.state?.from, {
            state: {
              ...location?.state?.parentState,
              state: location?.state?.parentState?.state,
            },
          });
        }
        dispatch(clearDocsInfo([]));
      }, 2000);
    }
  }, [res]);

  const handleBack = (flag) => {
    if (flag === 1) {
      navigate(location?.state?.from, {
        state: {
          ...location?.state?.superParentState,
          parentState: {
            ...location?.state?.superParentState?.parentState,
            state: location?.state?.superParentState?.parentState?.state,
          },
        },
      });
      dispatch(clearDocsInfo([]));
    } else if (flag === 2) {
      navigate(location?.state?.from, {
        state: {
          ...location?.state?.parentState,
          state: location?.state?.parentState?.state,
        },
      });
      dispatch(clearDocsInfo([]));
    }
  };

  // Return Starts
  return (
    <div className="content-wrapper">
      {isError && <ErrorModal />}
      {isLoading && <LoaderComp />}
      {successMessage && (
        <AlertMessage message={successMessage} setter={setSuccessMessage} />
      )}
      <div className="card">
        <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>
            {!correctionEditMode
              ? t('correction:create_new_correction')
              : `Correction: ${correctionValues?.name}`}
          </h1>
          {(location?.state?.from === '/correction-read-review')
            && <Button className="text-decoration-none" type="button" onClick={() => handleBack(1)}>Back</Button>}
          {(location?.state?.from === '/issue-read-review')
            && <Button className="text-decoration-none" type="button" onClick={() => handleBack(2)}>Back</Button>}
        </div>

        <div className="card-body">
          <div className="form-wrapper">
            <IssueFromBuilder
              label="correction"
              template={jsonTemplate}
              onNext={onSubmit}
              modalShow={modalShow}
              setModalShow={setModalShow}
              stepReset={stepReset}
              dfVals={correctionValues || null}
              editMode={correctionEditMode}
              cancelTitle={cancelTitle}
              resRelatedObjects={null}
              correctionResPeople={correctionResPeople}
              saveDocs={saveDocs}
              docsInfo={docsInfo}
              isChangeCorrection={location?.state?.type === 'Change'}
              isEpic={location?.state?.type === 'Epic'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const CreateCorrection = React.memo(CreatesCorrection);

export default CreateCorrection;
