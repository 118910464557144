/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessValues: {},
  businessTwinVals: {},
  capabilitiesData: [],
  pullProcessBusinessCaps: [],
  businessStepCapabilitiesData: [],
  businessStepReviewCaps: [],
  processBusinessReviewCaps: [],
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    addBusinessVals: (state, { payload }) => {
      state.businessValues = payload;
    },
    addBusinessTwinVals: (state, { payload }) => {
      state.businessTwinVals = payload;
    },
    addBusinessCaps: (state, { payload }) => {
      const uniqueAppCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.capabilitiesData = uniqueAppCaps;
    },
    addBusinessStepCapabilities: (state, { payload }) => {
      state.businessStepCapabilitiesData = payload;
    },
    addPullProcessBusinessCaps: (state, { payload }) => {
      const uniqueProcessBusinessCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcessBusinessCaps = uniqueProcessBusinessCaps;
    },
    addBusinessStepReviewCaps: (state, { payload }) => {
      state.businessStepReviewCaps = payload;
    },
    addProcessBusinessReviewCaps: (state, { payload }) => {
      const uniqueProcessRWCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processBusinessReviewCaps = uniqueProcessRWCaps;
    },
    removePullProcessBusinessCaps: (state, { payload }) => {
      state.pullProcessBusinessCaps = payload && payload.length > 0 && Array.isArray(payload) ? payload : [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addBusinessVals, addBusinessCaps,
  addBusinessStepCapabilities,
  addPullProcessBusinessCaps,
  addBusinessStepReviewCaps,
  addProcessBusinessReviewCaps,
  addBusinessTwinVals,
  removePullProcessBusinessCaps,
} = businessSlice.actions;

export default businessSlice.reducer;
