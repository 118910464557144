/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CancelModal from './CancelModal';
import TablePeople from './TablePeople';
import { useGetAllPeopleQuery } from '../../services/services';
import { addPeople } from '../../services/peopleSlice';
import StepFooter from './StepFooter';

function Peoples({
  label,
  steps,
  activeStep,
  handleBack,
  handleNext,
  modalShow,
  setModalShow,
  stepReset,
  fields,
  cancelTitle,
  labelTwin,
  assigneeDisabled,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('people');
  const dispatch = useDispatch();
  const tableData = useGetAllPeopleQuery();
  const editMode = useSelector((state) => state.editData.editMode);
  const peopleData = useSelector((state) => state.peopleData.peopleVals);
  const peopleDisabled = useSelector((state) => state.peopleData.peopleDisabled);

  const [owner, setOwner] = useState(false);
  const [resPerson, setResPerson] = useState(false);
  const [stCom, setStCom] = useState(false);
  const [manager, setManager] = useState(false);
  const [subjectExp, setSubjectExp] = useState(false);
  const [sponser, setSponser] = useState(false);
  const [assignee, setAssignee] = useState(false);
  const [teamMember, setTeamMember] = useState(false);
  const [collaborate, setCollaborate] = useState(false);
  const [ownerData, setOwnerData] = useState([]);
  const [resPersonData, setResPersonData] = useState([]);
  const [subjMtExpData, setSubjMtExpData] = useState([]);
  const [strComData, setStrComData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [sponserData, setSponserData] = useState([]);
  const [assigneeData, setAssigneeData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [collaboratorData, setCollaboratorData] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableTitle, setTableTitle] = useState('');
  const [selVal, setSelVal] = useState({
    flag: false,
    val: '',
  });

  const draftMode = useSelector((state) => state.formJson.draftMode);
  const processInputDisable = useSelector((state) => state.processData.processInputDisable);
  const appInputDisable = useSelector((state) => state.applicationData.appInputDisable);
  console.log('processInputDisable', processInputDisable, appInputDisable);

  useEffect(() => {
    if ((label === 'Process' || label === 'process')) {
      if (!teamMemberData.length || !ownerData.length || !managerData.length) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [teamMemberData.length, ownerData.length, managerData.length, label]);
  useEffect(() => {
    if (label !== 'application' && label !== 'Process' && label !== 'business' && label !== 'vendor' && label !== 'customer') {
      if (!ownerData.length || !managerData.length) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [ownerData.length, managerData.length, label]);

  useEffect(() => {
    if (label === 'application' || label === 'business' || label === 'vendor' || label === 'customer' || label === 'product') {
      if (!ownerData.length || !managerData.length || !teamMemberData.length) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [ownerData.length, managerData.length, teamMemberData.length, label]);

  useEffect(() => {
    if (fields?.resp_person && peopleDisabled) {
      setResPerson(true);
      setSelVal({
        ...selVal,
        flag: false,
      });
    }
    if (fields?.resp_person && !peopleDisabled) {
      setResPerson(true);
      setSelVal({
        ...selVal,
        flag: true,
      });
    }
    if (fields?.resp_person) {
      if (fields?.resp_person && !resPersonData.length) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [fields?.resp_person, resPersonData.length]);

  useEffect(() => {
    if (fields?.owner && fields?.assignee) {
      if (!assigneeData.length || !ownerData.length) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [fields?.owner, fields?.assignee, assigneeData.length, ownerData.length]);

  useEffect(() => {
    if (peopleData?.owner) {
      setOwnerData(peopleData.owner);
    }
    if (peopleData?.resp_person) {
      setResPersonData(peopleData.resp_person);
    }
    if (peopleData?.subjMtExp) {
      setSubjMtExpData(peopleData.subjMtExp);
    }
    if (peopleData?.manager) {
      setManagerData(peopleData.manager);
    }
    if (peopleData?.stcom) {
      setStrComData([...peopleData.stcom]);
    }
    if (peopleData?.sponsor) {
      setSponserData(peopleData.sponsor);
    }
    if (peopleData?.assignee) {
      setAssigneeData(peopleData.assignee);
    }
    if (peopleData?.teams) {
      setTeamMemberData(peopleData.teams);
    }
    if (peopleData?.collaborator) {
      setCollaboratorData([...peopleData.collaborator]);
    }
  }, [peopleData]);

  console.log('label', label);

  useEffect(() => {
    if ((owner || manager) && tableData.isSuccess) {
      if (tableData.data.tableData && Array.isArray(tableData.data.tableData) && tableData.data.tableData?.length > 0) {
        if (label === 'Process' || label === 'application' || label === 'business' || label === 'vendor' || label === 'customer' || label === 'product') {
          setDataTable([...tableData.data.tableData.filter((ele) => ele?.roles && Array.isArray(ele?.roles) && ele?.roles?.length > 0 && ele?.roles?.some((ee) => ee === 'Capability Manager'))]);
        } else if (label === 'benefit' && manager) {
          setDataTable([...tableData.data.tableData]);
        } else {
          setDataTable([...tableData.data.tableData.filter((ele) => ele?.roles && Array.isArray(ele?.roles) && ele?.roles?.length > 0 && ele?.roles?.some((ee) => ee === 'Manager'))]);
        }
      }
    } else if ((!owner && !manager) && tableData.isSuccess) {
      if (tableData.data.tableData && Array.isArray(tableData.data.tableData) && tableData.data.tableData?.length > 0) {
        setDataTable([...tableData.data.tableData]);
      }
    }
  }, [owner, manager, tableData]);

  useEffect(() => {
    if (fields?.resp_person) {
      setResPerson(true);
    }
  }, [fields?.resp_person]);

  const userhandler = (e, val) => {
    e.stopPropagation();
    setSelVal({
      ...selVal,
      flag: true,
      val,
    });
    if (val === 'owner') {
      setOwner(true);
      setResPerson(false);
      setSubjectExp(false);
      setManager(false);
      setSponser(false);
      setStCom(false);
      setTeamMember(false);
      setCollaborate(false);
      setAssignee(false);
      setTableTitle(t('people:selectTitle.owner'));
    } else if (val === 'manager') {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(false);
      setManager(true);
      setSponser(false);
      setStCom(false);
      setTeamMember(false);
      setCollaborate(false);
      setAssignee(false);
      setTableTitle(t('people:selectTitle.manager'));
    } else if (val === 'stcom') {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(false);
      setManager(false);
      setSponser(false);
      setStCom(true);
      setTeamMember(false);
      setCollaborate(false);
      setAssignee(false);
      setTableTitle(t('people:selectTitle.steering_committee', `Steering Committee (${strComData?.length} Selected)`));
    } else if (val === 'teamMember') {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(false);
      setManager(false);
      setSponser(false);
      setStCom(false);
      setTeamMember(true);
      setAssignee(false);
      setCollaborate(false);
      setTableTitle(t('people:selectTitle.team_member', `Team Member (${teamMemberData?.length} Selected)`));
    } else if (val === 'collab') {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(false);
      setManager(false);
      setSponser(false);
      setStCom(false);
      setAssignee(false);
      setTeamMember(false);
      setCollaborate(true);
    } else if (val === 'resp_person') {
      setOwner(false);
      setResPerson(true);
      setSubjectExp(false);
      setManager(false);
      setSponser(false);
      setAssignee(false);
      setStCom(false);
      setTeamMember(false);
      setCollaborate(false);
    } else if (val === 'assignee') {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(false);
      setManager(false);
      setSponser(false);
      setAssignee(true);
      setStCom(false);
      setTeamMember(false);
      setCollaborate(false);
      setTableTitle(t('people:selectTitle.assignee'));
    } else if (val === 'subjectExp') {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(true);
      setManager(false);
      setSponser(false);
      setAssignee(false);
      setStCom(false);
      setTeamMember(false);
      setCollaborate(false);
      setTableTitle(t('people:selectTitle.subject_matter_expert'));
    } else {
      setOwner(false);
      setResPerson(false);
      setSubjectExp(false);
      setManager(false);
      setSponser(true);
      setStCom(false);
      setTeamMember(false);
      setCollaborate(false);
      setAssignee(false);
      setTableTitle(t('people:selectTitle.sponsor'));
    }
  };

  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (owner) {
      if (e.target.checked) {
        setOwnerData([d]);
      } else {
        setOwnerData([]);
      }
    } else if (resPerson) {
      if (e.target.checked) {
        setResPersonData([d]);
      } else {
        setResPersonData([]);
      }
    } else if (subjectExp) {
      if (e.target.checked) {
        setSubjMtExpData([d]);
      } else {
        setSubjMtExpData([]);
      }
    } else if (manager) {
      if (e.target.checked) {
        setManagerData([d]);
      } else {
        setManagerData([]);
      }
    } else if (assignee) {
      if (e.target.checked) {
        setAssigneeData([d]);
      } else {
        setAssigneeData([]);
      }
    } else if (stCom) {
      if (strComData.some((v) => v.id === d.id)) {
        setStrComData(strComData.filter((val) => val.id !== d.id));
      } else if (peopleDisabled) {
        setStrComData([...strComData, { ...d, isNew: true }]);
      } else {
        setStrComData([...strComData, d]);
      }
    } else if (teamMember) {
      if (teamMemberData.some((v) => v.id === d.id)) {
        setTeamMemberData(teamMemberData.filter((val) => val.id !== d.id));
      } else if (peopleDisabled) {
        setTeamMemberData([...teamMemberData, { ...d, isNew: true }]);
      } else {
        setTeamMemberData([...teamMemberData, d]);
      }
    } else if (sponser) {
      if (e.target.checked) {
        setSponserData([d]);
      } else {
        setSponserData([]);
      }
    } else if (collaborate) {
      if (collaboratorData.some((v) => v.id === d.id)) {
        setCollaboratorData(collaboratorData.filter((val) => val.id !== d.id));
      } else {
        setCollaboratorData([...collaboratorData, d]);
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (val === 'owner') { return setOwnerData(ownerData.filter((value) => value.id !== name.id)); }
    if (val === 'resp_person') { return setResPersonData(resPersonData.filter((value) => value.id !== name.id)); }
    if (val === 'subjectExp') { return setSubjMtExpData(subjMtExpData.filter((value) => value.id !== name.id)); }
    if (val === 'assignee') { return setAssigneeData(assigneeData.filter((value) => value.id !== name.id)); }
    if (val === 'manager') {
      return setManagerData(
        managerData.filter((value) => value.id !== name.id),
      );
    }
    if (val === 'stCom') { return setStrComData(strComData.filter((value) => value.id !== name.id)); }
    if (val === 'teamMember') {
      return setTeamMemberData(
        teamMemberData.filter((value) => value.id !== name.id),
      );
    }
    if (val === 'sponser') {
      return setSponserData(
        sponserData.filter((value) => value.id !== name.id),
      );
    }
    if (val === 'collab') {
      return setCollaboratorData(
        collaboratorData.filter((value) => value.id !== name.id),
      );
    }
  };

  const getCheckedVal = (nameVal) => {
    if (owner && ownerData.some((el) => el.id === nameVal)) return true;
    if (resPerson && resPersonData.some((el) => el.id === nameVal)) return true;
    if (subjectExp && subjMtExpData.some((el) => el.id === nameVal)) return true;
    if (manager && managerData.some((el) => el.id === nameVal)) return true;
    if (sponser && sponserData.some((el) => el.id === nameVal)) return true;
    if (assignee && assigneeData.some((el) => el.id === nameVal)) return true;
    if (stCom && strComData.some((el) => el.id === nameVal)) return true;
    if (teamMember && teamMemberData.some((el) => el.id === nameVal)) { return true; }
    if (collaborate && collaboratorData.some((el) => el.id === nameVal)) { return true; }
    return false;
  };

  useEffect(() => {
    if (owner || manager || stCom || sponser || teamMember || collaborate || assignee || subjectExp) {
      setSelVal({
        ...selVal,
        flag: true,
      });
    }
  }, [owner, manager, stCom, sponser, teamMember, collaborate, resPerson, assignee, subjectExp]);

  const componentHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (
      ownerData
      || resPersonData
      || managerData
      || strComData
      || sponserData
      || teamMemberData
      || collaboratorData
      || assigneeData
      || subjMtExpData

    ) {
      if (label === 'application' || label === 'business' || label === 'vendor' || label === 'customer') {
        const data = {
          owner:
            ownerData && ownerData?.length > 0 && Array.isArray(ownerData)
              ? ownerData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },
              }))
              : [],
          manager:
            managerData && managerData?.length > 0 && Array.isArray(managerData)
              ? managerData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },

              }))
              : [],
          stcom: [],
          sponsor: [],
          teams:
            teamMemberData
            && teamMemberData?.length > 0
            && Array.isArray(teamMemberData)
              ? teamMemberData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },

              }))
              : [],
          collaborator: [],
        };
        dispatch(addPeople(data));
      } else {
        const data = {
          owner:
            ownerData && ownerData?.length > 0 && Array.isArray(ownerData)
              ? ownerData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },
                cancelabled: el?.cancelabled,
              }))
              : [],
          assignee:
              assigneeData && assigneeData?.length > 0 && Array.isArray(assigneeData)
                ? assigneeData?.map((el) => ({
                  id: el.id,
                  email: el.email,
                  first_name: el?.first_name,
                  last_name: el?.last_name,
                  designation_id: {
                    id: el?.designation_id?.id,
                    name: el?.designation_id?.name,
                  },
                  department_id: {
                    id: el?.department_id?.id,
                    name: el?.department_id?.name,
                  },
                }))
                : [],
          resp_person:
          resPersonData && resPersonData?.length > 0 && Array.isArray(resPersonData)
            ? resPersonData?.map((el) => ({
              id: el.id,
              email: el.email,
              first_name: el?.first_name,
              last_name: el?.last_name,
              designation_id: {
                id: el?.designation_id?.id,
                name: el?.designation_id?.name,
              },
              department_id: {
                id: el?.department_id?.id,
                name: el?.department_id?.name,
              },
            }))
            : [],

          subjMtExp:
            subjMtExpData && subjMtExpData?.length > 0 && Array.isArray(subjMtExpData)
              ? subjMtExpData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },

              }))
              : [],
          manager:
            managerData && managerData?.length > 0 && Array.isArray(managerData)
              ? managerData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },
                cancelabled: el?.cancelabled,
              }))
              : [],
          stcom:
            strComData && strComData?.length > 0 && Array.isArray(strComData)
              ? strComData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },

              }))
              : [],
          sponsor:
            sponserData && sponserData?.length > 0 && Array.isArray(sponserData)
              ? sponserData.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },

              }))
              : [],
          teams:
            teamMemberData
            && teamMemberData?.length > 0
            && Array.isArray(teamMemberData)
              ? teamMemberData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },
                cancelabled: el?.cancelabled,
              }))
              : [],
          collaborator:
            collaboratorData
            && collaboratorData?.length > 0
            && Array.isArray(collaboratorData)
              ? collaboratorData?.map((el) => ({
                id: el.id,
                email: el.email,
                first_name: el?.first_name,
                last_name: el?.last_name,
                designation_id: {
                  id: el?.designation_id?.id,
                  name: el?.designation_id?.name,
                },
                department_id: {
                  id: el?.department_id?.id,
                  name: el?.department_id?.name,
                },

              }))
              : [],
        };

        dispatch(addPeople(data));
      }
      handleNext();
    }
  };

  return (
    <form action="" className="add-program-form">
      <div className="form-unit">
        <div className="container-fluid">
          <h2>
            {(editMode && !draftMode && !fields?.resp_person) ? t('people:section_title_edit')
              : (fields?.resp_person ? t('people:section_title_res_person') : t('people:section_title_add'))}

          </h2>
          <div className="row add-people">
            {fields?.owner && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="program_subj">
                    {t('people:owner')}
                    <sup>*</sup>
                    <span className="people-icon owner-icon">{t('people:ow')}</span>
                  </label>
                  <div
                    className={`form-control ${owner ? 'active' : ''}`}
                    type="text"
                    name="subject_name"
                    id="program_subj"
                  >
                    <div className="md-chips">
                      {ownerData
                        && ownerData?.length > 0
                        && Array.isArray(ownerData)
                        && ownerData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {!peopleDisabled
                              && (
                              <button
                                aria-label="remove-stcom"
                                type="button"
                                className="md-chip-remove"
                                onClick={(e) => removeHandler(e, 'owner', val)}
                              >
                                <span className="icon-close" />
                              </button>
                              )}
                          </div>
                        ))}
                    </div>
                    {!peopleDisabled
                    && (
                    <div className="input-group-append">
                      <button
                        data-test-id="test-owner"
                        type="button"
                        className="input-group-text btn btn-link btn-small"
                        onClick={(e) => userhandler(e, 'owner')}
                      >
                        {t('people:selectTitle.select')}
                      </button>
                    </div>
                    )}
                  </div>
                </div>
              </div>

            )}

            {fields?.resp_person && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="program_subj">
                  {t('people:resp_person')}
                  <sup>*</sup>
                  <span className="people-icon owner-icon">{t('people:rp')}</span>
                </label>
                <div
                  className="form-control"
                  type="text"
                  name="subject_name"
                  id="program_subj"
                >
                  <div className="md-chips">
                    {resPersonData
                        && resPersonData?.length > 0
                        && Array.isArray(resPersonData)
                        && resPersonData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {!peopleDisabled
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'resp_person', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
            )}

            {fields?.manager && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="program_subj">
                    {t('people:manager')}
                    <sup>*</sup>
                    <span className="people-icon manager-icon">{t('people:ma')}</span>
                  </label>
                  <div
                    className={`form-control ${manager ? 'active' : ''}`}
                    type="text"
                    name="subject_name"
                    id="program_subj"
                  >
                    <div className="md-chips">
                      {managerData
                        && managerData?.length > 0
                        && Array.isArray(managerData)
                        && managerData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {!peopleDisabled
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'manager', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                    </div>
                    {!peopleDisabled
                    && (
                    <div className="input-group-append">
                      <button
                        data-test-id="test-manager"
                        type="button"
                        className="input-group-text btn btn-link btn-small"
                        onClick={(e) => userhandler(e, 'manager')}
                      >
                        {t('people:selectTitle.select')}
                      </button>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {fields?.subject_matter_expert && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="program_subj">
                  {t('people:subject_matter_expert')}
                  <span className="people-icon manager-icon">{t('people:sme')}</span>
                </label>
                <div
                  className={`form-control ${subjectExp ? 'active' : ''}`}
                  type="text"
                  name="subject_name"
                  id="program_subj"
                >
                  <div className="md-chips">
                    {subjMtExpData
                        && subjMtExpData?.length > 0
                        && Array.isArray(subjMtExpData)
                        && subjMtExpData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {!peopleDisabled
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'subjectExp', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                  </div>
                  {!peopleDisabled
                  && (
                  <div className="input-group-append">
                    <button
                      data-test-id="test-subjectExp"
                      type="button"
                      className="input-group-text btn btn-link btn-small"
                      onClick={(e) => userhandler(e, 'subjectExp')}
                    >
                      {t('people:selectTitle.select')}
                    </button>
                  </div>
                  )}
                </div>
              </div>
            </div>
            )}

            {fields?.sponsor && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="program_uid">
                    {t('people:sponsor')}
                    <span className="people-icon sponsor-icon">{t('people:sp')}</span>
                  </label>
                  <div
                    className={`form-control ${sponser ? 'active' : ''}`}
                    type="text"
                    name="unique_"
                    id="program_subj"
                  >
                    <div className="md-chips">
                      {sponserData
                        && sponserData?.length > 0
                        && Array.isArray(sponserData)
                        && sponserData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {!peopleDisabled
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'sponser', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                    </div>

                    <div className="input-group-append">
                      {!peopleDisabled
                      && (
                      <button
                        data-test-id="test-sponsor"
                        type="button"
                        className="input-group-text btn btn-link btn-small"
                        onClick={(e) => userhandler(e, 'sponser')}
                      >
                        {t('people:selectTitle.select')}
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {fields?.assignee && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="program_uid">
                  {t('people:assignee')}
                  <sup>*</sup>
                  <span className="people-icon sponsor-icon">{t('people:as')}</span>
                </label>
                <div
                  className={`form-control ${assignee ? 'active' : ''}`}
                  type="text"
                  name="unique_"
                  id="program_subj"
                >
                  <div className="md-chips">
                    {assigneeData
                        && assigneeData?.length > 0
                        && Array.isArray(assigneeData)
                        && assigneeData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {!peopleDisabled
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'assignee', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                  </div>

                  <div className="input-group-append">
                    {!peopleDisabled
                    && (
                    <button
                      data-test-id="test-assignee"
                      type="button"
                      className="input-group-text btn btn-link btn-small"
                      onClick={(e) => userhandler(e, 'assignee')}
                    >
                      {t('people:selectTitle.select')}
                    </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            )}

            {fields?.stCom && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="program_uid">
                  {t('people:steering_committee')}
                  <span className="people-icon committee-icon">{t('people:sc')}</span>
                </label>
                <div
                  className={`form-control ${stCom ? 'active' : ''}`}
                  type="text"
                  name="unique_"
                  id="program_subj"
                >
                  <div className="md-chips">
                    {strComData
                        && strComData?.length > 0
                        && Array.isArray(strComData)
                        && strComData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {(!peopleDisabled || val.isNew)
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'stCom', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                  </div>
                  <div className="input-group-append">
                    <button
                      data-test-id="test-stCom"
                      type="button"
                      className="input-group-text btn btn-link btn-small"
                      onClick={(e) => userhandler(e, 'stcom')}
                    >
                      {t('people:selectTitle.select')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            )}

            {fields?.teams && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="program_uid">
                    {t('people:team_member')}
                    {((label === 'Process' || label === 'process' || label === 'application' || label === 'business' || label === 'vendor' || label === 'customer' || label === 'product')) && <sup>*</sup>}
                    <span className="people-icon sponsor-icon">{t('people:tm')}</span>
                  </label>
                  <div
                    className={`form-control ${teamMember ? 'active' : ''}`}
                    type="text"
                    name="unique_"
                    id="program_subj"
                  >
                    <div className="md-chips">
                      {teamMemberData
                        && teamMemberData?.length > 0
                        && Array.isArray(teamMemberData)
                        && teamMemberData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            {(!peopleDisabled || val.isNew)
                            && (
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'teamMember', val)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="input-group-append">
                      <button
                        data-test-id="test-teamMember"
                        type="button"
                        className="input-group-text btn btn-link btn-small"
                        onClick={(e) => userhandler(e, 'teamMember')}
                      >
                        {t('people:selectTitle.select')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {fields?.collaborator && (
              <div className="col-sm-6">
                <div className="form-group">
                  {t('people:collaborator')}
                  <div
                    className={`form-control ${collaborate ? 'active' : ''}`}
                    type="text"
                    name="unique_"
                    id="program_subj"
                  >
                    <div className="md-chips">
                      {collaboratorData
                        && collaboratorData?.length > 0
                        && Array.isArray(collaboratorData)
                        && collaboratorData.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val.first_name || ''} ${val.last_name || ''}`}</span>
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'collab', val)}
                            >
                              <span className="icon-close" />
                            </button>
                          </div>
                        ))}
                    </div>
                    <div className="input-group-append">
                      <button
                        data-test-id="test-collab"
                        type="button"
                        className="input-group-text btn btn-link btn-small"
                        onClick={(e) => userhandler(e, 'collab')}
                      >
                        {t('people:selectTitle.select')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <hr />
          <h2>{teamMember ? t(`Team Member (${teamMemberData?.length} Selected)`) : stCom ? t(`Steering Committee (${strComData?.length} Selected)`) : tableTitle || (fields?.resp_person ? t('people:selectTitle.resp_person') : t('people:selectTitle.common'))}</h2>
          <TablePeople
            checkHandler={checkHandler}
            userData={dataTable}
            getCheckedVal={getCheckedVal}
            selVal={selVal}
            ownerData={ownerData}
            subjMtExpData={subjMtExpData}
            assigneeData={assigneeData}
            managerData={managerData}
            strComData={strComData}
            sponsorData={sponserData}
            teamMemberData={teamMemberData}
            resPersonData={resPersonData}
            disabled
            tableMessage={t('people:table_message')}
          />

          <CancelModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            cancelTitle={cancelTitle}
            func={stepReset}
          />
          <StepFooter
            label={label}
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            componentHandler={componentHandler}
            modalShow={modalShow}
            setModalShow={setModalShow}
            nextDisabled={nextDisabled}
          />
        </div>
      </div>
    </form>
  );
}

const People = React.memo(Peoples);
export default People;
