/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import React, { lazy } from 'react';
import DashChart from './components/DashChart';
import GanttChart from './components/GanntChart';
import LoginTest from './components/LoginTest';
import AccessDenied from './screens/dashboard/AccessDenied';
import BusinessTwin from './screens/business/BusinessTwin';
import BusinessTwinReadMode from './screens/business/BusinessTwinReadMode';
import ProductTwin from './screens/product/ProductTwin';
import ProductTwinReadMode from './screens/product/ProductTwinReadMode';
import VendorTwin from './screens/vendor/VendorTwin';
import CustomerTwinReadMode from './screens/Customer/CustomerTwinReadMode';
import VendorTwinReadMode from './screens/vendor/VendorTwinReadMode';
import CustomerTwin from './screens/Customer/CustomerTwin';
import Requirement from './screens/requirement/Requirement';
import EpicTask from './screens/epicTask/EpicTask';
import IssueReadReview from './screens/generateIssue/IssueReadReview';
import CreateIssue from './screens/generateIssue/Issue/IssueForm';
import CreateImpact from './screens/generateIssue/Impact/ImpactForm';
import CreateCorrection from './screens/generateIssue/Correcttion/CorrectionForm';
import CorrectionReadReview from './screens/generateIssue/Correcttion/CorrectionReadReview';
import ImpactReadReview from './screens/generateIssue/Impact/ImpactReadReview';

const Dashboard = lazy(() => import('./screens/dashboard/Dashboard'));
const HomeComponenet = lazy(() => import('./screens/dashboard/Home'));

const Process = lazy(() => import('./screens/process/Process'));
const ProcessTwinEdit = lazy(() => import('./screens/process/ProcessTwin/ProcessTwinEdit'));

const Programs = lazy(() => import('./screens/program/Programs'));
const AuthCallback = lazy(() => import('./components/common/AuthCallBack'));
const Tree = lazy(() => import('./temporary/Tree'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const RiskComponent = lazy(() => import('./screens/risk'));
const BenefitComponent = lazy(() => import('./screens/benefits'));
const ReadReviewComponent = lazy(() => import('./components/ReadReview'));
const OnTabReview = lazy(() => import('./components/OnTabReview'));
const Projects = lazy(() => import('./screens/projects/Projects'));
const Change = lazy(() => import('./screens/change/Change'));
const Applications = lazy(() => import('./screens/application/Applications'));
const Business = lazy(() => import('./screens/business/Business'));
const Product = lazy(() => import('./screens/product/Product'));
const Vendor = lazy(() => import('./screens/vendor/Vendor'));
const Customer = lazy(() => import('./screens/Customer/Customer'));
const Review = lazy(() => import('./components/common/Review'));
const Kanban = lazy(() => import('./screens/kanban/Kanban'));
const ChangeReadView = lazy(() => import('./screens/ungrouped/ChangeReadView'));
const BODComponent = lazy(() => import('./admin/enums'));
const AppTwin = lazy(() => import('./screens/digitalTwin'));
const AppTwinReadView = lazy(() => import('./screens/ungrouped/AppTwinReadMode'));
const ReadModeChange = lazy(() => import('./screens/change/ReadModeChange'));
const ReadModeProcess = lazy(() => import('./screens/process/ReadModeProcess'));
const Compare = lazy(() => import('./screens/change/readmode/Compare'));
const Epic = lazy(() => import('./screens/epic/Epic'));
const AdminDashoard = lazy(() => import('./screens/admin/AdminDashoard'));
const AddTranslation = lazy(() => import('./components/language/AddTranslation'));
const TransformationSettings = lazy(() => import('./admin/TransformationSettings'));
const CapabilitySettings = lazy(() => import('./admin/CapabilitySettings'));
const StatusConfig = lazy(() => import('./admin/StatusConfig'));
const GeneralSettings = lazy(() => import('./screens/admin/GeneralSettings'));
const TenantSettings = lazy(() => import('./admin/TenantSettings'));
const AllNotifications = lazy(() => import('./screens/dashboard/AllNotifications'));
const SolSpecs = lazy(() => import('./screens/solution-specification/SolutionSpecification'));
const Delegation = lazy(() => import('./screens/admin/Delegation'));

const ProjectPortfolio = lazy(() => import('./components/project_portfolio/index'));
const DigitalTwin = lazy(() => import('./components/digital_twin/TwinBox'));
const GenerateIssue = lazy(() => import('./screens/generateIssue/GenerateIssue'));

export const publicRoutes = [
  // { path: '/', Component: LoginTwin },
  { path: '/', Component: LoginTest },
  { path: '*', Component: PageNotFound },
  { path: '/callback', Component: AuthCallback },
  { path: '/unauthorizedAccess', Component: AccessDenied },
];

export const privateRoutes = [
  { path: '/readmode', Component: Review, label: ['readmode'] },
  { path: '/all-notifications', Component: AllNotifications, label: ['readmode'] },
  { path: '/solution-specification', Component: SolSpecs, label: ['readmode'] },
  // {
  //   path: '/dashboard', Component: Dashboard, title: 'Dashboard', label: ['dashboard'],
  // },
  {
    path: '/dashboard', Component: HomeComponenet, title: 'Dashboard', label: ['dashboard'],
  },
  {
    path: '/admindashboard', Component: AdminDashoard, title: 'Admin Dashboard', label: ['create:config'],
  },
  {
    path: '/language', Component: AddTranslation, title: 'Manage Language & Translation', label: ['create:config'],
  },
  {
    path: '/programs/', Component: Programs, title: 'Create Program', label: ['create:program', 'update:program', 'view:program'],
  },
  {
    path: '/process', Component: Process, title: 'Create Process', label: ['create:process', 'update:process', 'view:process'],
  },
  {
    path: '/processTwinUpdate', Component: ProcessTwinEdit, title: 'Process', label: ['create:process', 'update:process', 'view:process', 'create:processTwin', 'update:processTwin', 'view:processTwin'],
  },
  {
    path: '/change', Component: Change, title: 'Create Change', label: ['create:change', 'update:change', 'view:change'],
  },
  {
    path: '/projects', Component: Projects, title: 'Create Project', label: ['create:project', 'update:project', 'view:project'],
  },
  {
    path: '/risk', Component: RiskComponent, title: 'Create Risk', label: ['create:risk', 'update:risk', 'view:risk'],
  },
  {
    path: '/benefit', Component: BenefitComponent, title: 'Create Benefit', label: ['create:benefit', 'update:benefit', 'view:benefit'],
  },
  {
    path: '/application', Component: Applications, title: 'Create Application', label: ['create:application', 'view:application', 'update:application'],
  },
  {
    path: '/business', Component: Business, title: 'Create Business', label: ['business', 'create:business', 'view:business', 'update:business'],
  },
  {
    path: '/product', Component: Product, title: 'Create Product', label: ['product', 'create:product', 'view:product', 'update:product'],
  },
  {
    path: '/vendor', Component: Vendor, title: 'Create Vendor', label: ['vendor', 'create:vendor', 'view:vendor', 'update:vendor'],
  },
  {
    path: '/customer', Component: Customer, title: 'Create Customer', label: ['customer', 'create:customer', 'view:customer', 'update:customer'],
  },
  {
    path: '/epic', Component: Epic, title: 'Create Work Package', label: ['create:epic', 'update:epic', 'view:epic'],
  },
  {
    path: '/epic-task', Component: EpicTask, title: 'Create Work Package Task', label: ['create:epic', 'update:epic', 'view:epic'],
  },
  {
    path: '/requirement', Component: Requirement, title: 'Create Requirement', label: ['create:requirement', 'update:requirement', 'view:requirement'],
  },
  {
    path: '/readReview', Component: ReadReviewComponent, title: 'Read Review', label: ['readmode'],
  },
  {
    path: '/review/:slug/:id', Component: OnTabReview, title: 'Read Review', label: ['readmode'],
  },
  { path: '/tree-view', Component: Tree },
  {
    path: '/collaborate', Component: Kanban, title: 'collaborate', label: ['readmode'],
  },
  {
    path: '/transformation-settings', Component: TransformationSettings, title: 'Transformation Settings', label: ['create:config'],
  },
  {
    path: '/capability-settings', Component: CapabilitySettings, title: 'Capability Settings', label: ['create:config'],
  },
  {
    path: '/status-config', Component: StatusConfig, title: 'Status Config', label: ['create:config'],
  },
  {
    path: '/general-settings', Component: GeneralSettings, title: 'General Settings', label: ['create:config'],
  },
  {
    path: '/tenant-settings', Component: TenantSettings, title: 'Tenant Settings', label: ['create:config'],
  },

  { path: '/changeReadView', Component: ChangeReadView, label: ['create:change', 'update:change', 'view:change', 'readmode'] },
  { path: '/seed-data-settings', Component: BODComponent, label: ['readmode'] },
  { path: '/appTwin', Component: AppTwin, label: ['create:application', 'update:application', 'view:application', 'create:appTwin', 'update:appTwin', 'view:appTwin'] },
  { path: '/businessTwin', Component: BusinessTwin, label: ['create:business', 'update:business', 'view:business', 'create:businessTwin', 'update:businessTwin', 'view:businessTwin'] },
  { path: '/productTwin', Component: ProductTwin, label: ['create:product', 'update:product', 'view:product', 'create:productTwin', 'update:productTwin', 'view:productTwin'] },
  { path: '/customerTwin', Component: CustomerTwin, label: ['create:customer', 'update:customer', 'view:customer', 'create:customerTwin', 'update:customerTwin', 'view:customerTwin'] },
  { path: '/vendorTwin', Component: VendorTwin, label: ['create:vendor', 'update:vendor', 'view:vendor', 'create:vendorTwin', 'update:vendorTwin', 'view:vendorTwin'] },
  { path: '/readModeChange', Component: ReadModeChange, label: ['create:change', 'update:change', 'view:change', 'readmode'] },
  { path: '/readModeProcess', Component: ReadModeProcess, label: ['create:process', 'update:process', 'view:process', 'readmode'] },
  { path: '/appTwinReadView', Component: AppTwinReadView, label: ['create:appTwin', 'update:appTwin', 'view:appTwin', 'readmode'] },
  { path: '/businessTwinReadView', Component: BusinessTwinReadMode, label: ['create:businessTwin', 'update:businessTwin', 'view:businessTwin', 'readmode'] },
  { path: '/productTwinReadView', Component: ProductTwinReadMode, label: ['create:productTwin', 'update:productTwin', 'view:productTwin', 'readmode'] },
  { path: '/customerTwinReadView', Component: CustomerTwinReadMode, label: ['create:customerTwin', 'update:customerTwin', 'view:customerTwin', 'readmode'] },
  { path: '/vendorTwinReadView', Component: VendorTwinReadMode, label: ['create:vendorTwin', 'update:vendorTwin', 'view:vendorTwin', 'readmode'] },
  { path: '/change-compare', Component: Compare, label: ['readmode'] },
  { path: '/chart', Component: DashChart, label: ['readmode'] },
  { path: '/gantt', Component: GanttChart, label: ['readmode'] },
  { path: '/delegation', Component: Delegation, label: ['readmode'] },

  { path: '/project_portfolio', Component: ProjectPortfolio, label: ['dashboard'] },
  { path: '/digital_twin', Component: DigitalTwin, label: ['dashboard'] },

  { path: '/issue-read-review', Component: IssueReadReview, label: ['dashboard'] },
  { path: '/correction-read-review', Component: CorrectionReadReview, label: ['dashboard'] },
  { path: '/impact-read-review', Component: ImpactReadReview, label: ['dashboard'] },
  { path: '/create-issue', Component: CreateIssue, label: ['dashboard'] },
  { path: '/create-impact', Component: CreateImpact, label: ['dashboard'] },
  { path: '/create-correction', Component: CreateCorrection, label: ['dashboard'] },
];
