/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import '../../scss/customModal.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Alert from '../../images/alert3.png';

function ErrorModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const handle = () => {
    dispatch({ type: 'RESET_APP' });
    setHide(true);
    navigate('/dashboard');
  };
  return (
    <div className="error_modal_frame">
      <div className={`error_modal ${hide ? 'hide' : ''}`}>
        <img src={Alert} width="44" height="38" />
        <span className="title">Something went Wrong !!</span>
        <p>Please contact Admin.</p>
        <div className="button" onClick={handle}>Dismiss</div>
      </div>
    </div>
  );
}

export default ErrorModal;
