import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useGetMultiDownoadUrlsMutation } from '../../services/services';

function ReadMoreLessDocument({ doc, field }) {
  const [visible, setVisible] = useState(5);
  const [showMore, setShowMore] = useState(false);
  const handleVisiled = () => {
    if (!showMore) {
      setVisible(doc?.length);
      setShowMore(true);
    } else {
      setVisible(5);
      setShowMore(false);
    }
  };

  const [getUrl, respo] = useGetMultiDownoadUrlsMutation();

  const downLoadFile = (itm) => {
    getUrl({ fileName: itm.original_name, keyName: itm.s3_key_name });
  };

  useEffect(() => {
    if (respo.isSuccess) {
      const link = document.createElement('a');
      link.download = respo.data.key_name;
      link.href = respo.data.pre_signed_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [respo.isSuccess]);

  return (
    <>
      {doc?.slice(0, visible)?.map((item) => (
        <Button
          variant="link"
          onClick={(e) => downLoadFile(item)}
          href={item?.pre_signed_url}
          key={item?.original_name}
        >
          {item.original_name}
        </Button>
      ))}
      {!showMore && doc && Array.isArray(doc) && doc?.length > 5 && '...'}
      {doc?.length > 5 && showMore && <Button className="text-decoration-none" variant="link" type="button" onClick={() => handleVisiled()}>less</Button>}
      {doc?.length > 5 && !showMore && <Button className="text-decoration-none" variant="link" type="button" onClick={() => handleVisiled()}>more</Button>}
    </>
  );
}

export default ReadMoreLessDocument;
