/* eslint-disable max-len */
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import data from './barChart.json';

function BarChart() {
  const columns = ['group', 'full', 'partial', 'free'];
  useEffect(() => {
    // set the dimensions and margins of the graph
    const margin = {
      top: 10, right: 30, bottom: 20, left: 50,
    };
    const width = 450 - margin.left - margin.right;
    const height = 278 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select('#barplot')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const subgroups = columns.slice(1);

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = data.map((d) => d.group);

    // Add X axis
    const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickSizeOuter(0));

    // Add Y axis
    const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);
    svg.append('g').call(d3.axisLeft(y));

    // color palette = one color per subgroup
    const color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(['#3D9BD1', '#81D1FF', '#AFE2FF']);

    // stack the data? --> stack per subgroup
    const stackedData = d3.stack().keys(subgroups)(data);

    // Show the bars
    svg
      .append('g')
      .selectAll('g')
    // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .join('g')
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
    // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d) => d)
      .join('rect')
      .attr('x', (d) => x(d.data.group))
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth());

    // Label for Y-axis
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Number of people');
    // });
  }, []);
  return (
    <>
      <h2>People Demand</h2>
      <div id="barplot" />
      <div className="chart-notations no-color">
        <ul className="list-unstyled mb-0 justify-content-center">
          <li>
            <span className="chart-note full" />
            Fully occupied
          </li>
          <li>
            <span className="chart-note partial" />
            Partially Occupied
          </li>
          <li>
            <span className="chart-note free" />
            Free
          </li>
        </ul>
      </div>
    </>
  );
}

export default BarChart;
