import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  epicTaskVals: {},
  epicCapsMapping: [],
  selectedEpicTask: [],
  selectedDepndentEpicTask: [],
  dependentEpicTask: [],
};

export const epicTaskSlice = createSlice({
  name: 'epicTaskSlice',
  initialState,
  reducers: {
    addEpicTaskVals: (state, { payload }) => {
      state.epicTaskVals = payload;
    },
    addEpicCapsMapping: (state, { payload }) => {
      state.epicCapsMapping = payload;
    },
    addSelectedEpicTask: (state, { payload }) => {
      state.selectedEpicTask = payload;
    },
    addDependentEpicTask: (state, { payload }) => {
      state.dependentEpicTask = payload;
    },
    addSelectedDependentEpicTask: (state, { payload }) => {
      state.selectedDepndentEpicTask = payload;
    },
  },
});

export const {
  addEpicTaskVals,
  addEpicCapsMapping,
  addSelectedEpicTask,
  addDependentEpicTask,
  addSelectedDependentEpicTask,
} = epicTaskSlice.actions;

export default epicTaskSlice.reducer;
