/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCorrectionJsonQuery } from '../../../services/services';
import ReadMoreLessDocument from '../../../components/common/ReadMoreLessDoc';
import ReadMoreLess from '../../../components/common/ReadMoreLess';
import InnerLoader from '../../../components/InnerLoader';
import { setCorrectionEditMode } from '../../../services/issueSlice';
import CorrectReadOnlyCapability from './CorrectionReadOnlyCapability';
import EpicIssueCapabilitiesReadOnly from '../EpicIssueCapabilityReadOnly';

function CorrectionReview({ label }) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('correction');
  const formJson = useGetCorrectionJsonQuery({}, { refetchOnMountOrArgChange: true });
  const correctionValues = useSelector((state) => state.issueData.correctionValues);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const correctionResPeople = useSelector((state) => state.issueData.correctionResPeople);

  console.log('correctionValues', correctionValues, docsInfo);

  const [jsonTemplate, setJsonTemplate] = useState('');
  console.log('jsonTemplate', jsonTemplate);
  const [loader, setLoader] = useState(true);

  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (formJson?.isSuccess && correctionValues && formJson?.data?.fields && Array.isArray(formJson?.data?.fields) && formJson?.data?.fields?.length > 0) {
      const updated = [...formJson.data.fields];
      Object.entries(correctionValues).forEach((tv) => updated.forEach((dt, i) => {
        if (dt.field_name === tv[0]) {
          if (dt.field_name === 'priority' || dt.field_name === 'issue_type') {
            updated.splice(i, 1, { ...dt, value: { label: correctionValues[dt.field_name], value: correctionValues[dt.field_name] } });
          } else if (dt.field_name === 'status') {
            updated.splice(i, 1, { ...dt, value: { label: correctionValues?.status, value: correctionValues?.status } });
          } else {
            updated.splice(i, 1, { ...dt, value: correctionValues[dt.field_name] });
          }
        }
      }));
      const updatedSections = [];

      updated.forEach((el) => {
        if (!updatedSections.some((se) => se.id === el.section_title.id)) {
          updatedSections.push(el.section_title);
        }
      });
      console.log('updated', updated);
      setJsonTemplate({ fields: (updated && Array.isArray(updated) && updated.length > 1) ? updated : [], sections: updatedSections });
    }
  }, [formJson?.isSuccess]);

  const handleEdit = (e) => {
    dispatch(setCorrectionEditMode(true));
    navigate('/create-correction', {
      state: {
        id: location?.state?.id,
        type: location?.state?.type,
        from: location?.pathname,
        superParentState: location?.state,
      },
    });
  };

  useEffect(() => {
    if (correctionValues
      && jsonTemplate?.fields
      && Array.isArray(jsonTemplate?.fields)
      && jsonTemplate?.fields?.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [correctionValues, jsonTemplate]);

  return (
    loader
      ? <InnerLoader />
      : (
        <form action="" className="add-program-form">
          <div className="action_button_parent">

            <div className="d-flex justify-content-end mt-2">
              <button type="button" className="action-button mb-2 mb-md-0 btn-primary" onClick={(e) => handleEdit(e)}>
                <div className="action-button-icon d-flex align-items-center justify-content-center">
                  <span className="icon-edit" />
                </div>
                <span className="action_text">Edit</span>
              </button>
            </div>
          </div>
          <div className="form-unit">
            <div className="container-fluid">
              <h2 className="h1">
                {' '}
                Details
              </h2>
              {(jsonTemplate?.fields && Array.isArray(jsonTemplate?.fields) && jsonTemplate?.fields?.length > 0)
              && jsonTemplate?.sections?.map((tl, i) => (
                <Fragment key={tl?.id}>
                  <h2 className="section-title">{t(`section_title.${tl?.name}`)}</h2>
                  <div className="row">
                    {jsonTemplate?.fields?.map(
                      (fl) => tl?.id === fl?.section_title.id && (
                        <Fragment key={fl?.id}>
                          {jsonTemplate?.fields?.some((el) => el?.id === fl?.id && el?.field_name === 'organizations') && <div className="w-100" />}
                          {jsonTemplate?.fields?.some((el) => el?.id === fl?.id && el?.field_name === 'consequences_factor') && <div className="w-100" />}

                          <div key={fl.id} className="col-6 col-lg-4">

                            <div className="form-group">
                              <label htmlFor="program_subj">{t(t(`${fl.field_name}`).name)}</label>
                              <p className="readonly-text font-weight-bold">
                                {(((fl.field_data_type === 'text' && fl?.section_title?.name !== 'budget') || ((fl.field_data_type === 'readonly' && fl?.section_title?.name !== 'budget') || (fl.field_data_type === 'readOnly' && fl?.section_title?.name !== 'budget'))) && fl.value) ? fl.value
                                  : (fl.field_data_type === 'date' && fl?.value) ? dayjs(fl.value).format(userData?.tenant_info?.date_format)
                                    : ((fl.field_data_type === 'select' && fl.field_type !== 'Custom') && fl.value?.label) ? fl.value?.label
                                      : (fl.field_data_type === 'file'
                                          && fl.value !== null && Array.isArray(fl.value))
                                        ? (
                                          <ReadMoreLessDocument field={fl} doc={fl?.value} />
                                        )
                                        : fl.field_data_type === 'textarea' && fl?.value ? <ReadMoreLess text={fl?.value} />
                                          : fl.field_data_type === 'texteditor'
                                            ? (
                                              <Editor
                                                editorState={fl?.value ? EditorState.createWithContent(
                                                  ContentState.createFromBlockArray(
                                                    htmlToDraft(fl?.value),
                                                  ),
                                                ) : EditorState.createEmpty()}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                toolbarHidden
                                              />
                                            )
                                            : (fl?.field_data_type === 'text' && fl?.section_title?.name === 'budget') ? (fl?.value ? `${userData?.tenant_info?.currency_code} ${fl.value}` : '0')
                                              : ((fl.field_data_type === 'readonly' && fl?.section_title?.name === 'budget') || (fl.field_data_type === 'readOnly' && fl?.section_title?.name === 'budget')) ? (fl.value ? `${userData?.tenant_info?.currency_code} ${fl.value}` : '0')
                                                : (fl.field_data_type === 'select' && fl.field_type === 'Custom') ? fl?.value
                                                  : (fl.field_data_type === 'textarea' && fl.field_type === 'Custom') ? <ReadMoreLess text={fl?.value} />
                                                    : (fl?.field_name === 'defect_status' && epicValues?.status === 'Test' && epicValues.defect_status) ? fl?.value
                                                      : '-'}
                              </p>
                            </div>

                          </div>
                        </Fragment>
                      ),
                    )}
                  </div>
                  <hr />
                </Fragment>
              ))}

              <h2 className="h1">People</h2>
              <div className="row">
                <div className="col-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="program_subj">Responsible</label>
                    {correctionResPeople
                && Array.isArray(correctionResPeople)
                && correctionResPeople?.length > 0 ? (
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {correctionResPeople?.map((ow) => (
                      <span key={ow?.id}>
                        {`${ow?.first_name || ''} ${
                          ow?.last_name || ''
                        }`}

                      </span>
                    ))}
                  </p>
                      ) : (
                        '-'
                      )}
                  </div>
                </div>
              </div>
              <hr />

              {(location?.state?.type === 'Change')
              && <CorrectReadOnlyCapability />}

              {location?.state?.type === 'Epic'
            && <EpicIssueCapabilitiesReadOnly />}

              <div className="grey-bg">
                <h2>Related Dates and People</h2>
                <div className="row">
                  <div className="col-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="program_">Created</label>
                      <p className="readonly-text font-weight-bold">
                        {correctionValues?.created ? dayjs(correctionValues?.created).format(userData?.tenant_info?.date_format) : '-'}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="program_">Updated</label>
                      <p className="readonly-text font-weight-bold">
                        {correctionValues?.updated ? dayjs(correctionValues?.updated).format(userData?.tenant_info?.date_format) : '-'}
                      </p>
                    </div>
                  </div>
                  <div className="w-100" />
                  <div className="col-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="program_">Created By</label>
                      <p className="readonly-text font-weight-bold text-capitalize">
                        {correctionValues?.created_by ? `${correctionValues?.created_by?.first_name || ''} ${correctionValues?.created_by?.last_name || ''}` : '-'}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="program_">Updated By</label>
                      <p className="readonly-text font-weight-bold text-capitalize">
                        {correctionValues?.updated_by ? `${correctionValues?.updated_by?.first_name || ''} ${correctionValues?.updated_by?.last_name || ''}` : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </form>
      )

  );
}

export default CorrectionReview;
