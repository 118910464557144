/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  addRelatedChangeData,
  addRelatedEpicData,
  addRelatedInitData,
  addRelatedProcessData,
  addRelatedProgData,
} from '../../services/formSlice';
import EpicChangeModal from '../dependencies/EpicChangeModal';
import TransformationModal from '../dependencies/TransformationModal';
import RequirementModal from '../dependencies/RequirementModal';
import { addDependentRequirement, addSelectedEpicRequirement } from '../../services/requirementSlice';
import DependentEpicTaskModal from '../dependencies/DependentEpicTaskModal';
import { addDependentEpicTask } from '../../services/epicTaskSlice';

function RelatedSelect({
  label,
  title,
  isChangeRequire,
  handleChange,
  isTwin,
}: any): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const [modal, setModal] = useState({});

  const relatedProgData = useSelector(
    (state) => state.formJson.relatedProgData,
  );
  const relatedInitData = useSelector(
    (state) => state.formJson.relatedInitData,
  );
  const relatedChange = useSelector(
    (state) => state.formJson.relatedChangeData,
  );
  const relatedProcess = useSelector(
    (state) => state.formJson.relatedProcessData,
  );
  const selectedInitProgram = useSelector(
    (state) => state.treeData.selectedInitProgram,
  );
  const relatedEpicData = useSelector(
    (state) => state.formJson.relatedEpicData,
  );
  const editMode = useSelector((state) => state.editData.editMode);
  const draftMode = useSelector((state) => state.formJson.draftMode);
  const epicInputDisable = useSelector(
    (state) => state.epicData.epicInputDisable,
  );
  const changeInputDisable = useSelector(
    (state) => state.rcData.changeInputDisable,
  );
  const inputDisable = useSelector((state) => state.formJson.inputDisable);
  const processInputDisable = useSelector(
    (state) => state.processData.processInputDisable,
  );
  const programValues = useSelector((state) => state.formJson.programValues);
  const projectValues = useSelector((state) => state.initData.initValues);
  const rcValues = useSelector((state) => state.rcData.rcValues);
  const riskValues = useSelector((state) => state.riskData.riskValues);
  const benefitValues = useSelector((state) => state.benefitData.benefitValues);
  const dependentRequirement = useSelector((state) => state.requirementData.dependentRequirement);
  const selectedEpicRequirement = useSelector((state) => state.requirementData.selectedEpicRequirement);
  const selectedTwinRequirement = useSelector((state) => state.requirementData.selectedTwinRequirement);
  console.log('selectedEpicRequirement', selectedEpicRequirement);
  const isTwinMode = useSelector((state) => state.formJson.isTwinMode);
  const dependentEpicTask = useSelector((state) => state.epicTaskData.dependentEpicTask);

  useEffect(() => {
    if (selectedInitProgram) {
      relatedProgData?.filter((el) => el.id === selectedInitProgram);
    }
  }, [selectedInitProgram]);

  const { t } = useTranslation('related_select');

  const handleClose = () => {
    setModal({
      ...modal,
      key: '',
      title: '',
      show: false,
    });
  };

  const handleShow = (item) => {
    if (item.title === 'dependent_epic') {
      setModal({
        ...item,
        title: 'Dependent Work Package Predecessor',
      });
    } else if (item.title === 'dependent_work_package_task') {
      setModal({
        ...item,
        title: 'Dependent Work Package Task Predecessor',
      });
    } else if (item.title === 'dependent_requirement') {
      setModal({
        ...item,
        title: 'Dependent Requirement Predecessor',
      });
    } else if (item.title === 'initiative') {
      setModal({
        ...item,
        title: 'Project',
      });
    } else if (item.title === 'appTwin') {
      setModal({
        ...item,
        title: 'Supplier',
      });
    } else if (item.title === 'epic') {
      setModal({
        ...item,
        title: 'Change',
      });
    } else if (item.title === 'requirement') {
      setModal({
        ...item,
        title: 'Requirements',
      });
    } else {
      setModal(item);
    }
  };

  const removeHandler = (e, lbl, name) => {
    e.stopPropagation();
    if (lbl === 'program') {
      dispatch(
        addRelatedProgData(
          relatedProgData.filter((value) => value.id !== name.id),
        ),
      );
    }
    if (lbl === 'initiative') {
      dispatch(
        addRelatedInitData(
          relatedInitData.filter((value) => value.id !== name.id),
        ),
      );
    }
    if (lbl === 'epic') {
      dispatch(
        addRelatedChangeData(
          relatedChange.filter((value) => value.id !== name.id),
        ),
      );
      dispatch(
        addSelectedEpicRequirement([]),
      );
    }
    if (lbl === 'change') {
      dispatch(
        addRelatedChangeData(
          relatedChange.filter((value) => value.id !== name.id),
        ),
      );
    }
    if ((lbl === 'requirement' || label === 'dependent_requirement') && location?.pathname !== '/requirement' && !isTwin) {
      dispatch(
        addSelectedEpicRequirement(
          selectedEpicRequirement.filter((value) => value.id !== name.id),
        ),
      );
    }
    if ((lbl === 'requirement' || label === 'dependent_requirement') && location?.pathname === '/requirement') {
      dispatch(
        addDependentRequirement(
          dependentRequirement.filter((value) => value.id !== name.id),
        ),
      );
    }
    if ((lbl === 'dependent_work_package_task') && location?.pathname === '/epic-task') {
      dispatch(
        addDependentEpicTask(
          dependentEpicTask?.filter((value) => value.id !== name.id),
        ),
      );
    }
    if (lbl === 'process') {
      dispatch(
        addRelatedProcessData(
          relatedProcess.filter((value) => value.id !== name.id),
        ),
      );
    }
    if (lbl === 'dependent_epic') {
      dispatch(
        addRelatedEpicData(
          relatedEpicData.filter((value) => value.id !== name.id),
        ),
      );
    }
  };

  console.log('title', title, label);

  return (
    <>
      {title && <h2>{t(`section_title_${label}`)}</h2>}
      <div className="row add-people">
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <label htmlFor="program_subj">
              {(isTwinMode && label === 'change') ? 'Change/s'
                : title ? t(`name_${label}`) : t(`related_to_${label}.name`)}
              {(label === 'epic') && <sup>*</sup>}
            </label>

            <div
              className="form-control"
              type="text"
              name="subject_name"
              id="program_subj"
            >
              <div className="md-chips">
                {label === 'program'
                  && relatedProgData
                  && relatedProgData?.length > 0
                  && Array.isArray(relatedProgData)
                  && relatedProgData?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {!(
                        processInputDisable
                        || rcValues?.status === 'Initiation'
                        || programValues?.status === 'Initiation'
                        || ((projectValues?.status === 'Initiation'
                          || projectValues?.status === 'Implementation')
                          && projectValues?.related_programs?.some(
                            (ridt) => ridt?.id === val?.id,
                          ))
                      ) && (
                        <button
                          className="md-chip-remove"
                          type="button"
                          onClick={(e) => removeHandler(e, label, val)}
                        >
                          <span className="icon-close" />
                        </button>
                      )}
                    </div>
                  ))}
                {label === 'initiative'
                  && relatedInitData
                  && relatedInitData?.length > 0
                  && Array.isArray(relatedInitData)
                  && relatedInitData?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {!(
                        processInputDisable
                        || rcValues?.status === 'Initiation'
                        || (rcValues?.status === 'Implementation'
                          && rcValues?.related_initiatives?.some(
                            (ridt) => ridt?.id === val?.id,
                          ))
                        || programValues?.status === 'Initiation'
                        || ((projectValues?.status === 'Initiation'
                          || projectValues?.status === 'Implementation')
                          && projectValues?.related_initiatives?.some(
                            (ridt) => ridt?.id === val?.id,
                          ))
                      ) && (
                        <button
                          className="md-chip-remove"
                          type="button"
                          onClick={(e) => removeHandler(e, label, val)}
                        >
                          <span className="icon-close" />
                        </button>
                      )}
                    </div>
                  ))}
                {label === 'change'
                  && relatedChange
                  && relatedChange?.length > 0
                  && Array.isArray(relatedChange)
                  && relatedChange?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {!(
                        rcValues?.status === 'Initiation'
                        || programValues?.status === 'Initiation'
                        || projectValues?.status === 'Initiation'
                        || riskValues === 'On-hold'
                        || benefitValues === 'On-hold'
                        || isTwin
                      ) && (
                        <button
                          className="md-chip-remove"
                          type="button"
                          onClick={(e) => removeHandler(e, label, val)}
                        >
                          <span className="icon-close" />
                        </button>
                      )}
                    </div>
                  ))}
                {label === 'process'
                  && relatedProcess
                  && relatedProcess?.length > 0
                  && Array.isArray(relatedProcess)
                  && relatedProcess?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {!processInputDisable && (
                        <button
                          className="md-chip-remove"
                          type="button"
                          onClick={(e) => removeHandler(e, label, val)}
                        >
                          <span className="icon-close" />
                        </button>
                      )}
                    </div>
                  ))}
                {label === 'epic'
                  && relatedChange
                  && Array.isArray(relatedChange)
                  && relatedChange?.length > 0
                  && relatedChange?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {!(editMode && !draftMode) && (
                        <button
                          className="md-chip-remove"
                          type="button"
                          onClick={(e) => removeHandler(e, label, val)}
                        >
                          <span className="icon-close" />
                        </button>
                      )}
                    </div>
                  ))}

                  {((label === 'requirement' || label === 'dependent_requirement') && location?.pathname === '/requirement'
                  && (dependentRequirement
                  && Array.isArray(dependentRequirement)
                  && dependentRequirement?.length > 0)) && dependentRequirement?.map((val) => (
                    <div key={val?.id} className="md-chip">
                      <span>{`${val?.name}`}</span>
                      <button
                        className="md-chip-remove"
                        type="button"
                        onClick={(e) => removeHandler(e, label, val)}
                      >
                          <span className="icon-close" />
                      </button>
                    </div>
                  ))}

									{((label === 'requirement' || label === 'dependent_requirement') && location?.pathname !== '/requirement' && !isTwin
                  && (selectedEpicRequirement
                  && Array.isArray(selectedEpicRequirement)
                  && selectedEpicRequirement?.length > 0)) && selectedEpicRequirement?.map((val) => (
                    <div key={val?.id} className="md-chip">
                      <span>{`${val?.name}`}</span>
                      <button
                        className="md-chip-remove"
                        type="button"
                        onClick={(e) => removeHandler(e, label, val)}
                      >
                          <span className="icon-close" />
                      </button>
                    </div>
									))}

                  {((label === 'requirement' || label === 'dependent_requirement')
                    && isTwin && location?.pathname !== '/requirement'
                    && (selectedTwinRequirement
                    && Array.isArray(selectedTwinRequirement)
                    && selectedTwinRequirement?.length > 0))
                    && selectedTwinRequirement?.map((val) => (
                    <div key={val?.id} className="md-chip">
                      <span>{`${val?.name}`}</span>
                    </div>
                    ))}

                {label === 'dependent_epic'
                  && relatedEpicData
                  && relatedEpicData?.length > 0
                  && Array.isArray(relatedEpicData)
                  && relatedEpicData?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      <button
                        className="md-chip-remove"
                        type="button"
                        onClick={(e) => removeHandler(e, label, val)}
                      >
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}

                {label === 'dependent_work_package_task'
                  && dependentEpicTask
                  && dependentEpicTask?.length > 0
                  && Array.isArray(dependentEpicTask)
                  && dependentEpicTask?.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      <button
                        className="md-chip-remove"
                        type="button"
                        onClick={(e) => removeHandler(e, label, val)}
                      >
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}
              </div>
              <div className="input-group-append">
                {/* {!(editMode && !draftMode && location.pathname !== '/projects') && ( */}
                {!(
                  epicInputDisable
                  || changeInputDisable
                  || inputDisable
                  || processInputDisable
                  || rcValues?.status === 'Initiation'
                  || programValues?.status === 'Initiation'
                  || isTwin
                ) && (
                  <button
                    type="button"
                    className="input-group-text btn btn-link btn-small"
                    onClick={() => handleShow({ key: label, title: label, show: true })}
                  >
                    {t('select')}
                  </button>
                )}
                {/* )} */}
              </div>
            </div>
            {label === 'epic'
              && isChangeRequire
              && relatedChange
              && relatedChange?.length === 0
              && Array.isArray(relatedChange) && (
                <div className="invalid-feedback">
                  <span className="icon-error-red" />
                  Change is required
                </div>
            )}

            {label === 'dependent_epic' && (
              // <div>
              <span>{t(`related_to_${label}.message`)}</span>

            // </div>
            )}

            {location.pathname === '/change' && (
              // <div>
              <span>
                If no Project is selected, Change will be mapped under
                ‘Enterprise Project’
              </span>
              // </div>
            )}

            {location.pathname === '/projects' && label === 'program' && (
              // <div>
              <span>
                If no Program is selected, Project will be mapped under
                ‘Enterprise Program’
              </span>
              // </div>
            )}
          </div>
        </div>
      </div>
      {label === 'epic' ? (
        <EpicChangeModal
          modal={modal}
          handleClose={handleClose}
          label={label}
          handleChange={handleChange}
          relatedChange={relatedChange}
        />
      ) : label === 'requirement' || label === 'dependent_requirement' ? (
        <RequirementModal
          modal={modal}
          handleClose={handleClose}
          label={label}
        />
      ) : label === 'dependent_work_package_task' ? (
        <DependentEpicTaskModal
          modal={modal}
          handleClose={handleClose}
          label={label}
        />
      ) : (
        <TransformationModal
          modal={modal}
          handleClose={handleClose}
          label={label}
          handleChange={handleChange}
        />
      )}
    </>
  );
}

export default RelatedSelect;
