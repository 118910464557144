import React from 'react';

export default function AlertMessage({ message, setter }) {
  return (
    <div className="alert d-block alert-success alert-dismissible fade show mb-0" role="alert">
      <div className="alert-wrapper d-flex align-items-center">
        <div className="icon-wrapper">
          <span className="icon-Check" />
        </div>
        <div className="alert-content">
          <p className="mb-0">{message}</p>
        </div>
      </div>
      <button type="button" onClick={() => setter('')} className="close" data-dismiss="alert" aria-label="Close" />
    </div>
  );
}
