/* eslint-disable max-len */
export const validateForm = (fields, values, errors, label, t) => {
  fields.forEach((fld) => {
    if (!values[fld.field_name] && fld.field_value_option.validation) {
      errors = { ...errors, [fld.field_name]: label === 'addStep' ? `${t(`${fld.display_name}`)} is required` : `${t(t(`${label}:${fld.field_name}`).name)} is required` };
    }
  });
  return errors;
};

export const validateFields = (name, values, errors, setErrors, label, t, fields) => {
  console.log('label', label);
  if (label === 'impact'
  && (name === 'scope_min_impact'
  || name === 'scope_max_impact'
  || name === 'schedule_min_impact'
  || name === 'schedule_max_impact'
  || name === 'effort_min_impact'
  || name === 'effort_max_impact'
  || name === 'budget_min_impact'
  || name === 'budget_max_impact'
  || name === 'benefit_min_impact'
  || name === 'benefit_max_impact')) {
    if (values > 100) {
      setErrors((err) => ({
        ...err,
        [name]: 'Value should be between 0 to 100',
      }));
      return false;
    } if (values && !(/^[0-9]\d*(\.\d+)*$/.test(values))) {
      setErrors((err) => ({
        ...err,
        [name]: 'Only numeric & decimals allowed',
      }));
      return false;
    }
  } else if (values) {
    if (typeof values === 'string' && !(/^\S+(?:\s+\S+)*/.test(values))) {
      setErrors((err) => ({
        ...err,
        [name]: 'Can Not start with whitespace',
      }));
      return false;
    }
    if (name === 'name' && !(/^[ a-zA-Z0-9 !-@#$%^&)(}{\-`.+,_~/"]*$/.test(values))) {
      setErrors((err) => ({
        ...err,
        [name]: 'Name must be alphanumeric and special character',
      }));
      return false;
    }

    // if ((name === 'target_kpi' || name === 'current_kpi' || name === 'actual_kpi1' || name === 'actual_kpi2' || name === 'actual_kpi3') && !(/^[0-9a-zA-Z ]+$/.test(values))) {
    //   setErrors((err) => ({
    //     ...err,
    //     [name]: ' Only Alphanumeric value allowed',
    //   }));
    //   return false;
    // }

    if ((name === 'efforts_days' || name === 'estimated_effort') && !(/^\d*\.?\d*$/.test(values))) {
      setErrors((err) => ({
        ...err,
        [name]: 'Only numeric value allowed',
      }));

      return false;
    }

    if ((name === 'overheads_budget') && !(/^[1-9]\d*(\.\d+)?$/.test(values))) {
      setErrors((err) => ({
        ...err,
        [name]: 'Only numeric & decimals allowed',
      }));
      return false;
    }
  }

  return true;
};
