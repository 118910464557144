import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jsonData: [],
  sectionData: [],
  programValues: {},
  // peopleVals: {
  //   owner: [], manager: [], sponsor: [], stCom: [],
  // },
  // costCenter: [],
  // docsInfo: [],
  allProgarms: [],
  translations: { key: 'hello' },
  readReviewData: null,
  relatedProgData: [],
  relatedInitData: [],
  relatedChangeData: [],
  relatedProcessData: [],
  relatedEpicData: [],
  selectBU: [],
  selectOrg: [],
  selectDeprt: [],
  stepBU: [],
  stepOrg: [],
  stepDeprt: [],
  selectDomain: [],
  selectedBODData: {},
  dependentRiskBenfit: [],
  draftMode: false,
  relateDate: null,
  updatedCaps: [],
  updatedProcessCaps: [],
  isOnSubmit: false,
  inputDisable: false,
  recordPermission: null,
  readReviewTab: 'details',
  changeReadReviewTab: 'details',
  isTwinMode: false,
  newNotification: [],
  notificationCount: 0,
};

export const formSlice = createSlice({
  name: 'formSlice',
  initialState,
  reducers: {
    addJson: (state, { payload }) => {
      state.jsonData = payload;
    },
    addSection: (state, { payload }) => {
      state.sectionData = payload;
    },
    addTranslation: (state, { payload }) => {
      state.sectionData = payload;
    },
    addProgramVals: (state, { payload }) => {
      state.programValues = payload;
    },
    // addPeopleVals: (state, { payload }) => {
    //   state.peopleVals = payload;
    // },
    // addCostCenter: (state, { payload }) => {
    //   state.costCenter = payload;
    // },
    // addDocsInfo: (state, { payload }) => {
    //   state.docsInfo = payload;
    // },
    addAllProgs: (state, { payload }) => {
      state.allProgarms = payload;
    },
    addReview: (state, { payload }) => {
      state.readReviewData = payload;
    },
    addRelatedProgData: (state, { payload }) => {
      state.relatedProgData = payload;
    },
    addRelatedInitData: (state, { payload }) => {
      state.relatedInitData = payload;
    },
    addRelatedChangeData: (state, { payload }) => {
      state.relatedChangeData = payload;
    },
    addRelatedProcessData: (state, { payload }) => {
      state.relatedProcessData = payload;
    },
    addRelatedEpicData: (state, { payload }) => {
      state.relatedEpicData = payload;
    },
    selectBU: (state, { payload }) => {
      state.selectBU = payload;
    },
    selectOrg: (state, { payload }) => {
      state.selectOrg = payload;
    },
    selectDeprt: (state, { payload }) => {
      state.selectDeprt = payload;
    },
    setStepBU: (state, { payload }) => {
      state.stepBU = payload;
    },
    setStepOrg: (state, { payload }) => {
      state.stepOrg = payload;
    },
    setStepDeprt: (state, { payload }) => {
      state.stepDeprt = payload;
    },
    selectDomain: (state, { payload }) => {
      state.selectDomain = payload;
    },
    addBODData: (state, { payload }) => {
      state.selectedBODData = payload;
    },
    addDependentRiskBenfit: (state, { payload }) => {
      state.dependentRiskBenfit = payload;
    },
    isDraftMode: (state, { payload }) => {
      state.draftMode = payload;
    },
    addRalatedDate: (state, { payload }) => {
      state.relateDate = payload;
    },
    addUpdatedCabs: (state, { payload }) => {
      state.updatedCaps = payload;
    },
    addUpdatedProcessCabs: (state, { payload }) => {
      state.updatedProcessCaps = payload;
    },
    onClickSubmit: (state, { payload }) => {
      state.isOnSubmit = payload;
    },
    setInputDisabled: (state, { payload }) => {
      state.inputDisable = payload;
    },
    setRecordPermission: (state, { payload }) => {
      state.recordPermission = payload;
    },
    setReadReviewTab: (state, { payload }) => {
      state.readReviewTab = payload;
    },
    setChangeReadReviewTab: (state, { payload }) => {
      state.changeReadReviewTab = payload;
    },
    setIsTwinMode: (state, { payload }) => {
      state.isTwinMode = payload;
    },
    setNewNotification: (state, { payload }) => {
      state.newNotification = payload;
    },
    setNotificationCount: (state, { payload }) => {
      state.notificationCount = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addJson, addSection, addProgramVals, addPeopleVals, addCostCenter, addAllProgs,
  addReview, addRelatedProgData, addRelatedInitData, addRelatedChangeData,
  addRelatedProcessData, addRelatedEpicData,
  selectBU, selectOrg, selectDeprt, selectDomain, addBODData,
  addDependentRiskBenfit, isDraftMode, addRalatedDate, addUpdatedCabs, addUpdatedProcessCabs,
  onClickSubmit, setInputDisabled, setRecordPermission,
  setStepBU, setStepOrg, setStepDeprt, setReadReviewTab,
  setChangeReadReviewTab,
  setIsTwinMode,
  setNewNotification,
  setNotificationCount,
} = formSlice.actions;

export default formSlice.reducer;
