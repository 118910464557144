/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { FormEvent } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import { usePagination, DOTS } from '../../hooks/usePagination';

function PaginationComponenet({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  TBLData,
}: any): JSX.Element {
  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange.length < 2) {
    return (
      <div className="pagination-wrapper">
        <div className="showing-records">
          <p className="mb-0">
            Showing
            <span className="showing-range">{TBLData?.length}</span>
            of
            <span className="total-recods">{totalCount}</span>
            records
          </p>
        </div>
      </div>
    );
  }

  const onNext = (e:FormEvent) => {
    e.preventDefault();
    onPageChange(currentPage + 1);
  };

  const onPrevious = (e:FormEvent) => {
    e.preventDefault();
    onPageChange(currentPage - 1);
  };

  const gotoPageNumver = (pageNum:any) => {
    onPageChange(pageNum);
  };

  const onPageChangeFunc = (e:FormEvent, pageNumber:number) => {
    e.preventDefault();
    onPageChange(pageNumber);
  };

  const start = currentPage * pageSize - (pageSize - 1);
  const end = Math.min(start + pageSize - 1, totalCount);
  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="pagination-wrapper">
      <div className="showing-records">
        <p className="mb-0">
          Showing
          <span className="showing-range">{`${start} - ${end}`}</span>
          of
          <span className="total-recods">{totalCount}</span>
          records
        </p>
      </div>
      <nav>
        <ul className="pagination mb-0">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <Link className="page-link" to="" onClick={(e) => onPrevious(e)}>
              Previous
            </Link>
          </li>
          {(paginationRange && Array.isArray(paginationRange) && paginationRange?.length > 0)
          && paginationRange.map((pageNumber: any) => {
            if (pageNumber === DOTS) {
              return <li className="page-item dots" key={pageNumber}>&#8230;</li>;
            }
            return (
              <li
                className={`page-item ${
                  pageNumber === currentPage ? 'active' : ''
                }`}
              >
                <Link
                  className="page-link"
                  to=""
                  onClick={(e) => onPageChangeFunc(e, pageNumber)}
                >
                  {pageNumber}
                </Link>
              </li>
            );
          })}
          <li
            className={`page-item ${
              currentPage === lastPage ? 'disabled' : ''
            }`}
          >
            <Link className="page-link" to="" onClick={(e) => onNext(e)}>
              Next
            </Link>
          </li>
        </ul>
      </nav>
      {(paginationRange && Array.isArray(paginationRange) && paginationRange?.length > 1)
      && (
      <div className="jumpto-wrapper">
        <div className="jumpto">
          <span>Goto Page</span>
          <select className="custom-selectbox" onChange={(e) => gotoPageNumver(e.target.value)}>
            {(paginationRange && paginationRange?.length > 0 && Array.isArray(paginationRange))
            && paginationRange.map((pageNumber: any) => <option value={pageNumber}>{pageNumber}</option>)}

          </select>
        </div>
      </div>
      )}
    </div>
  );
}
export default PaginationComponenet;
