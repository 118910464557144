import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  issueReadReviewTab: 'details',
  correctionReadReviewTab: 'details',
  impactReadReviewTab: 'details',
  issueValues: null,
  impactValues: null,
  correctionValues: null,
  issueResPeople: [],
  issueEditMode: false,
  impactEditMode: false,
  correctionEditMode: false,
  issueCapability: [],
  impactList: [],
  correctionList: [],
  issueCapabilitiesReadOnly: [
    {
      key: 'process',
      icon: 'icon-value-chain',
      title: 'Process',
      value: [],
    },
    {
      key: 'application',
      icon: 'icon-application',
      title: 'Application',
      value: [],
    },
    {
      key: 'business',
      icon: 'icon-business',
      title: 'Business',
      value: [],
    },
    {
      key: 'vendor',
      icon: 'icon-supplier',
      title: 'Vendor',
      value: [],
    },
    {
      key: 'customer',
      icon: 'icon-consumer',
      title: 'Customer',
      value: [],
    },
    {
      key: 'product',
      icon: 'icon-toolbar',
      title: 'Product',
      value: [],
    },
  ],
  epicIssueCapsMapping: [],

  capabilitiesData: [],
  processCapabilitiesData: [],
  businessCapabilitiesData: [],
  vendorCapabilitiesData: [],
  customerCapabilitiesData: [],
  productCapabilitiesData: [],
  correctionResPeople: [],

};
export const issueSlice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    setIssueReadReviewTab: (state, { payload }) => {
      state.issueReadReviewTab = payload;
    },
    setCorrectionReadReviewTab: (state, { payload }) => {
      state.correctionReadReviewTab = payload;
    },
    setImpactReadReviewTab: (state, { payload }) => {
      state.impactReadReviewTab = payload;
    },
    addIssueResPeople: (state, { payload }) => {
      state.issueResPeople = payload;
    },
    addIssueVals: (state, { payload }) => {
      state.issueValues = payload;
    },
    addImpactVals: (state, { payload }) => {
      state.impactValues = payload;
    },
    addCorrectionVals: (state, { payload }) => {
      state.correctionValues = payload;
    },
    setIssueEditMode: (state, { payload }) => {
      state.issueEditMode = payload;
    },
    setImpactEditMode: (state, { payload }) => {
      state.impactEditMode = payload;
    },
    setCorrectionEditMode: (state, { payload }) => {
      state.correctionEditMode = payload;
    },
    setIssueCapability: (state, { payload }) => {
      state.issueCapability = payload;
    },
    setImpactList: (state, { payload }) => {
      state.impactList = payload;
    },
    setCorrectionList: (state, { payload }) => {
      state.correctionList = payload;
    },
    addIssueCapabilitiesReadOnly: (state, { payload }) => {
      state.issueCapabilitiesReadOnly = [
        {
          key: 'process',
          icon: 'icon-value-chain',
          title: 'Process',
          value: payload.processCaps,
        },
        {
          key: 'application',
          icon: 'icon-application',
          title: 'Application',
          value: payload.appCaps,
        },
        {
          key: 'business',
          icon: 'icon-business',
          title: 'Business',
          value: payload.businessCaps,
        },
        {
          key: 'vendor',
          icon: 'icon-supplier',
          title: 'Vendor',
          value: payload.vendorCaps,
        },
        {
          key: 'customer',
          icon: 'icon-consumer',
          title: 'Customer',
          value: payload.customerCaps,
        },
        {
          key: 'product',
          icon: 'icon-toolbar',
          title: 'Product',
          value: payload.productCaps,
        },
      ];
    },
    addEpicIssueCapsMapping: (state, { payload }) => {
      state.epicIssueCapsMapping = payload;
    },

    addCapabilitiesData: (state, { payload }) => {
      state.capabilitiesData = payload;
    },
    addProcessCapabilitiesData: (state, { payload }) => {
      state.processCapabilitiesData = payload;
    },
    addBusinessCapabilitiesData: (state, { payload }) => {
      state.businessCapabilitiesData = payload;
    },
    addVendorCapabilitiesData: (state, { payload }) => {
      state.vendorCapabilitiesData = payload;
    },
    addCustomerCapabilitiesData: (state, { payload }) => {
      state.customerCapabilitiesData = payload;
    },
    addProductCapabilitiesData: (state, { payload }) => {
      state.productCapabilitiesData = payload;
    },
    addCorrectionResPeople: (state, { payload }) => {
      state.correctionResPeople = payload;
    },
    resetIssueState: (state, { payload }) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setIssueReadReviewTab,
  setCorrectionReadReviewTab,
  setImpactReadReviewTab,
  addIssueResPeople,
  addIssueVals,
  setIssueEditMode,
  addImpactVals,
  setImpactEditMode,
  addCorrectionVals,
  setCorrectionEditMode,
  setIssueCapability,
  setImpactList,
  setCorrectionList,
  addIssueCapabilitiesReadOnly,
  addEpicIssueCapsMapping,
  addCapabilitiesData,
  addBusinessCapabilitiesData,
  addVendorCapabilitiesData,
  addCustomerCapabilitiesData,
  addProductCapabilitiesData,
  addProcessCapabilitiesData,
  addCorrectionResPeople,
  resetIssueState,
} = issueSlice.actions;

export default issueSlice.reducer;
