/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CancelModal from '../../components/common/CancelModal';
import useForm from '../../hooks/useForm';
import {
  validateForm,
  validateFields,
} from '../../components/common/FormValidation';
import IssueCustomForm from './IssueCustomeForm';
import IssuePeople from './IssueResponsiblePeople';
import ReadOnlyCaps from '../../components/capabalities/ReadOnlyCaps';
import IssueCapabilities from './IssueCapabilities';
import EpicIssueCapabilitiesReadOnly from './EpicIssueCapabilityReadOnly';
import EpicCapabilitySelection from './EpicCapabilitySelection';
import CorrectionPeople from './Correcttion/CorrectionPeople';

// eslint-disable-next-line no-dupe-args
function FormBuilder({
  label,
  labelTwin,
  onNext,
  template,
  dfVals,
  editMode,
  modalShow,
  setModalShow,
  stepReset,
  cancelTitle,
  isLoading,
  twinMode,
  resRelatedObjects,
  issueResPeople,
  saveDocs,
  docsInfo,
  issueEditMode,
  isChange,
  isEpic,
  isChangeImpact,
  isChangeCorrection,
  correctionResPeople,
}) {
  console.log('inSide', resRelatedObjects);

  const {
    values,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    setValues,
    clearFormState,
    isChangeRequire,
  } = useForm(
    onNext,
    validateForm,
    validateFields,
    template.fields,
    dfVals,
    stepReset,
    editMode,
    label,
    t,
  );

  console.log('issueResPeople', issueResPeople, values);

  useEffect(() => {
    const docsCopy = (values?.documents && Array.isArray(values?.documents) && values?.documents?.length > 0) && values?.documents
      ? values?.documents?.filter(
        (el) => !docsInfo?.some((dc) => dc?.s3_key_name === el?.s3_key_name),
      )
      : [];
    if (docsCopy && Array.isArray(docsCopy) && docsCopy?.length > 0) {
      saveDocs({ data: docsCopy, budgetData: [], effortData: [] });
    }
  }, [values?.documents]);

  return (
    <div className="container-fluid">
      <div className="form-unit">
        {template?.fields?.length > 0 && template?.sections?.length > 0 && (
          <form className="add-program-form">
            <IssueCustomForm
              isLoading={isLoading}
              label={label}
              twinMode={twinMode}
              fields={template.fields}
              sections={template.sections}
              t={t}
              labelTwin={labelTwin}
              errors={errors}
              setErrors={setErrors}
              values={values}
              handleChange={handleChange}
              editMode={editMode}
              setValues={setValues}
              isChangeRequire={isChangeRequire}
            />

            {label === 'issue'
              && ((resRelatedObjects?.isSuccess
                && resRelatedObjects?.data?.related_initiatives
                && resRelatedObjects?.data?.related_initiatives?.length > 0
                && Array.isArray(resRelatedObjects?.data?.related_initiatives))
                || (resRelatedObjects?.isSuccess
                  && resRelatedObjects?.data?.related_changes
                  && resRelatedObjects?.data?.related_changes?.length > 0
                  && Array.isArray(resRelatedObjects?.data?.related_changes))
                || (resRelatedObjects?.isSuccess
                  && resRelatedObjects?.data?.related_epics
                  && resRelatedObjects?.data?.related_epics?.length > 0
                  && Array.isArray(resRelatedObjects?.data?.related_epics))
                  || (resRelatedObjects?.isSuccess
                    && resRelatedObjects?.data?.related_programs
                    && resRelatedObjects?.data?.related_programs?.length > 0
                    && Array.isArray(resRelatedObjects?.data?.related_programs))
              ) && (
              <>
                <h2>Related Object</h2>
                <div className="row add-people">
                  {resRelatedObjects?.isSuccess
                      && resRelatedObjects?.data?.related_programs
                      && resRelatedObjects?.data?.related_programs?.length
                        > 0
                      && Array.isArray(
                        resRelatedObjects?.data?.related_programs,
                      ) && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="program_subj">Program</label>
                            <div
                              className="form-control"
                              type="text"
                              name="subject_name"
                              id="program_subj"
                            >
                              <div className="md-chips">
                                {resRelatedObjects?.isSuccess
                                  && resRelatedObjects?.data
                                    ?.related_programs
                                  && resRelatedObjects?.data?.related_programs
                                    ?.length > 0
                                  && Array.isArray(
                                    resRelatedObjects?.data?.related_programs,
                                  )
                                  && resRelatedObjects?.data?.related_programs?.map(
                                    (dt) => (
                                      <div key={dt.id} className="md-chip">
                                        <span>{`${dt.name}`}</span>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                  )}

                  {resRelatedObjects?.isSuccess
                      && resRelatedObjects?.data?.related_initiatives
                      && resRelatedObjects?.data?.related_initiatives?.length
                        > 0
                      && Array.isArray(
                        resRelatedObjects?.data?.related_initiatives,
                      ) && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="program_subj">Project</label>
                            <div
                              className="form-control"
                              type="text"
                              name="subject_name"
                              id="program_subj"
                            >
                              <div className="md-chips">
                                {resRelatedObjects?.isSuccess
                                  && resRelatedObjects?.data
                                    ?.related_initiatives
                                  && resRelatedObjects?.data?.related_initiatives
                                    ?.length > 0
                                  && Array.isArray(
                                    resRelatedObjects?.data?.related_initiatives,
                                  )
                                  && resRelatedObjects?.data?.related_initiatives?.map(
                                    (dt) => (
                                      <div key={dt.id} className="md-chip">
                                        <span>{`${dt.name}`}</span>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                  )}
                  {resRelatedObjects?.isSuccess
                      && resRelatedObjects?.data?.related_changes
                      && resRelatedObjects?.data?.related_changes?.length > 0
                      && Array.isArray(
                        resRelatedObjects?.data?.related_changes,
                      ) && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="program_subj">Change</label>
                            <div
                              className="form-control"
                              type="text"
                              name="subject_name"
                              id="program_subj"
                            >
                              <div className="md-chips">
                                {resRelatedObjects?.isSuccess
                                  && resRelatedObjects?.data?.related_changes
                                  && resRelatedObjects?.data?.related_changes
                                    ?.length > 0
                                  && Array.isArray(
                                    resRelatedObjects?.data?.related_changes,
                                  )
                                  && resRelatedObjects?.data?.related_changes?.map(
                                    (dt) => (
                                      <div key={dt.id} className="md-chip">
                                        <span>{`${dt.name}`}</span>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                  )}
                  {resRelatedObjects?.isSuccess
                      && resRelatedObjects?.data?.related_epics
                      && resRelatedObjects?.data?.related_epics?.length > 0
                      && Array.isArray(resRelatedObjects?.data?.related_epics) && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="program_subj">Work Package</label>
                            <div
                              className="form-control"
                              type="text"
                              name="subject_name"
                              id="program_subj"
                            >
                              <div className="md-chips">
                                {resRelatedObjects?.isSuccess
                                  && resRelatedObjects?.data?.related_epics
                                  && resRelatedObjects?.data?.related_epics
                                    ?.length > 0
                                  && Array.isArray(
                                    resRelatedObjects?.data?.related_epics,
                                  )
                                  && resRelatedObjects?.data?.related_epics?.map(
                                    (dt) => (
                                      <div key={dt.id} className="md-chip">
                                        <span>{`${dt.name}`}</span>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                  )}
                  <div className="w-100" />
                </div>
                <hr />
              </>
            )}

            {label === 'issue'
            && (
            <>
              <IssuePeople />
              <hr />
            </>
            )}

            {label === 'correction'
            && (
            <>
              <CorrectionPeople />
              <hr />
            </>
            )}

            {(isChange || isChangeImpact || isChangeCorrection)
            && (
              <IssueCapabilities />
            )}
            {isEpic
              && <EpicCapabilitySelection />}

            <div className="button-wrapper d-flex align-items-center justify-content-end w-100">
              <button
                onClick={() => setModalShow(true)}
                type="button"
                className="btn btn-dark"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSubmit()}
                disabled={(
                (label === 'issue' && issueResPeople && Array.isArray(issueResPeople) && !issueResPeople?.length)
                || (label === 'correction' && correctionResPeople && Array.isArray(correctionResPeople) && !correctionResPeople?.length)
                )}
              >
                Submit
              </button>
            </div>
            <CancelModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              cancelTitle={cancelTitle}
              func={clearFormState}
            />
          </form>
        )}
      </div>
    </div>
  );
}

export default memo(FormBuilder);
