/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import React from 'react';

function ReadOnlyCaps({
  appCapabilitiesData,
  processCapabilitiesData,
  businessCapabilitiesData,
  productCapabilitiesData,
  vendorCapabilitiesData,
  customerCapabilitiesData,
  setExpanded,
  size,
  position,
  capsInfo,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);

  const closeExpand = (e) => {
    e.preventDefault();
    const $this = document.getElementById('fsm_actual');
    $this.style.height = size.height;
    $this.style.width = size.width;
    $this.style.top = `${position.top}px`;
    $this.style.left = `${position.left}px`;
    $this.style.margin = '0';
    $this.classList.add('shrinking');
    setTimeout(() => {
      const { classList } = $this;
      while (classList.length > 0) {
        classList.remove(classList.item(0));
      }
      $this.style = '';
      setExpanded(false);
    }, 1000);
  };

  return (
    <div className="card">
      <h3 className="card-header">
        <span className={capsInfo?.icon} />
        {capsInfo?.title}
        <a href="#!" className="edit-card" onClick={(e) => closeExpand(e)}>
          <span className="icon-close" />
        </a>
      </h3>
      <div className="card-body">
        <h5 className="font-weight-normal">
          {capsInfo?.title}
        </h5>
        {capsInfo?.title === 'Application'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(appCapabilitiesData
                && appCapabilitiesData.length > 0
                && Array.isArray(appCapabilitiesData))
                && appCapabilitiesData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td>
                      {ele?.object_name ? ele?.object_name : ele?.name || '-'}
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">
                      {ele?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        {capsInfo?.title === 'Process'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(processCapabilitiesData
                && processCapabilitiesData.length > 0
                && Array.isArray(processCapabilitiesData))
                && processCapabilitiesData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td>
                      {ele?.object_name ? ele?.object_name : ele?.name || '-'}
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers && ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td>{(ele?.status || '-')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        {capsInfo?.title === 'Business'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(businessCapabilitiesData
                && businessCapabilitiesData.length > 0
                && Array.isArray(businessCapabilitiesData))
                && businessCapabilitiesData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td>
                      {ele?.object_name ? ele?.object_name : ele?.name || '-'}
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">
                      {ele?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}

        {capsInfo?.title === 'Product'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(productCapabilitiesData
                && Array.isArray(productCapabilitiesData))
                && productCapabilitiesData.length > 0
                && productCapabilitiesData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td>
                      {ele?.object_name ? ele?.object_name : ele?.name || '-'}
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">
                      {ele?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}

        {capsInfo?.title === 'Vendor'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(vendorCapabilitiesData
                && vendorCapabilitiesData.length > 0
                && Array.isArray(vendorCapabilitiesData))
                && vendorCapabilitiesData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td>
                      {ele?.object_name ? ele?.object_name : ele?.name || '-'}
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">
                      {ele?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        {capsInfo?.title === 'Customer'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(customerCapabilitiesData
                && customerCapabilitiesData.length > 0
                && Array.isArray(customerCapabilitiesData))
                && customerCapabilitiesData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td>
                      {ele?.object_name ? ele?.object_name : ele?.name || '-'}
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">
                      {ele?.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
      </div>
      <div className="button-wrapper d-flex align-items-center justify-content-end">
        <button type="button" className="btn btn-outline-primary btn-sm btn-rounded" onClick={(e) => closeExpand(e)}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ReadOnlyCaps;
