/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useTranslation } from 'react-i18next';
import {
  Alert, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// import { t } from 'i18next';
import AlertMessage from '../../SuperComponents/AlertMessage';
import CancelModal from './CancelModal';
import StepFooter from './StepFooter';
import { dateFormate } from '../../utils/form/form';
import { addBudgetDocsInfo, addDocsInfo } from '../../services/docInfoSlice';
import { addExtMtData, changeEditMode } from '../../services/editFormSlice';
import ReadMoreLess from './ReadMoreLess';
import ReadMoreLessDocument from './ReadMoreLessDoc';
import isJson from '../../utils/isJson';
import ReadOnlyCaps from '../capabalities/ReadOnlyCaps';
import {
  useApproveProjectMutation,
  useGetExtMtDtMutation, useGetExtMtDtQueryQuery, useSubmitEpicMutation, useSubmitFormMutation, useUpdateStatusMutation,
  useSolSpecsProjectMutation,
} from '../../services/services';
import LoaderComp from '../LoderQ';
import SolutionSpecification from '../../screens/solution-specification/SolutionSpecification';
import ErrorModal from './ErrorModal';
import { setReadReviewTab } from '../../services/formSlice';
import BarChartImg from '../../images/barchart-icon.svg';
import { resetIssueState } from '../../services/issueSlice';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

export default function Review({
  submit, saveDraft, stepReset, steps, modalShow, isLoading, isError, isSuccess, actions, editable,
  setModalShow, handleBack, activeStep, visibleFields, review, label, readMode, redirect, cancelTitle, changeId, epicTaskId,
  peopleView,
}) {
  console.log('label', label, review);
  const { t } = useTranslation(label === 'Project' ? 'Initiative' : label === 'Epic Task' ? 'epic' : label);
  const extlatestData = useGetExtMtDtQueryQuery({ name: review === 'project' ? 'initiative' : review === 'epicTask' ? 'Epic Task' : review });
  const [getSolProj, solProjRes] = useSolSpecsProjectMutation();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const extMtData = useSelector((state) => state.editData.extMtData);
  const peopleData = useSelector((state) => state.peopleData.peopleVals); // all people
  const epicTaskPeople = useSelector((state) => state.peopleData.epicTaskPeople); // epic task people
  const benefitData = useSelector((state) => state.benefitData.selectedBenefit); // benefit
  const trackingPointData = useSelector((state) => state.benefitData.trackingPointData); // benefit trackingpoint
  const riskData = useSelector((state) => state.riskData.selectedRisk); // risk
  const mitigationData = useSelector((state) => state.riskData.riskMitihgationData); // risk mitigation
  const relatedProgData = useSelector((state) => state.formJson.relatedProgData); // program
  const relatedInitData = useSelector((state) => state.formJson.relatedInitData); // initiative
  const relatedChangeData = useSelector((state) => state.formJson.relatedChangeData); // req change
  const relatedEpicData = useSelector((state) => state.formJson.relatedEpicData); // dependent epic
  const capabilitiesData = useSelector((state) => state.applicationData.capabilitiesData); // capabilities
  const businessCapsData = useSelector((state) => state.businessData.capabilitiesData); // business capabilities
  const vendorCapsData = useSelector((state) => state.vendorData.capabilitiesData); // vendor capabilities
  const customerCapsData = useSelector((state) => state.customerData.capabilitiesData); // customer capabilities
  const productCapsData = useSelector((state) => state.productData.capabilitiesData); // product capabilities
  const processCapabilitiesData = useSelector((state) => state.applicationData.processCapabilitiesData); // Process capabilities
  const visibleF = useSelector((state) => state.editData.visibleFieldsReview); // Visible Fields
  const relatedDate = useSelector((state) => state.formJson.relateDate); // related date and people
  const updatedCaps = useSelector((state) => state.formJson.updatedCaps); // for change capabilites
  const editMode = useSelector((state) => state.editData.editMode);
  const changeCapabilities = useSelector((state) => state.applicationData.changeCapabilities);
  const epicValues = useSelector((state) => state.epicData.epicVals);
  const initValues = useSelector((state) => state.initData.initValues);
  const recordPermission = useSelector((state) => state.formJson.recordPermission); // draft Mode
  const requirementData = useSelector((state) => state.requirementData.selectedRequirement);
  const selectedEpicRequirement = useSelector((state) => state.requirementData.selectedEpicRequirement);
  const selectedEpicTask = useSelector((state) => state.epicTaskData.selectedEpicTask);
  const changeCollaborator = useSelector((state) => state.peopleData.changeCollaborator);
  const requirementCollaborator = useSelector((state) => state.requirementData.requirementCollaborator);
  const requirementValues = useSelector((state) => state.requirementData.requirementValues);
  const dependentRequirement = useSelector((state) => state.requirementData.dependentRequirement);
  const dependentEpicTask = useSelector((state) => state.epicTaskData.dependentEpicTask);

  const relatedHeaderProgram = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'owners', name: 'Owner' },
    { type: '', field: 'managers', name: 'Manager' },
    { type: 'date', field: 'start_date', name: 'Start Date' },
    { type: 'date', field: 'end_date', name: 'End Date' },
    { type: '', field: 'status', name: 'Status' },
  ];

  const relatedHeader = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'owners', name: 'Owner' },
    { type: '', field: 'managers', name: 'Manager' },
    { type: 'date', field: 'start_date', name: 'Start Date' },
    { type: 'date', field: 'end_date', name: 'End Date' },
    { type: '', field: 'status', name: 'Status' },
  ];

  useEffect(() => {
    if (review === 'project') {
      getSolProj({ id: initValues.id });
    }
  }, [review]);
  const [show, setShow] = useState(false);

  const [notify, setNotify] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState(null);
  const [collaborator, setCollaborator] = useState([]);

  const fieldss = visibleF.filter((it) => it.visible);
  console.log('requirementCollaborator', requirementCollaborator);

  useEffect(() => {
    if (label === 'requirement' || label === 'Requirement') {
      if (peopleData?.reqCollaborator && Array.isArray(peopleData?.reqCollaborator) && peopleData?.reqCollaborator?.length > 0) {
        const filterCollaborators1 = peopleData?.reqCollaborator?.map((cb) => cb?.value)?.flat();
        const filterCollaborators2 = filterCollaborators1?.map((fc) => fc?.collaborators?.map((fcc) => `${fc?.name}: <small>
          ${fcc?.first_name || ''}
          ${fcc?.last_name || ''}
        </small>`))?.flat();
        setCollaborator(filterCollaborators2);
      }
    } else if (changeCollaborator && Array.isArray(changeCollaborator) && changeCollaborator?.length > 0) {
      console.log('changeCollaborator', changeCollaborator);
      const filterCollaborators1 = changeCollaborator?.map((cb) => cb?.value)?.flat();
      const filterCollaborators2 = requirementCollaborator?.map((cb) => cb?.value)?.flat();
      console.log('filterCollaborators2', filterCollaborators2);
      // const filterCollaborators2 = filterCollaborators1?.map((fc) => fc?.collaborators?.map((fcc) => `${fcc?.object_name} : <small>
      //     ${fcc?.first_name}
      //     ${fcc?.last_name}
      //   </small>`))?.flat();
      const flt1 = filterCollaborators1?.map((fc) => fc?.collaborators)?.flat()?.filter((el) => (el !== null));
      const flt2 = filterCollaborators2?.map((fc) => fc?.collaborators)?.flat()?.filter((el) => (el !== null));
      const finalFilter = [...flt1, ...flt2]?.filter((el) => (el !== null));
      if (finalFilter && Array.isArray(finalFilter) && finalFilter?.length > 0) {
        setCollaborator(finalFilter?.map((fcc, i) => `${i === 0 ? '' : '<br />'} ${fcc?.object_name} : <small>
          ${fcc?.first_name || ''}
          ${fcc?.last_name || ''}
        </small>`));
      }
    }
  }, [label, changeCollaborator]);

  const handleEdit = () => {
    if (label === 'Application') {
      if (editable) {
        dispatch(changeEditMode(true));
        if (extlatestData.isSuccess) {
          const updatedExt = [...extMtData.fields];
          const differ = extlatestData?.data?.fields?.filter((lt) => !updatedExt.some((ut) => ut.field_name === lt.field_name));
          const finalFields = [...updatedExt, ...differ];
          const final = { fields: finalFields, sections: extMtData.sections, new: [] };
          dispatch(addExtMtData(final));
          navigate(redirect, {
            state: {
              to: review,
              update: review === 'application' && userData.permissions.includes('update:application'),
              changeId,
              epicTaskId,
              breadcrumb: `Edit ${location?.state?.name}`,
            },
          });
        }
      } else {
        setNotify('Can not edit as Twins already created for this Capability');
      }
    } else {
      dispatch(changeEditMode(true));
      if (extlatestData.isSuccess) {
        const updatedExt = [...extMtData.fields];
        const differ = extlatestData?.data?.fields?.filter((lt) => !updatedExt.some((ut) => ut.field_name === lt.field_name));
        const finalFields = [...updatedExt, ...differ];
        const final = { fields: finalFields, sections: extMtData.sections, new: [] };
        dispatch(addExtMtData(final));
        navigate(redirect, {
          state: {
            to: review,
            update: review === 'application' && userData.permissions.includes('update:application'),
            changeId,
            epicTaskId,
            breadcrumb: `Edit ${location?.state?.name}`,
          },
        });
      }
    }
  };

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  const [submitEpicForm, epicRes] = useSubmitEpicMutation();
  const [submitProgForm, progRes] = useSubmitFormMutation();
  const [submitProjForm, projRes] = useApproveProjectMutation();
  const [updateStatus, updateRes] = useUpdateStatusMutation();

  const approveTask = (lbl) => {
    if (lbl === 'Program Approval') {
      const finalData = {
        ...location?.state?.progData, status: 'Initiation',
      };
      submitProgForm(finalData);
    } else if (lbl === 'Epic Approval') {
      const finalData = {
        ...location?.state?.epicData, status: 'Closed',
      };
      submitEpicForm(finalData);
    } else if (lbl === 'Initiative Approval') {
      const finalData = {
        ...location?.state?.projData,
        initiative_approvals: location.state.projData.initiative_approvals ? [
          ...location.state.projData.initiative_approvals,
          { approver_id: userData.id, approved: true, approved_on: dayjs().format('YYYY-MM-DD hh:mm:ss') },
        ] : lbl === 'Initiative Approval' ? [{ approver_id: userData.id, approved: true, approved_on: dayjs().format('YYYY-MM-DD hh:mm:ss') }] : [],
      };
      submitProjForm(finalData);
    }
  };

  useEffect(() => {
    if (epicRes?.isSuccess || progRes?.isSuccess || projRes?.isSuccess) {
      const data = {
        id: location?.state?.task_id,
        status: 'Completed',
        userId: userData.id,
      };
      updateStatus(data);
    }
  }, [epicRes, progRes, projRes]);

  useEffect(() => {
    if (updateRes?.isSuccess) {
      if (location?.state?.lbl === 'Program Approval') {
        setSuccessMessage('Program Approved Successfully');
      } else if (location?.state?.lbl === 'Initiative Approval') {
        setSuccessMessage('Project Approved Successfully');
      } else if (location?.state?.lbl === 'Epic Approval') {
        setSuccessMessage('Work Package Approved Successfully');
      }
      setTimeout(() => {
        navigate('/collaborate', {
          state: {
            breadcrumb: 'Collaborator',
          },
        });
      }, 200);
    }
  }, [updateRes]);

  const navigateToIssue = (e) => {
    e.preventDefault();
    dispatch(resetIssueState());
    navigate('/create-issue', {
      state: {
        id: location?.state?.id,
        type: location?.state?.label,
        from: location?.pathname,
        state: location?.state,
      },
    });
  };

  if (updateRes?.isError) {
    return <ErrorModal />;
  }

  return (
    <form action="" className="add-program-form">
      {(updateRes?.isLoading) && (
        <LoaderComp />
      )}
      {/* {review === 'project' && (
        <SolutionSpecification
          screen="project"
          show={show}
          setShow={setShow}
          id={initValues?.id}
          data={solProjRes.isSuccess ? solProjRes.data : null}
        />
      )} */}
      {notify && (
        <div className={`toast-wrapper ${notify && 'show'}`}>
          <Alert variant="primary" onClose={() => setNotify('')} dismissible>
            <span className="icon-warning" />
            {notify}
          </Alert>
        </div>
      )}
      {/* <div className={review === 'project' ? 'd-flex justify-content-end' : 'd-flex justify-content-end'}> */}

      {/* {review === 'project' && location.state?.solSpecs && (
        <OverlayTrigger placement="bottom" overlay={renderTooltip('Solution Specification')}>
          <Button
            variant="link"
            onClick={() => setShow(true)}
            className="mr-4"
          >
            <span className="icon-solution-spec" />
          </Button>
        </OverlayTrigger>
        )} */}
      {/* {(activeStep && Object.keys(activeStep).length > 0) ? ''
          : ((label === 'Epic' || label === 'epic') && epicValues?.status === 'Closed') ? ''
            : location?.state?.approval ? ''
              : (recordPermission?.canEdit)
                ? (
                  <button
                    type="button"
                    onClick={(e) => handleEdit(e)}
                    className="btn btn-link text-decoration-none"
                  >
                    <span className="icon-edit" />
                  </button>
                ) : ''} */}
      {/* </div> */}
      {location?.pathname === '/readReview'
      && (
      <div className="action_button_parent" style={(label === 'Program' || review === 'program' || label === 'Project' || review === 'project' || label === 'Epic' || label === 'epic' || review === 'Epic') ? { marginTop: '45px' } : { marginTop: '0px' }}>
        {location?.state?.approval && location?.state?.lbl && (
        <div className="d-flex justify-content-end">
          <button type="button" className="action-button mb-2 mb-md-0 btn-primary" onClick={() => approveTask(location?.state?.lbl)}>
            <div className="action-button-icon d-flex align-items-center justify-content-center">
              <span className="icon-Check" />
            </div>
            <span className="action_text">Approve</span>
          </button>
        </div>
        )}
        {(activeStep && Object.keys(activeStep).length > 0) ? ''
          : ((label === 'Epic' || label === 'epic') && epicValues?.status === 'Closed') ? ''
            : location?.state?.approval ? ''
              : (recordPermission?.canEdit)
                ? (
                  <div className="d-flex justify-content-end mt-2">
                    <button type="button" className="action-button mb-2 mb-md-0 btn-primary" onClick={(e) => handleEdit(e)}>
                      <div className="action-button-icon d-flex align-items-center justify-content-center">
                        <span className="icon-edit" />
                      </div>
                      <span className="action_text">Edit</span>
                    </button>
                  </div>
                ) : ''}
        {(label === 'Program' || review === 'program' || label === 'Project' || review === 'project' || label === 'epic' || label === 'Epic' || review === 'Epic')
        && (
        <div className="d-flex justify-content-end mt-2">
          <Link type="button" to="" onClick={(e) => navigateToIssue(e)} className="action-button mb-2 mb-md-0 btn-primary text-center">
            <div className="action-button-icon d-flex align-items-center  justify-content-center">
              <span className="icon-view" />
            </div>
            <span className="action_text ">Create Issue</span>
          </Link>
        </div>
        )}
      </div>
      )}

      {/* {location?.pathname === '/readReview' && (label === 'Program' || review === 'program' || label === 'Project' || review === 'project' || label === 'Epic' || label === 'epic' || review === 'Epic')
        && (
        <div className="d-flex justify-content-end">
          <button type="button" className="action-button  mb-md-0" style={{ backgroundColor: '#00b894', border: '#00b894' }} onClick={() => dispatch(setReadReviewTab('insights'))}>
            <div className="action-button-icon d-flex align-items-center justify-content-center">
              <img src={BarChartImg} alt="" height="25px" width="20px" />
            </div>
            <span className="action_text text-uppercase">Insights</span>
          </button>
        </div>
        )} */}

      <div className="form-unit">
        <div className="container-fluid">
          <div>
            {successMessage && (
              <AlertMessage
                message={successMessage}
                setter={setSuccessMessage}
              />
            )}
          </div>

          <h2 className="h1">
            1.
            {' '}
            {/* {t('review:program_information')} */}
            {' '}
            Details
          </h2>
          {(extMtData?.fields && Array.isArray(extMtData?.fields) && extMtData?.fields?.length > 0)
            && extMtData?.sections?.map((tl, i) => (
              <Fragment key={tl?.id}>
                <h2 className="section-title">{tl.display_name}</h2>
                {((label === 'Project' || review === 'project') && i === 0) && (
                  <div className="row">
                    <div className="col-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="program_subj">Related to Program/s</label>
                        {
                          (relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData))
                            ? (
                              <p className="readonly-text font-weight-bold">
                                {relatedProgData.map((rp, pindex) => (
                                  <Fragment key={rp?.name}>
                                    <span>{rp.name}</span>
                                    {pindex === relatedProgData.length - 1
                                      ? ''
                                      : ', '}
                                  </Fragment>
                                ))}
                              </p>
                            )
                            : '-'
                        }
                      </div>
                    </div>
                  </div>
                )}
                {((review === 'change' || label === 'Change') && i === 0) && (
                  <div className="row">
                    <div className="col-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="program_subj">Related to Project/s</label>
                        {relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData)
                          ? (
                            <p className="readonly-text font-weight-bold">
                              {relatedInitData.map((rp, pindex) => (
                                <Fragment key={rp?.name}>
                                  <span>{rp.name}</span>
                                  {pindex === relatedInitData.length - 1
                                    ? ''
                                    : ', '}
                                </Fragment>
                              ))}
                            </p>
                          )
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}

                {/* // epic change, program and project */}
                {((label === 'epic' || label === 'Epic') && i === 0)
                  && (
                    <>
                      <div className="row">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="program_subj">Related to Program/s</label>
                            <p className="readonly-text font-weight-bold">
                              {(relatedChangeData && relatedChangeData.length > 0 && Array.isArray(relatedChangeData)) ? relatedChangeData.map((rp) => (
                                <Fragment key={rp?.id}>
                                  {/* {(rp?.related_initiatives && rp?.related_initiatives.length > 0 && Array.isArray(rp?.related_initiatives))
                                    && (rp?.related_initiatives[0]?.related_programs && rp?.related_initiatives[0]?.related_programs.length > 0 && Array.isArray(rp?.related_initiatives[0]?.related_programs))
                                    ? rp?.related_initiatives[0]?.related_programs?.map((ele, inx) => (
                                      <Fragment key={ele?.name}>
                                        <span>{ele.name}</span>
                                        {inx === rp.related_initiatives[0].related_programs.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))
                                    : 'Enterprise Program'} */}
                                  {(rp?.programs && rp?.programs.length > 0 && Array.isArray(rp?.programs))
                                    ? rp?.programs?.map((ele, inx) => (
                                      <Fragment key={ele?.name}>
                                        <span>{ele?.name}</span>
                                        {inx === rp.programs.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))
                                    : 'Enterprise Program'}
                                </Fragment>
                              ))
                                : 'Enterprise Program'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="program_subj">Related to Project/s</label>
                            <p className="readonly-text font-weight-bold">
                              {(relatedChangeData && relatedChangeData.length > 0 && Array.isArray(relatedChangeData)) ? relatedChangeData.map((rp) => (
                                <Fragment key={rp?.id}>
                                  {(rp?.related_initiatives && rp?.related_initiatives.length > 0 && Array.isArray(rp?.related_initiatives))
                                    ? rp?.related_initiatives?.map((ele, inx) => (
                                      <Fragment key={ele?.name}>
                                        <span>{ele.name}</span>
                                        {inx === rp.related_initiatives.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))
                                    : 'Enterprise Project'}
                                </Fragment>
                              ))
                                : 'Enterprise Project'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="program_subj">Related to Change/s</label>
                            <p className="readonly-text font-weight-bold">
                              {relatedChangeData.map((rp) => (
                                <span key={rp?.name}>{rp.name}</span>
                              ))}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="program_subj">Requirement</label>
                            <p className="readonly-text font-weight-bold">
                              {selectedEpicRequirement && Array.isArray(selectedEpicRequirement) && selectedEpicRequirement?.length > 0
                                ? selectedEpicRequirement?.map((rp, inx) => (
                                  <>
                                    <span key={rp?.name}>{rp.name}</span>
                                    {inx === selectedEpicRequirement.length - 1 ? '' : ', '}
                                  </>
                                )) : 'No Data To Display '}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="program_subj">Dependent Work Package Predecessor</label>
                            <p className="readonly-text font-weight-bold">
                              {(relatedEpicData && relatedEpicData.length > 0 && Array.isArray(relatedEpicData)) ? relatedEpicData.map((dep, inx) => (
                                <Fragment key={dep?.id}>
                                  <span>{dep?.name}</span>
                                  {inx === relatedEpicData.length - 1 ? '' : ', '}
                                </Fragment>
                              ))
                                : 'No Data To Display'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* dependent work package task start */}
                {((label === 'epic:epic_task' || label === 'Epic Task') && i === 0)
                   && (
                   <div className="row">
                     <div className="col-6 col-lg-4">
                       <div className="form-group">
                         <label htmlFor="program_subj">Dependent Work Package Task Predecessor</label>
                         <p className="readonly-text font-weight-bold">
                           {(dependentEpicTask && Array.isArray(dependentEpicTask) && dependentEpicTask.length > 0) ? dependentEpicTask.map((dep, inx) => (
                             <Fragment key={dep?.id}>
                               <span>{dep?.name}</span>
                               {inx === dependentEpicTask.length - 1 ? '' : ', '}
                             </Fragment>
                           ))
                             : 'No Data To Display'}
                         </p>
                       </div>
                     </div>
                   </div>
                   )}
                {/* dependent work package task end */}

                {/* dependent work package task start */}
                {((label === 'requirement' || label === 'Requirement') && i === 0)
                   && (
                   <div className="row">
                     <div className="col-6 col-lg-4">
                       <div className="form-group">
                         <label htmlFor="program_subj">Dependent Requirement Predecessor</label>
                         <p className="readonly-text font-weight-bold">
                           {(dependentRequirement && dependentRequirement.length > 0 && Array.isArray(dependentRequirement)) ? dependentRequirement.map((dep, inx) => (
                             <Fragment key={dep?.id}>
                               <span>{dep?.name}</span>
                               {inx === dependentRequirement.length - 1 ? '' : ', '}
                             </Fragment>
                           ))
                             : 'No Data To Display'}
                         </p>
                       </div>
                     </div>
                   </div>
                   )}
                {/* dependent work package task end */}

                <div className="row">

                  {extMtData?.fields?.map(
                    (fl) => tl?.id === fl?.section_title.id && (
                      <Fragment key={fl?.id}>
                        {extMtData?.fields?.some((el) => el?.id === fl?.id && el?.field_name === 'organizations') && <div className="w-100" />}
                        {extMtData?.fields?.some((el) => el?.id === fl?.id && el?.field_name === 'consequences_factor') && <div className="w-100" />}

                        <div key={fl.id} className={`col-6  ${fl?.field_data_type === 'texteditor' ? 'col-lg-8' : 'col-lg-4'}`}>
                          {((label === 'Epic' || label === 'epic') && (fl?.field_name === 'defect_status' && epicValues?.status !== 'Test')) ? ''
                            : (
                              <div className="form-group">
                                <label htmlFor="program_subj">{t(t(`${label === 'Project' ? 'Initiative' : label === 'Epic Task' ? 'epic:epic_task' : label}:${fl?.field_name}`).name)}</label>
                                <p className="readonly-text font-weight-bold">
                                  {(((fl.field_data_type === 'text' && fl?.section_title?.name !== 'budget') || ((fl.field_data_type === 'readonly' && fl?.section_title?.name !== 'budget') || (fl.field_data_type === 'readOnly' && fl?.section_title?.name !== 'budget'))) && fl.value) ? fl.value
                                    : (fl.field_data_type === 'date' && fl?.value) ? dayjs(fl.value).format(userData?.tenant_info?.date_format)
                                      : ((fl.field_data_type === 'select' && fl.field_type !== 'Custom') && fl.value?.label) ? fl.value?.label
                                        : (fl.field_data_type === 'file'
                                        && fl.value !== null && Array.isArray(fl.value))
                                          ? (
                                            <ReadMoreLessDocument field={fl} doc={fl?.value} />
                                          )
                                          : fl.field_data_type === 'texteditor'
                                            ? (
                                              <Editor
                                                editorState={fl.value ? EditorState.createWithContent(
                                                  ContentState.createFromBlockArray(
                                                    htmlToDraft(fl.value),
                                                  ),
                                                ) : EditorState.createEmpty()}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                toolbarHidden
                                              />
                                            )
                                            : fl.field_data_type === 'textarea' && fl?.value ? <ReadMoreLess text={fl?.value} />
                                              : (fl?.field_data_type === 'text' && fl?.section_title?.name === 'budget') ? (fl?.value ? `${userData?.tenant_info?.currency_code} ${fl.value}` : '0')
                                                : ((fl.field_data_type === 'readonly' && fl?.section_title?.name === 'budget') || (fl.field_data_type === 'readOnly' && fl?.section_title?.name === 'budget')) ? (fl.value ? `${userData?.tenant_info?.currency_code} ${fl.value}` : '0')
                                                  : (fl.field_data_type === 'select' && fl.field_type === 'Custom') ? fl?.value
                                                    : (fl.field_data_type === 'textarea' && fl.field_type === 'Custom') ? <ReadMoreLess text={fl?.value} />
                                                      : (fl?.field_name === 'defect_status' && epicValues?.status === 'Test' && epicValues.defect_status) ? fl?.value
                                                        : '-'}
                                </p>
                              </div>
                            )}
                        </div>
                      </Fragment>
                    ),
                  )}
                </div>
                <hr />
              </Fragment>
            ))}

          {/* // other objecyts */}
          {review === 'program'
            && (
              <>
                <h2 className="section-title">Related Program</h2>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {(relatedHeaderProgram && relatedHeaderProgram?.length > 0 && Array.isArray(relatedHeaderProgram)) && relatedHeaderProgram?.map((ele) => (
                          <th key={ele?.name}>{ele.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData))
                        ? relatedProgData?.map((td) => (
                          <tr key={td?.id}>
                            {(relatedHeaderProgram && relatedHeaderProgram?.length > 0 && Array.isArray(relatedHeaderProgram))
                              && relatedHeaderProgram?.map((th) => (
                                (th.field === 'owner' || th.field === 'owners')
                                  ? (
                                    <td className="text-capitalize" key={th?.field}>
                                      {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                        `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                      )) : '-'}
                                    </td>
                                  )
                                  : (th.field === 'manager' || th.field === 'managers')
                                    ? (
                                      <td className="text-capitalize" key={th?.field}>
                                        {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                          `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                        )) : '-'}
                                      </td>
                                    )
                                    : th.type === 'date'
                                      ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                      : (th.field === 'name' && td[th.field]) ? (
                                        <td key={th?.field}>
                                          <Button href={`/review/program/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                                            {td[th.field]}
                                          </Button>
                                        </td>
                                      ) : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                              ))}
                          </tr>
                        ))
                        : <tr><td colSpan={7} className="text-center">No Data To Display</td></tr>}
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
            )}

          {review === 'project' && (
            <>
              <h2 className="section-title">Related Project</h2>
              <div className="table-responsive box-shadow mb-4">
                <table className="table table-hover">
                  <thead>
                    <tr className="heading-row">
                      {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader)) && relatedHeader?.map((ele) => (
                        <th key={ele?.name}>{ele.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData))
                      ? relatedInitData?.map((td) => (
                        <tr key={td?.id}>
                          {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader))
                            && relatedHeader?.map((th) => (
                              (th.field === 'owner' || th.field === 'owners')
                                ? (
                                  <td className="text-capitalize" key={th?.field}>
                                    {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                      `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                    )) : '-'}
                                  </td>
                                )
                                : (th.field === 'manager' || th.field === 'managers')
                                  ? (
                                    <td className="text-capitalize" key={th?.field}>
                                      {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                        `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                      )) : '-'}
                                    </td>
                                  )
                                  : th.type === 'date'
                                    ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                    : (th.field === 'name' && td[th.field]) ? (
                                      <td key={th?.field}>
                                        <Button href={`/review/initiative/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                                          {td[th.field]}
                                        </Button>
                                      </td>
                                    ) : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                            ))}
                        </tr>
                      ))
                      : <tr><td colSpan={6} className="text-center">No Data To Display</td></tr>}
                  </tbody>
                </table>
              </div>
              <hr />
            </>
          )}

          {review === 'risk' && (
            <>
              <h2 className="section-title">Dependent Risk</h2>
              <div className="table-responsive box-shadow mb-4">
                <table className="table table-hover">
                  <thead>
                    <tr className="heading-row">
                      <th>Name</th>
                      <th>Description</th>
                      <th>Responsible</th>
                      <th>Risk Product</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(riskData && riskData?.length > 0 && Array.isArray(riskData))
                      ? riskData.map((ele) => (
                        <tr key={ele?.id}>
                          <td><Button href={`/review/risk/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name || '-'}</Button></td>
                          <td>
                            <ReadMoreLess text={ele?.description} />
                          </td>
                          <td className="text-capitalize">{ele?.responsibility_id ? `${ele?.responsibility_id?.first_name || ''} ${ele?.responsibility_id?.last_name || ''}` : '-'}</td>
                          <td>{ele?.risk_product || '-'}</td>
                          <td>{ele?.status || '-'}</td>
                        </tr>
                      ))
                      : <tr><td colSpan={6} className="text-center">No Data To Display</td></tr>}
                  </tbody>
                </table>
              </div>
              <hr />

            </>
          )}

          {review === 'benefit' && (
            <>
              <h2 className="section-title">Dependent Benefits</h2>
              <div className="table-responsive box-shadow mb-4">
                <table className="table table-hover">
                  <thead>
                    <tr className="heading-row">
                      <th>Name</th>
                      <th>Description</th>
                      <th>Owner</th>
                      <th>Manager</th>
                      <th>Realization Completion Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(benefitData && benefitData?.length > 0 && Array.isArray(benefitData))
                      ? benefitData.map((ele) => (
                        <tr key={ele?.id}>
                          <td><Button href={`/review/benefit/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name}</Button></td>
                          <td>
                            <ReadMoreLess text={ele?.description} />
                          </td>
                          <td className="text-capitalize">
                            {(ele?.owners && ele?.owners?.length > 0 && Array.isArray(ele?.owners))
                              ? ele?.owners?.map((mn) => `${mn?.first_name || ''}  ${mn?.last_name || ''}`)
                              : '-'}
                          </td>
                          <td className="text-capitalize">
                            {(ele?.managers && ele?.managers?.length > 0 && Array.isArray(ele?.managers))
                              ? ele?.managers?.map((mn) => `${mn?.first_name || ''}  ${mn?.last_name || ''}`)
                              : '-'}
                          </td>
                          <td>{dayjs(ele?.realisation_completion_date).format(userData?.tenant_info?.date_format) || '-'}</td>
                          <td>{ele?.status}</td>
                        </tr>
                      ))
                      : <tr><td colSpan={4} className="text-center">No Data To Display</td></tr>}
                  </tbody>
                </table>
              </div>
              <hr />
            </>
          )}

          {(review === 'risk' || review === 'benefit' || review === 'change')
            && (
              <>
                <h2 className="section-title">Dependent Transformation Objects</h2>
                <h3>Programs</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {(relatedHeaderProgram && relatedHeaderProgram?.length > 0 && Array.isArray(relatedHeaderProgram)) && relatedHeaderProgram?.map((ele) => (
                          <th key={ele?.name}>{ele.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData))
                        ? relatedProgData?.map((td) => (
                          <tr key={td?.id}>
                            {(relatedHeaderProgram && relatedHeaderProgram?.length > 0 && Array.isArray(relatedHeaderProgram))
                              && relatedHeaderProgram?.map((th) => (
                                (th.field === 'owner' || th.field === 'owners')
                                  ? (
                                    <td className="text-capitalize" key={th?.field}>
                                      {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                        `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                      )) : '-'}
                                    </td>
                                  )
                                  : (th.field === 'manager' || th.field === 'managers')
                                    ? (
                                      <td className="text-capitalize" key={th?.field}>
                                        {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                          `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                        )) : '-'}
                                      </td>
                                    )
                                    : th.type === 'date'
                                      ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                      : (th.field === 'name' && td[th.field]) ? (
                                        <td key={th?.field}>
                                          <Button href={`/review/program/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                                            {td[th.field]}
                                          </Button>
                                        </td>
                                      ) : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                              ))}
                          </tr>
                        ))
                        : <tr><td colSpan={7} className="text-center">No Data To Display</td></tr>}
                    </tbody>
                  </table>
                </div>
                {label !== 'Change'
                  && (
                    <>
                      <h3>Projects</h3>
                      <div className="table-responsive box-shadow mb-4">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader)) && relatedHeader?.map((ele) => (
                                <th key={ele?.name}>{ele.name}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {(relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData))
                              ? relatedInitData?.map((td) => (
                                <tr key={td?.id}>
                                  {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader))
                                    && relatedHeader?.map((th) => (
                                      (th.field === 'owner' || th.field === 'owners')
                                        ? (
                                          <td className="text-capitalize" key={th?.field}>
                                            {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                              `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                            )) : '-'}
                                          </td>
                                        )
                                        : (th.field === 'manager' || th.field === 'managers')
                                          ? (
                                            <td className="text-capitalize" key={th?.field}>
                                              {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                                `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                              )) : '-'}
                                            </td>
                                          )
                                          : th.type === 'date'
                                            ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                            : (th.field === 'name' && td[th.field]) ? (
                                              <td key={th?.field}>
                                                <Button href={`/review/initiative/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                                                  {td[th.field]}
                                                </Button>
                                              </td>
                                            ) : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                                    ))}
                                </tr>
                              ))
                              : <tr><td colSpan={7} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                <h3>{(label === 'change' || label === 'Change') ? 'Change Predecessor' : 'Changes'}</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader)) && relatedHeader?.map((ele) => (
                          <th key={ele?.name}>{ele.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(relatedChangeData && relatedChangeData?.length > 0 && Array.isArray(relatedChangeData))
                        ? relatedChangeData?.map((td) => (
                          <tr key={td?.id}>
                            {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader))
                              && relatedHeader?.map((th) => (
                                (th.field === 'owner' || th.field === 'owners')
                                  ? (
                                    <td className="text-capitalize" key={th?.field}>
                                      {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                        `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                      )) : '-'}
                                    </td>
                                  )
                                  : (th.field === 'manager' || th.field === 'managers')
                                    ? (
                                      <td className="text-capitalize" key={th?.field}>
                                        {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                          `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                        )) : '-'}
                                      </td>
                                    )
                                    : th.type === 'date'
                                      ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                      : (th.field === 'name' && td[th.field]) ? (
                                        <td key={th?.field}>
                                          <Button href={`/review/change/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                                            {td[th.field]}
                                          </Button>
                                        </td>
                                      ) : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                              ))}
                          </tr>
                        ))
                        : <tr><td colSpan={7} className="text-center">No Data To Display</td></tr>}
                    </tbody>
                  </table>
                </div>
              </>
            )}

          {review === 'applicationsss'
            && (
              <>
                <h2 className="section-title">Related Objects</h2>
                <h3>Changes</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Responsible</th>
                        <th>Category</th>
                        <th>Risk Product</th>
                        <th>Programs</th>
                        <th>Projects</th>
                        <th>Changes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>Presentation Layers</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Responsible</th>
                        <th>Category</th>
                        <th>Risk Product</th>
                        <th>Programs</th>
                        <th>Projects</th>
                        <th>Changes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>Business Logic Layers</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Responsible</th>
                        <th>Category</th>
                        <th>Risk Product</th>
                        <th>Programs</th>
                        <th>Projects</th>
                        <th>Changes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>Data Layers</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        <th>ID</th>
                        <th>Name</th>
                        <th>Responsible</th>
                        <th>Category</th>
                        <th>Risk Product</th>
                        <th>Programs</th>
                        <th>Projects</th>
                        <th>Changes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                      <tr>
                        <td>RIK84947HH</td>
                        <td>Rist Title User Given</td>
                        <td>Arun Kumar</td>
                        <td>Customer Config</td>
                        <td>12</td>
                        <td>Customer Upgrade</td>
                        <td>Project _123 (+2)</td>
                        <td>Change _123 (+2)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <h2 className="section-title">Supplier</h2>
                <h3>Supplier</h3>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        <th>Name</th>
                        <th>Manager</th>
                        <th>Team</th>
                        <th className="date-col">Delivery Date</th>
                        <th>Changes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a href="#">Private Customer onboarding</a>
                        </td>
                        <td>Gisele Muller</td>
                        <td>Ingrid Krauss</td>
                        <td>01/11/2021</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">Corporate Customer onboarding</a>
                        </td>
                        <td>Gisele Muller</td>
                        <td>Ingrid Krauss</td>
                        <td>01/11/2021</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">Corporate Customer onboarding</a>
                        </td>
                        <td>Gisele Muller</td>
                        <td>Ingrid Krauss</td>
                        <td>01/11/2021</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">Corporate Customer onboarding</a>
                        </td>
                        <td>Gisele Muller</td>
                        <td>Ingrid Krauss</td>
                        <td>01/11/2021</td>
                        <td>4</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
            )}

          {(fieldss && fieldss?.length > 0 && Array.isArray(fieldss))
            && fieldss.map((item, index) => {
              if (item.name === 'people') {
                return (
                  <React.Fragment key={item.name}>
                    <h2 className="h1">
                      {2 + index}
                      .
                      {' '}
                      People
                    </h2>
                    <div className="row">
                      {peopleView?.owner
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Owner</label>
                              {(peopleData?.owner && peopleData?.owner?.length > 0 && Array.isArray(peopleData?.owner)) ? (
                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {peopleData?.owner.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.assignee
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Assignee</label>
                              {label === 'epic:epic_task'
                                ? (epicTaskPeople?.assignee && epicTaskPeople?.assignee?.length > 0 && Array.isArray(epicTaskPeople?.assignee)) ? (
                                  <p className="readonly-text font-weight-bold text-capitalize">
                                    {epicTaskPeople?.assignee.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                    ))}
                                  </p>
                                ) : '-'
                                : (peopleData?.assignee && peopleData?.assignee?.length > 0 && Array.isArray(peopleData?.assignee)) ? (
                                  <p className="readonly-text font-weight-bold text-capitalize">
                                    {peopleData?.assignee.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                    ))}
                                  </p>
                                ) : '-'}

                            </div>
                          </div>
                        )}
                      {peopleView?.resp_person
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Responsible Person</label>
                              {(peopleData?.resp_person && peopleData?.resp_person?.length > 0 && Array.isArray(peopleData?.resp_person)) ? (
                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {peopleData?.resp_person?.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.manager
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Manager</label>
                              {(peopleData?.manager && peopleData?.manager?.length > 0 && Array.isArray(peopleData?.manager)) ? (
                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {peopleData?.manager?.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.sponsor
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Sponsor</label>
                              {(peopleData?.sponsor && peopleData?.sponsor?.length > 0 && Array.isArray(peopleData?.sponsor)) ? (
                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {peopleData?.sponsor.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.stCom
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Steering Committee</label>
                              {(peopleData?.stcom && peopleData?.stcom?.length > 0 && Array.isArray(peopleData?.stcom)) ? (
                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {peopleData?.stcom.map((ow, i) => (
                                    <Fragment key={ow?.id}>
                                      <span>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                      {i === peopleData.stcom.length - 1
                                        ? ''
                                        : ', '}
                                    </Fragment>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.teams
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Team Member</label>
                              {(peopleData?.teams && peopleData?.teams?.length > 0 && Array.isArray(peopleData?.teams)) ? (
                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {' '}
                                  {peopleData?.teams.map((ow, i) => (
                                    <Fragment key={ow?.id}>
                                      <span>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                      {i === peopleData.teams.length - 1
                                        ? ''
                                        : ', '}
                                    </Fragment>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.risk
                        && (
                          <div className="col-6 col-lg-4">
                            <div className="form-group">
                              <label htmlFor="program_subj">Responsible Person</label>
                              {(peopleData?.risk && peopleData?.risk?.length > 0 && Array.isArray(peopleData?.risk)) ? (

                                <p className="readonly-text font-weight-bold text-capitalize">
                                  {' '}
                                  {peopleData?.risk.map((rk, i) => (
                                    <Fragment key={rk?.id}>
                                      <span>{`${rk?.first_name || ''} ${rk?.last_name || ''}`}</span>
                                      {i === peopleData.risk.length - 1
                                        ? ''
                                        : ', '}
                                    </Fragment>
                                  ))}
                                </p>
                              ) : '-'}
                            </div>
                          </div>
                        )}
                      {peopleView?.collaborator
                      && (
                      <div className="col-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="program_subj">Collaborator</label>
                          {(collaborator && Array.isArray(collaborator) && collaborator?.length > 0)
                            ? (
                              <p className="readonly-text font-weight-bold text-capitalize" dangerouslySetInnerHTML={{ __html: collaborator && Array.isArray(collaborator) && collaborator?.length > 0 ? collaborator?.join(', ') : '-' }} />
                            )
                            : '-'}
                          <br />

                        </div>
                      </div>
                      )}
                    </div>
                    <hr />
                  </React.Fragment>
                );
              } if (item.name === 'risk') {
                return (
                  <>
                    <h2 className="h1">
                      {index + 2}
                      .
                      {' '}
                      Risks
                    </h2>
                    <div className="table-responsive box-shadow mb-4">
                      <table className="table table-hover">
                        <thead>
                          <tr className="heading-row">
                            <th>Name</th>
                            <th>Description</th>
                            <th>Responsible</th>
                            <th>Risk Product</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(riskData && riskData?.length > 0 && Array.isArray(riskData))
                            ? riskData.map((ele) => (
                              <tr key={ele?.id}>
                                <td><Button href={`/review/risk/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name}</Button></td>
                                <td>
                                  <ReadMoreLess text={ele?.description} />
                                </td>
                                <td className="text-capitalize">{ele?.responsibility_id ? `${ele?.responsibility_id?.first_name || ''} ${ele?.responsibility_id?.last_name || ''}` : '-'}</td>
                                <td>{ele?.risk_product || '-'}</td>
                                <td>{ele?.status || '-'}</td>
                              </tr>
                            ))
                            : <tr><td colSpan={5} className="text-center">No Data To Display</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <hr />
                  </>
                );
              } if (item.name === 'benefit') {
                return (
                  <>
                    <h2 className="h1">

                      {index + 2}
                      .
                      {' '}
                      Benefits
                    </h2>
                    <div className="table-responsive box-shadow mb-4">
                      <table className="table table-hover">
                        <thead>
                          <tr className="heading-row">
                            <th>Name</th>
                            <th>Description</th>
                            <th>Owner</th>
                            <th>Manager</th>
                            <th>Realization Completion Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(benefitData && benefitData?.length > 0 && Array.isArray(benefitData))
                            ? benefitData.map((ele) => (
                              <tr key={ele?.id}>
                                <td><Button href={`/review/benefit/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name}</Button></td>
                                <td>
                                  <ReadMoreLess text={ele?.description} />
                                </td>
                                <td className="text-capitalize">
                                  {(ele?.owners && ele?.owners?.length > 0 && Array.isArray(ele?.owners))
                                    ? ele?.owners?.map((mn) => `${mn?.first_name || ''}  ${mn?.last_name || ''}`)
                                    : '-'}
                                </td>
                                <td className="text-capitalize">
                                  {(ele?.managers && ele?.managers?.length > 0 && Array.isArray(ele?.managers))
                                    ? ele?.managers?.map((mn) => `${mn?.first_name || ''}  ${mn?.last_name || ''}`)
                                    : '-'}
                                </td>
                                <td>{dayjs(ele?.realisation_completion_date).format(userData?.tenant_info?.date_format) || '-'}</td>
                                <td>{ele?.status}</td>
                              </tr>
                            ))
                            : <tr><td colSpan={6} className="text-center">No Data To Display</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <hr className="mb-0" />
                  </>
                );
              } if (item.name === 'capabilities') {
                return (
                  <>
                    <h2 className="h1">
                      {index + 2}
                      {'. '}
                      Capabilities
                    </h2>
                    {(processCapabilitiesData || capabilitiesData || businessCapsData || vendorCapsData || customerCapsData || productCapsData)
                      ? (
                        <div id="expand-demo">
                          <div id="wrpp" className={`capability-cards ${(label === 'Change' || label === 'change') ? 'change-caps' : 'review-cards'}`}>
                            <div id="fsm_container" className="fsm-container">
                              {((review === 'change' || label === 'Change') && changeCapabilities && Array.isArray(changeCapabilities) && changeCapabilities?.length > 0)
                                ? (
                                  <>
                                    {changeCapabilities?.map((cc) => (
                                      <div className="fsm" key={cc?.title}>
                                        <div className="card">
                                          <h3 className="card-header">
                                            <span className={cc.icon} />
                                            {cc.title}
                                            {' '}
                                            {`(${cc?.value?.length})`}
                                          </h3>
                                          {(cc?.value && cc?.value.length > 0 && Array.isArray(cc?.value))
                                            ? (
                                              <div className="card-body">
                                                <table className="table table-borderless">
                                                  <thead>
                                                    <tr>
                                                      <th className="sm-col">&nbsp;</th>
                                                      <th>{`${cc?.title}  Name`}</th>
                                                      <th>Delivery Date</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {(cc?.value && cc?.value.length > 0 && Array.isArray(cc?.value)) && cc?.value?.slice(0, 5)?.map((ccVal) => (
                                                      <tr key={ccVal?.name}>
                                                        <td className="sm-col">
                                                          <span className={cc.icon} />
                                                        </td>
                                                        <td>
                                                          <OverlayTrigger placement="bottom" overlay={renderTooltip(ccVal?.object_name)}>
                                                            <Button
                                                              className="text-decoration-none cap-link"
                                                              variant="link"
                                                              target="_blank"
                                                              href={`/review/${cc?.key}/${ccVal?.id}`}
                                                            >
                                                              {ccVal?.object_name?.length >= 75 ? `${ccVal?.object_name?.substring(0, 75)}...` : ccVal?.object_name}
                                                            </Button>
                                                          </OverlayTrigger>
                                                        </td>
                                                        <td>{ccVal?.delivery_date ? dayjs(ccVal?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </table>
                                                {cc?.value?.length > 5
                                                    && (
                                                      <div className="more-link d-flex justify-content-end">
                                                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, cc?.key)}>more</button>
                                                      </div>
                                                    )}
                                              </div>
                                            )
                                            : (
                                              <div className="card-body empty">
                                                <h1>No Data To Display</h1>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    ))}
                                    <div id="fsm_actual">
                                      {expanded
                                          && (
                                            <ReadOnlyCaps
                                              appCapabilitiesData={changeCapabilities?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()}
                                              processCapabilitiesData={changeCapabilities?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()}
                                              businessCapabilitiesData={changeCapabilities?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()}
                                              vendorCapabilitiesData={changeCapabilities?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()}
                                              customerCapabilitiesData={changeCapabilities?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()}
                                              setExpanded={setExpanded}
                                              size={size}
                                              position={position}
                                              capsInfo={capsInfo}
                                            />
                                          )}
                                    </div>
                                  </>
                                )
                                : (
                                  <>
                                    <div className="fsm">
                                      {(processCapabilitiesData && Array.isArray(processCapabilitiesData) && processCapabilitiesData.length > 0)
                                        ? (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-value-chain" />
                                              Process
                                              {' '}
                                              {`(${processCapabilitiesData.length})`}
                                            </h3>
                                            <div className="card-body">
                                              <table className="table table-borderless">
                                                <thead>
                                                  <tr>
                                                    <th className="sm-col">&nbsp;</th>
                                                    <th>Process Name</th>
                                                    <th>Delivery Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {processCapabilitiesData?.slice(0, 5)?.map((ele) => (
                                                    <tr key={ele?.name}>
                                                      <td className="sm-col">
                                                        <span className="icon-value-chain" />
                                                      </td>
                                                      <td>
                                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                          <Button
                                                            className="text-decoration-none"
                                                            variant="link"
                                                            target="_blank"
                                                            href={`/review/process/${ele?.id}`}
                                                          >
                                                            {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                          </Button>
                                                        </OverlayTrigger>
                                                      </td>
                                                      <td>{label === 'Requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                              {processCapabilitiesData?.length > 5
                                                  && (
                                                    <div className="more-link d-flex justify-content-end">
                                                      <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Process')}>more</button>
                                                    </div>
                                                  )}
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-value-chain" />
                                              Process
                                            </h3>
                                            <div className="card-body empty">
                                              <h1>No Data To Display</h1>
                                            </div>
                                          </div>
                                        )}
                                    </div>

                                    <div className="fsm">
                                      {(capabilitiesData && Array.isArray(capabilitiesData) && capabilitiesData.length > 0)
                                        ? (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-application" />
                                              Application
                                              {' '}
                                              {`(${capabilitiesData.length})`}
                                            </h3>
                                            <div className="card-body">
                                              <table className="table table-borderless">
                                                <thead>
                                                  <tr>
                                                    <th className="sm-col">&nbsp;</th>
                                                    <th>Application Name</th>
                                                    <th>Delivery Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {(updatedCaps && Array.isArray(updatedCaps) && updatedCaps.length)
                                                    ? updatedCaps?.slice(0, 5)?.map((ele) => (
                                                      <tr key={ele?.name}>
                                                        <td className="sm-col">
                                                          <span className="icon-application" />
                                                        </td>
                                                        <td>
                                                          <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                            {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                          </OverlayTrigger>
                                                        </td>
                                                        <td>{ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                      </tr>
                                                    ))
                                                    : capabilitiesData?.slice(0, 5)?.map((ele) => (
                                                      <tr key={ele?.name}>
                                                        <td className="sm-col">
                                                          <span className="icon-application" />
                                                        </td>
                                                        <td>
                                                          <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                            <Button
                                                              className="text-decoration-none"
                                                              variant="link"
                                                              target="_blank"
                                                              href={`/review/application/${ele?.id}`}
                                                            >
                                                              {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                            </Button>
                                                          </OverlayTrigger>
                                                        </td>
                                                        <td>{label === 'Requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                      </tr>
                                                    ))}
                                                </tbody>
                                              </table>
                                              {capabilitiesData?.length > 5
                                                  && (
                                                    <div className="more-link d-flex justify-content-end">
                                                      <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Application')}>more</button>
                                                    </div>
                                                  )}
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-application" />
                                              Application
                                            </h3>
                                            <div className="card-body empty">
                                              <h1>No Data To Display</h1>
                                            </div>
                                          </div>
                                        )}
                                    </div>

                                    {/* business caps  start */}
                                    <div className="fsm">
                                      {(businessCapsData && Array.isArray(businessCapsData) && businessCapsData.length > 0)
                                        ? (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-business" />
                                              Business
                                              {' '}
                                              {`(${businessCapsData.length})`}
                                            </h3>
                                            <div className="card-body">
                                              <table className="table table-borderless">
                                                <thead>
                                                  <tr>
                                                    <th className="sm-col">&nbsp;</th>
                                                    <th>Business Name</th>
                                                    <th>Delivery Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {(updatedCaps && Array.isArray(updatedCaps) && updatedCaps.length)
                                                    ? updatedCaps?.slice(0, 5)?.map((ele) => (
                                                      <tr key={ele?.name}>
                                                        <td className="sm-col">
                                                          <span className="icon-application" />
                                                        </td>
                                                        <td>
                                                          <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                            {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                          </OverlayTrigger>
                                                        </td>
                                                        <td>{ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                      </tr>
                                                    ))
                                                    : businessCapsData?.slice(0, 5)?.map((ele) => (
                                                      <tr key={ele?.name}>
                                                        <td className="sm-col">
                                                          <span className="icon-business" />
                                                        </td>
                                                        <td>
                                                          <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                            <Button
                                                              className="text-decoration-none"
                                                              variant="link"
                                                              target="_blank"
                                                              href={`/review/business/${ele?.id}`}
                                                            >
                                                              {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                            </Button>
                                                          </OverlayTrigger>
                                                        </td>
                                                        <td>{label === 'Requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                      </tr>
                                                    ))}
                                                </tbody>
                                              </table>
                                              {businessCapsData?.length > 5
                                                  && (
                                                    <div className="more-link d-flex justify-content-end">
                                                      <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Business')}>more</button>
                                                    </div>
                                                  )}
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-business" />
                                              Business
                                            </h3>
                                            <div className="card-body empty">
                                              <h1>No Data To Display</h1>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {/* business caps  end */}

                                    {/* vendor caps  start */}
                                    <div className="fsm">
                                      {(vendorCapsData && Array.isArray(vendorCapsData) && vendorCapsData.length > 0)
                                        ? (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-supplier" />
                                              Vendor
                                              {' '}
                                              {`(${vendorCapsData.length})`}
                                            </h3>
                                            <div className="card-body">
                                              <table className="table table-borderless">
                                                <thead>
                                                  <tr>
                                                    <th className="sm-col">&nbsp;</th>
                                                    <th>Vendor Name</th>
                                                    <th>Delivery Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {vendorCapsData?.slice(0, 5)?.map((ele) => (
                                                    <tr key={ele?.name}>
                                                      <td className="sm-col">
                                                        <span className="icon-supplier" />
                                                      </td>
                                                      <td>
                                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                          <Button
                                                            className="text-decoration-none"
                                                            variant="link"
                                                            target="_blank"
                                                            href={`/review/vendor/${ele?.id}`}
                                                          >
                                                            {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                          </Button>
                                                        </OverlayTrigger>
                                                      </td>
                                                      <td>{label === 'Requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                              {vendorCapsData?.length > 5
                                                  && (
                                                    <div className="more-link d-flex justify-content-end">
                                                      <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Vendor')}>more</button>
                                                    </div>
                                                  )}
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-supplier" />
                                              Vendor
                                            </h3>
                                            <div className="card-body empty">
                                              <h1>No Data To Display</h1>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {/* vendor caps  end */}

                                    {/* customer caps  start */}
                                    <div className="fsm">
                                      {(customerCapsData && Array.isArray(customerCapsData) && customerCapsData.length > 0)
                                        ? (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-consumer" />
                                              Customer
                                              {' '}
                                              {`(${customerCapsData.length})`}
                                            </h3>
                                            <div className="card-body">
                                              <table className="table table-borderless">
                                                <thead>
                                                  <tr>
                                                    <th className="sm-col">&nbsp;</th>
                                                    <th>Customer Name</th>
                                                    <th>Delivery Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {customerCapsData?.slice(0, 5)?.map((ele) => (
                                                    <tr key={ele?.name}>
                                                      <td className="sm-col">
                                                        <span className="icon-consumer" />
                                                      </td>
                                                      <td>
                                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                          <Button
                                                            className="text-decoration-none"
                                                            variant="link"
                                                            target="_blank"
                                                            href={`/review/customer/${ele?.id}`}
                                                          >
                                                            {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                          </Button>
                                                        </OverlayTrigger>
                                                      </td>
                                                      <td>{label === 'Requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                              {customerCapsData?.length > 5
                                                  && (
                                                    <div className="more-link d-flex justify-content-end">
                                                      <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Customer')}>more</button>
                                                    </div>
                                                  )}
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-consumer" />
                                              Customer
                                            </h3>
                                            <div className="card-body empty">
                                              <h1>No Data To Display</h1>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {/* customer caps  end */}

                                    {/* product caps  start */}
                                    <div className="fsm">
                                      {(productCapsData && Array.isArray(productCapsData) && productCapsData.length > 0)
                                        ? (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-toolbar" />
                                              Product
                                              {' '}
                                              {`(${productCapsData.length})`}
                                            </h3>
                                            <div className="card-body">
                                              <table className="table table-borderless">
                                                <thead>
                                                  <tr>
                                                    <th className="sm-col">&nbsp;</th>
                                                    <th>Product Name</th>
                                                    <th>Delivery Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {productCapsData?.slice(0, 5)?.map((ele) => (
                                                    <tr key={ele?.name}>
                                                      <td className="sm-col">
                                                        <span className="icon-toolbar" />

                                                      </td>
                                                      <td>
                                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                          <Button
                                                            className="text-decoration-none"
                                                            variant="link"
                                                            target="_blank"
                                                            href={`/review/product/${ele?.id}`}
                                                          >
                                                            {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                          </Button>
                                                        </OverlayTrigger>
                                                      </td>
                                                      <td>{label === 'Requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                              {productCapsData?.length > 5
                                                  && (
                                                    <div className="more-link d-flex justify-content-end">
                                                      <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Product')}>more</button>
                                                    </div>
                                                  )}
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className="card">
                                            <h3 className="card-header">
                                              <span className="icon-toolbar" />

                                              Product
                                            </h3>
                                            <div className="card-body empty">
                                              <h1>No Data To Display</h1>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {/* customer caps  end */}

                                    <div id="fsm_actual">
                                      {expanded
                                          && (
                                            <ReadOnlyCaps
                                              appCapabilitiesData={capabilitiesData}
                                              processCapabilitiesData={processCapabilitiesData}
                                              businessCapabilitiesData={businessCapsData}
                                              vendorCapabilitiesData={vendorCapsData}
                                              customerCapabilitiesData={customerCapsData}
                                              setExpanded={setExpanded}
                                              size={size}
                                              position={position}
                                              capsInfo={capsInfo}
                                            />
                                          )}
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      )
                      : <p>No Capabilities</p>}
                    <hr />
                  </>
                );
              } if (item.name === 'capabilityTwins') {
                return (
                  (capabilitiesData) && (
                    <>
                      <h2 className="h1">
                        {index + 2}
                        {'. '}
                        Capability Twins: Task
                      </h2>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Application Name</th>
                              <th>Task ID</th>
                              <th>Task Name</th>
                              {/* <th>Change Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(capabilitiesData && capabilitiesData.length > 0 && Array.isArray(capabilitiesData))
                              ? capabilitiesData?.map((ele) => (
                                <tr key={ele?.digital_twin_data?.name}>
                                  <td>
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/application/${ele?.digital_twin_data?.id}`}
                                    >
                                      {ele?.digital_twin_data?.name}
                                    </Button>
                                  </td>
                                  <td>
                                    {ele?.tasks?.map((tsk, inx) => (
                                      <Fragment key={tsk?.unique_id}>
                                        <span>
                                          {tsk?.unique_id}
                                        </span>
                                        {(ele?.tasks && Array.isArray(ele?.tasks) && ele?.tasks.length) && inx === ele.tasks.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))}
                                  </td>
                                  <td>{ele?.tasks?.map((tsk) => tsk?.name)}</td>
                                  {/* <td>
                                    <ReadMoreLess text={ele?.digital_twin_data?.change_details || ''} />
                                  </td> */}
                                </tr>
                              )) : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>

                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Process Name</th>
                              <th>Task ID</th>
                              <th>Task Name</th>
                              {/* <th>Change Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(processCapabilitiesData && processCapabilitiesData.length > 0 && Array.isArray(processCapabilitiesData))
                              ? processCapabilitiesData?.map((ele) => (
                                <tr key={ele?.digital_twin_data?.name}>
                                  <td>
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/process/${ele?.digital_twin_data?.id}`}
                                    >
                                      {ele?.digital_twin_data?.name}
                                    </Button>
                                  </td>
                                  <td>
                                    {ele?.tasks?.map((tsk, inx) => (
                                      <Fragment key={tsk?.unique_id}>
                                        <span>
                                          {tsk?.unique_id}
                                        </span>
                                        {(ele?.tasks && Array.isArray(ele?.tasks) && ele?.tasks.length) && inx === ele.tasks.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))}
                                  </td>
                                  <td>{ele?.tasks?.map((tsk) => tsk?.name)}</td>
                                  {/* <td>
                                    <ReadMoreLess text={ele?.digital_twin_data?.change_details || ''} />
                                  </td> */}
                                </tr>
                              )) : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>
                      </div>

                      {/* business start */}
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Business Name</th>
                              <th>Task ID</th>
                              <th>Task Name</th>
                              {/* <th>Change Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(businessCapsData && Array.isArray(businessCapsData) && businessCapsData.length > 0)
                              ? businessCapsData?.map((ele) => (
                                <tr key={ele?.digital_twin_data?.name}>
                                  <td>
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/business/${ele?.digital_twin_data?.id}`}
                                    >
                                      {ele?.digital_twin_data?.name}
                                    </Button>
                                  </td>
                                  <td>
                                    {ele?.tasks?.map((tsk, inx) => (
                                      <Fragment key={tsk?.unique_id}>
                                        <span>
                                          {tsk?.unique_id}
                                        </span>
                                        {(ele?.tasks && Array.isArray(ele?.tasks) && ele?.tasks.length) && inx === ele.tasks.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))}
                                  </td>
                                  <td>{ele?.tasks?.map((tsk) => tsk?.name)}</td>
                                  {/* <td>
                                    <ReadMoreLess text={ele?.digital_twin_data?.change_details || ''} />
                                  </td> */}
                                </tr>
                              )) : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>

                      </div>
                      {/* business end */}

                      {/* vendor start */}
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Vendor Name</th>
                              <th>Task ID</th>
                              <th>Task Name</th>
                              {/* <th>Change Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(vendorCapsData && vendorCapsData.length > 0 && Array.isArray(vendorCapsData))
                              ? vendorCapsData?.map((ele) => (
                                <tr key={ele?.digital_twin_data?.name}>
                                  <td>
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/vendor/${ele?.digital_twin_data?.id}`}
                                    >
                                      {ele?.digital_twin_data?.name}
                                    </Button>
                                  </td>
                                  <td>
                                    {ele?.tasks?.map((tsk, inx) => (
                                      <Fragment key={tsk?.unique_id}>
                                        <span>
                                          {tsk?.unique_id}
                                        </span>
                                        {(ele?.tasks && Array.isArray(ele?.tasks) && ele?.tasks.length) && inx === ele.tasks.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))}
                                  </td>
                                  <td>{ele?.tasks?.map((tsk) => tsk?.name)}</td>
                                  {/* <td>
                                    <ReadMoreLess text={ele?.digital_twin_data?.change_details || ''} />
                                  </td> */}
                                </tr>
                              )) : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>
                      </div>
                      {/* vendor end */}

                      {/* customer start */}
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Customer Name</th>
                              <th>Task ID</th>
                              <th>Task Name</th>
                              {/* <th>Change Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(customerCapsData && customerCapsData.length > 0 && Array.isArray(customerCapsData))
                              ? customerCapsData?.map((ele) => (
                                <tr key={ele?.digital_twin_data?.name}>
                                  <td>
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/customer/${ele?.digital_twin_data?.id}`}
                                    >
                                      {ele?.digital_twin_data?.name}
                                    </Button>
                                  </td>
                                  <td>
                                    {ele?.tasks?.map((tsk, inx) => (
                                      <Fragment key={tsk?.unique_id}>
                                        <span>
                                          {tsk?.unique_id}
                                        </span>
                                        {(ele?.tasks && Array.isArray(ele?.tasks) && ele?.tasks.length) && inx === ele.tasks.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))}
                                  </td>
                                  <td>{ele?.tasks?.map((tsk) => tsk?.name)}</td>
                                  {/* <td>
                                    <ReadMoreLess text={ele?.digital_twin_data?.change_details || ''} />
                                  </td> */}
                                </tr>
                              )) : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>
                      </div>
                      {/* customer end */}

                      {/* product start */}
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Product Name</th>
                              <th>Task ID</th>
                              <th>Task Name</th>
                              {/* <th>Change Details</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(productCapsData && productCapsData.length > 0 && Array.isArray(productCapsData))
                              ? productCapsData?.map((ele) => (
                                <tr key={ele?.digital_twin_data?.name}>
                                  <td>
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/product/${ele?.digital_twin_data?.id}`}
                                    >
                                      {ele?.digital_twin_data?.name}
                                    </Button>
                                  </td>
                                  <td>
                                    {ele?.tasks?.map((tsk, inx) => (
                                      <Fragment key={tsk?.unique_id}>
                                        <span>
                                          {tsk?.unique_id}
                                        </span>
                                        {(ele?.tasks && Array.isArray(ele?.tasks) && ele?.tasks.length) && inx === ele.tasks.length - 1 ? '' : ', '}
                                      </Fragment>
                                    ))}
                                  </td>
                                  <td>{ele?.tasks?.map((tsk) => tsk?.name)}</td>
                                  {/* <td>
                                    <ReadMoreLess text={ele?.digital_twin_data?.change_details || ''} />
                                  </td> */}
                                </tr>
                              )) : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                          </tbody>
                        </table>
                      </div>
                      {/* product end */}
                      {/* <hr /> */}
                    </>
                  )
                );
              } if (item.name === 'epic-task') {
                return (
                  <>
                    <h2 className="h1">

                      {index + 2}
                      .
                      {' '}
                      Transformation Tasks
                    </h2>
                    <div className="table-responsive box-shadow mb-4">
                      <table className="table table-hover">
                        <thead>
                          <tr className="heading-row">
                            <th>Name</th>
                            <th>Description</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Efforts</th>
                            <th>Assignee</th>
                            {/* <th>Notes</th> */}
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selectedEpicTask && Array.isArray(selectedEpicTask) && selectedEpicTask?.length > 0) ? selectedEpicTask?.map((ele) => (
                            <tr key={ele?.id}>
                              <td><Button href={`/review/epic-task/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name || '-'}</Button></td>
                              <td>
                                <ReadMoreLess text={ele?.description} />
                              </td>
                              <td className="text-capitalize">{ele?.start_date ? dayjs(ele?.start_date).format(userData?.tenant_info?.date_format) : '-'}</td>
                              <td className="text-capitalize">{ele?.end_date ? dayjs(ele?.end_date).format(userData?.tenant_info?.date_format) : '-'}</td>
                              <td className="text-capitalize">{ele?.estimated_efforts ? `${ele?.estimated_efforts} Days` : ele?.estimated_effort ? `${ele?.estimated_effort} Days` : '-'}</td>
                              <td className="text-capitalize">
                                {ele?.assignee ? `${ele?.assignee?.first_name || ''}  ${ele?.assignee?.last_name || ''}` : '-'}
                              </td>
                              {/* <td className="text-capitalize"><ReadMoreLess text={ele?.notes || '-'} /></td> */}
                              <td>{ele?.status || '-'}</td>
                            </tr>
                          ))
                            : <tr className="text-center"><td colSpan={7}>No Data To Display</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <hr className="mb-0" />
                  </>
                );
              } if (item.name === 'requirement') {
                return (
                  <>
                    <h2 className="h1">

                      {index + 2}
                      .
                      {' '}
                      Requirements
                    </h2>
                    <div className="table-responsive box-shadow mb-4">
                      <table className="table table-hover">
                        <thead>
                          <tr className="heading-row">
                            <th>Name</th>
                            <th>Description</th>
                            {/* <th>Depending Capabilities</th>
                            <th>Required Resource</th>
                            <th>Notes</th>
                            <th>Related Epics</th> */}
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(requirementData && requirementData?.length > 0 && Array.isArray(requirementData))
                            ? requirementData?.map((ele) => (
                              <tr key={ele?.id}>
                                <td><Button href={`/review/requirement/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name}</Button></td>
                                <td>
                                  <ReadMoreLess text={ele?.description || '-'} />
                                </td>
                                {/* <td className="text-capitalize">-</td>
                                <td className="text-capitalize">-</td>
                                <td className="text-capitalize"><ReadMoreLess text={ele?.notes || '-'} /></td>
                                <td className="text-capitalize">-</td> */}
                                <td>{ele?.status || '-'}</td>
                              </tr>
                            ))
                            : <tr><td colSpan={3} className="text-center">No Data To Display</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <hr className="mb-0" />
                  </>
                );
              } if (item.name === 'mitigation') {
                return (
                  <>
                    <h2 className="h1">
                      {index + 2}
                      {'. '}
                      Risk Mitigation
                    </h2>
                    <h2 className="section-title">Risk Mitigation Actions</h2>
                    <div className="table-responsive box-shadow mb-4">
                      <table className="table table-hover">
                        <thead>
                          <tr className="heading-row">
                            <th>Risk Mitigation Owner</th>
                            <th>Mitigation Status</th>
                            <th>Created By</th>
                            <th className="date-col">Action Date</th>
                            <th className="date-col">Updated Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(mitigationData && mitigationData?.length > 0 && Array.isArray(mitigationData))
                            ? mitigationData.map((ele, i) => (
                              <tr key={ele?.id}>
                                <td className="text-capitalize">
                                  {(ele?.owners && ele?.owners?.length > 0 && Array.isArray(ele?.owners)) ? ele?.owners?.map((ow) => (
                                    `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                  )) : '-'}
                                </td>
                                <td>{ele?.status || '-'}</td>
                                <td className="text-capitalize">{ele?.created_by ? `${ele?.created_by?.first_name} ${ele?.created_by?.last_name}` : '-'}</td>
                                <td className="date-col">{dayjs(ele?.action_date).format(userData?.tenant_info?.date_format) || '-'}</td>
                                <td className="date-col">{dayjs(ele?.updated).format(userData?.tenant_info?.date_format) || '-'}</td>
                              </tr>
                            ))
                            : <tr><td colSpan={5} className="text-center">No Data To Display</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <hr className="mb-0" />
                  </>
                );
              } if (item.name === 'trackingPoint') {
                return (
                  <>
                    <h2 className="h1">
                      {index + 2}
                      {'. '}
                      Tracking Point
                    </h2>
                    <h2 className="section-title">Tracking Point Milestones</h2>
                    <div className="table-responsive box-shadow mb-4">
                      <table className="table table-hover">
                        <thead>
                          <tr className="heading-row">
                            <th>Measure Owner</th>
                            <th>KPI Name</th>
                            <th>KPI Description</th>
                            <th>Created By</th>
                            <th className="date-col">Updated Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(trackingPointData && trackingPointData?.length > 0 && Array.isArray(trackingPointData))
                            ? trackingPointData.map((d) => (
                              <tr key={d?.id}>
                                <td className="text-capitalize">
                                  {(d?.measure_owners && d?.measure_owners?.length > 0 && Array.isArray(d?.measure_owners)) ? d?.measure_owners?.map((ow) => (
                                    `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                  )) : '-'}
                                </td>
                                <td>
                                  {d?.measure_name || '-'}
                                </td>
                                <td>
                                  <ReadMoreLess text={d?.measure_actions || '-'} />
                                </td>
                                <td className="text-capitalize">{(d?.created_by?.first_name || d?.created_by?.last_name) ? `${d?.created_by?.first_name || ''} ${d?.created_by?.last_name || ''}` : '-'}</td>
                                <td className="date-col">{dayjs(d?.updated).format(userData?.tenant_info?.date_format) || '-'}</td>
                                <td>{d?.measure_status || 'Open'}</td>
                              </tr>
                            ))
                            : <tr><td colSpan={5} className="text-center">No Data To Display</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <hr className="mb-0" />
                  </>
                );
              }
            })}

          <div className="grey-bg">
            <h2>Related Dates and People</h2>
            <div className="row">
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created</label>
                  <p className="readonly-text font-weight-bold">
                    {relatedDate?.created ? dayjs(relatedDate?.created).format(userData?.tenant_info?.date_format) : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated</label>
                  <p className="readonly-text font-weight-bold">
                    {relatedDate?.updated ? dayjs(relatedDate?.updated).format(userData?.tenant_info?.date_format) : '-'}
                  </p>
                </div>
              </div>
              <div className="w-100" />
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {relatedDate?.created_by ? relatedDate?.created_by : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {relatedDate?.updated_by ? relatedDate?.updated_by : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <hr className="mt-5" /> */}
          <CancelModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            cancelTitle={cancelTitle}
            func={stepReset}
          />
          {!readMode && (
            <StepFooter
              actions={actions}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              steps={steps}
              activeStep={activeStep}
              handleBack={handleBack}
              modalShow={modalShow}
              setModalShow={setModalShow}
              saveDraftHandler={saveDraft}
              componentHandler={submit}
              isDraft
              label={label}
            />
          )}

          {/* {location?.state?.approval && location?.state?.lbl && (
            <div className="mt-4 mb-2 d-flex justify-content-end">
              <Button
                variant="primary"
                className="btn-lg"
                onClick={() => approveTask(location?.state?.lbl)}
              >
                Approve
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </form>
  );
}
