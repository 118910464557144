import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  docsInfo: [],
  budgetDocsInfo: [],
  efforDocsInfo: [],
  stepDocsInfo: [],
  benefitDocs: [],
  reviewStepDocsInfo: [],
};

export const docInfoSlice = createSlice({
  name: 'docInfo',
  initialState,
  reducers: {
    addDocsInfo: (state, { payload }) => {
      state.docsInfo = [...state.docsInfo, ...payload];
    },
    addBenefitDocs: (state, { payload }) => {
      state.benefitDocs = [...state.benefitDocs, ...payload];
    },
    replaceDocsInfo: (state, { payload }) => {
      state.docsInfo = payload;
    },
    replaceBenefitDocs: (state, { payload }) => {
      state.benefitDocs = payload;
    },
    addStepDocsInfo: (state, { payload }) => {
      state.stepDocsInfo = [...state.stepDocsInfo, ...payload];
    },
    replaceSteptDocs: (state, { payload }) => {
      state.stepDocsInfo = payload;
    },
    addReviewStepDocsInfo: (state, { payload }) => {
      state.reviewStepDocsInfo = payload;
    },
    addDuplicateDocsInfo: (state, { payload }) => {
      state.stepDocsInfo = payload;
    },
    clearStepDocsInfo: (state, { payload }) => {
      state.stepDocsInfo = [];
      state.reviewStepDocsInfo = [];
    },
    addBudgetDocsInfo: (state, { payload }) => {
      state.budgetDocsInfo = [...state.budgetDocsInfo, ...payload];
    },
    addEffortDocsInfo: (state, { payload }) => {
      state.efforDocsInfo = [...state.efforDocsInfo, ...payload];
    },
    deleteDocInfo: (state, { payload }) => {
      state.docsInfo = state.docsInfo.filter((item) => item.s3_key_name !== payload);
    },
    deleteStepDocInfo: (state, { payload }) => {
      state.stepDocsInfo = state.stepDocsInfo.filter((item) => item.s3_key_name !== payload);
    },
    deleteEffortDocInfo: (state, { payload }) => {
      state.efforDocsInfo = state.efforDocsInfo.filter((item) => item.s3_key_name !== payload);
    },
    deleteBudgetDocInfo: (state, { payload }) => {
      state.budgetDocsInfo = state.budgetDocsInfo.filter((item) => item.s3_key_name !== payload);
    },
    clearDocsInfo: (state, { payload }) => {
      state.docsInfo = [];
      state.budgetDocsInfo = [];
      state.efforDocsInfo = [];
      state.stepDocsInfo = [];
    },
  },
});

export const {
  addDocsInfo, addBudgetDocsInfo, deleteBudgetDocInfo, deleteDocInfo, clearDocsInfo,
  addEffortDocsInfo, deleteEffortDocInfo, addStepDocsInfo,
  addDuplicateDocsInfo, deleteStepDocInfo, clearStepDocsInfo, replaceDocsInfo, addBenefitDocs,
  replaceBenefitDocs, addReviewStepDocsInfo, replaceSteptDocs,
} = docInfoSlice.actions;

export default docInfoSlice.reducer;
