/* eslint-disable import/prefer-default-export */
const timeStamp = () => {
  const date = new Date();
  const month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth() + 1;
  const datse = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  const time = `${date.getFullYear()}-${month}-${datse} ${hours}:${minutes}:${seconds}`;
  return time;
};

const dateFormate = (date) => {
  if (date) {
    const dt = new Date(date);
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;
  }
  return ' ';
};

const truncateName = (name, maxLength) => {
  if (name?.length > maxLength) {
    return `${name?.substring(0, 25)}...`;
  }
  return name;
};

export { timeStamp, dateFormate, truncateName };
