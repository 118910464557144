/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './screens/dashboard/Sidenav';
import DashboardHeader from './screens/dashboard/DashboardHeader';
import Breadcrumb from './components/common/Breadcrumb';
import LoaderQ from './images/QLorem_Logo_01.svg';
import useBackListener from './hooks/useBackListener';

function App({ children, title }) {
  const location = useLocation();
  console.log('location', location);
  useBackListener();

  return (
    <React.Suspense fallback={(
      <div className="loader">
        <img src={LoaderQ} alt="loading" />
      </div>
    )}
    >
      <main>
        <DashboardHeader />
        <section className="content d-flex">
          {/* sidebar starts */}
          <Sidebar />
          {/* main scontent starts */}
          <div className="main-content-wrapper">
            <Breadcrumb title={location?.state?.breadcrumb} />
            {children}
          </div>
        </section>
      </main>
    </React.Suspense>
  );
}

export default App;
