/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import DatePick from '../../components/form/DatePick';
import ReactSelect from '../../components/form/Select';
import FileUpload from '../../components/form/FileUpload';
import TextEditor from '../../components/form/TextEditor';
import RelatedSelect from '../../components/form/RelatedSelect';
import { selectBU, selectDeprt, selectOrg } from '../../services/formSlice';
import { useGetBuByOrgIdQuery, useGetDepByBuIdQuery, useGetOrgByIdQuery } from '../../services/services';
import { addChangEnhancement } from '../../services/reqChangeSlice';
import { addReqEnhancement } from '../../services/requirementSlice';
import ReadMoreLess from '../../components/common/ReadMoreLess';

const colourStyles = {
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isSelected ? '#EEF0F6' : null,
    color: isDisabled ? '#ccc' : '#585858',
  }),
};

const IssueCustomForm = memo(({
  sections, fields, label, labelTwin, twinMode,
  t, errors, setErrors, handleChange, values, setValues, editMode, isChangeRequire,
}) => {
  console.log('values', values);
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const location = useLocation();
  const dispatch = useDispatch();

  const relatedChange = useSelector((state) => state.formJson.relatedChangeData);
  const requirementData = useSelector((state) => state.requirementData.selectedRequirement);
  const relatedProjectDate = useSelector((state) => state.rcData.relatedProjectDate);
  const draftMode = useSelector((state) => state.formJson.draftMode);
  const epicTaskId = useSelector((state) => state.epicData.epicTaskId);
  const epicChangeReadOnly = useSelector((state) => state.epicData.epicChangeReadOnly);
  const epicInputDisable = useSelector((state) => state.epicData.epicInputDisable);
  const changeInputDisable = useSelector((state) => state.rcData.changeInputDisable);
  const appInputDisable = useSelector((state) => state.applicationData.appInputDisable);
  const processInputDisable = useSelector((state) => state.processData.processInputDisable);
  const inputDisable = useSelector((state) => state.formJson.inputDisable);
  const isTwinMode = useSelector((state) => state.formJson.isTwinMode);
  const reqEnhancement = useSelector(
    (state) => state.requirementData.reqEnhancement,
  );
  const changeEnhancement = useSelector((state) => state.rcData.changeEnhancement);

  const buData = useGetBuByOrgIdQuery({ id: values?.organizations });
  const depData = useGetDepByBuIdQuery({ id: values?.business_units });

  const readOnlyVal = (name, options, val, fieldVal) => {
    switch (name) {
      case 'unique_id':
        return val?.unique_id || '-';
      case 'source':
        return val?.source ? val?.source : 'User Created';
      case 'budget_aggregated_from_initiatives':
        return val?.budget_aggregated_from_initiatives ? Number(val?.budget_aggregated_from_initiatives) : '0';
      case 'budget_aggregated_from_changes':
        return val?.budget_aggregated_from_changes ? Number(val?.budget_aggregated_from_changes) : '0';
      case 'aggregated_from_epics':
        return val?.aggregated_from_epics ? Number(val?.aggregated_from_epics) : '0';
      case 'risk_product':
        return Number(val?.consequences_factor) * Number(val?.probability_factor) || '-';
      case 'total_budget':
        return (
          (twinMode === 'applicationTwin' && values?.overheads_budget !== fieldVal?.value) ? `${userData?.tenant_info?.currency_code} ${Number(values?.overheads_budget) + Number(fieldVal?.value)}`
            : (val?.budget_aggregated_from_initiatives) ? `${userData?.tenant_info?.currency_code} ${Number(val?.overheads_budget || 0) + Number(val?.budget_aggregated_from_initiatives || 0)}`
              : (val?.budget_aggregated_from_changes) ? `${userData?.tenant_info?.currency_code} ${Number(val?.overheads_budget) + Number(val?.budget_aggregated_from_changes)}`
                : (val?.aggregated_from_epics) ? `${userData?.tenant_info?.currency_code} ${Number(val?.epic_budget) + Number(val?.aggregated_from_epics)}`
                  : val?.overheads_budget ? `${userData?.tenant_info?.currency_code} ${Number(val?.overheads_budget)}` : val?.epic_budget ? `${userData?.tenant_info?.currency_code} ${Number(val?.epic_budget)}` : '0');
      case 'overheads_budget':
        return (val?.overheads_budget ? `${userData?.tenant_info?.currency_code} ${Number(val?.overheads_budget)}` : '0');

      // app twin display only
      case 'overheads_budget_display':
        return (fieldVal?.value ? `${userData?.tenant_info?.currency_code} ${Number(fieldVal?.value)}` : '0');
      case 'budget_aggregated_from_initiatives_display':
        return fieldVal?.value ? Number(fieldVal?.value) : '0';
      case 'total_budget_display':
        return (fieldVal?.value ? `${userData?.tenant_info?.currency_code} ${Number(fieldVal?.value)}` : '0');
      case 'total_effort':
        return val?.estimated_effort ? ((Number(val?.estimated_effort)) && ` ${val?.estimated_effort} Days`) : (Number(val?.efforts_days) && ` ${val?.efforts_days} Days`) || '-';
      case 'total_change_budget':
        return (val?.aggregated_from_epics ? `${userData?.tenant_info?.currency_code} ${Number(val?.overheads_budget) + Number(val?.aggregated_from_epics)}` : val?.overheads_budget ? `${userData?.tenant_info?.currency_code} ${Number(val?.overheads_budget)}` : 0);
      case 'organizations':
        return (relatedChange && relatedChange.length > 0 && Array.isArray(relatedChange) && Array.isArray(relatedChange[0]?.organizations)) ? relatedChange[0]?.organizations[0]?.name : '-';
      case 'departments':
        return (relatedChange && relatedChange.length > 0 && Array.isArray(relatedChange) && Array.isArray(relatedChange[0]?.departments)) ? relatedChange[0]?.departments[0]?.name : '-';
      case 'business_units':
        return (relatedChange && relatedChange.length > 0 && Array.isArray(relatedChange) && Array.isArray(relatedChange[0]?.business_units)) ? relatedChange[0]?.business_units[0]?.name : '-';
      case 'initiative_start_date':
        return relatedProjectDate?.startDate ? dayjs(relatedProjectDate?.startDate).format(userData?.tenant_info?.date_format) : '-';
      case 'initiative_end_date':
        return relatedProjectDate?.endDate ? dayjs(relatedProjectDate?.endDate).format(userData?.tenant_info?.date_format) : '-';
      case 'status':
        return val?.status || '-';
      default:
        break;
    }
  };
  const [readOnly, setReadOnly] = React.useState(false);

  useEffect(() => {
    if (labelTwin && labelTwin === 'processTwin') {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, [labelTwin]);

  const handleChngeEnhancement = (e) => {
    e.preventDefault();
    const res1 = changeEnhancement?.map((el) => ({ ...el, comments: e.target.value }));
    if (res1 && Array.isArray(res1) && res1?.length) {
      dispatch(addChangEnhancement(res1));
    }
  };

  const handleReqEnhancement = (e, dt) => {
    e.preventDefault();
    if (reqEnhancement?.some((el) => el?.id === dt?.id)) {
      const res1 = reqEnhancement?.map((el) => ({ ...el, comments: el?.id === dt?.id ? e.target.value : el?.comments }));
      if (res1 && Array.isArray(res1) && res1?.length) {
        dispatch(addReqEnhancement(res1));
      }
    }
  };

  console.log('reqEnhancement', reqEnhancement, changeEnhancement);

  console.log('values', values);

  return (
    (sections && sections?.length > 0 && Array.isArray(sections))
    && sections?.map((sele, i) => (
      <Fragment key={sele.display_name}>
        <h2>
          {t(`${label}:section_title.${sele.name}`)}
        </h2>
        <div className="row">
          {(fields && fields?.length > 0 && Array.isArray(fields))
          && fields.map((fld) => {
            if (sele.id === fld.program_section_title_id?.id
                || sele.id === fld.section_title?.id) {
              switch (fld.field_data_type) {
                case 'text':
                  return (
                    <React.Fragment key={fld.field_name}>
                      <div
                        className={label === 'impact' ? `col-6 
                      ${(fld?.field_name === 'schedule_min_impact'
                      || fld?.field_name === 'schedule_max_impact'
                      || fld?.field_name === 'budget_min_impact'
                      || fld?.field_name === 'budget_max_impact'
                      || fld?.field_name === 'effort_min_impact'
                      || fld?.field_name === 'effort_max_impact'
                      || fld?.field_name === 'scope_min_impact'
                      || fld?.field_name === 'scope_max_impact'
                      || fld?.field_name === 'benefit_min_impact'
                      || fld?.field_name === 'benefit_max_impact') ? 'col-lg-2' : 'col-lg-8'} 
                      ` : 'col-6 col-lg-4'}
                        key={fld.field_name}
                      >
                        <div className="form-group" key={values}>
                          <label htmlFor={fld.field_name} data-test-id={`label-${fld.field_name}`}>
                            {t(t(`${label}:${fld.field_name}`).name)}
                            {fld.field_value_option.validation && (
                            <sup>*</sup>
                            )}
                          </label>
                          <input
                            className={`form-control ${errors && errors[fld.field_name] ? 'is-invalid' : ''}`}
                            type="text"
                            data-test-id={`text-${fld.field_name}`}
                            placeholder={t(t(`${label}:${fld.field_name}`).placeholder)}
                            name={fld.field_name}
                            defaultValue={values[fld.field_name] ? values[fld.field_name] : ''}
                            onChange={(e) => handleChange(e)}
                            disabled={
                                    (editMode && !draftMode && location?.pathname === '/process' && fld.field_name === 'name')
                                    || (editMode && !draftMode && location?.pathname === '/application' && fld.field_name === 'name')
                                    || (editMode && !draftMode && location?.pathname === '/business' && fld.field_name === 'name')
                                    || (editMode && !draftMode && location?.pathname === '/vendor' && fld.field_name === 'name')
                                    || (editMode && !draftMode && location?.pathname === '/customer' && fld.field_name === 'name')
                                    || (editMode && !draftMode && location?.pathname === '/product' && fld.field_name === 'name')
                                  }
                          />
                          {errors && errors[fld.field_name] && (
                          <div className="invalid-feedback">
                            <span className="icon-error-red" />
                            {errors[fld.field_name]}
                          </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                case 'readonly':
                  return (
                    <div className="col-6 col-lg-4" key={fld.id}>
                      <div className="form-group">
                        <label htmlFor={fld.field_name} data-test-id={`label-${fld.field_name}`}>
                          {t(t(`${label}:${fld.field_name}`).name)}
                          {fld.field_value_option.validation && <sup>*</sup>}
                        </label>
                        <input
                          data-test-id={`readonly-${fld.field_name}`}
                          readOnly
                          className={`form-control-plaintext ${errors && errors[fld.field_name] ? 'is-invalid' : ''}`}
                          type="text"
                          value={readOnlyVal(fld.field_name, fld?.field_value_option?.options, values, fld)}
                          placeholder={fld.field_value_option.place_holder}
                        />
                      </div>
                    </div>
                  );
                case 'file':
                  return (
                    <React.Fragment key={fld.field_name}>
                      <FileUpload
                        name={fld.field_name}
                        fld={fld}
                        data-test-id={`file-${fld.field_name}`}
                        label={label}
                        validation={fld.field_value_option.validation}
                        displayName={t(t(`${label}:${fld.field_name}`).name)}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        setErrors={setErrors}
                        setValues={setValues}
                        epicInputDisable={epicInputDisable}
                        changeInputDisable={changeInputDisable}
                        inputDisable={inputDisable}
                      />
                    </React.Fragment>
                  );
                case 'date':
                  return (
                    <React.Fragment key={fld.field_name}>
                      <DatePick
                        editMode={editMode}
                        draftMode={draftMode}
                        data-test-id={`date-${fld.field_name}`}
                        fld={fld}
                        t={t}
                        label={label}
                        errors={errors}
                        handleChange={handleChange}
                        values={values}
                        epicInputDisable={epicInputDisable}
                        changeInputDisable={changeInputDisable}
                        inputDisable={inputDisable}
                      />
                    </React.Fragment>
                  );
                case 'email':
                  return (
                    <div className="col-6 col-lg-4" key={fld.field_name}>
                      <div className="form-group">
                        <label htmlFor={fld.field_name}>
                          {fld.display_name}
                          {fld.field_value_option.validation && <sup>*</sup>}
                        </label>
                        <input
                          className={`form-control ${errors && errors[fld.field_name] ? 'is-invalid' : ''}`}
                          type="email"
                          placeholder={fld.field_value_option.place_holder}
                        />
                        {errors && errors[fld.field_name] && (
                        <div className="invalid-feedback">
                          <span className="icon-error-red" />
                          {fld.field_value_option.validation}
                          {/* {errors[fld.field_name]} */}
                        </div>
                        )}
                      </div>
                    </div>
                  );
                case 'checkbox':
                  return (
                    <div className="col-6 col-lg-4" key={fld.field_name}>
                      <div className="form-group">
                        <label htmlFor={fld.field_name}>
                          {fld.display_name}
                          {fld.field_value_option.validation && <sup>*</sup>}
                        </label>
                        <input
                          className={`form-control ${errors && errors[fld.field_name] ? 'is-invalid' : ''}`}
                          type="checkbox"
                          placeholder={fld.field_value_option.place_holder}
                        />
                        {errors && errors[fld.field_name] && (
                        <div className="invalid-feedback">
                          <span className="icon-error-red" />
                          {fld.field_value_option.validation}
                          {/* {errors[fld.field_name]} */}
                        </div>
                        )}
                      </div>
                    </div>
                  );
                case 'url':
                  return (
                    <div className="col-6 col-lg-4" key={fld.field_name}>
                      <input
                        className={`form-control ${errors && errors[fld.field_name] ? 'is-invalid' : ''}`}
                        type="url"
                        placeholder={fld.field_value_option.place_holder}
                      />
                      {errors && errors[fld.field_name] && (
                      <div className="invalid-feedback">
                        <span className="icon-error-red" />
                        {fld.field_value_option.validation}
                        {/* {errors[fld.field_name]} */}
                      </div>
                      )}
                    </div>
                  );
                case 'texteditor':
                  return (
                    <React.Fragment key={fld.field_name}>
                      <TextEditor
                        data-test-id={`texteditor-${fld.field_name}`}
                        readOnly={readOnly}
                        fld={fld}
                        twinMode={twinMode}
                        t={t}
                        label={label}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        values={values}
                        epicInputDisable={epicInputDisable}
                        changeInputDisable={changeInputDisable}
                        inputDisable={inputDisable}
                      />
                    </React.Fragment>
                  );
                case 'textarea':
                  return (
                    <div className="col-6 col-lg-4" key={fld.field_name}>
                      <div className="form-group">
                        <label htmlFor={fld.field_name}>
                          {(fld.field_name === 'change_comments' || fld.field_name === 'requirement_comments') ? fld?.display_name : t(t(`${label}:${fld.field_name}`).name)}
                          {fld.field_value_option.validation && <sup>*</sup>}
                        </label>
                        <textarea
                          data-test-id={`textarea-${fld.field_name}`}
                          className={`form-control ${
                            errors && errors[fld.field_name] ? 'is-invalid' : ''
                          }`}
                          id={fld.field_name}
                          name={fld.field_name}
                          defaultValue={values[fld.field_name] ? values[fld.field_name] : ''}
                          placeholder={label === 'change' && !values[fld.field_name] ? t(t(`${label}:${fld.field_name}`).name) : t(t(`program_form.${fld.field_name}`).placeholder)}
                          onChange={(e) => handleChange(e)}
                          disabled={changeInputDisable || inputDisable || fld?.isDisabled}
                        />
                        {errors && errors[fld.field_name] && (
                        <div className="invalid-feedback">
                          <span className="icon-error-red" />
                          {/* {fld.field_value_option.validation} */}
                          {errors[fld.field_name]}
                          {/* {t(t(`${label}:${fld.field_name}`).validation)} */}
                        </div>
                        )}
                      </div>
                    </div>
                  );
                case 'select':
                  return (
                    <React.Fragment key={fld.field_name}>
                      {fields.some((el) => el?.id === fld?.id && el?.field_name === 'organizations') ? <div className="w-100" /> : ''}
                      {fields.some((el) => el?.id === fld?.id && el?.field_name === 'consequences_factor') ? <div className="w-100" /> : ''}
                      {fields.some((el) => el?.id === fld?.id && el?.screen_name === 'Epic' && el?.field_name === 'defect_status') && values?.status !== 'Test' ? ''
                        : (
                          <ReactSelect
                            index={fld.field_name}
                            dataTestId={`select-${fld.field_name}`}
                            fld={fld}
                            colourStyles={colourStyles}
                            t={t}
                            label={label}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            editMode={editMode}
                            buData={buData?.data || []}
                            depData={depData?.data || []}
                            epicInputDisable={epicInputDisable}
                            changeInputDisable={changeInputDisable}
                            inputDisable={inputDisable}
                          />
                        )}
                    </React.Fragment>
                  );
                default:
                  return (
                    <div key={fld.field_name}>
                      <span className="text-danger">Invalid Field</span>
                    </div>
                  );
              }
            }
            return null;
          })}
        </div>
        <hr />
      </Fragment>
    ))

  );
});

export default IssueCustomForm;
