/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
// @ts-nocheck
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  addCapabilities, addCapsMapping, addProcessCapabilities, addTableCapabilitieData,
} from '../../services/applicationSlice';
import { addpeopleCapab } from '../../services/reqChangeSlice';
import {
  useGetAppByModeQuery, useGetAppByStatusQuery, useGetAppsMutation, useGetBusinessByStatusQuery, useGetCustomerByStatusQuery, useGetProcessByModeQuery, useGetProcessByStatusQuery, useGetProductByStatusQuery, useGetVendorByStatusQuery,
} from '../../services/services';
import CancelModal from '../common/CancelModal';
import StepFooter from '../common/StepFooter';
import ExpandCardElement from './ExpandCaps';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function Capabilities({
  steps,
  activeStep,
  handleBack,
  handleNext,
  modalShow,
  setModalShow,
  label,
  stepReset,
  cancelTitle,
  capabilitiesName,
}) : JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('capability');
  const extMtData = useSelector((state) => state.editData.extMtData);
  const appCapsDt = useSelector((state) => state.applicationData.capabilitiesData);
  const businessCapsDt = useSelector((state) => state.businessData.capabilitiesData);
  const vendorCapsDt = useSelector((state) => state.vendorData.capabilitiesData);
  const customerCapsDt = useSelector((state) => state.customerData.capabilitiesData);
  const productCapsDt = useSelector((state) => state.productData.capabilitiesData);
  const processCapsDt = useSelector((state) => state.applicationData.processCapabilitiesData);
  const tableCapabilitiesData = useSelector((state) => state.applicationData.tableCapabilitiesData);
  const applicationValues = useSelector((state) => state.applicationData.applicationValues);
  const businessValues = useSelector((state) => state.businessData.businessValues);
  const vendorValues = useSelector((state) => state.vendorData.vendorValues);
  const customerValues = useSelector((state) => state.customerData.customerValues);
  const productValues = useSelector((state) => state.productData.productValues);
  const changeValues = useSelector((state) => state.rcData.rcValues);
  const requirementValues = useSelector((state) => state.requirementData.requirementValues);

  // const applicationData = useGetAppByModeQuery({ mode: 'submitted' });
  const applicationData = useGetAppByStatusQuery({ status: ['Active', 'Transformation'] });
  const processCapsData = useGetProcessByStatusQuery({ status: ['Active', 'Transformation'] });
  const businessCapsData = useGetBusinessByStatusQuery({ status: ['Active', 'Transformation'] });
  const vendorCapsData = useGetVendorByStatusQuery({ status: ['Active', 'Transformation'] });
  const customerCapsData = useGetCustomerByStatusQuery({ status: ['Active', 'Transformation'] });
  const productCapsData = useGetProductByStatusQuery({ status: ['Active', 'Transformation'] });

  const [getApps, appRes] = useGetAppsMutation();

  // common
  const editMode = useSelector((state) => state.editData.editMode);
  const businessUnit = useSelector((state) => state.formJson.selectBU);
  const departments = useSelector((state) => state.formJson.selectDeprt);
  const organizations = useSelector((state) => state.formJson.selectOrg);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const budgetDocsInfo = useSelector((state) => state.docInfoData.budgetDocsInfo);
  const relatedProgram = useSelector((state) => state.formJson.relatedProgData);
  const relatedChange = useSelector((state) => state.formJson.relatedChangeData);
  const relatedInit = useSelector((state) => state.formJson.relatedInitData);
  const peopleData = useSelector((state) => state.peopleData.peopleVals);
  const capsMapping = useSelector((state) => state.applicationData.capsMapping);
  const visibleF = useSelector((state) => state.editData.visibleFieldsReview); // Visible Fields
  const relatedDate = useSelector((state) => state.formJson.relateDate); // related date and people
  const draftMode = useSelector((state) => state.formJson.draftMode);

  // risk data
  const riskValues = useSelector((state) => state.riskData.riskValues);
  const mitigationData = useSelector((state) => state.riskData.riskMitihgationData);
  const dependentRiskData = useSelector((state) => state.riskData.selectedRisk);

  // benefit data
  const benefitValues = useSelector((state) => state.benefitData.benefitValues);
  const trackingPointData = useSelector((state) => state.benefitData.trackingPointData);
  const benefitDocsInfo = useSelector((state) => state.docInfoData.benefitDocs);
  const dependentBenefitData = useSelector((state) => state.benefitData.selectedBenefit);

  // twin vals
  const appTwinVals = useSelector((state) => state.applicationData.appTwinVals);
  const customerTwinVals = useSelector((state) => state.customerData.customerTwinVals);
  const businessTwinVals = useSelector((state) => state.businessData.businessTwinVals);
  const vendorTwinVals = useSelector((state) => state.vendorData.vendorTwinVals);
  const processTwinVals = useSelector((state) => state.processData.processTwinVals);
  const productTwinVals = useSelector((state) => state.productData.productTwinVals);

  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState('');

  useEffect(() => {
    dispatch(addCapsMapping([{
      key: 'application',
      value: appCapsDt && Array.isArray(appCapsDt) && appCapsDt?.length > 0 ? appCapsDt?.map((el) => ({ ...el, delivery_date: label === 'requirement' ? requirementValues?.end_date : el?.delivery_date })) : [],
    },
    {
      key: 'process',
      value: processCapsDt && Array.isArray(processCapsDt) && processCapsDt?.length > 0 ? processCapsDt?.map((el) => ({ ...el, delivery_date: label === 'requirement' ? requirementValues?.end_date : el?.delivery_date })) : [],
    },
    {
      key: 'business',
      value: businessCapsDt && Array.isArray(businessCapsDt) && businessCapsDt?.length > 0 ? businessCapsDt?.map((el) => ({ ...el, delivery_date: label === 'requirement' ? requirementValues?.end_date : el?.delivery_date })) : [],
    },
    {
      key: 'vendor',
      value: vendorCapsDt && Array.isArray(vendorCapsDt) && vendorCapsDt?.length > 0 ? vendorCapsDt?.map((el) => ({ ...el, delivery_date: label === 'requirement' ? requirementValues?.end_date : el?.delivery_date })) : [],
    },
    {
      key: 'customer',
      value: customerCapsDt && Array.isArray(customerCapsDt) && customerCapsDt?.length > 0 ? customerCapsDt?.map((el) => ({ ...el, delivery_date: label === 'requirement' ? requirementValues?.end_date : el?.delivery_date })) : [],
    },
    {
      key: 'product',
      value: productCapsDt && Array.isArray(productCapsDt) && productCapsDt?.length > 0 ? productCapsDt?.map((el) => ({ ...el, delivery_date: label === 'requirement' ? requirementValues?.end_date : el?.delivery_date })) : [],

    },
    ]));
  }, [appCapsDt, processCapsDt, businessCapsDt, vendorCapsDt, customerCapsDt, productCapsDt, label]);

  useEffect(() => {
    if (label === 'change' && appCapsDt.length > 0) {
      const data = [];
      appCapsDt.forEach((cp) => data.push({ id: cp.id }));
      const final = [
        {
          key: 'Application',
          value: data,
        },
      ];
      getApps({ final });
    }
  }, [appCapsDt, label]);

  useEffect(() => {
    if (appRes.isSuccess) {
      dispatch(addpeopleCapab(appRes.data));
    }
  }, [appRes.isSuccess]);

  const componentHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleNext();
  };

  useEffect(() => {
    if (capsInfo?.title === 'Process' && processCapsData.isSuccess) {
      dispatch(addTableCapabilitieData(processCapsData?.data));
    } else if (capsInfo?.title === 'Application' && applicationData.isSuccess) {
      if (capabilitiesName) {
        dispatch(addTableCapabilitieData(applicationData?.data?.filter((ele) => (ele.name !== capabilitiesName))));
      } else {
        dispatch(addTableCapabilitieData(applicationData?.data?.filter((ele) => (ele.id !== applicationValues?.id))));
      }
    } else if (capsInfo?.title === 'Business' && businessCapsData.isSuccess) {
      if (capabilitiesName) {
        dispatch(addTableCapabilitieData(businessCapsData?.data?.filter((ele) => (ele.name !== capabilitiesName))));
      } else {
        dispatch(addTableCapabilitieData(businessCapsData?.data?.filter((ele) => (ele.id !== businessValues?.id))));
      }
    } else if (capsInfo?.title === 'Vendor' && vendorCapsData.isSuccess) {
      if (capabilitiesName) {
        dispatch(addTableCapabilitieData(vendorCapsData?.data?.filter((ele) => (ele.name !== capabilitiesName))));
      } else {
        dispatch(addTableCapabilitieData(vendorCapsData?.data?.filter((ele) => (ele.id !== vendorValues?.id))));
      }
    } else if (capsInfo?.title === 'Customer' && customerCapsData.isSuccess) {
      if (capabilitiesName) {
        dispatch(addTableCapabilitieData(customerCapsData?.data?.filter((ele) => (ele.name !== capabilitiesName))));
      } else {
        dispatch(addTableCapabilitieData(customerCapsData?.data?.filter((ele) => (ele.id !== customerValues?.id))));
      }
    } else if (capsInfo?.title === 'Product' && productCapsData.isSuccess) {
      if (capabilitiesName) {
        dispatch(addTableCapabilitieData(productCapsData?.data?.filter((ele) => (ele.name !== capabilitiesName))));
      } else {
        dispatch(addTableCapabilitieData(productCapsData?.data?.filter((ele) => (ele.id !== productValues?.id))));
      }
    }
  }, [capsInfo,
    processCapsData.isSuccess,
    applicationData.isSuccess,
    businessCapsData?.isSuccess,
    vendorCapsData.isSuccess,
    customerCapsData.isSuccess,
    productCapsData.isSuccess,
  ]);

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  const [redirectState, setRedirectState] = useState(null);
  console.log('redirectState', redirectState);
  console.log('location?.pathname', location?.pathname, location?.state);

  useEffect(() => {
    if (location?.pathname === '/risk') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        riskValues,
        businessUnit,
        departments,
        organizations,
        dependentRiskData,
        relatedProgram,
        relatedChange,
        relatedInit,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        mitigationData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/benefit') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        benefitValues,
        businessUnit,
        departments,
        organizations,
        benefitDocsInfo,
        dependentBenefitData,
        relatedProgram,
        relatedChange,
        relatedInit,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        dependentRiskData,
        trackingPointData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/application' || location?.pathname === '/appTwin') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        applicationValues,
        appTwinVals,
        id: location?.state?.id,
        twinId: location?.state?.twinId,
        docsInfo,
        budgetDocsInfo,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/business' || location?.pathname === '/businessTwin') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        businessValues,
        businessTwinVals,
        taskId: location?.state?.taskId,
        twinId: location?.state?.twinId,
        docsInfo,
        businessUnit,
        departments,
        organizations,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/vendor' || location?.pathname === '/vendorTwin') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        vendorValues,
        vendorTwinVals,
        taskId: location?.state?.taskId,
        twinId: location?.state?.twinId,
        docsInfo,
        businessUnit,
        departments,
        organizations,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/customer' || location?.pathname === '/customerTwin') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        customerValues,
        customerTwinVals,
        taskId: location?.state?.taskId,
        twinId: location?.state?.twinId,
        docsInfo,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/product' || location?.pathname === '/productTwin') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        productValues,
        productTwinVals,
        taskId: location?.state?.taskId,
        twinId: location?.state?.twinId,
        docsInfo,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
      });
    } else if (location?.pathname === '/requirement') {
      setRedirectState({
        steps,
        activeStep,
        extMtData,
        requirementValues,
        docsInfo,
        appCapsDt,
        processCapsDt,
        businessCapsDt,
        vendorCapsDt,
        customerCapsDt,
        productCapsDt,
        capsMapping,
        peopleData,
        visibleF,
        editMode,
        draftMode,
        relatedDate,
        change_data: {
          from: location?.state?.change_data?.from ? location?.state?.change_data?.from : location?.state?.from,
          data: location?.state?.change_data?.data ? location?.state?.change_data?.data : location?.state?.data,
        },
      });
    }
  }, [location, appCapsDt, processCapsDt, businessCapsDt, vendorCapsDt, customerCapsDt, productCapsDt]);

  const handleAddCaps = (e) => {
    e.preventDefault();
    if (e.target.value === 'application') {
      navigate('/application', {
        state: {
          from: location.pathname,
          data: {
            ...redirectState,
            breadcrumb: location?.state?.breadcrumb,
          },
          isNewApp: location.pathname === '/application',
          breadcrumb: 'Create Application Capability',
        },
      });
      dispatch({ type: 'RESET_APP' });
    } else if (e.target.value === 'process') {
      navigate('/process', {
        state: {
          from: location.pathname,
          data: {
            ...redirectState,
            breadcrumb: location?.state?.breadcrumb,
          },
          breadcrumb: 'Create Process Capability',
        },
      });
      dispatch({ type: 'RESET_APP' });
    } else if (e.target.value === 'business') {
      navigate('/business', {
        state: {
          from: location.pathname,
          data: {
            ...redirectState,
            breadcrumb: location?.state?.breadcrumb,
          },
          isNewBusiness: location.pathname === '/business',
          breadcrumb: 'Create Business Capability',
        },
      });
      dispatch({ type: 'RESET_APP' });
    } else if (e.target.value === 'vendor') {
      navigate('/vendor', {
        state: {
          from: location.pathname,
          data: {
            ...redirectState,
            breadcrumb: location?.state?.breadcrumb,
          },
          isNewVendor: location.pathname === '/vendor',
          breadcrumb: 'Create Vendor Capability',
        },
      });
      dispatch({ type: 'RESET_APP' });
    } else if (e.target.value === 'customer') {
      navigate('/customer', {
        state: {
          from: location.pathname,
          data: {
            ...redirectState,
            breadcrumb: location?.state?.breadcrumb,
          },
          isNewCustomer: location.pathname === '/customer',
          breadcrumb: 'Create Customer Capability',
        },
      });
      dispatch({ type: 'RESET_APP' });
    } else if (e.target.value === 'product') {
      navigate('/product', {
        state: {
          from: location.pathname,
          data: {
            ...redirectState,
            breadcrumb: location?.state?.breadcrumb,
          },
          isNewProduct: location.pathname === '/product',
          breadcrumb: 'Create Product Capability',
        },
      });
      dispatch({ type: 'RESET_APP' });
    }
  };

  useEffect(() => {
    if (label === 'requirement' && capsMapping) {
      const resCaps = capsMapping?.map((el) => el?.value?.map((el2) => ({ ...el2, key: el?.key })))?.flat();
      const dj = [{
        key: '01',
        label: changeValues?.name ? changeValues?.name : 'Enterprise Change',
        icon: 'icon-requirement-changes',
        children: [{
          key: '01',
          label: requirementValues?.name ? requirementValues?.name : 'Enterprise Requirement',
          icon: '',
          children: resCaps?.map((vl) => ({
            key: vl?.id,
            label: vl?.name,
            clickable: true,
            type: vl.key,
            icon: vl.key === 'application' ? 'icon-application'
              : vl.key === 'business' ? 'icon-business'
                : vl.key === 'vendor' ? 'icon-supplier'
                  : vl.key === 'customer' ? 'icon-consumer'
                    : vl.key === 'product' ? 'icon-toolbar'
                      : 'icon-value-chain',
          })),
        }],
      }];
      dispatch(addpeopleCapab(dj));
    }
  }, [label, capsMapping]);

  return (
    <div className="container-fluid">
      <h2 className="d-flex justify-content-between">
        {t('section_title')}
        {capsInfo?.title && ` : ${capsInfo?.title}`}
        {userData?.roles
        && Array.isArray(userData?.roles)
        && userData?.roles?.length > 0
        && userData?.roles?.some((ee) => ee === 'Admin' || ee === 'Capability Manager')
        && !location?.state?.from && label !== 'requirement'
          && (
          <div className="col-md-3">
            <div className="form-group mb-0">
              <label htmlFor="sele">Add New Capability</label>
              <select
                onChange={(e) => handleAddCaps(e)}
                className="form-control"
              >
                <option value="">Select..</option>
                {userData?.permissions?.includes('create:process') && (
                <option value="process">
                  <span className="icon-expand mr-1" />
                  {t('add_process_title')}
                </option>
                )}
                {userData?.permissions?.includes('create:application') && (
                <option value="application">
                  <span className="icon-expand mr-1" />
                  {t('add_application_title')}
                </option>
                )}
                {userData?.permissions?.includes('create:business') && (
                <option value="business">
                  <span className="icon-expand mr-1" />
                  Add Business
                </option>
                )}
                {userData?.permissions?.includes('create:vendor') && (
                <option value="vendor">
                  <span className="icon-expand mr-1" />
                  Add Vendor
                </option>
                )}
                {userData?.permissions?.includes('create:customer') && (
                <option value="customer">
                  <span className="icon-expand mr-1" />
                  Add Customer
                </option>
                )}
                {userData?.permissions?.includes('create:product') && (
                <option value="product">
                  <span className="icon-expand mr-1" />
                  Add Product
                </option>
                )}
              </select>
            </div>
          </div>
          )}
        {userData?.roles
        && Array.isArray(userData?.roles)
        && userData?.roles?.length > 0
        && userData?.roles?.some((ee) => ee === 'Admin' || ee === 'Capability Manager') && label === 'requirement'
          && (
          <div className="col-md-3">
            <div className="form-group mb-0">
              <label htmlFor="sele">Add New Capability</label>
              <select
                onChange={(e) => handleAddCaps(e)}
                className="form-control"
              >
                <option value="">Select..</option>
                {userData?.permissions?.includes('create:process') && (
                <option value="process">
                  <span className="icon-expand mr-1" />
                  {t('add_process_title')}
                </option>
                )}
                {userData?.permissions?.includes('create:application') && (
                <option value="application">
                  <span className="icon-expand mr-1" />
                  {t('add_application_title')}
                </option>
                )}
                {userData?.permissions?.includes('create:business') && (
                <option value="business">
                  <span className="icon-expand mr-1" />
                  Add Business
                </option>
                )}
                {userData?.permissions?.includes('create:vendor') && (
                <option value="vendor">
                  <span className="icon-expand mr-1" />
                  Add Vendor
                </option>
                )}
                {userData?.permissions?.includes('create:customer') && (
                <option value="customer">
                  <span className="icon-expand mr-1" />
                  Add Customer
                </option>
                )}
                {userData?.permissions?.includes('create:product') && (
                <option value="product">
                  <span className="icon-expand mr-1" />
                  Add Product
                </option>
                )}
              </select>
            </div>
          </div>
          )}
      </h2>
      <div id="expand-demo">
        <div id="wrpp" className="capability-cards">
          <div id="fsm_container" className="fsm-container">
            {/*  porcess */}
            <div className="fsm">
              {(processCapsDt && Array.isArray(processCapsDt) && processCapsDt?.length > 0)
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-value-chain" />
                      {t('process')}
                      {' '}
                      {`(${processCapsDt?.length})`}
                      {(applicationValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Process')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>{t('process_table_header_name')}</th>
                            <th>{t('process_table_header_date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(processCapsDt && processCapsDt.length > 0 && Array.isArray(processCapsDt)) && processCapsDt?.slice(0, 5)?.map((ele) => (
                            <tr>
                              <td className="sm-col">
                                <span className="icon-value-chain" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/process/${ele?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{label === 'requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {processCapsDt.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Process')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-value-chain" />
                      {t('process')}
                      {(applicationValues !== 'Transformation' || appTwinVals !== 'Transformation') && processCapsDt?.length > 0 && (
                      <a
                        data-test-id="test-process"
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Process')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body empty">
                      {applicationValues !== 'Transformation' && (
                      <a
                        data-test-id="test-process"
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'Process')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          {t('select_card_process_title')}
                        </i>
                      </a>
                      )}
                    </div>
                  </div>
                )}
            </div>

            {/*  application */}
            <div className="fsm">
              {appCapsDt && Array.isArray(appCapsDt) && appCapsDt.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-application" />
                      {t('application')}
                      {' '}
                      {`(${appCapsDt.length})`}

                      {(applicationValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Application')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>{t('application_table_header_name')}</th>
                            <th>{t('application_table_header_date')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(appCapsDt && appCapsDt.length > 0 && Array.isArray(appCapsDt)) && appCapsDt?.slice(0, 5)?.map((ele) => (
                            <tr>
                              <td className="sm-col">
                                <span className="icon-application" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/application/${ele?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{label === 'requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {appCapsDt.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Application')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-application" />
                      {t('application')}
                      {(applicationValues !== 'Transformation' || appTwinVals !== 'Transformation') && appCapsDt?.length > 0 && (
                      <a
                        data-test-id="test-application"
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Application')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>

                    <div className="card-body empty">
                      {(applicationValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                        <a
                          data-test-id="test-application"
                          href="#!"
                          onClick={(e) => clickToExapand(e, 'Application')}
                        >
                          <span className="icon-expand" />
                          <i className="text-decoration-none">
                            {t('select_card_application_title')}
                          </i>
                        </a>
                      )}
                    </div>
                  </div>
                )}
            </div>

            {/*  business start */}
            <div className="fsm">
              {businessCapsDt && Array.isArray(businessCapsDt) && businessCapsDt.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-business" />
                      Business
                      {' '}
                      {`(${businessCapsDt.length})`}

                      {(businessValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Business')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Business Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(businessCapsDt && businessCapsDt.length > 0 && Array.isArray(businessCapsDt)) && businessCapsDt?.slice(0, 5)?.map((ele) => (
                            <tr>
                              <td className="sm-col">
                                <span className="icon-business" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/business/${ele?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{label === 'requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {businessCapsDt.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Business')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-business" />
                      Business
                      {(businessValues !== 'Transformation' || appTwinVals !== 'Transformation') && businessCapsDt?.length > 0 && (
                      <a
                        data-test-id="test-application"
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Business')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>

                    <div className="card-body empty">
                      {(businessValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        data-test-id="test-application"
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'Business')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Business/s
                        </i>
                      </a>
                      )}
                    </div>
                  </div>
                )}
            </div>
            {/*  business end */}

            {/*  vendor start */}
            <div className="fsm">
              {vendorCapsDt && Array.isArray(vendorCapsDt) && vendorCapsDt.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-supplier" />
                      Vendor
                      {' '}
                      {`(${vendorCapsDt.length})`}

                      {(vendorValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Vendor')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Vendor Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(vendorCapsDt && vendorCapsDt.length > 0 && Array.isArray(vendorCapsDt)) && vendorCapsDt?.slice(0, 5)?.map((ele) => (
                            <tr>
                              <td className="sm-col">
                                <span className="icon-supplier" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/vendor/${ele?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{label === 'requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {vendorCapsDt.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Vendor')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-supplier" />
                      Vendor
                      {(vendorValues !== 'Transformation' || appTwinVals !== 'Transformation') && vendorCapsDt?.length > 0 && (
                      <a
                        data-test-id="test-vendor"
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Vendor')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>

                    <div className="card-body empty">
                      {(vendorValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        data-test-id="test-vendor"
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'Vendor')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Vendor/s
                        </i>
                      </a>
                      )}
                    </div>
                  </div>
                )}
            </div>
            {/*  vendor end */}

            {/*  cutomer start */}
            <div className="fsm">
              {customerCapsDt && Array.isArray(customerCapsDt) && customerCapsDt.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-consumer" />
                      Customer
                      {' '}
                      {`(${customerCapsDt.length})`}

                      {(customerValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Customer')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Customer Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(customerCapsDt && customerCapsDt.length > 0 && Array.isArray(customerCapsDt)) && customerCapsDt?.slice(0, 5)?.map((ele) => (
                            <tr>
                              <td className="sm-col">
                                <span className="icon-consumer" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/customer/${ele?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{label === 'requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {customerCapsDt.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Customer')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-consumer" />
                      Customer
                      {(customerValues !== 'Transformation' || appTwinVals !== 'Transformation') && customerCapsDt?.length > 0 && (
                      <a
                        data-test-id="test-customer"
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Customer')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>

                    <div className="card-body empty">
                      {(customerValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        data-test-id="test-vendor"
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'Customer')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Customer/s
                        </i>
                      </a>
                      )}
                    </div>
                  </div>
                )}
            </div>
            {/*  customer end */}

            {/*  product start */}
            <div className="fsm">
              {productCapsDt && Array.isArray(productCapsDt) && productCapsDt.length > 0
                ? (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-toolbar" />
                      Product
                      {' '}
                      {`(${productCapsDt.length})`}

                      {(productValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Product')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>
                    <div className="card-body">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th className="sm-col">&nbsp;</th>
                            <th>Product Name</th>
                            <th>Delivery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(productCapsDt && productCapsDt.length > 0 && Array.isArray(productCapsDt)) && productCapsDt?.slice(0, 5)?.map((ele) => (
                            <tr>
                              <td className="sm-col">
                                <span className="icon-toolbar" />
                              </td>
                              <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                  <Button
                                    className="text-decoration-none"
                                    variant="link"
                                    target="_blank"
                                    href={`/review/product/${ele?.id}`}
                                  >
                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                  </Button>
                                </OverlayTrigger>
                              </td>
                              <td>{label === 'requirement' ? dayjs(requirementValues?.end_date).format('DD/MM/YYYY') : ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {productCapsDt.length > 5
                      && (
                      <div className="more-link d-flex justify-content-end">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Product')}>more</button>
                      </div>
                      )}
                    </div>
                  </div>
                )
                : (
                  <div className="card">
                    <h3 className="card-header">
                      <span className="icon-toolbar" />
                      Product
                      {(productValues !== 'Transformation' || appTwinVals !== 'Transformation') && productCapsDt?.length > 0 && (
                      <a
                        data-test-id="test-customer"
                        href="#!"
                        className="edit-card"
                        onClick={(e) => clickToExapand(e, 'Product')}
                      >
                        <span className="icon-edit" />
                      </a>
                      )}
                    </h3>

                    <div className="card-body empty">
                      {(productValues !== 'Transformation' || appTwinVals !== 'Transformation') && (
                      <a
                        data-test-id="test-vendor"
                        href="#!"
                        onClick={(e) => clickToExapand(e, 'Product')}
                      >
                        <span className="icon-expand" />
                        <i className="text-decoration-none">
                          Select Product/s
                        </i>
                      </a>
                      )}
                    </div>
                  </div>
                )}
            </div>
            {/*  product end */}

            <div id="fsm_actual">
              {expanded
              && (
              <ExpandCardElement
                tableData={tableCapabilitiesData}
                appCapsData={appCapsDt}
                processCapsData={processCapsDt}
                businessCapsData={businessCapsDt}
                vendorCapsData={vendorCapsDt}
                customerCapsData={customerCapsDt}
                productCapsData={productCapsDt}
                dispatch={dispatch}
                setExpanded={setExpanded}
                capsInfo={capsInfo}
                size={size}
                position={position}
              />
              )}
            </div>
          </div>
        </div>
        {/* <hr /> */}
        <CancelModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          cancelTitle={cancelTitle}
          func={stepReset}
        />
        <StepFooter
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          setModalShow={setModalShow}
          componentHandler={componentHandler}
        />
      </div>
    </div>
  );
}

export default Capabilities;
