/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React, {
  useState, SyntheticEvent, useEffect, Suspense,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Spinner } from 'react-bootstrap';
import logo from '../images/logo.svg';
import hide from '../images/hide.svg';
import show from '../images/show.svg';
// import data from './form/submit.json';
import { useGetLangNamesQuery, useCheckAuthMutation } from '../services/services';

function LoginTest():JSX.Element {
  // const { t } = useTranslation('login');
  const navigate = useNavigate();
  const langNames = useGetLangNamesQuery();
  const [checkAuth, authRes] = useCheckAuthMutation();
  const [checkedAdmin, setCheckedAdmin] = useState(false);
  const [checkedLogin, setCheckedLogin] = useState(true);
  const [checkedFp, setCheckedFp] = useState(false);
  const [passType, setPassType] = useState('password');
  const [authError, setAuthError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {
    loginWithRedirect, user, isAuthenticated, isLoading,
  } = useAuth0();

  const loginHandler = (credntial) => {
    const data = { user_name: username, password };
    checkAuth(data);
  };
  const showPassword = (e:SyntheticEvent) => {
    e.stopPropagation();
    if (passType === 'password') {
      setPassType('text');
    } else {
      setPassType('password');
    }
  };

  useEffect(() => {
    if (authRes.isSuccess) {
      localStorage.setItem('authData', JSON.stringify({
        token: authRes.data.token,
        email: authRes.data.email,
        organization: authRes.data.organization_name,
        userName: authRes.data.user_name,
        roles: authRes.data.roles,
        id: authRes.data.id,
      }));
      navigate('/dashboard');
    } else if (authRes.isError) {
      setAuthError('Wrong Credentials');
    }
  }, [authRes]);

  const clickHandler = (e: SyntheticEvent, val: string) => {
    e.stopPropagation();
    if (val === 'login') {
      setCheckedLogin(true);
      setCheckedFp(false);
      setCheckedAdmin(false);
    } else if (val === 'fp') {
      setCheckedLogin(false);
      setCheckedAdmin(false);
      setCheckedFp(true);
    } else {
      setCheckedLogin(false);
      setCheckedFp(false);
      setCheckedAdmin(true);
    }
  };
  useEffect(() => {
    loginWithRedirect();
  }, []);

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%' }}><Spinner animation="grow" /></div>
  );
}

export default LoginTest;
