import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allAssets: [],
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    addAssets: (state, { payload }) => {
      state.allAssets = payload;
    },
  },
});

export const { addAssets } = assetsSlice.actions;
export default assetsSlice.reducer;
