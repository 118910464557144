/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, {
  useEffect, memo, useRef, useLayoutEffect, useState,
} from 'react';
import * as d3 from 'd3';

function LineChart({ varianceData, selectedVariance, tabKey }) {
  const svgRef = useRef(null);
  // State to track width and height of SVG Container
  const [WIDTH, setWidth] = useState(0);
  const [HEIGHT, setHeight] = useState(170);
  console.log('line WIDTH', WIDTH, HEIGHT);

  useLayoutEffect(() => {
    setWidth(svgRef.current.offsetWidth);
  }, [tabKey]);

  const handleResize = () => {
    const newWidth = parseInt(d3.select('#lineChart').style('width'), 10);
    setWidth(newWidth);
    const newHeight = parseInt(d3.select('#lineChart').style('height'), 10);
    setHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const margin = {
    top: 10, right: 30, bottom: 30, left: 60,
  };
  const width = WIDTH - margin.left - margin.right;
  const height = HEIGHT - margin.top - margin.bottom;
  console.log('varianceData', varianceData, 'selectedVariances', selectedVariance);

  useEffect(() => {
    if (selectedVariance && varianceData
      && varianceData[selectedVariance?.id]
      && Array.isArray(varianceData[selectedVariance?.id])
      && varianceData[selectedVariance?.id]?.length > 0
      && !Array.isArray(varianceData)) {
      // clear all previous content on refresh
      const ref = d3.select('#lineChart');
      ref.selectAll('*').remove();
      // append the svg object to the body of the page
      const svg = d3
        .select('#lineChart')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // const parseTime = d3.timeParse('%Y-%m-%d');
      const parseDate = d3.timeParse('%Y-%m-%d');
      const valuesForXAxis = varianceData[selectedVariance?.id]?.map((d) => parseDate(d.date));
      const formatData = varianceData[selectedVariance?.id]?.map((d) => ({ date: parseDate(d.date), value: d.value, name: d.name }));

      // Set the ranges
      const x = d3.scaleTime().domain(d3.extent(formatData, (d) => d.date)).range([0, width]);
      const y = d3.scaleLinear().domain([0, d3.max(formatData, (d) => d.value)]).range([height, 0]);
      const groupedData = Array.from(d3.group(formatData, (d) => d.name), ([key, value]) => ({ key, value }));

      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%y/%m/%d')).tickValues(valuesForXAxis))
        .selectAll('.tick text')
        .attr('transform', 'translate(-30,15)rotate(-30)')
        .style('text-anchor', 'start')
        .style('font-size', 8);
      svg.append('g').call(d3.axisLeft(y));

      const lineGenerator = d3.line()
        .x((d) => x(d.date))
        .y((d) => y(d.value))
        .curve(d3.curveBasis);

      svg
        .append('g')
        .selectAll('.line-path')
        .data(groupedData)
        .enter()
        .append('path')
        .attr('class', 'line-path')
        .attr('d', (d) => lineGenerator(d.value))
        .attr('fill', 'none')
        .attr('stroke', (d) => (d?.key === 'Plan' ? '#0068AA' : '#00B894'))
        .attr('stroke-width', 1);
    } else {
      const ref = d3.select('#lineChart');
      ref.selectAll('*').remove();
      // append the svg object to the body of the page
      const svg = d3
        .select('#lineChart')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Set the ranges
      const x = d3.scaleTime().range([0, width]);
      const y = d3.scaleLinear().range([height, 0]);

      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(x).ticks(0))
        .selectAll('.tick text')
        .attr('transform', 'translate(-30,18)rotate(-40)')
        .style('text-anchor', 'start')
        .style('font-size', 8);
      svg.append('g').call(d3.axisLeft(y).ticks(0));
    }
  }, [selectedVariance, varianceData, WIDTH, HEIGHT]);
  return (
    <div className="col-5">
      <div className="chart-notations no-color">
        <ul className="list-unstyled mb-0 justify-content-start">
          <li>
            <span className="chart-note plan" />
            Plan
          </li>
          <li>
            <span className="chart-note actual" />
            Actual
          </li>
        </ul>
      </div>
      <div id="lineChart" ref={svgRef} />
    </div>
  );
}

export default memo(LineChart);
