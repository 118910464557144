/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import People from '../../components/common/People';
import {
  useLockStatusMutation,
  useProductTwinByIdMutation,
  useSaveDocsMutation,
  useSubmitProductTwinMutation,
  useUpdateStatusMutation,
} from '../../services/services';
import AlertMessage from '../../SuperComponents/AlertMessage';
import Capabilities from '../../components/capabalities';
import { addDocsInfo } from '../../services/docInfoSlice';
import { addExtMtData } from '../../services/editFormSlice';
import { addIsDirty } from '../../services/navSlice';
import FormBuilder from '../../components/form/FormBuilder';
import LoaderComp from '../../components/LoderQ';
import { addProductTwinVals } from '../../services/productSlice';
import ProductTwinReview from './ProductTwinReview';
import ErrorModal from '../../components/common/ErrorModal';

export default function ProductTwin() {
  const { t } = useTranslation('product');
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { taskId, twinId } = location?.state;
  const [saveDocs, docRes] = useSaveDocsMutation();
  const [productTwin, productTwinFormat] = useProductTwinByIdMutation();
  const [submitForm, res] = useSubmitProductTwinMutation();
  const [updateStatus, updateRes] = useUpdateStatusMutation();
  const { isLoading, isError } = res;
  const [lockStatus] = useLockStatusMutation();
  const productTwinVals = useSelector((state) => state.productData.productTwinVals);
  const extMtData = useSelector((state) => state.editData.extMtData);
  const businessUnit = useSelector((state) => state.formJson.selectBU);
  const departments = useSelector((state) => state.formJson.selectDeprt);
  const organizations = useSelector((state) => state.formJson.selectOrg);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const budgetDocsInfo = useSelector(
    (state) => state.docInfoData.budgetDocsInfo,
  );
  const peopleData = useSelector((state) => state.peopleData.peopleVals);
  const capsMapping = useSelector((state) => state.applicationData.capsMapping);
  const editMode = useSelector((state) => state.editData.editMode);
  const reqEnhancement = useSelector(
    (state) => state.requirementData.reqEnhancement,
  );
  const changeEnhancement = useSelector((state) => state.rcData.changeEnhancement);
  const [isModeDraf, setIsModeDraft] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [steps, setSteps] = useState([
    {
      key: 'step1',
      label: 'Details',
      isDone: false,
    },
    {
      key: 'step2',
      label: 'Capabilities',
      isDone: false,
    },
    {
      key: 'step3',
      label: 'People',
      isDone: false,
    },
    {
      key: 'step4',
      label: 'Review',
      isDone: false,
    },
  ]);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [modalShow, setModalShow] = useState(false);
  const cancelTitle = 'Product twin updated';

  console.log('IDS', taskId, twinId);

  useEffect(() => {
    if (twinId) {
      productTwin({ id: twinId });
    }
  }, [twinId]);

  // reset step and active step
  useEffect(() => {
    if (location?.state?.steps && location?.state?.activeStep) {
      setSteps(location?.state?.steps);
      setActiveStep(location?.state?.activeStep);
      setModalShow(false);
    }
  }, [location?.state]);

  // Reset All steps
  const stepReset = () => {
    lockStatus({
      capability: 'product',
      id: twinId,
      locked: false,
      locked_by: userData?.id,
    });
    dispatch(addIsDirty(false));
    dispatch({ type: 'RESET_APP' });
    navigate('/collaborate', {
      state: {
        breadcrumb: 'Collaborator',
      },
    });
  };

  // Function to move next step
  const handleNext = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) => prevStep.map((x) => {
      if (x.key === activeStep.key) x.isDone = true;
      return x;
    }));
    setActiveStep(steps[index + 1]);
  };

  // function for back
  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;
    setSteps((prevStep) => prevStep.map((x) => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }));
    setActiveStep(steps[index - 1]);
  };

  // Save Doc Res to State
  useEffect(() => {
    if (
      docRes.isSuccess
      && docRes.data.docData
      && docRes.data.docData?.length > 0
      && Array.isArray(docRes.data.docData)
    ) {
      const docsData = docRes.data.docData.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addDocsInfo(docsData));
    }
  }, [docRes.isSuccess]);

  // Function to set State while clicking on Next
  const onSubmit = (values) => {
    const vals = {
      ...values,
      status: values?.status,
      unique_id: values?.unique_id ? values?.unique_id : '-',
      business_units: values?.business_units || '',
      organizations: values?.organizations || '',
      departments: values?.departments || '',
      documents: values?.documents && Array.isArray(values?.documents) && values?.documents?.length > 0 ? values?.documents : '',
    };
    if (values?.documents || values?.budget_documents) {
      const docsCopy = values?.documents
        ? values?.documents?.filter(
          (el) => !docsInfo?.some((dc) => dc.s3_key_name === el.s3_key_name),
        )
        : [];
      const budgetCopy = values?.budget_documents
        ? values?.budget_documents?.filter(
          (el) => !budgetDocsInfo?.some((bdc) => bdc.s3_key_name === el.s3_key_name),
        )
        : [];
      saveDocs({ data: docsCopy, budgetData: budgetCopy, effortData: [] });
    }
    const updatedExtMt = extMtData?.fields?.map((dt) => ({ ...dt, value: (dt.field_data_type === 'select' && dt.field_type === 'System' && vals[dt.field_name]) ? { label: dt.field_value_option.options.find((op) => op.value?.toString() === vals[dt.field_name]?.toString())?.name, value: vals[dt.field_name] } : vals[dt.field_name] || '' }));
    dispatch(
      addExtMtData({ fields: updatedExtMt, sections: productTwinFormat?.data?.sections }),
    );
    dispatch(addProductTwinVals(vals));
    handleNext();
  };

  const saveProductTwin = (e, mode) => {
    e.preventDefault();
    if (mode === 'draft') {
      setIsModeDraft(true);
    }
    const filterExtMtData = extMtData?.fields?.filter((ext) => (ext?.screen_name !== 'displayOnly'));
    const finalfilterExtMtData = filterExtMtData?.filter((ext) => (ext?.field_name !== 'change_details'));
    const ddArr = finalfilterExtMtData.map((fl) => ((fl.field_data_type === 'date') ? { ...fl, value: dayjs(productTwinVals[fl.field_name]).format('YYYY-MM-DD hh:mm:ss') } : { ...fl }));
    const dd = ddArr.map(({ isDisabled, ...rest }) => rest);
    const finalData = {
      ...productTwinFormat?.data?.data,
      digital_twin_data: {
        deleted: false,
        id: productTwinVals?.id,
        // change_details: productTwinVals?.change_details,
        product_extensions: dd?.map((ed) => ((ed.field_data_type === 'select' && ed.field_type === 'System')
          ? ({ ...ed, value: `${ed?.value?.value}` })
          : ({ ...ed, value: `${ed.value}` })))?.filter((fl) => fl.screen_name !== 'displayonly').map((upd) => (upd.field_name === 'status' ? { ...upd, field_data_type: 'select' }
          : { ...upd })),
        capability_mappings: (capsMapping && Array.isArray(capsMapping) && capsMapping.length) ? capsMapping.filter((ele) => ele.value.length !== 0) : null,
        description: productTwinVals?.description,
        mode,
        name: productTwinVals?.name,
        business_units:
        businessUnit && businessUnit?.length > 0 && Array.isArray(businessUnit)
          ? businessUnit
          : null,
        departments:
        departments && departments?.length > 0 && Array.isArray(departments)
          ? departments
          : null,
        organizations:
        organizations
          && organizations?.length > 0
          && Array.isArray(organizations)
          ? organizations
          : null,
        owners:
        peopleData.owner
        && peopleData.owner?.length > 0
        && Array.isArray(peopleData.owner)
          ? peopleData.owner
          : null,
        managers:
        peopleData.manager
        && peopleData.manager?.length > 0
        && Array.isArray(peopleData.manager)
          ? peopleData.manager
          : null,
        teams:
        peopleData.teams
        && peopleData.teams?.length > 0
        && Array.isArray(peopleData.teams)
          ? peopleData.teams
          : null,
        status: mode === 'draft' ? 'New' : 'Active',
        unique_id: productTwinVals?.unique_id,
        created: productTwinVals?.created,
        created_by: {
          id: userData?.id,
        },
        updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
        updated_by: {
          id: userData?.id,
        },
        documents: productTwinVals?.documents ? productTwinVals?.documents : null,
        delivery_date: productTwinVals?.delivery_date ? dayjs(productTwinVals?.delivery_date).format('YYYY-MM-DD hh:mm:ss') : '',
        requirement_comments: reqEnhancement || [],
        change_comments: changeEnhancement || [],
      },
      locked: false,
    };
    submitForm(finalData);
  };

  // After Submit Redirect & Success Message
  useEffect(() => {
    if (res?.isSuccess) {
      setSuccessMessage('Digital Twin Saved Successfully');
      dispatch(addIsDirty(false));
      if (!isModeDraf && taskId) {
        const data = {
          id: taskId,
          status: 'Completed',
          userId: userData.id,
        };
        updateStatus(data);
      } else {
        setTimeout(() => {
          dispatch({ type: 'RESET_APP' });
          navigate('/collaborate', {
            state: {
              breadcrumb: 'Collaborator',
            },
          });
        }, 800);
      }
    }
  }, [res?.isSuccess]);

  useEffect(() => {
    if (updateRes?.isSuccess) {
      setTimeout(() => {
        dispatch({ type: 'RESET_APP' });
        navigate('/collaborate', {
          state: {
            breadcrumb: 'Collaborator',
          },
        });
      }, 3000);
    }
  }, [updateRes]);

  // Remove Success Message
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }
  }, [successMessage]);

  return (
    <div className="content-wrapper">
      {(res?.isError || updateRes?.isError) && <ErrorModal />}
      {(isLoading) && (
      <LoaderComp />
      )}
      {successMessage && (
        <AlertMessage message={successMessage} setter={setSuccessMessage} />
      )}
      <div className="card">
        <div className="card-header has-lockicon">
          <h1>
            Digital Twin :
            {' '}
            {productTwinVals?.name}
          </h1>
        </div>
        <div className="card-body">
          <div className="step-count-wrapper">
            <ul className="list-unstyled mb-0 steps-list d-flex align-items-center justify-content-center">
              {steps.map((step, i) => (
                <li
                  key={step.key}
                  className={`d-flex align-items-center ${
                    activeStep.key === step.key ? 'active' : ''
                  } ${step.isDone ? 'success' : ''}`}
                >
                  <span className="step-count">
                    <span className="icon-progress" />
                    {i + 1}
                  </span>
                  <p>{step.label}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="form-wrapper">
            {activeStep.key === 'step1' && (
            <FormBuilder
              steps={steps}
              label="product"
              twinMode="productTwin"
              activeStep={activeStep}
              handleBack={handleBack}
              template={{ fields: extMtData?.fields, sections: productTwinFormat?.data?.sections || [] }}
              onNext={onSubmit}
              modalShow={modalShow}
              setModalShow={setModalShow}
              t={t}
              dfVals={productTwinVals}
              stepReset={stepReset}
              cancelTitle={cancelTitle}
            />
            )}

            {activeStep.key === 'step2' && (
            <Capabilities
              label="productTwin"
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleBack={handleBack}
              handleNext={handleNext}
              modalShow={modalShow}
              setModalShow={setModalShow}
              stepReset={stepReset}
              t={t}
              cancelTitle={cancelTitle}
              capabilitiesName={productTwinVals?.name}
            />
            )}

            {activeStep.key === 'step3' && (
            <People
              label="productTwin"
              labelTwin
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleBack={handleBack}
              handleNext={handleNext}
              modalShow={modalShow}
              setModalShow={setModalShow}
              stepReset={stepReset}
              fields={{
                owner: true,
                manager: true,
                stCom: false,
                teams: true,
                sponsor: false,
                collaborator: false,
              }}
              cancelTitle={cancelTitle}
            />
            )}

            {activeStep.key === 'step4' && (
            <ProductTwinReview
              steps={steps}
              activeStep={activeStep}
              handleBack={handleBack}
              modalShow={modalShow}
              setModalShow={setModalShow}
              stepReset={stepReset}
              submit={saveProductTwin}
              cancelTitle={cancelTitle}
              isLoading={isLoading}
              isError={isError}
              peopleView={{
                owner: true,
                manager: true,
                stCom: false,
                teams: true,
                sponsor: false,
                collaborator: false,
              }}
            />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
