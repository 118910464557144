/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vendorValues: {},
  vendorTwinVals: {},
  capabilitiesData: [],
  pullProcessVendorCaps: [],
  vendorStepCapabilitiesData: [],
  vendorStepReviewCaps: [],
  processVendorReviewCaps: [],
};

export const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    addVendorVals: (state, { payload }) => {
      state.vendorValues = payload;
    },
    addVendorTwinVals: (state, { payload }) => {
      state.vendorTwinVals = payload;
    },
    addVendorCaps: (state, { payload }) => {
      const uniqueAppCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.capabilitiesData = uniqueAppCaps;
    },
    addVendorStepCapabilities: (state, { payload }) => {
      state.vendorStepCapabilitiesData = payload;
    },
    addPullProcessVendorCaps: (state, { payload }) => {
      const uniqueProcessVendorCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcessVendorCaps = uniqueProcessVendorCaps;
    },
    addVendorStepReviewCaps: (state, { payload }) => {
      state.vendorStepReviewCaps = payload;
    },
    addProcessVendorReviewCaps: (state, { payload }) => {
      const uniqueProcessRWCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processVendorReviewCaps = uniqueProcessRWCaps;
    },
    removePullProcessVendorCaps: (state, { payload }) => {
      state.pullProcessVendorCaps = payload && payload.length > 0 && Array.isArray(payload) ? payload : [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addVendorCaps,
  addVendorVals,
  addVendorTwinVals,
  addVendorStepCapabilities,
  addVendorStepReviewCaps,
  addProcessVendorReviewCaps,
  addPullProcessVendorCaps,
  removePullProcessVendorCaps,
} = vendorSlice.actions;

export default vendorSlice.reducer;
