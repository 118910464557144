import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTabs: [],
  isDirty: false,
};

export const navSlice = createSlice({
  name: 'navSlice',
  initialState,
  reducers: {
    addActiveNav: (state, { payload }) => {
      // eslint-disable-next-line max-len
      if (state.activeTabs.length < 5 && payload.icon && !state.activeTabs.some((val) => val.icon === payload.icon)) {
        state.activeTabs.unshift(payload);
        // eslint-disable-next-line max-len
      } else if (state.activeTabs.length === 5 && payload.icon && !state.activeTabs.some((val) => val.icon === payload.icon)) {
        state.activeTabs.pop();
        state.activeTabs.unshift(payload);
      }
    },
    removeActiveNav: (state, { payload }) => {
      if (state.activeTabs?.length && payload?.icon) {
        state.activeTabs = state.activeTabs?.filter((val) => val?.icon !== payload?.icon);
      }
    },
    removeTabs: (state, { payload }) => {
      if (state.activeTabs.length > 0
        && state.activeTabs.some((val) => val.icon === payload.icon)) {
        const index = state.activeTabs.findIndex((x) => x.icon === payload.icon);

        state.activeTabs.splice(index, 1);
        // eslint-disable-next-line max-len
      }
    },
    addIsDirty: (state, { payload }) => {
      state.isDirty = payload;
    },
    shiftTabs: (state, { payload }) => {
      if (state.activeTabs.length > 1) {
        // const index = state.activeTabs.findIndex((x) => x.to === payload.to);
        state.activeTabs.splice(state.activeTabs.findIndex((x) => x.to === payload.to), 1);
        state.activeTabs.unshift(payload);
      }
    },
    shuffleTabs: (state, { payload }) => {
      state.activeTabs = payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  addActiveNav, addIsDirty, shiftTabs, shuffleTabs, removeTabs,
  removeActiveNav,
} = navSlice.actions;

export default navSlice.reducer;
