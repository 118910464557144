/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  OverlayTrigger, Tab, Tabs, Tooltip,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useBusinessTwinByIdMutation, useProductTwinByIdMutation } from '../../services/services';
import ProductTwinReview from './ProductTwinReview';
import { addPeople, setPeopleDisabled } from '../../services/peopleSlice';
import { addBusinessCaps } from '../../services/businessSlice';
import { addCapabilities, addProcessCapabilities } from '../../services/applicationSlice';
import {
  addRalatedDate, addRelatedChangeData, selectBU, selectDeprt, selectOrg, setIsTwinMode,
} from '../../services/formSlice';
import { addExtMtData } from '../../services/editFormSlice';
import { addVendorCaps } from '../../services/vendorSlice';
import { addCustomerCaps } from '../../services/customerSlice';
import { addReqEnhancement, addSelectedTwinRequirement } from '../../services/requirementSlice';
import { addChangEnhancement } from '../../services/reqChangeSlice';
import LoaderComp from '../../components/LoderQ';
import { addProductCaps, addProductTwinVals } from '../../services/productSlice';

const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function ProductTwinReadMode() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    type, taskId, twinId, draftMode,
  } = location.state;
  const [productTwin, productTwinRes] = useProductTwinByIdMutation();
  const productTwinVals = useSelector((state) => state.productData.productTwinVals);
  const [key, setKey] = useState('details');

  useEffect(() => {
    if (type === 'Product' || type === 'product') {
      productTwin({ id: twinId }, { pollingInterval: 4000 });
    }
  }, [type]);

  useEffect(() => {
    if (productTwinRes.isSuccess && productTwinRes?.data?.data) {
      const reqDT1 = productTwinRes?.data?.data?.requirements && Array.isArray(productTwinRes?.data?.data?.requirements) && productTwinRes?.data?.data?.requirements?.length > 0 ? productTwinRes?.data?.data?.requirements : [];
      const reqDT2 = productTwinRes?.data?.data?.change_id?.requirements && Array.isArray(productTwinRes?.data?.data?.change_id?.requirements) && productTwinRes?.data?.data?.change_id?.requirements?.length > 0 ? productTwinRes?.data?.data?.change_id?.requirements : [];
      const reqDT3 = (reqDT1 && Array.isArray(reqDT1) && reqDT1?.length > 0) ? reqDT2?.filter((el) => reqDT1?.some((el2) => (el?.id === el2?.id && el?.name === el2?.name))) : [];
      const [RQDT] = reqDT3;
      console.log('reqDT3', reqDT3, 'RQDT', RQDT);
      if (productTwinRes?.data?.data?.digital_twin_data) {
        const twinVals = { ...productTwinRes?.data?.data?.digital_twin_data };
        productTwinRes?.data?.extraFields?.forEach((dt) => twinVals[dt.field_name] = dt.value);
        const updatedExtMt = [];
        productTwinRes?.data?.extraFields?.forEach((dt) => {
          if ((dt.field_data_type === 'date' && dt?.value === '')) {
            updatedExtMt.push({ ...dt, value: '' });
          }
          if (
            dt.field_name === 'organizations'
          ) {
            updatedExtMt.push({
              ...dt,
              value: (productTwinRes?.data?.data?.digital_twin_data?.organizations && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.organizations) && productTwinRes?.data?.data?.digital_twin_data?.organizations?.length > 0)
                ? {
                  label: productTwinRes?.data?.data?.digital_twin_data?.organizations[0]?.name,
                  value: productTwinRes?.data?.data?.digital_twin_data?.organizations[0]?.id,
                } : '-',
            });
          } else if (dt.field_name === 'business_units') {
            updatedExtMt.push({
              ...dt,
              value: (productTwinRes?.data?.data?.digital_twin_data?.business_units && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.business_units) && productTwinRes?.data?.data?.digital_twin_data?.business_units?.length > 0)
                ? {
                  label: productTwinRes?.data?.data?.digital_twin_data?.business_units[0]?.name,
                  value: productTwinRes?.data?.data?.digital_twin_data?.business_units[0]?.id,
                } : '-',
            });
          } else if (dt.field_name === 'departments') {
            updatedExtMt.push({
              ...dt,
              value: (productTwinRes?.data?.data?.digital_twin_data?.departments && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.departments) && productTwinRes?.data?.data?.digital_twin_data?.departments?.length > 0)
                ? {
                  label: productTwinRes?.data?.data?.digital_twin_data?.departments[0]?.name,
                  value: productTwinRes?.data?.data?.digital_twin_data?.departments[0]?.id,
                } : '-',
            });
          } else if (
            dt.field_name === 'status'
          ) {
            updatedExtMt.push({ ...dt, value: { label: productTwinRes?.data?.data?.status, value: productTwinRes?.data?.data?.status } });
          } else if (dt.field_name === 'documents') {
            updatedExtMt.push({
              ...dt,
              value: Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.documents) && productTwinRes?.data?.data?.digital_twin_data?.documents?.length > 0
                ? productTwinRes?.data?.data?.digital_twin_data?.documents : null,
              isDisabled: false,
            });
          } else if (dt.field_name === 'description') {
            updatedExtMt.push({
              ...dt,
              value: productTwinRes?.data?.data?.digital_twin_data?.change_details ? productTwinRes?.data?.data?.digital_twin_data?.change_details : productTwinRes?.data?.data?.digital_twin_data?.description,
            });
          } else if (dt.field_name === 'change_details') {
            updatedExtMt.push({
              ...dt,
              value: '',
            });
          } else if (dt.field_name === 'delivery_date' && RQDT?.end_date) {
            updatedExtMt.push({
              ...dt,
              value: RQDT?.end_date,
              isDisabled: true,
            });
          } else {
            updatedExtMt.push({ ...dt, value: dt?.value });
          }
        });
        dispatch(
          addExtMtData({ fields: updatedExtMt, sections: productTwinRes?.data?.sections }),
        );
        dispatch(selectBU(productTwinRes?.data?.data?.digital_twin_data?.business_units));
        dispatch(selectOrg(productTwinRes?.data?.data?.digital_twin_data?.organizations));
        dispatch(selectDeprt(productTwinRes?.data?.data?.digital_twin_data?.departments));
        const updatedTwinVals = { ...productTwinRes?.data?.data?.digital_twin_data, status: productTwinRes?.data?.data?.status };
        dispatch(addProductTwinVals({
          ...updatedTwinVals,
          business_units: productTwinRes?.data?.data?.digital_twin_data?.business_units ? productTwinRes?.data?.data?.digital_twin_data?.business_units[0]?.id : '',
          organizations: productTwinRes?.data?.data?.digital_twin_data?.organizations ? productTwinRes?.data?.data?.digital_twin_data?.organizations[0]?.id : '',
          departments: productTwinRes?.data?.data?.digital_twin_data?.departments ? productTwinRes?.data?.data?.digital_twin_data?.departments[0]?.id : '',
          description: productTwinRes?.data?.data?.digital_twin_data?.change_details ? productTwinRes?.data?.data?.digital_twin_data?.change_details : productTwinRes?.data?.data?.digital_twin_data?.description,
          delivery_date: RQDT?.end_date ? RQDT?.end_date : productTwinRes?.data?.data?.digital_twin_data?.delivery_date,
          unique_id: productTwinRes?.data?.data?.digital_twin_data?.unique_id ? productTwinRes?.data?.data?.digital_twin_data?.unique_id : '',
        }));
      }
      dispatch(addPeople({
        owner: (productTwinRes?.data?.data?.digital_twin_data?.owners && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.owners) && productTwinRes?.data?.data?.digital_twin_data?.owners?.length > 0)
          ? productTwinRes?.data?.data?.digital_twin_data?.owners?.map((element) => ({ ...element, cancelabled: true })) : [],
        manager: (productTwinRes?.data?.data?.digital_twin_data?.managers && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.managers) && productTwinRes?.data?.data?.digital_twin_data?.managers?.length > 0)
          ? productTwinRes?.data?.data?.digital_twin_data?.managers?.map((element) => ({ ...element, cancelabled: true })) : [],
        teams: (productTwinRes?.data?.data?.digital_twin_data?.teams && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.teams) && productTwinRes?.data?.data?.digital_twin_data?.teams?.length > 0)
          ? productTwinRes?.data?.data?.digital_twin_data?.teams?.map((element) => ({ ...element, cancelabled: true }))
          : [],
      }));
      if (productTwinRes?.data?.data?.digital_twin_data?.capability_mappings && Array.isArray(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings) && productTwinRes?.data?.data?.digital_twin_data?.capability_mappings.length) {
        dispatch(addCapabilities(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessCapabilities(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addBusinessCaps(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProductCaps(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
        dispatch(addVendorCaps(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addCustomerCaps(productTwinRes?.data?.data?.digital_twin_data?.capability_mappings?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
      }
      dispatch(addRalatedDate({
        created: productTwinRes?.data?.data?.created || '',
        created_by: `${productTwinRes?.data?.data?.created_by?.first_name || ''} ${productTwinRes?.data?.data?.created_by?.last_name || ''}` || '',
        updated: productTwinRes?.data?.data?.updated || '',
        updated_by: `${productTwinRes?.data?.data?.updated_by?.first_name || ''} ${productTwinRes?.data?.data?.updated_by?.last_name || ''}` || '',
      }));
      console.log('productTwinRes', productTwinRes);

      // if (productTwinRes?.data?.data?.digital_twin_data?.status !== 'New') {
      dispatch(setPeopleDisabled(true));
      // }
      if (productTwinRes?.data?.data?.change_id) {
        dispatch(addRelatedChangeData([productTwinRes?.data?.data?.change_id]));
        const changeEnhancement = [{
          id: productTwinRes?.data?.data?.change_id?.id,
          name: productTwinRes?.data?.data?.change_id?.name,
          description: productTwinRes?.data?.data?.change_id?.description,
          comments: '',
        }];
        dispatch(addChangEnhancement(changeEnhancement));
        if (productTwinRes?.data?.data?.requirements && Array.isArray(productTwinRes?.data?.data?.requirements) && productTwinRes?.data?.data?.requirements?.length > 0) {
          const reqRes = productTwinRes?.data?.data?.change_id?.requirements?.filter((el) => productTwinRes?.data?.data?.requirements?.some((el2) => (el?.id === el2?.id && el?.name === el2?.name)));
          if (reqRes && Array.isArray(reqRes) && reqRes?.length > 0) {
            dispatch(addSelectedTwinRequirement(reqRes));
            const reqEnhancement = reqRes?.map((el) => ({
              id: el?.id,
              name: el?.name,
              description: el?.description,
              comments: '',
            }));
            dispatch(addReqEnhancement(reqEnhancement));
          }
        } else {
          dispatch(addSelectedTwinRequirement([]));
          dispatch(addReqEnhancement([]));
        }
      }
      dispatch(setIsTwinMode(true));
    }
  }, [productTwinRes.isSuccess]);

  console.log('productTwinRes?.data', productTwinRes?.data);
  return (
    <div className="content-wrapper">
      <div className="card">
        <div className="card-header has-lockicon">
          <h1>
            {(productTwinRes?.data?.data?.locked)
              ? (
                <OverlayTrigger placement="right" overlay={renderTooltip('Capability Twin Locked: Currently being edited by another collaborator. Please try again later.')}>
                  <span className="lock-icon locked">
                    <span className="icon-lock" />
                  </span>
                </OverlayTrigger>
              )
              : (
                <span className="lock-icon unlocked">
                  <span className="icon-unlock" />
                </span>
              )}
            {/* <span className={`lock-icon ${productTwinRes?.data?.data?.locked ? 'locked' : 'unlocked'} "`}>
              <span className={productTwinRes?.data?.data?.locked ? 'icon-lock' : 'icon-unlock'} />
              {' '}

            </span> */}
            Product Twin :
            {' '}
            {productTwinVals?.name}
          </h1>
        </div>
        <div className="card-body">
          <div className="pills-tabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="details" title="Details">
                {productTwinRes?.isLoading ? <LoaderComp />
                  : (
                    <ProductTwinReview
                      taskId={taskId}
                      twinId={twinId}
                      draftMode={draftMode}
                      readMode
                      peopleView={{
                        owner: true,
                        manager: true,
                        stCom: false,
                        teams: true,
                        sponsor: false,
                        collaborator: false,
                      }}
                      isLocked={productTwinRes?.data?.data?.locked}
                    />
                  )}
              </Tab>
              <Tab eventKey="dependency" title="Dependency">
                <h2>Dependency </h2>
              </Tab>
              <Tab eventKey="relatedObject" title="Related Objects">
                Related Objects
              </Tab>
              <Tab eventKey="logs" title="Logs">
                <h2>Logs</h2>
                <div className="table-responsive box-shadow">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        <th>Date Time</th>
                        <th>Person</th>
                        <th>Process</th>
                        <th>Messages</th>
                      </tr>
                      <tr className="filter-row">
                        <th>
                          <DatePicker
                            className="form-control"
                            placeholderText="DD/MM/YYYY"
                            portalId="root-portal"
                          />
                        </th>
                        <th>
                          <input type="search" className="form-control" placeholder="Search" />
                        </th>
                        <th>
                          <input type="search" className="form-control" placeholder="Search" />
                        </th>
                        <th>
                          <input type="search" className="form-control" placeholder="Search" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productTwinRes?.data?.change_log
                        && Array.isArray(productTwinRes?.data?.change_log)
                        && productTwinRes?.data?.change_log?.length > 0
                        ? productTwinRes?.data?.change_log?.map((ele) => (
                          <tr key={ele?.date}>
                            <td>{dayjs(ele?.date).format('D MMM YYYY, hh:mm A') || '-'}</td>
                            <td>
                              {ele?.user_name || '-'}
                            </td>
                            <td>{ele?.operation_type || '-'}</td>
                            {ele?.details
                              ? <td dangerouslySetInnerHTML={{ __html: ele?.details }} />
                              : <td>-</td>}
                          </tr>
                        )) : <tr><td className="text-center" colSpan={4}>No Data To Display</td></tr>}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductTwinReadMode;
