/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import { Button, Toast } from 'react-bootstrap';
import readXlsxFile from 'read-excel-file';
import Review from '../../components/common/Review';
import {
  useSaveDocsMutation,
  useSubmitRequirementMutation,
  useGetReqirementJsonQuery,
} from '../../services/services';
import {
  addAppTwinVals,
  addApplicationVals,
  addCapabilities,
  addCapsMapping,
  addChangeCapabilitiesBackUp,
  addProcessCapabilities,
  addProcessStepCapabilities,
  addRequirementCapabilitiesBackUp,
  addStepCapabilities,
} from '../../services/applicationSlice';
import {
  addBenefitDocs,
  addBudgetDocsInfo, addDocsInfo, addStepDocsInfo, clearDocsInfo,
} from '../../services/docInfoSlice';
import FormBuilder from '../../components/form/FormBuilder';
import { addChangeCollaborator, addPeople, addStepPeople } from '../../services/peopleSlice';
import People from '../../components/common/People';
import {
  addExtMtData, addProcessStepExtMtData, addvisibleFields, changeEditMode,
} from '../../services/editFormSlice';
import AlertMessage from '../../SuperComponents/AlertMessage';
import { addBenefit, addBenefitVals, addTrackingPointData } from '../../services/benefitSlice';
import { addRisk, addRiskMitigationData, addRiskVals } from '../../services/riskSlice';
import Capabilities from '../../components/capabalities';
import {
  addRalatedDate,
  addRelatedChangeData,
  addRelatedInitData,
  addRelatedProcessData,
  addRelatedProgData,
  isDraftMode,
  selectBU,
  selectDeprt,
  selectOrg,
  setStepBU,
  setStepDeprt,
  setStepOrg,
} from '../../services/formSlice';
import { addIsDirty, removeTabs } from '../../services/navSlice';
import { addRcVals } from '../../services/reqChangeSlice';
import LoaderComp from '../../components/LoderQ';
import {
  addCurrentSteps,
  addDuplicateVals,
  addEndDesc,
  addProcessStepRalatedDate,
  addProcessTwinVals,
  addProcessVals,
  addStartDesc,
  addStepVals,
  setSelectedStep,
  toggleProcessComponent,
} from '../../services/processSlice';
import {
  addBusinessCaps, addBusinessStepCapabilities, addBusinessTwinVals, addBusinessVals,
} from '../../services/businessSlice';
import {
  addVendorCaps, addVendorStepCapabilities, addVendorTwinVals, addVendorVals,
} from '../../services/vendorSlice';
import {
  addCustomerCaps, addCustomerStepCapabilities, addCustomerTwinVals, addCustomerVals,
} from '../../services/customerSlice';
import { addRequirementCollaborator, addRequirementVals, addSelectedRequirement } from '../../services/requirementSlice';
import RequirementPeople from './RequirementPeople';
import ErrorModal from '../../components/common/ErrorModal';
import { addProductCaps } from '../../services/productSlice';

dayjs.extend(isToday);

function CreateRequirement() {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('requirement');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Queries & Mutation
  const [saveDocs, docRes] = useSaveDocsMutation();
  const [submitForm, res] = useSubmitRequirementMutation();
  const formJson = useGetReqirementJsonQuery();

  // Redux State
  const editMode = useSelector((state) => state.editData.editMode);
  const editExtMtData = useSelector((state) => state.editData.extMtData);
  const requirementValues = useSelector((state) => state.requirementData.requirementValues);
  const dependentRequirement = useSelector((state) => state.requirementData.dependentRequirement);
  const peopleData = useSelector((state) => state.peopleData.peopleVals);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const budgetDocsInfo = useSelector((state) => state.docInfoData.budgetDocsInfo);
  const capsMapping = useSelector((state) => state.applicationData.capsMapping);
  console.log('require >>>>>>>>>', requirementValues, editMode);

  const [jsonTemplate, setJsonTemplate] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Form Steps
  const [steps, setSteps] = useState([
    {
      key: 'step1',
      label: t('strings.step1'),
      isDone: false,
    },
    {
      key: 'step2',
      label: t('strings.step2'),
      isDone: false,
    },
    {
      key: 'step3',
      label: t('strings.step3'),
      isDone: false,
    },
    {
      key: 'step4',
      label: t('strings.step6'),
      isDone: false,
    },
  ]);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [modalShow, setModalShow] = React.useState(false);

  const cancelTitle = 'Requirement creation';

  // Function to move next step
  const handleNext = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) => prevStep.map((x) => {
      if (x.key === activeStep.key) x.isDone = true;
      return x;
    }));
    setActiveStep(steps[index + 1]);
  };

  // function for back
  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;
    setSteps((prevStep) => prevStep.map((x) => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }));
    setActiveStep(steps[index - 1]);
  };

  // reset step and active step
  useEffect(() => {
    if (location?.state?.isNewApp) {
      setSteps(steps?.map((el) => ({ ...el, isDone: false })));
      setActiveStep(steps[0]);
    } else if (!location?.state?.isNewApp && location?.state?.steps && location?.state?.activeStep) {
      setSteps(location?.state?.steps);
      setActiveStep(location?.state?.activeStep);
      setModalShow(false);
    }
  }, [location?.state]);

  // Setting ext Mt Dt as json
  useEffect(() => {
    if (editMode) {
      setJsonTemplate(editExtMtData);
      if (requirementValues?.documents) {
        dispatch(addDocsInfo(requirementValues?.documents));
      }
      if (requirementValues?.budget_documents) {
        dispatch(addBudgetDocsInfo(requirementValues?.budget_documents));
      }
    }
  }, [editMode]);

  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (formJson?.isSuccess) {
      setJsonTemplate(formJson.data);
    }
  }, [formJson?.isSuccess]);

  // Save Doc Res to State
  useEffect(() => {
    if (docRes.isSuccess && (docRes.data.docData && docRes.data.docData?.length > 0 && Array.isArray(docRes.data.docData))) {
      const docsData = docRes.data.docData.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addDocsInfo(docsData));
    }
    if (docRes.isSuccess && (docRes.data.budgetDocData && docRes.data.budgetDocData?.length > 0 && Array.isArray(docRes.data.budgetDocData))) {
      const budgetDocs = docRes.data.budgetDocData.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addBudgetDocsInfo(budgetDocs));
    }
  }, [docRes.isSuccess]);

  // Function to set State while clicking on Next
  const onSubmit = (values) => {
    const vals = {
      ...values,
      status: values.status,
      unique_id: values?.unique_id ? values?.unique_id : '-',
      documents: values?.documents || null,
      // budget_documents: values?.budget_documents || null,
    };
    if (vals?.documents || vals?.budget_documents) {
      const docsCopy = vals?.documents ? vals?.documents?.filter((el) => !docsInfo?.some((dc) => dc.s3_key_name === el.s3_key_name)) : [];
      const budgetCopy = vals?.budget_documents ? vals?.budget_documents?.filter((el) => !budgetDocsInfo?.some((bdc) => bdc.s3_key_name === el.s3_key_name)) : [];
      saveDocs({ data: docsCopy, budgetData: budgetCopy, effortData: [] });
    }

    const updated = editMode
      ? editExtMtData?.fields?.map((dt) => ({ ...dt, value: (dt.field_data_type === 'select' && dt.field_type === 'System') ? { label: dt.field_value_option.options.find((op) => op.value === vals[dt.field_name])?.name, value: vals[dt.field_name] } : vals[dt.field_name] || '' }))
      : formJson.data?.fields?.map((dt) => ({ ...dt, value: (dt.field_data_type === 'select' && dt.field_type === 'System' && vals[dt.field_name]) ? { label: dt.field_value_option.options.find((op) => op.value === vals[dt.field_name])?.name, value: vals[dt.field_name] } : vals[dt.field_name] || '' }));

    console.log('updated', updated);
    dispatch(addRequirementVals(vals));
    dispatch(
      addExtMtData({ fields: updated, sections: formJson?.data?.sections }),
    );
    dispatch(addvisibleFields(
      [
        { name: 'capabilities', visible: true },
        { name: 'people', visible: true },
      ],
    ));
    handleNext();
  };

  console.log('check app location', location?.state?.from, location?.state);

  // Reset All steps
  const stepReset = () => {
    dispatch(addIsDirty(false));
    if (location?.state?.from && location?.state?.from === '/change') {
      dispatch(addExtMtData(location?.state?.data?.extMtData));
      dispatch(addCapabilities(location?.state?.data?.appCapsDt));
      dispatch(addProcessCapabilities(location?.state?.data?.processCapsDt));
      dispatch(addBusinessCaps(location?.state?.data?.businessCapsDt));
      dispatch(addVendorCaps(location?.state?.data?.vendorCapsDt));
      dispatch(addCustomerCaps(location?.state?.data?.customerCapsDt));
      dispatch(addProductCaps(location?.state?.data?.productCapsDt));
      dispatch(addRisk(location?.state?.data?.riskData));
      dispatch(addBenefit(location?.state?.data?.benefitData));
      dispatch(addPeople(location?.state?.data?.peopleData));
      dispatch(addRelatedProgData(location?.state?.data?.relatedProgram));
      dispatch(addRelatedInitData(location?.state?.data?.relatedInitiative));
      dispatch(addRelatedChangeData(location?.state?.data?.relatedChange));
      dispatch(addvisibleFields(location?.state.data?.visibleField));
      dispatch(addDocsInfo(location?.state?.data?.docsInfo));
      dispatch(addBudgetDocsInfo(location?.state?.data?.budgetDocsInfo));
      dispatch(addChangeCapabilitiesBackUp(location?.state?.data?.changeCapabilities));
      dispatch(addRalatedDate(location?.state?.data?.relatedDate));
      dispatch(changeEditMode(location?.state?.data?.editMode));
      dispatch(isDraftMode(location?.state?.data?.draftMode));
      dispatch(addSelectedRequirement(location?.state?.data?.selectedRequirement));
      dispatch(addRequirementCapabilitiesBackUp(location?.state?.data?.requirementCapabilities));
      dispatch(addRequirementCollaborator(location?.state?.data?.requirementCollaborator));
      dispatch(addChangeCollaborator(location?.state?.data?.changeCollaborator));
      if (location?.state?.from === '/change') {
        dispatch(addRcVals(location?.state?.data?.dfVals));
      }
      navigate(location?.state?.from, {
        state: {
          steps: location?.state?.data?.steps,
          activeStep: location?.state?.data?.activeStep,
          breadcrumb: location?.state?.data?.breadcrumb,
        },
      });
    } else if (location?.state?.change_data?.from && location?.state?.change_data?.from === '/change') {
      dispatch(addExtMtData(location?.state?.change_data?.data?.extMtData));
      dispatch(addCapabilities(location?.state?.change_data?.data?.appCapsDt));
      dispatch(addProcessCapabilities(location?.state?.change_data?.data?.processCapsDt));
      dispatch(addBusinessCaps(location?.state?.change_data?.data?.businessCapsDt));
      dispatch(addVendorCaps(location?.state?.change_data?.data?.vendorCapsDt));
      dispatch(addCustomerCaps(location?.state?.change_data?.data?.customerCapsDt));
      dispatch(addProductCaps(location?.state?.change_data?.data?.productCapsDt));
      dispatch(addRisk(location?.state?.change_data?.data?.riskData));
      dispatch(addBenefit(location?.state?.change_data?.data?.benefitData));
      dispatch(addPeople(location?.state?.change_data?.data?.peopleData));
      dispatch(addRelatedProgData(location?.state?.change_data?.data?.relatedProgram));
      dispatch(addRelatedInitData(location?.state?.change_data?.data?.relatedInitiative));
      dispatch(addRelatedChangeData(location?.state?.change_data?.data?.relatedChange));
      dispatch(addvisibleFields(location?.state?.change_data?.data?.visibleField));
      dispatch(addDocsInfo(location?.state?.change_data?.data?.docsInfo));
      dispatch(addBudgetDocsInfo(location?.state?.change_data?.data?.budgetDocsInfo));
      dispatch(addChangeCapabilitiesBackUp(location?.state?.change_data?.data?.changeCapabilities));
      dispatch(addRalatedDate(location?.state?.change_data?.data?.relatedDate));
      dispatch(changeEditMode(location?.state?.change_data?.data?.editMode));
      dispatch(isDraftMode(location?.state?.change_data?.data?.draftMode));
      dispatch(addSelectedRequirement(location?.state?.change_data?.data?.selectedRequirement));
      dispatch(addRequirementCapabilitiesBackUp(location?.state?.change_data?.data?.requirementCapabilities));
      dispatch(addRequirementCollaborator(location?.state?.change_data?.data?.requirementCollaborator));
      dispatch(addChangeCollaborator(location?.state?.change_data?.data?.changeCollaborator));
      if (location?.state?.change_data?.from === '/change') {
        dispatch(addRcVals(location?.state?.change_data?.data?.dfVals));
      }
      navigate(location?.state?.change_data?.from, {
        state: {
          steps: location?.state?.change_data?.data?.steps,
          activeStep: location?.state?.change_data?.data?.activeStep,
          breadcrumb: location?.state?.change_data?.data?.breadcrumb,
        },
      });
    } else {
      dispatch({ type: 'RESET_APP' });
      setTimeout(() => {
        navigate('/dashboard', {
          state: {
            selected: 'Requirements',
          },
        });
      }, 500);
    }
  };

  // Remove Success Message
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }
  }, [successMessage]);

  console.log('requirement location > ', location?.state);

  // After Submit Redirect & Success Message
  useEffect(() => {
    if (res?.isSuccess && res?.data) {
      setSuccessMessage(`Requirement ${res?.data?.mode === 'draft' ? 'Saved' : 'Submitted'} Successfully`);
      dispatch(addIsDirty(false));
      dispatch(clearDocsInfo());
      if (location?.state?.from && location?.state?.from === '/change') {
        dispatch(addExtMtData(location?.state?.data?.extMtData));
        dispatch(addCapabilities(location?.state?.data?.appCapsDt));
        dispatch(addProcessCapabilities(location?.state?.data?.processCapsDt));
        dispatch(addBusinessCaps(location?.state?.data?.businessCapsDt));
        dispatch(addVendorCaps(location?.state?.data?.vendorCapsDt));
        dispatch(addCustomerCaps(location?.state?.data?.customerCapsDt));
        dispatch(addProductCaps(location?.state?.data?.productCapsDt));
        dispatch(addRisk(location?.state?.data?.riskData));
        dispatch(addBenefit(location?.state?.data?.benefitData));
        dispatch(addPeople(location?.state?.data?.peopleData));
        dispatch(addRelatedProgData(location?.state?.data?.relatedProgram));
        dispatch(addRelatedInitData(location?.state?.data?.relatedInitiative));
        dispatch(addRelatedChangeData(location?.state?.data?.relatedChange));
        dispatch(addvisibleFields(location?.state.data?.visibleField));
        dispatch(addDocsInfo(location?.state?.data?.docsInfo));
        dispatch(addBudgetDocsInfo(location?.state?.data?.budgetDocsInfo));
        dispatch(addChangeCapabilitiesBackUp(location?.state?.data?.changeCapabilities));
        dispatch(addRalatedDate(location?.state?.data?.relatedDate));
        dispatch(changeEditMode(location?.state?.data?.editMode));
        dispatch(isDraftMode(location?.state?.data?.draftMode));
        const requirementData = location?.state?.data?.selectedRequirement;
        if (requirementData?.some((el) => el?.id === res?.data?.id)) {
          const resReq1 = requirementData?.filter((el) => el?.id !== res?.data?.id);
          const resReq2 = {
            ...res?.data,
            capability_mappings: (res?.data?.capability_mappings && Array.isArray(res?.data?.capability_mappings) && res?.data?.capability_mappings?.length > 0) ? res?.data?.capability_mappings?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
              })),
            })) : [],
            collaborators: (res?.data?.collaborators && Array.isArray(res?.data?.collaborators) && res?.data?.collaborators?.length > 0) ? res?.data?.collaborators?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
                requirement_data: [{
                  id: res?.data?.id,
                  name: res?.data?.name,
                }],
                collaborators: (el3?.collaborators && Array.isArray(el3?.collaborators) && el3.collaborators?.length > 0)
                  ? el3?.collaborators?.map((cb) => ({
                    ...cb,
                    object_name: `${res?.data?.name} : ${el3?.name}`,
                  }))
                  : [],
              })),
            })) : [],
          };
          console.log('resReq2', resReq2);
          dispatch(addSelectedRequirement([...resReq1, resReq2]));
          const cbRes1 = location?.state?.data?.requirementCollaborator ? location?.state?.data?.requirementCollaborator?.map((el) => el?.value?.map((el2) => ({ ...el2, key: el?.key })))?.flat()?.filter((el) => el !== undefined) : [];
          const cbRes2 = resReq2?.collaborators?.flat()?.filter((el) => el !== undefined);
          const cbRes3 = cbRes2?.map((el) => el?.value?.map((el2) => ({ ...el2, key: el?.key })))?.flat()?.filter((el) => el !== undefined);
          // const cbRes4 = cbRes1?.filter((el) => cbRes3?.find((el2) => (el2?.name === el?.name && el2?.id === el?.id)));
          console.log('cbRes1', cbRes1, 'cbRes3', cbRes3);
          const cbRes4 = [...cbRes1, ...cbRes3]?.flat()?.filter((el) => el !== undefined);
          const cbRes5 = (cbRes4 && Array.isArray(cbRes4) && cbRes4?.length > 0) ? cbRes4?.map((el) => ({
            ...el,
            requirement_data: el?.requirement_data && Array.isArray(el?.requirement_data) && el?.requirement_data?.length > 0 ? el?.requirement_data : [],
            collaborators: el?.collaborators && Array.isArray(el?.collaborators) && el?.collaborators?.length > 0 ? el?.collaborators : [],
          })) : [];
          console.log('cbRes5', cbRes5);
          const uniquecCOB = [];
          if (cbRes5 && Array.isArray(cbRes5) && cbRes5?.length > 0) {
            cbRes5?.forEach((object) => {
              const existing = uniquecCOB.filter((item) => (item?.id === object?.id && item?.key === object?.key));
              if (existing?.length) {
                const existingIndex = uniquecCOB.indexOf(existing[0]);
                uniquecCOB[existingIndex].collaborators = uniquecCOB[existingIndex]?.collaborators.concat(object.collaborators);
                uniquecCOB[existingIndex].requirement_data = uniquecCOB[existingIndex]?.requirement_data.concat(object.requirement_data);
              } else {
                uniquecCOB.push(object);
              }
            });
          }
          const filterData = uniquecCOB?.map((el) => ({
            ...el,
            requirement_data: el?.requirement_data?.filter(
              (obj, index) => el?.requirement_data?.findIndex((item) => item?.name === obj?.name && item?.id === obj?.id) === index,
            ),
          }));

          // const resCob1 = (resReq2?.collaborators && Array.isArray(resReq2?.collaborators) && resReq2?.collaborators?.length > 0)
          //   ? resReq2?.collaborators?.map((el) => el?.value?.map((el2) => ({ ...el2, key: el?.key })))?.flat()?.filter((el) => el !== undefined) : [];
          // const filterData2 = (resCob1 && Array.isArray(resCob1) && resCob1?.length > 0)
          //   ? filterData?.filter((el) => resCob1?.some((el2) => (el2?.name === el?.name && el2?.id === el?.id)))
          //   : filterData;

          console.log('uniquecCOB', uniquecCOB);
          console.log('filterData', filterData);
          // console.log('filterData2', filterData2);

          if (filterData && Array.isArray(filterData) && filterData?.length > 0) {
            const finalCb1 = location?.state?.data?.requirementCollaborator?.map((el) => ({ ...el, value: filterData?.filter((ucb) => ucb?.key === el?.key) }));
            const finalCb = finalCb1?.map((el) => ({ key: el?.key, value: el?.value }));
            dispatch(addRequirementCollaborator(finalCb));
          }
        } else {
          const result1 = {
            ...res?.data,
            capability_mappings: (res?.data?.capability_mappings && Array.isArray(res?.data?.capability_mappings) && res?.data?.capability_mappings?.length > 0) ? res?.data?.capability_mappings?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
              })),
            })) : [],
            collaborators: (res?.data?.collaborators && Array.isArray(res?.data?.collaborators) && res?.data?.collaborators?.length > 0) ? res?.data?.collaborators?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
                requirement_data: [{
                  id: res?.data?.id,
                  name: res?.data?.name,
                }],
                collaborators: (el3?.collaborators && Array.isArray(el3?.collaborators) && el3.collaborators?.length > 0)
                  ? el3?.collaborators?.map((cb) => ({
                    ...cb,
                    object_name: `${res?.data?.name} : ${el3?.name}`,
                  }))
                  : [],
              })),
            })) : [],
          };
          dispatch(addSelectedRequirement([...requirementData, result1]));
          dispatch(addRequirementCollaborator(location?.state?.data?.requirementCollaborator));
        }
        dispatch(addRequirementCapabilitiesBackUp(location?.state?.data?.requirementCapabilities));
        dispatch(addChangeCollaborator(location?.state?.data?.changeCollaborator));
        if (location?.state?.from === '/change') {
          dispatch(addRcVals(location?.state?.data?.dfVals));
        }
        setTimeout(() => {
          navigate(location?.state?.from, {
            state: {
              steps: location?.state?.data?.steps,
              activeStep: location?.state?.data?.activeStep,
              breadcrumb: location?.state?.data?.breadcrumb,
            },
          });
        }, 400);
      } else if (location?.state?.change_data?.from && location?.state?.change_data?.from === '/change') {
        dispatch(addExtMtData(location?.state?.change_data?.data?.extMtData));
        dispatch(addCapabilities(location?.state?.change_data?.data?.appCapsDt));
        dispatch(addProcessCapabilities(location?.state?.change_data?.data?.processCapsDt));
        dispatch(addBusinessCaps(location?.state?.change_data?.data?.businessCapsDt));
        dispatch(addVendorCaps(location?.state?.change_data?.data?.vendorCapsDt));
        dispatch(addCustomerCaps(location?.state?.change_data?.data?.customerCapsDt));
        dispatch(addProductCaps(location?.state?.change_data?.data?.productCapsDt));
        dispatch(addRisk(location?.state?.change_data?.data?.riskData));
        dispatch(addBenefit(location?.state?.change_data?.data?.benefitData));
        dispatch(addPeople(location?.state?.change_data?.data?.peopleData));
        dispatch(addRelatedProgData(location?.state?.change_data?.data?.relatedProgram));
        dispatch(addRelatedInitData(location?.state?.change_data?.data?.relatedInitiative));
        dispatch(addRelatedChangeData(location?.state?.change_data?.data?.relatedChange));
        dispatch(addvisibleFields(location?.state?.change_data?.data?.visibleField));
        dispatch(addDocsInfo(location?.state?.change_data?.data?.docsInfo));
        dispatch(addBudgetDocsInfo(location?.state?.change_data?.data?.budgetDocsInfo));
        dispatch(addChangeCapabilitiesBackUp(location?.state?.change_data?.data?.changeCapabilities));
        dispatch(addRalatedDate(location?.state?.change_data?.data?.relatedDate));
        dispatch(changeEditMode(location?.state?.change_data?.data?.editMode));
        dispatch(isDraftMode(location?.state?.change_data?.data?.draftMode));
        const requirementData = location?.state?.change_data?.data?.selectedRequirement;
        if (requirementData?.some((el) => el?.id === res?.data?.id)) {
          const resReq1 = requirementData?.filter((el) => el?.id !== res?.data?.id);
          const resReq2 = {
            ...res?.data,
            capability_mappings: (res?.data?.capability_mappings && Array.isArray(res?.data?.capability_mappings) && res?.data?.capability_mappings?.length > 0) ? res?.data?.capability_mappings?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
              })),
            })) : [],
            collaborators: (res?.data?.collaborators && Array.isArray(res?.data?.collaborators) && res?.data?.collaborators?.length > 0) ? res?.data?.collaborators?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
                requirement_data: [{
                  id: res?.data?.id,
                  name: res?.data?.name,
                }],
                collaborators: (el3?.collaborators && Array.isArray(el3?.collaborators) && el3.collaborators?.length > 0)
                  ? el3?.collaborators?.map((cb) => ({
                    ...cb,
                    object_name: `${res?.data?.name} : ${el3?.name}`,
                  }))
                  : [],
              })),
            })) : [],
          };
          console.log('resReq2', resReq2);
          dispatch(addSelectedRequirement([...resReq1, resReq2]));
          const cbRes1 = location?.state?.change_data?.data?.requirementCollaborator ? location?.state?.change_data?.data?.requirementCollaborator?.map((el) => el?.value?.map((el2) => ({ ...el2, key: el?.key })))?.flat()?.filter((el) => el !== undefined) : [];
          const cbRes2 = resReq2?.collaborators?.flat()?.filter((el) => el !== undefined);
          const cbRes3 = cbRes2?.map((el) => el?.value?.map((el2) => ({ ...el2, key: el?.key })))?.flat()?.filter((el) => el !== undefined);
          // const cbRes4 = cbRes1?.filter((el) => cbRes3?.find((el2) => (el2?.name === el?.name && el2?.id === el?.id)));
          const cbRes4 = [...cbRes1, ...cbRes3]?.flat()?.filter((el) => el !== undefined);
          const cbRes5 = (cbRes4 && Array.isArray(cbRes4) && cbRes4?.length > 0) ? cbRes4?.map((el) => ({
            ...el,
            requirement_data: el?.requirement_data && Array.isArray(el?.requirement_data) && el?.requirement_data?.length > 0 ? el?.requirement_data : [],
            collaborators: el?.collaborators && Array.isArray(el?.collaborators) && el?.collaborators?.length > 0 ? el?.collaborators : [],
          })) : [];
          console.log('cbRes5', cbRes5);
          const uniquecCOB = [];
          if (cbRes5 && Array.isArray(cbRes5) && cbRes5?.length > 0) {
            cbRes5?.forEach((object) => {
              const existing = uniquecCOB.filter((item) => (item?.id === object?.id && item?.key === object?.key));
              if (existing?.length) {
                const existingIndex = uniquecCOB.indexOf(existing[0]);
                uniquecCOB[existingIndex].collaborators = uniquecCOB[existingIndex]?.collaborators.concat(object.collaborators);
                uniquecCOB[existingIndex].requirement_data = uniquecCOB[existingIndex]?.requirement_data.concat(object.requirement_data);
              } else {
                uniquecCOB.push(object);
              }
            });
          }
          const filterData = uniquecCOB?.map((el) => ({
            ...el,
            requirement_data: el?.requirement_data?.filter(
              (obj, index) => el?.requirement_data?.findIndex((item) => item?.name === obj?.name && item?.id === obj?.id) === index,
            ),
          }));
          console.log('uniquecCOB', uniquecCOB);
          console.log('filterData', filterData);

          if (filterData && Array.isArray(filterData) && filterData?.length > 0) {
            const finalCb1 = location?.state?.change_data?.data?.requirementCollaborator?.map((el) => ({ ...el, value: filterData?.filter((ucb) => ucb?.key === el?.key) }));
            const finalCb = finalCb1?.map((el) => ({ key: el?.key, value: el?.value }));
            dispatch(addRequirementCollaborator(finalCb));
          }
        } else {
          const result1 = {
            ...res?.data,
            capability_mappings: (res?.data?.capability_mappings && Array.isArray(res?.data?.capability_mappings) && res?.data?.capability_mappings?.length > 0) ? res?.data?.capability_mappings?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
              })),
            })) : [],
            collaborators: (res?.data?.collaborators && Array.isArray(res?.data?.collaborators) && res?.data?.collaborators?.length > 0) ? res?.data?.collaborators?.map((el2) => ({
              ...el2,
              value: el2?.value?.map((el3) => ({
                ...el3,
                requirement_id: res?.data?.id,
                object_name: `${res?.data?.name} : ${el3?.name}`,
                requirement_data: [{
                  id: res?.data?.id,
                  name: res?.data?.name,
                }],
                collaborators: (el3?.collaborators && Array.isArray(el3?.collaborators) && el3.collaborators?.length > 0)
                  ? el3?.collaborators?.map((cb) => ({
                    ...cb,
                    object_name: `${res?.data?.name} : ${el3?.name}`,
                  }))
                  : [],
              })),
            })) : [],
          };
          dispatch(addSelectedRequirement([...requirementData, result1]));
          dispatch(addRequirementCollaborator(location?.state?.change_data?.data?.requirementCollaborator));
        }
        dispatch(addRequirementCapabilitiesBackUp(location?.state?.change_data?.data?.requirementCapabilities));
        dispatch(addChangeCollaborator(location?.state?.change_data?.data?.changeCollaborator));
        if (location?.state?.change_data?.from === '/change') {
          dispatch(addRcVals(location?.state?.change_data?.data?.dfVals));
        }
        setTimeout(() => {
          navigate(location?.state?.change_data?.from, {
            state: {
              steps: location?.state?.change_data?.data?.steps,
              activeStep: location?.state?.change_data?.data?.activeStep,
              breadcrumb: location?.state?.change_data?.data?.breadcrumb,
            },
          });
        }, 400);
      } else {
        setTimeout(() => {
          dispatch({ type: 'RESET_APP' });
          navigate('/dashboard', {
            state: {
              selected: 'Requirements',
            },
          });
        }, 300);
      }
    }
  }, [res]);

  // Submit Flow function
  const saveRequirement = (e, mode) => {
    const extensionMetaDataArr = editExtMtData?.fields?.map((fl) => ((fl.field_data_type === 'date' && requirementValues[fl.field_name]) ? { ...fl, value: dayjs(requirementValues[fl.field_name]).format('YYYY-MM-DD hh:mm:ss') } : { ...fl }));
    const extensionMetaData = extensionMetaDataArr.map(({ isDisabled, ...rest }) => rest);
    const finalData = {
      ...requirementValues,
      collaborators: peopleData.reqCollaborator && Array.isArray(peopleData.reqCollaborator) && peopleData.reqCollaborator?.length > 0 ? peopleData?.reqCollaborator?.map((el) => ({ key: el.key, value: el.value }))?.filter((ele) => ele?.value?.length !== 0) : null,
      capability_mappings: (capsMapping && Array.isArray(capsMapping) && capsMapping.length > 0) ? capsMapping.filter((ele) => ele.value.length !== 0) : null,
      requirement_extensions: extensionMetaData?.map((ed) => ((ed.field_data_type === 'select' && ed.field_type === 'System') ? ({ ...ed, value: `${ed?.value?.value}` }) : ({ ...ed, value: `${ed.value}` }))),
      created: editMode ? requirementValues?.created : dayjs().format('YYYY-MM-DD hh:mm:ss'),
      created_by: {
        id: userData?.id,
      },
      updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      start_date: dayjs(requirementValues.start_date).format('YYYY-MM-DD hh:mm:ss'),
      end_date: dayjs(requirementValues.end_date).format('YYYY-MM-DD hh:mm:ss'),
      notes: requirementValues?.notes ? requirementValues?.notes : '',
      updated_by: {
        id: userData?.id,
      },
      deleted: false,
      status: (mode === 'submitted' && requirementValues?.status?.trim() === 'New') ? 'Open' : mode === 'submitted' && (requirementValues?.status?.trim() !== 'New' || requirementValues?.status?.trim() !== 'Open') ? requirementValues?.status : 'New',
      documents: docsInfo || null,
      mode,
      dependent_requirements: dependentRequirement && Array.isArray(dependentRequirement) && dependentRequirement?.length > 0 ? dependentRequirement : [],
    };
    if (!editMode) {
      delete finalData.id;
      delete finalData.unique_id;
      submitForm(finalData);
    } else {
      submitForm(finalData);
    }
    const reqState = {
      ...requirementValues,
      created: editMode ? requirementValues?.created : dayjs().format('YYYY-MM-DD hh:mm:ss'),
      created_by: {
        id: userData?.id,
      },
      updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      updated_by: {
        id: userData?.id,
      },
      start_date: dayjs(requirementValues.start_date).format('YYYY-MM-DD hh:mm:ss'),
      end_date: dayjs(requirementValues.end_date).format('YYYY-MM-DD hh:mm:ss'),
      capability_mappings: (capsMapping && Array.isArray(capsMapping) && capsMapping.length) ? capsMapping.filter((ele) => ele.value.length !== 0) : null,
      mode,
      collaborators: null,
      deleted: false,
      requirements_extensions: extensionMetaData?.map((ed) => ((ed.field_data_type === 'select' && ed.field_type === 'System') ? ({ ...ed, value: `${ed?.value?.value}` }) : ({ ...ed, value: `${ed.value}` }))),
    };
    console.log('reqState', reqState);
    // if (!editMode) {
    //   delete reqState.id;
    //   delete reqState.unique_id;
    //   submitForm(reqState);
    // } else {
    //   submitForm(reqState);
    // }
  };

  // Return Starts
  return (
    <div className="content-wrapper">
      {(res?.isError) && <ErrorModal />}
      {(res.isLoading) && (
      <LoaderComp />
      )}
      {successMessage && (
        <AlertMessage message={successMessage} setter={setSuccessMessage} />
      )}

      <div className="card">
        <div className="card-header">
          <h1>
            {!editMode ? t('create_new_requirement') : `REQUIREMENT: ${requirementValues?.name}`}
          </h1>
        </div>

        <div className="card-body">
          {/* <input type="file" accept=".xls, .xlsx" className="form-control" onChange={(e) => handleExcelFile(e)} />
          <Button onClick={() => handleExcelToJson()}>Excel To Json</Button> */}

          <div className="step-count-wrapper">
            <ul className="list-unstyled mb-0 steps-list d-flex align-items-center justify-content-center">
              {steps.map((step, i) => (
                <li
                  key={step.key}
                  className={`d-flex align-items-center ${
                    activeStep.key === step.key ? 'active' : ''
                  } ${step.isDone ? 'success' : ''}`}
                >
                  <span className="step-count">
                    <span className="icon-progress" />
                    {i + 1}
                  </span>
                  <p>{step.label}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="form-wrapper">

            {activeStep.key === 'step1' && (
              <FormBuilder
                steps={steps}
                label="requirement"
                activeStep={activeStep}
                handleBack={handleBack}
                template={editMode ? editExtMtData : jsonTemplate}
                onNext={onSubmit}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                dfVals={requirementValues}
                editMode={editMode}
                cancelTitle={cancelTitle}
              />
            )}
            {activeStep.key === 'step2'
            && (
              <Capabilities
                label="requirement"
                t={t}
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                cancelTitle={cancelTitle}
              />
            )}

            {activeStep.key === 'step3' && (
              <RequirementPeople
                label="requirement"
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleBack={handleBack}
                handleNext={handleNext}
                edit={editMode}
                template={jsonTemplate}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                fields={{
                  owner: false,
                  manager: false,
                  stCom: false,
                  teams: false,
                  sponsor: false,
                  collaborator: true,
                  resp_person: false,
                }}
                cancelTitle={cancelTitle}
              />
            )}

            {activeStep.key === 'step4' && (
              <Review
                review="requirement"
                label="Requirement"
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                submit={saveRequirement}
                cancelTitle={cancelTitle}
                isLoading={res?.isLoading}
                isError={res?.isError}
                peopleView={{
                  owner: false,
                  manager: false,
                  stCom: false,
                  teams: false,
                  sponsor: false,
                  collaborator: true,
                  resp_person: false,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const Requirement = React.memo(CreateRequirement);

export default Requirement;
