import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  redirectState: {},
};

export const redirectSlice = createSlice({
  name: 'redirectSlice',
  initialState,
  reducers: {
    addRedirectState: (state, { payload }) => {
      state.redirectState = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addRedirectState,
} = redirectSlice.actions;

export default redirectSlice.reducer;
