/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function Tree({
  data = [], handleClick, selectedApp, collapseHandle,
}) {
  return (
    <ul className="list-unstyled mb-0">
      {(data && data?.length > 0 && Array.isArray(data))
      && data.map((tree) => (
        <TreeNode
          key={tree.key}
          node={tree}
          handleClick={handleClick}
          selectedApp={selectedApp}
          collapseHandle={collapseHandle}
        />
      ))}
    </ul>
  );
}

function TreeNode({
  node, handleClick, selectedApp, collapseHandle,
}) {
  const location = useLocation();
  const [childVisible, setChildVisiblity] = useState(true);
  const hasChild = node.children;
  const editMode = useSelector((state) => state.editData.editMode);
  const draftMode = useSelector((state) => state.formJson.draftMode);
  const requirementData = useSelector((state) => state.requirementData.selectedRequirement);
  console.log('requirementData', requirementData);

  const handleChildVisibility = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setChildVisiblity((v) => !v);
  };
  useEffect(() => {
    if (collapseHandle) {
      if (childVisible === false) {
        collapseHandle(false);
      } else {
        collapseHandle(true);
      }
    }
  }, [childVisible, collapseHandle]);
  return (
    <li
      className={` ${childVisible ? 'open-tree' : ''} ${
        hasChild ? 'has-sub' : ''
      }`}
    >
      {hasChild ? (
        <OverlayTrigger placement="bottom" overlay={renderTooltip(node?.label)}>
          <a onClick={(e) => handleChildVisibility(e)}>
            <span className="icon-sort" />
            <span className={node.icon} />
            {node?.label?.length > 20 ? `${node?.label?.substring(0, 20)}...` : node?.label}
            {node.children
            && node.children.length > 0
            && `(${node.children.length})`}
          </a>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="bottom" overlay={renderTooltip(node?.label)}>
          <a onClick={(e) => handleChildVisibility(e)}>
            <span className={node.icon} />
            {/* {node.label} */}

            {/* {(node.clickable && location?.pathname !== '/requirement' && !(editMode && !draftMode)) ? ( */}
            {(node.clickable && location?.pathname !== '/requirement') ? (
              <em onClick={(e) => handleClick(node, e)}>
                {node?.label?.length > 20 ? `${node?.label?.substring(0, 20)}...` : node?.label}
              </em>
            )
              : location?.pathname === '/requirement' && node.clickable ? (
                <em onClick={(e) => handleClick(node, e)}>
                  {node?.label?.length > 20 ? `${node?.label?.substring(0, 20)}...` : node?.label}
                </em>
              )
                : node?.label?.length > 20 ? `${node?.label?.substring(0, 20)}...` : node?.label}
            {node.children
            && node.children.length > 0
            && `(${node.children.length})`}
          </a>
        </OverlayTrigger>
      )}

      {hasChild && childVisible && (
        <Tree
          data={node.children}
          handleClick={handleClick}
          selectedApp={selectedApp}
          collapseHandle={collapseHandle}
        />
      )}
      {node?.key === 'change tree' && requirementData && Array.isArray(requirementData) && requirementData?.length > 0
            && (

              requirementData?.map((el) => (
                <ul className="list-unstyled mb-0  has-sub">

                  <li className="open-tree">
                    <a>
                      <span className="icon-sort" />
                      <span className="icon-properties" />
                      <em>{el?.name}</em>
                    </a>
                    <ul className="list-unstyled mb-0  has-sub">
                      {(el?.capability_mappings?.map((el2) => el2?.value)?.flat())
                    && Array.isArray(el?.capability_mappings?.map((el2) => el2?.value)?.flat())
                    && el?.capability_mappings?.map((el2) => el2?.value)?.flat()?.length > 0
                    && el?.capability_mappings?.map((el2) => el2?.value?.map((el3) => ({ ...el3, key: el2?.key })))?.flat()?.map((el3) => (
                      <li className="open-tree">
                        <a>
                          <span className={el3?.key === 'vendor' ? 'icon-supplier' : el3?.key === 'customer' ? 'icon-consumer' : el3?.key === 'product' ? 'icon-toolbar' : el3?.key === 'process' ? 'icon-value-chain' : `icon-${el3?.key}`} />
                          <em>{el3?.name}</em>
                        </a>
                      </li>
                    ))}
                    </ul>
                  </li>
                </ul>

              ))
            )}
    </li>
  );
}

export default Tree;
