/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import {
  useGetIssueJsonQuery,
  useGetIssueRelatedObjectMutation,
} from '../../services/services';
import ReadMoreLessDocument from '../../components/common/ReadMoreLessDoc';
import ReadMoreLess from '../../components/common/ReadMoreLess';
import { addIssueVals, setIssueEditMode } from '../../services/issueSlice';
import InnerLoader from '../../components/InnerLoader';
import ReadOnlyCaps from '../../components/capabalities/ReadOnlyCaps';
import EpicIssueCapabilitiesReadOnly from './EpicIssueCapabilityReadOnly';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function IssueReview({ label }) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('issue');
  const formJson = useGetIssueJsonQuery({}, { refetchOnMountOrArgChange: true });
  const [reqRelatedObjects, resRelatedObjects] = useGetIssueRelatedObjectMutation();
  const issueValues = useSelector((state) => state.issueData.issueValues);
  const issueResPeople = useSelector((state) => state.issueData.issueResPeople);
  const issueCapabilitiesReadOnly = useSelector((state) => state.issueData.issueCapabilitiesReadOnly);

  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);

  console.log('issueValues', issueValues, docsInfo);

  const [jsonTemplate, setJsonTemplate] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState(null);
  console.log('jsonTemplate', jsonTemplate);
  const [loader, setLoader] = useState(true);

  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (
      formJson?.isSuccess
      && issueValues
      && formJson?.data?.fields
      && Array.isArray(formJson?.data?.fields)
      && formJson?.data?.fields?.length > 0
    ) {
      const updated = [...formJson.data.fields];
      Object.entries(issueValues).forEach((tv) => updated.forEach((dt, i) => {
        if (dt.field_name === tv[0]) {
          if (
            dt.field_name === 'priority'
              || dt.field_name === 'issue_type'
              || dt.field_name === 'status'
          ) {
            updated.splice(i, 1, {
              ...dt,
              value: {
                label: issueValues[dt.field_name],
                value: issueValues[dt.field_name],
              },
            });
          } else {
            updated.splice(i, 1, {
              ...dt,
              value: issueValues[dt.field_name],
            });
          }
        }
      }));
      const updatedSections = [];

      updated.forEach((el) => {
        if (!updatedSections.some((se) => se.id === el.section_title.id)) {
          updatedSections.push(el.section_title);
        }
      });
      setJsonTemplate({
        fields:
          updated && Array.isArray(updated) && updated.length > 1
            ? updated
            : [],
        sections: updatedSections,
      });
    }
  }, [formJson?.isSuccess]);

  useEffect(() => {
    reqRelatedObjects({
      id: location?.state?.id,
      type: location?.state?.type,
    });
  }, []);

  const relatedHeader = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'owners', name: 'Owner' },
    { type: '', field: 'managers', name: 'Manager' },
    { type: 'date', field: 'start_date', name: 'Start Date' },
    { type: 'date', field: 'end_date', name: 'End Date' },
    { type: '', field: 'status', name: 'Status' },
  ];
  const relatedHeaderEpic = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'owners', name: 'Owner' },
    { type: '', field: 'assignees', name: 'Assignee' },
    { type: 'date', field: 'start_date', name: 'Start Date' },
    { type: 'date', field: 'end_date', name: 'End Date' },
    { type: '', field: 'status', name: 'Status' },
  ];

  const handleEdit = (e) => {
    dispatch(setIssueEditMode(true));
    navigate('/create-issue', {
      state: {
        id: location?.state?.id,
        type: location?.state?.type,
        from: location?.pathname,
        parentState: location?.state,
      },
    });
  };

  useEffect(() => {
    if (
      issueValues
      && jsonTemplate?.fields
      && Array.isArray(jsonTemplate?.fields)
      && jsonTemplate?.fields?.length > 0
      && resRelatedObjects?.isSuccess
      && issueResPeople
      && Array.isArray(issueResPeople)
      && issueResPeople?.length > 0
    ) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [issueValues, jsonTemplate, resRelatedObjects, issueResPeople]);

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  return loader ? (
    <InnerLoader />
  ) : (
    <form action="" className="add-program-form">
      <div className="action_button_parent">
        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="action-button mb-2 mb-md-0 btn-primary"
            onClick={(e) => handleEdit(e)}
          >
            <div className="action-button-icon d-flex align-items-center justify-content-center">
              <span className="icon-edit" />
            </div>
            <span className="action_text">Edit</span>
          </button>
        </div>
      </div>
      <div className="form-unit">
        <div className="container-fluid">
          <h2 className="h1">Details</h2>
          {jsonTemplate?.fields
            && Array.isArray(jsonTemplate?.fields)
            && jsonTemplate?.fields?.length > 0
            && jsonTemplate?.sections?.map((tl, i) => (
              <Fragment key={tl?.id}>
                <h2 className="section-title">
                  {t(`section_title.${tl?.name}`)}
                </h2>
                <div className="row">
                  {jsonTemplate?.fields?.map(
                    (fl) => tl?.id === fl?.section_title.id && (
                    <Fragment key={fl?.id}>
                      {jsonTemplate?.fields?.some(
                        (el) => el?.id === fl?.id
                              && el?.field_name === 'organizations',
                      ) && <div className="w-100" />}
                      {jsonTemplate?.fields?.some(
                        (el) => el?.id === fl?.id
                              && el?.field_name === 'consequences_factor',
                      ) && <div className="w-100" />}

                      <div key={fl.id} className="col-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="program_subj">
                            {t(t(`${fl.field_name}`).name)}
                          </label>
                          <p className="readonly-text font-weight-bold">
                            {((fl.field_data_type === 'text'
                                  && fl?.section_title?.name !== 'budget')
                                  || (fl.field_data_type === 'readonly'
                                    && fl?.section_title?.name !== 'budget')
                                  || (fl.field_data_type === 'readOnly'
                                    && fl?.section_title?.name !== 'budget'))
                                && fl.value ? (
                                fl.value
                              ) : fl.field_data_type === 'date'
                                  && fl?.value ? (
                                  dayjs(fl.value).format(
                                    userData?.tenant_info?.date_format,
                                  )
                                ) : fl.field_data_type === 'select'
                                  && fl.field_type !== 'Custom'
                                  && fl.value?.label ? (
                                    fl.value?.label
                                  ) : fl.field_data_type === 'file'
                                  && fl.value !== null
                                  && Array.isArray(fl.value) ? (
                                    <ReadMoreLessDocument
                                      field={fl}
                                      doc={fl?.value}
                                    />
                                    ) : fl.field_data_type === 'textarea'
                                  && fl?.value ? (
                                    <ReadMoreLess text={fl?.value} />
                                      ) : fl.field_data_type === 'texteditor'
                                        ? (
                                          <Editor
                                            editorState={fl?.value ? EditorState.createWithContent(
                                              ContentState.createFromBlockArray(
                                                htmlToDraft(fl?.value),
                                              ),
                                            ) : EditorState.createEmpty()}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            toolbarHidden
                                          />
                                        ) : fl?.field_data_type === 'text'
                                  && fl?.section_title?.name === 'budget' ? (
                                            fl?.value ? (
                                              `${userData?.tenant_info?.currency_code} ${fl.value}`
                                            ) : (
                                              '0'
                                            )
                                          ) : (fl.field_data_type === 'readonly'
                                    && fl?.section_title?.name === 'budget')
                                  || (fl.field_data_type === 'readOnly'
                                    && fl?.section_title?.name === 'budget') ? (
                                              fl.value ? (
                                                `${userData?.tenant_info?.currency_code} ${fl.value}`
                                              ) : (
                                                '0'
                                              )
                                            ) : fl.field_data_type === 'select'
                                  && fl.field_type === 'Custom' ? (
                                                fl?.value
                                              ) : fl.field_data_type === 'textarea'
                                  && fl.field_type === 'Custom' ? (
                                    <ReadMoreLess text={fl?.value} />
                                                ) : fl?.field_name === 'defect_status'
                                  && epicValues?.status === 'Test'
                                  && epicValues.defect_status ? (
                                                    fl?.value
                                                  ) : (
                                                    '-'
                                                  )}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                    ),
                  )}
                </div>
                <hr />
              </Fragment>
            ))}

          {resRelatedObjects?.data?.related_programs
            && Array.isArray(resRelatedObjects?.data?.related_programs)
            && resRelatedObjects?.data?.related_programs?.length > 0 && (
              <>
                <h2 className="section-title">Related Program</h2>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {relatedHeader
                          && relatedHeader?.length > 0
                          && Array.isArray(relatedHeader)
                          && relatedHeader?.map((ele) => (
                            <th key={ele?.name}>{ele.name}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {resRelatedObjects?.data?.related_programs
                      && Array.isArray(
                        resRelatedObjects?.data?.related_programs,
                      )
                      && resRelatedObjects?.data?.related_programs?.length
                        > 0 ? (
                          resRelatedObjects?.data?.related_programs?.map(
                            (td) => (
                              <tr key={td?.id}>
                                {relatedHeader
                                && Array.isArray(relatedHeader)
                                && relatedHeader?.length > 0
                                && relatedHeader?.map((th) => (th.field === 'owner'
                                  || th.field === 'owners' ? (
                                    <td
                                      className="text-capitalize"
                                      key={th?.field}
                                    >
                                      {td[th.field]
                                      && td[th.field]?.length > 0
                                      && Array.isArray(td[th.field])
                                        ? td[th.field]?.map(
                                          (ow) => `${ow?.first_name || ''}  ${
                                            ow?.last_name || ''
                                          }`,
                                        )
                                        : '-'}
                                    </td>
                                  ) : th.field === 'manager'
                                    || th.field === 'managers' ? (
                                      <td
                                        className="text-capitalize"
                                        key={th?.field}
                                      >
                                        {td[th.field]
                                      && td[th.field]?.length > 0
                                      && Array.isArray(td[th.field])
                                          ? td[th.field]?.map(
                                            (mn) => `${mn?.first_name || ''}  ${
                                              mn?.last_name || ''
                                            }`,
                                          )
                                          : '-'}
                                      </td>
                                    ) : th.type === 'date' ? (
                                      td[th.field] && (
                                      <td key={th?.field}>
                                        {dayjs(td[th.field]).format(
                                          userData?.tenant_info?.date_format,
                                        ) || '-'}
                                      </td>
                                      )
                                    ) : th.field === 'name' && td[th.field] ? (
                                      <td key={th?.field}>
                                        <Button
                                          href={`/review/program/${td?.id}`}
                                          target="_blank"
                                          variant="link"
                                          style={{ textDecoration: 'none' }}
                                        >
                                          {td[th.field]}
                                        </Button>
                                      </td>
                                    ) : (
                                      td[th.field] && (
                                      <td key={th?.field}>
                                        {td[th.field] || '-'}
                                      </td>
                                      )
                                    )))}
                              </tr>
                            ),
                          )
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data To Display
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
          )}
          {resRelatedObjects?.data?.related_initiatives
            && Array.isArray(resRelatedObjects?.data?.related_initiatives)
            && resRelatedObjects?.data?.related_initiatives?.length > 0 && (
              <>
                <h2 className="section-title">Related Project</h2>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {relatedHeader
                          && relatedHeader?.length > 0
                          && Array.isArray(relatedHeader)
                          && relatedHeader?.map((ele) => (
                            <th key={ele?.name}>{ele.name}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {resRelatedObjects?.data?.related_initiatives
                      && Array.isArray(
                        resRelatedObjects?.data?.related_initiatives,
                      )
                      && resRelatedObjects?.data?.related_initiatives?.length
                        > 0 ? (
                          resRelatedObjects?.data?.related_initiatives?.map(
                            (td) => (
                              <tr key={td?.id}>
                                {relatedHeader
                                && Array.isArray(relatedHeader)
                                && relatedHeader?.length > 0
                                && relatedHeader?.map((th) => (th.field === 'owner'
                                  || th.field === 'owners' ? (
                                    <td
                                      className="text-capitalize"
                                      key={th?.field}
                                    >
                                      {td[th.field]
                                      && td[th.field]?.length > 0
                                      && Array.isArray(td[th.field])
                                        ? td[th.field]?.map(
                                          (ow) => `${ow?.first_name || ''}  ${
                                            ow?.last_name || ''
                                          }`,
                                        )
                                        : '-'}
                                    </td>
                                  ) : th.field === 'manager'
                                    || th.field === 'managers' ? (
                                      <td
                                        className="text-capitalize"
                                        key={th?.field}
                                      >
                                        {td[th.field]
                                      && td[th.field]?.length > 0
                                      && Array.isArray(td[th.field])
                                          ? td[th.field]?.map(
                                            (mn) => `${mn?.first_name || ''}  ${
                                              mn?.last_name || ''
                                            }`,
                                          )
                                          : '-'}
                                      </td>
                                    ) : th.type === 'date' ? (
                                      td[th.field] && (
                                      <td key={th?.field}>
                                        {dayjs(td[th.field]).format(
                                          userData?.tenant_info?.date_format,
                                        ) || '-'}
                                      </td>
                                      )
                                    ) : th.field === 'name' && td[th.field] ? (
                                      <td key={th?.field}>
                                        <Button
                                          href={`/review/initiative/${td?.id}`}
                                          target="_blank"
                                          variant="link"
                                          style={{ textDecoration: 'none' }}
                                        >
                                          {td[th.field]}
                                        </Button>
                                      </td>
                                    ) : (
                                      td[th.field] && (
                                      <td key={th?.field}>
                                        {td[th.field] || '-'}
                                      </td>
                                      )
                                    )))}
                              </tr>
                            ),
                          )
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data To Display
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
          )}
          {resRelatedObjects?.data?.related_changes
            && Array.isArray(resRelatedObjects?.data?.related_changes)
            && resRelatedObjects?.data?.related_changes?.length > 0 && (
              <>
                <h2>Related Change</h2>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {relatedHeader
                          && relatedHeader?.length > 0
                          && Array.isArray(relatedHeader)
                          && relatedHeader?.map((ele) => (
                            <th key={ele?.name}>{ele.name}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {resRelatedObjects?.data?.related_changes
                      && Array.isArray(resRelatedObjects?.data?.related_changes)
                      && resRelatedObjects?.data?.related_changes?.length > 0 ? (
                          resRelatedObjects?.data?.related_changes?.map((td) => (
                            <tr key={td?.id}>
                              {relatedHeader
                              && relatedHeader?.length > 0
                              && Array.isArray(relatedHeader)
                              && relatedHeader?.map((th) => (th.field === 'owner'
                                || th.field === 'owners' ? (
                                  <td
                                    className="text-capitalize"
                                    key={th?.field}
                                  >
                                    {td[th.field]
                                    && td[th.field]?.length > 0
                                    && Array.isArray(td[th.field])
                                      ? td[th.field]?.map(
                                        (ow) => `${ow?.first_name || ''}  ${
                                          ow?.last_name || ''
                                        }`,
                                      )
                                      : '-'}
                                  </td>
                                ) : th.field === 'manager'
                                  || th.field === 'managers' ? (
                                    <td
                                      className="text-capitalize"
                                      key={th?.field}
                                    >
                                      {td[th.field]
                                    && td[th.field]?.length > 0
                                    && Array.isArray(td[th.field])
                                        ? td[th.field]?.map(
                                          (mn) => `${mn?.first_name || ''}  ${
                                            mn?.last_name || ''
                                          }`,
                                        )
                                        : '-'}
                                    </td>
                                  ) : th.type === 'date' ? (
                                    td[th.field] && (
                                    <td key={th?.field}>
                                      {dayjs(td[th.field]).format(
                                        userData?.tenant_info?.date_format,
                                      ) || '-'}
                                    </td>
                                    )
                                  ) : th.field === 'name' && td[th.field] ? (
                                    <td key={th?.field}>
                                      <Button
                                        href={`/review/change/${td?.id}`}
                                        target="_blank"
                                        variant="link"
                                        style={{ textDecoration: 'none' }}
                                      >
                                        {td[th.field]}
                                      </Button>
                                    </td>
                                  ) : (
                                    td[th.field] && (
                                    <td key={th?.field}>
                                      {td[th.field] || '-'}
                                    </td>
                                    )
                                  )))}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              No Data To Display
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
          )}

          {resRelatedObjects?.data?.related_epics
            && Array.isArray(resRelatedObjects?.data?.related_epics)
            && resRelatedObjects?.data?.related_epics?.length > 0 && (
              <>
                <h2>Related Work Package</h2>
                <div className="table-responsive box-shadow mb-4">
                  <table className="table table-hover">
                    <thead>
                      <tr className="heading-row">
                        {relatedHeaderEpic
                          && relatedHeaderEpic?.length > 0
                          && Array.isArray(relatedHeaderEpic)
                          && relatedHeaderEpic?.map((ele) => (
                            <th key={ele?.name}>{ele.name}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {resRelatedObjects?.data?.related_epics
                      && Array.isArray(resRelatedObjects?.data?.related_epics)
                      && resRelatedObjects?.data?.related_epics?.length > 0 ? (
                          resRelatedObjects?.data?.related_epics?.map((td) => (
                            <tr key={td?.id}>
                              {relatedHeaderEpic
                              && relatedHeaderEpic?.length > 0
                              && Array.isArray(relatedHeaderEpic)
                              && relatedHeaderEpic?.map((th) => (th.field === 'owner'
                                || th.field === 'owners' ? (
                                  <td
                                    className="text-capitalize"
                                    key={th?.field}
                                  >
                                    {td[th.field]
                                    && td[th.field]?.length > 0
                                    && Array.isArray(td[th.field])
                                      ? td[th.field]?.map(
                                        (ow) => `${ow?.first_name || ''}  ${
                                          ow?.last_name || ''
                                        }`,
                                      )
                                      : '-'}
                                  </td>
                                ) : th.field === 'manager'
                                  || th.field === 'managers'
                                  || th.field === 'assignees' ? (
                                    <td
                                      className="text-capitalize"
                                      key={th?.field}
                                    >
                                      {td[th.field]
                                    && td[th.field]?.length > 0
                                    && Array.isArray(td[th.field])
                                        ? td[th.field]?.map(
                                          (mn) => `${mn?.first_name || ''}  ${
                                            mn?.last_name || ''
                                          }`,
                                        )
                                        : '-'}
                                    </td>
                                  ) : th.type === 'date' ? (
                                    td[th.field] && (
                                    <td key={th?.field}>
                                      {dayjs(td[th.field]).format(
                                        userData?.tenant_info?.date_format,
                                      ) || '-'}
                                    </td>
                                    )
                                  ) : th.field === 'name' && td[th.field] ? (
                                    <td key={th?.field}>
                                      <Button
                                        href={`/review/change/${td?.id}`}
                                        target="_blank"
                                        variant="link"
                                        style={{ textDecoration: 'none' }}
                                      >
                                        {td[th.field]}
                                      </Button>
                                    </td>
                                  ) : (
                                    td[th.field] && (
                                    <td key={th?.field}>
                                      {td[th.field] || '-'}
                                    </td>
                                    )
                                  )))}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              No Data To Display
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <hr />
              </>
          )}

          <h2 className="h1">People</h2>
          <div className="row">
            <div className="col-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="program_subj">Responsible</label>
                {issueResPeople
                && Array.isArray(issueResPeople)
                && issueResPeople?.length > 0 ? (
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {issueResPeople?.map((ow) => (
                      <span key={ow?.id}>
                        {`${ow?.first_name || ''} ${
                          ow?.last_name || ''
                        }`}

                      </span>
                    ))}
                  </p>
                  ) : (
                    '-'
                  )}
              </div>
            </div>
          </div>
          <hr />

          {(location?.state?.type === 'Change')
            && (
            <>
              <h2 className="h1">Capabilities</h2>
              <div id="expand-demo">
                <div id="wrpp" className="capability-cards  change-caps">
                  <div id="fsm_container" className="fsm-container">
                    {(issueCapabilitiesReadOnly && Array.isArray(issueCapabilitiesReadOnly) && issueCapabilitiesReadOnly?.length > 0)
                      ? (
                        <>
                          {issueCapabilitiesReadOnly?.map((cc) => (
                            <div className="fsm" key={cc?.title}>
                              <div className="card">
                                <h3 className="card-header">
                                  <span className={cc.icon} />
                                  {cc.title}
                                  {' '}
                                  {`(${cc?.value?.length})`}
                                </h3>
                                {(cc?.value && Array.isArray(cc?.value) && cc?.value.length > 0)
                                  ? (
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>{`${cc?.title}  Name`}</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(cc?.value && Array.isArray(cc?.value) && cc?.value.length > 0) && cc?.value?.slice(0, 5)?.map((ccVal) => (
                                            <tr key={ccVal?.name}>
                                              <td className="sm-col">
                                                <span className={cc.icon} />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ccVal?.object_name)}>
                                                  <Button
                                                    className="text-decoration-none cap-link"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/${cc?.key}/${ccVal?.id}`}
                                                  >
                                                    {ccVal?.object_name?.length >= 75 ? `${ccVal?.object_name?.substring(0, 75)}...` : ccVal?.object_name}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{ccVal?.delivery_date ? dayjs(ccVal?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {cc?.value?.length > 5
                                                                && (
                                                                  <div className="more-link d-flex justify-content-end">
                                                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, cc?.key)}>more</button>
                                                                  </div>
                                                                )}
                                    </div>
                                  )
                                  : (
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                          <div id="fsm_actual">
                            {expanded
                                          && (
                                            <ReadOnlyCaps
                                              appCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()}
                                              processCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()}
                                              businessCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()}
                                              productCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()}
                                              vendorCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()}
                                              customerCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()}
                                              setExpanded={setExpanded}
                                              size={size}
                                              position={position}
                                              capsInfo={capsInfo}
                                            />
                                          )}
                          </div>
                        </>
                      )
                      : ''}
                  </div>
                </div>
              </div>
              <hr />
            </>
            )}

          {location?.state?.type === 'Epic'
            && <EpicIssueCapabilitiesReadOnly />}

          <div className="grey-bg">
            <h2>Related Dates and People</h2>
            <div className="row">
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created</label>
                  <p className="readonly-text font-weight-bold">
                    {issueValues?.created
                      ? dayjs(issueValues?.created).format(
                        userData?.tenant_info?.date_format,
                      )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated</label>
                  <p className="readonly-text font-weight-bold">
                    {issueValues?.updated
                      ? dayjs(issueValues?.updated).format(
                        userData?.tenant_info?.date_format,
                      )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="w-100" />
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {issueValues?.created_by
                      ? `${issueValues?.created_by?.first_name || ''} ${
                        issueValues?.created_by?.last_name || ''
                      }`
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {issueValues?.updated_by
                      ? `${issueValues?.updated_by?.first_name || ''} ${
                        issueValues?.updated_by?.last_name || ''
                      }`
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default IssueReview;
