/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerValues: {},
  customerTwinVals: {},
  capabilitiesData: [],
  pullProcessCustomerCaps: [],
  customerStepCapabilitiesData: [],
  customerStepReviewCaps: [],
  processCustomerReviewCaps: [],
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    addCustomerVals: (state, { payload }) => {
      state.customerValues = payload;
    },
    addCustomerTwinVals: (state, { payload }) => {
      state.customerTwinVals = payload;
    },
    addCustomerCaps: (state, { payload }) => {
      const uniqueAppCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.capabilitiesData = uniqueAppCaps;
    },
    addCustomerStepCapabilities: (state, { payload }) => {
      state.customerStepCapabilitiesData = payload;
    },
    addPullProcessCustomerCaps: (state, { payload }) => {
      const uniqueProcessCustomerCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcessCustomerCaps = uniqueProcessCustomerCaps;
    },
    addCustomerStepReviewCaps: (state, { payload }) => {
      state.customerStepReviewCaps = payload;
    },
    addProcessCustomerReviewCaps: (state, { payload }) => {
      const uniqueProcessRWCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processCustomerReviewCaps = uniqueProcessRWCaps;
    },
    removePullProcessCustomerCaps: (state, { payload }) => {
      state.pullProcessCustomerCaps = payload && payload.length > 0 && Array.isArray(payload) ? payload : [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addCustomerVals,
  addCustomerTwinVals,
  addCustomerCaps,
  addCustomerStepCapabilities,
  addPullProcessCustomerCaps,
  addCustomerStepReviewCaps,
  addProcessCustomerReviewCaps,
  removePullProcessCustomerCaps,
} = customerSlice.actions;

export default customerSlice.reducer;
