/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import {
  useGetAllProgsQuery,
  useSaveDocsMutation,
  useSubmitFormMutation,
  useGetIssueJsonQuery,
  useGetIssueRelatedObjectMutation,
  useSubmitIssueMutation,
} from '../../../services/services';
import AlertMessage from '../../../SuperComponents/AlertMessage';
import LoaderComp from '../../../components/LoderQ';
import ErrorModal from '../../../components/common/ErrorModal';
import IssueFromBuilder from '../IssueFromBuilder';
import { addDocsInfo, clearDocsInfo } from '../../../services/docInfoSlice';
import { addIssueResPeople } from '../../../services/issueSlice';
import { addIsDirty } from '../../../services/navSlice';

function CreatesIssue() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('issue');
  const location = useLocation();
  console.log('issue location', location);

  // Queries & Mutation
  const [saveDocs, docRes] = useSaveDocsMutation();
  const [submitForm, res] = useSubmitIssueMutation();
  const { isSuccess, isLoading, isError } = res;
  const formJson = useGetIssueJsonQuery();
  const [reqRelatedObjects, resRelatedObjects] = useGetIssueRelatedObjectMutation();
  console.log('resRelatedObjects', resRelatedObjects);
  // Redux State
  const issueEditMode = useSelector((state) => state.issueData.issueEditMode);
  const issueValues = useSelector((state) => state.issueData.issueValues);
  const issueResPeople = useSelector((state) => state.issueData.issueResPeople);
  const issueCapabilitiesReadOnly = useSelector((state) => state.issueData.issueCapabilitiesReadOnly);
  const epicIssueCapsMapping = useSelector((state) => state.issueData.epicIssueCapsMapping);

  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  console.log('docsInfo', docsInfo, docsInfo.filter((
    item,
    index,
  ) => docsInfo.indexOf(item) === index));
  console.log('issueValues', issueValues);

  const [jsonTemplate, setJsonTemplate] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const cancelTitle = 'Issue creation';

  console.log('jsonTemplate', jsonTemplate);
  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (formJson?.isSuccess) {
      if (issueValues?.status && issueValues?.status !== 'New') {
        const data = (Array.isArray(formJson?.data?.fields) && formJson?.data?.fields?.length > 0) && formJson?.data?.fields?.map((rs) => ((rs.field_name === 'status') ? {
          ...rs,
          field_value_option:
           {
             ...rs.field_value_option,
             options: rs?.field_value_option?.options?.filter((up) => (
               up?.name === 'Open'
            || up?.name === 'Impact Analyzed'
            || up?.name === 'Correction Action created'
            || up?.name === 'Correction Action approved'
            || up?.name === 'Rejected'
            || up?.name === 'Closed')),
           },
        } : { ...rs }));
        setJsonTemplate({ fields: data, sections: formJson?.data?.sections });
      } else {
        setJsonTemplate(formJson.data);
      }
    }
  }, [formJson?.isSuccess, issueValues]);

  useEffect(() => {
    reqRelatedObjects({
      id: location?.state?.id,
      type: location?.state?.type,
    });
  }, []);

  // Setting Docments on edit
  useEffect(() => {
    if (issueEditMode) {
      if (issueValues?.documents && Array.isArray(issueValues?.documents) && issueValues?.documents?.length > 0) {
        dispatch(addDocsInfo(issueValues?.documents));
      }
    }
  }, [issueEditMode]);
  // Save Doc Res to State
  useEffect(() => {
    if (
      docRes.isSuccess
      && docRes.data.docData
      && docRes.data.docData?.length > 0
      && Array.isArray(docRes.data.docData)
    ) {
      const docsData = docRes?.data?.docData?.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addDocsInfo(docsData));
    }
  }, [docRes.isSuccess]);

  // Reset All steps
  const stepReset = () => {
    dispatch({ type: 'RESET_APP' });
    navigate('/dashboard', {
      state: {
        selected: 'Programs',
      },
    });
  };

  // Function to set State while clicking on Next
  const onSubmit = (values) => {
    console.log('values', values);
    const finalData = {
      ...values,
      status: values?.status === 'New' ? 'Open' : values?.status,
      object_type: location?.state?.type,
      object_id: location?.state?.id,
      created: issueEditMode ? issueValues?.created : dayjs().format('YYYY-MM-DD hh:mm:ss'),
      created_by: {
        id: userData?.id,
      },
      updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      updated_by: {
        id: userData?.id,
      },
      deleted: false,
      documents: docsInfo?.filter((
        item,
        index,
      ) => docsInfo.indexOf(item) === index) || null,
      responsible:
          issueResPeople
          && issueResPeople?.length > 0
          && Array.isArray(issueResPeople)
            ? issueResPeople?.map((el) => ({
              id: el.id,
              email: el.email,
              first_name: el?.first_name,
              last_name: el?.last_name,
              designation_id: {
                id: el?.designation_id?.id,
                name: el?.designation_id?.name,
              },
              department_id: {
                id: el?.department_id?.id,
                name: el?.department_id?.name,
              },
            }))
            : [],
      related_programs: (
        resRelatedObjects
              && Array.isArray(resRelatedObjects?.data?.related_programs)
              && resRelatedObjects?.data?.related_programs?.length > 0
      ) ? resRelatedObjects?.data?.related_programs?.map((d) => ({
          id: d.id,
          unique_id: d.unique_id,
          name: d.name,
          priority: d.priority,
          status: d.status,
          start_date: d.start_date,
          end_date: d.end_date,
          updated: d.updated,
          owners: d.owners,
          managers: d.managers,
        })) : null,
      related_initiatives: (
        resRelatedObjects
        && Array.isArray(resRelatedObjects?.data?.related_initiatives)
        && resRelatedObjects?.data?.related_initiatives?.length > 0
      ) ? resRelatedObjects?.data?.related_initiatives?.map((d) => ({
          id: d.id,
          unique_id: d.unique_id,
          related_programs: d?.related_programs ? d?.related_programs : null,
          name: d.name,
          status: d.status,
          start_date: d.start_date,
          end_date: d.end_date,
          owners: d.owners,
          managers: d.managers,
        })) : null,
      related_changes: (resRelatedObjects && Array.isArray(resRelatedObjects?.data?.related_changes) && resRelatedObjects?.data?.related_changes?.length > 0)
        ? resRelatedObjects?.data?.related_changes?.map((d) => ({
          id: d?.id,
          unique_id: d?.unique_id,
          name: d?.name,
          status: d?.status,
          start_date: d?.start_date,
          end_date: d?.end_date,
          owners: d.owners,
          managers: d.managers,
        })) : null,
      related_epics: (resRelatedObjects && Array.isArray(resRelatedObjects?.data?.related_epics) && resRelatedObjects?.data?.related_epics?.length > 0)
        ? resRelatedObjects?.data?.related_epics?.map((d) => ({
          id: d?.id,
          unique_id: d?.unique_id,
          name: d?.name,
          status: d?.status,
          start_date: d?.start_date,
          end_date: d?.end_date,
          owners: d.owners,
          managers: d.managers,
        })) : null,
      mode: 'Submitted',
      dependencies: (epicIssueCapsMapping && Array.isArray(epicIssueCapsMapping) && epicIssueCapsMapping.length > 0) ? epicIssueCapsMapping?.filter((ele) => ele?.value?.length !== 0) : (issueCapabilitiesReadOnly && Array.isArray(issueCapabilitiesReadOnly) && issueCapabilitiesReadOnly?.length) ? issueCapabilitiesReadOnly?.map((el) => ({ key: el.key, value: el.value }))?.filter((ele) => ele?.value?.length !== 0) : null,
    };
    if (location?.state?.type === 'Change' || location?.state?.type === 'Epic') {
      delete finalData.related_initiatives;
      delete finalData?.related_programs;
    }
    if (location?.state?.type === 'Project') {
      delete finalData.dependencies;
      delete finalData?.related_programs;
    }
    if (location?.state?.type === 'Program') {
      delete finalData.dependencies;
    }
    submitForm(finalData);
    console.log('finalData', finalData);
    // handleNext();
  };

  useEffect(() => {
    if (!issueEditMode) {
      dispatch(addIssueResPeople([]));
      dispatch(addDocsInfo([]));
    }
  }, [issueEditMode]);

  // Remove Success Message
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    }
  }, [successMessage]);

  // After Submit Redirect & Success Message
  useEffect(() => {
    if (res?.isSuccess) {
      setSuccessMessage(
        `Issue ${issueEditMode ? 'Saved' : 'Submitted'} Successfully`,
      );
      dispatch(addIsDirty(false));
      setTimeout(() => {
        if (location?.state?.from === '/readReview' || location?.state?.from === '/readModeChange') {
          navigate(location?.state?.from, { state: location?.state?.state });
        } else if (location?.state?.from === '/issue-read-review') {
          navigate(location?.state?.from, { state: { ...location?.state?.parentState, state: location?.state?.parentState?.state } });
        }
        dispatch(clearDocsInfo([]));
      }, 2000);
    }
  }, [res, location]);

  const handleBack = (flag) => {
    if (flag === 1) {
      navigate(location?.state?.from, { state: location?.state?.state });
      dispatch(clearDocsInfo([]));
    } else if (flag === 2) {
      navigate(location?.state?.from, { state: { ...location?.state?.parentState, state: location?.state?.parentState?.state } });
      dispatch(clearDocsInfo([]));
    }
  };

  // Return Starts
  return (
    <div className="content-wrapper">
      {isError && <ErrorModal />}
      {isLoading && <LoaderComp />}
      {successMessage && (
        <AlertMessage message={successMessage} setter={setSuccessMessage} />
      )}
      <div className="card">
        <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>
            {!issueEditMode
              ? t('issue:create_new_issue')
              : `Issue: ${issueValues?.name}`}
          </h1>
          {(location?.state?.from === '/readReview' || location?.state?.from === '/readModeChange')
            && <Button className="text-decoration-none" type="button" onClick={() => handleBack(1)}>Back</Button>}
          {(location?.state?.from === '/issue-read-review')
            && <Button className="text-decoration-none" type="button" onClick={() => handleBack(2)}>Back</Button>}
        </div>

        <div className="card-body">
          <div className="form-wrapper">
            <IssueFromBuilder
              label="issue"
              template={jsonTemplate}
              onNext={onSubmit}
              modalShow={modalShow}
              setModalShow={setModalShow}
              stepReset={stepReset}
              dfVals={issueValues || null}
              editMode={issueEditMode}
              cancelTitle={cancelTitle}
              resRelatedObjects={resRelatedObjects}
              issueResPeople={issueResPeople}
              saveDocs={saveDocs}
              docsInfo={docsInfo}
              issueEditMode={issueEditMode}
              isChange={location?.state?.type === 'Change'}
              isEpic={location?.state?.type === 'Epic'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const CreateIssue = React.memo(CreatesIssue);

export default CreateIssue;
