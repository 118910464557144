/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ReadOnlyCaps from '../../components/capabalities/ReadOnlyCaps';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);
function EpicIssueCapabilitiesReadOnly() {
  const issueCapabilitiesReadOnly = useSelector((state) => state.issueData.issueCapabilitiesReadOnly);
  console.log('issueCapabilitiesReadOnly', issueCapabilitiesReadOnly);
  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState(null);
  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };
  return (
    <>
      {/* <h2>Capabilities</h2> */}
      <h2 className="h1">Capabilities</h2>
      <div id="expand-demo">
        <div id="wrpp" className="capability-cards  change-caps">
          <div id="fsm_container" className="fsm-container">
            {(issueCapabilitiesReadOnly && Array.isArray(issueCapabilitiesReadOnly) && issueCapabilitiesReadOnly?.length > 0)
              ? (
                <>
                  {issueCapabilitiesReadOnly?.map((cc) => (
                    <div className="fsm" key={cc?.title}>
                      <div className="card">
                        <h3 className="card-header">
                          <span className={cc.icon} />
                          {cc.title}
                          {' '}
                          {`(${cc?.value?.length})`}
                        </h3>
                        {(cc?.value && Array.isArray(cc?.value) && cc?.value.length > 0)
                          ? (
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>{`${cc?.title}  Name`}</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(cc?.value && Array.isArray(cc?.value) && cc?.value.length > 0) && cc?.value?.slice(0, 5)?.map((ccVal) => (
                                    <tr key={ccVal?.digital_twin_data?.name}>
                                      <td className="sm-col">
                                        <span className={cc.icon} />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ccVal?.name)}>
                                          <Button
                                            className="text-decoration-none cap-link"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/${cc?.key}/${ccVal?.digital_twin_data?.id}`}
                                          >
                                            {ccVal?.digital_twin_data?.name?.length >= 75 ? `${ccVal?.digital_twin_data?.name?.substring(0, 75)}...` : ccVal?.digital_twin_data?.name}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ccVal?.digital_twin_data?.delivery_date ? dayjs(ccVal?.digital_twin_data?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {cc?.value?.length > 5
                                   && (
                                     <div className="more-link d-flex justify-content-end">
                                       <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, cc?.key)}>more</button>
                                     </div>
                                   )}
                            </div>
                          )
                          : (
                            <div className="card-body empty">
                              <h1>No Data To Display</h1>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                  <div id="fsm_actual">
                    {expanded
             && (
               <ReadOnlyCaps
                 appCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()}
                 processCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()}
                 businessCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()}
                 productCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()}
                 vendorCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()}
                 customerCapabilitiesData={issueCapabilitiesReadOnly?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()}
                 setExpanded={setExpanded}
                 size={size}
                 position={position}
                 capsInfo={capsInfo}
               />
             )}
                  </div>
                </>
              )
              : ''}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default EpicIssueCapabilitiesReadOnly;
