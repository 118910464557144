/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PaginationComponenet from './Pagination';
import useSearchFilter from '../../hooks/useSearchFilter';

export default function TablePeople({
  ownerData,
  subjMtExpData,
  assigneeData,
  resPersonData,
  sponsorData,
  managerData,
  memberData,
  strComData,
  teamMemberData,
  collaboratorData,
  checkHandler,
  userData,
  getCheckedVal,
  selVal,
  getIcon,
  tableMessage,
  disabled,
  setSelVal,
  label,
  selectedApp,
}) {
  console.log('tm +++', teamMemberData, 'st ++', strComData);

  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [TBLData, setTBLData] = useState(userData || []);
  const [currentPage, setCurrentPage] = useState(1);
  const peopleDisabled = useSelector((state) => state.peopleData.peopleDisabled);

  const tblHeader = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'email', name: 'Email' },
    { type: '', field: 'created', name: 'Created' },
    { type: '', field: 'is_active', name: 'Active' }];

  useEffect(() => {
    if (userData && Array.isArray(userData) && userData?.length > 0) {
      setTotalCount(userData.length);
    } else {
      setTotalCount(0);
    }
  }, [userData]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (userData && Array.isArray(userData) && userData?.length > 10) {
      const reversedArray = [];
      userData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (userData && Array.isArray(userData) && userData?.length > 0) {
      const reversedArray = [];
      userData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [userData, currentPage]);

  const checkHandler1 = (e, d) => {
    checkHandler(e, d);
  };

  const checkbox = selVal?.val === 'stcom'
    || selVal?.val === 'teamMember'
    || selVal?.val === 'collab';

  const draftMode = useSelector((state) => state.formJson.draftMode);
  const editMode = useSelector((state) => state.editData.editMode);

  // useEffect(() => {
  //   if (editMode && !draftMode) {
  //     setSelVal({
  //       flag: true,
  //       val: '',
  //     });
  //   }
  // }, [editMode, draftMode]);

  const { filter, setFilter, filterArray } = useSearchFilter(userData || []);

  useEffect(() => {
    if (userData && Array.isArray(userData) && userData?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [userData, filter, filterArray]);

  return (
    <div className="table-wrapper mt-0">
      <div className="container-fluid p-0">
        <div className="table-responsive box-shadow mb-0">
          {!disabled ? (
            ''
          ) : (
            <div className={`table-disabled ${(label === 'requirement' && selVal.flag && selectedApp) ? '' : selVal.flag ? '' : 'show'} `}>
              <p>{tableMessage}</p>
            </div>
          )}
          <table className="table table-hover ">
            <thead>
              <tr className="heading-row">
                <th className="check-field">
                  {checkbox && (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectRow1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectRow1"
                        aria-label="select"
                      />
                    </div>
                  )}
                </th>
                {(tblHeader && tblHeader?.length > 0 && Array.isArray(tblHeader))
                        && tblHeader?.map((ele) => (
                          <th key={ele?.name}>{ele.name}</th>
                        ))}
              </tr>
              <tr className="filter-row">
                <th>&nbsp;</th>
                {
                       (tblHeader && tblHeader?.length > 0 && Array.isArray(tblHeader))
                        && tblHeader?.map((ele) => (
                          <th key={ele?.field}>
                            {ele?.type === '' && (
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search"
                                name={ele?.field}
                                disabled={ele?.field !== 'name'}
                                onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                              />
                            )}
                          </th>
                        ))
                }
              </tr>
            </thead>
            <tbody>
              {(TBLData
               && Array.isArray(TBLData)
                && TBLData?.length > 0)
                ? TBLData?.map((d) => (
                  <tr key={d.id}>
                    <td className="check-field">
                      {checkbox ? (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={getCheckedVal(d.id)}
                            onChange={(e) => checkHandler1(e, d)}
                            value={d.name}
                            id={d.id}
                            disabled={peopleDisabled && ((selVal?.val === 'teamMember' && teamMemberData?.some((tm) => !tm?.isNew && tm.id === d.id)) || (selVal?.val === 'stcom' && strComData?.some((tm) => !tm?.isNew && tm.id === d.id)))}
                          />
                          <label
                            data-test-id="select-people-test-checkBox"
                            className="custom-control-label"
                            htmlFor={d.id}
                            aria-label="select"
                          />
                        </div>
                      ) : (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            checked={getCheckedVal(d.id)}
                            onChange={(e) => checkHandler1(e, d)}
                            value={d.name}
                            id={d.id}
                          />
                          <label
                            data-test-id="select-people-test-radio"
                            className="custom-control-label"
                            htmlFor={d.id}
                            aria-label="select"
                          />
                        </div>
                      )}
                    </td>
                    <td className="text-capitalize">
                      {`${d.first_name || ''}   ${d.last_name || ''} `}
                      {(Array.isArray(ownerData) && ownerData.length > 0)
                        && ownerData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">OW</span>
                      )}
                      {(Array.isArray(subjMtExpData) && subjMtExpData.length > 0)
                        && subjMtExpData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">SM</span>
                      )}
                      {(Array.isArray(assigneeData) && assigneeData?.length > 0)
                        && assigneeData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">AS</span>
                      )}
                      {(Array.isArray(resPersonData) && resPersonData.length > 0)
                        && resPersonData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">RP</span>
                      )}
                      {(Array.isArray(managerData) && managerData.length > 0)
                        && managerData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">MA</span>
                      )}

                      {(Array.isArray(strComData) && strComData.length > 0)
                        && strComData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">SC</span>
                      )}
                      {(Array.isArray(sponsorData) && sponsorData.length > 0)
                        && sponsorData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">SP</span>
                      )}
                      {(Array.isArray(teamMemberData) && teamMemberData.length > 0)
                        && teamMemberData?.some((el) => el.id === d.id) && (
                        <span className="people-icon owner-icon">TM</span>
                      )}
                      {collaboratorData && (Array.isArray(collaboratorData) && collaboratorData?.length > 0)
                      && collaboratorData?.some((cd) => cd?.value?.some(
                        (cv) => cv?.collaborators?.some((cl) => cl?.id === d?.id),
                      ))
                        && (
                        <span className="people-icon owner-icon">CB</span>
                        )}
                    </td>
                    <td>{d?.email || '-'}</td>
                    <td>{dayjs(d?.created).format('MM/DD/YYYY') || '-'}</td>
                    <td>{d?.is_active ? 'Active' : 'Active'}</td>
                  </tr>
                ))
                : (
                  <tr>
                    <td colSpan={7} className="text-center">No Data To Display</td>
                  </tr>
                )}
            </tbody>
          </table>
          {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
        </div>
      </div>
    </div>
  );
}
