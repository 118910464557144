/* eslint-disable no-debugger */
/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jsonData: [],
  sectionData: [],
  applicationValues: {},
  appTwinVals: {},
  capabilitiesData: [],
  processCapabilitiesData: [],
  processAppCapabilitiesData: [], // process app caps
  stepCapabilitiesData: [],
  tableCapabilitiesData: [],
  changeCapabilities: [
    {
      key: 'process',
      icon: 'icon-value-chain',
      title: 'Process',
      value: [],
    },
    {
      key: 'application',
      icon: 'icon-application',
      title: 'Application',
      value: [],
    },
    {
      key: 'business',
      icon: 'icon-business',
      title: 'Business',
      value: [],
    },
    {
      key: 'vendor',
      icon: 'icon-supplier',
      title: 'Vendor',
      value: [],
    },
    {
      key: 'customer',
      icon: 'icon-consumer',
      title: 'Customer',
      value: [],
    },
    {
      key: 'product',
      icon: 'icon-toolbar',
      title: 'Product',
      value: [],
    },
  ],
  requirementCapabilities: [
    {
      key: 'process',
      icon: 'icon-value-chain',
      title: 'Process',
      value: [],
    },
    {
      key: 'application',
      icon: 'icon-application',
      title: 'Application',
      value: [],
    },
    {
      key: 'business',
      icon: 'icon-business',
      title: 'Business',
      value: [],
    },
    {
      key: 'vendor',
      icon: 'icon-supplier',
      title: 'Vendor',
      value: [],
    },
    {
      key: 'customer',
      icon: 'icon-consumer',
      title: 'Customer',
      value: [],
    },
    {
      key: 'product',
      icon: 'icon-toolbar',
      title: 'Product',
      value: [],
    },
  ],
  capsMapping: [],
  stepCapsMapping: [],
  procesStepCapabilitiesData: [],
  pullProcesCapabilitiesData: [],
  pullProcessCaps: [],
  pullProcessAppCaps: [],
  processReviewCaps: [],
  processAppReviewCaps: [],
  processStepReviewCaps: [],
  processStepAppReviewCaps: [],
  appInputDisable: false,
  changeCapabilitiesReadOnly: [
    {
      key: 'process',
      icon: 'icon-value-chain',
      title: 'Process',
      value: [],
    },
    {
      key: 'application',
      icon: 'icon-application',
      title: 'Application',
      value: [],
    },
    {
      key: 'business',
      icon: 'icon-business',
      title: 'Business',
      value: [],
    },
    {
      key: 'vendor',
      icon: 'icon-supplier',
      title: 'Vendor',
      value: [],
    },
    {
      key: 'customer',
      icon: 'icon-consumer',
      title: 'Customer',
      value: [],
    },
    {
      key: 'product',
      icon: 'icon-toolbar',
      title: 'Product',
      value: [],
    },
  ],
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    addJson: (state, { payload }) => {
      state.jsonData = payload;
    },
    addSection: (state, { payload }) => {
      state.sectionData = payload;
    },
    addTranslation: (state, { payload }) => {
      state.sectionData = payload;
    },
    addApplicationVals: (state, { payload }) => {
      state.applicationValues = payload;
    },
    addAppTwinVals: (state, { payload }) => {
      state.appTwinVals = payload;
    },
    addCapabilities: (state, { payload }) => {
      // state.capabilitiesData = payload;
      const uniqueAppCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.capabilitiesData = uniqueAppCaps;
    },
    addProcessCapabilities: (state, { payload }) => {
      const uniqueProcessCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processCapabilitiesData = uniqueProcessCaps;
    },
    pullProcessCapabilities: (state, { payload }) => {
      const uniqueProcessCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcesCapabilitiesData = uniqueProcessCaps;
    },
    addProcessAppCapabilities: (state, { payload }) => {
      state.processAppCapabilitiesData = payload;
    },
    addTableCapabilitieData: (state, { payload }) => {
      state.tableCapabilitiesData = payload;
    },
    addCapsMapping: (state, { payload }) => {
      state.capsMapping = payload;
    },
    addStepCapsMapping: (state, { payload }) => {
      state.stepCapsMapping = payload;
    },
    addChangeCapabilities: (state, { payload }) => {
      state.changeCapabilities = [
        {
          key: 'process',
          icon: 'icon-value-chain',
          title: 'Process',
          value: payload.processCaps,
        },
        {
          key: 'application',
          icon: 'icon-application',
          title: 'Application',
          value: payload.appCaps,
        },
        {
          key: 'business',
          icon: 'icon-business',
          title: 'Business',
          value: payload.businessCaps,
        },
        {
          key: 'vendor',
          icon: 'icon-supplier',
          title: 'Vendor',
          value: payload.vendorCaps,
        },
        {
          key: 'customer',
          icon: 'icon-consumer',
          title: 'Customer',
          value: payload.customerCaps,
        },
        {
          key: 'product',
          icon: 'icon-toolbar',
          title: 'Product',
          value: payload.productCaps,
        },
      ];
    },
    addChangeCapabilitiesBackUp: (state, { payload }) => {
      const payload1 = payload?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      const payload2 = payload1?.filter(
        (obj, index) => payload1?.findIndex((item) => item?.type === obj?.type && item?.id === obj?.id && item?.requirement_id === obj?.requirement_id) === index,
      );
      state.changeCapabilities = state?.changeCapabilities?.map((el) => ({ ...el, value: payload2?.filter((rc) => rc?.type === el?.key) }));
    },
    addRequirementCapabilitiesBackUp: (state, { payload }) => {
      const payload1 = payload?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      const payload2 = payload1?.filter(
        (obj, index) => payload1?.findIndex((item) => item?.type === obj?.type && item?.id === obj?.id && item?.requirement_id === obj?.requirement_id) === index,
      );
      state.requirementCapabilities = state?.requirementCapabilities?.map((el) => ({ ...el, value: payload2?.filter((rc) => rc?.type === el?.key) }));
    },
    addChangeCapsFromRiskBenefit: (state, { payload }) => {
      state.changeCapabilities = payload;
    },

    addPullProcessCaps: (state, { payload }) => {
      const uniqueProcessCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcessCaps = uniqueProcessCaps;
    },
    addPullProcessAppCaps: (state, { payload }) => {
      const uniqueProcessAppCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcessAppCaps = uniqueProcessAppCaps;
    },
    addProcessReviewCaps: (state, { payload }) => {
      const uniqueProcessRWCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processReviewCaps = uniqueProcessRWCaps;
    },
    addProcessAppReviewCaps: (state, { payload }) => {
      const uniqueProcessAppRWCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processAppReviewCaps = uniqueProcessAppRWCaps;
    },
    addProcessStepReviewCaps: (state, { payload }) => {
      state.processStepReviewCaps = payload;
    },
    addProcessStepAppReviewCaps: (state, { payload }) => {
      state.processStepAppReviewCaps = payload;
    },
    addStepCapabilities: (state, { payload }) => {
      state.stepCapabilitiesData = payload;
    },
    addProcessStepCapabilities: (state, { payload }) => {
      state.procesStepCapabilitiesData = payload;
    },
    setAppInputDisabled: (state, { payload }) => {
      state.appInputDisable = payload;
    },
    removePullProcessCaps: (state, { payload }) => {
      state.pullProcessCaps = payload && payload.length > 0 && Array.isArray(payload) ? payload : [];
    },
    removePullProcessAppCaps: (state, { payload }) => {
      state.pullProcessAppCaps = payload && payload.length > 0 && Array.isArray(payload) ? payload : [];
    },
    addChangeCapabilitiesReadOnly: (state, { payload }) => {
      state.changeCapabilitiesReadOnly = [
        {
          key: 'process',
          icon: 'icon-value-chain',
          title: 'Process',
          value: payload.processCaps,
        },
        {
          key: 'application',
          icon: 'icon-application',
          title: 'Application',
          value: payload.appCaps,
        },
        {
          key: 'business',
          icon: 'icon-business',
          title: 'Business',
          value: payload.businessCaps,
        },
        {
          key: 'vendor',
          icon: 'icon-supplier',
          title: 'Vendor',
          value: payload.vendorCaps,
        },
        {
          key: 'customer',
          icon: 'icon-consumer',
          title: 'Customer',
          value: payload.customerCaps,
        },
        {
          key: 'product',
          icon: 'icon-toolbar',
          title: 'Product',
          value: payload.productCaps,
        },
      ];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addJson, addSection, addApplicationVals, addAppTwinVals, addCapabilities, addStepCapabilities,
  addProcessCapabilities, addProcessAppCapabilities, addTableCapabilitieData,
  addChangeCapabilities, addCapsMapping,
  addProcessStepCapabilities, addStepCapsMapping, pullProcessCapabilities,
  addPullProcessCaps, addPullProcessAppCaps, addProcessReviewCaps, addProcessAppReviewCaps,
  addProcessStepReviewCaps, addProcessStepAppReviewCaps, addChangeCapsFromRiskBenefit,
  setAppInputDisabled,
  removePullProcessCaps,
  removePullProcessAppCaps,
  addChangeCapabilitiesBackUp,
  addRequirementCapabilitiesBackUp,
  addChangeCapabilitiesReadOnly,
} = applicationSlice.actions;

export default applicationSlice.reducer;
