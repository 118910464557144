/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {
  Alert, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { changeStepEditMode } from '../../services/editFormSlice';
import { addDuplicateVals, toggleProcessComponent } from '../../services/processSlice';
import { addStepDocsInfo, replaceSteptDocs } from '../../services/docInfoSlice';
import ReadMoreLessDocument from '../../components/common/ReadMoreLessDoc';
import { useTwinByCapabMutation } from '../../services/services';
import ReadOnlyCaps from '../../components/capabalities/ReadOnlyCaps';
import ReadMoreLess from '../../components/common/ReadMoreLess';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

export default function ProcessStepReviw({ labelTwin }) {
  // const { t } = useTranslation('ProcessStep');
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const dispatch = useDispatch();
  const extMtData = useSelector((state) => state.editData.processStepExtMtData);
  const [getAppTwin, appTwinData] = useTwinByCapabMutation();
  const peopleData = useSelector((state) => state.peopleData.stepPeople); // step  people
  // const processStepAppReviewCaps = useSelector((state) => state.applicationData.stepCapabilitiesData); // step App capabilities
  // const processStepReviewCaps = useSelector((state) => state.applicationData.procesStepCapabilitiesData);
  const processStepAppReviewCaps = useSelector((state) => state.applicationData.processStepAppReviewCaps);
  const processStepReviewCaps = useSelector((state) => state.applicationData.processStepReviewCaps);
  const businessStepReviewCaps = useSelector((state) => state.businessData.businessStepReviewCaps);
  const vendorStepReviewCaps = useSelector((state) => state.vendorData.vendorStepReviewCaps);
  const customerStepReviewCaps = useSelector((state) => state.customerData.customerStepReviewCaps);
  const productStepReviewCaps = useSelector((state) => state.productData.productStepReviewCaps);
  console.log('businessStepReviewCaps', businessStepReviewCaps);

  const reviewStepDocsInfo = useSelector((state) => state.docInfoData.reviewStepDocsInfo);

  const relatedDate = useSelector((state) => state.processData.relateDate); // related date and people
  const stepId = useSelector((state) => state.processData.stepId);
  const processInputDisable = useSelector((state) => state.processData.processInputDisable);

  const [notify, setNotify] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState(null);
  const handleEdit = (e) => {
    e.preventDefault();
    dispatch(changeStepEditMode(true));
    dispatch(toggleProcessComponent({
      addStep: true,
      listStep: false,
      stepReview: false,
      processReview: false,
      middleDetails: {},
    }));
    dispatch(replaceSteptDocs(reviewStepDocsInfo));
    // getAppTwin({ id: stepId, type: 'Process Step' });
  };

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  return (

    <form action="" className="add-program-form">
      {notify && (
        <div className={`toast-wrapper ${notify && 'show'} text-capitalize`}>
          <Alert variant="primary" onClose={() => setNotify('')} dismissible>
            <span className="icon-warning" />
            {notify}
          </Alert>
        </div>
      )}
      {!(labelTwin === 'readOnly' || processInputDisable) && (
      <div className="d-flex justify-content-end">
        <button
          type="button"
          onClick={(e) => handleEdit(e)}
          className="btn btn-link text-decoration-none"
        >
          <span className="icon-edit" />
        </button>
      </div>
      )}
      <div className="form-unit">
        <div className="container-fluid">
          <h2 className="h1">
            1.
            {' '}
            Details
          </h2>
          {(extMtData?.fields && extMtData?.fields?.length > 0 && Array.isArray(extMtData?.fields))
            && extMtData.sections.map((tl, i) => (
              <Fragment key={tl?.id}>
                <h2 className="section-title">{tl.display_name}</h2>
                <div className="row">
                  {extMtData.fields.map(
                    (fl) => tl?.id === fl?.section_title.id && (
                      <div key={fl.id} className={`col-6  ${fl?.field_data_type === 'texteditor' ? 'col-lg-8' : 'col-lg-4'}`}>
                        <div className="form-group">
                          <label htmlFor="program_subj">
                            {fl?.field_name === 'unique_id'
                              ? 'Unique ID' : fl?.field_name === 'documents'
                                ? 'Document(s)' : fl.display_name}
                          </label>
                          <p className="readonly-text font-weight-bold">
                            {((fl.field_data_type === 'text' || fl.field_data_type === 'date' || (fl.field_data_type === 'readonly' || fl.field_data_type === 'readOnly')) && fl.value) ? fl.value
                              : ((fl.field_data_type === 'select') && fl.value?.label) ? fl.value?.label
                                : (fl.field_data_type === 'file'
                                  && fl.value !== null && Array.isArray(fl.value)) ? (
                                    <ReadMoreLessDocument field={fl} doc={fl?.value} />
                                  )
                                  : fl.field_data_type === 'texteditor'
                                    ? (
                                      <Editor
                                        editorState={fl.value ? EditorState.createWithContent(
                                          ContentState.createFromBlockArray(
                                            htmlToDraft(fl.value),
                                          ),
                                        ) : EditorState.createEmpty()}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        toolbarHidden
                                      />
                                    )
                                    : (fl.field_data_type === 'select' && fl.field_name === 'domain') ? fl?.value
                                      : (fl.field_data_type === 'textarea' && fl?.value) ? <ReadMoreLess text={fl?.value} /> : '-'}
                          </p>
                        </div>
                      </div>
                    ),
                  )}
                </div>
                <hr />
              </Fragment>
            ))}

          <h2 className="h1">
            2.
            Capabilities
          </h2>
          {((processStepAppReviewCaps && Array.isArray(processStepAppReviewCaps) && processStepAppReviewCaps.length > 0)
          || (processStepReviewCaps && Array.isArray(processStepReviewCaps) && processStepReviewCaps.length > 0)
          || (businessStepReviewCaps && Array.isArray(businessStepReviewCaps) && businessStepReviewCaps.length > 0)
          || (vendorStepReviewCaps && Array.isArray(vendorStepReviewCaps) && vendorStepReviewCaps.length > 0)
          || (customerStepReviewCaps && Array.isArray(customerStepReviewCaps) && customerStepReviewCaps.length > 0)
          || (productStepReviewCaps && Array.isArray(productStepReviewCaps) && productStepReviewCaps.length > 0)

          )
            ? (
              <div id="expand-demo">
                <div id="wrpp" className="capability-cards process-cards review-cards">
                  <div id="fsm_container" className="fsm-container">
                    {(processStepReviewCaps && Array.isArray(processStepReviewCaps) && processStepReviewCaps.length > 0)
                      && (
                        <div className="fsm">
                          <div className="card">
                            <h3 className="card-header">
                              <span className="icon-value-chain" />
                              Process
                              {' '}
                              {`(${processStepReviewCaps.length})`}
                            </h3>
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>Process Name</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {processStepReviewCaps?.slice(0, 5)?.map((ele) => (
                                    <tr key={ele?.name}>
                                      <td className="sm-col">
                                        <span className="icon-value-chain" />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                          <Button
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/process/${ele?.id}`}
                                          >
                                            {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name)}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ele?.created || '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {processStepReviewCaps?.length > 5
                                && (
                                  <div className="more-link d-flex justify-content-end">
                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Process')}>more</button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    {(processStepAppReviewCaps && Array.isArray(processStepAppReviewCaps) && processStepAppReviewCaps.length > 0)
                      && (
                        <div className="fsm">
                          <div className="card">
                            <h3 className="card-header">
                              <span className="icon-application" />
                              Application
                              {' '}
                              {`(${processStepAppReviewCaps.length})`}
                            </h3>
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>Application Name</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {processStepAppReviewCaps?.slice(0, 5)?.map((ele) => (
                                    <tr key={ele?.name}>
                                      <td className="sm-col">
                                        <span className="icon-application" />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                          <Button
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/application/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                          >
                                            {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ele?.created || '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {processStepAppReviewCaps?.length > 5
                                && (
                                  <div className="more-link d-flex justify-content-end">
                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Application')}>more</button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    {(businessStepReviewCaps && Array.isArray(businessStepReviewCaps) && businessStepReviewCaps.length > 0)
                      && (
                        <div className="fsm">
                          <div className="card">
                            <h3 className="card-header">
                              <span className="icon-business" />
                              Business
                              {' '}
                              {`(${businessStepReviewCaps.length})`}
                            </h3>
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>Business Name</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {businessStepReviewCaps?.slice(0, 5)?.map((ele) => (
                                    <tr key={ele?.name}>
                                      <td className="sm-col">
                                        <span className="icon-business" />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                          <Button
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/business/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                          >
                                            {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ele?.created || '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {businessStepReviewCaps?.length > 5
                                && (
                                  <div className="more-link d-flex justify-content-end">
                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Business')}>more</button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                    {(vendorStepReviewCaps && Array.isArray(vendorStepReviewCaps) && vendorStepReviewCaps.length > 0)
                      && (
                        <div className="fsm">
                          <div className="card">
                            <h3 className="card-header">
                              <span className="icon-supplier" />
                              Vendor
                              {' '}
                              {`(${vendorStepReviewCaps.length})`}
                            </h3>
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>Vendor Name</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendorStepReviewCaps?.slice(0, 5)?.map((ele) => (
                                    <tr key={ele?.name}>
                                      <td className="sm-col">
                                        <span className="icon-supplier" />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                          <Button
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/vendor/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                          >
                                            {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ele?.created || '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {vendorStepReviewCaps?.length > 5
                                && (
                                  <div className="more-link d-flex justify-content-end">
                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Vendor')}>more</button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                    {(customerStepReviewCaps && Array.isArray(customerStepReviewCaps) && customerStepReviewCaps.length > 0)
                      && (
                        <div className="fsm">
                          <div className="card">
                            <h3 className="card-header">
                              <span className="icon-consumer" />
                              Customer
                              {' '}
                              {`(${customerStepReviewCaps.length})`}
                            </h3>
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>Customer Name</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {customerStepReviewCaps?.slice(0, 5)?.map((ele) => (
                                    <tr key={ele?.name}>
                                      <td className="sm-col">
                                        <span className="icon-consumer" />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                          <Button
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/customer/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                          >
                                            {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ele?.created || '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {customerStepReviewCaps?.length > 5
                                && (
                                  <div className="more-link d-flex justify-content-end">
                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Customer')}>more</button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                    {(productStepReviewCaps && Array.isArray(productStepReviewCaps) && productStepReviewCaps.length > 0)
                      && (
                        <div className="fsm">
                          <div className="card">
                            <h3 className="card-header">
                              <span className="icon-toolbar" />
                              Product
                              {' '}
                              {`(${productStepReviewCaps.length})`}
                            </h3>
                            <div className="card-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th className="sm-col">&nbsp;</th>
                                    <th>Product Name</th>
                                    <th>Delivery Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productStepReviewCaps?.slice(0, 5)?.map((ele) => (
                                    <tr key={ele?.name}>
                                      <td className="sm-col">
                                        <span className="icon-toolbar" />
                                      </td>
                                      <td>
                                        <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                          <Button
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                            href={`/review/product/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                          >
                                            {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{ele?.created || '-'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              {productStepReviewCaps?.length > 5
                                && (
                                  <div className="more-link d-flex justify-content-end">
                                    <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Product')}>more</button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                    <div id="fsm_actual">
                      {expanded
                        && (
                          <ReadOnlyCaps
                            appCapabilitiesData={processStepAppReviewCaps}
                            processCapabilitiesData={processStepReviewCaps}
                            businessCapabilitiesData={businessStepReviewCaps}
                            vendorCapabilitiesData={vendorStepReviewCaps}
                            customerCapabilitiesData={customerStepReviewCaps}
                            productCapabilitiesData={productStepReviewCaps}
                            setExpanded={setExpanded}
                            capsInfo={capsInfo}
                            size={size}
                            position={position}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )
            : <p>No Capabilities To Display</p>}
          <hr />
          <h2 className="h1">
            3.
            {' '}
            People
          </h2>
          <div className="row">
            <div className="col-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="program_subj">Owner</label>
                {(peopleData?.owner && peopleData?.owner?.length > 0 && Array.isArray(peopleData?.owner)) ? (
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {peopleData?.owner.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                    ))}
                  </p>
                ) : '-'}
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="program_subj">Manager</label>
                {(peopleData?.manager && peopleData?.manager?.length > 0 && Array.isArray(peopleData?.manager)) ? (
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {peopleData?.manager?.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                    ))}
                  </p>
                ) : '-'}
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="form-group">
                <label htmlFor="program_subj">Team Member</label>
                {(peopleData?.teams && peopleData?.teams?.length > 0 && Array.isArray(peopleData?.teams)) ? (
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {' '}
                    {peopleData?.teams.map((ow, i) => (
                      <>
                        <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                        {i === peopleData.teams.length - 1
                          ? ''
                          : ', '}
                      </>
                    ))}
                  </p>
                ) : '-'}
              </div>
            </div>
          </div>
          <hr />

          <div className="grey-bg">
            <h2>Related Dates and People</h2>
            <div className="row">
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created</label>
                  <p className="readonly-text font-weight-bold">
                    {relatedDate?.created ? dayjs(relatedDate?.created).format(userData?.tenant_info?.date_format) : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated</label>
                  <p className="readonly-text font-weight-bold">
                    {relatedDate?.updated ? dayjs(relatedDate?.updated).format(userData?.tenant_info?.date_format) : '-'}
                  </p>
                </div>
              </div>
              <div className="w-100" />
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {relatedDate?.created_by ? relatedDate?.created_by : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {relatedDate?.updated_by ? relatedDate?.updated_by : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  );
}
