/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

const useSearchFilter = (tblArray) => {
  const [filter, setFilter] = useState(null);
  const [filterArray, setFilterArray] = useState([]);

  const filterTBLData = (array, filters) => {
    const filterKeys = Object.keys(filters);
    console.log('filterKeys', filterKeys);
    if (tblArray?.some((tb) => tb?.digital_twin_data)) {
      return tblArray?.filter((item) => filterKeys?.every((key) => item?.digital_twin_data[key]?.toString()?.toLowerCase()?.includes(filters[key]?.toString()?.toLowerCase())));
    }
    return array?.filter((item) => filterKeys?.every((key) => {
      if ((key === 'owners') && item[key] && Array.isArray(item[key]) && item[key]?.length > 0) {
        return item[key]?.some((ow) => `${ow?.first_name} ${ow?.last_name}`?.toString()?.toLowerCase()?.includes(filters?.owners?.toString()?.toLowerCase()));
      }
      if ((key === 'measure_owners') && item[key] && Array.isArray(item[key]) && item[key]?.length > 0) {
        return item[key]?.some((ow) => `${ow?.first_name} ${ow?.last_name}`?.toString()?.toLowerCase()?.includes(filters?.measure_owners?.toString()?.toLowerCase()));
      }
      if ((key === 'teams') && item[key] && Array.isArray(item[key]) && item[key]?.length > 0) {
        return item[key]?.some((ow) => `${ow?.first_name} ${ow?.last_name}`?.toString()?.toLowerCase()?.includes(filters?.teams?.toString()?.toLowerCase()));
      }
      if ((key === 'managers') && item[key] && Array.isArray(item[key]) && item[key]?.length > 0) {
        return item[key]?.some((ow) => `${ow?.first_name} ${ow?.last_name}`?.toString()?.toLowerCase()?.includes(filters?.managers?.toString()?.toLowerCase()));
      }
      if ((key === 'assignees') && item[key] && Array.isArray(item[key]) && item[key]?.length > 0) {
        return item[key]?.some((ow) => `${ow?.first_name} ${ow?.last_name}`?.toString()?.toLowerCase()?.includes(filters?.assignees?.toString()?.toLowerCase()));
      }
      return item[key]?.toString()?.toLowerCase()?.includes(filters[key]?.toString()?.toLowerCase());
    }));
  };

  useEffect(() => {
    if (tblArray && Array.isArray(tblArray) && tblArray?.length > 0 && filter) {
      const myFilterArr = filterTBLData(tblArray, filter);
      if (myFilterArr && Array.isArray(myFilterArr) && myFilterArr?.length > 0) {
        setFilterArray(myFilterArr);
      } else {
        setFilterArray([]);
      }
    }
  }, [tblArray, filter]);

  return {
    filter,
    setFilter,
    filterArray,
  };
};
export default useSearchFilter;
