/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { Fragment, useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ReadOnlyCaps from '../../components/capabalities/ReadOnlyCaps';
import { useLockStatusMutation } from '../../services/services';
import { addIsDirty } from '../../services/navSlice';
import { changeEditMode } from '../../services/editFormSlice';
import ReadMoreLessDocument from '../../components/common/ReadMoreLessDoc';
import CancelModal from '../../components/common/CancelModal';
import StepFooter from '../../components/common/StepFooter';
import ReadMoreLess from '../../components/common/ReadMoreLess';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function CustomerTwinReview({
  taskId,
  twinId,
  draftMode,
  readMode,
  peopleView,
  submit,
  steps,
  activeStep,
  cancelTitle,
  modalShow,
  setModalShow,
  handleBack,
  isLoading,
  isError,
  stepReset,
  dfVals,
  isLocked,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('customer');
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const [lockStatus, lockRes] = useLockStatusMutation();
  const relatedDate = useSelector((state) => state.formJson.relateDate); // related date and people
  const peopleData = useSelector((state) => state.peopleData.peopleVals); // all people
  const appCapabilitiesData = useSelector(
    (state) => state.applicationData.capabilitiesData,
  ); // app capabilities
  const processCapabilitiesData = useSelector(
    (state) => state.applicationData.processCapabilitiesData,
  ); // Process capabilities
  const businessCapabilitiesData = useSelector(
    (state) => state.businessData.capabilitiesData,
  ); // Business capabilities
  const vendorCapabilitiesData = useSelector(
    (state) => state.vendorData.capabilitiesData,
  ); // Vendor capabilities
  const customerCapabilitiesData = useSelector(
    (state) => state.customerData.capabilitiesData,
  ); // Customer capabilities
  const productCapabilitiesData = useSelector((state) => state.productData.capabilitiesData); // Product capabilities
  const relatedChangeData = useSelector(
    (state) => state.formJson.relatedChangeData,
  ); // req change
  const selectedTwinRequirement = useSelector(
    (state) => state.requirementData.selectedTwinRequirement,
  );
  const isTwinMode = useSelector((state) => state.formJson.isTwinMode);
  const reqEnhancement = useSelector(
    (state) => state.requirementData.reqEnhancement,
  );
  const changeEnhancement = useSelector(
    (state) => state.rcData.changeEnhancement,
  );

  const extMtData = useSelector((state) => state.editData.extMtData);
  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState(null);

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(addIsDirty(true));
    lockStatus({
      capability: 'customer',
      id: twinId,
      locked: true,
      locked_by: userData?.id,
    });
  };

  useEffect(() => {
    if (lockRes.isSuccess) {
      dispatch(changeEditMode(true));
      navigate('/customerTwin', {
        state: {
          taskId,
          twinId,
          breadcrumb: location?.state?.breadcrumb,
        },
      });
    }
  }, [lockRes]);

  const getComponents = (extensions) => extensions?.sections?.map((sec, i) => (
    <React.Fragment key={sec?.display_name}>
      <h2 className="section-title">{sec?.display_name}</h2>
      {/* for twin only */}
      {sec?.display_name === 'Enhancement' && isTwinMode && (
      <>
        <h3>Changes</h3>
        <div className="table-responsive box-shadow mb-4">
          <table className="table table-hover">
            <thead>
              <tr className="heading-row">
                <th>Name</th>
                <th>Description</th>
                <th>Change Comments</th>
              </tr>
            </thead>
            <tbody>
              {changeEnhancement
                  && Array.isArray(changeEnhancement)
                  && changeEnhancement?.length > 0 ? (
                  changeEnhancement?.map((td) => (
                    <tr key={td?.name}>
                      <td className="col-md-4">
                        <Button
                          href={`/review/change/${td?.id}`}
                          target="_blank"
                          variant="link"
                          style={{ textDecoration: 'none' }}
                        >
                          {td?.name}
                        </Button>
                      </td>
                      <td className="col-lg-3"><ReadMoreLess text={td?.description || ''} /></td>
                      <td className="col-md-8">
                        {td?.comments ? td?.comments : '-'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2} className="text-center">
                      No Data To Display
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </>
      )}
      {sec?.display_name === 'Enhancement' && isTwinMode && (reqEnhancement && Array.isArray(reqEnhancement) && reqEnhancement?.length > 0) && (
      <>
        <h3>Requirements</h3>
        <div className="table-responsive box-shadow mb-4">
          <table className="table table-hover">
            <thead>
              <tr className="heading-row">
                <th>Name</th>
                <th>Description</th>
                <th>Requirement Comments</th>
              </tr>
            </thead>
            <tbody>
              {reqEnhancement?.map((td) => (
                <tr key={td?.id}>
                  <td className="col-md-4">
                    <Button
                      href={`/review/requirement/${td?.id}`}
                      target="_blank"
                      variant="link"
                      style={{ textDecoration: 'none' }}
                    >
                      {td?.name}
                    </Button>
                  </td>
                  <td className="col-lg-3"><ReadMoreLess text={td?.description || ''} /></td>
                  <td className="col-md-8">
                    {td?.comments
                      ? td?.comments
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
      )}

      <div className="row">
        {extensions?.fields
            && extensions?.fields?.length > 0
            && extensions?.fields?.map((fld, index) => {
              if (sec?.name === fld.section_title.name) {
                switch (fld.field_data_type) {
                  case 'text':
                    return (
                      <div className="col-6 col-lg-4" key={fld.field_name}>
                        <div className="form-group">
                          <label
                            htmlFor={fld.field_name}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {t(t(`customer:${fld?.field_name}`).name)}
                          </label>

                          <p className="readonly-text font-weight-bold">
                            {sec?.name === 'budget'
                              ? fld?.value
                                ? `${userData?.tenant_info?.currency_code} ${fld.value}`
                                : '0'
                              : fld?.value}
                          </p>
                        </div>
                      </div>
                    );
                  case 'readonly':
                    return (
                      <div className="col-6 col-lg-4" key={fld.field_name}>
                        <div className="form-group">
                          <label
                            htmlFor={fld.field_name}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {t(t(`customer:${fld?.field_name}`).name)}
                          </label>
                          <p className="readonly-text font-weight-bold">
                            {sec?.name === 'budget'
                              ? fld.value
                                ? `${userData?.tenant_info?.currency_code} ${fld.value}`
                                : '0'
                              : fld.value}
                          </p>
                        </div>
                      </div>
                    );
                  case 'file':
                    return (
                      <div className="col-6 col-lg-4" key={fld.field_name}>
                        <div className="form-group upload-group">
                          <label
                            htmlFor={fld.field_name}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {t(t(`customer:${fld?.field_name}`).name)}
                          </label>
                          {fld?.value !== null && Array.isArray(fld.value) ? (
                            <ReadMoreLessDocument field={fld} doc={fld.value} />
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    );
                  case 'date':
                    return (
                      <div className="col-6 col-lg-4" key={fld.field_name}>
                        <div className="form-group">
                          <label
                            htmlFor={fld.field_name}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {t(t(`customer:${fld?.field_name}`).name)}
                          </label>
                          <input
                            readOnly
                            className="form-control-plaintext"
                            type="text"
                            value={
                              fld?.value
                              && dayjs(fld?.value).format(
                                userData?.tenant_info?.date_format,
                              )
                            }
                            style={{ color: '#000' }}
                            placeholder={fld.field_value_option.place_holder}
                          />
                        </div>
                      </div>
                    );

                  case 'textarea':
                    return (
                      <div className="col-6 col-lg-4" key={fld.field_name}>
                        <div className="form-group">
                          <label
                            htmlFor={fld.field_name}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {fld.field_name === 'change_comments'
                            || fld.field_name === 'requirement_comments'
                              ? fld?.display_name
                              : t(t(`customer:${fld?.field_name}`).name)}
                          </label>
                          <textarea
                            className="form-control "
                            id={fld.field_name}
                            value={fld?.value ? fld?.value : ''}
                          />
                        </div>
                      </div>
                    );
                  // case 'texteditor':
                  //   return (
                  //     <div className="col-6 col-lg-8" key={fld.field_name}>
                  //       <div className="form-group">
                  //         <label
                  //           htmlFor={fld.field_name}
                  //           className="d-flex justify-content-between align-items-center"
                  //         >
                  //           {t(t(`customer:${fld?.field_name}`).name)}
                  //         </label>
                  //         <Editor
                  //           editorState={(isJson(fld?.value) || fld?.value) ? EditorState.createWithContent(
                  //             ContentState.createFromBlockArray(
                  //               htmlToDraft(isJson(fld?.value) ? JSON.parse(fld?.value) : fld?.value),
                  //             ),
                  //           ) : EditorState.createEmpty()}
                  //           wrapperClassName="wrapper-class"
                  //           editorClassName="editor-class"
                  //           toolbarClassName="toolbar-class"
                  //           toolbarHidden
                  //         />
                  //       </div>
                  //     </div>
                  //   );
                  case 'select':
                    return (
                      <div className="col-6 col-lg-4" key={fld.field_name}>
                        <div className="form-group">
                          <label
                            htmlFor={fld.field_name}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {t(t(`customer:${fld?.field_name}`).name)}
                          </label>
                          <p className="readonly-text font-weight-bold">
                            {fld?.field_type === 'System'
                            && fld?.value
                            && fld?.value !== 'undefined'
                              ? fld?.value?.label || '-'
                              : fld?.field_type === 'Custom'
                                && fld?.value !== 'undefined'
                                ? fld?.value
                                : fld?.field_name === 'status'
                                  ? fld?.value?.value
                                  : '-'}
                          </p>
                        </div>
                      </div>
                    );
                  default:
                    return (
                      <div key={fld.field_name}>
                        <span className="text-danger">Invalid Field</span>
                      </div>
                    );
                }
              }
              return false;
            })}
      </div>
      <hr />
    </React.Fragment>
  ));

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',
      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',
      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  return (
    <>
      {draftMode && readMode && !isLocked && (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={(e) => handleEdit(e)}
            className="btn btn-link text-decoration-none"
          >
            <span className="icon-edit" />
          </button>
        </div>
      )}
      <div className="form-unit">
        <div className="container-fluid">
          <h2 className="h1">1. Details</h2>
          {extMtData && getComponents(extMtData)}
          <h2 className="h1">2. Capabilities</h2>
          <div id="expand-demo">
            <div id="wrpp" className="capability-cards review-cards">
              <div id="fsm_container" className="fsm-container">
                <div className="fsm">
                  {processCapabilitiesData
                  && Array.isArray(processCapabilitiesData)
                  && processCapabilitiesData.length > 0 ? (
                    <div className="card">
                      <h3 className="card-header">
                        <span className="icon-value-chain" />
                        Process
                        {' '}
                        {`(${processCapabilitiesData?.length})`}
                      </h3>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="sm-col">&nbsp;</th>
                              <th>Process Name</th>
                              <th>Delivery Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {processCapabilitiesData
                              ?.slice(0, 5)
                              ?.map((ele) => (
                                <tr key={ele?.name}>
                                  <td className="sm-col">
                                    <span className="icon-value-chain" />
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip(ele?.name)}
                                    >
                                      <Button
                                        className="text-decoration-none"
                                        variant="link"
                                        target="_blank"
                                        href={`/review/process/${ele?.id}`}
                                      >
                                        {ele?.name?.length > 10
                                          ? `${ele?.name?.substring(0, 10)}...`
                                          : ele?.name}
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    {ele?.delivery_date
                                      ? dayjs(ele?.delivery_date).format(
                                        'DD/MM/YYYY',
                                      )
                                      : '-'}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {processCapabilitiesData?.length > 5 && (
                          <div className="more-link d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              onClick={(e) => clickToExapand(e, 'Process')}
                            >
                              more
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    ) : (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-value-chain" />
                          Process
                        </h3>
                        <div className="card-body empty">
                          <h1>No Data To Display</h1>
                        </div>
                      </div>
                    )}
                </div>

                <div className="fsm">
                  {appCapabilitiesData
                  && Array.isArray(appCapabilitiesData)
                  && appCapabilitiesData.length > 0 ? (
                    <div className="card">
                      <h3 className="card-header">
                        <span className="icon-application" />
                        Application
                        {' '}
                        {`(${appCapabilitiesData?.length})`}
                      </h3>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="sm-col">&nbsp;</th>
                              <th>Application Name</th>
                              <th>Delivery Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {appCapabilitiesData?.slice(0, 5)?.map((ele) => (
                              <tr key={ele?.name}>
                                <td className="sm-col">
                                  <span className="icon-application" />
                                </td>
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={renderTooltip(ele?.name)}
                                  >
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/application/${ele?.id}`}
                                    >
                                      {ele?.name?.length > 10
                                        ? `${ele?.name?.substring(0, 10)}...`
                                        : ele?.name}
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                                <td>
                                  {ele?.delivery_date
                                    ? dayjs(ele?.delivery_date).format(
                                      'DD/MM/YYYY',
                                    )
                                    : '-'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {appCapabilitiesData?.length > 5 && (
                          <div className="more-link d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              onClick={(e) => clickToExapand(e, 'Application')}
                            >
                              more
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    ) : (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-application" />
                          Application
                        </h3>
                        <div className="card-body empty">
                          <h1>No Data To Display</h1>
                        </div>
                      </div>
                    )}
                </div>

                <div className="fsm">
                  {businessCapabilitiesData
                  && Array.isArray(businessCapabilitiesData)
                  && businessCapabilitiesData.length > 0 ? (
                    <div className="card">
                      <h3 className="card-header">
                        <span className="icon-business" />
                        Business
                        {' '}
                        {`(${businessCapabilitiesData?.length})`}
                      </h3>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="sm-col">&nbsp;</th>
                              <th>Business Name</th>
                              <th>Delivery Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {businessCapabilitiesData
                              ?.slice(0, 5)
                              ?.map((ele) => (
                                <tr key={ele?.name}>
                                  <td className="sm-col">
                                    <span className="icon-business" />
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip(ele?.name)}
                                    >
                                      <Button
                                        className="text-decoration-none"
                                        variant="link"
                                        target="_blank"
                                        href={`/review/business/${ele?.id}`}
                                      >
                                        {ele?.name?.length > 10
                                          ? `${ele?.name?.substring(0, 10)}...`
                                          : ele?.name}
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    {ele?.delivery_date
                                      ? dayjs(ele?.delivery_date).format(
                                        'DD/MM/YYYY',
                                      )
                                      : '-'}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {businessCapabilitiesData?.length > 5 && (
                          <div className="more-link d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              onClick={(e) => clickToExapand(e, 'Business')}
                            >
                              more
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    ) : (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-business" />
                          Business
                        </h3>
                        <div className="card-body empty">
                          <h1>No Data To Display</h1>
                        </div>
                      </div>
                    )}
                </div>

                <div className="fsm">
                  {vendorCapabilitiesData
                  && Array.isArray(vendorCapabilitiesData)
                  && vendorCapabilitiesData.length > 0 ? (
                    <div className="card">
                      <h3 className="card-header">
                        <span className="icon-supplier" />
                        Vendor
                        {' '}
                        {`(${vendorCapabilitiesData?.length})`}
                      </h3>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="sm-col">&nbsp;</th>
                              <th>Vendor Name</th>
                              <th>Delivery Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {vendorCapabilitiesData?.slice(0, 5)?.map((ele) => (
                              <tr key={ele?.name}>
                                <td className="sm-col">
                                  <span className="icon-supplier" />
                                </td>
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={renderTooltip(ele?.name)}
                                  >
                                    <Button
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                      href={`/review/vendor/${ele?.id}`}
                                    >
                                      {ele?.name?.length > 10
                                        ? `${ele?.name?.substring(0, 10)}...`
                                        : ele?.name}
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                                <td>
                                  {ele?.delivery_date
                                    ? dayjs(ele?.delivery_date).format(
                                      'DD/MM/YYYY',
                                    )
                                    : '-'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {vendorCapabilitiesData?.length > 5 && (
                          <div className="more-link d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              onClick={(e) => clickToExapand(e, 'Vendor')}
                            >
                              more
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    ) : (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-supplier" />
                          Vendor
                        </h3>
                        <div className="card-body empty">
                          <h1>No Data To Display</h1>
                        </div>
                      </div>
                    )}
                </div>

                <div className="fsm">
                  {customerCapabilitiesData
                  && Array.isArray(customerCapabilitiesData)
                  && customerCapabilitiesData.length > 0 ? (
                    <div className="card">
                      <h3 className="card-header">
                        <span className="icon-consumer" />
                        Customer
                        {' '}
                        {`(${customerCapabilitiesData?.length})`}
                      </h3>
                      <div className="card-body">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className="sm-col">&nbsp;</th>
                              <th>Customer Name</th>
                              <th>Delivery Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customerCapabilitiesData
                              ?.slice(0, 5)
                              ?.map((ele) => (
                                <tr key={ele?.name}>
                                  <td className="sm-col">
                                    <span className="icon-consumer" />
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={renderTooltip(ele?.name)}
                                    >
                                      <Button
                                        className="text-decoration-none"
                                        variant="link"
                                        target="_blank"
                                        href={`/review/customer/${ele?.id}`}
                                      >
                                        {ele?.name?.length > 10
                                          ? `${ele?.name?.substring(0, 10)}...`
                                          : ele?.name}
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    {ele?.delivery_date
                                      ? dayjs(ele?.delivery_date).format(
                                        'DD/MM/YYYY',
                                      )
                                      : '-'}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {customerCapabilitiesData?.length > 5 && (
                          <div className="more-link d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none"
                              onClick={(e) => clickToExapand(e, 'Customer')}
                            >
                              more
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    ) : (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-consumer" />
                          Customer
                        </h3>
                        <div className="card-body empty">
                          <h1>No Data To Display</h1>
                        </div>
                      </div>
                    )}
                </div>

                <div className="fsm">
                  {(productCapabilitiesData && Array.isArray(productCapabilitiesData) && productCapabilitiesData.length > 0)
                    ? (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-business" />
                          Product
                          {' '}
                          {`(${productCapabilitiesData?.length})`}
                        </h3>
                        <div className="card-body">
                          <table className="table table-borderless">
                            <thead>
                              <tr>
                                <th className="sm-col">&nbsp;</th>
                                <th>Product Name</th>
                                <th>Delivery Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {productCapabilitiesData?.slice(0, 5)?.map((ele) => (
                                <tr key={ele?.name}>
                                  <td className="sm-col">
                                    <span className="icon-business" />
                                  </td>
                                  <td>
                                    <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                      <Button
                                        className="text-decoration-none"
                                        variant="link"
                                        target="_blank"
                                        href={`/review/product/${ele?.id}`}
                                      >
                                        {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{ele?.delivery_date ? dayjs(ele?.delivery_date).format('DD/MM/YYYY') : '-'}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {productCapabilitiesData?.length > 5
                          && (
                            <div className="more-link d-flex justify-content-end">
                              <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Product')}>more</button>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                    : (
                      <div className="card">
                        <h3 className="card-header">
                          <span className="icon-business" />
                          Product
                        </h3>
                        <div className="card-body empty">
                          <h1>No Data To Display</h1>
                        </div>
                      </div>
                    )}
                </div>

                <div id="fsm_actual">
                  {expanded && (
                    <ReadOnlyCaps
                      appCapabilitiesData={appCapabilitiesData}
                      processCapabilitiesData={processCapabilitiesData}
                      businessCapabilitiesData={businessCapabilitiesData}
                      vendorCapabilitiesData={vendorCapabilitiesData}
                      customerCapabilitiesData={customerCapabilitiesData}
                      productCapabilitiesData={productCapabilitiesData}
                      setExpanded={setExpanded}
                      capsInfo={capsInfo}
                      size={size}
                      position={position}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h2 className="h1">3. People</h2>
          <div className="row">
            {peopleView?.owner && (
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_subj">Owner</label>
                  {peopleData.owner
                  && peopleData.owner?.length > 0
                  && Array.isArray(peopleData.owner) ? (
                    <p className="readonly-text font-weight-bold text-capitalize">
                      {peopleData.owner.map((ow) => (
                        <span key={ow?.id}>
                          {`${ow?.first_name} ${ow?.last_name || ''}`}
                        </span>
                      ))}
                    </p>
                    ) : (
                      '-'
                    )}
                </div>
              </div>
            )}
            {peopleView?.manager && (
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_subj">Manager</label>
                  {peopleData.manager
                  && peopleData.manager?.length > 0
                  && Array.isArray(peopleData.manager) ? (
                    <p className="readonly-text font-weight-bold text-capitalize">
                      {peopleData.manager?.map((ow) => (
                        <span key={ow?.id}>
                          {`${ow?.first_name} ${ow?.last_name || ''}`}
                        </span>
                      ))}
                    </p>
                    ) : (
                      '-'
                    )}
                </div>
              </div>
            )}
            {peopleView?.teams && (
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_subj">Team Member</label>
                  {peopleData.teams
                  && peopleData.teams?.length > 0
                  && Array.isArray(peopleData.teams) ? (
                    <p className="readonly-text font-weight-bold text-capitalize">
                      {' '}
                      {peopleData.teams.map((ow, i) => (
                        <Fragment key={i}>
                          <span>
                            {`${ow?.first_name} ${ow?.last_name || ''}`}
                          </span>
                          {i === peopleData.teams.length - 1 ? '' : ', '}
                        </Fragment>
                      ))}
                    </p>
                    ) : (
                      '-'
                    )}
                </div>
              </div>
            )}
          </div>
          <hr />
          <div className="grey-bg">
            <h2>Related Dates and People</h2>
            <div className="row">
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created</label>
                  <p className="readonly-text font-weight-bold">
                    {relatedDate?.created
                      ? dayjs(relatedDate?.created).format(
                        userData?.tenant_info?.date_format,
                      )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated</label>
                  <p className="readonly-text font-weight-bold">
                    {relatedDate?.updated
                      ? dayjs(relatedDate?.updated).format(
                        userData?.tenant_info?.date_format,
                      )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="w-100" />
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Created By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {relatedDate?.created_by ? relatedDate?.created_by : '-'}
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="program_">Updated By</label>
                  <p className="readonly-text font-weight-bold text-capitalize">
                    {relatedDate?.updated_by ? relatedDate?.updated_by : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <CancelModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            cancelTitle={cancelTitle}
            func={stepReset}
          />
          {!readMode && (
            <StepFooter
              isLoading={isLoading}
              isError={isError}
              steps={steps}
              activeStep={activeStep}
              handleBack={handleBack}
              setModalShow={setModalShow}
              componentHandler={submit}
              isDraft
              twinMode
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerTwinReview;
