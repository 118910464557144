/* eslint-disable import/prefer-default-export */
// import html2pdf from 'html2pdf.js';
import html2pdf from 'html2pdf.js';

export const printToPDF = (id, fileName) => {
  const domElement = document.getElementById(id);
  const opt = {
    margin: 15,
    filename: fileName,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2, letterRendering: true },
    jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  };
  html2pdf().from(domElement).set(opt).save();
};
