/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TablePeople from '../../../components/common/TablePeople';
import { useGetAllPeopleQuery } from '../../../services/services';
import { addPeople } from '../../../services/peopleSlice';
import { addCorrectionResPeople } from '../../../services/issueSlice';

function CorrectionResponsiblePeople() {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('people');
  const dispatch = useDispatch();
  const tableData = useGetAllPeopleQuery();

  const correctionEditMode = useSelector((state) => state.issueData.correctionEditMode);
  const correctionResPeople = useSelector((state) => state.issueData.correctionResPeople);
  console.log('correctionResPeople', correctionResPeople);

  const [resPerson, setResPerson] = useState(false);
  const [resPersonData, setResPersonData] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (correctionEditMode && correctionResPeople && Array.isArray(correctionResPeople) && correctionResPeople?.length > 0) {
      setResPerson(true);
      setResPersonData(correctionResPeople);
    }
  }, [correctionEditMode]);

  useEffect(() => {
    if (tableData.isSuccess) {
      setDataTable([...tableData.data.tableData]);
    }
  }, [tableData]);

  const checkHandler = (e, d) => {
    e.stopPropagation();
    setResPerson(true);
    if (e.target.checked) {
      setResPersonData([d]);
      dispatch(addCorrectionResPeople([d]));
    } else {
      setResPersonData([]);
      dispatch(addCorrectionResPeople([]));
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    setResPersonData(resPersonData?.filter((value) => value.id !== name.id));
    dispatch(
      addCorrectionResPeople(resPersonData?.filter((value) => value.id !== name.id)),
    );
  };

  const getCheckedVal = (nameVal) => {
    if (resPerson && resPersonData.some((el) => el.id === nameVal)) return true;
    return false;
  };

  return (
    <div className="mb-2">
      <h2>Responsible People</h2>
      <div className="row add-people">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="program_subj">
              Responsible
              <sup>*</sup>
              <span className="people-icon owner-icon">RP</span>
            </label>
            <div
              className="form-control"
              type="text"
              name="subject_name"
              id="program_subj"
            >
              <div className="md-chips">
                {resPersonData
                  && resPersonData?.length > 0
                  && Array.isArray(resPersonData)
                  && resPersonData.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span className="text-capitalize">
                        {`${
                          val?.first_name || ''
                        } ${val?.last_name || ''}`}

                      </span>
                      <button
                        aria-label="remove-stcom"
                        type="button"
                        className="md-chip-remove"
                        onClick={(e) => removeHandler(e, 'resp_person', val)}
                      >
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Select People</h2>
      <TablePeople
        checkHandler={checkHandler}
        userData={dataTable}
        getCheckedVal={getCheckedVal}
        // selVal={selVal}
        resPersonData={resPersonData}
        tableMessage={t('people:table_message')}
      />
    </div>
  );
}

const CorrectionPeople = React.memo(CorrectionResponsiblePeople);
export default CorrectionPeople;
