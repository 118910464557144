/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { addCapabilities, addProcessCapabilities } from '../../services/applicationSlice';
import PaginationComponenet from '../common/Pagination';
import useSearchFilter from '../../hooks/useSearchFilter';
import { addBusinessCaps } from '../../services/businessSlice';
import { addVendorCaps } from '../../services/vendorSlice';
import { addCustomerCaps } from '../../services/customerSlice';
import { addProductCaps } from '../../services/productSlice';

function ExpandCardElement({
  capsInfo,
  appCapsData,
  businessCapsData,
  vendorCapsData,
  customerCapsData,
  productCapsData,
  processCapsData,
  tableData,
  setExpanded,
  dispatch,
  size,
  position,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const [appCapabilitiesData, setAppCapabilitiesData] = useState(appCapsData);
  const [processCapabilitiesData, setProcessCapabilitiesData] = useState(processCapsData);
  const [businessCapabilitiesData, setBusinessCapabilitiesData] = useState(businessCapsData);
  const [vendorCapabilitiesData, setVendorCapabilitiesData] = useState(vendorCapsData);
  const [customerCapabilitiesData, setCustomerCapabilitiesData] = useState(customerCapsData);
  const [productCapabilitiesData, setProductCapabilitiesData] = useState(productCapsData);

  const [TBLData, setTBLData] = useState(tableData || []);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const { filter, setFilter, filterArray } = useSearchFilter(tableData || []);
  useEffect(() => {
    if (tableData && Array.isArray(tableData) && tableData?.length > 0) {
      setTotalCount(tableData.length);
    } else {
      setTotalCount(0);
    }
  }, [tableData]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (tableData && Array.isArray(tableData) && tableData?.length > 10) {
      const reversedArray = [];
      tableData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (tableData && Array.isArray(tableData) && tableData?.length > 0) {
      const reversedArray = [];
      tableData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [tableData, currentPage]);

  const closeExpand = (e) => {
    e.preventDefault();
    const $this = document.getElementById('fsm_actual');
    $this.style.height = size.height;
    $this.style.width = size.width;
    $this.style.top = `${position.top}px`;
    $this.style.left = `${position.left}px`;
    $this.style.margin = '0';
    $this.classList.add('shrinking');

    setTimeout(() => {
      const { classList } = $this;
      while (classList.length > 0) {
        classList.remove(classList.item(0));
      }
      $this.style = '';
      setExpanded(false);
    }, 1000);
  };

  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (capsInfo?.title === 'Application' && Array.isArray(appCapabilitiesData)) {
      if (appCapabilitiesData.some((v) => v.id === d.id)) {
        setAppCapabilitiesData(appCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setAppCapabilitiesData([...appCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Process' && Array.isArray(processCapabilitiesData)) {
      if (processCapabilitiesData.some((v) => v.id === d.id)) {
        setProcessCapabilitiesData(processCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setProcessCapabilitiesData([...processCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Business' && Array.isArray(businessCapabilitiesData)) {
      if (businessCapabilitiesData.some((v) => v.id === d.id)) {
        setBusinessCapabilitiesData(businessCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setBusinessCapabilitiesData([...businessCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Vendor' && Array.isArray(vendorCapabilitiesData)) {
      if (vendorCapabilitiesData.some((v) => v.id === d.id)) {
        setVendorCapabilitiesData(vendorCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setVendorCapabilitiesData([...vendorCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Customer' && Array.isArray(customerCapabilitiesData)) {
      if (customerCapabilitiesData.some((v) => v.id === d.id)) {
        setCustomerCapabilitiesData(customerCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setCustomerCapabilitiesData([...customerCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Product' && Array.isArray(productCapabilitiesData)) {
      if (productCapabilitiesData.some((v) => v.id === d.id)) {
        setProductCapabilitiesData(productCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setProductCapabilitiesData([...productCapabilitiesData, d]);
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (capsInfo?.title === 'Application') {
      if (Array.isArray(appCapabilitiesData)) return setAppCapabilitiesData(appCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Process') {
      if (Array.isArray(processCapabilitiesData)) return setProcessCapabilitiesData(processCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Business') {
      if (Array.isArray(businessCapabilitiesData)) return setBusinessCapabilitiesData(businessCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Vendor') {
      if (Array.isArray(vendorCapabilitiesData)) return setVendorCapabilitiesData(vendorCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Customer') {
      if (Array.isArray(customerCapabilitiesData)) return setCustomerCapabilitiesData(customerCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Product') {
      if (Array.isArray(productCapabilitiesData)) return setProductCapabilitiesData(productCapabilitiesData.filter((value) => value.id !== name.id));
    }
  };

  const getCheckedVal = (nameVal) => {
    if (capsInfo?.title === 'Application') {
      if (appCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Process') {
      if (processCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Business') {
      if (businessCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Vendor') {
      if (vendorCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Customer') {
      if (customerCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Product') {
      if (productCapabilitiesData.some((el) => el.id === nameVal)) return true;
    }
    return false;
  };

  const OnSelect = (e) => {
    e.preventDefault();
    if (capsInfo?.title === 'Application' && Array.isArray(appCapabilitiesData)) {
      dispatch(addCapabilities(appCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        unique_id: ele.unique_id,
        owners: ele.owners,
        managers: ele.managers,
        teams: ele.teams,
        status: ele.status,
        delivery_date: ele?.delivery_date,
        cancelabled: ele?.cancelabled,
      }))));
    } else if (capsInfo?.title === 'Process' && Array.isArray(processCapabilitiesData)) {
      dispatch(addProcessCapabilities(processCapabilitiesData?.map((ele) => ({
        id: ele?.id,
        unique_id: ele?.unique_id,
        name: ele.name,
        step_mappings: ele.step_mappings,
        status: ele.status,
        owners: ele.owners,
        managers: ele.managers,
        teams: ele?.teams,
        delivery_date: ele?.delivery_date,
      }))));
    } else if (capsInfo?.title === 'Business' && Array.isArray(businessCapabilitiesData)) {
      dispatch(addBusinessCaps(businessCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        unique_id: ele.unique_id,
        owners: ele.owners,
        managers: ele.managers,
        teams: ele.teams,
        status: ele.status,
        delivery_date: ele?.delivery_date,
        cancelabled: ele?.cancelabled,
      }))));
    } else if (capsInfo?.title === 'Vendor' && Array.isArray(vendorCapabilitiesData)) {
      dispatch(addVendorCaps(vendorCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        unique_id: ele.unique_id,
        owners: ele.owners,
        managers: ele.managers,
        teams: ele.teams,
        status: ele.status,
        delivery_date: ele?.delivery_date,
        cancelabled: ele?.cancelabled,
      }))));
    } else if (capsInfo?.title === 'Customer' && Array.isArray(customerCapabilitiesData)) {
      dispatch(addCustomerCaps(customerCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        unique_id: ele.unique_id,
        owners: ele.owners,
        managers: ele.managers,
        teams: ele.teams,
        status: ele.status,
        delivery_date: ele?.delivery_date,
        cancelabled: ele?.cancelabled,
      }))));
    } else if (capsInfo?.title === 'Product' && Array.isArray(productCapabilitiesData)) {
      dispatch(addProductCaps(productCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        unique_id: ele.unique_id,
        owners: ele.owners,
        managers: ele.managers,
        teams: ele.teams,
        status: ele.status,
        delivery_date: ele?.delivery_date,
        cancelabled: ele?.cancelabled,
      }))));
    }
    closeExpand(e);
  };

  useEffect(() => {
    if (tableData && Array.isArray(tableData) && tableData?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [tableData, filter, filterArray]);

  return (
    <div className="card">
      <h3 className="card-header">
        <span className={capsInfo?.icon} />
        {capsInfo?.title}
        <a href="#!" className="edit-card" onClick={(e) => closeExpand(e)}>
          <span className="icon-close" />
        </a>
      </h3>
      <div className="card-body">
        <h5 className="font-weight-normal">
          Select
          {' '}
          {capsInfo?.title}
        </h5>
        <div className="chip-wrapper">
          <div className="md-chips">
            {capsInfo?.title === 'Application'
              && (appCapabilitiesData && appCapabilitiesData.length > 0 && Array.isArray(appCapabilitiesData)) && appCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  {!val?.cancelabled
                  && <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>}
                </div>
            ))}

            {capsInfo?.title === 'Process'
              && (processCapabilitiesData && processCapabilitiesData.length > 0 && Array.isArray(processCapabilitiesData)) && processCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>
                </div>
            ))}

            {capsInfo?.title === 'Business'
              && (businessCapabilitiesData && businessCapabilitiesData.length > 0 && Array.isArray(businessCapabilitiesData)) && businessCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  {!val?.cancelabled
                  && <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>}
                </div>
            ))}

            {capsInfo?.title === 'Vendor'
              && (vendorCapabilitiesData && vendorCapabilitiesData.length > 0 && Array.isArray(vendorCapabilitiesData)) && vendorCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  {!val?.cancelabled
                  && <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>}
                </div>
            ))}

            {capsInfo?.title === 'Customer'
              && (customerCapabilitiesData && customerCapabilitiesData.length > 0 && Array.isArray(customerCapabilitiesData)) && customerCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  {!val?.cancelabled
                  && <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>}
                </div>
            ))}

            {capsInfo?.title === 'Product'
              && (productCapabilitiesData && productCapabilitiesData.length > 0 && Array.isArray(productCapabilitiesData)) && productCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  {!val?.cancelabled
                  && <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>}
                </div>
            ))}

          </div>
        </div>
        {capsInfo?.title === 'Application'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th className="sm-col">Status</th>
                </tr>
                <tr className="filter-row">
                  <th className="check-field">&nbsp;</th>
                  <th>
                    <input type="search" className="form-control" name="name" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="owners" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="managers" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="status" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                && TBLData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(ele?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: ele.id,
                            name: ele.name,
                            unique_id: ele.unique_id,
                            owners: ele.owners,
                            managers: ele.managers,
                            teams: ele.teams,
                            status: ele.status,
                            delivery_date: ele?.delivery_date,
                          })}
                          value={ele?.name}
                          id={ele?.id}
                          disabled={appCapabilitiesData?.length > 0 && Array.isArray(appCapabilitiesData) && appCapabilitiesData?.some((sc) => sc?.id === ele?.id && sc?.cancelabled)}
                        />
                        <label
                          data-test-id="select-appCaps-test-checkBox"
                          className="custom-control-label"
                          htmlFor={ele?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td>
                      <Button href={`/review/application/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                        {ele?.name}
                      </Button>
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="sm-col">{ele?.status || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
          </div>
          )}

        {capsInfo?.title === 'Process'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th>Status</th>
                </tr>
                <tr className="filter-row">
                  <th className="check-field">&nbsp;</th>
                  <th>
                    <input type="search" className="form-control" name="name" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="owners" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="managers" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" disabled placeholder="Search" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                && TBLData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(ele?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: ele?.id,
                            unique_id: ele?.unique_id,
                            name: ele.name,
                            step_mappings: ele.step_mappings,
                            status: ele.status,
                            owners: ele.owners,
                            managers: ele.managers,
                            teams: ele?.teams,
                            delivery_date: ele?.delivery_date,
                          })}
                          value={ele?.name}
                          id={ele?.id}
                          disabled={processCapabilitiesData?.length > 0 && Array.isArray(processCapabilitiesData) && processCapabilitiesData?.some((sc) => sc?.id === ele?.id && sc?.cancelabled)}
                        />
                        <label
                          data-test-id="select-processCaps-test-checkBox"
                          className="custom-control-label"
                          htmlFor={ele?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td>
                      <Button href={`/review/process/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                        {ele?.name}
                      </Button>
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers && ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td>{(ele?.status || '-')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
          </div>
          )}

        {capsInfo?.title === 'Business'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th className="sm-col">Status</th>
                </tr>
                <tr className="filter-row">
                  <th className="check-field">&nbsp;</th>
                  <th>
                    <input type="search" className="form-control" name="name" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="owners" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="managers" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="status" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                && TBLData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(ele?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: ele.id,
                            name: ele.name,
                            unique_id: ele.unique_id,
                            owners: ele.owners,
                            managers: ele.managers,
                            teams: ele.teams,
                            status: ele.status,
                            delivery_date: ele?.delivery_date,
                          })}
                          value={ele?.name}
                          id={ele?.id}
                          disabled={businessCapabilitiesData?.length > 0 && Array.isArray(businessCapabilitiesData) && businessCapabilitiesData?.some((sc) => sc?.id === ele?.id && sc?.cancelabled)}
                        />
                        <label
                          data-test-id="select-appCaps-test-checkBox"
                          className="custom-control-label"
                          htmlFor={ele?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td>
                      <Button href={`/review/business/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                        {ele?.name}
                      </Button>
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="sm-col">{ele?.status || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
          </div>
          )}

        {capsInfo?.title === 'Vendor'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th className="sm-col">Status</th>
                </tr>
                <tr className="filter-row">
                  <th className="check-field">&nbsp;</th>
                  <th>
                    <input type="search" className="form-control" name="name" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="owners" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="managers" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="status" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                && TBLData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(ele?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: ele.id,
                            name: ele.name,
                            unique_id: ele.unique_id,
                            owners: ele.owners,
                            managers: ele.managers,
                            teams: ele.teams,
                            status: ele.status,
                            delivery_date: ele?.delivery_date,
                          })}
                          value={ele?.name}
                          id={ele?.id}
                          disabled={vendorCapabilitiesData?.length > 0 && Array.isArray(vendorCapabilitiesData) && vendorCapabilitiesData?.some((sc) => sc?.id === ele?.id && sc?.cancelabled)}
                        />
                        <label
                          data-test-id="select-appCaps-test-checkBox"
                          className="custom-control-label"
                          htmlFor={ele?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td>
                      <Button href={`/review/vendor/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                        {ele?.name}
                      </Button>
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="sm-col">{ele?.status || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
          </div>
          )}

        {capsInfo?.title === 'Customer'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th className="sm-col">Status</th>
                </tr>
                <tr className="filter-row">
                  <th className="check-field">&nbsp;</th>
                  <th>
                    <input type="search" className="form-control" name="name" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="owners" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="managers" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="status" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                && TBLData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(ele?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: ele.id,
                            name: ele.name,
                            unique_id: ele.unique_id,
                            owners: ele.owners,
                            managers: ele.managers,
                            teams: ele.teams,
                            status: ele.status,
                            delivery_date: ele?.delivery_date,
                          })}
                          value={ele?.name}
                          id={ele?.id}
                          disabled={customerCapabilitiesData?.length > 0 && Array.isArray(customerCapabilitiesData) && customerCapabilitiesData?.some((sc) => sc?.id === ele?.id && sc?.cancelabled)}
                        />
                        <label
                          data-test-id="select-appCaps-test-checkBox"
                          className="custom-control-label"
                          htmlFor={ele?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td>
                      <Button href={`/review/customer/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                        {ele?.name}
                      </Button>
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="sm-col">{ele?.status || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
          </div>
          )}

        {capsInfo?.title === 'Product'
          && (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Manager</th>
                  <th className="sm-col">Status</th>
                </tr>
                <tr className="filter-row">
                  <th className="check-field">&nbsp;</th>
                  <th>
                    <input type="search" className="form-control" name="name" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="owners" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="managers" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                  <th>
                    <input type="search" className="form-control" name="status" placeholder="Search" onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                && TBLData?.map((ele) => (
                  <tr key={ele?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(ele?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: ele.id,
                            name: ele.name,
                            unique_id: ele.unique_id,
                            owners: ele.owners,
                            managers: ele.managers,
                            teams: ele.teams,
                            status: ele.status,
                            delivery_date: ele?.delivery_date,
                          })}
                          value={ele?.name}
                          id={ele?.id}
                          disabled={businessCapabilitiesData?.length > 0 && Array.isArray(businessCapabilitiesData) && businessCapabilitiesData?.some((sc) => sc?.id === ele?.id && sc?.cancelabled)}
                        />
                        <label
                          data-test-id="select-appCaps-test-checkBox"
                          className="custom-control-label"
                          htmlFor={ele?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td>
                      <Button href={`/review/product/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                        {ele?.name}
                      </Button>
                    </td>
                    <td className="text-capitalize">{ele?.owners ? `${ele?.owners[0]?.first_name ? ele?.owners[0]?.first_name : ''} ${ele?.owners[0]?.last_name ? ele?.owners[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="text-capitalize">{ele?.managers ? `${ele?.managers[0]?.first_name ? ele?.managers[0]?.first_name : ''} ${ele?.managers[0]?.last_name ? ele?.managers[0]?.last_name : ''}` || '-' : '-'}</td>
                    <td className="sm-col">{ele?.status || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
          </div>
          )}

      </div>
      <div className="button-wrapper d-flex align-items-center justify-content-end">
        <button type="button" className="btn btn-outline-primary btn-sm btn-rounded" onClick={(e) => closeExpand(e)}>
          Cancel
        </button>
        <button data-test-id="caps-modal-close" type="button" className="btn btn-primary btn-sm btn-rounded" onClick={(e) => OnSelect(e)}>Select</button>
      </div>
    </div>
  );
}

export default ExpandCardElement;
