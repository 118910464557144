import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processVals: {},
  stepVals: {},
  duplicateVals: {},
  processChange: {},
  activeStepComp: {
    listStep: false,
    addStep: false,
    listProcess: false,
    stepReview: false,
    processReview: false,
    middleDetails: {},
  },
  teamsPeopleFromCapb: [],
  processTwin: {},
  processTwinVals: {},
  selectedStep: [],
  currentSteps: [],
  processTwinSteps: [],
  readOnlyProcessSteps: [],
  selectedProcess: [],
  processStepError: false,
  startDesc: '',
  endDesc: '',
  relateDate: null,
  processReadOnlyData: null,
  stepId: '',
  processInputDisable: false,
  processStepInputDisable: false,

};

export const processSlice = createSlice({
  name: 'processSlice',
  initialState,
  reducers: {
    addStartDesc: (state, { payload }) => {
      state.startDesc = payload;
    },
    addEndDesc: (state, { payload }) => {
      state.endDesc = payload;
    },
    addDuplicateVals: (state, { payload }) => {
      state.duplicateVals = payload;
    },
    addProcessVals: (state, { payload }) => {
      state.processVals = payload;
    },
    addProcessTwinVals: (state, { payload }) => {
      state.processTwinVals = payload;
    },
    addProcessTwin: (state, { payload }) => {
      state.processTwin = payload;
    },
    addStepId: (state, { payload }) => {
      state.stepId = payload;
    },
    toggleProcessComponent: (state, { payload }) => {
      state.activeStepComp = payload;
    },
    addCurrentSteps: (state, { payload }) => {
      state.currentSteps = payload;
    },
    addProcessTwinStep: (state, { payload }) => {
      state.processTwinSteps = payload;
    },
    addReadOnlyProcessSteps: (state, { payload }) => {
      state.readOnlyProcessSteps = payload;
    },
    addSelectedProcess: (state, { payload }) => {
      state.selectedProcess = payload;
    },
    setTemasPeopleFromCapb: (state, { payload }) => {
      const uniqueTeams = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.teamsPeopleFromCapb = uniqueTeams;
    },
    addStepVals: (state, { payload }) => {
      state.stepVals = payload;
    },
    setSelectedStep: (state, { payload }) => {
      state.selectedStep = payload;
    },
    setSelectedProcess: (state, { payload }) => {
      state.selectedProcess = payload;
    },
    isProcessStepErrors: (state, { payload }) => {
      state.processStepError = payload;
    },
    addProcessStepRalatedDate: (state, { payload }) => {
      state.relateDate = payload;
    },
    addProcessReadOnlyData: (state, { payload }) => {
      state.processReadOnlyData = payload;
    },
    setProcessInputDisabled: (state, { payload }) => {
      state.processInputDisable = payload;
    },
    setProcessStepInputDisabled: (state, { payload }) => {
      state.processStepInputDisable = payload;
    },
  },
  addProcessChange: (state, { payload }) => {
    state.processChange = payload;
  },

});

export const {
  addProcessVals, addProcessChange, toggleProcessComponent, setTemasPeopleFromCapb,
  addSelectedProcess, isProcessStepErrors,
  addStepVals, addCurrentSteps, addProcessTwinStep, addReadOnlyProcessSteps,
  addDuplicateVals, addStartDesc, addEndDesc,
  setSelectedStep, setSelectedProcess, addProcessStepRalatedDate, addProcessTwin,
  addProcessTwinVals, addProcessReadOnlyData, addStepId,
  setProcessInputDisabled, setProcessStepInputDisabled,
} = processSlice.actions;

export default processSlice.reducer;
