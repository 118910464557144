/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-nocheck
import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import {
  useGetBenefitByModeQuery, useGetBenefitByStatusQuery, useGetRiskByModeQuery, useGetRiskByStatusQuery,
} from '../../services/services';
import { addRisk, addRiskVals } from '../../services/riskSlice';
import { addBenefit } from '../../services/benefitSlice';
import PaginationComponenet from '../common/Pagination';
import useSearchFilter from '../../hooks/useSearchFilter';
import ReadMoreLess from '../common/ReadMoreLess';

function Dependencies({ label }):JSX.Element {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('transformation_object');
  const dispatch = useDispatch();
  const [tableShow, setTableShow] = useState(false);
  const riskTableData = useGetRiskByStatusQuery({ status: 'Active' });
  const benefitTableData = useGetBenefitByStatusQuery({ status: 'Active' });
  const riskData = useSelector((state) => state.riskData.selectedRisk);
  const benefitData = useSelector((state) => state.benefitData.selectedBenefit);
  const editMode = useSelector((state) => state.editData.editMode);
  const draftMode = useSelector((state) => state.formJson.draftMode);
  const benefitVals = useSelector((state) => state.benefitData.benefitValues);
  const riskVals = useSelector((state) => state.riskData.riskValues);

  const [selectedRiskData, setSelectedRiskData] = useState([]);
  const [selectedBenefitData, setSelectedBenefitData] = useState([]);
  const [header, setHeader] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  const [TBLData, setTBLData] = useState(dataTable || []);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 5;
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0) {
      setTotalCount(dataTable.length);
    } else {
      setTotalCount(0);
    }
  }, [dataTable]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 5) {
      const reversedArray = [];
      dataTable?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0) {
      const reversedArray = [];
      dataTable?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [dataTable, currentPage]);

  const { filter, setFilter, filterArray } = useSearchFilter(dataTable || []);

  useEffect(() => {
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [dataTable, filter, filterArray]);

  useEffect(() => {
    if ((riskData && riskData?.length > 0 && Array.isArray(riskData))) {
      setSelectedRiskData(riskData.map((el) => ({
        id: el?.id,
        name: el?.name,
        strategy: el?.strategy,
        category: el?.category,
        source: el?.source,
        status: el?.status,
        risk_product: el?.risk_product,
        description: el?.description,
        responsibility_id: el?.responsibility_id,
      })));
    }
    if ((benefitData && benefitData?.length > 0 && Array.isArray(benefitData))) {
      setSelectedBenefitData(benefitData.map((el) => ({
        id: el?.id,
        name: el?.name,
        realisation_start_date: el?.realisation_start_date,
        realisation_completion_date: el?.realisation_completion_date,
        status: el?.status,
        managers: el?.managers,
        owners: el?.owners,
        description: el?.description,
      })));
    }
  }, [riskData, benefitData]);

  useEffect(() => {
    if (label === 'benefit') {
      setHeader(
        [
          { type: '', field: 'name', name: 'Name' },
          { type: '', field: 'description', name: 'Description' },
          { type: '', field: 'owners', name: 'Owner' },
          { type: '', field: 'managers', name: 'Manager' },
          { type: 'date', field: 'realisation_completion_date', name: 'Realization Completion Date' },
          { type: '', field: 'status', name: 'Status' },
        ],
      );
      if (editMode && !draftMode && benefitTableData.isSuccess && benefitTableData?.data?.length > 0) {
        setDataTable(benefitTableData?.data.filter((pd) => pd.id !== benefitVals.id));
      } else if (benefitTableData.isSuccess && benefitTableData?.data?.length > 0) {
        setDataTable([...benefitTableData.data]);
      }
    }
    if (label === 'risk') {
      setHeader(
        [
          { type: '', field: 'name', name: 'Name' },
          { type: '', field: 'description', name: 'Description' },
          { type: '', field: 'responsibility_id', name: 'Responsible' },
          { type: '', field: 'risk_product', name: 'Risk Product' },
          { type: '', field: 'status', name: 'Status' },
        ],
      );
      if (riskTableData.isSuccess && riskTableData?.data?.length > 0 && editMode && !draftMode) {
        setDataTable(riskTableData.data.filter((rd) => rd.id !== riskVals.id));
      } else if (riskTableData.isSuccess && riskTableData?.data?.length > 0) {
        setDataTable([...riskTableData.data]);
      }
    }
  }, [label, benefitTableData.isSuccess, riskTableData.isSuccess]);

  const checkHandler = (e, d, value) => {
    e.stopPropagation();
    if (value === 'risk') {
      if (selectedRiskData?.some((v) => v.id === d.id)) {
        setSelectedRiskData(selectedRiskData?.filter((val) => val.id !== d.id));
        dispatch(addRisk(selectedRiskData?.filter((val) => val.id !== d.id)));
      } else {
        setSelectedRiskData([...selectedRiskData, d]);
        dispatch(addRisk([...selectedRiskData, d]));
      }
    }
    if (value === 'benefit') {
      if (selectedBenefitData?.some((v) => v.id === d.id)) {
        setSelectedBenefitData(selectedBenefitData?.filter((val) => val.id !== d.id));
        dispatch(addBenefit(selectedBenefitData?.filter((val) => val.id !== d.id)));
      } else {
        setSelectedBenefitData([...selectedBenefitData, d]);
        dispatch(addBenefit([...selectedBenefitData, d]));
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (val === 'risk') {
      setSelectedRiskData(selectedRiskData?.filter((value) => value.id !== name.id));
      dispatch(addRisk(selectedRiskData?.filter((value) => value.id !== name.id)));
    }
    if (val === 'benefit') {
      setSelectedBenefitData(selectedBenefitData?.filter((value) => value.id !== name.id));
      dispatch(addBenefit(selectedBenefitData?.filter((value) => value.id !== name.id)));
    }
  };

  const getCheckedVal = (nameVal) => {
    if (label === 'risk' && selectedRiskData?.some((el) => el.id === nameVal)) return true;
    if (label === 'benefit' && selectedBenefitData?.some((el) => el.id === nameVal)) return true;
    return false;
  };

  return (
    <>
      <h2>
        {t(`depenedent_${label}`)}
      </h2>
      <div className="row add-people">
        <div className="col-12">
          <div className="form-group mb-0">
            <label htmlFor="program_subj">
              {t(`selected_depenedent_${label}`)}
            </label>
            <div
              className="chip-wrapper"
            >
              <div className="md-chips">
                {(label === 'benefit' && selectedBenefitData && selectedBenefitData?.length > 0 && Array.isArray(selectedBenefitData))
                && selectedBenefitData?.map((val) => (
                  <div key={val.id} className="md-chip">
                    <span>{`${val.name}`}</span>
                    <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, label, val)}>
                      <span className="icon-close" />
                    </button>
                  </div>
                ))}
                {(label === 'risk' && selectedRiskData && selectedRiskData?.length > 0 && Array.isArray(selectedRiskData))
                && selectedRiskData?.map((val) => (
                  <div key={val.id} className="md-chip">
                    <span>{`${val.name}`}</span>
                    <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, label, val)}>
                      <span className="icon-close" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive box-shadow">
        <table className="table table-hover">
          <thead>
            <tr className="heading-row">
              <th className="check-field">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="selectAll"
                    disabled
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="selectAll"
                  />
                </div>
              </th>
              {(header && header?.length > 0 && Array.isArray(header))
              && header.map((ele) => (
                <th key={label + ele.name}>{ele?.name}</th>
              ))}
            </tr>
            <tr className="filter-row">
              <th>&nbsp;</th>
              {(header && header?.length > 0 && Array.isArray(header))
              && header.map((ele) => (
                <th key={label + ele.name}>
                  {ele?.type === '' && (
                  <input
                    type={ele?.field === 'risk_product' ? 'number' : 'search'}
                    className="form-control"
                    placeholder="Search"
                    name={ele?.field}
                    disabled={ele?.field !== 'name' && ele?.field !== 'status' && ele?.field !== 'risk_product' && ele?.field !== 'description'}
                    onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                  />
                  )}
                  {ele?.type === 'date' && (
                  <DatePicker
                    className="form-control"
                    placeholderText="DD/MM/YYYY"
                    portalId="root-portal"
                    disabled={ele?.type === 'date'}
                  />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
              ? TBLData?.map((td) => (
                <tr key={label + td.id}>
                  <td className="check-field">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={getCheckedVal(td.id)}
                        onChange={(e) => checkHandler(e, td, label)}
                        value={td.name}
                        id={td.id}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={td.id}
                        aria-label="select"
                      />
                    </div>
                  </td>
                  {label === 'risk' && (header && header?.length > 0 && Array.isArray(header))
                && header.map((th) => (
                  (th.field === 'programs' || th.field === 'initiatives' || th.field === 'changes')
                    ? (
                      <td key={label + th.field}>
                        {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field]))
                          ? td[th.field]?.map((pic, inx) => (
                            <Fragment key={pic?.name}>
                              {pic?.name}
                              {inx === td[th.field].length - 1 ? '' : ',  '}
                            </Fragment>
                          ))
                          : '-'}
                      </td>
                    )
                    : (th.field === 'name')
                      ? (
                        <td key={label + th.field}>
                          <Button href={`/review/${label}/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                            {td[th.field]}
                          </Button>
                        </td>
                      ) : th.field === 'responsibility_id'
                        ? <td className="text-capitalize" key={label + th.field}>{`${`${td[th.field]?.first_name || ''} ${td[th.field]?.last_name || ''}`}` || '-'}</td>
                        : (th?.field === 'description' || th?.type === 'textarea') && td[th?.field] ? (
                          <td key={th?.field}>
                            <ReadMoreLess text={td[th?.field]} />
                          </td>
                        )
                          : <td key={label + th.field}>{td[th.field] || '-'}</td>
                ))}
                  {label === 'benefit' && (header && header?.length > 0 && Array.isArray(header))
                && header.map((th) => (
                  (th.field === 'id' || th.field === 'name')
                    ? (
                      <td key={label + th.field}>
                        <Button href={`/review/${label}/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>
                          {td[th.field] || '-'}
                        </Button>
                      </td>
                    )
                    : (th.field === 'owner' || th.field === 'owners' || th.field === 'manager' || th.field === 'managers')
                      ? (
                        <td key={label + th.field} className="text-capitalize">
                          {(td[th?.field] && Array.isArray(td[th?.field]) && td[th?.field]?.length > 0)
                            ? td[th.field]?.map((mn) => (
                              <Fragment key={mn?.id}>{mn ? `${mn?.first_name || ''}  ${mn?.last_name || ''}` : '-'}</Fragment>
                            )) : '-'}
                        </td>
                      )
                      : (th?.field === 'description' || th?.type === 'textarea') && td[th?.field] ? (
                        <td key={th?.field}>
                          <ReadMoreLess text={td[th?.field]} />
                        </td>
                      )
                        : (th.type === 'date' && td[th.field]) ? <td key={label + th.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td> : <td key={label + th.field}>{td[th.field] || '-'}</td>
                ))}
                </tr>
              )) : <tr><td colSpan={(header && header?.length > 0 && Array.isArray(header)) ? header?.length : 0} className="text-center">No Data To Display</td></tr>}
          </tbody>
        </table>
        {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
          && (
          <PaginationComponenet
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
            TBLData={TBLData}
          />
          )}
      </div>
      <hr />
    </>
  );
}

export default Dependencies;
