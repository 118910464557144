import React, { useEffect, useState } from 'react';

function useDebounce(value, delay) {
  const [debouceVal, setDebounceVal] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVal(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouceVal;
}

export default useDebounce;
