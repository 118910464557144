/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requirementValues: {},
  selectedRequirement: [],
  dependentRequirement: [],
  selectedEpicRequirement: [],
  selectedTwinRequirement: [],
  requirementCollaborator: [
    {
      key: 'process',
      value: [],
    },
    {
      key: 'application',
      value: [],
    },
    {
      key: 'business',
      value: [],
    },
    {
      key: 'vendor',
      value: [],
    },
    {
      key: 'customer',
      value: [],
    },
    {
      key: 'product',
      value: [],
    },
  ],
  reqEnhancement: [],
};

export const requirementSlice = createSlice({
  name: 'requirement',
  initialState,
  reducers: {
    addRequirementVals: (state, { payload }) => {
      state.requirementValues = payload;
    },
    addSelectedRequirement: (state, { payload }) => {
      state.selectedRequirement = payload;
    },
    addDependentRequirement: (state, { payload }) => {
      state.dependentRequirement = payload;
    },
    addSelectedEpicRequirement: (state, { payload }) => {
      state.selectedEpicRequirement = payload;
    },
    addSelectedTwinRequirement: (state, { payload }) => {
      state.selectedTwinRequirement = payload;
    },
    addRequirementCollaborator: (state, { payload }) => {
      console.log('payload', payload);
      const payload1 = payload?.map((el) => el?.value)?.flat()?.filter((el) => el !== undefined);
      const payload2 = payload1?.filter(
        (obj, index) => payload1?.findIndex((item) => item?.key === obj?.key && item?.id === obj?.id) === index,
      );
      state.requirementCollaborator = state?.requirementCollaborator?.map((el) => ({ ...el, value: payload2?.filter((rc) => rc?.key === el?.key) }));
    },
    addReqEnhancement: (state, { payload }) => {
      state.reqEnhancement = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addRequirementVals,
  addSelectedRequirement,
  addDependentRequirement,
  addSelectedEpicRequirement,
  addRequirementCollaborator,
  addSelectedTwinRequirement,
  addReqEnhancement,
} = requirementSlice.actions;

export default requirementSlice.reducer;
