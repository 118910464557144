/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
import StepFooter from '../common/StepFooter';
import CancelModal from '../common/CancelModal';
import RelatedSelect from './RelatedSelect';
import Dependencies from '../dependencies/Dependent';
import Transformation from '../dependencies/Transformation';
import CustomForm from './CustomForm';
import useForm from '../../hooks/useForm';
import { validateForm, validateFields } from '../common/FormValidation';

// eslint-disable-next-line no-dupe-args
function FormBuilder({
  label,
  labelTwin,
  steps,
  activeStep,
  handleBack,
  handleNext,
  onNext,
  template,
  dfVals,
  editMode,
  modalShow,
  setModalShow,
  stepReset,
  relatedData,
  cancelTitle,
  isLoading,
  twinMode,
  resRelatedObjects,
}) {
  const {
    values,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    setValues,
    clearFormState,
    isChangeRequire,
  } = useForm(
    onNext,
    validateForm,
    validateFields,
    template.fields,
    dfVals,
    stepReset,
    editMode,
    label,
    t,
  );

  return (
    <div className="container-fluid">
      <div className="form-unit">
        {(template?.fields?.length > 0 && template?.sections?.length > 0)
        && (
        <form className="add-program-form">
          <CustomForm
            isLoading={isLoading}
            label={label}
            twinMode={twinMode}
            fields={template.fields}
            sections={template.sections}
            t={t}
            labelTwin={labelTwin}
            errors={errors}
            setErrors={setErrors}
            values={values}
            handleChange={handleChange}
            editMode={editMode}
            setValues={setValues}
            isChangeRequire={isChangeRequire}
          />
          {label === 'issue'

          && ((resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_initiatives && resRelatedObjects?.data?.related_initiatives?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_initiatives))
           || (resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_changes && resRelatedObjects?.data?.related_changes?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_changes))
           || (resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_epics && resRelatedObjects?.data?.related_epics?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_epics))
          )
          && (
          <>
            <h2>Related Object</h2>
            <div className="row add-people">
              {(resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_initiatives && resRelatedObjects?.data?.related_initiatives?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_initiatives))
                  && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="program_subj">
                        Project
                      </label>
                      <div
                        className="form-control"
                        type="text"
                        name="subject_name"
                        id="program_subj"
                      >
                        <div className="md-chips">

                          {(resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_initiatives && resRelatedObjects?.data?.related_initiatives?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_initiatives))
                  && resRelatedObjects?.data?.related_initiatives?.map((dt) => (
                    <div key={dt.id} className="md-chip">
                      <span>{`${dt.name}`}</span>

                    </div>
                  ))}

                        </div>
                      </div>
                    </div>
                  </div>
                  )}
              {(resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_changes && resRelatedObjects?.data?.related_changes?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_changes))

              && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="program_subj">
                    Change
                  </label>
                  <div
                    className="form-control"
                    type="text"
                    name="subject_name"
                    id="program_subj"
                  >
                    <div className="md-chips">

                      {(resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_changes && resRelatedObjects?.data?.related_changes?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_changes))

                  && resRelatedObjects?.data?.related_changes?.map((dt) => (
                    <div key={dt.id} className="md-chip">
                      <span>{`${dt.name}`}</span>
                    </div>
                  ))}
                    </div>
                  </div>
                </div>
              </div>
              )}
              {(resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_epics && resRelatedObjects?.data?.related_epics?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_epics))

&& (
<div className="col-sm-6">
  <div className="form-group">
    <label htmlFor="program_subj">
      Work Package
    </label>
    <div
      className="form-control"
      type="text"
      name="subject_name"
      id="program_subj"
    >
      <div className="md-chips">

        {(resRelatedObjects?.isSuccess && resRelatedObjects?.data?.related_epics && resRelatedObjects?.data?.related_epics?.length > 0 && Array.isArray(resRelatedObjects?.data?.related_epics))

    && resRelatedObjects?.data?.related_epics?.map((dt) => (
      <div key={dt.id} className="md-chip">
        <span>{`${dt.name}`}</span>
      </div>
    ))}
      </div>
    </div>
  </div>
</div>
)}
              <div className="w-100" />
            </div>
          </>
          )}
          {(label === 'risk' || label === 'benefit')
          && (
          <>
            <Dependencies label={label} />
            <Transformation label={label} selecteArr={['program', 'initiative', 'change']} />
          </>
          )}
          {(label === 'change')
          && (
          <Transformation label={label} selecteArr={['program', 'change']} />
          )}
          {(label === 'program' || label === 'initiative')
          && <RelatedSelect label={label} title relatedData={relatedData} />}
          {(label === 'process')
          && (
          <>
            <h2>Related Objects</h2>
            <RelatedSelect label="program" title={false} />
            <RelatedSelect label="initiative" title={false} />
            <RelatedSelect label="process" title={false} />
          </>
          )}
          <StepFooter
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            setModalShow={setModalShow}
            componentHandler={handleSubmit}
          />
          <CancelModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            cancelTitle={cancelTitle}
            func={clearFormState}
          />
        </form>
        )}
      </div>
    </div>
  );
}

export default memo(FormBuilder);
