/* eslint-disable camelcase */
/* eslint-disable no-void */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, {
  useState, SyntheticEvent, useEffect, useRef,
} from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addActiveNav, addIsDirty, removeActiveNav, shiftTabs,
} from '../../services/navSlice';
import { RootState } from '../../services/store';
import useOutsideClick from '../../hooks/useOutsideClick';
import LeaveModal from '../../components/common/LeaveModal';
import DigitalTwinImg from '../../images/dTwin.svg';
import { useLockStatusMutation } from '../../services/services';

const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function Sidenav(): JSX.Element {
  const { t } = useTranslation('dashboard');
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const sideMenuRef = useRef();

  const [lockStatus] = useLockStatusMutation();

  const activeTabss = useSelector(
    (state: RootState) => state.sideNav.activeTabs,
  );
  const navigate = useNavigate();

  const isDirty = useSelector((state) => state.sideNav.isDirty);

  const path = useLocation();

  const [confirms, setConfirms] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');
  const [breadcrumb, setBreadCrumb] = useState('');
  console.log('breadcrumb', breadcrumb);

  const [program, setProgram] = useState(false);
  const [icons, setIcon] = useState('');
  const [singleMenu, setSingleMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [Hide, setHide] = useState('');
  const [pathss, setPathss] = useState(path.pathname);

  useEffect(() => {
    if (
      !userData.permissions.includes('create:program')
      && !userData.permissions.includes('create:project')
      && !userData.permissions.includes('create:change')
      && !userData.permissions.includes('create:risk')
      && !userData.permissions.includes('create:benefit')
      && !userData.permissions.includes('create:epic')
    ) {
      setSingleMenu(true);
      setHide('transform');
    }
    if (
      !userData.permissions.includes('create:process')
      && !userData.permissions.includes('create:application')
    ) {
      setSingleMenu(true);
      setHide('capab');
    }
    if (
      !userData.permissions.includes('create:program')
      && !userData.permissions.includes('create:process')
      && !userData.permissions.includes('create:application')
      && !userData.permissions.includes('create:project')
      && !userData.permissions.includes('create:change')
      && !userData.permissions.includes('create:risk')
      && !userData.permissions.includes('create:benefit')
      && !userData.permissions.includes('create:epic')
    ) {
      setMainMenu(true);
    }
  }, [userData.permissions]);

  // console.table(path);
  const handleSubMenuClick = (e: SyntheticEvent, val: string) => {
    //  e.stopPropagation();
    //  if (val === 'enter') {
    setProgram(true);
    //  } else {
    //    setProgram(false);
    //  }
  };

  useEffect(() => {
    setPathss(path.pathname);
  }, []);

  useOutsideClick(sideMenuRef, () => {
    setProgram(false);
  });

  const handleSubMenu = (e: SyntheticEvent, to: string, icon: string, brdc:string) => {
    e.preventDefault();
    e.stopPropagation();
    setProgram(false);
    const data = { icon, to: to === 'epic' ? 'Work Package' : to };
    if (!isDirty) {
      dispatch({ type: 'RESET_APP' });
      dispatch(addActiveNav(data));
      navigate(`/${to}`, {
        state: {
          to,
          breadcrumb: brdc,
          breadcrumbPath: [brdc],

        },
      });
    } else {
      setNavigationPath(`/${to}`);
      setBreadCrumb(brdc);
      setIcon(icon);
      setModalShow(true);
    }
  };

  const hanldeTemptabs = (data) => {
    dispatch(shiftTabs(data));
    if (!isDirty) {
      dispatch({ type: 'RESET_APP' });
      navigate(`/${data.to}`, {
        state: {
          to: data.to,
        },
      });
    } else {
      setNavigationPath(`/${data.to}`);
      setModalShow(true);
    }
  };

  const handleMainNav = (e, to, brdc) => {
    e.preventDefault();
    e.stopPropagation();
    setProgram(false);
    if (!isDirty) {
      if (location?.pathname !== '/dashboard') {
        dispatch({ type: 'RESET_APP' });
      }
      navigate(to, {
        state: {
          breadcrumb: brdc,
          breadcrumbPath: [brdc],
        },
      });
    } else {
      setNavigationPath(to);
      setBreadCrumb(brdc);
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (confirms) {
      dispatch({ type: 'RESET_APP' });
      setModalShow(false);
      setConfirms(false);
      dispatch(addIsDirty(false));
      const data = { icon: icons, to: navigationPath.replace('/', '') };
      dispatch(addActiveNav(data));
      if (location?.pathname === '/appTwin') {
        lockStatus({
          capability: 'application',
          id: location?.state?.twinId,
          locked: false,
          locked_by: userData?.id,
        });
      } else if (location?.pathname === '/processTwinUpdate') {
        lockStatus({
          capability: 'process',
          id: location?.state?.twinId,
          locked: false,
          locked_by: userData?.id,
        });
      } else if (location?.pathname === '/businessTwin') {
        lockStatus({
          capability: 'business',
          id: location?.state?.twinId,
          locked: false,
          locked_by: userData?.id,
        });
      } else if (location?.pathname === '/vendorTwin') {
        lockStatus({
          capability: 'vendor',
          id: location?.state?.twinId,
          locked: false,
          locked_by: userData?.id,
        });
      } else if (location?.pathname === '/customerTwin') {
        lockStatus({
          capability: 'customer',
          id: location?.state?.twinId,
          locked: false,
          locked_by: userData?.id,
        });
      } else if (location?.pathname === '/productTwin') {
        lockStatus({
          capability: 'product',
          id: location?.state?.twinId,
          locked: false,
          locked_by: userData?.id,
        });
      }
      navigate(navigationPath, {
        state: {
          breadcrumb,
          breadcrumbPath: [breadcrumb],
        },
      });
    }
  }, [confirms]);

  const [tempTabClose, setTempTabClose] = useState({ close: false, to: '', icon: '' });
  const closeRemoveTempTab = ({ to, icon }) => {
    dispatch(removeActiveNav({ to, icon }));
    const res = activeTabss?.filter((at) => at?.icon !== icon);
    if (res && Array.isArray(res) && res?.length) {
      navigate(`/${res[0]?.to}`);
    } else {
      navigate('/dashboard');
    }
  };

  const handleRemoveActiveNav = ({ to, icon }) => {
    if (!isDirty) {
      closeRemoveTempTab({ to, icon });
    } else {
      setTempTabClose({ close: true, to, icon });
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (confirms && tempTabClose?.close) {
      closeRemoveTempTab({ to: tempTabClose?.to, icon: tempTabClose?.icon });
      setTempTabClose({ close: false, to: '', icon: '' });
      setConfirms(false);
    }
  }, [confirms, tempTabClose.close]);

  console.log('activeTabss', activeTabss);

  return (
    <div className="side-nav-wrapper">
      <LeaveModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        func={setConfirms}
      />
      <div className="side-nav primary" ref={sideMenuRef}>
        <ul className="list-unstyled main-menu mb-0">
          <OverlayTrigger placement="right" overlay={renderTooltip('Home')}>
            <li
              className={`text-center ${
                path.pathname === '/dashboard' && 'active'
              }`}
              data-container="body"
              data-toggle="popover"
              data-placement="right"
              data-content="Home"
            >
              <Button
                variant="link"
                size="lg"
                className="text-decoration-none"
                onClick={(e) => handleMainNav(e, '/dashboard', 'Dashbaord')}
              >
                <span className="icon-home" />
              </Button>
            </li>
          </OverlayTrigger>
          {/* Project Portfolio  Start */}
          <OverlayTrigger placement="right" overlay={renderTooltip('Project Portfolio')}>
            <li
              className={`text-center ${
                path.pathname === '/project_portfolio' && 'active'
              }`}
              data-container="body"
              data-toggle="popover"
              data-placement="right"
              data-content="Project Portfolio"
            >
              <Button
                variant="link"
                size="lg"
                className="text-decoration-none"
                onClick={(e) => handleMainNav(e, '/project_portfolio', 'Project Portfolio')}
              >
                <span className="icon-list" style={{ fontSize: '21px' }} />
              </Button>
            </li>
          </OverlayTrigger>

          {/* Project Portfolio  End */}

          {/* Project Portfolio  Start */}
          <OverlayTrigger placement="right" overlay={renderTooltip('Digital Twin')}>
            <li
              className={`text-center ${
                path.pathname === '/digital_twin' && 'active'
              }`}
              data-container="body"
              data-toggle="popover"
              data-placement="right"
              data-content="Digital Twin"
            >
              <Button
                variant="link"
                size="lg"
                className="text-decoration-none"
                onClick={(e) => handleMainNav(e, '/digital_twin', 'Digital Twin')}
              >
                <svg width="25px" height="25px" viewBox="0 0 335 340" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1_11)">
                    <path
                      d="M334.669 150.973V165.548C321.583 165.548 312.414 168.291 307.164 173.776C301.992 179.262 299.406 188.43 299.406 201.281V238.895C299.406 249.709 298.348 259.073 296.233 266.988C294.195 274.902 290.826 281.445 286.124 286.617C281.422 291.789 275.153 295.629 267.317 298.136C259.481 300.644 249.803 301.898 238.284 301.898V278.859C247.374 278.859 254.505 277.41 259.677 274.51C264.927 271.689 268.61 267.34 270.726 261.463C272.92 255.586 274.017 248.063 274.017 238.895V191.878C274.017 185.766 274.801 180.202 276.368 175.187C278.013 170.172 280.991 165.862 285.301 162.257C289.611 158.652 295.723 155.871 303.638 153.912C311.631 151.953 321.974 150.973 334.669 150.973ZM238.284 0.98862C249.803 0.98862 259.481 2.24241 267.317 4.75C275.153 7.25757 281.422 11.0973 286.124 16.2692C290.826 21.441 294.195 27.9842 296.233 35.8988C298.348 43.8133 299.406 53.1775 299.406 63.9915V101.605C299.406 114.456 301.992 123.625 307.164 129.11C312.414 134.595 321.583 137.338 334.669 137.338V151.913C321.974 151.913 311.631 150.934 303.638 148.975C295.723 147.016 289.611 144.234 285.301 140.629C280.991 137.025 278.013 132.715 276.368 127.7C274.801 122.684 274.017 117.121 274.017 111.009V63.9915C274.017 54.8231 272.92 47.3004 270.726 41.4233C268.61 35.4678 264.927 31.0795 259.677 28.2585C254.505 25.4375 247.374 24.027 238.284 24.027V0.98862ZM334.669 137.338V165.548H306.929V137.338H334.669Z"
                      fill={path.pathname === '/digital_twin' ? '#fff' : '#585858'}
                    />
                    <path
                      d="M0.751818 188.725L0.798645 174.15C13.885 174.192 23.0621 171.479 28.3299 166.011C33.5194 160.542 36.1348 151.382 36.1761 138.531L36.2969 100.917C36.3316 90.1034 37.4196 80.7427 39.5608 72.835C41.6236 64.927 45.0142 58.3947 49.7325 53.2379C54.4508 48.0812 60.732 44.2616 68.5762 41.7792C76.4204 39.2968 86.102 38.0742 97.6212 38.1112L97.5471 61.1494C88.4572 61.1202 81.3217 62.547 76.1405 65.4297C70.8812 68.2339 67.1843 72.5711 65.0497 78.4414C62.8367 84.3114 61.7154 91.8306 61.686 100.999L61.5349 148.016C61.5153 154.128 60.7138 159.689 59.1304 164.699C57.4687 169.709 54.4772 174.009 50.1557 177.6C45.8343 181.191 39.7131 183.953 31.7923 185.887C23.7932 187.82 13.4464 188.766 0.751818 188.725ZM96.6544 339.019C85.1353 338.982 75.4617 337.697 67.6336 335.164C59.8055 332.631 53.549 328.772 48.8639 323.585C44.1788 318.398 40.8303 311.844 38.8183 303.923C36.728 296.001 35.7002 286.634 35.735 275.82L35.8558 238.206C35.8971 225.355 33.3406 216.179 28.1864 210.677C22.9538 205.174 13.7944 202.402 0.708012 202.36L0.754839 187.785C13.4494 187.826 23.7899 188.839 31.7765 190.823C39.6847 192.808 45.7879 195.609 50.0862 199.228C54.3845 202.846 57.3484 207.166 58.9779 212.186C60.529 217.206 61.2947 222.772 61.2751 228.885L61.124 275.901C61.0946 285.07 62.1675 292.596 64.3427 298.48C66.4393 304.442 70.1082 308.842 75.3494 311.68C80.5121 314.518 87.6385 315.951 96.7284 315.98L96.6544 339.019ZM0.708012 202.36L0.798645 174.15L28.5386 174.239L28.4479 202.449L0.708012 202.36Z"
                      fill={path.pathname === '/digital_twin' ? '#fff' : '#585858'}
                    />
                    <path d="M97 87V290H241" stroke={path.pathname === '/digital_twin' ? '#fff' : '#585858'} strokeWidth="26" />
                    <path d="M238.214 253.462L238.867 50.4627L94.8673 50.0001" stroke={path.pathname === '/digital_twin' ? '#fff' : '#585858'} strokeWidth="26" />
                    <path d="M240.977 327.471L88.9773 326.983" stroke={path.pathname === '/digital_twin' ? '#fff' : '#585858'} strokeWidth="26" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_11">
                      <rect width="335" height="340" fill="#fff" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </li>
          </OverlayTrigger>

          {/* Project Portfolio  End */}

          {!mainMenu && (
            <li
              className={`text-center has-sub ${
                program ? 'show-sub active' : ''
              } `}
            >
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip('Create')}
              >
                <Button
                  variant="link"
                  size="lg"
                  data-test-id="create-button"
                  className="text-decoration-none"
                  onClick={(e) => handleSubMenuClick(e, 'enter')}
                >
                  <span className="icon-new" />
                </Button>
              </OverlayTrigger>
              <div className="mega-menu-wrapper">
                <div className={`mega-menu ${singleMenu ? 'single-menu' : ''}`}>
                  <div className="mega-header">
                    {Hide !== 'transform' && (
                      <h5>{t('dashboard:transformation')}</h5>
                    )}
                    {Hide !== 'capab' && <h5>{t('dashboard:capabilities')}</h5>}
                  </div>
                  <div className="mega-body">
                    {Hide !== 'transform' && (
                      <div className="mega-list">
                        <ul className="list-unstyled mb-0">
                          {userData?.permissions.includes('create:program') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-program"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'programs', 'icon-program', 'Create Program')}
                              >
                                <span className="icon-program" />
                                {t('dashboard:program')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:project') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-project"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'projects',
                                  'icon-initiatives',
                                  'Create Project',
                                )}
                              >
                                <span className="icon-initiatives" />
                                {t('dashboard:project')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:change') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-change"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'change',
                                  'icon-requirement-changes',
                                  'Create Change',
                                )}
                              >
                                <span className="icon-requirement-changes" />
                                {t('dashboard:change')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:epic') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-epic"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'epic', 'icon-epics', 'Create Work Package')}
                              >
                                <span className="icon-epics" />
                                {t('dashboard:epic')}
                              </Button>
                            </li>
                          )}

                          {userData?.permissions.includes(
                            'create:maintenance',
                          ) && (
                            <li>
                              <Button
                                variant="link"
                                className="text-decoration-none btn btn-link"
                              >
                                <span className="icon-configuration" />
                                {t('dashboard:maintenance')}
                              </Button>
                            </li>
                          )}

                          {userData?.permissions.includes('create:risk') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-risk"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'risk', 'icon-error', 'Create Risk')}
                              >
                                <span className="icon-error" />
                                {t('dashboard:risk')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:benefit') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-benefit"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'benefit', 'icon-benefit', 'Create Benefit')}
                              >
                                <span className="icon-benefit" />
                                {t('dashboard:benefit')}
                              </Button>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                    {Hide !== 'capab' && (
                      <div className="mega-list">
                        <ul className="list-unstyled mb-0">
                          {userData?.permissions.includes('create:process') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-process"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'process',
                                  'icon-value-chain',
                                  'Create Process',
                                )}
                              >
                                <span className="icon-value-chain" />
                                {t('dashboard:process')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes(
                            'create:application',
                          ) && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-application"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'application',
                                  'icon-application',
                                  'Create Application',
                                )}
                              >
                                <span className="icon-application" />
                                {t('dashboard:application')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes(
                            'create:business',
                          ) && (
                          <li>
                            <Button
                              variant="link"
                              data-test-id="create-business"
                              className="text-decoration-none btn btn-link"
                              onClick={(e) => handleSubMenu(
                                e,
                                'business',
                                'icon-business',
                                'Create Business Capability',
                              )}
                            >
                              <span className="icon-business" />
                              Business
                            </Button>
                          </li>
                          )}
                          {userData?.permissions.includes(
                            'create:vendor',
                          ) && (
                          <li>
                            <Button
                              variant="link"
                              data-test-id="create-vendor"
                              className="text-decoration-none btn btn-link"
                              onClick={(e) => handleSubMenu(
                                e,
                                'vendor',
                                'icon-supplier',
                                'Create Vendor Capability',
                              )}
                            >
                              <span className="icon-supplier" />
                              Vendor
                            </Button>
                          </li>
                          )}
                          {userData?.permissions.includes(
                            'create:customer',
                          ) && (
                          <li>
                            <Button
                              variant="link"
                              data-test-id="create-customer"
                              className="text-decoration-none btn btn-link"
                              onClick={(e) => handleSubMenu(
                                e,
                                'customer',
                                'icon-consumer',
                                'Create Customer Capability',
                              )}
                            >
                              <span className="icon-consumer" />
                              Customer
                            </Button>
                          </li>
                          )}
                          {userData?.permissions.includes(
                            'create:product',
                          ) && (
                          <li>
                            <Button
                              variant="link"
                              data-test-id="create-product"
                              className="text-decoration-none btn btn-link"
                              onClick={(e) => handleSubMenu(
                                e,
                                'product',
                                'icon-toolbar',
                                'Create Product Capability',
                              )}
                            >
                              <span className="icon-toolbar" />
                              Product
                            </Button>
                          </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          )}
          <li
            className={`text-center ${
              path.pathname === '/collaborate' && 'active'
            }`}
          >
            <OverlayTrigger
              placement="right"
              overlay={renderTooltip('Collaborate')}
            >
              <Button
                variant="link"
                className="text-decoration-none"
                onClick={(e) => handleMainNav(e, '/collaborate', 'Collaborator')}
              >
                <span className="icon-collaborate" />
              </Button>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
      <div className="side-nav secondary">
        <ul className="list-unstyled mb-0">
          {activeTabss
            && activeTabss?.map((acNav: activeTabsa[]) => (
              <li
                key={acNav.to}
                className={`text-center ${
                  `/${acNav.to}` === path.pathname && 'active'
                }`}
              >
                <Button variant="link" className="text-decoration-none close-temp-tab" onClick={() => handleRemoveActiveNav({ to: acNav.to, icon: acNav.icon })}>
                  <span className="icon-close" />
                </Button>
                <OverlayTrigger
                  placement="right"
                  style={{ textTranform: 'capitalize' }}
                  overlay={renderTooltip(
                    acNav.to.charAt(0).toUpperCase() + acNav.to.slice(1),
                  )}
                >
                  <Button
                    variant="link"
                    className="text-decoration-none"
                    onClick={() => hanldeTemptabs({ to: acNav.to, icon: acNav.icon })}
                  >
                    <span className={acNav.icon} />
                  </Button>
                </OverlayTrigger>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

const Sidebar = React.memo(Sidenav);
export default Sidebar;
