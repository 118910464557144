/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ProcessReadOnlyView from './ProcessReadOnlyView';
import ProcessStepReviw from './ProcessStepReview';

function ProcessStepViewModal({ show, onHide, details }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>

        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="modal-title" id="exampleModalLabel">
            {details?.type === 'Process Step' ? `Step : ${details?.value?.name}` : details?.type === 'Process' ? `Process : ${details?.value?.name}` : ''}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {details?.type === 'Process Step' ? <ProcessStepReviw labelTwin="readOnly" />
          : details?.type === 'Process' ? <ProcessReadOnlyView /> : ''}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProcessStepViewModal;
