/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, {
  useEffect, useRef, useState, useLayoutEffect,
} from 'react';
import * as d3 from 'd3';

export default function Heatmap({ data, tabKey }) {
  const ref = useRef(null);
  // State to track width and height of SVG Container
  const [WIDTH, setWidth] = useState(0);
  const [HEIGHT, setHeight] = useState(250);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [tabKey]);

  const handleResize = () => {
    const newWidth = parseInt(d3.select('#heatmap').style('width'), 10);
    setWidth(newWidth);
    const newHeight = parseInt(d3.select('#heatmap').style('height'), 10);
    setHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const margin = {
    top: 10, right: 15, bottom: 40, left: 70,
  };
  const width = WIDTH - margin.left - margin.right;
  const height = HEIGHT - margin.top - margin.bottom;
  useEffect(() => {
    if (data && Array.isArray(data) && data?.length) {
      // clear all previous content on refresh
      const svgs = d3.select('#heatmap');
      svgs.selectAll('*').remove();

      // append the svg object to the body of the page
      const svg = d3
        .select('#heatmap')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Labels of row and columns
      const myGroups = ['Negligible', 'Minor', 'Moderate', 'Serious', 'Critical'];
      const myVars = ['Very Low', 'Low', 'Medium', 'High', 'Very High'];

      const xScale = d3.scaleBand().range([0, width]).domain(myGroups).padding(0.01);
      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .attr('font', 'Lato')
        .attr('font-weight', 400)
        .attr('color', '#000')
        .call(WIDTH < 410 ? d3.axisBottom(xScale).tickFormat('') : d3.axisBottom(xScale));
      const yScale = d3.scaleBand().range([height, 0]).domain(myVars).padding(0.01);
      svg.append('g')
        .attr('font', 'Lato')
        .attr('font-weight', 400)
        .attr('color', '#000')
        .call(d3.axisLeft(yScale));

      // Label for X-axis
      svg
        .append('text')
        .attr('x', width / 2)
        .attr('y', height + margin.bottom)
        .style('text-anchor', 'middle')
        .attr('font', 'Lato')
        .attr('font-weight', 700)
        .attr('color', '#000')
        .text('IMPACT');

      // Label for Y-axis
      svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - height / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('font', 'Lato')
        .attr('font-weight', 700)
        .attr('color', '#000')
        .text('Probability');

      svg
        .selectAll()
        .data(data, (d) => `${d.group}:${d.variable}`)
        .enter()
        .append('rect')
        .attr('x', (d) => xScale(d.group))
        .attr('y', (d) => yScale(d.variable))
        .attr('width', xScale.bandwidth())
        .attr('height', yScale.bandwidth())
        .style('fill', (d) => d.color)
        .attr('stroke', (d) => d.color)
        .attr('stroke-opacity', 0.3);

      const dots = data.map((dt) => dt.heat_matrix_value).flat().filter((hm) => hm !== undefined);
      console.log('dotss', dots);

      const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);
      const x = d3.scaleLinear().domain([0, 100]).range([0, width]);

      const xaxis = d3.axisBottom(x);
      xaxis.ticks(4).tickFormat((d) => `${d}%`);

      const yaxis = d3.axisLeft(y);
      yaxis.tickValues([20, 40, 60, 80, 100]).tickFormat((d) => `${d}%`);

      svg.append('g').attr('transform', `translate(0, ${height})`)
        .attr('font', 'Lato')
        .attr('font-weight', 400)
        .attr('color', '#717171')
        .call(xaxis);
      svg.append('g')
        .attr('font', 'Lato')
        .attr('font-weight', 400)
        .attr('color', '#717171')
        .call(yaxis);

      const tooltip = d3.select('body')
        .append('div')
        .style('background-color', '#585858')
        .style('color', '#EEF0F6')
        .style('font-size', '12px')
        .style('border-radius', '5px')
        .style('padding', '5px')
        .style('position', 'absolute')
        .style('z-index', '10')
        .style('visibility', 'hidden')
        .text('a simple sstooltip');

      svg.append('g')
        .selectAll('dot')
        .data(dots)
        .enter()
        .append('circle')
        .attr('cx', (d) => x(d?.x))
        .attr('cy', (d) => y(d?.y))
        .attr('r', 4)
        .attr('stroke', '#717171')
        .attr('stroke-width', 1)
        .attr('fill', '#717171')
        .on('mouseover', (event, d) => {
          tooltip.style('visibility', 'visible');
        })
        .on('mousemove', (event, d) => {
          const tooltipData = [];
          for (let i = 0; i < dots?.length; i++) {
            if (dots[i]?.x === d?.x && dots[i]?.y === d?.y) {
              tooltipData.push(dots[i]);
            }
          }
          if (tooltipData && Array.isArray(tooltipData) && tooltipData?.length > 0) {
            tooltip
              .html(`${tooltipData?.map((tp) => `<span className='d-block'>Risk Name (ID): ${tp?.name} (${tp?.id})</span>`)}`)
              .style('top', `${event.pageY - 10}px`).style('left', `${event.pageX + 10}px`);
          }
        })
        .on('mouseout', (d) => {
          tooltip.style('visibility', 'hidden');
        });
    }
  }, [data, WIDTH, HEIGHT]);

  return (
    <div id="heatmap" ref={ref} />
  );
}
