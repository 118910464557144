/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useTranslation } from 'react-i18next';
import LoaderComp from '../../components/LoderQ';
import {
  addCapabilities,
  addProcessAppReviewCaps,
  addProcessCapabilities,
  addProcessReviewCaps,
  addProcessStepAppReviewCaps,
  addProcessStepCapabilities,
  addProcessStepReviewCaps,
  addStepCapabilities,
} from '../../services/applicationSlice';
import { addBenefit } from '../../services/benefitSlice';
import {
 addExtMtData, addProcessExtMtData, addProcessStepExtMtData, addvisibleFields,
} from '../../services/editFormSlice';
import {
  addRelatedChangeData,
  addRelatedInitData,
  addRelatedProgData,
  selectBU,
  selectDeprt,
  selectOrg,
  setRecordPermission,
} from '../../services/formSlice';
import { addPeople, addStepPeople } from '../../services/peopleSlice';
import {
  addRcVals,
  setAppCapsLevel,
  setChangeInputDisabled,
  setRelatedProjectDate,
} from '../../services/reqChangeSlice';
import { addRisk } from '../../services/riskSlice';
import {
  useGetChangeByIdMutation,
  useGetMultiDownoadUrlsMutation,
  useGetProcessJsonQuery,
  useGetProcessStepJsonQuery,
  useStepsByProcessIdMutation,
  useTaskByUserQuery,
} from '../../services/services';
import ReadMoreLess from '../../components/common/ReadMoreLess';
import {
 addProcessReadOnlyData, addProcessStepRalatedDate, addReadOnlyProcessSteps, addStepId,
} from '../../services/processSlice';
import { addReviewStepDocsInfo } from '../../services/docInfoSlice';
import ProcessStepViewModal from '../process/ProcessStepViewModal';
import { addProcessBusinessReviewCaps } from '../../services/businessSlice';
import { printToPDF } from '../../utils/printPdf';

export default function SolutionSpecification({
  show,
  setShow,
  id,
  screen,
  data,
}) {
  const { t } = useTranslation(screen === 'project' ? 'initiative' : 'change');
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const navigate = useNavigate();
  const formProcessJson = useGetProcessJsonQuery();
  const formStepJson = useGetProcessStepJsonQuery();
  const [getSteps, stepRes] = useStepsByProcessIdMutation();
  const [changeById, changeRes] = useGetChangeByIdMutation();
  const [getUrl, respo] = useGetMultiDownoadUrlsMutation();
  const peopleData = useSelector((state) => state.peopleData.peopleVals); // all people
  const [modal, setModal] = useState(false);
  const [processStepDetails, setProcessStepDetails] = useState(null);
  const navigateToChange = (cid) => {
    changeById({ id: cid });
  };
  const allTask = useTaskByUserQuery({ id: userData.id });
  const [targetElement, setTargetElement] = useState('');
  const [changeDetails, setChangeDetails] = useState(null);
  console.log('changeDetails', changeDetails);
  const [reqList, setReqList] = useState([]); // for project to show table of requirement
  console.log('reqList', reqList);

  const downLoadFile = (itm) => {
    getUrl({ fileName: itm.original_name, keyName: itm.s3_key_name });
  };

  const handleClickScroll = (str) => {
    console.log('str', str);
    const element = document.getElementById(str);
    setTargetElement(element);
    if (element) {
      element.classList.add('scroll-highlight');
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (targetElement) {
      const timeOut = setTimeout(() => {
        targetElement.classList.remove('scroll-highlight');
      }, 2000);
      return () => clearTimeout(timeOut);
    }
  }, [targetElement]);

  useEffect(() => {
    if (changeRes.isSuccess) {
      const updated = [...changeRes.data.change_extensions];
      Object.entries(changeRes?.data).forEach((tv) => updated.forEach((dt, i) => {
          if (dt.field_name === tv[0]) {
            if (
              dt.field_name === 'business_units'
              || dt.field_name === 'organizations'
            ) {
              updated.splice(i, 1, {
                ...dt,
                value: { label: tv[1][0]?.name, value: tv[1][0]?.id },
              });
            } else if (dt.field_name === 'departments') {
              updated.splice(i, 1, {
                ...dt,
                value: { label: tv[1][0]?.name, value: tv[1][0]?.id },
              });
            } else if (dt.field_name === 'cost_center_id') {
              updated.splice(i, 1, {
                ...dt,
                value: { label: tv[1]?.name, value: tv[1]?.id },
              });
            } else if (
              dt.field_name === 'category'
              || dt.field_name === 'priority'
              || dt.field_name === 'confidentiality'
              || dt.field_name === 'status'
            ) {
              updated.splice(i, 1, {
                ...dt,
                value: { label: tv[1], value: tv[1] },
              });
            } else if (dt.field_name === 'overheads_budget') {
              updated.splice(i, 1, {
                ...dt,
                display_name: 'Change Budget',
                value: tv[1],
              });
            } else if (dt.field_name === 'aggregated_from_epics') {
              updated.splice(i, 1, {
                ...dt,
                display_name: 'Budget Aggregated From Work Package',
                value: tv[1],
              });
            } else if (dt.field_name === 'total_change_budget') {
              updated.splice(i, 1, {
                ...dt,
                display_name: 'Total Change Budget',
                value: Number(tv[1]) + Number(tv[1]) || 0,
              });
            } else if (dt.field_name === 'bdd_description' && tv[1] === 'BDD Description') {
              updated.splice(i, 1, { ...dt, value: '' });
            } else if (dt.field_name === 'consequences' && tv[1] === "Consequences of 'Do Nothings'") {
              updated.splice(i, 1, { ...dt, value: '' });
            } else if (dt.field_name === 'technical_effects' && tv[1] === 'Technical Effects of Implementation') {
              updated.splice(i, 1, { ...dt, value: '' });
            } else if (dt.field_name === 'business_effect' && tv[1] === 'Business Effect of Implementation') {
              updated.splice(i, 1, { ...dt, value: '' });
            } else {
              updated.splice(i, 1, { ...dt, value: tv[1] });
            }
          }
        }));
      dispatch(
        setAppCapsLevel(
          changeRes?.data?.dependency_level === 'all_level' ? 'all' : 'first',
        ),
      );
      const updatedSections = [];
      updated.forEach((el) => {
        if (!updatedSections.some((se) => se.id === el.section_title.id)) {
          updatedSections.push(el.section_title);
        }
      });
      dispatch(
        addExtMtData({
          fields:
            updated && updated.length > 1 && Array.isArray(updated)
              ? updated
              : [],
          sections: updatedSections,
        }),
      );

      dispatch(
        addPeople({
          manager: changeRes?.data?.managers,
          owner: changeRes?.data?.owners,
          collaborator: changeRes?.data?.collaborators,
        }),
      );
      if (
        changeRes?.data?.status.trim() === 'New'
        || changeRes?.data?.status.trim() === 'Open'
      ) {
        dispatch(setChangeInputDisabled(false));
      } else {
        dispatch(setChangeInputDisabled(true));
      }
      if (
        changeRes?.data?.capability_mappings
        && Array.isArray(changeRes?.data?.capability_mappings)
        && changeRes?.data?.capability_mappings.length
      ) {
        dispatch(
          addCapabilities(
            changeRes?.data?.capability_mappings
              ?.filter(
                (cm) => cm.key === 'application' || cm.key === 'Application',
              )
              .map((dm) => dm.value)
              .flat(),
          ),
        );
        dispatch(
          addProcessCapabilities(
            changeRes?.data?.capability_mappings
              ?.filter((cm) => cm.key === 'process' || cm.key === 'Process')
              .map((dm) => dm.value)
              .flat(),
          ),
        );
      }
      dispatch(addBenefit(changeRes?.data?.benefits));
      dispatch(addRisk(changeRes?.data?.risks));
      const rcVals = { ...changeRes?.data };
      rcVals?.change_extensions?.forEach(
        (dt) => dt.field_type === 'Custom'
          && (dt.field_data_type === 'select' && isJson(dt.value)
            ? (rcVals[dt.field_name] = JSON.parse(dt.value))
            : (rcVals[dt.field_name] = dt.value)),
      );

      dispatch(
        addRcVals({
          ...rcVals,
          business_units: changeRes?.data?.business_units
            ? changeRes?.data?.business_units[0]?.id
            : '',
          organizations: changeRes?.data?.organizations
            ? changeRes?.data?.organizations[0]?.id
            : '',
          departments: changeRes?.data?.departments
            ? changeRes?.data?.departments[0]?.id
            : '',
          start_date: new Date(changeRes?.data?.start_date),
          end_date: new Date(changeRes?.data?.end_date),
          approval_due_date: changeRes?.data?.approval_due_date
            ? new Date(changeRes?.data?.approval_due_date)
            : '',
          aggregated_from_epics: 0,
          total_change_budget: Number(rcVals.overheads_budget) + 0,
          business_effect: changeRes?.data?.business_effect !== 'Business Effect of Implementation' ? changeRes?.data?.business_effect : '',
          technical_effects: changeRes?.data?.technical_effects !== 'Technical Effects of Implementation' ? changeRes?.data?.technical_effects : '',
          consequences: changeRes?.data?.consequences !== "Consequences of 'Do Nothings'" ? changeRes?.data?.consequences : '',
          bdd_description: changeRes?.data?.bdd_description !== 'BDD Description' ? changeRes?.data?.bdd_description : '',
        }),
      );
      if (
        changeRes?.data?.related_initiatives
        && Array.isArray(changeRes?.data?.related_initiatives)
        && changeRes?.data?.related_initiatives.length
      ) {
        const minDateArr = [];
        const maxDateArr = [];
        changeRes?.data?.related_initiatives?.forEach((vl) => {
          minDateArr.push(vl?.start_date);
        });
        changeRes?.data?.related_initiatives?.forEach((vl) => {
          maxDateArr.push(vl?.end_date);
        });
        const minDate = minDateArr?.reduce((first, second) => (first < second ? first : second));
        const maxDate = maxDateArr?.reduce((first, second) => (first > second ? first : second));

        if (minDate && maxDate) {
          dispatch(
            setRelatedProjectDate({ startDate: minDate, endDate: maxDate }),
          );
        }
      }
      dispatch(selectBU(changeRes?.data?.business_units));
      dispatch(selectOrg(changeRes?.data?.organizations));
      dispatch(selectDeprt(changeRes?.data?.departments));
      dispatch(addRelatedProgData(changeRes?.data?.programs || []));
      dispatch(addRelatedInitData(changeRes?.data?.related_initiatives || []));
      dispatch(addRelatedChangeData(changeRes?.data?.changes || []));
      dispatch(
        addvisibleFields([
          { name: 'capabilities', visible: true },
          { name: 'people', visible: true },
          { name: 'risk', visible: true },
          { name: 'benefit', visible: true },
        ]),
      );
      dispatch(setRecordPermission(changeRes?.data?.recordPermission));
      setShow(false);
      navigate('/readModeChange', {
        state: {
          id: changeRes?.data?.id,
          solSpecs: true,
          tab: 'dyndto',
          view: !!(
            (allTask.isSuccess
              && allTask?.data?.data?.some(
                (tsk) => tsk?.change_id?.id === changeRes?.data?.id,
              ))
            || userData?.permissions?.includes('view:change')
          ),
          peopleView: {
            owner: true,
            manager: true,
            stCom: false,
            teams: false,
            sponsor: false,
            collaborator: true,
            resp_person: false,
          },
        },
      });
    }
  }, [changeRes.isSuccess]);

  const [capOverview, setCapOverview] = useState([]);
  const [reqCapOverview, setReqCapOverview] = useState([]);
  const [epicCapOverview, setEpicCapOverview] = useState([]);
  const [epicTask, setEpicTask] = useState([]);

  useEffect(() => {
    const capsData = [];
    data?.changes
      && Array.isArray(data.changes)
      && data?.changes?.forEach((change) => change?.capability_mappings?.map((cm) => cm?.value?.forEach(
            (cv) => !capsData.some((cd) => cd?.unique_id === cv?.unique_id)
              && capsData.push({
                ...cv,
                type: cm?.key === 'Process' ? 'process' : cm?.key,
                parentId: change?.id,
              }),
          )));
    setCapOverview([...capsData]);
  }, [data]);

  useEffect(() => {
    const reqCapsData = [];
    const res1 = (data?.changes && Array.isArray(data?.changes)) ? data?.changes?.map((el) => el?.requirements)?.flat()?.filter((el) => el !== undefined) : [];
    const res2 = (res1 && Array.isArray(res1) && res1?.length > 0) ? res1?.forEach((req) => req?.capability_mappings?.map((cm) => cm?.value?.forEach(
              (cv) => !reqCapsData.some((cd) => cd?.unique_id === cv?.unique_id)
                && reqCapsData.push({
                  ...cv,
                  type: cm?.key === 'Process' ? 'process' : cm?.key,
                  parentId: req?.id,
                }),
            ))) : [];
      setReqCapOverview([...reqCapsData]);
  }, [data]);

  useEffect(() => {
    const epicCapsData = [];
    const res1 = (data?.changes && Array.isArray(data?.changes)) ? data?.changes?.map((el) => el?.epics)?.flat()?.filter((el) => el !== undefined) : [];
    const res2 = (res1 && Array.isArray(res1) && res1?.length > 0) ? res1?.forEach((req) => req?.capability_twins?.map((cm) => cm?.value?.forEach(
              (cv) => !epicCapsData.some((cd) => cd?.unique_id === cv?.unique_id)
                && epicCapsData.push({
                  ...cv,
                  type: cm?.key === 'Process' ? 'process' : cm?.key,
                  parentId: req?.id,
                }),
            ))) : [];
      setEpicCapOverview([...epicCapsData]);
  }, [data]);

  useEffect(() => {
    const epicCapsData = [];
    const res1 = (data?.changes && Array.isArray(data?.changes)) ? data?.changes?.map((el) => el?.epics)?.flat()?.filter((el) => el !== undefined) : [];
    const res2 = (res1 && Array.isArray(res1)) ? res1?.map((el) => el?.epic_tasks)?.flat()?.filter((el) => el !== undefined) : [];
    console.log('res2', res2);
    if (res2 && Array.isArray(res2) && res2?.length > 0) {
          setEpicTask(res2);
    }
  }, [data]);

  useEffect(() => {
    if (data?.changes && Array.isArray(data?.changes) && data?.changes?.length > 0) {
      const [CD] = data.changes;
      if (CD) {
        setChangeDetails(CD);
      }
      // const ddddd = [{
      //   requirements: undefined,
      // }, {
      //   requirements: undefined,
      // }];
      const RQ = data?.changes?.map((el) => el?.requirements?.map((el2) => ({ ...el2, change_name: el?.name })))?.flat()?.filter((el) => (el !== undefined));
      console.log('RQ', RQ);
      if (RQ && Array.isArray(RQ) && RQ?.length > 0) {
        setReqList(RQ);
      }
    }
  }, [data?.changes]);

  const tableContentChange = [
    { // el1
      level: [ // el2
        {
          content: 'Introduction',
          level: [ // el3
            {
              content: 'Purpose and Goals',
              subContent: `The purpose of this specification is to secure that stakeholders
                have a clear view on the scope to be delivered.`,
            },
            {
              content: 'Limitations',
              subContent: `The document is based on dialogue, presentations, and demos with
          stakeholders. All involved parties carry the responsibilities to
          ensure the functionality containing possible Changes have been
          discussed, concluded, and documented in this document. There may
          be some changes related to depending on drawings, screenshots,
          or mock-ups.`,
            },
          ],
        },
      ],
    },
    { // el1
      level: [ // el2
        {
          content: 'Capability overview',
          type: 'table',
        },
      ],
    },
    { // el1
      level: [ // el2
        {
            content: changeDetails ? changeDetails?.name : '',
            level: [ // el3
              {
                content: 'Change Description',
                subContent: changeDetails?.description ? changeDetails?.description : 'No Data To Display',
                type: changeDetails?.description ? 'texteditor' : '',
              },
              {
                content: t('reason_for_change.name'),
                subContent: changeDetails?.reason_for_change ? changeDetails?.reason_for_change : 'No Data To Display',
                type: changeDetails?.reason_for_change ? 'texteditor' : '',
              },
              {
                content: t('business_effect.name'),
                subContent: changeDetails?.business_effect ? changeDetails?.business_effect : 'No Data To Display',
                type: changeDetails?.business_effect ? 'textarea' : '',
              },
              // {
              //   content: `Use Case (${t('bdd_description.name')})`,
              //   subContent: cs?.bdd_description ? cs?.bdd_description : 'No Data To Display',
              //   type: cs?.bdd_description ? 'textarea' : '',
              // },
              {
                content: t('technical_effects.name'),
                subContent: changeDetails?.technical_effects ? changeDetails?.technical_effects : 'No Data To Display',
                type: changeDetails?.technical_effects ? 'textarea' : '',
              },
              {
                content: 'Impacted Capabilities',
                level:
                  capOverview
                  && Array.isArray(capOverview)
                  && capOverview?.length > 0
                    ? capOverview?.map((co) => ((
                      (co?.type === 'application' || co?.type === 'Application')
                      || (co?.type === 'business' || co?.type === 'Business')
                      || (co?.type === 'vendor' || co?.type === 'Vendor')
                      || (co?.type === 'customer' || co?.type === 'Customer'))
                          ? {
                              content: co?.name,
                              level: [
                                {
                                  content: 'Description',
                                  subContent: co?.description ? co?.description : 'No Data To Display',
                                  type: co?.description ? 'textarea' : '',
                                },
                                // {
                                //   content: 'Change Details',
                                //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                                //   type: co?.change_details ? 'texteditor' : '',
                                // },
                                {
                                  content: 'Related Documents',
                                  type: 'document',
                                  documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                                },
                              ],
                            }
                          : (co?.type === 'process' || co?.type === 'Process')
                          ? {
                              content: co?.name,
                              level: [
                                {
                                  content: 'Description',
                                  subContent: co?.description ? co?.description : 'No Data To Display',
                                  type: co?.description ? 'textarea' : '',
                                },
                                {
                                  content: 'Process Step Details',
                                  type: 'table',
                                  process_diagram: true,
                                  steps: co?.step_mappings,
                                },
                                // {
                                //   content: 'Change Details',
                                //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                                //   type: co?.change_details ? 'texteditor' : '',
                                // },
                                {
                                  content: 'Related Documents',
                                  type: 'document',
                                  documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                                },
                              ],
                            }
                          : ''))
                    : [],
              },
      ],
        },
      ],
    },
    {
      level: [
          {
            content: 'Requirement(s)',
            type: 'table',
            requirements: changeDetails?.requirements && Array.isArray(changeDetails?.requirements) && changeDetails?.requirements?.length > 0 ? changeDetails?.requirements : [],
            level: (reqCapOverview
            && Array.isArray(reqCapOverview)
            && reqCapOverview?.length > 0) ? [{
              content: 'Impacted Capabilities',
              level:
              reqCapOverview
              && Array.isArray(reqCapOverview)
              && reqCapOverview?.length > 0
                ? reqCapOverview?.map((co) => ((
                  (co?.type === 'application' || co?.type === 'Application')
                  || (co?.type === 'business' || co?.type === 'Business')
                  || (co?.type === 'vendor' || co?.type === 'Vendor')
                  || (co?.type === 'customer' || co?.type === 'Customer'))
                      ? {
                          content: co?.name,
                          level: [
                            {
                              content: 'Description',
                              subContent: co?.description ? co?.description : 'No Data To Display',
                              type: co?.description ? 'textarea' : '',
                            },
                            // {
                            //   content: 'Change Details',
                            //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                            //   type: co?.change_details ? 'texteditor' : '',
                            // },
                            {
                              content: 'Related Documents',
                              type: 'document',
                              documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                            },
                          ],
                        }
                      : (co?.type === 'process' || co?.type === 'Process')
                      ? {
                          content: co?.name,
                          level: [
                            {
                              content: 'Description',
                              subContent: co?.description ? co?.description : 'No Data To Display',
                              type: co?.description ? 'textarea' : '',
                            },
                            {
                              content: 'Process Step Details',
                              type: 'table',
                              process_diagram: true,
                              steps: co?.step_mappings,
                            },
                            // {
                            //   content: 'Change Details',
                            //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                            //   type: co?.change_details ? 'texteditor' : '',
                            // },
                            {
                              content: 'Related Documents',
                              type: 'document',
                              documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                            },
                          ],
                        }
                      : ''))
                : [],
            }] : [],
          },

      ],
    },
  ];
  console.log('tableContentChange', tableContentChange);

  const tableContentProject = [
    {
      level: [
        {
          content: 'Introduction',
          level: [
            {
              content: 'Purpose and Goals',
              object_type: 'project',
            },
            {
              content: 'Limitations',
              subContent: `The document is based on dialogue, presentations, and demos with
          stakeholders. All involved parties carry the responsibilities to
          ensure the functionality containing possible Changes have been
          discussed, concluded, and documented in this document. There may
          be some changes related to depending on drawings, screenshots,
          or mock-ups.`,
            },
            {
              content: 'Requirements and Changes',
              subContent:
                'This document handles the following functional Changes:',
              type: 'table',
            },
          ],
        },
      ],
    },
    {
      level: [
        {
          content: 'Capability overview',
          type: 'table',
        },
      ],
    },
    {
      level: [
        {
          content: data?.front_page?.name,
          level: [
            {
              content: t('description.name'),
              subContent: data?.front_page?.description ? data?.front_page?.description : 'No Data To Display',
              type: data?.front_page?.description ? 'textarea' : '',
            },
            {
              content: t('mandate.name'),
              subContent: data?.front_page?.mandate ? data?.front_page?.mandate : 'No Data To Display',
              type: data?.front_page?.mandate ? 'texteditor' : '',
            },
            {
              content: 'Related Programs and Projects',
              type: 'table',
            },
          ],
        },
      ],
    },
    {
      level: [
        {
          content: 'Change(s)',
          noData: !((data?.changes && Array.isArray(data?.changes) && data?.changes?.length > 0)),
          level: data?.changes?.map((cs) => ({
            content: cs?.name,
            level: [
              {
                content: 'Change Description',
                subContent: cs?.description ? cs?.description : 'No Data To Display',
                type: cs?.description ? 'texteditor' : '',
              },
              {
                content: 'Reason for Change',
                subContent: cs?.reason_for_change ? cs?.reason_for_change : 'No Data To Display',
                type: cs?.reason_for_change ? 'texteditor' : '',
              },
              {
                content: 'Business Effect of Implementation',
                subContent: cs?.business_effect ? cs?.business_effect : 'No Data To Display',
                type: cs?.business_effect ? 'textarea' : '',
              },
              // {
              //   content: 'Use Case (BDD Description)',
              //   subContent: cs?.bdd_description ? cs?.bdd_description : 'No Data To Display',
              //   type: cs?.bdd_description ? 'textarea' : '',
              // },
              {
                content: 'Technical Effect',
                subContent: cs?.technical_effects ? cs?.technical_effects : 'No Data To Display',
                type: cs?.technical_effects ? 'textarea' : '',
              },
              {
                content: 'Impacted Capabilities',
                level:
                  capOverview
                  && Array.isArray(capOverview)
                  && capOverview?.length > 0
                    ? capOverview?.map((co) => (co?.type === 'application' && co?.parentId === cs?.id
                          ? {
                              content: co?.name,
                              level: [
                                {
                                  content: 'Description',
                                  subContent: co?.description ? co?.description : 'No Data To Display',
                                  type: co?.description ? 'textarea' : '',
                                },
                                // {
                                //   content: 'Change Details',
                                //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                                //   type: co?.change_details ? 'texteditor' : '',
                                // },
                                {
                                  content: 'Related Documents',
                                  type: 'document',
                                  documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                                },
                              ],
                            }
                          : co?.type === 'process' && co?.parentId === cs?.id
                          ? {
                              content: co?.name,
                              level: [
                                {
                                  content: 'Description',
                                  subContent: co?.description ? co?.description : 'No Data To Display',
                                  type: co?.description ? 'textarea' : '',
                                },
                                {
                                  content: 'Process Step Details',
                                  type: 'table',
                                  process_diagram: true,
                                  steps: co?.step_mappings,
                                },
                                // {
                                //   content: 'Change Details',
                                //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                                //   type: co?.change_details ? 'texteditor' : '',
                                // },
                                {
                                  content: 'Related Documents',
                                  type: 'document',
                                  documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                                },
                              ],
                            }
                          : ''))?.filter((el) => el)
                    : [],
              },
            ],
          })),
        },
      ],
    },
    {
      level: [
        {
          content: 'Requirement(s)',
          noData: !((reqList && Array.isArray(reqList) && reqList?.length > 0)),
          level: (reqList && Array.isArray(reqList) && reqList?.length > 0) ? reqList?.map((cs) => ({
            content: cs?.name,
            level: [
              {
                content: 'Description',
                subContent: cs?.description ? cs?.description : 'No Data To Display',
                type: cs?.description ? 'texteditor' : '',
              },
              {
                content: 'Requirements and Change  details',
                type: 'table',
              },
              {
                content: 'Related Document',
                type: 'document',
                documents: cs?.documents && Array.isArray(cs?.documents) && cs?.documents?.length > 0 ? cs?.documents : [],
              },
              {
                content: 'Impacted Capabilities',
                level:
                  reqCapOverview
                  && Array.isArray(reqCapOverview)
                  && reqCapOverview?.length > 0
                    ? reqCapOverview?.map((co) => (co?.type === 'application' && co?.parentId === cs?.id
                          ? {
                              content: co?.name,
                              level: [
                                {
                                  content: 'Description',
                                  subContent: co?.description ? co?.description : 'No Data To Display',
                                  type: co?.description ? 'textarea' : '',
                                },
                                // {
                                //   content: 'Change Details',
                                //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                                //   type: co?.change_details ? 'texteditor' : '',
                                // },
                                {
                                  content: 'Related Documents',
                                  type: 'document',
                                  documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                                },
                              ],
                            }
                          : co?.type === 'process' && co?.parentId === cs?.id
                          ? {
                              content: co?.name,
                              level: [
                                {
                                  content: 'Description',
                                  subContent: co?.description ? co?.description : 'No Data To Display',
                                  type: co?.description ? 'textarea' : '',
                                },
                                {
                                  content: 'Process Step Details',
                                  type: 'table',
                                  process_diagram: true,
                                  steps: co?.step_mappings,
                                },
                                // {
                                //   content: 'Change Details',
                                //   subContent: co?.change_details ? co?.change_details : 'No Data To Display',
                                //   type: co?.change_details ? 'texteditor' : '',
                                // },
                                {
                                  content: 'Related Documents',
                                  type: 'document',
                                  documents: co?.documents && Array.isArray(co?.documents) && co?.documents?.length > 0 ? co?.documents : [],
                                },
                              ],
                            }
                          : ''))?.filter((el) => el)
                    : [],
              },
            ],
          })) : [],
        },
      ],
    },
  ];

  const handleShowProceeDetails = (stepD) => {
    if (stepD?.type === 'Process Step') {
      const updated = [...stepD.value.process_step_extensions];
      Object.entries(stepD?.value).forEach((tv) => updated.forEach((dt, i) => {
        if (dt.field_name === 'step_documents' && Array.isArray(stepD?.value?.step_documents)) {
          updated.splice(i, 1, { ...dt, value: stepD?.value?.step_documents });
        }
        if (dt.field_name === 'unique_id') {
          updated.splice(i, 1, { ...dt, value: stepD?.value?.unique_id ? stepD?.value?.unique_id : '' });
        }
        if (dt.field_name === tv[0]) {
          if (dt.field_name === 'business_units' || dt.field_name === 'organizations') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else if (dt.field_name === 'departments') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else {
            updated.splice(i, 1, { ...dt, value: tv[1] });
          }
        }
      }));
      dispatch(addStepId(stepD?.value?.id));
      dispatch(selectBU(stepD?.value?.business_units));
      dispatch(selectOrg(stepD?.value?.organizations));
      dispatch(selectDeprt(stepD?.value?.departments));
      dispatch(addProcessStepExtMtData({ fields: (updated && updated.length > 1 && Array.isArray(updated)) ? updated : [], sections: (formStepJson?.isSuccess && formStepJson?.data) ? formStepJson.data.sections : [] }));
      if (stepD?.value?.capability_mappings && Array.isArray(stepD?.value?.capability_mappings) && stepD?.value?.capability_mappings.length > 0) {
        dispatch(addStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addProcessStepAppReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessStepReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
      } else {
        dispatch(addStepCapabilities([]));
        dispatch(addProcessStepCapabilities([]));
        dispatch(addProcessStepAppReviewCaps([]));
        dispatch(addProcessStepReviewCaps([]));
      }
      dispatch(addReviewStepDocsInfo(stepD?.value?.step_documents || []));
      dispatch(addStepPeople({
        ...peopleData,
        owner: stepD?.value?.owners || [],
        manager: stepD?.value?.managers || [],
        teams: stepD?.value?.teams || [],
      }));
      dispatch(addProcessStepRalatedDate({
        created: stepD?.value?.created || '',
        created_by: stepD?.value?.created_by?.first_name ? `${stepD?.value?.created_by?.first_name || ''} ${stepD?.value?.created_by?.last_name || ''}` : '',
        updated: stepD?.value?.updated || '',
        updated_by: stepD?.value?.updated_by?.first_name ? `${stepD?.value?.updated_by?.first_name || ''} ${stepD?.value?.updated_by?.last_name || ''}` : '',
      }));
    } else if (stepD?.type === 'Process') {
      getSteps({ id: stepD?.value?.id });
      const updated = [...stepD.value.process_extensions];
      Object.entries(stepD?.value).forEach((tv) => updated.forEach((dt, i) => {
        if (dt.field_name === 'documents' && Array.isArray(stepD?.value?.step_documents)) {
          updated.splice(i, 1, { ...dt, value: stepD?.value?.step_documents });
        }
        if (dt.field_name === tv[0]) {
          if (dt.field_name === 'business_units' || dt.field_name === 'organizations') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else if (dt.field_name === 'departments') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else if (dt.field_name === 'domain' || dt.field_name === 'status') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1], value: tv[1] } });
          } else {
            updated.splice(i, 1, { ...dt, value: tv[1] });
          }
        }
      }));
      dispatch(addProcessReadOnlyData({
        people: {
          manager: stepD?.value?.managers,
          owner: stepD?.value?.owners,
          teams: stepD?.value?.teams,
          subjMtExp: stepD?.value?.subject_matter_experts,
        },
        related_programs: stepD?.value?.related_programs || [],
        related_initiatives: stepD?.value?.related_initiatives || [],
        related_changes: stepD?.value?.related_changes || [],
        dependent_processes: stepD?.value?.dependent_processes || [],
        visibleFieldsReview: [
          { name: 'processSteps', visible: true },
          { name: 'capabilities', visible: true },
          { name: 'people', visible: true },
        ],
        relatedDate: {
          created: stepD?.value?.created || '',
          created_by: stepD?.value?.created_by?.first_name ? `${stepD?.value?.created_by?.first_name || ''} ${stepD?.value?.created_by?.last_name || ''}` : '',
          updated: stepD?.value?.updated || '',
          updated_by: stepD?.value?.updated_by?.first_name ? `${stepD?.value?.updated_by?.first_name || ''} ${stepD?.value?.updated_by?.last_name || ''}` : '',
          version: stepD?.value?.version || 0,
        },
      }));
      dispatch(addProcessExtMtData({ fields: (updated && updated.length > 1 && Array.isArray(updated)) ? updated : [], sections: (formProcessJson?.isSuccess && formProcessJson?.data) ? formProcessJson.data.sections : [] }));
      if ((stepD?.value?.capability_mappings && stepD?.value?.capability_mappings?.length > 0 && Array.isArray(stepD?.value?.capability_mappings))) {
        dispatch(addProcessAppReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addProcessBusinessReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
      }
    }
    setProcessStepDetails(stepD);
    setModal(true);
  };

  useEffect(() => {
    if (stepRes?.isSuccess && stepRes?.data && Array.isArray(stepRes?.data) && stepRes?.data?.length > 0) {
      dispatch(addReadOnlyProcessSteps(stepRes?.data));
    }
  }, [stepRes?.isSuccess]);

  return data === null ? (
    ''
  ) : (
    <>
    <Modal size="lg" scrollable show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Solution Specification - {data?.front_page?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="spec-wrapper p-4" id="sol_spec">
          <h2 className="text-center">{data?.front_page?.name}</h2>
          <div className="d-grid">
            <dl>
              <dt>{t('organizations.name')}:</dt>
              <dd>
                {data?.front_page?.organizations
                  ? data?.front_page?.organizations.map((org) => org.name)
                  : '-'}
              </dd>
            </dl>
            <dl>
              <dt>{t('business_units.name')}:</dt>
              <dd>
                {data?.front_page?.business_units
                  ? data?.front_page?.business_units.map((org) => org.name)
                  : '-'}
              </dd>
            </dl>
            <dl>
              <dt>{t('departments.name')}:</dt>
              <dd>
                {data?.front_page?.departments
                  ? data?.front_page?.departments.map((org) => org.name)
                  : '-'}
              </dd>
            </dl>
            <dl>
              <dt>{screen === 'project' ? 'Project Owner :' : 'Change Owner :'}</dt>
              {data?.front_page?.owners
              && Array.isArray(data?.front_page?.owners)
              && data?.front_page?.owners?.length > 0
                ? data?.front_page?.owners?.map((el) => (
                    <dd className="text-capitalize">{`${el?.first_name} ${el?.last_name}`}</dd>
                  ))
                : '-'}
            </dl>
            <dl>
              <dt>{screen === 'project' ? 'Project Manager :' : 'Change Manager :'}</dt>
              {data?.front_page?.managers
              && Array.isArray(data?.front_page?.managers)
              && data?.front_page?.managers?.length > 0
                ? data?.front_page?.managers?.map((el) => (
                    <dd className="text-capitalize">{`${el?.first_name} ${el?.last_name}`}</dd>
                  ))
                : '-'}
            </dl>
            <dl>
              <dt>{t('status.name')}:</dt>
              <dd>
                {data?.front_page?.status ? data?.front_page?.status : '-'}
              </dd>
            </dl>
          </div>
          <h3>Revision History</h3>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="heading-row">
                  <th>Revision No.</th>
                  <th>Revision Date</th>
                  <th>Modified By</th>
                </tr>
              </thead>
              <tbody>
                {data?.revision_history
                && Array.isArray(data?.revision_history)
                && data?.revision_history?.length > 0 ? (
                  data?.revision_history.map((rh) => (
                    <tr>
                      <td>{rh.version}</td>
                      <td>
                        {rh.as_on_date
                          ? dayjs(rh.as_on_date).format('DD/MM/YYYY')
                          : '-'}
                      </td>
                      <td className="text-capitalize">{`${rh.updated_by.first_name} ${rh.updated_by.last_name}`}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={3}>
                      No Data To Display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="html2pdf__page-break" />
          <h3>Table of Content</h3>
          <ol className="toc-list">
            {screen === 'change'
              && tableContentChange?.map((el, index) => (
                <Fragment key={index}>
                  <li>
                    {el?.level?.map((el2, index2) => (
                      <Fragment key={index2}>
                        <Button
                          variant="link"
                          className="text-decoration-none"
                          onClick={() => handleClickScroll(el2.content)}
                        >
                          <span className="title" key={index2}>
                            {`${index + 1}. ${el2.content}`}
                          </span>
                        </Button>
                        <ol className="toc-list mb-0">
                          {el2?.level?.map((el3, index3) => (
                            <Fragment key={index3}>
                              <li>
                                <Button
                                  variant="link"
                                  className="text-decoration-none"
                                  onClick={() => handleClickScroll(el2?.content === 'Requirement(s)' ? `req ${el3.content}` : `change ${el3.content}`)}
                                >
                                  <span className="title">
                                    {`${index + 1}.${index3 + 1}. ${
                                      el3.content
                                    }`}
                                  </span>
                                </Button>
                                <ol className="toc-list mb-0">
                                  {el3?.level?.map((el4, index4) => (
                                    <Fragment key={index4}>
                                      <li>
                                        <Button
                                          variant="link"
                                          className="text-decoration-none"
                                          onClick={() => handleClickScroll(el2?.content === 'Requirement(s)' ? `req ${index + 1}.${index3 + 1}.${
                                            index4 + 1
                                          }. ${el4.content}` : `change ${index + 1}.${index3 + 1}.${
                                            index4 + 1
                                          }. ${el4.content}`)}
                                        >
                                          <span className="title">
                                            {`${index + 1}.${index3 + 1}.${
                                              index4 + 1
                                            }. ${el4.content}`}
                                          </span>
                                        </Button>
                                        <ol className="toc-list mb-0">
                                          {el4?.level?.map((el5, index5) => (
                                            <Fragment key={index5}>
                                              <li>
                                                <Button
                                                  variant="link"
                                                  className="text-decoration-none"
                                                  onClick={() => handleClickScroll(
                                                    el2?.content === 'Requirement(s)' ? `req ${index + 1}.${
                                                      index3 + 1
                                                    }.${index4 + 1}.${
                                                      index5 + 1
                                                    }. ${el5.content}` : `change ${index + 1}.${
                                                      index3 + 1
                                                    }.${index4 + 1}.${
                                                      index5 + 1
                                                    }. ${el5.content}`,
                                                    )}
                                                >
                                                  <span className="title">
                                                    {`${index + 1}.${
                                                      index3 + 1
                                                    }.${index4 + 1}.${
                                                      index5 + 1
                                                    }. ${el5.content}`}
                                                  </span>
                                                </Button>
                                                <ol className="toc-list mb-0">
                                                  {el5?.level?.map(
                                                    (el6, index6) => (
                                                      <Fragment key={index6}>
                                                        <li>
                                                          <Button
                                                            variant="link"
                                                            className="text-decoration-none"
                                                            onClick={() => handleClickScroll(
                                                              el2?.content === 'Requirement(s)' ? `req ${el6.content}` : `change ${el5?.content} ${el6.content}`,
                                                              )}
                                                          >
                                                            <span className="title">
                                                              {`${index + 1}.${
                                                                index3 + 1
                                                              }.${index4 + 1}.${
                                                                index5 + 1
                                                              }.${
                                                                index6 + 1
                                                              }. ${
                                                                el6.content
                                                              }`}
                                                            </span>
                                                          </Button>
                                                          <ol className="toc-list mb-0">
                                                            {el6?.level?.map(
                                                              (el7, index7) => (
                                                                <Fragment key={index7}>
                                                                  <li>
                                                                    <Button
                                                                      variant="link"
                                                                      className="text-decoration-none"
                                                                      onClick={() => handleClickScroll(
                                                                        el4?.content === 'Requirement(s)' ? `req ${el7.content}` : el4?.content === 'Impacted Capabilities' ? `change ${el7.content}` : el7.content,

                                                                        )}
                                                                    >
                                                                      <span className="title">
                                                                        {`${index + 1}.${
                                                                          index3 + 1
                                                                        }.${index4 + 1}.${
                                                                          index5 + 1
                                                                        }.${
                                                                          index6 + 1
                                                                        }.${
                                                                          index7 + 1
                                                                        }. ${
                                                                          el7.content
                                                                        }`}
                                                                      </span>
                                                                    </Button>
                                                                  </li>
                                                                </Fragment>
                                                              ),
                                                            )}
                                                          </ol>
                                                        </li>
                                                      </Fragment>
                                                    ),
                                                  )}
                                                </ol>
                                              </li>
                                            </Fragment>
                                          ))}
                                        </ol>
                                      </li>
                                    </Fragment>
                                  ))}
                                </ol>
                              </li>
                            </Fragment>
                          ))}
                        </ol>
                      </Fragment>
                    ))}
                  </li>
                </Fragment>
              ))}
            {screen === 'project'
              && tableContentProject?.map((el, index) => (
                <Fragment key={index}>
                  <li>
                    {el?.level?.map((el2, index2) => (
                      <Fragment key={index2}>
                        <Button
                          variant="link"
                          className="text-decoration-none"
                          onClick={() => handleClickScroll(el2.content)}
                        >
                          <span className="title" key={index2}>
                            {`${index + 1}. ${el2.content}`}
                          </span>
                        </Button>
                        <ol className="toc-list mb-0">
                          {el2?.level?.map((el3, index3) => (
                            <Fragment key={index3}>
                              <li>
                                <Button
                                  variant="link"
                                  className="text-decoration-none"
                                  onClick={() => handleClickScroll(`${index + 1}.${index3 + 1}. ${
                                    el3.content
                                  }`)}
                                >
                                  <span className="title">
                                    {`${index + 1}.${index3 + 1}. ${
                                      el3.content
                                    }`}
                                  </span>
                                </Button>
                                <ol className="toc-list mb-0">
                                {console.log('pppppppel3', el3)}
                                  {el3?.level?.map((el4, index4) => (
                                    <Fragment key={index4}>
                                      <li>
                                        <Button
                                          variant="link"
                                          className="text-decoration-none"
                                          onClick={() => handleClickScroll(`${index + 1}.${index3 + 1}.${
                                            index4 + 1
                                          }. ${el4.content}`)}
                                        >
                                          <span className="title">
                                            {`${index + 1}.${index3 + 1}.${
                                              index4 + 1
                                            }. ${el4.content}`}
                                          </span>
                                        </Button>
                                        <ol className="toc-list mb-0">
                                          {el4?.level?.map((el5, index5) => (
                                            <Fragment key={index5}>
                                              <li>
                                                <Button
                                                  variant="link"
                                                  className="text-decoration-none"
                                                  onClick={() => handleClickScroll(
                                                    `${index + 1}.${
                                                      index3 + 1
                                                    }.${index4 + 1}.${
                                                      index5 + 1
                                                    }. ${el5.content}`,
                                                    )}
                                                >
                                                  <span className="title">
                                                    {`${index + 1}.${
                                                      index3 + 1
                                                    }.${index4 + 1}.${
                                                      index5 + 1
                                                    }. ${el5.content}`}
                                                  </span>
                                                </Button>

                                                <ol className="toc-list mb-0">
                                                  {el5?.level?.map(
                                                    (el6, index6) => (
                                                      <Fragment key={index6}>
                                                        <li>
                                                          <Button
                                                            variant="link"
                                                            className="text-decoration-none"
                                                            onClick={() => handleClickScroll(
                                                              `${index + 1}.${
                                                                index3 + 1
                                                              }.${index4 + 1}.${
                                                                index5 + 1
                                                              }.${
                                                                index6 + 1
                                                              }. ${
                                                                el6.content
                                                              }`,
                                                              )}
                                                          >
                                                            <span className="title">
                                                              {`${index + 1}.${
                                                                index3 + 1
                                                              }.${index4 + 1}.${
                                                                index5 + 1
                                                              }.${
                                                                index6 + 1
                                                              }. ${
                                                                el6.content
                                                              }`}
                                                            </span>
                                                          </Button>
                                                        </li>
                                                      </Fragment>
                                                    ),
                                                  )}
                                                </ol>
                                              </li>
                                            </Fragment>
                                          ))}
                                        </ol>
                                      </li>
                                    </Fragment>
                                  ))}
                                </ol>
                              </li>
                            </Fragment>
                          ))}
                        </ol>
                      </Fragment>
                    ))}
                  </li>
                </Fragment>
              ))}
          </ol>
          <div className="html2pdf__page-break" />
          {screen === 'change'
            && tableContentChange?.map((el, index) => (
              <div className="detail-content" key={index}>
                {el?.level?.map((el2, index2) => (
                  <Fragment key={index2}>
                    <h3 id={el2.content}>{`${index + 1}. ${el2.content}`}</h3>
                    {el2?.noData && 'No Data To Display'}
                    {el2?.type === 'table' && el2?.content === 'Capability overview' && (
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Capability</th>
                              <th>Type</th>
                              <th>Description</th>
                              <th>List Of Ongoing Changes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {capOverview
                            && Array.isArray(capOverview)
                            && capOverview?.length > 0 ? (
                              capOverview.map((cv) => (
                                <tr>
                                  <td>
                                    <Button
                                      href={`/review/${cv?.type}/${cv?.id}`}
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                    >
                                      {cv.name}
                                    </Button>
                                  </td>
                                  <td className="text-capitalize">
                                    {cv?.type}
                                  </td>
                                  <td>
                                    <ReadMoreLess text={cv.description} />
                                  </td>
                                  <td>
                                    <ol>
                                      {cv?.ongoing_changes?.map((cvh) => (
                                        <li>
                                          <Button
                                            href={`/review/change/${cvh.id}`}
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                          >
                                            {cvh.name}
                                          </Button>
                                        </li>
                                      ))}
                                    </ol>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan={3}>No Data To Display</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                     {el2?.type === 'table' && el2?.content === 'Requirement(s)'
                            && (
                              <div className="table-responsive box-shadow mb-4">
                                            <table className="table table-hover">
                                              <thead>
                                                <tr className="heading-row">
                                                  <th>Name</th>
                                                  <th>Description</th>
                                                  <th>Notes</th>
                                                  <th>Status</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {(el2?.requirements && Array.isArray(el2?.requirements) && el2?.requirements?.length > 0)
                                                  ? el2?.requirements?.map((ele) => (
                                                    <tr key={ele?.id}>
                                                      <td><Button href={`/review/requirement/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name}</Button></td>
                                                      <td>
                                                        <ReadMoreLess text={ele?.description} />
                                                      </td>
                                                      <td>
                                                        <ReadMoreLess text={ele?.notes} />
                                                      </td>
                                                      <td>{ele?.status || '-'}</td>
                                                    </tr>
                                                  ))
                                                  : <tr><td colSpan={4} className="text-center">No Data To Display</td></tr>}
                                              </tbody>
                                            </table>
                              </div>
                            )}
                    {el2?.level?.map((el3, index3) => (
                      <Fragment key={index3}>
                        <h5 id={el2?.content === 'Requirement(s)' ? `req ${el3.content}` : `change ${el3.content}`} key={index3}>
                          {`${index + 1}.${index3 + 1}. ${el3.content}`}
                        </h5>
                        {el3?.type === 'texteditor' ? (
                              <Editor
                                editorState={
                                  el3.subContent
                                    ? EditorState.createWithContent(
                                        ContentState.createFromBlockArray(
                                          htmlToDraft(
                                            el3?.subContent
                                              ? el3?.subContent
                                              : '',
                                          ),
                                        ),
                                      )
                                    : EditorState.createEmpty()
                                }
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                toolbarHidden
                              />
                            ) : el3?.type === 'textarea' ? (
                              <textarea
                                className="form-control"
                                defaultValue={el3?.subContent}
                                readOnly
                                style={{ overflow: 'auto' }}
                              />
                            ) : (el3?.type === 'table' && el3?.content === 'Requirement(s)')
                            ? (
                              <div className="table-responsive box-shadow mb-4">
                                            <table className="table table-hover">
                                              <thead>
                                                <tr className="heading-row">
                                                  <th>Name</th>
                                                  <th>Description</th>
                                                  <th>Notes</th>
                                                  <th>Status</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {(el3?.requirements && Array.isArray(el3?.requirements) && el3?.requirements?.length > 0)
                                                  ? el3?.requirements?.map((ele) => (
                                                    <tr key={ele?.id}>
                                                      <td><Button href={`/review/requirement/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name}</Button></td>
                                                      <td>
                                                        <ReadMoreLess text={ele?.description} />
                                                      </td>
                                                      <td>
                                                        <ReadMoreLess text={ele?.notes} />
                                                      </td>
                                                      <td>{ele?.status || '-'}</td>
                                                    </tr>
                                                  ))
                                                  : <tr><td colSpan={4} className="text-center">No Data To Display</td></tr>}
                                              </tbody>
                                            </table>
                              </div>
                            ) : (el3?.type === 'table' && el3?.content === 'Work Packages')
                            ? (
                              <div className="table-responsive box-shadow mb-4">
                                            <table className="table table-hover">
                                              <thead>
                                                <tr className="heading-row">
                                                  <th>Name</th>
                                                  <th>Start Date</th>
                                                  <th>End Date</th>
                                                  <th>Status</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {(el3?.epics && Array.isArray(el3?.epics) && el3?.epics?.length > 0)
                                                  ? el3?.epics?.map((ele) => (
                                                    <tr key={ele?.id}>
                                                      <td><Button href={`/review/epic/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele?.name}</Button></td>
                                                      <td>
                                                        {dayjs(ele?.start_date).format(userData?.tenant_info?.date_format) || '-'}
                                                      </td>
                                                      <td>
                                                        {dayjs(ele?.end_date).format(userData?.tenant_info?.date_format) || '-'}
                                                      </td>
                                                      <td>{ele?.status || '-'}</td>
                                                    </tr>
                                                  ))
                                                  : <tr><td colSpan={4} className="text-center">No Data To Display</td></tr>}
                                              </tbody>
                                            </table>
                              </div>
                            )
                            : (
                              <p>{el3.subContent}</p>
                            )}

                        {el3?.level?.map((el4, index4) => (
                          <Fragment key={index4}>
                            <h5
                              id={el2?.content === 'Requirement(s)' ? `req ${index + 1}.${index3 + 1}.${index4 + 1}. ${
                                el4.content
                              }` : `change ${index + 1}.${index3 + 1}.${index4 + 1}. ${
                                el4.content
                              }`}
                              key={index4}
                            >
                              {`${index + 1}.${index3 + 1}.${index4 + 1}. ${
                                el4.content
                              }`}
                            </h5>

                            {el4?.level?.map((el5, index5) => (
                              <Fragment key={index5}>
                                <h5
                                  id={el2?.content === 'Requirement(s)' ? `req ${index + 1}.${index3 + 1}.${index4 + 1}.${
                                    index5 + 1
                                  }. ${el5.content}` : `change ${index + 1}.${index3 + 1}.${index4 + 1}.${
                                    index5 + 1
                                  }. ${el5.content}`}
                                  key={index5}
                                >
                                  {`${index + 1}.${index3 + 1}.${index4 + 1}.${
                                    index5 + 1
                                  } ${el5.content}`}
                                </h5>

                                    {el5?.type === 'table' && (
                                      <div className="table-responsive">
                                        <table className="table table-hover">
                                          <thead>
                                            <tr className="heading-row">
                                              <th>Step Name</th>
                                              <th>Description</th>
                                              <th>Depending Capabilities</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el5?.steps
                                            && Array.isArray(el5?.steps)
                                            && el5?.steps?.length > 0 ? (
                                              el5?.steps?.map((sp) => (
                                                <tr>
                                                  <td>
                                                    <Button
                                                      className="text-decoration-none"
                                                      variant="link"
                                                      onClick={() => handleShowProceeDetails(sp)}
                                                    >
                                                      {sp?.value?.name}
                                                    </Button>
                                                  </td>
                                                  <td>
                                                    <ReadMoreLess
                                                      text={
                                                        sp?.value?.description
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <ol>
                                                  {sp?.value?.capability_mappings && Array.isArray(sp?.value?.capability_mappings)
                                                  && sp?.value?.capability_mappings?.length > 0

                                                  ? sp?.value?.capability_mappings?.map((scm) => scm?.value?.map((sv) => (
                                                  <li key={sv?.id}>
                                                    <Button
                                                      href={`/review/${scm?.key === ('Process' || 'process') ? 'process' : scm?.key}/${sv?.id}`}
                                                      variant="link"
                                                      target="_blank"
                                                      className="text-decoration-none"
                                                    >
                                                    {sv?.name}
                                                    </Button>
                                                  </li>

                                                  ))) : '-'}
                                                    </ol>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr className="text-center">
                                                <td colSpan={3}>
                                                  No Data To Display
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                    {el5?.process_diagram
                                     && (
                                    <div className="canvas-wrapper mb-4">
                                     <div id="review-pdf" className="flow-wrapper">
                                       <div className="flow-chart">
                                         <div className="step-endpoints start-point">
                                           <span>start</span>
                                         </div>
                                         {
                                           (el5?.steps && el5?.steps.length > 0) && el5?.steps.map(((cs, i) => (
                                             <div
                                               className={`flow-item ${cs?.type === 'Process' ? 'sub-process' : ''}`}
                                               onClick={() => handleShowProceeDetails(cs)}
                                             >
                                               <div className="flow-header">
                                                 {cs?.type === 'Process Step'
                                                   && (
                                                     <p className="mb-0">
                                                       {cs?.value?.name}
                                                     </p>
                                                   )}
                                               </div>

                                               {cs?.type === 'Process'
                                                 ? (
                                                   <div
                                                     className="flow-body d-flex align-items-center justify-content-between"
                                                     style={{ cursor: 'pointer' }}
                                                   >
                                                     <div className="process-icon">
                                                       <span className="icon-value-chain" />
                                                     </div>
                                                     <p className="mb-0">{cs?.value?.name}</p>
                                                     <span className="process-count ml-auto">{Array.isArray(cs?.value?.step_mappings) && `${cs?.value?.step_mappings?.length} Steps`}</span>
                                                   </div>
                                                 ) : (
                                                   <div
                                                     className="flow-body d-flex align-items-center justify-content-between"
                                                     style={{ cursor: 'pointer' }}
                                                   >
                                                     <ul className="list-unstyled mb-0 cap-icons d-flex align-items-center">
                                                       {cs?.value?.mode !== 'current' && (
                                                         cs?.value?.capability_mappings
                                                         && Array.isArray(cs?.value?.capability_mappings)
                                                         && cs?.value?.capability_mappings?.length > 0)
                                                         && (
                                                           cs?.value?.capability_mappings.map((cm) => (
                                                             <li key={cm?.key}>
                                                               <span className={cm.key === 'application' ? 'icon-application' : cm.key === 'business' ? 'icon-business' : 'icon-value-chain'} />
                                                               {cm?.value?.length > 1 ? <i className="cap-count">{`+${cm.value.length - 1}`}</i> : ''}
                                                             </li>
                                                           ))
                                                         )}
                                                     </ul>
                                                   </div>
                                                 )}
                                             </div>
                                           )
                                           ))
                                         }
                                         <div className="step-endpoints finish-point">
                                           <span>end</span>
                                         </div>
                                       </div>
                                     </div>
                                    </div>
)}
                                    {el5?.type === 'texteditor' ? (
                                      <Editor
                                        editorState={
                                          el5?.subContent
                                            ? EditorState.createWithContent(
                                                ContentState.createFromBlockArray(
                                                  htmlToDraft(
                                                    el5?.subContent
                                                      ? el5?.subContent
                                                      : '',
                                                  ),
                                                ),
                                              )
                                            : EditorState.createEmpty()
                                        }
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        toolbarHidden
                                      />
                                    ) : el5?.type === 'textarea' ? (
                                      <textarea
                                        className="form-control"
                                        defaultValue={el5?.subContent}
                                        readOnly
                                        style={{ overflow: 'auto' }}
                                      />
                                    ) : el5?.type === 'document' ? (
                                      el5?.documents && Array.isArray(el5?.documents) && el5?.documents?.length > 0
                                      ? (
                                        <ol>
                                        {el5?.documents?.map((dc) => (
                                                <li>
                                                  <Button
                                                    variant="link"
                                                    onClick={(e) => downLoadFile(dc)}
                                                    href={dc?.pre_signed_url}
                                                    key={dc?.original_name}
                                                  >
                                                  {dc?.file_name}
                                                  </Button>
                                                </li>
                                              ))}
                                        </ol>
                                      )
                                        : <p>No Document To Display</p>
                                    )
                                     : (
                                      <p>{el5?.subContent}</p>
                                    )}

                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </div>
            ))}
          {screen === 'project'
            && tableContentProject?.map((el, index) => (
              <div className="detail-content" key={index}>
                {el?.level?.map((el2, index2) => (
                  <Fragment key={index2}>
                    <h3 id={el2.content}>{`${index + 1}. ${el2.content}`}</h3>
                    {el2?.noData && 'No Data To Display'}
                    {el2?.type === 'table' && (
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr className="heading-row">
                              <th>Capability</th>
                              <th>Type</th>
                              <th>Description</th>
                              <th>List Of Ongoing Changes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {capOverview
                            && Array.isArray(capOverview)
                            && capOverview?.length > 0 ? (
                              capOverview.map((cv) => (
                                <tr>
                                  <td>
                                    <Button
                                      href={`/review/${cv?.type}/${cv?.id}`}
                                      className="text-decoration-none"
                                      variant="link"
                                      target="_blank"
                                    >
                                      {cv.name}
                                    </Button>
                                  </td>
                                  <td className="text-capitalize">
                                    {cv?.type}
                                  </td>
                                  <td>
                                    <ReadMoreLess text={cv.description} />
                                  </td>
                                  <td>
                                    <ol>
                                      {cv?.ongoing_changes?.map((cvh) => (
                                        <li>
                                          <Button
                                            href={`/review/change/${cvh.id}`}
                                            className="text-decoration-none"
                                            variant="link"
                                            target="_blank"
                                          >
                                            {cvh.name}
                                          </Button>
                                        </li>
                                      ))}
                                    </ol>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan={3}>No Data To Display</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {el2?.level?.map((el3, index3) => (
                      <Fragment key={index3}>
                        <h5 id={`${index + 1}.${index3 + 1}. ${el3?.content}`} key={index3}>
                          {`${index + 1}.${index3 + 1}. ${el3?.content}`}
                        </h5>
                        {el3?.type === 'texteditor' ? (
                          <Editor
                            editorState={
                              el3.subContent
                                ? EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                      htmlToDraft(
                                        el3?.subContent ? el3?.subContent : '',
                                      ),
                                    ),
                                  )
                                : EditorState.createEmpty()
                            }
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            toolbarHidden
                          />
                        ) : el3?.type === 'textarea' ? (
                          <textarea
                            className="form-control"
                            defaultValue={el3?.subContent}
                            readOnly
                            style={{ overflow: 'auto' }}
                          />
                        ) : (
                          <p>{el3.subContent}</p>
                        )}
                        {el3?.object_type === 'project' && (
                          <p>
                            The purpose of this specification is to secure that
                            stakeholders have a clear view on the scope to be
                            delivered in the{' '}
                            <strong>{data?.front_page.name}</strong>
                            {data?.front_page?.related_programs
                            && Array.isArray(data?.front_page?.related_programs)
                            && data?.front_page?.related_programs.length > 0
                              ? ' and '
                              : '.'}
                            {data?.front_page?.related_programs
                              && Array.isArray(
                                data?.front_page?.related_programs,
                              )
                              && data?.front_page?.related_programs?.map(
                                (pr, i) => <strong key={i}>{pr.name}. </strong>,
                              )}
                          </p>
                        )}
                        {el3?.content === 'Requirements and Changes'
                          && el3?.type === 'table' && (
                            <>
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr className="heading-row">
                                    <th>Requirement Name</th>
                                    <th>Requirement ID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reqList
                                  && Array.isArray(reqList)
                                  && reqList?.length > 0 ? (
                                    reqList?.map((rq) => (
                                      <tr>
                                        <td>
                                          <Button
                                            href={`/review/requirement/${rq?.id}`}
                                            className="text-decoration-none"
                                            target="_blank"
                                            variant="link"
                                          >
                                            {rq?.name}
                                          </Button>
                                        </td>
                                        <td>{rq?.unique_id}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan={2}>
                                        No Data To Display
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr className="heading-row">
                                    <th>Change Name</th>
                                    <th>Change ID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.changes
                                  && Array.isArray(data?.changes)
                                  && data?.changes?.length > 0 ? (
                                    data?.changes?.map((ch) => (
                                      <tr>
                                        <td>
                                          <Button
                                            href={`/review/change/${ch?.id}`}
                                            className="text-decoration-none"
                                            target="_blank"
                                            variant="link"
                                          >
                                            {ch?.name}
                                          </Button>
                                        </td>
                                        <td>{ch?.unique_id}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan={2}>
                                        No Data To Display
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            </>
                          )}
                        {el3?.content === 'Related Programs and Projects'
                          && el3?.type === 'table' && (
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr className="heading-row">
                                    <th>Name</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.front_page?.related_programs
                                  && Array.isArray(
                                    data?.front_page?.related_programs,
                                  )
                                  && data?.front_page?.related_programs?.length
                                    > 0 ? (
                                    data?.front_page?.related_programs?.map(
                                      (rp) => (
                                        <tr key={rp?.id}>
                                          <td>
                                            <Button
                                              href={`/review/program/${rp?.id}`}
                                              className="text-decoration-none"
                                              target="_blank"
                                              variant="link"
                                            >
                                              {rp?.name}
                                            </Button>
                                          </td>
                                          <td>
                                            <ReadMoreLess
                                              text={rp?.description}
                                            />
                                          </td>
                                        </tr>
                                      ),
                                    )
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan={2}>
                                        No Data To Display
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {el3?.level?.map((el4, index4) => (
                          <Fragment key={index4}>
                            <h5
                              id={`${index + 1}.${index3 + 1}.${index4 + 1}. ${
                                el4.content
                              }`}
                              key={index4}
                            >
                              {`${index + 1}.${index3 + 1}.${index4 + 1}. ${
                                el4.content
                              }`}
                            </h5>
                            {el4?.type === 'table'
                              ? (
                                <div className="table-responsive">
                                                            <table className="table table-hover">
                                                              <thead>
                                                                <tr className="heading-row">
                                                                  <th>Name</th>
                                                                  <th>Description</th>
                                                                  <th>Comment</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {/* {reqList
                                                                && Array.isArray(reqList)
                                                                && reqList?.length > 0 ? (
                                                                  reqList?.map((rq) => (
                                                                    <tr>
                                                                      <td>
                                                                        <Button
                                                                          href={`/review/requirement/${rq?.id}`}
                                                                          className="text-decoration-none"
                                                                          target="_blank"
                                                                          variant="link"
                                                                        >
                                                                          {rq?.name}
                                                                        </Button>
                                                                      </td>
                                                                      <td>{rq?.unique_id}</td>
                                                                    </tr>
                                                                  ))
                                                                ) : ( */}
                                                                  <tr>
                                                                    <td className="text-center" colSpan={3}>
                                                                      No Data To Display
                                                                    </td>
                                                                  </tr>
                                                                {/* )} */}
                                                              </tbody>
                                                            </table>
                                </div>
                              )
                              : el4?.type === 'texteditor' ? (
                                  <Editor
                                    editorState={
                                      el4.subContent
                                        ? EditorState.createWithContent(
                                            ContentState.createFromBlockArray(
                                              htmlToDraft(
                                                el4?.subContent
                                                  ? el4?.subContent
                                                  : '',
                                              ),
                                            ),
                                          )
                                        : EditorState.createEmpty()
                                    }
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    toolbarHidden
                                  />
                              ) : el4?.type === 'textarea' ? (
                                <textarea
                                  className="form-control"
                                  defaultValue={el4?.subContent}
                                  readOnly
                                  style={{ overflow: 'auto' }}
                                />
                              ) : el4?.type === 'document' ? (
                                el4?.documents && Array.isArray(el4?.documents) && el4?.documents?.length > 0
                                ? (
                                  <ol>
                                  {el4?.documents?.map((dc) => (
                                          <li>
                                            <Button
                                              variant="link"
                                              onClick={(e) => downLoadFile(dc)}
                                              href={dc?.pre_signed_url}
                                              key={dc?.original_name}
                                            >
                                            {dc?.file_name}
                                            </Button>
                                          </li>
                                        ))}
                                  </ol>
                                )
                                  : <p>No Document To Display</p>
                              ) : (
                                <p>{el4.subContent}</p>
                              )}
                            {el4?.level?.map((el5, index5) => (
                              <Fragment key={index5}>
                                <h5
                                  id={`${index + 1}.${index3 + 1}.${index4 + 1}.${
                                    index5 + 1
                                  }. ${el5.content}`}
                                  key={index5}
                                >
                                  {`${index + 1}.${index3 + 1}.${index4 + 1}.${
                                    index5 + 1
                                  } ${el5.content}`}
                                </h5>
                                {el5?.level?.map((el6, index6) => (
                                  <Fragment key={index6}>
                                    <h5
                                      id={`${index + 1}.${index3 + 1}.${
                                        index4 + 1
                                      }.${index5 + 1}.${index6 + 1}. ${
                                        el6?.content
                                      }`}
                                      key={index6}
                                    >
                                      {`${index + 1}.${index3 + 1}.${
                                        index4 + 1
                                      }.${index5 + 1}.${index6 + 1} ${
                                        el6?.content
                                      }`}
                                    </h5>

                                    {el6?.type === 'table' && (
                                      <div className="table-responsive">
                                        <table className="table table-hover">
                                          <thead>
                                            <tr className="heading-row">
                                              <th>Step Name</th>
                                              <th>Description</th>
                                              <th>Depending Capabilities</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el6?.steps
                                            && Array.isArray(el6?.steps)
                                            && el6?.steps?.length > 0 ? (
                                              el6?.steps?.map((sp) => (
                                                <tr>
                                                  <td>
                                                    <Button
                                                      className="text-decoration-none"
                                                      variant="link"
                                                      onClick={() => handleShowProceeDetails(sp)}
                                                    >
                                                      {sp?.value?.name}
                                                    </Button>
                                                  </td>
                                                  <td>
                                                    <ReadMoreLess
                                                      text={
                                                        sp?.value?.description
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <ol>
                                                  {sp?.value?.capability_mappings && Array.isArray(sp?.value?.capability_mappings)
                                                  && sp?.value?.capability_mappings?.length > 0

                                                  ? sp?.value?.capability_mappings?.map((scm) => scm?.value?.map((sv) => (
                                                  <li key={sv?.id}>
                                                    <Button
                                                      href={`/review/${scm?.key === ('Process' || 'process') ? 'process' : scm?.key}/${sv?.id}`}
                                                      variant="link"
                                                      target="_blank"
                                                      className="text-decoration-none"
                                                    >
                                                    {sv?.name}
                                                    </Button>
                                                  </li>

                                                  ))) : '-'}
                                                    </ol>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr className="text-center">
                                                <td colSpan={3}>
                                                  No Data To Display
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                    {el6?.process_diagram
                                     && (
                                    <div className="canvas-wrapper mb-4">
                                     <div id="review-pdf" className="flow-wrapper">
                                       <div className="flow-chart">
                                         <div className="step-endpoints start-point">
                                           <span>start</span>
                                         </div>
                                         {
                                           (el6?.steps && el6?.steps.length > 0) && el6?.steps.map(((cs, i) => (
                                             <div
                                               className={`flow-item ${cs?.type === 'Process' ? 'sub-process' : ''}`}
                                               onClick={() => handleShowProceeDetails(cs)}
                                             >
                                               <div className="flow-header">
                                                 {cs?.type === 'Process Step'
                                                   && (
                                                     <p className="mb-0">
                                                       {cs?.value?.name}
                                                     </p>
                                                   )}
                                               </div>

                                               {cs?.type === 'Process'
                                                 ? (
                                                   <div
                                                     className="flow-body d-flex align-items-center justify-content-between"
                                                     style={{ cursor: 'pointer' }}
                                                   >
                                                     <div className="process-icon">
                                                       <span className="icon-value-chain" />
                                                     </div>
                                                     <p className="mb-0">{cs?.value?.name}</p>
                                                     <span className="process-count ml-auto">{Array.isArray(cs?.value?.step_mappings) && `${cs?.value?.step_mappings?.length} Steps`}</span>
                                                   </div>
                                                 ) : (
                                                   <div
                                                     className="flow-body d-flex align-items-center justify-content-between"
                                                     style={{ cursor: 'pointer' }}
                                                   >
                                                     <ul className="list-unstyled mb-0 cap-icons d-flex align-items-center">
                                                       {cs?.value?.mode !== 'current' && (
                                                         cs?.value?.capability_mappings
                                                         && Array.isArray(cs?.value?.capability_mappings)
                                                         && cs?.value?.capability_mappings?.length > 0)
                                                         && (
                                                           cs?.value?.capability_mappings.map((cm) => (
                                                             <li key={cm?.key}>
                                                               <span className={cm.key === 'application' ? 'icon-application' : 'icon-value-chain'} />
                                                               {cm?.value?.length > 1 ? <i className="cap-count">{`+${cm.value.length - 1}`}</i> : ''}
                                                             </li>
                                                           ))
                                                         )}
                                                     </ul>
                                                   </div>
                                                 )}
                                             </div>
                                           )
                                           ))
                                         }
                                         <div className="step-endpoints finish-point">
                                           <span>end</span>
                                         </div>
                                       </div>
                                     </div>
                                    </div>
)}
                                    {el6?.type === 'texteditor' ? (
                                      <Editor
                                        editorState={
                                          el6?.subContent
                                            ? EditorState.createWithContent(
                                                ContentState.createFromBlockArray(
                                                  htmlToDraft(
                                                    el6?.subContent
                                                      ? el6?.subContent
                                                      : '',
                                                  ),
                                                ),
                                              )
                                            : EditorState.createEmpty()
                                        }
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        toolbarHidden
                                      />
                                    ) : el6?.type === 'textarea' ? (
                                      <textarea
                                        className="form-control"
                                        defaultValue={el6?.subContent}
                                        readOnly
                                        style={{ overflow: 'auto' }}
                                      />
                                    ) : el6?.type === 'document' ? (
                                      el6?.documents && Array.isArray(el6?.documents) && el6?.documents?.length > 0
                                      ? (
                                        <ol>
                                        {el6?.documents?.map((dc) => (
                                                <li>
                                                  <Button
                                                    variant="link"
                                                    onClick={(e) => downLoadFile(dc)}
                                                    href={dc?.pre_signed_url}
                                                    key={dc?.original_name}
                                                  >
                                                  {dc?.file_name}
                                                  </Button>
                                                </li>
                                          ))}
                                        </ol>
                                        )
                                        : <p>No Document To Display</p>
                                    )
                                     : (
                                      <p>{el6.subContent}</p>
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </div>
            ))}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
      <Button variant="button" id="print" className="mx-auto btn-primary" onClick={() => printToPDF('sol_spec', 'SolSpec.pdf')}>Print</Button>
      </Modal.Footer> */}
    </Modal>
    <ProcessStepViewModal
      show={modal}
      onHide={setModal}
      details={processStepDetails}
    />
    </>
  );
}
