/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TablePeople from '../../components/common/TablePeople';
import { useGetAllPeopleQuery } from '../../services/services';
import { addPeople } from '../../services/peopleSlice';
import { addIssueResPeople } from '../../services/issueSlice';

function IssueResponsiblePeople() {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('people');
  const dispatch = useDispatch();
  const tableData = useGetAllPeopleQuery();

  const issueEditMode = useSelector((state) => state.issueData.issueEditMode);
  const issueResPeople = useSelector((state) => state.issueData.issueResPeople);
  console.log('issueResPeople', issueResPeople);

  const [resPerson, setResPerson] = useState(false);
  const [resPersonData, setResPersonData] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (issueEditMode && issueResPeople && Array.isArray(issueResPeople) && issueResPeople?.length > 0) {
      setResPerson(true);
      setResPersonData(issueResPeople);
    }
  }, [issueEditMode]);

  useEffect(() => {
    if (tableData.isSuccess) {
      setDataTable([...tableData.data.tableData]);
    }
  }, [tableData]);

  const checkHandler = (e, d) => {
    e.stopPropagation();
    setResPerson(true);
    if (e.target.checked) {
      setResPersonData([d]);
      dispatch(addIssueResPeople([d]));
    } else {
      setResPersonData([]);
      dispatch(addIssueResPeople([]));
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    setResPersonData(resPersonData.filter((value) => value.id !== name.id));
    dispatch(
      addIssueResPeople(resPersonData.filter((value) => value.id !== name.id)),
    );
  };

  const getCheckedVal = (nameVal) => {
    if (resPerson && resPersonData.some((el) => el.id === nameVal)) return true;
    return false;
  };

  const componentHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (resPersonData) {
      const data = {
        responsible:
          resPersonData
          && resPersonData?.length > 0
          && Array.isArray(resPersonData)
            ? resPersonData?.map((el) => ({
              id: el.id,
              email: el.email,
              first_name: el?.first_name,
              last_name: el?.last_name,
              designation_id: {
                id: el?.designation_id?.id,
                name: el?.designation_id?.name,
              },
              department_id: {
                id: el?.department_id?.id,
                name: el?.department_id?.name,
              },
            }))
            : [],
      };

      dispatch(addPeople(data));
    }
  };

  return (
    <div className="mb-2">
      <h2>Responsible People</h2>
      <div className="row add-people">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="program_subj">
              Responsible
              <sup>*</sup>
              <span className="people-icon owner-icon">RP</span>
            </label>
            <div
              className="form-control"
              type="text"
              name="subject_name"
              id="program_subj"
            >
              <div className="md-chips">
                {resPersonData
                  && resPersonData?.length > 0
                  && Array.isArray(resPersonData)
                  && resPersonData.map((val) => (
                    <div key={val.id} className="md-chip">
                      <span className="text-capitalize">
                        {`${
                          val?.first_name || ''
                        } ${val?.last_name || ''}`}

                      </span>
                      <button
                        aria-label="remove-stcom"
                        type="button"
                        className="md-chip-remove"
                        onClick={(e) => removeHandler(e, 'resp_person', val)}
                      >
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Select People</h2>
      <TablePeople
        checkHandler={checkHandler}
        userData={dataTable}
        getCheckedVal={getCheckedVal}
        // selVal={selVal}
        resPersonData={resPersonData}
        tableMessage={t('people:table_message')}
      />

    </div>
  );
}

const IssuePeople = React.memo(IssueResponsiblePeople);
export default IssuePeople;
