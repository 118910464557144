/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function LeaveModal({
  show, onHide, func, label,
}) {
  const location = useLocation();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="alert alert-warning d-flex align-items-center"
          role="alert"
        >
          <span className="icon-warning" />
          <p className="mb-0">
            You have unsaved changes,  .
            <br />
            <br />
            <strong>
              Your changes will be lost, proceed anyway?.
            </strong>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => func(true)}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}
