/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  selectBU, selectDeprt, selectDomain, selectOrg, setStepBU, setStepDeprt, setStepOrg,
} from '../../services/formSlice';
import CountyData from '../common/country.json';

export default function ReactSelect({
  index, fld, errors, colourStyles, t, label, handleChange, values, editMode, dataTestId,
  buData, depData, epicInputDisable, changeInputDisable, inputDisable,
  disableProcessStep,
}) {
  console.log('label', label, editMode);

  console.log('fld', fld);
  console.log('loooooooo', values);

  console.log('disableProcessStep', disableProcessStep);
  const dispatch = useDispatch();
  const [targetSelect, setTargetSelect] = useState('');

  const handleSelectChange = (value, name) => {
    const target = { name, value: value.value };
    handleChange({ target });
    if (name === 'business_units') {
      let bussinessU;
      buData?.filter((el) => {
        if (value.value === el.id) {
          bussinessU = { id: el.id, name: el.name };
        }
        return el;
      });
      if (label === 'addStep') {
        dispatch(setStepBU([bussinessU]));
      } else {
        dispatch(selectBU([bussinessU]));
      }
    }
    if (name === 'departments') {
      let departments;
      depData?.filter((el) => {
        if (value.value === el.id) {
          departments = { id: el.id, name: el.name };
        }
        return el;
      });
      if (label === 'addStep') {
        dispatch(setStepDeprt([departments]));
      } else {
        dispatch(selectDeprt([departments]));
      }
    }
    if (name === 'organizations') {
      let organizations;
      fld.field_value_option.options.filter((el) => {
        if (value.value === el.value) {
          organizations = { id: el.value, name: el.name };
        }
        return el;
      });
      if (label === 'addStep') {
        if (!value.value) {
          dispatch(setStepOrg([]));
        } else {
          dispatch(setStepOrg([organizations]));
        }
        dispatch(setStepBU([]));
        dispatch(setStepDeprt([]));
      }
      if (label !== 'addStep') {
        if (!value.value) {
          dispatch(selectOrg([]));
        } else {
          dispatch(selectOrg([organizations]));
        }
        dispatch(selectBU([]));
        dispatch(selectDeprt([]));
      }
    }
    if (name === 'domain') {
      let domain;
      fld.field_value_option.options.filter((el) => {
        if (value.value === el.value) {
          domain = { id: el.value, name: el.name };
        }
        return el;
      });
      dispatch(selectDomain([domain]));
    }
  };

  const handleSelect = (target) => {
    setTargetSelect(target);
  };

  return (
    <div className="col-6 col-lg-4" key={index}>
      <div className="form-group">
        <label htmlFor={fld.field_name}>
          {fld.field_name === 'organizations' ? t(t(`${label}:${fld.field_name}`).name) : label === 'addStep' ? t(`${fld.field_name}.name`) : t(t(`${label}:${fld.field_name}`).name)}

          {fld.field_value_option.validation && <sup>*</sup>}
        </label>

        <Select
          className="react-select-container"
          data-test-id={dataTestId}
          classNamePrefix="react-select"
          options={
            fld?.field_name === 'location'
              ? CountyData
              : fld?.field_name === 'business_units' && buData && Array.isArray(buData) && buData?.length > 0
                ? buData.map(
                  (op) => {
                    const data = { value: op.id, name: fld.field_name, label: op.name };
                    delete data.name;
                    return data;
                  },
                )
                : fld?.field_name === 'business_units' && buData && Array.isArray(buData) && !buData?.length ? []
                  : fld?.field_name === 'departments' && depData && depData?.length > 0 && Array.isArray(depData)
                    ? depData.map(
                      (op) => {
                        const data = { value: op.id, name: fld.field_name, label: op.name };
                        delete data.name;
                        return data;
                      },
                    )
                    : fld?.field_name === 'departments' && depData && Array.isArray(depData) && !depData?.length ? []
                      : (fld?.field_name !== 'business_units' || fld?.field_name !== 'departments') && (fld.field_value_option.options && fld.field_value_option.options?.length > 0 && Array.isArray(fld.field_value_option.options)) && fld.field_value_option.options.map(
                        (op) => {
                          const data = { ...op, name: fld.field_name, label: op.name };
                          delete data.name;
                          return data;
                        },
                      )
          }
          styles={colourStyles}
          onChange={(val) => handleSelectChange(val, fld.field_name)}
          value={
            fld?.field_name === 'location' ? CountyData?.filter((op) => op?.value?.toString() === values[fld.field_name]?.toString())
              : fld?.field_name === 'business_units' && values[fld.field_name]
                ? (buData && buData?.length > 0 && Array.isArray(buData))
              && buData.map((op) => {
                const data = { value: op.id, label: op.name };
                delete data.name;
                return data;
              }).find(
                (c) => (c.value?.toString() === values[fld.field_name]?.toString()),
              )
                : fld?.field_name === 'departments' && values[fld.field_name]
                  ? (depData && depData?.length > 0 && Array.isArray(depData))
                && depData.map((op) => {
                  const data = { value: op.id, label: op.name };
                  delete data.name;
                  return data;
                }).find(
                  (c) => (c.value?.toString() === values[fld.field_name]?.toString()),
                )
                  : (fld?.field_name !== 'business_units' || fld?.field_name !== 'departments') && values[fld?.field_name]
                    ? (fld.field_value_option.options && fld.field_value_option.options?.length > 0 && Array.isArray(fld.field_value_option.options))
                  && fld.field_value_option.options.map((op) => {
                    const data = { ...op, label: op.name };
                    delete data.name;
                    return data;
                  }).find(
                    (c) => (c.value?.toString() === values[fld.field_name]?.toString()),
                  ) : ''
          }
          isSearchable={fld?.field_name === 'location'}
          onFocus={() => handleSelect(fld.field_name)}
          isDisabled={(
            targetSelect === 'business_units' && !values?.organizations)
            || (targetSelect === 'departments' && !values?.business_units)
            || (fld.field_name === 'status' && (values[fld.field_name] === 'New' || values[fld.field_name] === 'New '))
            || (fld.field_name !== 'status' && fld.field_name !== 'defect_status' && epicInputDisable)
            || (fld.field_name !== 'status' && changeInputDisable)
            || (fld.field_name !== 'status' && inputDisable)
            || (fld.field_name === 'defect_status' && values?.status !== 'Test')
            || fld?.isDisabled}
          isOptionDisabled={(option) => (!editMode && fld.field_name === 'status' && fld.display_name !== 'Risk Mitigation Status' && !(option.value === 'New' || option.value === 'New ')) || (label === 'benefit:trackingPointModal' && !editMode && option.value === 'Closed')}
          placeholder={((label === 'program' || label === 'initiative') && (fld.field_name === 'confidentiality' || fld.field_name === 'priority' || fld.field_name === 'category' || fld.field_name === 'cost_center_id')) ? 'None' : 'Select'}
        />
        {errors && errors[fld.field_name] && (
          <div className="invalid-feedback">
            <span className="icon-error-red" />
            {errors[fld.field_name]}
          </div>
        )}
      </div>
    </div>
  );
}
