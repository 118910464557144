import React, { useEffect } from 'react';
import anychart from 'anychart';

function GanntChart() {
  const data = [
    {
      id: '1',
      name: 'Development',
      actualStart: '2018-01-25',
      actualEnd: '2018-03-10',
      children: [
        {
          id: '1_1',
          name: 'Analysis',
          actualStart: '2018-01-25',
          actualEnd: '2018-02-08',
        },
        {
          id: '1_2',
          name: 'Design',
          actualStart: '2018-02-04',
          actualEnd: '2018-02-14',
        },
        {
          id: '1_3',
          name: 'Meeting',
          actualStart: '2018-02-15',
          actualEnd: '2018-02-15',
        },
        {
          id: '1_4',
          name: 'Implementation',
          actualStart: '2018-02-15',
          actualEnd: '2018-02-27',
        },
        {
          id: '1_5',
          name: 'Testing',
          actualStart: '2018-02-28',
          actualEnd: '2018-03-10',
        },
      ],
    },
  ];

  useEffect(() => {
    // create a data tree
    const treeData = anychart.data.tree(data, 'as-tree');

    // create a chart
    const chart = anychart.ganttProject();
    const dataGrid = chart.dataGrid();
    console.log('chart', chart);

    // set the data
    chart.data(treeData);
    chart.splitterPosition(300);

    const timeLine = chart.getTimeline();

    const tasks = timeLine.tasks();

    const progress = tasks.progress();
    progress.fill('yellow 0.3');
    progress.stroke('none');
    progress.height('34%');
    progress.position('left-bottom');
    progress.anchor('left-bottom');

    // set the container id
    chart.container('ss');

    // initiate drawing the chart
    chart.draw();
  }, []);
  return (
    <div id="ss">Gantt</div>
  );
}

export default GanntChart;
