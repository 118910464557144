/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState, useEffect, Fragment } from 'react';
import useSearchFilter from '../../hooks/useSearchFilter';
import PaginationComponenet from '../../components/common/Pagination';
import {
  addBusinessCapabilitiesData, addCapabilitiesData, addCustomerCapabilitiesData, addProcessCapabilitiesData, addProductCapabilitiesData, addVendorCapabilitiesData,
} from '../../services/issueSlice';

function ExpandIssueEpicCapsCardElement({
  capsInfo,
  capabilitiesData,
  processCapabilitiesData,
  businessCapabilitiesData,
  vendorCapabilitiesData,
  customerCapabilitiesData,
  productCapabilitiesData,
  setExpanded,
  tableData,
  dispatch,
  size,
  position,
}) {
  const [TBLData, setTBLData] = useState(tableData || []);
  console.log('TBLData', TBLData, processCapabilitiesData);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const closeExpand = (e) => {
    e.preventDefault();
    const $this = document.getElementById('fsm_actual');
    $this.style.height = size.height;
    $this.style.width = size.width;
    $this.style.top = `${position.top}px`;
    $this.style.left = `${position.left}px`;
    $this.style.margin = '0';
    $this.classList.add('shrinking');

    setTimeout(() => {
      const { classList } = $this;
      while (classList.length > 0) {
        classList.remove(classList.item(0));
      }
      $this.style = '';
      setExpanded(false);
    }, 1000);
  };

  const [selectedCapabilitiesData, setSelectedCapabilitiesData] = useState(capabilitiesData);
  const [selectedProcessCapabilitiesData, setSelectedProcessCapabilitiesData] = useState(processCapabilitiesData);
  const [selectedBusinessCapabilitiesData, setSelectedBusinessCapabilitiesData] = useState(businessCapabilitiesData);
  const [selectedVendorCapabilitiesData, setSelectedVendorCapabilitiesData] = useState(vendorCapabilitiesData);
  const [selectedCustomerCapabilitiesData, setSelectedCustomerCapabilitiesData] = useState(customerCapabilitiesData);
  const [selectedProductCapabilitiesData, setSelectedProductCapabilitiesData] = useState(productCapabilitiesData);

  const { filter, setFilter, filterArray } = useSearchFilter(tableData || []);
  useEffect(() => {
    if (tableData && Array.isArray(tableData) && tableData?.length > 0) {
      setTotalCount(tableData?.length);
    } else {
      setTotalCount(0);
    }
  }, [tableData]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (tableData && Array.isArray(tableData) && tableData?.length > 10) {
      const reversedArray = [];
      tableData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (tableData && Array.isArray(tableData) && tableData?.length > 0) {
      const reversedArray = [];
      tableData?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [tableData, currentPage]);

  useEffect(() => {
    if (tableData && Array.isArray(tableData) && tableData?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [tableData, filter, filterArray]);

  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (capsInfo?.title === 'Application' && Array.isArray(selectedCapabilitiesData)) {
      if (selectedCapabilitiesData.some((v) => v.id === d?.id)) {
        setSelectedCapabilitiesData(selectedCapabilitiesData.filter((val) => val.id !== d?.id));
      } else {
        setSelectedCapabilitiesData([...selectedCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Process' && Array.isArray(selectedProcessCapabilitiesData)) {
      if (selectedProcessCapabilitiesData.some((v) => v.id === d.id)) {
        setSelectedProcessCapabilitiesData(selectedProcessCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setSelectedProcessCapabilitiesData([...selectedProcessCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Business' && Array.isArray(selectedBusinessCapabilitiesData)) {
      if (selectedBusinessCapabilitiesData.some((v) => v.id === d.id)) {
        setSelectedBusinessCapabilitiesData(selectedBusinessCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setSelectedBusinessCapabilitiesData([...selectedBusinessCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Vendor' && Array.isArray(selectedVendorCapabilitiesData)) {
      if (selectedVendorCapabilitiesData.some((v) => v.id === d.id)) {
        setSelectedVendorCapabilitiesData(selectedVendorCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setSelectedVendorCapabilitiesData([...selectedVendorCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Customer' && Array.isArray(selectedCustomerCapabilitiesData)) {
      if (selectedCustomerCapabilitiesData.some((v) => v.id === d.id)) {
        setSelectedCustomerCapabilitiesData(selectedCustomerCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setSelectedCustomerCapabilitiesData([...selectedCustomerCapabilitiesData, d]);
      }
    } else if (capsInfo?.title === 'Product' && Array.isArray(selectedProductCapabilitiesData)) {
      if (selectedProductCapabilitiesData.some((v) => v.id === d.id)) {
        setSelectedProductCapabilitiesData(selectedProductCapabilitiesData.filter((val) => val.id !== d.id));
      } else {
        setSelectedProductCapabilitiesData([...selectedProductCapabilitiesData, d]);
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (capsInfo?.title === 'Application') {
      if (Array.isArray(selectedCapabilitiesData)) return setSelectedCapabilitiesData(selectedCapabilitiesData.filter((value) => value?.id !== name?.id));
    } else if (capsInfo?.title === 'Process') {
      if (Array.isArray(selectedProcessCapabilitiesData)) return setSelectedProcessCapabilitiesData(selectedProcessCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Business') {
      if (Array.isArray(selectedBusinessCapabilitiesData)) return setSelectedBusinessCapabilitiesData(selectedBusinessCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Vendor') {
      if (Array.isArray(selectedVendorCapabilitiesData)) return setSelectedVendorCapabilitiesData(selectedVendorCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Customer') {
      if (Array.isArray(selectedCustomerCapabilitiesData)) return setSelectedCustomerCapabilitiesData(selectedCustomerCapabilitiesData.filter((value) => value.id !== name.id));
    } else if (capsInfo?.title === 'Product') {
      if (Array.isArray(selectedProductCapabilitiesData)) return setSelectedProductCapabilitiesData(selectedProductCapabilitiesData.filter((value) => value.id !== name.id));
    }
  };

  const getCheckedVal = (nameVal) => {
    if (capsInfo?.title === 'Application') {
      if (selectedCapabilitiesData.some((el) => el?.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Process') {
      if (selectedProcessCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Business') {
      if (selectedBusinessCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Vendor') {
      if (selectedVendorCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Customer') {
      if (selectedCustomerCapabilitiesData.some((el) => el.id === nameVal)) return true;
    } else if (capsInfo?.title === 'Product') {
      if (selectedProductCapabilitiesData.some((el) => el.id === nameVal)) return true;
    }
    return false;
  };

  const OnSelect = (e) => {
    e.preventDefault();
    if (capsInfo?.title === 'Application' && Array.isArray(selectedCapabilitiesData)) {
      dispatch(addCapabilitiesData(selectedCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        digital_twin_data: ele.digital_twin_data,
        tasks: ele?.tasks,
      }))));
    } else if (capsInfo?.title === 'Process' && Array.isArray(selectedProcessCapabilitiesData)) {
      dispatch(addProcessCapabilitiesData(selectedProcessCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        digital_twin_data: ele.digital_twin_data,
        tasks: ele?.tasks,
      }))));
    } else if (capsInfo?.title === 'Business' && Array.isArray(selectedBusinessCapabilitiesData)) {
      dispatch(addBusinessCapabilitiesData(selectedBusinessCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        digital_twin_data: ele.digital_twin_data,
        tasks: ele?.tasks,
      }))));
    } else if (capsInfo?.title === 'Vendor' && Array.isArray(selectedVendorCapabilitiesData)) {
      dispatch(addVendorCapabilitiesData(selectedVendorCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        digital_twin_data: ele.digital_twin_data,
        tasks: ele?.tasks,
      }))));
    } else if (capsInfo?.title === 'Customer' && Array.isArray(selectedCustomerCapabilitiesData)) {
      dispatch(addCustomerCapabilitiesData(selectedCustomerCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        digital_twin_data: ele.digital_twin_data,
        tasks: ele?.tasks,
      }))));
    } else if (capsInfo?.title === 'Product' && Array.isArray(selectedProductCapabilitiesData)) {
      dispatch(addProductCapabilitiesData(selectedProductCapabilitiesData?.map((ele) => ({
        id: ele.id,
        name: ele.name,
        digital_twin_data: ele.digital_twin_data,
        tasks: ele?.tasks,
      }))));
    }
    closeExpand(e);
  };

  return (
    <div className="card">
      <h3 className="card-header text-capitalize">
        <span className={capsInfo?.icon} />
        {`${capsInfo?.title}`}
        <a href="#!" className="edit-card" onClick={(e) => closeExpand(e)}>
          <span className="icon-close" />
        </a>
      </h3>
      <div className="card-body">
        <h5 className="font-weight-normal text-capitalize">{`Select ${capsInfo?.title}`}</h5>
        <div className="chip-wrapper">
          <div className="md-chips">
            {capsInfo?.title === 'Application'
              && (selectedCapabilitiesData && selectedCapabilitiesData.length > 0 && Array.isArray(selectedCapabilitiesData)) && selectedCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  {!val?.cancelabled
                && <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>}
                </div>
            ))}

            {capsInfo?.title === 'Process'
              && (selectedProcessCapabilitiesData && selectedProcessCapabilitiesData.length > 0 && Array.isArray(selectedProcessCapabilitiesData)) && selectedProcessCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>
                </div>
            ))}

            {capsInfo?.title === 'Business'
              && (selectedBusinessCapabilitiesData && selectedBusinessCapabilitiesData.length > 0 && Array.isArray(selectedBusinessCapabilitiesData)) && selectedBusinessCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>
                </div>
            ))}

            {capsInfo?.title === 'Vendor'
              && (selectedVendorCapabilitiesData && selectedVendorCapabilitiesData.length > 0 && Array.isArray(selectedVendorCapabilitiesData)) && selectedVendorCapabilitiesData.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>
                </div>
            ))}

            {capsInfo?.title === 'Customer'
              && (selectedCustomerCapabilitiesData && selectedCustomerCapabilitiesData.length > 0 && Array.isArray(selectedCustomerCapabilitiesData)) && selectedCustomerCapabilitiesData?.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>
                </div>
            ))}

            {capsInfo?.title === 'Product'
              && (selectedProductCapabilitiesData && selectedProductCapabilitiesData.length > 0 && Array.isArray(selectedProductCapabilitiesData)) && selectedProductCapabilitiesData?.map((val) => (
                <div key={val.id} className="md-chip">
                  <span>{val.name}</span>
                  <button aria-label="remove-stcom" type="button" className="md-chip-remove" onClick={(e) => removeHandler(e, 'capabilities', val)}><span className="icon-close" /></button>
                </div>
            ))}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="heading-row">
                <th className="check-field">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="selectAll"
                      disabled
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="selectAll"
                    />
                  </div>
                </th>
                <th className="sm-col">Capability Twin Name</th>
                <th className="sm-col">Capability Type</th>
                <th className="sm-col">Task ID</th>
                <th>Task Name</th>
                {/* <th>Change Details</th> */}
              </tr>
              <tr className="filter-row">
                <th className="check-field">&nbsp;</th>
                <th className="sm-col">
                  <input
                    type="search"
                    name="name"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                  />
                </th>
                <th className="sm-col">
                  <input type="search" disabled className="form-control" />
                </th>
                <th className="sm-col">
                  <input type="search" disabled className="form-control" />
                </th>
                <th>
                  <input type="search" disabled className="form-control" />
                </th>
                {/* <th>
                  <input type="search" disabled className="form-control" />
                </th> */}
              </tr>
            </thead>
            <tbody>
              {(TBLData
                && Array.isArray(TBLData)
                && TBLData.length > 0)
                ? TBLData?.map((vl) => (
                  <tr key={vl?.id}>
                    <td className="check-field">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={getCheckedVal(vl?.id)}
                          onChange={(e) => checkHandler(e, {
                            id: vl?.id,
                            name: vl?.digital_twin_data?.name,
                            digital_twin_data: vl?.digital_twin_data,
                            tasks: vl?.tasks,
                          })}
                          value={vl?.digital_twin_data?.name}
                          id={vl?.id}
                          disabled={(selectedCapabilitiesData?.length > 0 && Array.isArray(selectedCapabilitiesData)) && selectedCapabilitiesData?.some((sc) => sc?.cancelabled)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={vl?.id}
                          aria-label="select"
                        />
                      </div>
                    </td>
                    <td className="text-decoration-none sm-col">
                      {capsInfo?.title === 'Process' ? vl?.process_id?.name : vl?.digital_twin_data?.name}
                    </td>
                    <td style={{ textTransform: 'capitalize' }} className="sm-col">
                      {capsInfo?.title}
                    </td>
                    <td className="sm-col">
                      {vl?.tasks?.map((tsk, inx) => (
                        <Fragment key={tsk?.unique_id}>
                          <span>
                            {tsk?.unique_id}
                          </span>
                          {(vl?.tasks && Array.isArray(vl?.tasks) && vl?.tasks.length) && inx === vl.tasks.length - 1 ? '' : ', '}
                        </Fragment>
                      )) || '-'}
                    </td>
                    <td>{vl?.tasks?.map((tsk) => tsk.name) || '-'}</td>
                    {/* <td>
                      <ReadMoreLess text={vl?.digital_twin_data?.change_details || ''} />
                    </td> */}
                  </tr>
                )) : <tr><td colSpan={5} className="text-center">No Data To Display</td></tr>}
            </tbody>
          </table>
          {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                && (
                <PaginationComponenet
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                  TBLData={TBLData}
                />
                )}
        </div>
        <hr />
        <div className="button-wrapper d-flex align-items-center justify-content-end w-100">
          <button className="btn btn-outline-primary btn-sm btn-rounded" onClick={(e) => closeExpand(e)}>
            Cancel
          </button>
          <button className="btn btn-primary btn-sm btn-rounded" onClick={(e) => OnSelect(e)}>Select</button>
        </div>
      </div>
    </div>
  );
}
export default ExpandIssueEpicCapsCardElement;
