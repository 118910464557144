/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
// @ts-nocheck
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { isExpired, decodeToken } from 'react-jwt';
// import { read, utils, writeFile } from 'xlsx';
import logo from '../../images/logo.svg';
import { useGetNotificationsByIdQuery } from '../../services/services';
import Notification from './Notification';
import { addIsDirty } from '../../services/navSlice';
import { setNewNotification, setNotificationCount } from '../../services/formSlice';

function DashboardsHeader() {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const allNotifications = useGetNotificationsByIdQuery(
    { id: userData.id },
    { pollingInterval: process.env.POLL_TIME, refetchOnMountOrArgChange: true },
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    logout,
  } = useAuth0();
  const newNotification = useSelector((state) => state.formJson.newNotification);
  const notificationCount = useSelector((state) => state.formJson.notificationCount);
  console.log('notificationCount', notificationCount);
  console.log('newNotification', newNotification);

  const [profileDropDown, setProfileDropDown] = useState(false);
  const [userToekn, setUserToken] = useState(() => userData.token);
  const [showNotification, setShowNotification] = useState(false);
  const [showDelegationModal, setShowDelegationModal] = useState(false);

  const profileRef = useRef(null);
  const toggleProfile = () => {
    setProfileDropDown(!profileDropDown);
  };

  useEffect(() => {
    console.log('sssss', allNotifications?.data);

    if (allNotifications?.isSuccess && allNotifications?.data) {
      dispatch(setNewNotification(allNotifications?.data));
      const notificationLength = (Array.isArray(allNotifications?.data) && allNotifications?.data?.filter((nt) => nt?.viewed === 'Unread')
      && Array.isArray(allNotifications?.data?.filter((nt) => nt?.viewed === 'Unread'))
      && allNotifications?.data?.filter((nt) => nt?.viewed === 'Unread')?.length > 0) ? allNotifications?.data?.filter((nt) => nt?.viewed === 'Unread')?.length : 0;
      dispatch(setNotificationCount(notificationLength));
    }
  }, [allNotifications?.isSuccess]);

  useEffect(() => {
    if (isExpired(userData.token)) {
      logout();
    }
  }, [isExpired(userData.token)]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (profileDropDown && profileRef.current && !profileRef.current.contains(e.target)) {
        setProfileDropDown(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [profileDropDown]);

  useEffect(() => {
    if (!userData) {
      dispatch({ type: 'RESET_APP' });
      navigate('/');
    }
  }, [userData]);

  const handleLogout = (e) => {
    e.stopPropagation();
    localStorage.removeItem('authData');
    dispatch(addIsDirty(false));
    dispatch({ type: 'RESET_APP' });
    logout();
  };

  const handleAdminNav = () => {
    navigate('/admindashboard');
  };

  const moveToDelegation = () => {
    navigate('/delegation');
  };

  return (
    <header className="header d-flex align-items-center justify-content-between">
      <div className="logo">
        <Link to="/dashboard">
          <img src={logo} alt="Qlorem" />
          <span className="company-name">{userData ? userData?.tenant_info?.name : '-'}</span>
        </Link>
      </div>
      <div className="header-links d-flex align-items-center">
        {/* <div className="search">
          <form action="" className="search-form mb-0">
            <div className="form-group mb-0">
              <input
                type="search"
                className="form-control"
                name="main-search"
                placeholder="Search"
                id="main-search"
              />
              <input type="submit" className="search-submit" value="" />
            </div>
          </form>
        </div> */}
        <ul className="user-links list-unstyled d-flex mb-0">

          <li className={`notify ${allNotifications?.isSuccess
             && newNotification?.some((nt) => nt?.viewed === 'Unread') ? 'has-notifications' : ''}
             ${showNotification ? 'show-notifications' : ''}`}
          >
            <Link
              to="#"
              onClick={() => setShowNotification(!showNotification)}
              className="d-flex align-items-center justify-content-center"
            >
              <span className="icon-notification" />
              {allNotifications?.isSuccess
             && newNotification?.some((nt) => nt?.viewed === 'Unread')
              && (
              <span className="notification-category">
                {notificationCount}
                {/* {allNotifications?.isSuccess
                && allNotifications.data.filter((nt) => nt.viewed === 'Unread').length > 99 ? '99+' : allNotifications.data.filter((nt) => nt.viewed === 'Unread').length} */}
              </span>
              )}
            </Link>
            {allNotifications.isSuccess
             && (
             <Notification
               data={newNotification || []}
               setShow={setShowNotification}
               showNotification={showNotification}
               userData={userData}
             />
             )}
          </li>
          {(userData && (userData?.permissions?.includes('create:config') || userData?.permissions?.includes('update:config') || userData?.permissions?.includes('view:config'))) && (
          <li>
            <Button
              variant="link"
              onClick={(e) => handleAdminNav(e)}
              className="text-decoration-none d-flex align-items-center justify-content-center"
              data-test-id="config"
            >
              <span className="icon-settings" />
            </Button>
          </li>
          )}
          <li className={`has-sub ${profileDropDown ? 'show-sub' : ''}`} ref={profileRef}>
            <Button
              variant="link"
              className="text-decoration-none d-flex align-items-center justify-content-center action-link"
              onClick={() => toggleProfile()}
            >
              <span className="icon-user" />
            </Button>
            <ul className="sub-menu list-unstyled mb-0">
              <li>
                <Button variant="link" className="text-decoration-none">
                  <span className="icon-user" />
                  <span style={{ textTransform: 'capitalize' }}>{`${userData?.first_name || ''} ${userData?.last_name || ''}`}</span>
                </Button>
              </li>
              {(userData?.roles?.includes('Admin') || userData?.roles?.includes('Transformation Manager') || userData?.roles?.includes('Capability Manager') || userData?.roles?.includes('Manager'))
              && (
              <li>
                <Button
                  onClick={() => moveToDelegation()}
                  variant="link"
                  className="text-decoration-none"
                >
                  <span className="icon-settings" />
                  Delegation
                </Button>
              </li>
              )}
              <li>
                <Button onClick={(e) => handleLogout(e)} variant="link" className="text-decoration-none">
                  <span className="icon-logout" />
                  Logout
                </Button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </header>
  );
}
const DashboardHeader = React.memo(DashboardsHeader);
export default DashboardHeader;
