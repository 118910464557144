/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import useSearchFilter from '../../hooks/useSearchFilter';
import { addEpicTaskId } from '../../services/epicSlice';
import {
  addRelatedChangeData,
} from '../../services/formSlice';
import {
  useChangesByCompletedTaskQuery,
  useGetEpicInfoMMutation,
  useTaskByChangeMMutation,
} from '../../services/services';
import PaginationComponenet from '../common/Pagination';

function EpicChangeModal({
  modal, handleClose, label, relatedChange,
}:any):JSX.Element {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const dispatch = useDispatch();
  const epicChangeData = useChangesByCompletedTaskQuery();
  // const [tskByChange, tslChangRes] = useTaskByChangeMMutation();
  // const [getEpicInfo, epicInfoData] = useGetEpicInfoMMutation();
  const [header, setHeader] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [selectedChange, setSelectedChange] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [TBLData, setTBLData] = useState(tabledata || []);
  console.log('TBLData', TBLData);

  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const { filter, setFilter, filterArray } = useSearchFilter(tabledata || []);

  useEffect(() => {
    if (tabledata && Array.isArray(tabledata) && tabledata?.length > 0) {
      setTotalCount(tabledata.length);
    } else {
      setTotalCount(0);
    }
  }, [tabledata]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (tabledata && Array.isArray(tabledata) && tabledata?.length > 10) {
      const reversedArray = [];
      tabledata?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (tabledata && Array.isArray(tabledata) && tabledata?.length > 0) {
      const reversedArray = [];
      tabledata?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [tabledata, currentPage]);

  useEffect(() => {
    if (tabledata && Array.isArray(tabledata) && tabledata?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [tabledata, filter, filterArray]);

  useEffect(() => {
    if (relatedChange
      && Array.isArray(relatedChange)
      && relatedChange?.length > 0) {
      setSelectedChange(relatedChange);
    }
  }, [relatedChange]);

  // useEffect(() => {
  //   if (Array.isArray(selectedChange) && selectedChange.length > 0 && selectedChange[0]?.id) {
  //     tskByChange({ id: selectedChange[0]?.id });
  //     getEpicInfo({ id: selectedChange[0]?.id });
  //   }
  // }, [Array.isArray(selectedChange) && selectedChange.length > 0 && selectedChange[0]?.id]);

  // useEffect(() => {
  //   if (tslChangRes.isSuccess) {
  //     tslChangRes.data.data.forEach((tsk) => {
  //       if (tsk.capability_type === 'Epic') {
  //         dispatch(addEpicTaskId(tsk.id));
  //       }
  //     });
  //   }
  // }, [tslChangRes.isSuccess]);

  // useEffect(() => {
  //   if (modal.key === 'epic' && epicInfoData?.isSuccess) {
  //     if ((epicInfoData?.data?.total_twin_count === epicInfoData?.data?.twins_in_epic_count || epicInfoData?.data?.total_twin_count < epicInfoData?.data?.twins_in_epic_count)
  //       || tslChangRes?.data?.data?.some((tsk) => tsk?.status !== 'Completed' && tsk?.capability_type !== 'Epic')
  //     ) {
  //       setSaveDisabled(true);
  //       setErrorMessage('All tasks are not Completed / All twins consumed');
  //     } else if (tslChangRes?.data?.data?.some((tsk) => tsk?.status === 'Available' && tsk?.capability_twin_id === -999)) {
  //       setSaveDisabled(true);
  //       setErrorMessage('Epic task is not accepted ');
  //     } else {
  //       setSaveDisabled(false);
  //     }
  //   } else {
  //     setSaveDisabled(false);
  //     setErrorMessage('');
  //   }
  // }, [modal.key, epicInfoData?.isSuccess]);

  useEffect(() => {
    if (epicChangeData?.isSuccess && epicChangeData?.data) {
      if (modal.key === 'epic') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'managers', name: 'Manager' },
            { type: 'date', field: 'start_date', name: 'Start Date' },
            { type: 'date', field: 'end_date', name: 'End Date' },
            { type: '', field: 'status', name: 'Status' },
          ],
        );
        if (epicChangeData?.data) {
          setTableData([...epicChangeData.data]);
        }
      }
    }
  }, [label, modal.key, epicChangeData?.isSuccess]);

  const checkHandler = (e, d, value) => {
    e.stopPropagation();
    if (modal.key === 'epic') {
      if (e.target.checked) {
        setSelectedChange([d]);
      } else {
        setSelectedChange([]);
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (modal.key === 'epic') {
      setSelectedChange(selectedChange.filter((value) => value.id !== name.id));
    }
  };

  console.log('selectedChange', selectedChange);

  const getCheckedVal = (nameVal) => {
    if ((modal.key === 'epic') && selectedChange.some((el) => el.id === nameVal)) return true;
    return false;
  };

  const onSelect = () => {
    dispatch(addRelatedChangeData(selectedChange));
    handleClose();
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal
      show={modal.show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Related
          {' '}
          {modal.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(tabledata.length > 0 && header.length > 0)
          ? (
            <div className="card">
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionDark"
              >
                <div className="card-body">
                  <h5 className="font-weight-normal" style={{ textTransform: 'capitalize' }}>
                    {`${modal?.title}`}
                  </h5>
                  <div className="chip-wrapper">
                    <div className="md-chips">
                      {((modal?.key === 'epic')) && (selectedChange && Array.isArray(selectedChange) && selectedChange?.length > 0) && selectedChange?.map((val) => (
                        <div key={label + val.id} className="md-chip">
                          <span>{`${val.name}`}</span>
                          <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, modal.key, val)}>
                            <span className="icon-close" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  {modal.key === 'epic' && saveDisabled && (
                    <div className="invalid-feedback mb-3">
                      <span className="icon-error-red" />
                      {errorMessage && errorMessage}
                    </div>
                  )}
                  <h2>{`Select ${modal.title}s`}</h2>

                  <div className="table-responsive box-shadow mb-0">
                    <table className="table table-hover">
                      <thead>
                        <tr className="heading-row">
                          <th className="check-field">
                            {label !== 'epic'
                            && (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="selectAll"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="selectAll"
                              />
                            </div>
                            )}
                          </th>
                          {(header && header?.length > 0 && Array.isArray(header)) && header?.map((ele) => (
                            <th key={ele?.name}>{ele.name}</th>
                          ))}
                        </tr>
                        <tr className="filter-row">
                          <th>&nbsp;</th>
                          {(header && header?.length > 0 && Array.isArray(header)) && header?.map((ele) => (
                            <th key={ele?.name}>
                              {ele?.type === '' && (
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Search"
                                name={ele?.field}
                                disabled={(ele?.field !== 'name' && ele?.field !== 'status' && ele?.field !== 'owners')}
                                onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                              />
                              )}
                              {ele.type === 'date' && (
                              <DatePicker
                                className="form-control"
                                placeholderText="DD/MM/YYYY"
                                portalId="root-portal"
                                disabled={(ele?.field === 'start_date' || ele?.field === 'end_date')}
                              />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0) ? TBLData?.map((td) => (
                          <tr key={td?.id}>
                            <td className="check-field">
                              <div className={`custom-control ${label === 'epic' ? 'custom-radio' : 'custom-checkbox'}`}>
                                <input
                                  type={label === 'epic' ? 'radio' : 'checkbox'}
                                  className="custom-control-input"
                                  checked={getCheckedVal(td.id)}
                                  onChange={(e) => checkHandler(e, td, label)}
                                  value={td.name}
                                  id={modal.key + td.id}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={modal.key + td.id}
                                  aria-label="select"
                                />
                              </div>
                            </td>
                            {(header && header?.length > 0 && Array.isArray(header)) && header?.map((th) => (
                              (th.field === 'owner' || th.field === 'owners')
                                ? (
                                  <td className="text-capitalize" key={th?.field}>
                                    {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                      `${ow?.first_name}  ${ow?.last_name || ''}`
                                    )) : '-'}
                                  </td>
                                )
                                : (th.field === 'manager' || th.field === 'managers')
                                  ? (
                                    <td className="text-capitalize" key={th?.field}>
                                      {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                        `${mn?.first_name}  ${mn?.last_name || ''}`
                                      )) : '-'}
                                    </td>
                                  )
                                  : (th.field === 'step_mappings')
                                    ? (
                                      <td key={th?.field}>
                                        {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.length : '-'}
                                      </td>
                                    )
                                    : th.field === 'initiative' ? <td key={th?.field}>0</td>
                                      : th.field === 'overheads_budget' ? <td key={th?.field}>{td[th.field] || '-'}</td>
                                        : th.field === 'name' ? td[th.field] && <td key={th?.field}><Button href={`/review/change/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{td[th.field]}</Button></td>
                                          : th.type === 'date' ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                            : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                            ))}
                          </tr>
                        ))
                          : (
                            <tr>
                              <td
                                colSpan={(header && Array.isArray(header) && header?.length) && header?.length}
                                className="text-center"
                              >
                                No Change Found
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                    && (
                    <PaginationComponenet
                      currentPage={currentPage}
                      totalCount={totalCount}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                      TBLData={TBLData}
                    />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
          : `No ${modal.title} Found`}
      </Modal.Body>
      {(tabledata?.length > 0 && header?.length > 0)
      && (
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
        <Button variant="primary" onClick={() => onSelect()} disabled={modal.key === 'epic' && saveDisabled}>Select</Button>
      </Modal.Footer>
      )}
    </Modal>
  );
}

export default EpicChangeModal;
