/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import {
  useGetAllProgsQuery,
  useSaveDocsMutation,
  useSubmitFormMutation,
  useGetIssueJsonQuery,
  useGetIssueRelatedObjectMutation,
  useSubmitIssueMutation,
  useGetImpactJsonQuery,
  useSubmitImpactMutation,
} from '../../../services/services';
import AlertMessage from '../../../SuperComponents/AlertMessage';
import LoaderComp from '../../../components/LoderQ';
import ErrorModal from '../../../components/common/ErrorModal';
import IssueFromBuilder from '../IssueFromBuilder';
import { addDocsInfo, clearDocsInfo } from '../../../services/docInfoSlice';
import { addIssueResPeople } from '../../../services/issueSlice';
import { addIsDirty } from '../../../services/navSlice';

function CreatesImpact() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('impact');
  const location = useLocation();
  console.log('issue location', location);

  // Queries & Mutation
  const [saveDocs, docRes] = useSaveDocsMutation();
  const [submitForm, res] = useSubmitImpactMutation();
  const { isSuccess, isLoading, isError } = res;
  const formJson = useGetImpactJsonQuery();
  // Redux State
  const issueValues = useSelector((state) => state.issueData.issueValues);
  const impactEditMode = useSelector((state) => state.issueData.impactEditMode);
  const impactValues = useSelector((state) => state.issueData.impactValues);
  const issueResPeople = useSelector((state) => state.issueData.issueResPeople);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const issueCapabilitiesReadOnly = useSelector((state) => state.issueData.issueCapabilitiesReadOnly);
  const epicIssueCapsMapping = useSelector((state) => state.issueData.epicIssueCapsMapping);

  console.log('docsInfo', docsInfo, docsInfo.filter((
    item,
    index,
  ) => docsInfo.indexOf(item) === index));
  console.log('impactValues', impactValues);
  console.log('issueResPeople', issueResPeople);

  const [jsonTemplate, setJsonTemplate] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const cancelTitle = 'Impact creation';

  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (formJson?.isSuccess) {
      setJsonTemplate(formJson.data);
    }
  }, [formJson?.isSuccess]);

  // Setting Docments on edit
  useEffect(() => {
    if (impactEditMode) {
      if (impactValues?.documents && Array.isArray(impactValues?.documents) && impactValues?.documents?.length > 0) {
        dispatch(addDocsInfo(impactValues?.documents));
      }
    }
  }, [impactEditMode]);
  // Save Doc Res to State
  useEffect(() => {
    if (
      docRes.isSuccess
      && docRes.data.docData
      && docRes.data.docData?.length > 0
      && Array.isArray(docRes.data.docData)
    ) {
      const docsData = docRes?.data?.docData?.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addDocsInfo(docsData));
    }
  }, [docRes.isSuccess]);

  // Reset All steps
  const stepReset = () => {
    dispatch({ type: 'RESET_APP' });
    navigate('/dashboard', {
      state: {
        selected: 'Programs',
      },
    });
  };

  // Function to set State while clicking on Next
  const onSubmit = (values) => {
    console.log('values', values);
    const finalData = {
      ...values,
      scope_min_impact: values?.scope_min_impact ? Number(values?.scope_min_impact) : '',
      scope_max_impact: values?.scope_max_impact ? Number(values?.scope_max_impact) : '',
      schedule_min_impact: values?.schedule_min_impact ? Number(values?.schedule_min_impact) : '',
      schedule_max_impact: values?.schedule_max_impact ? Number(values?.schedule_max_impact) : '',
      effort_min_impact: values?.effort_min_impact ? Number(values?.effort_min_impact) : '',
      effort_max_impact: values?.effort_max_impact ? Number(values?.effort_max_impact) : '',
      budget_min_impact: values?.budget_min_impact ? Number(values?.budget_min_impact) : '',
      budget_max_impact: values?.budget_max_impact ? Number(values?.budget_max_impact) : '',
      benefit_min_impact: values?.benefit_min_impact ? Number(values?.benefit_min_impact) : '',
      benefit_max_impact: values?.benefit_max_impact ? Number(values?.benefit_max_impact) : '',
      created: impactEditMode ? impactValues?.created : dayjs().format('YYYY-MM-DD hh:mm:ss'),
      created_by: {
        id: userData?.id,
      },
      updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      updated_by: {
        id: userData?.id,
      },
      deleted: false,
      documents: docsInfo?.filter((
        item,
        index,
      ) => docsInfo?.indexOf(item) === index) || null,
      mode: 'Submitted',
      issue: {
        ...issueValues,
      },
      dependencies: (epicIssueCapsMapping && Array.isArray(epicIssueCapsMapping) && epicIssueCapsMapping.length > 0) ? epicIssueCapsMapping?.filter((ele) => ele?.value?.length !== 0) : (issueCapabilitiesReadOnly && Array.isArray(issueCapabilitiesReadOnly) && issueCapabilitiesReadOnly?.length) ? issueCapabilitiesReadOnly?.map((el) => ({ key: el.key, value: el.value }))?.filter((ele) => ele?.value?.length !== 0) : null,

      // dependencies: (issueCapabilitiesReadOnly && Array.isArray(issueCapabilitiesReadOnly) && issueCapabilitiesReadOnly?.length) ? issueCapabilitiesReadOnly?.map((el) => ({ key: el.key, value: el.value }))?.filter((ele) => ele?.value?.length !== 0) : null,
    };
    if (location?.state?.type === 'Program' || location?.state?.type === 'Project') {
      delete finalData.dependencies;
    }
    delete finalData.status;
    submitForm(finalData);
    console.log('finalData', finalData);
    // handleNext();
  };

  useEffect(() => {
    if (!impactEditMode) {
      dispatch(addDocsInfo([]));
    }
  }, [impactEditMode]);

  // Remove Success Message
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    }
  }, [successMessage]);

  // After Submit Redirect & Success Message
  useEffect(() => {
    if (res?.isSuccess) {
      setSuccessMessage(
        `Impact ${impactEditMode ? 'Saved' : 'Submitted'} Successfully`,
      );
      dispatch(addIsDirty(false));
      setTimeout(() => {
        if (location?.state?.from === '/impact-read-review') {
          navigate(location?.state?.from, {
            state: {
              ...location?.state?.superParentState,
              parentState: {
                ...location?.state?.superParentState?.parentState,
                state: location?.state?.superParentState?.parentState?.state,
              },
            },
          });
        } else if (location?.state?.from === '/issue-read-review') {
          navigate(location?.state?.from, {
            state: {
              ...location?.state?.parentState,
              state: location?.state?.parentState?.state,
            },
          });
        }
        dispatch(clearDocsInfo([]));
      }, 2000);
    }
  }, [res, location]);

  const handleBack = (flag) => {
    if (flag === 1) {
      navigate(location?.state?.from, {
        state: {
          ...location?.state?.superParentState,
          parentState: {
            ...location?.state?.superParentState?.parentState,
            state: location?.state?.superParentState?.parentState?.state,
          },
        },
      });
      dispatch(clearDocsInfo([]));
    } else if (flag === 2) {
      navigate(location?.state?.from, {
        state: {
          ...location?.state?.parentState,
          state: location?.state?.parentState?.state,
        },
      });
      dispatch(clearDocsInfo([]));
    }
  };

  // Return Starts
  return (
    <div className="content-wrapper">
      {isError && <ErrorModal />}
      {isLoading && <LoaderComp />}
      {successMessage && (
        <AlertMessage message={successMessage} setter={setSuccessMessage} />
      )}
      <div className="card">
        <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>
            {!impactEditMode
              ? t('impact:create_new_impact')
              : `Impact: ${impactValues?.name}`}
          </h1>
          {(location?.state?.from === '/impact-read-review')
            && <Button className="text-decoration-none" type="button" onClick={() => handleBack(1)}>Back</Button>}
          {(location?.state?.from === '/issue-read-review')
            && <Button className="text-decoration-none" type="button" onClick={() => handleBack(2)}>Back</Button>}
        </div>

        <div className="card-body">
          <div className="form-wrapper">
            <IssueFromBuilder
              label="impact"
              template={jsonTemplate}
              onNext={onSubmit}
              modalShow={modalShow}
              setModalShow={setModalShow}
              stepReset={stepReset}
              dfVals={impactValues || null}
              editMode={impactEditMode}
              cancelTitle={cancelTitle}
              resRelatedObjects={null}
              issueResPeople={issueResPeople}
              saveDocs={saveDocs}
              docsInfo={docsInfo}
              isChangeImpact={location?.state?.type === 'Change'}
              isEpic={location?.state?.type === 'Epic'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const CreateImpact = React.memo(CreatesImpact);

export default CreateImpact;
