/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addIsDirty } from '../services/navSlice';
import { addCurrentSteps, addStepVals } from '../services/processSlice';
import {
  useCheckProgNameMutation,
  useCheckProjectNameMutation,
  useCheckBenefitNameMutation,
  useCheckRiskNameMutation,
  useCheckAppNameMutation,
  useCheckChangeNameMutation,
  useCheckEpicNameMutation,
  useCheckProcessNameMutation,
  useCheckProcessStepNameMutation,
  useCheckVendorNameMutation,
  useCheckCustomerNameMutation,
  useCheckBusinessNameMutation,
  useCheckRequirementNameMutation,
  useCheckIssueNameMutation,
  useCheckImpactNameMutation,
  useCheckCorrectionNameMutation,
} from '../services/services';
import useDebounce from './useDebounce';

const useForm = (
  callback,
  validate,
  validateFields,
  fields,
  dfVals,
  stepReset,
  editMode,
  label,
  t,
) => {
  const [checkProgName, resCheckProgName] = useCheckProgNameMutation();
  const [checkProjName, resCheckProjName] = useCheckProjectNameMutation();
  const [checkBenefitName, resCheckBenefitName] = useCheckBenefitNameMutation();
  const [checkRiskName, resCheckRiskName] = useCheckRiskNameMutation();
  const [checkAppName, resCheckAppName] = useCheckAppNameMutation();
  const [checkEpicName, resCheckEpicName] = useCheckEpicNameMutation();
  const [checkChangeName, resCheckChangeName] = useCheckChangeNameMutation();
  const [checkProcessStepName, resCheckProcessStepName] = useCheckProcessStepNameMutation();
  const [checkProcessName, resCheckProcessName] = useCheckProcessNameMutation();
  const [checkVendorName, resCheckVendorName] = useCheckVendorNameMutation();
  const [checkBusinessName, resCheckBusinessName] = useCheckBusinessNameMutation();
  const [checkCustomerName, resCheckCustomerName] = useCheckCustomerNameMutation();
  const [checkRequirementName, resCheckRequirementName] = useCheckRequirementNameMutation();
  const [checkIssueName, resCheckIssueName] = useCheckIssueNameMutation();
  const [checkImpactName, resCheckImpactName] = useCheckImpactNameMutation();
  const [checkCorrectionName, resCheckCorrectionName] = useCheckCorrectionNameMutation();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [isChangeRequire, setIsChangeRequire] = useState(false);

  const [nameVal, setNameVal] = useState('');

  const dispatch = useDispatch();
  const relatedChange = useSelector((state) => state.formJson.relatedChangeData);
  const programValues = useSelector((state) => state.formJson.programValues);
  const applicationValues = useSelector((state) => state.applicationData.applicationValues);
  const benefitValues = useSelector((state) => state.benefitData.benefitValues);
  const rcValues = useSelector((state) => state.rcData.rcValues);
  const epicValues = useSelector((state) => state.epicData.epicVals);
  const riskValues = useSelector((state) => state.riskData.riskValues);
  const projValues = useSelector((state) => state.initData.initValues);
  const debouncedValue = useDebounce(nameVal, 500);
  const currentSteps = useSelector((state) => state.processData.currentSteps);
  const stepVals = useSelector((state) => state.processData.stepVals);
  const processValues = useSelector((state) => state.processData.processVals);
  const vendorValues = useSelector((state) => state.vendorData.vendorValues);
  const businessValues = useSelector((state) => state.businessData.businessValues);
  const customerValues = useSelector((state) => state.customerData.customerValues);
  const requirementValues = useSelector((state) => state.requirementData.requirementValues);
  const issueValues = useSelector((state) => state.issueData.issueValues);
  const impactValues = useSelector((state) => state.issueData.impactValues);
  const correctionValues = useSelector((state) => state.issueData.correctionValues);
  const editModeStep = useSelector((state) => state.editData.editMode);
  const activeStepComp = useSelector((state) => state.processData.activeStepComp);
  console.log('stepVals', stepVals, 'processValues', processValues, 'debouncedValue', debouncedValue);
  const issueEditMode = useSelector((state) => state.issueData.issueEditMode);
  const impactEditMode = useSelector((state) => state.issueData.impactEditMode);
  const correctionEditMode = useSelector((state) => state.issueData.correctionEditMode);

  useEffect(() => {
    if (label === 'addStep') {
      setValues(dfVals);
      if (editModeStep && currentSteps?.every((ele) => ele?.value?.mode !== 'submitted')) {
        setNameVal(dfVals?.name);
      }
    }
  }, [activeStepComp?.addStep, label, dfVals]);

  useEffect(() => {
    if (label === 'addStep') {
      const temp = [];
      currentSteps.map((cs) => {
        if (cs?.value?.mode === 'current') {
          temp.push({ ...cs, value: { ...cs.value, name: values?.name } });
        } else {
          temp.push({ ...cs });
        }
      });
      dispatch(addCurrentSteps(temp));
      dispatch(addStepVals(values));
    }
  }, [label, values]);

  useEffect(() => {
    if (debouncedValue && label === 'program') {
      if (editMode && debouncedValue !== programValues.name) {
        checkProgName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkProgName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'initiatives') {
      if (editMode && debouncedValue !== projValues.name) {
        checkProjName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkProjName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'change') {
      if (editMode && debouncedValue !== rcValues.name) {
        checkChangeName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkChangeName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'epic') {
      if (editMode && debouncedValue !== epicValues.name) {
        checkEpicName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkEpicName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'risk') {
      if (editMode && debouncedValue !== riskValues.name) {
        checkRiskName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkRiskName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'benefit') {
      if (editMode && debouncedValue !== benefitValues.name) {
        checkBenefitName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkBenefitName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'application') {
      if (editMode && debouncedValue !== applicationValues.name) {
        checkAppName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkAppName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'addStep') {
      if (editMode && debouncedValue !== stepVals?.name) {
        checkProcessStepName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkProcessStepName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'process') {
      if (editMode && debouncedValue !== processValues?.name) {
        checkProcessName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkProcessName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'vendor') {
      if (editMode && debouncedValue !== vendorValues.name) {
        checkVendorName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkVendorName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'business') {
      if (editMode && debouncedValue !== businessValues.name) {
        checkBusinessName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkBusinessName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'customer') {
      if (editMode && debouncedValue !== customerValues.name) {
        checkCustomerName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkCustomerName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'requirement') {
      if (editMode && debouncedValue !== requirementValues.name) {
        checkRequirementName({ name: debouncedValue.trim() });
      } else if (!editMode) {
        checkRequirementName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'issue') {
      if (issueEditMode && debouncedValue !== issueValues?.name) {
        checkIssueName({ name: debouncedValue.trim() });
      } else if (!issueEditMode) {
        checkIssueName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'impact') {
      if (impactEditMode && debouncedValue !== impactValues?.name) {
        checkImpactName({ name: debouncedValue.trim() });
      } else if (!impactEditMode) {
        checkImpactName({ name: debouncedValue.trim() });
      }
    } else if (debouncedValue && label === 'correction') {
      if (correctionEditMode && debouncedValue !== correctionValues?.name) {
        checkCorrectionName({ name: debouncedValue.trim() });
      } else if (!correctionEditMode) {
        checkCorrectionName({ name: debouncedValue.trim() });
      }
    }
  }, [label, debouncedValue]);

  const checkSuccsess = resCheckProgName.isSuccess || resCheckProjName.isSuccess
  || resCheckBenefitName.isSuccess || resCheckChangeName.isSuccess
  || resCheckRiskName.isSuccess || resCheckAppName.isSuccess
  || resCheckEpicName.isSuccess || resCheckProcessName.isSuccess
  || resCheckProcessStepName.isSuccess || resCheckVendorName.isSuccess
  || resCheckBusinessName.isSuccess || resCheckCustomerName.isSuccess
  || resCheckRequirementName.isSuccess
  || resCheckIssueName.isSuccess
  || resCheckImpactName.isSuccess
  || resCheckCorrectionName.isSuccess;

  useEffect(() => {
    if (checkSuccsess) {
      if (label === 'program') {
        setErrors({ name: 'Program name already taken ' });
      } else if (label === 'initiatives') {
        setErrors({ name: 'Project name already taken ' });
      } else if (label === 'change') {
        setErrors({ name: 'Change name already taken ' });
      } else if (label === 'epic') {
        setErrors({ name: 'Epic name already taken ' });
      } else if (label === 'risk') {
        setErrors({ name: 'Risk name already taken ' });
      } else if (label === 'benefit') {
        setErrors({ name: 'Benefit name already taken ' });
      } else if (label === 'application') {
        setErrors({ name: 'Application name already taken ' });
      } else if (label === 'addStep' && resCheckProcessStepName?.isSuccess && debouncedValue) {
        setErrors({ name: 'Process Step name already taken ' });
      } else if (label === 'process') {
        setErrors({ name: 'Process name already taken ' });
      } else if (label === 'vendor') {
        setErrors({ name: 'Vendor name already taken ' });
      } else if (label === 'business') {
        setErrors({ name: 'Business name already taken ' });
      } else if (label === 'customer') {
        setErrors({ name: 'Customer name already taken ' });
      } else if (label === 'requirement') {
        setErrors({ name: 'Requirement name already taken ' });
      } else if (label === 'issue') {
        setErrors({ name: 'Issue name already taken ' });
      } else if (label === 'impact') {
        setErrors({ name: 'Impact name already taken ' });
      } else if (label === 'correction') {
        setErrors({ name: 'Correction name already taken ' });
      }
    }
  }, [label, checkSuccsess]);

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      dispatch(addIsDirty(true));
    } else {
      dispatch(addIsDirty(false));
    }
  }, [values]);

  useEffect(() => {
    if (editMode && dfVals) {
      setValues(dfVals);
    } else {
      setValues(dfVals);
    }
  }, [editMode, dfVals]);

  useEffect(() => {
    if (!editMode) {
      setValues({ ...dfVals, status: 'New' });
    }
  }, [editMode]);

  useEffect(() => {
    if (label === 'change' && !editMode) {
      setValues({ ...dfVals, status: 'New' });
    }
  }, [editMode, label]);

  useEffect(() => {
    if (label === 'epic'
       && relatedChange
       && (Object.keys(errors).length !== 0 || Object.keys(errors).length === 0)
       && relatedChange?.length === 0
       && Array.isArray(relatedChange)
       && isSubmitting) {
      setIsChangeRequire(true);
      setIsSubmitting(false);
    } else if (label === 'epic'
      && relatedChange
      && Array.isArray(relatedChange)
      && relatedChange?.length > 0
      && (Object.keys(errors).length === 0)
      && isSubmitting) {
      setIsChangeRequire(false);
      setIsSubmitting(false);
      callback(values);
    } else if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
    }
  }, [label, relatedChange, Object.keys(errors).length, isSubmitting]);

  useEffect(() => {
    if (isClear) {
      stepReset();
      setValues({});
      setErrors({});
      setIsSubmitting(false);
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    if (label === 'benefit:trackingPointModal' && isSubmitting) {
      setIsSubmitting(false);
    }
  }, [label, isSubmitting]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(fields, values, errors, label, t));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name' && typeof name === 'string' && !editMode) {
      setNameVal(value);
    } else if (name === 'name' && typeof name === 'string') {
      setNameVal(value.trim());
    }
    if (name === 'organizations') {
      setValues((values) => ({
        ...values, organizations: Number(value), business_units: '', departments: '',
      }));
    } else if (value) {
      setValues((values) => ({ ...values, [name]: value }));
    } else {
      setValues((current) => {
        const copy = { ...current };
        delete copy[name];
        return copy;
      });
    }

    if (validateFields(name, value, errors, setErrors, label, t, fields)) {
      setErrors((current) => {
        const copy = { ...current };
        delete copy[name];
        return copy;
      });
    }
    setIsSubmitting(false);
  };

  const clearFormState = () => {
    setIsClear(true);
  };

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
    clearFormState,
    isChangeRequire,
  };
};

export default useForm;
