/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import CancelModal from '../../components/common/CancelModal';
import TablePeople from '../../components/common/TablePeople';
import { useGetAllPeopleQuery } from '../../services/services';
import { addPeople } from '../../services/peopleSlice';
import StepFooter from '../../components/common/StepFooter';
import Tree from '../../temporary/Tree';
import { addTeamsCapab } from '../../services/reqChangeSlice';

function RequirementPeoples({
  label,
  steps,
  activeStep,
  handleBack,
  handleNext,
  modalShow,
  setModalShow,
  stepReset,
  fields,
  cancelTitle,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('people');
  const dispatch = useDispatch();
  const location = useLocation();
  const tableData = useGetAllPeopleQuery();
  const peopleData = useSelector((state) => state.peopleData.peopleVals);
  const editMode = useSelector((state) => state.editData.editMode);
  const treeData = useSelector((state) => state.rcData.peopleCapab);
  const capsMapping = useSelector((state) => state.applicationData.capsMapping);
  const peopleDisabled = useSelector((state) => state.peopleData.peopleDisabled);
  const [collaborate, setCollaborate] = useState(false);
  const [collaboratorData, setCollaboratorData] = useState([]);
  console.log('collaboratorData', collaboratorData);
  const [colle, setColle] = useState(true);
  const [selectedApp, setSelectedApp] = useState();
  console.log('selectedApp', selectedApp);
  const [notify, setNotify] = useState('');
  const [height, setHeight] = useState('');
  const [nextDisabled, setNextDisabled] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableTitle, setTableTitle] = useState('');
  const draftMode = useSelector((state) => state.formJson.draftMode);
  const [checkApps, setCheckApps] = useState('');
  const [displayTable, setDisplayTable] = useState(true);
  const teamsData = useSelector((state) => state.rcData.teamsCapab);
  const [selVal, setSelVal] = useState({
    flag: false,
    val: '',
  });

  useEffect(() => {
    if (collaborate) {
      setSelVal({
        ...selVal,
        flag: true,
      });
    }
  }, [collaborate]);

  React.useEffect(() => {
    if (tableData.isSuccess) {
      if (tableData.data.tableData && Array.isArray(tableData.data.tableData) && tableData.data.tableData?.length > 0) {
        setDataTable([...tableData.data.tableData]);
        setDisplayTable(true);
      }
    }
  }, [tableData]);

  useEffect(() => {
    const teams = [];
    if (capsMapping) {
      capsMapping?.map((df) => df?.value?.map((vl) => vl?.teams?.map((vt) => teams.push(vt))));
    }
    dispatch(addTeamsCapab(teams));
  }, [capsMapping]);

  useEffect(() => {
    if (treeData?.some((element) => element?.children?.some((fc) => fc?.children?.length > 0)) && !checkApps) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [checkApps]);

  useEffect(() => {
    if (peopleData?.reqCollaborator
      && Array.isArray(peopleData?.reqCollaborator)
      && peopleData?.reqCollaborator?.length > 0
      && capsMapping
      && Array.isArray(capsMapping)
      && capsMapping?.length > 0
    ) {
      const keyFilter = peopleData?.reqCollaborator?.filter((cb) => capsMapping?.some((cc) => cc?.key === cb?.key));
      const valueFilter = capsMapping?.map((cc) => cc?.value?.map((vl) => ({ ...vl, key: cc?.key })))?.flat();
      const result = keyFilter?.map((cb) => ({ key: cb?.key, value: cb?.value?.filter((cbv) => valueFilter?.some((cc) => (cc?.key === cb?.key && cbv?.id === cc?.id))) }));
      setCollaboratorData(result);
    } else {
      setCollaboratorData([]);
    }
  }, [peopleData]);

  const userhandler = (e, val) => {
    e.stopPropagation();
    setSelVal({
      ...selVal,
      flag: true,
      val,
    });
    if (val === 'collab') {
      if (!treeData?.some((element) => element?.children?.some((fc) => fc?.children?.length > 0))) {
        setNotify('No capabilities are selected');
      } else {
        setCollaborate(true);
        setTableTitle(t('people:selectTitle.collaborator', `Collaborator (${collaboratorData?.length} Selected)`));
      }
    }
  };
  console.log('selectedApp', selectedApp);
  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (collaborate && selectedApp) {
      if (
        !collaboratorData?.some((ck) => ck.value.some((cv) => cv?.name === selectedApp?.label && cv?.key === selectedApp?.type))
      ) {
        const dataa = {
          key: selectedApp?.type,
          id: selectedApp?.key,
          name: selectedApp?.label,
          collaborators: [
            {
              id: d.id,
              first_name: d?.first_name,
              last_name: d?.last_name,
            },
          ],
        };
        if ((collaboratorData && Array.isArray(collaboratorData) && collaboratorData?.length > 0) && collaboratorData?.some((cd) => cd.key === selectedApp?.type)) {
          if (collaboratorData?.some((cd) => cd.key === selectedApp?.type)) {
            const copyDt = [...collaboratorData];
            const arr = copyDt.filter((cd) => cd.key === selectedApp?.type).map((cl) => [...cl.value, dataa]).flat();
            const index = copyDt.findIndex((ele) => ele.key === selectedApp?.type);
            const updatedCopyDt = copyDt.map((cp, i) => (i === index ? { ...cp, value: arr } : { ...cp }));
            setCollaboratorData(updatedCopyDt);
          }
        } else {
          setCollaboratorData([...collaboratorData, { key: selectedApp?.type, value: [dataa] }]);
        }
      } else {
        const vals = [...collaboratorData];
        const dddd = vals.filter((cd) => cd.key === selectedApp?.type).map((cd) => cd.value.map((cv) => (cv.name === selectedApp?.label ? {
          ...cv,
          collaborators: cv.collaborators.some((cl) => cl.id === d.id)
            ? cv.collaborators.filter((cf) => cf.id !== d.id)
            : [...cv.collaborators, {
              id: d.id,
              first_name: d?.first_name,
              last_name: d?.last_name,
            }],
        } : cv))).flat();
        const updated = vals.filter((vl) => vl.key === selectedApp?.type).map((ml) => ml.value.map((vl) => (dddd.some((dd) => dd.name === vl.name) ? { ...vl, collaborators: dddd[dddd.findIndex((dd) => dd.name === vl.name)].collaborators } : vl))).flat();
        const index = vals.findIndex((ele) => ele.key === selectedApp?.type);
        vals[index].value = updated;
        setCollaboratorData(vals);
      }
    }
  };

  useEffect(() => {
    if (collaborate && collaboratorData) {
      setTableTitle(`Collaborator (${collaboratorData?.map((cb) => cb?.value?.map((cv) => cv?.collaborators).flat())?.flat()?.length} Selected)`);
    }
  }, [collaborate, collaboratorData]);

  useEffect(() => {
    if (selectedApp && !collaborate) {
      setNotify(t('people:tostifyTitle.select_collaborator'));
    }
  }, [selectedApp]);

  useEffect(() => {
    if (collaborate && !selectedApp && treeData?.some((element) => element?.children?.some((fc) => fc?.children?.length > 0))) {
      setNotify('Please select capabilities');
    } else {
      setNotify('');
    }
  }, [collaborate]);

  const removeHandler = (e, val, name, appName, cls, key) => {
    e.stopPropagation();
    if (val === 'collab') {
      if ((collaboratorData && Array.isArray(collaboratorData) && collaboratorData?.length > 0) && appName) {
        const vals = [...collaboratorData];
        const result = vals?.map((item) => ({
          ...item,
          value: item?.value?.map((vl) => ({
            ...vl,
            collaborators: (vl?.name === appName && key === item?.key) ? vl?.collaborators?.filter((el) => el?.id !== cls?.id) : vl?.collaborators,
          })),
        }));
        setCollaboratorData(result);
      }
    }
  };

  const getCheckedVal = (nameVal) => {
    if (
      collaborate
      && collaboratorData?.some((cd) => cd.value.some(
        (cv) => (cv?.name === selectedApp?.label && cv?.key === selectedApp?.type)
            && cv.collaborators.some((cl) => cl.id === nameVal),
      ))
    ) { return true; }
    return false;
  };

  const componentHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (collaboratorData) {
      const pdata = {
        reqCollaborator:
          collaboratorData && collaboratorData?.length > 0
            ? collaboratorData
            : null,
      };
      dispatch(addPeople(pdata));
      handleNext();
    }
  };
  console.log('treeData', treeData);

  useEffect(() => {
    if (collaboratorData && Array.isArray(collaboratorData) && collaboratorData.length > 0) {
      const cbValArr = collaboratorData?.map((cb) => cb?.value)?.flat();
      if (treeData && Array.isArray(treeData) && treeData?.length > 0) {
        treeData?.some((element) => {
          element?.children?.some((sc) => {
            if (cbValArr && Array.isArray(cbValArr) && cbValArr) {
              if (cbValArr?.length === sc?.children?.length && cbValArr?.every((vl) => vl?.collaborators?.length > 0)) {
                setCheckApps(true);
                setNotify('');
              } else {
                setNotify('Please select capabilities');
                setCheckApps(false);
              }
            }
          });
        });
      }
    }
  }, [treeData, collaboratorData]);

  const toggleTreebar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const el = document.getElementById('toggle');
    el.classList.toggle('open-tree');
  };

  // add collaborator
  const addCollaborator = (dt, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!collaborate) {
      setNotify('Please Select Collaborator');
    }
    const tree = document.getElementById('tree');
    const attr = tree.getBoundingClientRect();
    const selected = e.target;
    const selectedRect = selected.getBoundingClientRect();
    setHeight(selectedRect.top - attr.top + tree.scrollTop);
    setSelectedApp(dt);
    setColle(true);
  };

  const changeStatus = location?.state?.data?.dfVals?.status || location?.state?.change_data?.data?.dfVals?.status;

  return (
    <form action="" className="add-program-form">
      <div className="form-unit">
        <div className="container-fluid">
          <h2>
            {(editMode && !draftMode && !fields?.resp_person) ? t('people:section_title_edit')
              : t('people:section_title_add')}

          </h2>
          <div className="row add-people">
            {fields?.collaborator && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="program_uid">
                    {t('people:collaborator')}
                    {treeData?.some((element) => element?.children?.some((fc) => fc?.children?.length > 0)) && <sup>*</sup>}
                  </label>
                  <div
                    className={`form-control ${collaborate ? 'active' : ''}`}
                    type="text"
                    name="unique_"
                    id="program_subj"
                  >
                    <div className="md-chips">
                      {collaboratorData
                        && collaboratorData.length > 0
                        && collaboratorData?.map((cb) => cb?.value?.map((cv) => cv?.collaborators?.map((cls) => (
                          <div className="md-chip" key={cv?.name}>
                            <span className="collaborator-chip">
                              <span className={
                                cb?.key === 'process' ? 'icon-value-chain'
                                  : cb?.key === 'vendor' ? 'icon-supplier'
                                    : cb?.key === 'business' ? 'icon-business'
                                      : cb?.key === 'customer' ? 'icon-consumer'
                                        : cb?.key === 'product' ? 'icon-toolbar'
                                          : `icon-${cb?.key}`
                              }
                              />
                              {' '}
                              {cv?.name}
                              :
                              {' '}
                              <small className="text-capitalize">{`${cls?.first_name || ''} ${cls?.last_name || ''}`}</small>
                            </span>
                            {(changeStatus === 'New' || changeStatus === 'Open' || changeStatus === 'Initiation' || changeStatus === 'Backlog') && (
                            <button
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'collab', cls, cv?.name, cls, cb?.key)}
                            >
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        ))))}
                    </div>
                    <div className="input-group-append">
                      {/* {(changeStatus === 'New' || changeStatus === 'Open' || changeStatus === 'Initiation' || changeStatus === 'Backlog' || changeStatus === 'Backlog' || changeStatus === 'In Progress') && treeData?.some((element) => element?.children?.some((fc) => fc?.children?.length > 0)) && ( */}
                      {(changeStatus === 'New' || changeStatus === 'Open' || changeStatus === 'Initiation' || changeStatus === 'Backlog' || changeStatus === 'Backlog' || changeStatus === 'In Progress') && (
                      <button
                        type="button"
                        className="input-group-text btn btn-link btn-small"
                        onClick={(e) => userhandler(e, 'collab')}
                      >
                        {t('people:selectTitle.select')}
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="table-wrapper mb-4">

            <div className="tab-content" id="ChartTabContent">
              <div
                className="tab-pane fade show active"
                id="program"
                role="tabpanel"
                aria-labelledby="program-tab"
              >
                {notify && (
                  <div className={`toast-wrapper ${notify && 'show'} text-capitalize`}>
                    <Alert variant="warning" onClose={() => setNotify('')} dismissible>
                      <span className="icon-information" />
                      {notify}
                    </Alert>
                  </div>
                )}
                <div className="slide-item white-card">
                  <div id="toggle" className="object-wrapper open-tree">
                    <div className="object-header">
                      <h5>{t('people:related_people.title')}</h5>
                      <Link to="" className="slide-toggle" onClick={(e) => toggleTreebar(e)}>
                        <span className="icon-showhide" />
                      </Link>
                    </div>
                    <div className="object-body">
                      <div className="tree-wrapper" id="tree">
                        <Tree collapseHandle={setColle} data={treeData} selectedApp={selectedApp?.label} handleClick={addCollaborator} />
                        {selectedApp && colle
                        && <span className="white-highlight" style={{ top: height }}>&nbsp;</span>}
                      </div>
                      <button
                        type="button"
                        onClick={() => setDisplayTable(!displayTable)}
                        className="btn btn-outline-primary btn-rounded"
                      >
                        {displayTable ? t('people:related_people.showAllUser') : t('people:related_people.showTeamUser')}
                      </button>
                    </div>
                  </div>
                  <div className="initiative-list">
                    <div className="list-head d-flex align-items-center justify-content-between">
                      <h2 className="mb-0">
                        {tableTitle || t('people:selectTitle.common')}
                      </h2>
                    </div>
                    <div>
                      {
                        !displayTable
                        && (
                        <TablePeople
                          checkHandler={checkHandler}
                          userData={dataTable}
                          getCheckedVal={getCheckedVal}
                          selVal={selVal}
                          setSelVal={setSelVal}
                          collaboratorData={collaboratorData}
                          disabled
                          tableMessage="Please select the role for which you want to add people."
                          selectedApp={selectedApp}
                          label="requirement"
                        />
                        )
                      }
                      {
                          displayTable
                          && (
                          <TablePeople
                            checkHandler={checkHandler}
                            userData={teamsData}
                            getCheckedVal={getCheckedVal}
                            selVal={selVal}
                            setSelVal={setSelVal}
                            collaboratorData={collaboratorData}
                            disabled
                            tableMessage="Please select the role for which you want to add people."
                            selectedApp={selectedApp}
                            label="requirement"
                          />
                          )
                        }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          {/* <h2>{t('people:selectTitle.common')}</h2>
          <TablePeople
            checkHandler={checkHandler}
            userData={dataTable}
            getCheckedVal={getCheckedVal}
            selVal={selVal}
            // ownerData={ownerData}
            // subjMtExpData={subjMtExpData}
            // assigneeData={assigneeData}
            // managerData={managerData}
            // strComData={strComData}
            // sponsorData={sponserData}
            // teamMemberData={teamMemberData}
            // resPersonData={resPersonData}
            disabled
            tableMessage={t('people:table_message')}
          /> */}

          <CancelModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            cancelTitle={cancelTitle}
            func={stepReset}
          />
          <StepFooter
            label={label}
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            componentHandler={componentHandler}
            modalShow={modalShow}
            setModalShow={setModalShow}
            nextDisabled={nextDisabled}
          />
        </div>
      </div>
    </form>
  );
}

const RequirementPeople = React.memo(RequirementPeoples);
export default RequirementPeople;
