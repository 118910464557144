import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  valueChains: [],
};
export const valueChainSlice = createSlice({
  name: 'valueChain',
  initialState,
  reducers: {
    addValChains: (state, { payload }) => {
      state.valueChains = payload;
    },
  },
});

export const { addValChains } = valueChainSlice.actions;

export default valueChainSlice.reducer;
