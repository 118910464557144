/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSearchFilter from '../../hooks/useSearchFilter';
import PaginationComponenet from '../common/Pagination';
import { addDependentEpicTask, addSelectedDependentEpicTask } from '../../services/epicTaskSlice';
import ReadMoreLess from '../common/ReadMoreLess';
import { useMyPageEpicTaskMutation } from '../../services/services';

function DependentEpicTaskModal({
  modal, handleClose, label,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const location = useLocation();
  const dispatch = useDispatch();
  const [reqFetchData, fetchData] = useMyPageEpicTaskMutation();
  const epicTaskValues = useSelector((state) => state.epicTaskData.epicTaskVals);
  const selectedDepndentEpicTask = useSelector((state) => state.epicTaskData.selectedDepndentEpicTask);
  const dependentEpicTask = useSelector((state) => state.epicTaskData.dependentEpicTask);
  console.log('dependentEpicTask', dependentEpicTask);

  const [dataTable, setDataTable] = useState([]);
  const [TBLData, setTBLData] = useState(dataTable || []);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [selectedEpicTask, setSelectedEpicTask] = useState([]);
  console.log('selectedEpicTask', selectedEpicTask);
  console.log('requirementData', TBLData);

  useEffect(() => {
    reqFetchData({ id: userData?.id });
  }, [label]);

  useEffect(() => {
    if (fetchData?.isSuccess && Array.isArray(fetchData?.data) && fetchData?.data?.length > 0) {
      if (epicTaskValues?.name) {
        setDataTable(fetchData?.data?.filter((pd) => pd?.name !== epicTaskValues?.name));
      } else {
        setDataTable([...fetchData.data]);
      }
    } else {
      setDataTable([]);
    }
  }, [fetchData?.isSuccess]);

  useEffect(() => {
    if (location.pathname === '/epic-task') {
      if (dependentEpicTask && Array.isArray(dependentEpicTask) && dependentEpicTask?.length > 0) {
        setSelectedEpicTask(dependentEpicTask);
      } else {
        setSelectedEpicTask([]);
      }
    } else if (selectedDepndentEpicTask && Array.isArray(selectedDepndentEpicTask) && selectedDepndentEpicTask?.length > 0) {
      setSelectedEpicTask(selectedDepndentEpicTask);
    } else {
      setSelectedEpicTask([]);
    }
  }, [location, selectedDepndentEpicTask, dependentEpicTask]);

  const { filter, setFilter, filterArray } = useSearchFilter(dataTable || []);

  useEffect(() => {
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0) {
      setTotalCount(dataTable.length);
    } else {
      setTotalCount(0);
    }
  }, [dataTable]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 10) {
      const reversedArray = [];
      dataTable?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0) {
      const reversedArray = [];
      dataTable?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [dataTable, currentPage]);

  useEffect(() => {
    if (dataTable && Array.isArray(dataTable) && dataTable?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [dataTable, filter, filterArray]);

  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (selectedEpicTask?.some((v) => v.id === d.id)) {
      setSelectedEpicTask(selectedEpicTask.filter((val) => val?.id !== d?.id));
    } else {
      setSelectedEpicTask([...selectedEpicTask, d]);
    }
  };

  const removeHandler = (e, name) => {
    e.stopPropagation();
    setSelectedEpicTask(selectedEpicTask?.filter((value) => value?.id !== name?.id));
  };

  const getCheckedVal = (nameVal) => selectedEpicTask?.some((el) => el?.id === nameVal);

  const onSelect = () => {
    if (location.pathname === '/epic-task') {
      dispatch(addDependentEpicTask(selectedEpicTask));
    } else {
      dispatch(addSelectedDependentEpicTask(selectedEpicTask));
    }
    handleClose();
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal
      show={modal.show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Related Dependent Work Package Task Predecessor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
          ? (
            <div className="card">
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionDark"
              >
                <div className="card-body">
                  <h5 className="font-weight-normal" style={{ textTransform: 'capitalize' }}>
                    {`${modal.title}`}
                  </h5>
                  <div className="chip-wrapper">
                    <div className="md-chips">
                      {(selectedEpicTask && Array.isArray(selectedEpicTask) && selectedEpicTask?.length > 0) && selectedEpicTask?.map((val) => (
                        <div key={label + val.id} className="md-chip">
                          <span>{`${val.name}`}</span>
                          <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, val)}>
                            <span className="icon-close" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <h2>{`Select ${modal.title}s`}</h2>
                  <div className="table-responsive box-shadow mb-0">
                    <table className="table table-hover">
                      <thead>
                        <tr className="heading-row">
                          <th />
                          <th>Name</th>
                          <th>Description</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Efforts</th>
                          <th>Assignee</th>
                          <th>Status</th>
                        </tr>
                        <tr className="filter-row">
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="name"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="description"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="depending_capabilities"
                              disabled
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="required_resource"
                              disabled
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              name="related_epic"
                              disabled
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="status"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              name="status"
                              onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                            />
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0) ? TBLData?.map((ele) => (
                          <tr key={ele?.id}>
                            <td className="check-field">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type={label === 'epic' ? 'radio' : 'checkbox'}
                                  className="custom-control-input"
                                  checked={getCheckedVal(ele?.id)}
                                  onChange={(e) => checkHandler(e, ele)}
                                  value={ele?.name}
                                  id={modal.key + ele.id}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={modal.key + ele.id}
                                  aria-label="select"
                                />
                              </div>
                            </td>
                            <td><Button href={`/review/epic-task/${ele?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{ele.name || '-'}</Button></td>
                            <td>
                              <ReadMoreLess text={ele?.description} />
                            </td>
                            <td className="text-capitalize">{ele?.start_date ? dayjs(ele?.start_date).format(userData?.tenant_info?.date_format) : '-'}</td>
                            <td className="text-capitalize">{ele?.end_date ? dayjs(ele?.end_date).format(userData?.tenant_info?.date_format) : '-'}</td>
                            <td className="text-capitalize">{(ele?.estimated_effort || ele?.estimated_efforts) ? `${(ele?.estimated_effort || ele?.estimated_efforts)}  Days` : '-'}</td>
                            <td className="text-capitalize">
                              {ele?.assignee ? `${ele?.assignee?.first_name || ''}  ${ele?.assignee?.last_name || ''}` : '-'}
                            </td>
                            {/* <td className="text-capitalize"><ReadMoreLess text={ele?.notes || '-'} /></td> */}
                            <td>{ele?.status || '-'}</td>
                          </tr>
                        ))
                          : <tr className="text-center"><td colSpan={7}>No Data To Display</td></tr>}
                      </tbody>
                    </table>
                    {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
       && (
       <PaginationComponenet
         currentPage={currentPage}
         totalCount={totalCount}
         pageSize={PageSize}
         onPageChange={(page) => setCurrentPage(page)}
         TBLData={TBLData}
       />
       )}
                  </div>
                </div>
              </div>
            </div>
          )
          : `No ${modal.title} Found`}
      </Modal.Body>
      {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
      && (
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
        <Button variant="primary" onClick={() => onSelect()}>Select</Button>
      </Modal.Footer>
      )}
    </Modal>
  );
}

export default DependentEpicTaskModal;
