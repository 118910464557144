/* eslint-disable no-return-assign */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
// @ts-nocheck

import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { addDocsInfo, deleteBudgetDocInfo, deleteDocInfo } from '../../services/docInfoSlice';
import {
  useGetUrlsMutation, useGetDeleteUrlMutation,
  useGetDownoadUrlsMutation, useDeleteDocsMutation, useSaveDocsMutation,
  useSubmitFormMutation,
} from '../../services/services';

const FileUpload = memo(({
  fld, name, displayName, errors, setErrors, validation, label, values, handleChange, setValues,
  epicInputDisable, changeInputDisable, inputDisable,
}) => {
  const allDocs = useSelector((state) => state.docInfoData.docsInfo);
  const dispatch = useDispatch();
  const [getUrl, respo] = useGetUrlsMutation();

  const [getDeleteUrl, response] = useGetDeleteUrlMutation();
  const [downloadUrls, downRenponse] = useGetDownoadUrlsMutation();

  const [deleteDocs, deleteRes] = useDeleteDocsMutation();

  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const budgetDocsInfo = useSelector((state) => state.docInfoData.budgetDocsInfo);
  const editMode = useSelector((state) => state.editData.editMode);

  const [uploadFile, setUploadFile] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [existing, setExisting] = useState([]);
  const [fileError, setFileError] = useState([]);
  const [durls, setDurls] = useState([]);

  const timeStamp = +new Date();
  const date = new Date();
  const month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
  const datse = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  const time = `${date.getFullYear()}-${month}-${datse} ${hours}:${minutes}:${seconds}`;

  const fileHandler = (e) => {
    e.preventDefault();
    const { files } = e.target;
    const temArr = [];
    const filesArr = [];
    const apdArr = values[name] ? [...values[name]] : [];
    const upFile = [];
    const appenFileArr = [];
    const errorss = [];
    const sizeErr = [];
    const extErr = [];
    const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png'];
    setFileError([]);

    for (let i = 0; i < files.length; i++) {
      const fileExtension = files[i].name.split('.').pop();
      if ((e.target.files[i].size > 10000000)) {
        // sizeErr.push({ name: e.target.files[i].name, error: 'Max File size Limit exceeded' });
        // setErrors({ ...errors, name: [...sizeErr] });
        errorss.push({ name: e.target.files[i].name, error: 'Max File size Limit exceeded' });
        setFileError([...errorss]);
      } else if (!allowedExtensions.includes(fileExtension)) {
        // extErr.push({ name: e.target.files[i].name, error: 'File Type not allowed' });
        // setErrors({ ...errors, [name]: [...extErr] });
        errorss.push({ name: e.target.files[i].name, error: 'File Type not allowed' });
        setFileError([...errorss]);
      } else {
        temArr.push({ file_name: files[i].name, key_name: `${timeStamp}_${files[i].name}`, pre_signed_url: '' });
        upFile.push({ file: files[i].name, percent: '' });
        if (values[name] && values[name].length > 0) {
          apdArr.push({
            category: label,
            source: 1,
            original_name: files[i].name,
            s3_key_name: `${timeStamp}_${files[i].name}`,
            file: files[i],
            created: time,
            updated: time,
            deleted: false,
            created_by: {
              id: 1,
            },
            updated_by: {
              id: 1,
            },
            location: '',
            author: 1,
            prct: '',
          });
          const target = { name: e.target.name, value: apdArr };
          handleChange({ target });
        } else {
          filesArr.push({
            category: label,
            source: 1,
            original_name: files[i].name,
            s3_key_name: `${timeStamp}_${files[i].name}`,
            file: files[i],
            created: time,
            updated: time,
            deleted: false,
            created_by: {
              id: 1,
            },
            updated_by: {
              id: 1,
            },
            location: '',
            author: 1,
            prct: '',
          });
          const target = { name: e.target.name, value: filesArr };
          handleChange({ target });
        }
      }
    }

    setFileList([...temArr]);
    setUploadFile([...filesArr]);
    setUploadPercentage([...filesArr]);
  };

  useEffect(() => {
    if (!allDocs.length) {
      setUploadPercentage([]);
    }
  }, [allDocs.length]);

  useEffect(() => {
    getUrl(fileList);
  }, [fileList]);

  // useEffect(() => {
  //   if (values[name] && Array.isArray(values[name]) && values[name]?.length > 0) {
  //     const sendData = values[name]?.map((ele) => ({
  //       file_name: ele?.original_name,
  //       key_name: ele?.s3_key_name,
  //       pre_signed_url: '',
  //     }));
  //     downloadUrls({ data: sendData });
  //   }
  // }, [values[name]]);

  useEffect(() => {
    const formData = new FormData();
    if (respo.isSuccess) {
      const temFiles = values[name];
      respo.data.forEach((dt) => temFiles.forEach((fl) => {
        if (fl.s3_key_name === dt.key_name) {
          formData.append(fl.original_name, fl.file);
          axios.put(dt.pre_signed_url, fl.file, {
            // headers: { 'Content-Type': 'application/octet-stream' },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              const filesCopy = values[name];

              const tempDurl = durls;
              const newFile1 = {
                ...fl,
                prct: percentCompleted,
                loaded: progressEvent.loaded,
                total: progressEvent.total,
              };
              delete newFile1.file;
              const fileIndex = filesCopy.findIndex((el) => el.s3_key_name === fl.s3_key_name);

              filesCopy.splice(fileIndex, 1, newFile1);

              setUploadPercentage(filesCopy);
              const target = { name, value: filesCopy };
              handleChange({ target });
              setExisting([...filesCopy]);

              fl = { ...fl, prct: percentCompleted };
            },
          });
        }
      }));
    }
  }, [respo]);
  useEffect(() => {
    if (response.isSuccess) {
      if (values[name]) {
        const arrayrr = [...values[name]];
        const deletedId = arrayrr.filter((item) => item.s3_key_name === response.data.keyName);
        const target = { name, value: arrayrr.filter((item) => item.s3_key_name !== response.data.keyName) };
        if (deletedId.length > 0) {
          deleteDocs({ id: deletedId[0].id });
        }
        handleChange({ target });
      }
      if (name === 'documents') {
        dispatch(deleteDocInfo(response.data.keyName));
      }
      if (name === 'budget_documents') {
        dispatch(deleteBudgetDocInfo(response.data.keyName));
      }
    }
  }, [response]);

  const deleteFile = (val) => {
    getDeleteUrl({ keyName: val.s3_key_name });
  };

  // useEffect(() => {
  //   uploadPercentage.forEach((ex) => {
  //     if (ex.prct === 100) {
  //       if (allDocs.length === 0) return dispatch(addDocsInfo(uploadPercentage));
  //       if (allDocs.length > 0
  //         && !allDocs.some((all) => uploadPercentage.some((exl) => exl.s3_key_name === all.s3_key_name))) {
  //         dispatch(addDocsInfo([...allDocs, ...uploadPercentage]));
  //       }
  //     }
  //   });
  // }, [uploadPercentage.every((up) => up.prct === 100)]);

  useEffect(() => {
    allDocs.forEach((ex) => {
      if (uploadPercentage.length === 0) return setUploadPercentage(allDocs);
    });
  }, [allDocs]);

  return (
    <div className="col-6 col-lg-4" key={name}>

      <div className="form-group upload-group">
        <label htmlFor={name + label}>
          <span>{displayName}</span>
          {validation && <sup>*</sup>}
          <strong>{(values[name] && values[name]?.length > 0 && Array.isArray(values[name])) && `${values[name].length} File(s)`}</strong>
          {!(epicInputDisable || changeInputDisable || inputDisable || fld?.isDisabled) && <span className="upload-select">Select</span>}
        </label>
        <input
          type="file"
          name={name}
          onClick={(e) => e.target.value = ''}
          id={name + label}
          onChange={(e) => fileHandler(e)}
          className="form-control inputfile"
          data-multiple-caption="{count} files selected"
          multiple
          disabled={fld?.isDisabled}
        />
        <div className="upload-list-wrapper">
          <div className="upload-list">
            {
               (values[name] && values[name]?.length > 0 && Array.isArray(values[name]))
                  && values[name]?.map((fl) => (
                    fl.prct < 100
                      ? (
                        <div key={fl.s3_key_name} className="upload-item">
                          <span className="upload-name">
                            {fl.original_name || ''}
                          </span>
                          <div className="progress-wrapper d-flex align-items-center">
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${fl.prct}%` }}
                                aria-valuenow={fl.prct}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                aria-label="progress-bar"
                              />
                            </div>
                            <span className="upload-progress">
                              {`  ${fl.prct}`}
                              %
                            </span>
                            {(fl.loaded && fl.total)
                         && (
                         <span className="upload-progress">
                           {`  ${fl.loaded / 1000}kb  of  ${fl.total / 1000}kb` }
                         </span>
                         )}
                          </div>
                        </div>
                      ) : (
                        <div className="md-chips" key={fl?.s3_key_name}>
                          <div key={fl?.s3_key_name} className="md-chip">
                            <span>
                              {fl.original_name}
                            </span>
                            {!fl?.cancelabled && !fld?.isDisabled
                            && (
                            <button aria-label="button" onClick={() => deleteFile(fl)} type="button" className="md-chip-remove ml-2">
                              <span className="icon-close" />
                            </button>
                            )}
                          </div>
                        </div>
                      )
                  ))
            }
            {
              ((!values[name] || (values[name]?.length === 0 && Array.isArray(values[name]))))
              && (
              <span>
                File Upload
              </span>
              )
            }
          </div>
          <p className="upload-note">
            (
            {t(t(`${label}:file_message`))}
            )
            {' '}

            {' '}
          </p>
          {(fileError && fileError?.length > 0 && Array.isArray(fileError))
          && fileError?.map((err) => (
            <div className="invalid-feedback" key={err?.name}>
              <span className="icon-error-red" />
              {`${err.name}:-`}
              {' '}
              {err.error}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
export default FileUpload;
