/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import Heatmap from './HeatMap';
import Heatmap2 from './Heatmap2';
import GanttChart from './GanttChart';
import GuageChart from './GuageChart';
import BarChart from './BarChart';
import LineChart from './LineChart';

const data = [
  { type: 'error', count: '5' },
  { type: 'success', count: '15' },
  { type: 'warning', count: '3' },
  { type: 'aborted', count: '2' },
];

// const heatmapData = [
//   { x: 'A', y: 'A', value: 1 },
//   { x: 'B', y: 'A', value: 2 },
//   { x: 'C', y: 'A', value: 3 },
//   { x: 'D', y: 'A', value: 4 },
//   { x: 'E', y: 'A', value: 5 },
//   { x: 'F', y: 'A', value: 6 },
//   { x: 'G', y: 'A', value: 7 },
//   { x: 'H', y: 'A', value: 8 },
//   { x: 'B', y: 'B', value: 1 },
//   { x: 'B', y: 'B', value: 2 },
//   { x: 'C', y: 'A', value: 3 },
//   { x: 'D', y: 'A', value: 4 },
//   { x: 'E', y: 'A', value: 5 },
//   { x: 'F', y: 'A', value: 6 },
//   { x: 'G', y: 'A', value: 7 },
//   { x: 'H', y: 'A', value: 8 },

// ];

const heatmapData = [
  { x: 'Negligible', y: 'Very Low', value: 10 },
  { x: 'Negligible', y: 'Low', value: 20 },
  { x: 'Negligible', y: 'Medium', value: 30 },
  { x: 'Negligible', y: 'High', value: 50 },
  { x: 'Negligible', y: 'Very High', value: 59 },
  { x: 'Minor', y: 'Very Low', value: 12 },
  { x: 'Minor', y: 'Low', value: 24 },
  { x: 'Minor', y: 'Medium', value: 45 },
  { x: 'Minor', y: 'High', value: 64 },
  { x: 'Minor', y: 'Very High', value: 76 },
  { x: 'Moderate', y: 'Very Low', value: 30 },
  { x: 'Moderate', y: 'Low', value: 50 },
  { x: 'Moderate', y: 'Medium', value: 42 },
  { x: 'Moderate', y: 'High', value: 62 },
  { x: 'Moderate', y: 'Very High', value: 84 },
  { x: 'Serious', y: 'Very Low', value: 11 },
  { x: 'Serious', y: 'Low', value: 36 },
  { x: 'Serious', y: 'Medium', value: 47 },
  { x: 'Serious', y: 'High', value: 78 },
  { x: 'Serious', y: 'Very High', value: 89 },
  { x: 'Critical', y: 'Very Low', value: 53 },
  { x: 'Critical', y: 'Low', value: 61 },
  { x: 'Critical', y: 'Medium', value: 98 },
  { x: 'Critical', y: 'High', value: 83 },
  { x: 'Critical', y: 'Very High', value: 86 },
];

function DashChart() {
  const [arc, setArc] = useState(null);
  const [pie, setPie] = useState(null);
  const [colors, setColors] = useState(null);

  const createArc = () => {
    const outerRadius = 300 / 2;
    return d3
      .arc()
      .innerRadius(62) // This is the size of the donut hole
      .outerRadius(37);
  };
  const createPie = () => d3
    .pie()
    .sort(null)
    .value((d) => +d.count)
    .padAngle(0.02);

  // eslint-disable-next-line arrow-body-style
  const createColors = () => {
    // return d3.scaleOrdinal(d3.schemeCategory10).domain(data?.map((d) => d.type));
    return d3
      .scaleOrdinal()
      .range(['#FF3333', '#22EE22', '#FF9D00', '#DFE1E4']);
  };

  useEffect(() => {
    setArc(createArc);
    setColors(createColors);
    setPie(createPie);
  }, []);

  if (!arc || !pie || !colors) {
    return <div>Loading...</div>;
  }

  const ganttOneData = {
    // subHeader:[{
    //   '2018':[
    //     {sub:'Q1'},
    //     {sub:'Q2'},
    //     {sub:'Q3'},
    //     {sub:'Q4', colum:'4/16',text: 'National ID Integration'},
    //   ],
    //   '2019':[
    //     {sub:'Q1',colum:'5/12',text: 'MDM-Customer Upgrade (1 Change, 2 Processes & 1 Epic)'},
    //     {sub:'Q2'},
    //     {sub:'Q3',colum:'7/10',text: 'Customer Upgrade'},
    //     {sub:'Q4',colum:'8/9',text: 'Customer Upgrade'},
    //   ],
    //   '2020':[
    //     {sub:'Q1'},
    //     {sub:'Q2'},
    //     {sub:'Q3'},
    //     {sub:'Q4', },
    //   ],
    //   '2021':[
    //     {sub:'Q1'},
    //     {sub:'Q2'},
    //     {sub:'Q3'},
    //     {sub:'Q4', colum:'8/9',text: 'Value Chain 656'},
    //   ],
    //   '2022':[
    //     {sub:'Q1'},
    //     {sub:'Q2'},
    //     {sub:'Q3'},
    //     {sub:'Q4', colum:'8/9',text: 'Epics 767'},
    //   ],
    // }
    // ]
    header: ['2018', '2019', '2020', '2021', '2022'],
    subHeader: [
      {
        2018: ['Q1', 'Q2', 'Q3', 'Q4'],
        2019: ['Q1', 'Q2', 'Q3', 'Q4'],
        2020: ['Q1', 'Q2', 'Q3', 'Q4'],
        2021: ['Q1', 'Q2', 'Q3', 'Q4'],
        2022: ['Q1', 'Q2', 'Q3', 'Q4'],
      },
    ],
    rowData: [
      {
        startYear: '2018',
        startQ: 'Q4',
        colum: '4/16',
        text: 'National ID Integration',
      },
      {
        startYear: '2019',
        startQ: 'Q1',
        colum: '5/12',
        text: 'MDM-Customer Upgrade (1 Change, 2 Processes & 1 Epic)',
      },
      {
        startYear: '2019',
        startQ: 'Q3',
        colum: '7/10',
        text: 'Customer Upgrade',
      },
      // {
      //   colum: '8/9',
      //   text: 'Private Customer',
      // },
      // {
      //   colum: '8/9',
      //   text: 'Value Chain 656',
      // },
      // {
      //   colum: '8/9',
      //   text: 'Epics 767',
      // },
    ],
  };

  return (
    <div className="content-wrapper">
      <div className="dash-wrapper d-flex flex-wrap">
        <div className="dash-item d-grid p-0">
          <ul className="list-unstyled mb-0 prog-info">
            <li>
              Program Name:
              {' '}
              <b>National ID Integration</b>
            </li>
            <li>
              Program ID:
              {' '}
              <b>CB 0001675</b>
            </li>
            <li>
              Owner Name:
              {' '}
              <b>Sebastain Gruber</b>
            </li>
            <li>
              Manager Name:
              {' '}
              <b>Michelle Hauser</b>
            </li>
            <li>
              Completion (Actual):
              {' '}
              <b>34%</b>
            </li>
            <li>
              Completion (Plan):
              {' '}
              <b>44%</b>
            </li>
          </ul>
          <ul className="list-unstyled mb-0 status-list">
            <li>
              <b>Overall Status</b>
              <span className="chart-note green border-0 sm" />
            </li>
            <li>
              <b>Scope</b>
              <span className="chart-note green border-0 sm" />
            </li>
            <li>
              <b>Schedule</b>
              <span className="chart-note red border-0 sm" />
            </li>
            <li>
              <b>Resources</b>
              <span className="chart-note orange border-0 sm" />
            </li>
            <li>
              <b>Budget</b>
              <span className="chart-note orange border-0 sm" />
            </li>
            <li>
              <b>Quality</b>
              <span className="chart-note orange border-0 sm" />
            </li>
          </ul>
          <div className="doughnut-wrapper">
            <div className="donut-item">
              {/* <div id="doughnut_1" /> */}
              <svg width={500} height={600} className="pie-chart">
                <g transform={`translate(${200 / 2}, ${300 / 2})`}>
                  {pie(data).map((d) => (
                    <path
                      key={d.data.type}
                      fill={colors(d.data.type)}
                      d={arc(d)}
                    />
                  ))}
                  <text textAnchor="middle" fontSize="18px" y="10">
                    {data.map((d) => d.count)}
                  </text>
                </g>
              </svg>
              <h4 className="text-center">Projects</h4>
            </div>
            <div className="donut-item">
              <svg width={500} height={600} className="pie-chart">
                <g transform={`translate(${200 / 2}, ${300 / 2})`}>
                  {pie(data).map((d) => (
                    <path
                      key={d.data.type}
                      fill={colors(d.data.type)}
                      d={arc(d)}
                    />
                  ))}
                  <text textAnchor="middle" fontSize="18px" y="10">
                    {data.reduce((result, d) => result + d.count, 0)}
                  </text>
                </g>
              </svg>
              <h4 className="text-center">Changes</h4>
            </div>
            <div className="donut-item">
              <svg width={500} height={600} className="pie-chart">
                <g transform={`translate(${200 / 2}, ${300 / 2})`}>
                  {pie(data).map((d) => (
                    <path
                      key={d.data.type}
                      fill={colors(d.data.type)}
                      d={arc(d)}
                    />
                  ))}
                  <text textAnchor="middle" fontSize="18px" y="10">
                    {data.reduce((result, d) => result + d.count, 0)}
                  </text>
                </g>
              </svg>
              <h4 className="text-center">Epics</h4>
            </div>
          </div>
          <div className="chart-notations">
            <ul className="list-unstyled mb-0">
              <li>
                <span className="chart-note green" />
                On Track
              </li>
              <li>
                <span className="chart-note orange" />
                At Risk
              </li>
              <li>
                <span className="chart-note red" />
                Critical Issues
              </li>
              <li>
                <span className="chart-note grey" />
                Open
              </li>
            </ul>
          </div>
        </div>
        <div className="dash-item">
          <h2>Variance</h2>
          <div className="row">
            <LineChart />
            <div className="col-8">
              <div className="chart-notations no-color">
                <ul className="list-unstyled mb-0">
                  <li>
                    <span className="chart-note green" />
                    On Track
                  </li>
                  <li>
                    <span className="chart-note orange" />
                    At Risk
                  </li>
                  <li>
                    <span className="chart-note red" />
                    Critical Issues
                  </li>
                </ul>
              </div>
              <div className="table-repsponsive box-shadow">
                <table className="table table-hover">
                  <thead>
                    <tr className="heading-row">
                      <th>&nbsp;</th>
                      <th>Name</th>
                      <th>Manager</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Overall Status</th>
                      <th>% Complete</th>
                      <th>Variance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Project 01</td>
                      <td>Gisele Muller</td>
                      <td>01/11/2021</td>
                      <td>01/11/2021</td>
                      <td className="text-center">
                        <span className="chart-note red" />
                      </td>
                      <td>10%</td>
                      <td />
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Project 02</td>
                      <td>Sebastian Gruber</td>
                      <td>01/11/2021</td>
                      <td>01/11/2021</td>
                      <td className="text-center">
                        <span className="chart-note orange" />
                      </td>
                      <td>60%</td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="dash-item p-0 dash-card">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h2>CRITICAL PATH</h2>
              <ul className="toolbar list-unstyled mb-0 d-flex align-items-center justify-content-between">
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-sort-down" />
                      {' '}
                      Status
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        <span className="chart-note red" />
                        {' '}
                        Red
                      </a>
                      <a className="dropdown-item" href="#">
                        <span className="chart-note green" />
                        {' '}
                        Green
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-calender-empty" />
                      {' '}
                      5 years
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        Year
                      </a>
                      <a className="dropdown-item" href="#">
                        Month
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="#!">
                    <span className="icon-share" />
                  </a>
                  <a href="#!">
                    <span className="icon-settings" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="object-wrapper open-tree">
                <div className="object-header">
                  <h5>Portfolio Objects</h5>
                  <a href="#!" className="slide-toggle">
                    <span className="icon-showhide" />
                  </a>
                </div>
                <div className="object-body">
                  <div className="tree-wrapper">
                    <ul className="list-unstyled">
                      <li className="open-tree">
                        <a href="#!">
                          <span className="icon-sort" />
                          <span className="icon-portfolio" />
                          Digital Transformation Portfolio
                        </a>
                        <ul className="list-unstyled">
                          <li>
                            <a href="#!">
                              <span className="icon-sort" />
                              <span className="icon-program" />
                              Enterprise Program
                            </a>
                            <ul className="list-unstyled">
                              <li className="open-tree">
                                <a href="#!">
                                  <span className="icon-sort" />
                                  <span className="icon-digital-twin" />
                                  Digital Twins
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-epics" />
                                      Epics
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-digital-twin" />
                                  Digital Twins
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="open-tree">
                            <a href="#!">
                              <span className="icon-sort" />
                              <span className="icon-program" />
                              National ID Integration
                            </a>
                            <ul className="list-unstyled">
                              <li className="open-tree">
                                <a href="#!">
                                  <span className="icon-sort" />
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li className="open-tree">
                                    <a href="#!">
                                      <span className="icon-sort" />
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#!">
                              <span className="icon-program" />
                              Loan Ordination 2022
                            </a>
                            <ul className="list-unstyled">
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#!">
                              <span className="icon-program" />
                              Falcon
                            </a>
                            <ul className="list-unstyled">
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="gantt-wrapper gantt__fiveYear">
                <div className="gantt__row gantt__row--header">
                  {ganttOneData.header.map((hd) => (
                    <span>{hd}</span>
                  ))}
                </div>
                <div className="gantt__row gantt__row--sub-header">
                  {ganttOneData.header.map((hd) => ganttOneData.subHeader.map((shd) => shd[`${hd}`].map((dt) => <span>{dt}</span>)))}
                </div>

                {/* <div className="gantt__row gantt__row--header">
                    <span>2018</span>
                    <span>2019</span>
                    <span>2020</span>
                    <span>2021</span>
                    <span>2022</span>
                  </div> */}

                {/* <div className="gantt__row gantt__row--sub-header">
                    <span>Q1</span>
                    <span>Q2</span>
                    <span>
                      Q3
                    </span>
                    <span>Q4</span>
                    {' '}
                    <span>Q1</span>
                    <span>
                      Q2
                    </span>
                    <span>Q3</span>
                    <span>Q4</span>
                    {' '}
                    <span>
                      Q1
                    </span>
                    <span>Q2</span>
                    <span>Q3</span>
                    <span>Q4</span>
                    <span>Q1</span>
                    <span>Q2</span>
                    <span>
                      Q3
                    </span>
                    <span>Q4</span>
                    {' '}
                    <span>Q1</span>
                    <span>
                      Q2
                    </span>
                    <span>Q3</span>
                    <span>Q4</span>
                  </div> */}
                {/* <!-- <div class="gantt__row gantt__row--lines">
                        <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                      </div> --> */}
                <div className="gantt-body">
                  <div className="gantt-lines">
                    {ganttOneData.header.map((hd) => ganttOneData.subHeader.map((shd) => shd[`${hd}`].map((dt) => <span key={dt} />)))}

                    {/* <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span /> */}
                  </div>
                  {ganttOneData.header.map((hd) => ganttOneData.subHeader.map((shd) => shd[`${hd}`].map((dt) => ganttOneData.rowData.map((rd) => (rd.startYear === hd && rd.startQ === dt ? (
                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li
                        className="bar bar-program"
                        style={{ gridColumn: rd.colum }}
                      >
                        <div className="bar-inner">
                          <span className="icon-program" />
                          <p className="mb-0 px-1">{rd.text}</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul className="gantt__row--bars list-unstyled mb-0" />
                  ))))))}

                  {/* {ganttOneData.rowData.map((rd) => (
                      <ul className="gantt__row--bars list-unstyled mb-0">
                        <li className="bar bar-program" style={{ gridColumn: rd.colum }}>
                          <div className="bar-inner">
                            <span className="icon-program" />
                            <p className="mb-0 px-1">{rd.text}</p>
                            <span className="icon-more-vertical" />
                          </div>
                        </li>
                      </ul>
                    ))} */}

                  {/* <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li className="bar bar-program" style={{ gridColumn: '4/16' }}>
                        <div className="bar-inner">
                          <span className="icon-program" />
                          <p className="mb-0 px-1">National ID Integration</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>

                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li className="bar bar-project" style={{ gridColumn: '5/12' }}>
                        <div className="bar-inner">
                          <span className="icon-initiatives" />
                          <p className="mb-0 px-1">MDM-Customer Upgrade (1 Change, 2 Processes & 1 Epic)</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>

                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li className="bar bar-change" style={{ gridColumn: '7/10' }}>
                        <div className="bar-inner">
                          <span className="icon-requirement-changes" />
                          <p className="mb-0 px-1">Customer Upgrade</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>

                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li className="bar bar-capability" style={{ gridColumn: '8/9' }}>
                        <div className="bar-inner">
                          <span className="icon-value-chain" />
                          <p className="mb-0 px-1">Private Customer</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>

                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li className="bar bar-capability" style={{ gridColumn: '8/9' }}>
                        <div className="bar-inner">
                          <span className="icon-value-chain" />
                          <p className="mb-0 px-1">Value Chain 656</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>

                    <ul className="gantt__row--bars list-unstyled mb-0">
                      <li className="bar bar-capability" style={{ gridColumn: '8/9' }}>
                        <div className="bar-inner">
                          <span className="icon-epics" />
                          <p className="mb-0 px-1">Epics 767</p>
                          <span className="icon-more-vertical" />
                        </div>
                      </li>
                    </ul>

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" />

                    <ul className="gantt__row--bars list-unstyled mb-0" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="dash-item p-0 dash-card">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h2>CRITICAL PATH</h2>
              <ul className="toolbar list-unstyled mb-0 d-flex align-items-center justify-content-between">
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-sort-down" />
                      {' '}
                      Status
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        <span className="chart-note red" />
                        {' '}
                        Red
                      </a>
                      <a className="dropdown-item" href="#">
                        <span className="chart-note green" />
                        {' '}
                        Green
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-calender-empty" />
                      {' '}
                      1 year
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        Year
                      </a>
                      <a className="dropdown-item" href="#">
                        Month
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="#!">
                    <span className="icon-share" />
                  </a>
                  <a href="#!">
                    <span className="icon-settings" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="object-wrapper open-tree">
                <div className="object-header">
                  <h5>Portfolio Objects</h5>
                  <a href="#!" className="slide-toggle">
                    <span className="icon-showhide" />
                  </a>
                </div>
                <div className="object-body">
                  <div className="tree-wrapper">
                    <ul className="list-unstyled">
                      <li className="open-tree">
                        <a href="#!">
                          <span className="icon-sort" />
                          <span className="icon-portfolio" />
                          Digital Transformation Portfolio
                        </a>
                        <ul className="list-unstyled">
                          <li>
                            <a href="#!">
                              <span className="icon-sort" />
                              <span className="icon-program" />
                              Enterprise Program
                            </a>
                            <ul className="list-unstyled">
                              <li className="open-tree">
                                <a href="#!">
                                  <span className="icon-sort" />
                                  <span className="icon-digital-twin" />
                                  Digital Twins
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-epics" />
                                      Epics
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-digital-twin" />
                                  Digital Twins
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="open-tree">
                            <a href="#!">
                              <span className="icon-sort" />
                              <span className="icon-program" />
                              National ID Integration
                            </a>
                            <ul className="list-unstyled">
                              <li className="open-tree">
                                <a href="#!">
                                  <span className="icon-sort" />
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li className="open-tree">
                                    <a href="#!">
                                      <span className="icon-sort" />
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#!">
                              <span className="icon-program" />
                              Loan Ordination 2022
                            </a>
                            <ul className="list-unstyled">
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#!">
                              <span className="icon-program" />
                              Falcon
                            </a>
                            <ul className="list-unstyled">
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="gantt-wrapper gantt__oneYear">
                <div className="gantt__row gantt__row--header">
                  <span>2022</span>
                </div>
                <div className="gantt__row gantt__row--sub-header">
                  <span>Jan</span>
                  <span>Feb</span>
                  <span>Mar</span>
                  <span>Apr</span>
                  <span>May</span>
                  <span>Jun</span>
                  <span>Jul</span>
                  <span>Aug</span>
                  <span>Sep</span>
                  <span>Oct</span>
                  <span>Nov</span>
                  <span>Dec</span>
                </div>
                <div className="gantt-body">
                  <div className="gantt-lines">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                  </div>
                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-program"
                      style={{ gridColumn: '4/6' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-program" />
                        <p className="mb-0 px-1">National ID Integration</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-project"
                      style={{ gridColumn: '5/12' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-initiatives" />
                        <p className="mb-0 px-1">
                          MDM-Customer Upgrade (1 Change, 2 Processes & 1 Epic)
                        </p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-change"
                      style={{ gridColumn: '7/10' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-requirement-changes" />
                        <p className="mb-0 px-1">Customer Upgrade</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-capability"
                      style={{ gridColumn: '8/9' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-value-chain" />
                        <p className="mb-0 px-1">Private Customer</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-capability"
                      style={{ gridColumn: '8/9' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-value-chain" />
                        <p className="mb-0 px-1">Value Chain 656</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-capability"
                      style={{ gridColumn: '8/9' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-epics" />
                        <p className="mb-0 px-1">Epics 767</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dash-item p-0 dash-card">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h2>CRITICAL PATH</h2>
              <ul className="toolbar list-unstyled mb-0 d-flex align-items-center justify-content-between">
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-sort-down" />
                      {' '}
                      Status
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        <span className="chart-note red" />
                        {' '}
                        Red
                      </a>
                      <a className="dropdown-item" href="#">
                        <span className="chart-note green" />
                        {' '}
                        Green
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-calender-empty" />
                      {' '}
                      1 Month
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        Year
                      </a>
                      <a className="dropdown-item" href="#">
                        Month
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="#!">
                    <span className="icon-share" />
                  </a>
                  <a href="#!">
                    <span className="icon-settings" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="object-wrapper open-tree">
                <div className="object-header">
                  <h5>Portfolio Objects</h5>
                  <a href="#!" className="slide-toggle">
                    <span className="icon-showhide" />
                  </a>
                </div>
                <div className="object-body">
                  <div className="tree-wrapper">
                    <ul className="list-unstyled">
                      <li className="open-tree">
                        <a href="#!">
                          <span className="icon-sort" />
                          <span className="icon-portfolio" />
                          Digital Transformation Portfolio
                        </a>
                        <ul className="list-unstyled">
                          <li>
                            <a href="#!">
                              <span className="icon-sort" />
                              <span className="icon-program" />
                              Enterprise Program
                            </a>
                            <ul className="list-unstyled">
                              <li className="open-tree">
                                <a href="#!">
                                  <span className="icon-sort" />
                                  <span className="icon-digital-twin" />
                                  Digital Twins
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-epics" />
                                      Epics
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-digital-twin" />
                                  Digital Twins
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li className="open-tree">
                            <a href="#!">
                              <span className="icon-sort" />
                              <span className="icon-program" />
                              National ID Integration
                            </a>
                            <ul className="list-unstyled">
                              <li className="open-tree">
                                <a href="#!">
                                  <span className="icon-sort" />
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li className="open-tree">
                                    <a href="#!">
                                      <span className="icon-sort" />
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#!">
                              <span className="icon-program" />
                              Loan Ordination 2022
                            </a>
                            <ul className="list-unstyled">
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="#!">
                              <span className="icon-program" />
                              Falcon
                            </a>
                            <ul className="list-unstyled">
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#!">
                                  <span className="icon-initiatives" />
                                  MDM-Customer Upgrade
                                </a>
                                <ul className="list-unstyled">
                                  <li>
                                    <a href="#!">
                                      <span className="icon-requirement-changes" />
                                      Customer Upgrade
                                    </a>
                                    <ul className="list-unstyled">
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Private Customer
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-value-chain" />
                                          Value Chain 656
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <span className="icon-epics" />
                                          Epics 756
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="gantt-wrapper gantt__oneMonth">
                <div className="gantt__row gantt__row--header">
                  <span>Jan</span>
                </div>
                <div className="gantt__row gantt__row--sub-header">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                  <span>10</span>
                  <span>11</span>
                  <span>12</span>
                  <span>13</span>
                  <span>14</span>
                  <span>15</span>
                  <span>16</span>
                  <span>17</span>
                  <span>18</span>
                  <span>19</span>
                  <span>20</span>
                  <span>21</span>
                  <span>22</span>
                  <span>23</span>
                  <span>24</span>
                  <span>25</span>
                  <span>26</span>
                  <span>27</span>
                  <span>28</span>
                  <span>29</span>
                  <span>30</span>
                  <span>31</span>
                </div>
                <div className="gantt-body">
                  <div className="gantt-lines">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                  </div>
                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-program"
                      style={{ gridColumn: '4/6' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-program" />
                        <p className="mb-0 px-1">National ID Integration</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-project"
                      style={{ gridColumn: '5/12' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-initiatives" />
                        <p className="mb-0 px-1">
                          MDM-Customer Upgrade (1 Change, 2 Processes & 1 Epic)
                        </p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-change"
                      style={{ gridColumn: '7/10' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-requirement-changes" />
                        <p className="mb-0 px-1">Customer Upgrade</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-capability"
                      style={{ gridColumn: '8/9' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-value-chain" />
                        <p className="mb-0 px-1">Private Customer</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-capability"
                      style={{ gridColumn: '8/9' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-value-chain" />
                        <p className="mb-0 px-1">Value Chain 656</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0">
                    <li
                      className="bar bar-capability"
                      style={{ gridColumn: '8/9' }}
                    >
                      <div className="bar-inner">
                        <span className="icon-epics" />
                        <p className="mb-0 px-1">Epics 767</p>
                        <span className="icon-more-vertical" />
                      </div>
                    </li>
                  </ul>

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />

                  <ul className="gantt__row--bars list-unstyled mb-0" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="dash-item sm-item">
          <h2>Summary Logs</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Date/Time</th>
                  <th>Object</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>02/09/2022 14:14</td>
                  <td>Project 9190</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </td>
                </tr>
                <tr>
                  <td>02/09/2022 14:14</td>
                  <td>Project 9190</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </td>
                </tr>
                <tr>
                  <td>02/09/2022 14:14</td>
                  <td>Project 9190</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </td>
                </tr>
                <tr>
                  <td>02/09/2022 14:14</td>
                  <td>Project 9190</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="more-link d-flex justify-content-end p-3">
              <button type="button" className="btn btn-link">
                Show all
              </button>
            </div>
          </div>
        </div>
        <div className="dash-item lg-item">
          <h2>Risks</h2>
          <div className="row">
            <div className="col-5">
              <GuageChart />
            </div>
            <div className="col-7">
              {/* <Heatmap2 /> */}
              <Heatmap />
            </div>
          </div>
        </div>
        <div className="w-100" />
        <div className="dash-item sm-item">
          <BarChart />
        </div>
        <div className="dash-item lg-item">
          <h2>Top 5 Open Risks</h2>
          <div className="table-responsive box-shadow">
            <table className="table">
              <thead>
                <tr className="heading-row">
                  <th>Name</th>
                  <th>Strategy</th>
                  <th>Category</th>
                  <th>Mitigation Actions</th>
                  <th>Risk Product</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="#!">Risk Title</a>
                  </td>
                  <td>Lorem Lumpsum Lorem Lumpsum</td>
                  <td>Customer Config</td>
                  <td>4</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>
                    <a href="#!">Risk Title</a>
                  </td>
                  <td>Lorem Lumpsum Lorem Lumpsum</td>
                  <td>Customer Config</td>
                  <td>4</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>
                    <a href="#!">Risk Title</a>
                  </td>
                  <td>Lorem Lumpsum Lorem Lumpsum</td>
                  <td>Customer Config</td>
                  <td>4</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>
                    <a href="#!">Risk Title</a>
                  </td>
                  <td>Lorem Lumpsum Lorem Lumpsum</td>
                  <td>Customer Config</td>
                  <td>4</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>
                    <a href="#!">Risk Title</a>
                  </td>
                  <td>Lorem Lumpsum Lorem Lumpsum</td>
                  <td>Customer Config</td>
                  <td>4</td>
                  <td>28</td>
                </tr>
              </tbody>
            </table>
            <div className="more-link d-flex justify-content-end p-3">
              <button type="button" className="btn btn-link">
                Show all
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashChart;
