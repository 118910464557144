import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  costCenter: [],
};

export const costCenterSlice = createSlice({
  name: 'costCenter',
  initialState,
  reducers: {
    addCostCenter: (state, { payload }) => {
      state.costCenter = payload;
    },
  },
});

export const { addCostCenter } = costCenterSlice.actions;

export default costCenterSlice.reducer;
