/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable import/no-relative-packages */
import React, { useEffect, useState } from 'react';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useSelector } from 'react-redux';
import Bold from '../../images/Bold.svg';
import Italic from '../../images/Italics.svg';
import Underline from '../../images/Underline.svg';
import Alignment from '../../images/Alignment.svg';
import Ordered from '../../images/ordered.svg';
import UnOrdered from '../../images/unordered.svg';
import Text from '../../images/text.svg';
import Color from '../../images/color.svg';
import InvComa from '../../images/InvComa.svg';
import Code from '../../images/code.svg';
import Indent from '../../images/Indent.svg';
import isJson from '../../utils/isJson';

function TextEditor({
  fld, errors, setErrors, t, label, handleChange, values, readOnly, twinMode,
  epicInputDisable, changeInputDisable, inputDisable,
}) {
  const [hasFocus, setHasFocus] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = values[fld.field_name] ? values[fld.field_name] : '';
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [values[fld.field_name]]);

  useEffect(() => {
    if (values[fld.field_name]) {
      setUpdated(false);
    }
  }, [values[fld.field_name]]);

  const handleEditorChange = (editorState, name) => {
    setUpdated(true);
    setEditorState(editorState);
    if (convertToRaw(editorState.getCurrentContent()).blocks[0].text.trim().length !== 0 && draftToHtml(convertToRaw(editorState.getCurrentContent()))) {
      const target = { name, value: draftToHtml(convertToRaw(editorState.getCurrentContent())) };
      handleChange({ target });
    }
    // if (!editorState.getCurrentContent().hasText()) {
    //   setErrors({
    //     ...errors,
    //     [fld.field_name]: fld.field_value_option.validation,
    //   });
    // }
  };

  return (
    <div className="col-12 col-lg-8" key={fld.field_name}>
      <div className="form-group">
        <label htmlFor={fld.field_name}>
          {label === 'addStep' ? t(`${fld.field_name}.name`) : t(t(`${label}:${fld.field_name}`).name)}
          {fld.field_value_option.validation && <sup>*</sup>}
        </label>
        { (
          <Editor
            onFocus={() => setHasFocus(true)}
            editorState={editorState}
            onEditorStateChange={(val) => handleEditorChange(val, fld.field_name)}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder={t(t(`${label}:${fld.field_name}`).placeholder)}
            toolbar={
                    {
                      options: ['inline', 'blockType', 'colorPicker', 'list', 'textAlign'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                        bold: { icon: Bold },
                        italic: { icon: Italic },
                        underline: { icon: Underline },
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered', 'indent'],
                        unordered: { icon: UnOrdered },
                        ordered: { icon: Ordered },
                        indent: { icon: Indent },
                      },
                      textAlign: {
                        icon: Alignment,
                        inDropdown: true,
                        options: ['left', 'center', 'right', 'justify'],
                      },
                      colorPicker: {
                        // inDropdown: false,
                        icon: Color,
                        // component: TextColor,
                      },
                    }

                  }
            toolbarHidden={readOnly || epicInputDisable || changeInputDisable || inputDisable || fld?.isDisabled}
            readOnly={readOnly || epicInputDisable || changeInputDisable || inputDisable || fld?.isDisabled}
          />
          )}
        {errors && errors[fld.field_name] && (
        <div className="invalid-feedback">
          <span className="icon-error-red" />
          {/* {fld.field_value_option.validation} */}
          {errors[fld.field_name]}
          {/* {t(t(`${label}:${fld.field_name}`).validation)} */}
        </div>
        )}
      </div>
    </div>

  );
}

export default TextEditor;
