/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-debugger */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ReadMoreLessDocument from '../../components/common/ReadMoreLessDoc';
import ReadOnlyCaps from '../../components/capabalities/ReadOnlyCaps';
import ProcessStepViewModal from './ProcessStepViewModal';
import {
  addProcessReadOnlyData, addProcessStepRalatedDate, addReadOnlyProcessSteps, addStepId,
} from '../../services/processSlice';
import { selectBU, selectDeprt, selectOrg } from '../../services/formSlice';
import { addProcessExtMtData, addProcessStepExtMtData } from '../../services/editFormSlice';
import {
  addProcessAppReviewCaps, addProcessReviewCaps, addProcessStepAppReviewCaps, addProcessStepCapabilities, addProcessStepReviewCaps, addStepCapabilities,
} from '../../services/applicationSlice';
import { addReviewStepDocsInfo } from '../../services/docInfoSlice';
import { addStepPeople } from '../../services/peopleSlice';
import { useGetProcessJsonQuery, useGetProcessStepJsonQuery, useStepsByProcessIdMutation } from '../../services/services';
import ReadMoreLess from '../../components/common/ReadMoreLess';
import { addBusinessStepCapabilities, addBusinessStepReviewCaps, addProcessBusinessReviewCaps } from '../../services/businessSlice';
import { addProcessVendorReviewCaps, addVendorStepCapabilities, addVendorStepReviewCaps } from '../../services/vendorSlice';
import { addCustomerStepCapabilities, addCustomerStepReviewCaps, addProcessCustomerReviewCaps } from '../../services/customerSlice';
import { addProcessProductReviewCaps, addProductStepCapabilities, addProductStepReviewCaps } from '../../services/productSlice';

let position = {};
let size = {};
const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function ProcessReadOnlyView() {
  const dispatch = useDispatch();
  const { t } = useTranslation('Process');
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const peopleData = useSelector((state) => state.peopleData.peopleVals); // all people
  const extMtData = useSelector((state) => state.editData.processExtMtData);
  const currentSteps = useSelector((state) => state.processData.readOnlyProcessSteps); // process step data
  const processReadOnlyData = useSelector((state) => state.processData.processReadOnlyData); // process step data
  const processAppReviewCaps = useSelector((state) => state.applicationData.processAppReviewCaps);
  const processReviewCaps = useSelector((state) => state.applicationData.processReviewCaps);
  const processBusinessReviewCaps = useSelector((state) => state.businessData.processBusinessReviewCaps);
  const processVendorReviewCaps = useSelector((state) => state.vendorData.processVendorReviewCaps);
  const processCustomerReviewCaps = useSelector((state) => state.customerData.processCustomerReviewCaps);
  const processProductReviewCaps = useSelector((state) => state.productData.processProductReviewCaps);

  const fieldss = processReadOnlyData?.visibleFieldsReview?.filter((it) => it.visible);
  const [expanded, setExpanded] = useState(false);
  const [capsInfo, setCapsInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const [processStepDetails, setProcessStepDetails] = useState(null);
  const formProcessJson = useGetProcessJsonQuery();
  const formStepJson = useGetProcessStepJsonQuery();
  const [getSteps, stepRes] = useStepsByProcessIdMutation();

  const relatedHeader = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'owners', name: 'Owner' },
    { type: '', field: 'managers', name: 'Manager' },
    { type: '', field: 'status', name: 'Status' },
    { type: 'date', field: 'start_date', name: 'Start Date' },
    { type: 'date', field: 'end_date', name: 'End Date' },
  ];

  const relatedHeaderProcess = [
    { type: '', field: 'name', name: 'Name' },
    { type: '', field: 'step_mappings', name: 'Steps' },
    { type: '', field: 'owners', name: 'Owner' },
    { type: '', field: 'managers', name: 'Manager' },
    { type: '', field: 'status', name: 'Status' },
  ];

  const clickToExapand = (event, capabs) => {
    event.preventDefault();
    if (capabs === 'Application' || capabs === 'application') {
      setCapsInfo({
        icon: 'icon-application',
        title: 'Application',
        key: 'application',
      });
    } else if (capabs === 'Process' || capabs === 'process') {
      setCapsInfo({
        icon: 'icon-value-chain',
        title: 'Process',
        key: 'process',
      });
    } else if (capabs === 'Business' || capabs === 'business') {
      setCapsInfo({
        icon: 'icon-business',
        title: 'Business',
        key: 'business',

      });
    } else if (capabs === 'Vendor' || capabs === 'vendor') {
      setCapsInfo({
        icon: 'icon-supplier',
        title: 'Vendor',
        key: 'vendor',

      });
    } else if (capabs === 'Customer' || capabs === 'customer') {
      setCapsInfo({
        icon: 'icon-consumer',
        title: 'Customer',
        key: 'customer',
      });
    } else if (capabs === 'Product' || capabs === 'product') {
      setCapsInfo({
        icon: 'icon-toolbar',
        title: 'Product',
        key: 'product',
      });
    }
    // Setup
    const $fsmActual = document.querySelector('#fsm_actual');
    $fsmActual.style.position = 'absolute';
    const $this = event.currentTarget.closest('.fsm');

    position = {
      left: $this.offsetLeft,
      top: $this.offsetTop,
      bottom: $this.offsetBottom,
    };
    const pos = position.top !== 0 ? position.top - 350 : 0;
    size = {
      width: window.getComputedStyle($this).width,
      height: window.getComputedStyle($this).height,
    };

    $fsmActual.style.position = 'absolute';
    $fsmActual.style.top = `${position.top}px`;
    $fsmActual.style.left = `${position.left}px`;
    $fsmActual.style.height = size.height;
    $fsmActual.style.width = size.width;
    $fsmActual.style.margin = $this.style.margin;
    setExpanded(true);

    setTimeout(() => {
      const classes = $this.classList.value.split(' ');
      for (let i = 0; i < classes.length; i++) {
        $fsmActual.classList.add(classes[i]);
      }
      $fsmActual.classList.add('card-full');
      $fsmActual.classList.add('growing');
      $fsmActual.style.height = '670px';
      $fsmActual.style.width = '100%';
      $fsmActual.style.top = `${pos}px`;
      $fsmActual.style.left = '0';
      $fsmActual.style.bottom = '670px';
      $fsmActual.style.margin = '0';
    }, 1);

    setTimeout(() => {
      $fsmActual.classList.remove('growing');
      $fsmActual.classList.add('full-screen');
    }, 600);
  };

  useEffect(() => {
    if (stepRes.isSuccess && stepRes.data && Array.isArray(stepRes.data) && stepRes.data.length > 0) {
      dispatch(addReadOnlyProcessSteps(stepRes.data));
    }
  }, [stepRes.isSuccess]);

  const handleShowProceeDetails = (stepD) => {
    console.log('stepD', stepD);
    if (stepD?.type === 'Process Step') {
      const updated = [...stepD.value.process_step_extensions];
      Object.entries(stepD?.value).forEach((tv) => updated.forEach((dt, i) => {
        if (dt.field_name === 'step_documents' && Array.isArray(stepD?.value?.step_documents)) {
          updated.splice(i, 1, { ...dt, value: stepD?.value?.step_documents });
        }
        if (dt.field_name === 'unique_id') {
          updated.splice(i, 1, { ...dt, value: stepD?.value?.unique_id ? stepD?.value?.unique_id : '' });
        }
        if (dt.field_name === tv[0]) {
          if (dt.field_name === 'business_units' || dt.field_name === 'organizations') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else if (dt.field_name === 'departments') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else {
            updated.splice(i, 1, { ...dt, value: tv[1] });
          }
        }
      }));
      dispatch(addStepId(stepD?.value?.id));
      dispatch(selectBU(stepD?.value?.business_units));
      dispatch(selectOrg(stepD?.value?.organizations));
      dispatch(selectDeprt(stepD?.value?.departments));
      dispatch(addProcessStepExtMtData({ fields: (updated && updated.length > 1 && Array.isArray(updated)) ? updated : [], sections: (formStepJson?.isSuccess && formStepJson?.data) ? formStepJson.data.sections : [] }));
      if (stepD?.value?.capability_mappings && Array.isArray(stepD?.value?.capability_mappings) && stepD?.value?.capability_mappings.length) {
        dispatch(addStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addBusinessStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProductStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
        dispatch(addVendorStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addCustomerStepCapabilities(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
        dispatch(addProcessStepAppReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessStepReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addBusinessStepReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProductStepReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
        dispatch(addVendorStepReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addCustomerStepReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
      }
      dispatch(addReviewStepDocsInfo(stepD?.value?.step_documents || []));
      dispatch(addStepPeople({
        ...peopleData,
        owner: stepD?.value?.owners || [],
        manager: stepD?.value?.managers || [],
        teams: stepD?.value?.teams || [],
      }));
      dispatch(addProcessStepRalatedDate({
        created: stepD?.value?.created || '',
        created_by: stepD?.value?.created_by?.first_name ? `${stepD?.value?.created_by?.first_name} ${stepD?.value?.created_by?.last_name}` : '',
        updated: stepD?.value?.updated || '',
        updated_by: stepD?.value?.updated_by?.first_name ? `${stepD?.value?.updated_by?.first_name} ${stepD?.value?.updated_by?.last_name}` : '',
      }));
    } else if (stepD?.type === 'Process') {
      getSteps({ id: stepD?.value?.id });
      const updated = [...stepD.value.process_extensions];
      Object.entries(stepD?.value).forEach((tv) => updated.forEach((dt, i) => {
        if (dt.field_name === 'documents' && Array.isArray(stepD?.value?.step_documents)) {
          updated.splice(i, 1, { ...dt, value: stepD?.value?.step_documents });
        }
        if (dt.field_name === tv[0]) {
          if (dt.field_name === 'business_units' || dt.field_name === 'organizations') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else if (dt.field_name === 'departments') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1][0]?.name, value: tv[1][0]?.id } });
          } else if (dt.field_name === 'domain' || dt.field_name === 'status') {
            updated.splice(i, 1, { ...dt, value: { label: tv[1], value: tv[1] } });
          } else {
            updated.splice(i, 1, { ...dt, value: tv[1] });
          }
        }
      }));
      dispatch(addProcessReadOnlyData({
        people: {
          manager: stepD?.value?.managers,
          owner: stepD?.value?.owners,
          teams: stepD?.value?.teams,
          subjMtExp: stepD?.value?.subject_matter_experts,
        },
        related_programs: stepD?.value?.related_programs || [],
        related_initiatives: stepD?.value?.related_initiatives || [],
        related_changes: stepD?.value?.related_changes || [],
        dependent_processes: stepD?.value?.dependent_processes || [],
        visibleFieldsReview: [
          { name: 'processSteps', visible: true },
          { name: 'capabilities', visible: true },
          { name: 'people', visible: true },
        ],
        relatedDate: {
          created: stepD?.value?.created || '',
          created_by: stepD?.value?.created_by?.first_name ? `${stepD?.value?.created_by?.first_name || ''} ${stepD?.value?.created_by?.last_name || ''}` : '',
          updated: stepD?.value?.updated || '',
          updated_by: stepD?.value?.updated_by?.first_name ? `${stepD?.value?.updated_by?.first_name || ''} ${stepD?.value?.updated_by?.last_name || ''}` : '',
          version: stepD?.value?.version || 0,
        },
      }));
      dispatch(addProcessExtMtData({ fields: (updated && updated.length > 1 && Array.isArray(updated)) ? updated : [], sections: (formProcessJson?.isSuccess && formProcessJson?.data) ? formProcessJson.data.sections : [] }));
      if ((stepD?.value?.capability_mappings && stepD?.value?.capability_mappings?.length > 0 && Array.isArray(stepD?.value?.capability_mappings))) {
        dispatch(addProcessAppReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addProcessBusinessReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProcessVendorReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addProcessCustomerReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
        dispatch(addProcessProductReviewCaps(stepD?.value?.capability_mappings?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
      }
    }
    setProcessStepDetails(stepD);
    setModal(true);
  };

  return (
    <>
      <form action="" className="add-program-form">
        <div className="form-unit">
          <div className="container-fluid">
            <h2 className="h1">
              1.
              {' '}
              Details
            </h2>
            {(extMtData?.fields && extMtData?.fields?.length > 0 && Array.isArray(extMtData?.fields))
            && extMtData.sections.map((tl, i) => (
              <Fragment key={tl?.id}>
                <h2 className="section-title">{tl.display_name}</h2>
                <div className="row">
                  {extMtData.fields.map(
                    (fl) => tl?.id === fl?.section_title.id && (
                      <Fragment key={fl.id}>
                        {extMtData?.fields?.some((el) => el?.id === fl?.id && el?.field_name === 'organizations') && <div className="w-100" />}
                        <div key={fl.id} className={`col-6  ${fl?.field_data_type === 'texteditor' ? 'col-lg-8' : 'col-lg-4'}`}>
                          <div className="form-group">
                            <label htmlFor="process">{t(t(`Process:${fl?.field_name}`).name)}</label>
                            <p className="readonly-text font-weight-bold">
                              {((fl.field_data_type === 'text' || (fl.field_data_type === 'readonly' || fl.field_data_type === 'readOnly')) && fl.value) ? fl.value
                                : (fl.field_data_type === 'date' && fl?.value) ? dayjs(fl.value).format(userData?.tenant_info?.date_format)
                                  : ((fl.field_data_type === 'select' && fl.field_type === 'System') && fl.value?.label) ? fl.value?.label
                                    : (fl.field_data_type === 'select' && fl.field_type === 'Custom') ? fl?.value
                                      : (fl.field_data_type === 'file'
                                    && fl.value !== null && Array.isArray(fl.value)) ? (
                                      <ReadMoreLessDocument field={fl} doc={fl?.value} />
                                        )
                                        : fl.field_data_type === 'texteditor'
                                          ? (
                                            <Editor
                                              editorState={fl.value ? EditorState.createWithContent(
                                                ContentState.createFromBlockArray(
                                                  htmlToDraft(fl.value),
                                                ),
                                              ) : EditorState.createEmpty()}
                                              wrapperClassName="wrapper-class"
                                              editorClassName="editor-class"
                                              toolbarClassName="toolbar-class"
                                              toolbarHidden
                                            />
                                          )
                                          : (fl.field_data_type === 'textarea' && fl?.value) ? <ReadMoreLess text={fl?.value} />
                                            : '-'}
                            </p>
                          </div>
                        </div>
                      </Fragment>
                    ),
                  )}
                </div>
                <hr />
              </Fragment>
            ))}

            <h2>Related Objects</h2>
            <h3>Programs</h3>
            <div className="table-responsive box-shadow mb-4">
              <table className="table table-hover">
                <thead>
                  <tr className="heading-row">
                    {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader)) && relatedHeader?.map((ele) => (
                      <th key={ele?.name}>{ele.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(processReadOnlyData?.related_programs && processReadOnlyData?.related_programs?.length > 0 && Array.isArray(processReadOnlyData?.related_programs))
                    ? processReadOnlyData?.related_programs?.map((td) => (
                      <tr key={td?.id}>
                        {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader))
                        && relatedHeader?.map((th) => (
                          (th.field === 'owner' || th.field === 'owners')
                            ? (
                              <td className="text-capitalize" key={th?.field}>
                                {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                  `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                )) : '-'}
                              </td>
                            )
                            : (th.field === 'manager' || th.field === 'managers')
                              ? (
                                <td className="text-capitalize" key={th?.field}>
                                  {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                    `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                  )) : '-'}
                                </td>
                              )
                              : th.type === 'date'
                                ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                        ))}
                      </tr>
                    ))
                    : <tr><td colSpan={6} className="text-center">No Data To Display</td></tr>}
                </tbody>
              </table>
            </div>
            <h3>Projects</h3>
            <div className="table-responsive box-shadow mb-4">
              <table className="table table-hover">
                <thead>
                  <tr className="heading-row">
                    {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader)) && relatedHeader?.map((ele) => (
                      <th key={ele?.name}>{ele.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(processReadOnlyData?.related_initiatives && processReadOnlyData?.related_initiatives?.length > 0 && Array.isArray(processReadOnlyData?.related_initiatives))
                    ? processReadOnlyData?.related_initiatives?.map((td) => (
                      <tr key={td?.id}>
                        {(relatedHeader && relatedHeader?.length > 0 && Array.isArray(relatedHeader))
                        && relatedHeader?.map((th) => (
                          (th.field === 'owner' || th.field === 'owners')
                            ? (
                              <td className="text-capitalize" key={th?.field}>
                                {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                  `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                )) : '-'}
                              </td>
                            )
                            : (th.field === 'manager' || th.field === 'managers')
                              ? (
                                <td className="text-capitalize" key={th?.field}>
                                  {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                    `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                  )) : '-'}
                                </td>
                              )
                              : th.type === 'date'
                                ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                        ))}
                      </tr>
                    ))
                    : <tr><td colSpan={6} className="text-center">No Data To Display</td></tr>}
                </tbody>
              </table>
            </div>
            <h3>Processes</h3>
            <div className="table-responsive box-shadow mb-4">
              <table className="table table-hover">
                <thead>
                  <tr className="heading-row">
                    {(relatedHeaderProcess && relatedHeaderProcess?.length > 0 && Array.isArray(relatedHeaderProcess)) && relatedHeaderProcess?.map((ele) => (
                      <th key={ele?.name}>{ele.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(processReadOnlyData?.dependent_processes && processReadOnlyData?.dependent_processes?.length > 0 && Array.isArray(processReadOnlyData?.dependent_processes))
                    ? processReadOnlyData?.dependent_processes?.map((td) => (
                      <tr key={td?.id}>
                        {(relatedHeaderProcess && relatedHeaderProcess?.length > 0 && Array.isArray(relatedHeaderProcess))
                        && relatedHeaderProcess?.map((th) => (
                          (th.field === 'owner' || th.field === 'owners')
                            ? (
                              <td className="text-capitalize" key={th?.field}>
                                {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                  `${ow?.first_name || ''}  ${ow?.last_name || ''}`
                                )) : '-'}
                              </td>
                            )
                            : (th.field === 'manager' || th.field === 'managers')
                              ? (
                                <td className="text-capitalize" key={th?.field}>
                                  {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                    `${mn?.first_name || ''}  ${mn?.last_name || ''}`
                                  )) : '-'}
                                </td>
                              )
                              : (th.field === 'step_mappings')
                                ? (
                                  <td key={th?.field}>
                                    {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.length : '-'}
                                  </td>
                                )
                                : th.type === 'date'
                                  ? td[th.field] && <td key={th?.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                  : td[th.field] && <td key={th?.field}>{td[th.field] || '-'}</td>
                        ))}
                      </tr>
                    ))
                    : <tr><td colSpan={6} className="text-center">No Data To Display</td></tr>}
                </tbody>
              </table>
            </div>

            {(fieldss && fieldss?.length > 0 && Array.isArray(fieldss))
            && fieldss.map((item, index) => {
              if (item.name === 'processSteps') {
                return (
                  <Fragment key={item?.name}>
                    <h2 className="h1">
                      {2 + index}
                      .
                      {' '}
                      Process Steps
                    </h2>
                    <div className="canvas-wrapper mb-4">
                      <div id="review-pdf" className="flow-wrapper">
                        <div className="flow-chart">
                          <div className="step-endpoints start-point">
                            <span>start</span>
                          </div>
                          {
                            (currentSteps && currentSteps.length > 0) && currentSteps.map(((cs, i) => (
                              <div
                                key={cs?.type}
                                className={`flow-item ${cs?.type === 'Process' ? 'sub-process' : ''}`}
                                onClick={() => handleShowProceeDetails(cs)}
                              >
                                <div className="flow-header">
                                  {cs?.type === 'Process Step'
                                    && (
                                      <p className="mb-0">
                                        {cs?.value?.name}
                                      </p>
                                    )}
                                </div>
                                {cs?.type === 'Process'
                                  ? (
                                    <div className="flow-body d-flex align-items-center justify-content-between">
                                      <div className="process-icon">
                                        <span className="icon-value-chain" />
                                      </div>
                                      <h4 className="mb-0">{cs?.value?.name}</h4>
                                      <span className="process-count ml-auto">{Array.isArray(cs?.value?.step_mappings) && `${cs?.value?.step_mappings.length} Steps`}</span>
                                    </div>
                                  ) : (
                                    <div className="flow-body d-flex align-items-center justify-content-between">
                                      <ul className="list-unstyled mb-0 cap-icons d-flex align-items-center">
                                        {cs?.value?.mode !== 'current' && (
                                          <li>
                                            {(cs?.value?.capability_mappings
                                              && Array.isArray(cs?.value?.capability_mappings)
                                              && cs?.value?.capability_mappings?.length > 0)
                                              && (
                                                cs?.value?.capability_mappings.map((cm) => (
                                                  <Fragment key={cm?.key}>
                                                    <span className={cm.key === 'business' ? 'icon-business'
                                                      : cm.key === 'application' ? 'icon-application'
                                                        : cm.key === 'vendor' ? 'icon-supplier'
                                                          : cm.key === 'customer' ? 'icon-consumer'
                                                            : cm.key === 'product' ? 'icon-toolbar'
                                                              : 'icon-value-chain'}
                                                    />
                                                    {cm?.value?.length > 1 ? <i className="cap-count">{`+${cm.value.length - 1}`}</i> : ''}
                                                  </Fragment>
                                                ))
                                              )}
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            )
                            ))
                          }
                          <div className="step-endpoints finish-point">
                            <span>end</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </Fragment>
                );
              }
              if (item.name === 'people') {
                return (
                  <>
                    <h2 className="h1">
                      {2 + index}
                      .
                      {' '}
                      People
                    </h2>
                    <div className="row">
                      <div className="col-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="program_subj">Owner</label>
                          {(processReadOnlyData?.people?.owner && processReadOnlyData?.people?.owner?.length > 0 && Array.isArray(processReadOnlyData?.people?.owner)) ? (
                            <p className="readonly-text font-weight-bold text-capitalize">
                              {processReadOnlyData?.people?.owner?.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                              ))}
                            </p>
                          ) : '-'}
                        </div>
                      </div>
                      <div className="col-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="program_subj">Manager</label>
                          {(processReadOnlyData?.people?.manager && processReadOnlyData?.people?.manager?.length > 0 && Array.isArray(processReadOnlyData?.people?.manager)) ? (
                            <p className="readonly-text font-weight-bold text-capitalize">
                              {processReadOnlyData?.people?.manager?.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                              ))}
                            </p>
                          ) : '-'}
                        </div>
                      </div>
                      <div className="col-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="program_subj">Subject Matter Expert</label>
                          {(processReadOnlyData?.people?.subjMtExp && processReadOnlyData?.people?.subjMtExp?.length > 0 && Array.isArray(processReadOnlyData?.people?.subjMtExp)) ? (
                            <p className="readonly-text font-weight-bold text-capitalize">
                              {processReadOnlyData?.people?.subjMtExp?.map(((ow) => <span key={ow?.id}>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                              ))}
                            </p>
                          ) : '-'}
                        </div>
                      </div>
                      <div className="col-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="program_subj">Team Member</label>
                          {(processReadOnlyData?.people?.teams && processReadOnlyData?.people?.teams?.length > 0 && Array.isArray(processReadOnlyData?.people?.teams)) ? (
                            <p className="readonly-text font-weight-bold text-capitalize">
                              {' '}
                              {processReadOnlyData?.people?.teams.map((ow, i) => (
                                <Fragment key={ow?.id}>
                                  <span>{`${ow?.first_name || ''} ${ow?.last_name || ''}`}</span>
                                  {i === processReadOnlyData.people.teams.length - 1
                                    ? ''
                                    : ', '}
                                </Fragment>
                              ))}
                            </p>
                          ) : '-'}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              } if (item.name === 'capabilities') {
                return (
                  (processAppReviewCaps
                    || processReviewCaps
                    || processBusinessReviewCaps
                    || processVendorReviewCaps
                    || processCustomerReviewCaps
                    || processProductReviewCaps
                  ) && (
                    <>
                      <h2 className="h1">
                        {index + 2}
                        {'. '}
                        Capabilities
                      </h2>
                      <div id="expand-demo">
                        <div id="wrpp" className="capability-cards process-cards review-cards">
                          <div id="fsm_container" className="fsm-container">
                            <div className="fsm">
                              {(processReviewCaps && Array.isArray(processReviewCaps) && processReviewCaps.length > 0)
                                ? (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-value-chain" />
                                      Process
                                      {' '}
                                      {`(${processReviewCaps?.length})`}
                                    </h3>
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>Process Name</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {processReviewCaps?.slice(0, 5)?.map((ele) => (
                                            <tr key={ele?.name}>
                                              <td className="sm-col">
                                                <span className="icon-value-chain" />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                  <Button
                                                    className="text-decoration-none"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/process/${ele?.id}`}
                                                  >
                                                    {ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{dayjs(ele.created).format(userData?.tenant_info?.date_format) || '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {processReviewCaps?.length > 5
                                        && (
                                          <div className="more-link d-flex justify-content-end">
                                            <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Process')}>more</button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                )
                                : (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-value-chain" />
                                      Process
                                    </h3>
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="fsm">
                              {(processAppReviewCaps && Array.isArray(processAppReviewCaps) && processAppReviewCaps.length > 0)
                                ? (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-application" />
                                      Application
                                      {' '}
                                      {`(${processAppReviewCaps?.length})`}
                                    </h3>
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>Application Name</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {processAppReviewCaps?.slice(0, 5)?.map((ele) => (
                                            <tr key={ele?.name}>
                                              <td className="sm-col">
                                                <span className="icon-application" />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                  <Button
                                                    className="text-decoration-none"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/application/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                                  >
                                                    {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{ele?.created || '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {processAppReviewCaps?.length > 5
                                        && (
                                          <div className="more-link d-flex justify-content-end">
                                            <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Application')}>more</button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-application" />
                                      Application
                                    </h3>
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="fsm">
                              {(processBusinessReviewCaps && Array.isArray(processBusinessReviewCaps) && processBusinessReviewCaps.length > 0)
                                ? (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-business" />
                                      Business
                                      {' '}
                                      {`(${processBusinessReviewCaps?.length})`}
                                    </h3>
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>Business Name</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {processBusinessReviewCaps?.slice(0, 5)?.map((ele) => (
                                            <tr key={ele?.name}>
                                              <td className="sm-col">
                                                <span className="icon-business" />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                  <Button
                                                    className="text-decoration-none"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/business/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                                  >
                                                    {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{ele?.created || '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {processBusinessReviewCaps?.length > 5
                                        && (
                                          <div className="more-link d-flex justify-content-end">
                                            <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Business')}>more</button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-business" />
                                      Business
                                    </h3>
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="fsm">
                              {(processVendorReviewCaps && Array.isArray(processVendorReviewCaps) && processVendorReviewCaps.length > 0)
                                ? (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-supplier" />
                                      Vendor
                                      {' '}
                                      {`(${processVendorReviewCaps?.length})`}
                                    </h3>
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>Vendor Name</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {processVendorReviewCaps?.slice(0, 5)?.map((ele) => (
                                            <tr key={ele?.name}>
                                              <td className="sm-col">
                                                <span className="icon-supplier" />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                  <Button
                                                    className="text-decoration-none"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/vendor/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                                  >
                                                    {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{ele?.created || '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {processVendorReviewCaps?.length > 5
                                        && (
                                          <div className="more-link d-flex justify-content-end">
                                            <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Vendor')}>more</button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-supplier" />
                                      Vendor
                                    </h3>
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="fsm">
                              {(processCustomerReviewCaps && Array.isArray(processCustomerReviewCaps) && processCustomerReviewCaps.length > 0)
                                ? (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-consumer" />
                                      Customer
                                      {' '}
                                      {`(${processCustomerReviewCaps?.length})`}
                                    </h3>
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>Customer Name</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {processCustomerReviewCaps?.slice(0, 5)?.map((ele) => (
                                            <tr key={ele?.name}>
                                              <td className="sm-col">
                                                <span className="icon-consumer" />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                  <Button
                                                    className="text-decoration-none"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/customer/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                                  >
                                                    {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{ele?.created || '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {processCustomerReviewCaps?.length > 5
                                        && (
                                          <div className="more-link d-flex justify-content-end">
                                            <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Customer')}>more</button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-consumer" />
                                      Customer
                                    </h3>
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="fsm">
                              {(processProductReviewCaps && Array.isArray(processProductReviewCaps) && processProductReviewCaps.length > 0)
                                ? (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-toolbar" />
                                      Product
                                      {' '}
                                      {`(${processProductReviewCaps?.length})`}
                                    </h3>
                                    <div className="card-body">
                                      <table className="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th className="sm-col">&nbsp;</th>
                                            <th>Product Name</th>
                                            <th>Delivery Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {processProductReviewCaps?.slice(0, 5)?.map((ele) => (
                                            <tr key={ele?.name}>
                                              <td className="sm-col">
                                                <span className="icon-toolbar" />
                                              </td>
                                              <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip(ele?.name)}>
                                                  <Button
                                                    className="text-decoration-none"
                                                    variant="link"
                                                    target="_blank"
                                                    href={`/review/product/${ele?.id || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.id)}`}
                                                  >
                                                    {(ele?.name?.length > 10 ? `${ele?.name?.substring(0, 10)}...` : ele?.name) || ((ele?.value && Array.isArray(ele?.value) && ele?.value?.length > 0) && ele?.value[0]?.name)}
                                                  </Button>
                                                </OverlayTrigger>
                                              </td>
                                              <td>{ele?.created || '-'}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                      {processProductReviewCaps?.length > 5
                                        && (
                                          <div className="more-link d-flex justify-content-end">
                                            <button type="button" className="btn btn-link text-decoration-none" onClick={(e) => clickToExapand(e, 'Product')}>more</button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card">
                                    <h3 className="card-header">
                                      <span className="icon-toolbar" />
                                      Product
                                    </h3>
                                    <div className="card-body empty">
                                      <h1>No Data To Display</h1>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div id="fsm_actual">
                              {expanded
                                && (
                                  <ReadOnlyCaps
                                    appCapabilitiesData={processAppReviewCaps}
                                    processCapabilitiesData={processReviewCaps}
                                    businessCapabilitiesData={processBusinessReviewCaps}
                                    vendorCapabilitiesData={processVendorReviewCaps}
                                    customerCapabilitiesData={processCustomerReviewCaps}
                                    productCapabilitiesData={processProductReviewCaps}
                                    setExpanded={setExpanded}
                                    capsInfo={capsInfo}
                                    size={size}
                                    position={position}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  )
                );
              }
            })}

            <div className="grey-bg">
              <h2>Related Dates and People</h2>
              <div className="row">
                <div className="col-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="program_">Created</label>
                    <p className="readonly-text font-weight-bold">
                      {processReadOnlyData?.relatedDate?.created ? dayjs(processReadOnlyData?.relatedDate?.created).format(userData?.tenant_info?.date_format) : '-'}
                    </p>
                  </div>
                </div>
                <div className="col-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="program_">Updated</label>
                    <p className="readonly-text font-weight-bold">
                      {processReadOnlyData?.relatedDate?.updated ? dayjs(processReadOnlyData?.relatedDate?.updated).format(userData?.tenant_info?.date_format) : '-'}
                    </p>
                  </div>
                </div>
                <div className="w-100" />
                <div className="col-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="program_">Created By</label>
                    <p className="readonly-text font-weight-bold text-capitalize">
                      {processReadOnlyData?.relatedDate?.created_by ? processReadOnlyData?.relatedDate?.created_by || '' : '-'}
                    </p>
                  </div>
                </div>
                <div className="col-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="program_">Updated By</label>
                    <p className="readonly-text font-weight-bold text-capitalize">
                      {processReadOnlyData?.relatedDate?.updated_by ? processReadOnlyData?.relatedDate?.updated_by || '' : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ProcessStepViewModal
        show={modal}
        onHide={setModal}
        details={processStepDetails}
      />
    </>
  );
}

export default ProcessReadOnlyView;
