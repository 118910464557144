import { eventWrapper } from '@testing-library/user-event/dist/utils';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AccessDenied() {
  const navigate = useNavigate();

  return (
    <div className="form-wrapper">
      <center style={{ background: '#fff', paddingTop: '10px', paddingBottom: '10px' }}>
        <h1><strong> Access Denied !</strong></h1>
        <Button variant="primary" onClick={() => navigate('/dashboard')} style={{ marginTop: '15px' }}>Go Back</Button>
        {' '}
        <br />
        {' '}
        <h4>Please contact Administrator</h4>
      </center>
    </div>
  );
}
