import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  peopleVals: {
    owner: [],
    assignee: [],
    resp_person: [],
    subjMtExp: [],
    manager: [],
    sponsor: [],
    stCom: [],
    teamMember: [],
    teams: [],
    collaborator: [],
    reqCollaborator: [],
    risk: [],
    riskBenefitOwner: [],
  },
  stepPeople: {
    owner: [],
    manager: [],
    teams: [],
  },
  delegationPeople: {
    owner: [],
    assignee: [],
    resp_person: [],
    subjMtExp: [],
    manager: [],
    sponsor: [],
    stCom: [],
    teamMember: [],
    teams: [],
    collaborator: [],
    risk: [],
    riskBenefitOwner: [],
  },
  peopleDisabled: false,
  peopleProcessStepDisabled: false,
  epicTaskPeople: {
    assignee: [],
  },
  changeCollaborator: [
    {
      key: 'process',
      value: [],
    },
    {
      key: 'application',
      value: [],
    },
    {
      key: 'business',
      value: [],
    },
    {
      key: 'vendor',
      value: [],
    },
    {
      key: 'customer',
      value: [],
    },
    {
      key: 'product',
      value: [],
    },
  ],
};

export const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    addPeople: (state, { payload }) => {
      state.peopleVals = payload;
    },
    addDelegationPeople: (state, { payload }) => {
      state.delegationPeople = payload;
    },
    addStepPeople: (state, { payload }) => {
      state.stepPeople = payload;
    },
    addEpicTaskPeople: (state, { payload }) => {
      state.epicTaskPeople = payload;
    },
    addOwner: (state, { payload }) => {
      state.peopleVals.owner = payload;
    },
    addManager: (state, { payload }) => {
      state.peopleVals.manager = payload;
    },
    addSponsor: (state, { payload }) => {
      state.peopleVals.sponsor = payload;
    },
    addTeams: (state, { payload }) => {
      state.peopleVals.teamMember = payload;
    },
    addRiskPeople: (state, { payload }) => {
      state.peopleVals.risk = payload;
    },
    addRiskBenefitOwner: (state, { payload }) => {
      state.peopleVals.riskBenefitOwner = payload;
    },
    setPeopleDisabled: (state, { payload }) => {
      state.peopleDisabled = payload;
    },
    setPeopleProcessStepDisabled: (state, { payload }) => {
      state.peopleProcessStepDisabled = payload;
    },
    addChangeCollaborator: (state, { payload }) => {
      state.changeCollaborator = payload;
    },
  },
});

export const {
  addPeople, addOwner, addManager, addSponsor, addTeams, addRiskPeople, addRiskBenefitOwner,
  addDelegationPeople,
  addStepPeople,
  setPeopleProcessStepDisabled,
  setPeopleDisabled,
  addEpicTaskPeople,
  addChangeCollaborator,
} = peopleSlice.actions;

export default peopleSlice.reducer;
