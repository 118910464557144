/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jsonData: [],
  sectionData: [],
  pathData: [],
  selectedInit: {},
  rcValues: {},
  peopleVals: {
    owner: [], manager: [], member: [],
  },
  costCenter: [],
  valueChains: [],
  docsInfo: [],
  allAssets: [],
  allRc: [],
  peopleCapab: [],
  teamsCapab: [],
  findTwinsRes: [],
  selectedChange: {},
  appCapsLevel: 'all',
  firstLevelData: [],
  relatedProjectDate: { startDate: null, endDate: null },
  changeInputDisable: false,
  changeEnhancement: [],
};

export const reqChangeSlice = createSlice({
  name: 'reqChangeSlice',
  initialState,
  reducers: {
    addPaths: (state, { payload }) => {
      if (!state.pathData.includes(payload) && !Array.isArray(payload)) {
        state.pathData = [...state.pathData, payload];
      }
      if (Array.isArray(payload)) {
        state.pathData = payload;
      }
    },
    clearPaths: (state) => {
      state.pathData = [];
    },
    addRcVals: (state, { payload }) => {
      state.rcValues = payload;
    },
    addValChains: (state, { payload }) => {
      state.valueChains = payload;
    },
    addPeople: (state, { payload }) => {
      state.peopleVals = payload;
    },
    addAssets: (state, { payload }) => {
      state.allAssets = payload;
    },
    AddSelInit: (state, { payload }) => {
      state.selectedInit = payload;
    },
    addJsonData: (state, { payload }) => {
      state.jsonData = payload;
    },
    addSectionData: (state, { payload }) => {
      state.sectionData = payload;
    },
    addAllRC: (state, { payload }) => {
      state.allRc = payload;
    },
    addpeopleCapab: (state, { payload }) => {
      state.peopleCapab = payload;
    },
    addfindTwins: (state, { payload }) => {
      state.findTwinsRes = payload;
    },
    addSelectedChange: (state, { payload }) => {
      state.selectedChange = payload;
    },
    addTeamsCapab: (state, { payload }) => {
      const uniqueTeams = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.teamsCapab = uniqueTeams;
    },
    setAppCapsLevel: (state, { payload }) => {
      state.appCapsLevel = payload;
    },
    setFirstLevelData: (state, { payload }) => {
      const uniqueFirstLevelData = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id && obj?.type === o?.type)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.firstLevelData = uniqueFirstLevelData;
    },
    setRelatedProjectDate: (state, { payload }) => {
      state.relatedProjectDate = payload;
    },
    setChangeInputDisabled: (state, { payload }) => {
      state.changeInputDisable = payload;
    },
    addChangEnhancement: (state, { payload }) => {
      state.changeEnhancement = payload;
    },
  },
});

export const {
  addPaths, addRcVals, addValChains, addPeople, addAssets, AddSelInit,
  addJsonData, addSectionData, clearPaths, addAllRC, addpeopleCapab, addTeamsCapab, addfindTwins, addSelectedChange,
  setAppCapsLevel, setFirstLevelData, setRelatedProjectDate, setChangeInputDisabled,
  addChangEnhancement,
} = reqChangeSlice.actions;

export default reqChangeSlice.reducer;
