/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

export default function CancelModal({
  show, onHide, cancelTitle, func,
}) {
  const location = useLocation();

  return (
    <Modal
      show={show}
      onHide={onHide}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`Abort ${cancelTitle} process?`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger" role="alert">
          <span className="icon-error-red" />
          You have unsaved changes...
        </div>
        <p>
          Click
          {' '}
          <strong>‘Cancel’</strong>
          {' '}
          If you want to continue editing
        </p>
        <p>
          Click
          {' '}
          <strong>‘Confirm’</strong>
          {' '}
          {`If you want to abort the ${cancelTitle} process, nothing will be saved.`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-dark" onClick={onHide}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={(e) => func(e)}>Confirm</button>
      </Modal.Footer>
    </Modal>
  );
}
