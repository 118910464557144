/* eslint-disable max-len */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-nocheck

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addRelatedChangeData, addRelatedInitData, addRelatedProgData } from '../../services/formSlice';
import TransformationModal from './TransformationModal';

function Transformation({ label, selecteArr }):JSX.Element {
  console.log('selecteArr', selecteArr);
  console.log('label', label);

  const { t } = useTranslation('transformation_object');
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const relatedProgData = useSelector((state) => state.formJson.relatedProgData);
  const relatedInitData = useSelector((state) => state.formJson.relatedInitData);
  const relatedChangeData = useSelector((state) => state.formJson.relatedChangeData);
  const changeInputDisable = useSelector((state) => state.rcData.changeInputDisable);
  const inputDisable = useSelector((state) => state.formJson.inputDisable);
  const programValues = useSelector((state) => state.formJson.programValues);
  const projectValues = useSelector((state) => state.initData.initValues);
  const rcValues = useSelector((state) => state.rcData.rcValues);

  const handleClose = () => {
    setModal({
      ...modal,
      key: '',
      title: '',
      show: false,
    });
  };

  const handleShow = (item) => {
    if (item.title === 'initiative') {
      setModal({
        ...item,
        title: 'Project',
      });
    } else if (item.title === 'change' && label === 'change') {
      setModal({
        ...item,
        title: 'Change Predecessor',
      });
    } else {
      setModal(item);
    }
  };

  // const [transformationObj, setTransformationObj] = useState([]);

  // const isProgram = (relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData)),
  // const isProject = (relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData)),
  // const isChange = (relatedChangeData && relatedChangeData?.length > 0 && Array.isArray(relatedChangeData)),

  // useEffect(() => {
  //   if ((relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData))
  //   || (relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData))
  //   || (relatedChangeData && relatedChangeData?.length > 0 && Array.isArray(relatedChangeData))) {
  //     setTransformationObj([
  //       {
  //         key: 'program',
  //         data: relatedProgData,
  //       },
  //       {
  //         key: 'initiatives',
  //         data: relatedInitData,
  //       },
  //       {
  //         key: 'change',
  //         data: relatedChangeData,
  //       },
  //     ]);
  //   } else {
  //     setTransformationObj([]);
  //   }
  // }, [modal, (relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData)),
  //   (relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData)), (relatedChangeData && relatedChangeData?.length > 0 && Array.isArray(relatedChangeData))]);

  const removeHandler = (e, lbl, name) => {
    e.stopPropagation();
    if (lbl === 'program') { dispatch(addRelatedProgData(relatedProgData.filter((value) => value.id !== name.id))); }
    if (lbl === 'initiative') { dispatch(addRelatedInitData(relatedInitData.filter((value) => value.id !== name.id))); }
    if (lbl === 'change') { dispatch(addRelatedChangeData(relatedChangeData.filter((value) => value.id !== name.id))); }
  };

  return (
    <>
      <h2>{t(`section_title_${label}`)}</h2>
      <div className="row add-people">
        {(selecteArr && selecteArr?.length > 0 && Array.isArray(selecteArr))
        && selecteArr?.map((item) => (
          <Fragment key={item}>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="program_subj">
                  {(item === 'change' && label === 'change') ? t('dependent_change') : t(item)}
                </label>
                <div
                  className="form-control"
                  type="text"
                  name="subject_name"
                  id="program_subj"
                >
                  <div className="md-chips">
                    {(relatedProgData && relatedProgData?.length > 0 && Array.isArray(relatedProgData))
                    && item === 'program'
                    && relatedProgData?.map((dt) => (
                      <div key={dt.id} className="md-chip">
                        <span>{`${dt.name}`}</span>
                        {/* {(label === 'risk' || label === 'benefit') ? '' : !(rcValues?.status === 'Initiation') */}
                        {!(rcValues?.status === 'Initiation')
                        && (
                        <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, item, dt)}>
                          <span className="icon-close" />
                        </button>
                        )}
                      </div>
                    ))}

                    {(relatedInitData && relatedInitData?.length > 0 && Array.isArray(relatedInitData))
                    && item === 'initiative'
                    && relatedInitData?.map((dt) => (
                      <div key={dt.id} className="md-chip">
                        <span>{`${dt.name}`}</span>
                        {/* {(label === 'risk' || label === 'benefit') ? '' : !(rcValues?.status === 'Initiation') */}
                        {!(rcValues?.status === 'Initiation')
                        && (
                        <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, item, dt)}>
                          <span className="icon-close" />
                        </button>
                        )}
                      </div>
                    ))}

                    {(relatedChangeData && relatedChangeData?.length > 0 && Array.isArray(relatedChangeData))
                    && item === 'change'
                    && relatedChangeData?.map((dt) => (
                      <div key={dt.id} className="md-chip">
                        <span>{`${dt.name}`}</span>
                        {/* {(label === 'risk' || label === 'benefit') ? '' : !(rcValues?.status === 'Initiation') */}
                        {!(rcValues?.status === 'Initiation')
                        && (
                        <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, item, dt)}>
                          <span className="icon-close" />
                        </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="input-group-append">
                    {
                    // (label === 'risk' || label === 'benefit') ? '' : !(changeInputDisable || inputDisable || rcValues?.status === 'Initiation')
                    !(changeInputDisable || inputDisable || rcValues?.status === 'Initiation')
                    && (
                    <button
                      type="button"
                      className="input-group-text btn btn-link btn-small"
                      onClick={() => handleShow({ key: item, title: item, show: true })}
                    >
                      select
                    </button>
                    )
}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100" />
          </Fragment>
        ))}
      </div>
      <TransformationModal
        modal={modal}
        handleClose={handleClose}
        label={label}
      />
    </>
  );
}

export default Transformation;
