/* eslint-disable no-debugger */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';

import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';

const useBackListener = () => {
  const navigate = useNavigate();
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const [isPush, setIsPush] = useState(false);
  const [currentState, setCurrentState] = useState(null);
  useEffect(() => {
    const listener = ({ location, action }) => {
      if (action === 'PUSH') {
        setIsPush(true);
        setCurrentState(location);
      } else {
        setIsPush(false);
        navigate(1);
      }
    };
    const unlisten = navigator.listen(listener);
    return unlisten;
  }, []);

  useEffect(() => {
    if (isPush) {
      navigator.push(currentState?.pathname, currentState?.state);
      setIsPush(false);
    }
  }, [isPush]);
};
export default useBackListener;
