import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  extMtData: {},
  processStepExtMtData: {},
  processExtMtData: {},
  peopleData: {},
  riskData: {},
  benefitData: {},
  riskMitigationData: {},
  benefitMilestoneData: {},
  visibleFieldsReview: [],
  editMode: false,
  stepEditMode: false,
  twinMode: false,
};

export const editformSlice = createSlice({
  name: 'editformSlice',
  initialState,
  reducers: {

    addExtMtData: (state, { payload }) => {
      state.extMtData = payload;
    },
    addProcessStepExtMtData: (state, { payload }) => {
      state.processStepExtMtData = payload;
    },
    addProcessExtMtData: (state, { payload }) => {
      state.processExtMtData = payload;
    },
    addPeopleData: (state, { payload }) => {
      state.peopleData = payload;
    },
    addRiskData: (state, { payload }) => {
      state.riskData = payload;
    },
    addBenefitData: (state, { payload }) => {
      state.benefitData = payload;
    },
    addvisibleFields: (state, { payload }) => {
      state.visibleFieldsReview = payload;
    },
    changeEditMode: (state, { payload }) => {
      state.editMode = payload;
    },
    changeStepEditMode: (state, { payload }) => {
      state.stepEditMode = payload;
    },
    changeTwinMode: (state, { payload }) => {
      state.twinMode = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addExtMtData, addProcessStepExtMtData,
  addBenefitData, addPeopleData, addRiskData, changeEditMode, addvisibleFields,
  addProcessExtMtData, changeTwinMode, changeStepEditMode,
} = editformSlice.actions;

export default editformSlice.reducer;
