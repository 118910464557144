/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productValues: {},
  productTwinVals: {},
  capabilitiesData: [],
  pullProcessProductCaps: [],
  productStepCapabilitiesData: [],
  productStepReviewCaps: [],
  processProductReviewCaps: [],
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProductVals: (state, { payload }) => {
      state.productValues = payload;
    },
    addProductCaps: (state, { payload }) => {
      const uniqueProductCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.capabilitiesData = uniqueProductCaps;
    },
    addProductTwinVals: (state, { payload }) => {
      state.productTwinVals = payload;
    },
    addProductStepCapabilities: (state, { payload }) => {
      state.productStepCapabilitiesData = payload;
    },
    addPullProcessProductCaps: (state, { payload }) => {
      const uniqueProcessProductCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.pullProcessProductCaps = uniqueProcessProductCaps;
    },
    removePullProcessProductCaps: (state, { payload }) => {
      state.pullProcessProductCaps = payload && payload.length > 0 && Array.isArray(payload) ? payload : [];
    },
    addProductStepReviewCaps: (state, { payload }) => {
      state.productStepReviewCaps = payload;
    },
    addProcessProductReviewCaps: (state, { payload }) => {
      const uniqueProcessRWCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.processProductReviewCaps = uniqueProcessRWCaps;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addProductVals,
  addProductCaps,
  addProductTwinVals,
  addProductStepCapabilities,
  addPullProcessProductCaps,
  removePullProcessProductCaps,
  addProductStepReviewCaps,
  addProcessProductReviewCaps,
} = productSlice.actions;

export default productSlice.reducer;
