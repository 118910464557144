/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addBusinessCapabilitiesData, addCapabilitiesData, addCustomerCapabilitiesData, addImpactVals, addIssueCapabilitiesReadOnly, addProcessCapabilitiesData, addProductCapabilitiesData, addVendorCapabilitiesData, setImpactReadReviewTab,
} from '../../../services/issueSlice';
import ImpactReview from './ImpactReview';
import { useGetImpactByIdMutation } from '../../../services/services';
import { addDocsInfo } from '../../../services/docInfoSlice';

function ImpactReadReview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = useSelector((state) => state.issueData.impactReadReviewTab);
  const impactValues = useSelector((state) => state.issueData.impactValues);

  const [reqImpactById, resImpactById] = useGetImpactByIdMutation();

  const handleTab = (tab) => {
    dispatch(setImpactReadReviewTab(tab));
  };

  useEffect(() => {
    if (impactValues?.id) {
      reqImpactById({ id: impactValues?.id });
    }
  }, []);

  useEffect(() => {
    if (resImpactById?.isSuccess && resImpactById?.data) {
      console.log('resImpactById?.data', resImpactById?.data);
      dispatch(addImpactVals(resImpactById?.data));
      // if (resImpactById?.data?.documents && Array.isArray(resImpactById?.data?.documents) && resImpactById?.data?.documents?.length > 0) {
      //   dispatch(addDocsInfo(resImpactById?.data?.documents));
      // }
      if ((resImpactById?.data?.issue?.object_type === 'Change' || resImpactById?.data?.issue?.object_type === 'Epic') && resImpactById?.data?.dependencies && Array.isArray(resImpactById?.data?.dependencies) && resImpactById?.data?.dependencies?.length > 0) {
        dispatch(addIssueCapabilitiesReadOnly({
          processCaps: resImpactById?.data?.dependencies?.filter((el) => (el?.key === 'process' || el?.key === 'Process'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          appCaps: resImpactById?.data?.dependencies?.filter((el) => (el?.key === 'application' || el?.key === 'Application'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          businessCaps: resImpactById?.data?.dependencies?.filter((el) => (el?.key === 'business' || el?.key === 'Business'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          productCaps: resImpactById?.data?.dependencies?.filter((el) => (el?.key === 'product' || el?.key === 'Product'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          vendorCaps: resImpactById?.data?.dependencies?.filter((el) => (el?.key === 'vendor' || el?.key === 'Vendor'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
          customerCaps: resImpactById?.data?.dependencies?.filter((el) => (el?.key === 'customer' || el?.key === 'Customer'))?.map((el) => el?.value)?.flat()?.filter((item) => item !== undefined && item !== null) || [],
        }));
      } else {
        dispatch(addIssueCapabilitiesReadOnly({
          processCaps: [],
          appCaps: [],
          businessCaps: [],
          productCaps: [],
          vendorCaps: [],
          customerCaps: [],
        }));
      }
      if ((resImpactById?.data?.issue?.object_type === 'Epic') && resImpactById?.data?.dependencies && Array.isArray(resImpactById?.data?.dependencies) && resImpactById?.data?.dependencies?.length > 0) {
        dispatch(addCapabilitiesData(resImpactById?.data?.dependencies?.filter((cm) => (cm.key === 'application' || cm.key === 'Application')).map((dm) => dm.value).flat()));
        dispatch(addProcessCapabilitiesData(resImpactById?.data?.dependencies?.filter((cm) => (cm.key === 'process' || cm.key === 'Process')).map((dm) => dm.value).flat()));
        dispatch(addBusinessCapabilitiesData(resImpactById?.data?.dependencies?.filter((cm) => (cm.key === 'business' || cm.key === 'Business')).map((dm) => dm.value).flat()));
        dispatch(addProductCapabilitiesData(resImpactById?.data?.dependencies?.filter((cm) => (cm.key === 'product' || cm.key === 'Product')).map((dm) => dm.value).flat()));
        dispatch(addVendorCapabilitiesData(resImpactById?.data?.dependencies?.filter((cm) => (cm.key === 'vendor' || cm.key === 'Vendor')).map((dm) => dm.value).flat()));
        dispatch(addCustomerCapabilitiesData(resImpactById?.data?.dependencies?.filter((cm) => (cm.key === 'customer' || cm.key === 'Customer')).map((dm) => dm.value).flat()));
      } else {
        dispatch(addCapabilitiesData([]));
        dispatch(addProcessCapabilitiesData([]));
        dispatch(addBusinessCapabilitiesData([]));
        dispatch(addProductCapabilitiesData([]));
        dispatch(addVendorCapabilitiesData([]));
        dispatch(addCustomerCapabilitiesData([]));
      }
    }
  }, [resImpactById?.isSuccess]);

  return (
    <div className="content-wrapper">
      <div className="card">
        <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>
            Details
            {' '}
            {location?.state?.impactName || ''}
          </h1>
          {(location?.state?.from === '/issue-read-review')
            && <Button className="text-decoration-none" type="button" onClick={() => navigate(location?.state?.from, { state: { ...location?.state?.parentState, state: location?.state?.parentState?.state } })}>Back</Button>}
        </div>
        <div className="card-body">
          <div className="pills-tabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(k) => handleTab(k)}
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <ImpactReview label={location?.state?.type} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImpactReadReview;
