/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import dayjs from 'dayjs';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSearchFilter from '../../hooks/useSearchFilter';
import { addEpicTaskId } from '../../services/epicSlice';
import {
  addRelatedChangeData, addRelatedProcessData, addRelatedEpicData, addRelatedInitData, addRelatedProgData,
} from '../../services/formSlice';
import { setRelatedProjectDate } from '../../services/reqChangeSlice';
import {
  useChangesByCompletedTaskQuery,
  useGetAllEpicsQuery,
  useGetChangeByModeQuery, useGetEpicInfoQuery, useGetProcessByModeQuery,
  useTaskByChangeMMutation, useGetProgByModeQuery, useGetProjByModeQuery, useTaskByChangeQuery, useGetProcessByStatusQuery, useGetProgramByStatusQuery, useGetInitByStatusQuery,
} from '../../services/services';
import PaginationComponenet from '../common/Pagination';

function TransformationModal({
  modal, handleClose, label, handleChange,
}:any):JSX.Element {
  console.log('modal', modal);
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const location = useLocation();
  const dispatch = useDispatch();
  const programData = useGetProgramByStatusQuery({ status: 'Initiation' });
  const initData = useGetInitByStatusQuery(location?.pathname === '/change' ? { status: ['Initiation', 'Implementation'] } : { status: ['Initiation'] });
  const changeData = useGetChangeByModeQuery({ mode: 'submitted' });
  const processData = useGetProcessByStatusQuery({ status: ['Active', 'Transformation'] });
  const epicChangeData = useChangesByCompletedTaskQuery();
  const epicData = useGetAllEpicsQuery();
  const [tskByChange, tslChangRes] = useTaskByChangeMMutation();

  const relatedProgram = useSelector((state) => state.formJson.relatedProgData);
  const progVals = useSelector((state) => state.formJson.programValues);
  const relatedInitiative = useSelector((state) => state.formJson.relatedInitData);
  const initVals = useSelector((state) => state.initData.initValues);
  const relatedChange = useSelector((state) => state.formJson.relatedChangeData);
  const relatedProcess = useSelector((state) => state.formJson.relatedProcessData);
  const relatedEpicData = useSelector((state) => state.formJson.relatedEpicData);
  const editMode = useSelector((state) => state.editData.editMode);
  const draftMode = useSelector((state) => state.formJson.draftMode);
  const processVals = useSelector((state) => state.processData.processVals);
  const epicValues = useSelector((state) => state.epicData.epicVals);
  const rcValues = useSelector((state) => state.rcData.rcValues);

  const [header, setHeader] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState([]);
  console.log('selectedProgram', selectedProgram);

  const [selectedProject, setSelectedProject] = useState([]);
  console.log('selectedProject', selectedProject);

  const [selectedChange, setSelectedChange] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [TBLData, setTBLData] = useState(tabledata || []);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const [totalCount, setTotalCount] = useState(0);
  console.log('TBLData', TBLData);

  const epicInfoData = useGetEpicInfoQuery({ id: selectedChange && Array.isArray(selectedChange) && selectedChange.length > 0 ? selectedChange[0]?.id : '' });

  const { filter, setFilter, filterArray } = useSearchFilter(tabledata || []);

  useEffect(() => {
    if (tabledata && Array.isArray(tabledata) && tabledata?.length > 0) {
      setTotalCount(tabledata.length);
    } else {
      setTotalCount(0);
    }
  }, [tabledata]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (tabledata && Array.isArray(tabledata) && tabledata?.length > 10) {
      const reversedArray = [];
      tabledata?.forEach((element) => {
        reversedArray.unshift(element);
      });
      const tData = reversedArray?.slice(firstPageIndex, lastPageIndex);
      setTBLData(tData);
    } else if (tabledata && Array.isArray(tabledata) && tabledata?.length > 0) {
      const reversedArray = [];
      tabledata?.forEach((element) => {
        reversedArray.unshift(element);
      });
      setTBLData(reversedArray);
    } else {
      setTBLData([]);
    }
  }, [tabledata, currentPage]);

  useEffect(() => {
    if (tabledata && Array.isArray(tabledata) && tabledata?.length > 0 && filter) {
      if (filterArray && Array.isArray(filterArray) && filterArray?.length > 0) {
        setTBLData(filterArray);
      } else {
        setTBLData([]);
      }
    }
  }, [tabledata, filter, filterArray]);

  useEffect(() => {
    if (Array.isArray(selectedChange) && selectedChange.length > 0 && selectedChange[0]?.id) { tskByChange({ id: selectedChange[0]?.id }); }
  }, [Array.isArray(selectedChange) && selectedChange.length > 0 && selectedChange[0]?.id]);

  useEffect(() => {
    if (modal.key === 'epic' && epicInfoData.isSuccess) {
      if (epicInfoData?.data?.epic_info && epicInfoData?.data?.epic_info?.length > 0) {
        epicInfoData?.data?.epic_info?.forEach((ep) => ep.epic_task_id && dispatch(addEpicTaskId(ep.epic_task_id)));
      }
    }
  }, [modal.key, epicInfoData.isSuccess]);

  useEffect(() => {
    if (tslChangRes.isSuccess) {
      tslChangRes.data.data.forEach((tsk) => {
        if (tsk.capability_type === 'Epic') {
          dispatch(addEpicTaskId(tsk.id));
        }
      });
    }
  }, [tslChangRes.isSuccess]);

  useEffect(() => {
    if (modal.key === 'epic' && epicInfoData?.isSuccess) {
      if ((epicInfoData?.data?.total_twin_count === epicInfoData?.data?.twins_in_epic_count
        || epicInfoData?.data?.total_twin_count < epicInfoData?.data?.twins_in_epic_count)
        || tslChangRes?.data?.data?.some((tsk) => tsk?.status !== 'Completed' && tsk?.capability_type !== 'Epic')
      ) {
        setSaveDisabled(true);
        setErrorMessage('All tasks are not Completed / All twins consumed');
      } else if (tslChangRes?.data?.data?.some((tsk) => tsk?.status === 'Available' && tsk?.capability_twin_id === -999)) {
        setSaveDisabled(true);
        setErrorMessage('Epic task is not accepted ');
      } else {
        setSaveDisabled(false);
      }
    } else {
      setSaveDisabled(false);
      setErrorMessage('');
    }
  }, [modal.key, epicInfoData?.isSuccess]);

  useEffect(() => {
    if ((relatedProgram && relatedProgram?.length > 0 && Array.isArray(relatedProgram))) {
      setSelectedProgram(relatedProgram);
    } else {
      setSelectedProgram([]);
    }
    if ((relatedInitiative && relatedInitiative?.length > 0 && Array.isArray(relatedInitiative))) {
      setSelectedProject(relatedInitiative);
    } else {
      setSelectedProject([]);
    }
    if ((relatedChange && relatedChange?.length > 0 && Array.isArray(relatedChange))) {
      setSelectedChange(relatedChange);
    } else {
      setSelectedChange([]);
    }
    if ((relatedProcess && relatedProcess?.length > 0 && Array.isArray(relatedProcess))) {
      setSelectedProcess(relatedProcess);
    } else {
      setSelectedProcess([]);
    }
    if ((relatedEpicData && relatedEpicData?.length > 0 && Array.isArray(relatedEpicData))) {
      setSelectedEpic(relatedEpicData);
    } else {
      setSelectedEpic([]);
    }
  }, [modal, (relatedProgram && relatedProgram?.length > 0 && Array.isArray(relatedProgram)),
    (relatedInitiative && relatedInitiative?.length > 0 && Array.isArray(relatedInitiative)),
    (relatedChange && relatedChange?.length > 0 && Array.isArray(relatedChange)),
    (relatedProcess && relatedProcess?.length > 0 && Array.isArray(relatedProcess)),
    (relatedEpicData && relatedEpicData?.length > 0 && Array.isArray(relatedEpicData))]);

  console.log('label', label);

  useEffect(() => {
    if (programData?.isSuccess && programData?.data) {
      if (modal.key === 'program') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'managers', name: 'Manager' },
            { type: 'date', field: 'start_date', name: 'Start Date' },
            { type: 'date', field: 'end_date', name: 'End Date' },
            { type: '', field: 'status', name: 'Status' },
          ],
        );
        if (location?.pathname === '/projects' && label === 'program' && (initVals?.status === 'Initiation' || initVals?.status === 'Implementation')) {
          const res = programData?.data?.map((dt) => ((initVals?.related_programs?.some((sp) => dt?.id === sp?.id)) ? { ...dt, isDisabled: true } : { ...dt }));
          setTableData([...res]);
        } else if (programData?.data && editMode && !draftMode) {
          setTableData(programData?.data.filter((pd) => pd.id !== progVals.id));
        } else if (programData?.data) {
          setTableData([...programData.data]);
        } else {
          setTableData([]);
        }
      }
    }
    if (programData?.isSuccess && initData?.data) {
      if (modal.key === 'initiative') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'managers', name: 'Manager' },
            { type: 'date', field: 'start_date', name: 'Start Date' },
            { type: 'date', field: 'end_date', name: 'End Date' },
            { type: '', field: 'status', name: 'Status' },
          ],
        );
        if (location?.pathname === '/change' && label === 'initiative') {
          const res = initData?.data?.map((dt) => (rcValues?.related_initiatives?.some((sp) => dt?.id === sp?.id) ? { ...dt, isDisabled: true } : { ...dt }));
          setTableData([...res]);
        } else if (location?.pathname === '/projects' && label === 'initiative' && (initVals?.status === 'Initiation' || initVals?.status === 'Implementation')) {
          const res = initData?.data?.map((dt) => (initVals?.related_initiatives?.some((sp) => dt?.id === sp?.id) ? { ...dt, isDisabled: true } : { ...dt }));
          const res2 = res?.filter((rs) => rs?.id !== initVals?.id);
          setTableData([...res2]);
        } else if (initData?.data && editMode && !draftMode) {
          setTableData(initData?.data.filter((pd) => pd.id !== initVals.id));
        } else if (initData?.data) {
          setTableData([...initData.data]);
        } else {
          setTableData([]);
        }
      }
    }
    if (changeData?.isSuccess && changeData?.data) {
      if ((modal.key === 'change' || modal.key === 'epic') && modal.key !== 'dependent_epic') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'managers', name: 'Manager' },
            { type: 'date', field: 'start_date', name: 'Start Date' },
            { type: 'date', field: 'end_date', name: 'End Date' },
            { type: '', field: 'status', name: 'Status' },
          ],
        );
        if (changeData?.data) {
          if (label === 'risk' || label === 'benefit') {
            const resChange = changeData?.data?.filter((cd) => cd?.status === 'Initiation');
            setTableData(resChange);
          } else {
            const resChange = changeData?.data?.filter((cd) => cd?.status === 'Initiation');
            setTableData(resChange);
          }
        } else {
          setTableData([]);
        }
      }
    }
    if (epicChangeData?.isSuccess && epicChangeData?.data) {
      if ((modal.key === 'epic') && modal.key !== 'dependent_epic') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'overheads_budget', name: 'Budget' },
            { type: 'date', field: 'start_date', name: 'Start Date' },
            { type: 'date', field: 'end_date', name: 'End Date' },
          ],
        );
        if (epicChangeData?.data) {
          setTableData([...epicChangeData.data]);
        } else {
          setTableData([]);
        }
      }
    }
    if (epicData?.isSuccess && epicData?.data) {
      if (modal.key === 'dependent_epic' && modal.key !== 'epic') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'assignees', name: 'Assignee' },
            { type: 'date', field: 'start_date', name: 'Start Date' },
            { type: 'date', field: 'end_date', name: 'End Date' },
            { type: '', field: 'status', name: 'Status' },
          ],
        );
        if (epicData?.data && editMode) {
          setTableData(epicData?.data?.data?.filter((ep) => ep?.id !== epicValues?.id));
        } else if (epicData?.data) {
          setTableData([...epicData.data.data]);
        } else {
          setTableData([]);
        }
      }
    }
    if (processData?.isSuccess && processData?.data) {
      if (modal.key === 'process') {
        setHeader(
          [
            { type: '', field: 'name', name: 'Name' },
            { type: '', field: 'owners', name: 'Owner' },
            { type: '', field: 'managers', name: 'Manager' },
            { type: '', field: 'status', name: 'Status' },
          ],
        );
        if (processData?.data && Array.isArray(processData?.data) && processData?.data?.length > 0) {
          if (processVals?.name) {
            setTableData(processData?.data?.filter((pd) => pd?.name !== processVals?.name));
          } else {
            setTableData([...processData.data]);
          }
        } else {
          setTableData([]);
        }
      }
    }
  }, [label, modal.key, programData?.isSuccess, initData?.isSuccess, changeData?.isSuccess, processData?.isSuccess, epicData?.isSuccess, epicChangeData?.isSuccess]);

  const checkHandler = (e, d, value) => {
    e.stopPropagation();
    if (modal.key === 'program') {
      if (selectedProgram.some((v) => v.id === d.id)) {
        setSelectedProgram(selectedProgram.filter((val) => val.id !== d.id));
      } else {
        setSelectedProgram([...selectedProgram, d]);
      }
    }
    if (modal.key === 'initiative') {
      if (selectedProject.some((v) => v.id === d.id)) {
        setSelectedProject(selectedProject.filter((val) => val.id !== d.id));
      } else {
        setSelectedProject([...selectedProject, d]);
      }
    }
    if (modal.key === 'change') {
      if (selectedChange.some((v) => v.id === d.id)) {
        setSelectedChange(selectedChange.filter((val) => val.id !== d.id));
      } else {
        setSelectedChange([...selectedChange, d]);
      }
    }
    if (modal.key === 'process') {
      if (selectedProcess.some((v) => v.id === d.id)) {
        setSelectedProcess(selectedProcess.filter((val) => val.id !== d.id));
      } else {
        setSelectedProcess([...selectedProcess, d]);
      }
    }
    if (modal.key === 'epic') {
      if (e.target.checked) {
        setSelectedChange([d]);
      } else {
        setSelectedChange([]);
      }
    }
    if (modal.key === 'dependent_epic') {
      if (selectedEpic.some((v) => v.id === d.id)) {
        setSelectedEpic(selectedEpic.filter((val) => val.id !== d.id));
      } else {
        setSelectedEpic([...selectedEpic, d]);
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (modal.key === 'program') { setSelectedProgram(selectedProgram.filter((value) => value.id !== name.id)); }
    if (modal.key === 'initiative') { setSelectedProject(selectedProject.filter((value) => value.id !== name.id)); }
    if (modal.key === 'change') { setSelectedChange(selectedChange.filter((value) => value.id !== name.id)); }
    if (modal.key === 'process') { setSelectedProcess(selectedProcess.filter((value) => value.id !== name.id)); }
    if (modal.key === 'epic') {
      setSelectedChange(selectedChange.filter((value) => value.id !== name.id));
    }
    if (modal.key === 'dependent_epic') { return setSelectedEpic(selectedEpic.filter((value) => value.id !== name.id)); }
  };

  const getCheckedVal = (nameVal) => {
    if ((modal.key === 'program') && selectedProgram.some((el) => el.id === nameVal)) return true;
    if ((modal.key === 'initiative') && selectedProject.some((el) => el.id === nameVal)) return true;
    if ((modal.key === 'change') && selectedChange.some((el) => el.id === nameVal)) return true;
    if ((modal.key === 'process') && selectedProcess.some((el) => el.id === nameVal)) return true;
    if ((modal.key === 'epic') && selectedChange.some((el) => el.id === nameVal)) return true;
    if ((modal.key === 'dependent_epic') && selectedEpic.some((el) => el.id === nameVal)) return true;

    return false;
  };

  const onSelect = () => {
    dispatch(addRelatedProgData(selectedProgram));
    dispatch(addRelatedInitData(selectedProject));
    dispatch(addRelatedChangeData(selectedChange));
    dispatch(addRelatedProcessData(selectedProcess));
    dispatch(addRelatedEpicData(selectedEpic));
    handleClose();
  };

  useEffect(() => {
    if (location?.pathname === '/change' && (relatedInitiative && Array.isArray(relatedInitiative) && relatedInitiative.length)) {
      const minDateArr = [];
      const maxDateArr = [];
      relatedInitiative?.forEach((vl) => {
        minDateArr.push(vl?.start_date);
      });
      relatedInitiative?.forEach((vl) => {
        maxDateArr.push(vl?.end_date);
      });
      const minDate = minDateArr?.reduce((first, second) => (first < second ? first : second));
      const maxDate = maxDateArr?.reduce((first, second) => (first > second ? first : second));

      if (minDate && maxDate) {
        dispatch(setRelatedProjectDate({ startDate: minDate, endDate: maxDate }));
      }
    } else if (location?.pathname === '/projects' && (relatedProgram && Array.isArray(relatedProgram) && relatedProgram.length)) {
      const minDateArr = [];
      const maxDateArr = [];
      relatedProgram?.forEach((vl) => {
        minDateArr.push(vl?.start_date);
      });
      relatedProgram?.forEach((vl) => {
        maxDateArr.push(vl?.end_date);
      });
      const minDate = minDateArr?.reduce((first, second) => (first < second ? first : second));
      const maxDate = maxDateArr?.reduce((first, second) => (first > second ? first : second));

      if (minDate && maxDate) {
        dispatch(setRelatedProjectDate({ startDate: minDate, endDate: maxDate }));
      }
    } else {
      dispatch(setRelatedProjectDate({ startDate: null, endDate: null }));
    }
  }, [relatedInitiative]);

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal
      show={modal.show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Related
          {' '}
          {modal.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="card">
          <div
            id="collapseOne"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionDark"
          >
            <div className="card-body">
              <h5 className="font-weight-normal" style={{ textTransform: 'capitalize' }}>
                {`${modal.title}`}
              </h5>
              <div className="chip-wrapper">
                <div className="md-chips">
                  {((modal.key === 'program')) && (selectedProgram && selectedProgram?.length > 0 && Array.isArray(selectedProgram)) && selectedProgram?.map((val) => (
                    <div key={label + val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {
                        !((initVals?.status === 'Initiation' || initVals?.status === 'Implementation') && initVals?.related_programs?.some((ridt) => ridt?.id === val?.id))
                      && (
                      <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, 'program', val)}>
                        <span className="icon-close" />
                      </button>
                      )
}
                    </div>
                  ))}
                  {((modal.key === 'initiative')) && (selectedProject && selectedProject?.length > 0 && Array.isArray(selectedProject)) && selectedProject?.map((val) => (
                    <div key={label + val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      {!((rcValues?.status === 'Implementation' && rcValues?.related_initiatives?.some((ridt) => ridt?.id === val?.id)) || ((initVals?.status === 'Initiation' || initVals?.status === 'Implementation') && initVals?.related_initiatives?.some((ridt) => ridt?.id === val?.id)))
                      && (
                      <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, 'initiative', val)}>
                        <span className="icon-close" />
                      </button>
                      )}
                    </div>
                  ))}
                  {((modal.key === 'change' || modal.key === 'epic')) && (selectedChange && selectedChange?.length > 0 && Array.isArray(selectedChange)) && selectedChange?.map((val) => (
                    <div key={label + val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, modal.key, val)}>
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}
                  {(modal.key === 'process') && (selectedProcess && selectedProcess?.length > 0 && Array.isArray(selectedProcess)) && selectedProcess?.map((val) => (
                    <div key={label + val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, modal.key, val)}>
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}
                  {((modal.key === 'dependent_epic')) && (selectedEpic && selectedEpic?.length > 0 && Array.isArray(selectedEpic)) && selectedEpic?.map((val) => (
                    <div key={label + val.id} className="md-chip">
                      <span>{`${val.name}`}</span>
                      <button className="md-chip-remove" type="button" onClick={(e) => removeHandler(e, modal.key, val)}>
                        <span className="icon-close" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              {modal.key === 'epic' && saveDisabled && (
              <div className="invalid-feedback mb-3">
                <span className="icon-error-red" />
                {errorMessage && errorMessage}
              </div>
              )}
              <h2>{`Select ${modal.title}s`}</h2>
              <div className="table-responsive box-shadow mb-0">
                <table className="table table-hover">
                  <thead>
                    <tr className="heading-row">
                      <th className="check-field">
                        {label !== 'epic'
                            && (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="selectAll"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="selectAll"
                              />
                            </div>
                            )}
                      </th>
                      {(header && Array.isArray(header) && header?.length > 0) && header?.map((ele) => (
                        <th key={ele?.name}>{ele.name}</th>
                      ))}
                    </tr>
                    <tr className="filter-row">
                      <th>&nbsp;</th>
                      {(header && Array.isArray(header) && header?.length > 0) && header?.map((ele) => (
                        <th key={ele?.name}>
                          {ele?.type === '' && (
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            name={ele?.field}
                            disabled={(ele?.field !== 'name' && ele?.field !== 'status' && ele?.field !== 'owners' && ele?.field !== 'managers' && ele?.field !== 'assignees')}
                            onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                          />
                          )}
                          {ele.type === 'date' && (
                          <DatePicker
                            className="form-control"
                            placeholderText="DD/MM/YYYY"
                            portalId="root-portal"
                            disabled={(ele?.field === 'start_date' || ele?.field === 'end_date')}
                          />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(TBLData && Array.isArray(TBLData) && TBLData?.length > 0) ? TBLData?.map((td) => (
                      <tr key={td.id}>
                        <td className="check-field">
                          <div className={`custom-control ${label === 'epic' ? 'custom-radio' : 'custom-checkbox'}`}>
                            <input
                              type={label === 'epic' ? 'radio' : 'checkbox'}
                              className="custom-control-input"
                              checked={getCheckedVal(td.id)}
                              onChange={(e) => checkHandler(e, td, label)}
                              value={td.name}
                              id={modal.key + td.id}
                              disabled={td?.isDisabled}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={modal.key + td.id}
                              aria-label="select"
                            />
                          </div>
                        </td>
                        {(header && header?.length > 0 && Array.isArray(header)) && header?.map((th) => (
                          (th.field === 'owner' || th.field === 'owners')
                            ? (
                              <td className="text-capitalize" key={th.field}>
                                {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((ow) => (
                                  `${ow?.first_name}  ${ow?.last_name || ''}`
                                )) : '-'}
                              </td>
                            )
                            : (th.field === 'manager' || th.field === 'managers' || th.field === 'assignees')
                              ? (
                                <td className="text-capitalize" key={th.field}>
                                  {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.map((mn) => (
                                    `${mn?.first_name}  ${mn?.last_name || ''}`
                                  )) : '-'}
                                </td>
                              )
                              : (th.field === 'step_mappings')
                                ? (
                                  <td key={th.field}>

                                    {(td[th.field] && td[th.field]?.length > 0 && Array.isArray(td[th.field])) ? td[th.field]?.length : '-'}
                                  </td>
                                )
                                : th.field === 'initiative' ? <td key={th.field}>0</td>
                                  : th.field === 'overheads_budget' ? (
                                    <td key={th.field}>
                                      {' '}
                                      {td[th.field] || '-'}
                                    </td>
                                  )
                                    : th.field === 'name' ? td[th.field] && <td key={th.field}><Button href={`/review/${modal.key === 'dependent_epic' ? 'epic' : modal?.key}/${td?.id}`} target="_blank" variant="link" style={{ textDecoration: 'none' }}>{td[th.field]}</Button></td>
                                      : th.type === 'date' ? td[th.field] && <td key={th.field}>{dayjs(td[th.field]).format(userData?.tenant_info?.date_format) || '-'}</td>
                                        : td[th.field] && <td key={th.field}>{td[th.field] || '-'}</td>
                        ))}
                      </tr>
                    ))
                      : (
                        <tr>
                          <td
                            colSpan={(header && header?.length && Array.isArray(header)) && header?.length}
                            className="text-center"
                          >
                            {`No ${modal.title} Found`}

                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
                {totalCount > 0 && (TBLData && Array.isArray(TBLData) && TBLData?.length > 0)
                    && (
                    <PaginationComponenet
                      currentPage={currentPage}
                      totalCount={totalCount}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                      TBLData={TBLData}
                    />
                    )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {(tabledata?.length > 0 && header?.length > 0)
      && (
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="primary" onClick={onSelect} disabled={modal.key === 'epic' && saveDisabled}>Select</Button>
      </Modal.Footer>
      )}

    </Modal>
  );
}

export default TransformationModal;
