/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CancelModal from '../../components/common/CancelModal';
import TablePeople from '../../components/common/TablePeople';
import { useGetAllPeopleQuery } from '../../services/services';
import { addEpicTaskPeople } from '../../services/peopleSlice';
import StepFooter from '../../components/common/StepFooter';

function EpicTaskPeople({
  label,
  steps,
  activeStep,
  handleBack,
  handleNext,
  modalShow,
  setModalShow,
  stepReset,
  fields,
  cancelTitle,
}) {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('people');
  const dispatch = useDispatch();
  const tableData = useGetAllPeopleQuery();
  const editMode = useSelector((state) => state.editData.editMode);
  const epicTaskPeople = useSelector((state) => state.peopleData.epicTaskPeople);
  const [assignee, setAssignee] = useState(false);
  const [assigneeData, setAssigneeData] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tableTitle, setTableTitle] = useState('');
  const [selVal, setSelVal] = useState({
    flag: false,
    val: '',
  });
  const draftMode = useSelector((state) => state.formJson.draftMode);

  useEffect(() => {
    if (fields?.assignee) {
      if (!assigneeData?.length) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [fields?.assignee, assigneeData?.length]);

  useEffect(() => {
    if (epicTaskPeople?.assignee) {
      setAssigneeData(epicTaskPeople?.assignee);
    }
  }, [epicTaskPeople]);

  console.log('label', label);

  useEffect(() => {
    if (tableData.isSuccess) {
      if (tableData.data.tableData && Array.isArray(tableData.data.tableData) && tableData.data.tableData?.length > 0) {
        setDataTable([...tableData.data.tableData]);
      }
    }
  }, [tableData]);

  const userhandler = (e, val) => {
    e.stopPropagation();
    setSelVal({
      ...selVal,
      flag: true,
      val,
    });
    if (val === 'assignee') {
      setAssignee(true);
      setTableTitle(t('people:selectTitle.assignee'));
    }
  };

  const checkHandler = (e, d) => {
    e.stopPropagation();
    if (assignee) {
      if (e.target.checked) {
        setAssigneeData([d]);
      } else {
        setAssigneeData([]);
      }
    }
  };

  const removeHandler = (e, val, name) => {
    e.stopPropagation();
    if (val === 'assignee') { return setAssigneeData(assigneeData?.filter((value) => value.id !== name.id)); }
  };

  const getCheckedVal = (nameVal) => {
    if (assignee && assigneeData?.some((el) => el.id === nameVal)) return true;
    return false;
  };

  useEffect(() => {
    if (assignee) {
      setSelVal({
        ...selVal,
        flag: true,
      });
    }
  }, [assignee]);

  const componentHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (assigneeData) {
      const data = {
        assignee:
              assigneeData && Array.isArray(assigneeData) && assigneeData?.length > 0
                ? assigneeData?.map((el) => ({
                  id: el.id,
                  email: el.email,
                  first_name: el?.first_name,
                  last_name: el?.last_name,
                  designation_id: {
                    id: el?.designation_id?.id,
                    name: el?.designation_id?.name,
                  },
                  department_id: {
                    id: el?.department_id?.id,
                    name: el?.department_id?.name,
                  },
                }))
                : [],

      };
      dispatch(addEpicTaskPeople(data));
      handleNext();
    }
  };

  return (
    <form action="" className="add-program-form">
      <div className="form-unit">
        <div className="container-fluid">
          <h2>
            {(editMode && !draftMode) ? t('people:section_title_edit')
              : t('people:section_title_add')}

          </h2>
          <div className="row add-people">
            {fields?.assignee && (
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="program_uid">
                  {t('people:assignee')}
                  <sup>*</sup>
                  <span className="people-icon sponsor-icon">{t('people:as')}</span>
                </label>
                <div
                  className={`form-control ${assignee ? 'active' : ''}`}
                  type="text"
                  name="unique_"
                  id="program_subj"
                >
                  <div className="md-chips">
                    {assigneeData
                        && assigneeData?.length > 0
                        && Array.isArray(assigneeData)
                        && assigneeData?.map((val) => (
                          <div key={val.id} className="md-chip">
                            <span className="text-capitalize">{`${val?.first_name || ''} ${val?.last_name || ''}`}</span>
                            <button
                              aria-label="remove-stcom"
                              type="button"
                              className="md-chip-remove"
                              onClick={(e) => removeHandler(e, 'assignee', val)}
                            >
                              <span className="icon-close" />
                            </button>
                          </div>
                        ))}
                  </div>
                  <div className="input-group-append">
                    <button
                      data-test-id="test-assignee"
                      type="button"
                      className="input-group-text btn btn-link btn-small"
                      onClick={(e) => userhandler(e, 'assignee')}
                    >
                      {t('people:selectTitle.select')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
          <hr />
          <h2>{tableTitle}</h2>
          <TablePeople
            checkHandler={checkHandler}
            userData={dataTable}
            getCheckedVal={getCheckedVal}
            selVal={selVal}
            ownerData={[]}
            subjMtExpData={[]}
            assigneeData={assigneeData}
            managerData={[]}
            strComData={[]}
            sponsorData={[]}
            teamMemberData={[]}
            resPersonData={[]}
            disabled
            tableMessage={t('people:table_message')}
          />

          <CancelModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            cancelTitle={cancelTitle}
            func={stepReset}
          />
          <StepFooter
            label={label}
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            componentHandler={componentHandler}
            modalShow={modalShow}
            setModalShow={setModalShow}
            nextDisabled={nextDisabled}
          />
        </div>
      </div>
    </form>
  );
}
const TaskPeople = React.memo(EpicTaskPeople);
export default TaskPeople;
