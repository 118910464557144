/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import { Button, Toast } from 'react-bootstrap';
import Review from '../../components/common/Review';
import {
  useSaveDocsMutation,
  useSubmitEpicTaskMutation,
  useGetEpicTaskExtMtDtQuery,
} from '../../services/services';
import {
  addCapabilities, addCapsMapping, addProcessCapabilities,
} from '../../services/applicationSlice';
import {
  addBudgetDocsInfo, addDocsInfo, addEffortDocsInfo, clearDocsInfo,
} from '../../services/docInfoSlice';
import FormBuilder from '../../components/form/FormBuilder';
import { addPeople } from '../../services/peopleSlice';
import People from '../../components/common/People';
import {
  addExtMtData, addvisibleFields, changeEditMode,
} from '../../services/editFormSlice';
import AlertMessage from '../../SuperComponents/AlertMessage';
import Capabilities from '../../components/capabalities';
import {
  addRalatedDate,
  addRelatedChangeData, addRelatedEpicData, isDraftMode, selectBU, selectDeprt, selectOrg,
} from '../../services/formSlice';
import { addIsDirty } from '../../services/navSlice';
import LoaderComp from '../../components/LoderQ';
import {
  addBusinessCaps,
} from '../../services/businessSlice';
import {
  addVendorCaps,
} from '../../services/vendorSlice';
import {
  addCustomerCaps,
} from '../../services/customerSlice';
import { addEpicTaskVals, addSelectedEpicTask } from '../../services/epicTaskSlice';
import { addEpicTaskId, addEpicVals } from '../../services/epicSlice';
import TaskPeople from './EpicTaskPeople';
import { addSelectedEpicRequirement, addSelectedRequirement } from '../../services/requirementSlice';
import ErrorModal from '../../components/common/ErrorModal';
import { addProductCaps } from '../../services/productSlice';

dayjs.extend(isToday);

function CreateEpicTask() {
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const { t } = useTranslation('epic');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Queries & Mutation
  const [saveDocs, docRes] = useSaveDocsMutation();
  const [submitForm, res] = useSubmitEpicTaskMutation();
  const formJson = useGetEpicTaskExtMtDtQuery();

  // Redux State
  const editMode = useSelector((state) => state.editData.editMode);
  const epicTaskValues = useSelector((state) => state.epicTaskData.epicTaskVals);
  const epicTaskPeople = useSelector((state) => state.peopleData.epicTaskPeople);
  const epicCapsMapping = useSelector((state) => state.epicTaskData.epicTaskVals);
  const editExtMtData = useSelector((state) => state.editData.extMtData);
  const docsInfo = useSelector((state) => state.docInfoData.docsInfo);
  const relatedChanges = useSelector((state) => state.formJson.relatedChangeData);
  const selectedEpicRequirement = useSelector((state) => state.requirementData.selectedEpicRequirement);
  const dependentEpicTask = useSelector((state) => state.epicTaskData.dependentEpicTask);

  const [jsonTemplate, setJsonTemplate] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Form Steps
  const [steps, setSteps] = useState([
    {
      key: 'step1',
      label: t('epic_task.strings.step1'),
      isDone: false,
    },
    {
      key: 'step2',
      label: t('epic_task.strings.step2'),
      isDone: false,
    },
    {
      key: 'step3',
      label: t('epic_task.strings.step4'),
      isDone: false,
    },
    // {
    //   key: 'step4',
    //   label: t('strings.step4'),
    //   isDone: false,
    // },
  ]);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [modalShow, setModalShow] = React.useState(false);
  const [jsonContent, setJsonContent] = React.useState('');

  const cancelTitle = 'Work Package Task creation';

  // Function to move next step
  const handleNext = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) => prevStep.map((x) => {
      if (x.key === activeStep.key) x.isDone = true;
      return x;
    }));
    setActiveStep(steps[index + 1]);
  };

  // function for back
  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;
    setSteps((prevStep) => prevStep.map((x) => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }));
    setActiveStep(steps[index - 1]);
  };

  // Setting ext Mt Dt as json
  useEffect(() => {
    if (editMode) {
      setJsonTemplate(editExtMtData);
      if (epicTaskValues?.documents) {
        dispatch(addDocsInfo(epicTaskValues?.documents));
      }
      if (epicTaskValues?.budget_documents) {
        dispatch(addBudgetDocsInfo(epicTaskValues?.budget_documents));
      }
    }
  }, [editMode]);

  // Setting ext Mt Dt as json based on for Json
  useEffect(() => {
    if (formJson?.isSuccess) {
      setJsonTemplate(formJson.data);
    }
  }, [formJson?.isSuccess]);

  // Save Doc Res to State
  useEffect(() => {
    if (docRes.isSuccess && (docRes.data.docData && docRes.data.docData?.length > 0 && Array.isArray(docRes.data.docData))) {
      const docsData = docRes.data.docData.map((ele) => ({
        id: ele.id,
        original_name: ele.original_name,
        s3_key_name: ele.s3_key_name,
      }));
      dispatch(addDocsInfo(docsData));
    }
  }, [docRes.isSuccess]);

  // Function to set State while clicking on Next
  const onSubmit = (values) => {
    const vals = {
      ...values,
      status: values.status,
      unique_id: values?.unique_id ? values?.unique_id : '-',
      documents: values?.documents || '',
      estimated_effort: values?.estimated_effort ? Number(values?.estimated_effort) : 0,
      total_effort: values?.estimated_effort ? Number(values?.estimated_effort) : 0,
    };
    if (vals?.documents) {
      const docsCopy = vals?.documents ? vals?.documents?.filter((el) => !docsInfo?.some((dc) => dc.s3_key_name === el.s3_key_name)) : [];
      saveDocs({ data: docsCopy, budgetData: [], effortData: [] });
    }

    const updated = editMode
      ? editExtMtData?.fields?.map((dt) => ({ ...dt, value: (dt.field_data_type === 'select' && dt.field_type === 'System') ? { label: dt.field_value_option.options.find((op) => op.value === vals[dt.field_name])?.name, value: vals[dt.field_name] } : (dt?.field_name === 'estimated_effort' || dt?.field_name === 'total_effort') ? `${vals[dt.field_name]} Days` : vals[dt.field_name] || '' }))
      : formJson.data?.fields?.map((dt) => ({ ...dt, value: (dt.field_data_type === 'select' && dt.field_type === 'System' && vals[dt.field_name]) ? { label: dt.field_value_option.options.find((op) => op.value === vals[dt.field_name])?.name, value: vals[dt.field_name] } : (dt?.field_name === 'estimated_effort' || dt?.field_name === 'total_effort') ? `${vals[dt.field_name]} Days` : vals[dt.field_name] || '' }));

    dispatch(addEpicTaskVals(vals));
    dispatch(
      addExtMtData({ fields: updated, sections: formJson?.data?.sections }),
    );
    dispatch(addvisibleFields(
      [
        { name: 'people', visible: true },
      ],
    ));
    handleNext();
  };

  console.log('check task location', location?.state?.from, location?.state);

  // Reset All steps
  const stepReset = () => {
    dispatch(addIsDirty(false));
    if (location?.state?.from && location?.state?.from === '/epic') {
      dispatch(addEpicTaskId(location?.state?.data?.epicTaskId));
      dispatch(addExtMtData(location?.state?.data?.extMtData));
      dispatch(selectBU(location?.state?.data?.businessUnit));
      dispatch(selectDeprt(location?.state?.data?.departments));
      dispatch(selectOrg(location?.state?.data?.organizations));
      dispatch(addDocsInfo(location?.state?.data?.docsInfo));
      dispatch(addBudgetDocsInfo(location?.state?.data?.budgetDocsInfo));
      dispatch(addEffortDocsInfo(location?.state?.data?.efforDocsInfo));
      dispatch(addRelatedChangeData(location?.state?.data?.relatedChange));
      dispatch(addRelatedEpicData(location?.state?.data?.relatedEpicData));
      dispatch(addCapabilities(location?.state?.data?.appCapsDt));
      dispatch(addProcessCapabilities(location?.state?.data?.processCapsDt));
      dispatch(addBusinessCaps(location?.state?.data?.businessCapsDt));
      dispatch(addVendorCaps(location?.state?.data?.vendorCapsDt));
      dispatch(addCustomerCaps(location?.state?.data?.customerCapsDt));
      dispatch(addProductCaps(location?.state?.data?.productCapsDt));
      dispatch(addCapsMapping(location?.state?.data?.capsMapping));
      dispatch(addPeople(location?.state?.data?.peopleData));
      dispatch(addvisibleFields(location?.state?.data?.visibleField));
      dispatch(addRalatedDate(location?.state?.data?.relatedDate));
      dispatch(changeEditMode(location?.state?.data?.editMode));
      dispatch(isDraftMode(location?.state?.data?.draftMode));
      dispatch(addSelectedRequirement(location?.state?.data?.requirementData));
      dispatch(addSelectedEpicRequirement(location?.state?.data?.selectedEpicRequirement));
      dispatch(addSelectedEpicTask(location?.state?.data?.selectedEpicTask));
      dispatch(addEpicVals(location?.state?.data?.dfVals));
      navigate(location?.state?.from, {
        state: {
          steps: location?.state?.data?.steps,
          activeStep: location?.state?.data?.activeStep,
          breadcrumb: location?.state?.data?.breadcrumb,
        },
      });
    } else {
      dispatch({ type: 'RESET_APP' });
      setTimeout(() => {
        navigate('/dashboard', {
          state: {
            selected: 'Epic Task',
          },
        });
      }, 300);
    }
  };

  // Remove Success Message
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }
  }, [successMessage]);

  // After Submit Redirect & Success Message
  useEffect(() => {
    if (res?.isSuccess) {
      setSuccessMessage(`Work Package Task ${res?.data?.mode === 'draft' ? 'Saved' : 'Submitted'} Successfully`);
      dispatch(addIsDirty(false));
      dispatch(clearDocsInfo());
      if (location?.state?.from && location?.state?.from === '/epic') {
        dispatch(addEpicTaskId(location?.state?.data?.epicTaskId));
        dispatch(addExtMtData(location?.state?.data?.extMtData));
        dispatch(selectBU(location?.state?.data?.businessUnit));
        dispatch(selectDeprt(location?.state?.data?.departments));
        dispatch(selectOrg(location?.state?.data?.organizations));
        dispatch(addDocsInfo(location?.state?.data?.docsInfo));
        dispatch(addBudgetDocsInfo(location?.state?.data?.budgetDocsInfo));
        dispatch(addEffortDocsInfo(location?.state?.data?.efforDocsInfo));
        dispatch(addRelatedChangeData(location?.state?.data?.relatedChange));
        dispatch(addRelatedEpicData(location?.state?.data?.relatedEpicData));
        dispatch(addCapabilities(location?.state?.data?.appCapsDt));
        dispatch(addProcessCapabilities(location?.state?.data?.processCapsDt));
        dispatch(addBusinessCaps(location?.state?.data?.businessCapsDt));
        dispatch(addVendorCaps(location?.state?.data?.vendorCapsDt));
        dispatch(addCustomerCaps(location?.state?.data?.customerCapsDt));
        dispatch(addProductCaps(location?.state?.data?.productCapsDt));
        dispatch(addCapsMapping(location?.state?.data?.capsMapping));
        dispatch(addPeople(location?.state?.data?.peopleData));
        dispatch(addvisibleFields(location?.state?.data?.visibleField));
        dispatch(addRalatedDate(location?.state?.data?.relatedDate));
        dispatch(changeEditMode(location?.state?.data?.editMode));
        dispatch(isDraftMode(location?.state?.data?.draftMode));
        dispatch(addSelectedRequirement(location?.state?.data?.requirementData));
        dispatch(addSelectedEpicRequirement(location?.state?.data?.selectedEpicRequirement));
        const epicTaskData = location?.state?.data?.selectedEpicTask;
        if (epicTaskData?.some((el) => el?.id === res?.data?.id)) {
          const resReq1 = epicTaskData?.filter((el) => el?.id !== res?.data?.id);
          dispatch(addSelectedEpicTask([...resReq1, res?.data]));
        } else {
          dispatch(addSelectedEpicTask([...epicTaskData, res?.data]));
        }
        dispatch(addEpicVals(location?.state?.data?.dfVals));
        navigate(location?.state?.from, {
          state: {
            steps: location?.state?.data?.steps,
            activeStep: location?.state?.data?.activeStep,
            breadcrumb: location?.state?.data?.breadcrumb,
          },
        });
      } else {
        setTimeout(() => {
          dispatch({ type: 'RESET_APP' });
          navigate('/dashboard');
        }, 800);
      }
    }
  }, [res]);

  // Submit Flow function
  const saveEpicTask = (e, mode) => {
    const extensionMetaDataArr = editExtMtData?.fields?.map((fl) => ((fl.field_data_type === 'date' && epicTaskValues[fl.field_name]) ? { ...fl, value: dayjs(epicTaskValues[fl.field_name]).format('YYYY-MM-DD hh:mm:ss') } : { ...fl }));
    const extensionMetaData = extensionMetaDataArr.map(({ isDisabled, ...rest }) => rest);
    const finalData = {
      ...epicTaskValues,
      // capability_type: '',
      // capability_twin_id: '',
      change_id: location?.state?.data?.relatedChange[0],
      requirement_id: location?.state?.data?.selectedEpicRequirement && Array.isArray(location?.state?.data?.selectedEpicRequirement) && location?.state?.data?.selectedEpicRequirement?.length > 0 ? location?.state?.data?.selectedEpicRequirement[0] : null,
      assignee: epicTaskPeople?.assignee?.length > 0 ? epicTaskPeople?.assignee[0] : null,
      epic_task_extensions: extensionMetaData?.map((ed) => ((ed.field_data_type === 'select' && ed.field_type === 'System') ? ({ ...ed, value: `${ed?.value?.value}` }) : ({ ...ed, value: `${ed.value}` }))),
      created: editMode ? epicTaskValues?.created : dayjs().format('YYYY-MM-DD hh:mm:ss'),
      created_by: {
        id: userData?.id,
      },
      updated: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      start_date: dayjs(epicTaskValues?.start_date).format('YYYY-MM-DD hh:mm:ss'),
      end_date: dayjs(epicTaskValues?.end_date).format('YYYY-MM-DD hh:mm:ss'),
      updated_by: {
        id: userData?.id,
      },
      deleted: false,
      notes: epicTaskValues?.notes ? epicTaskValues?.notes : '',
      status: (mode === 'submitted' && epicTaskValues?.status?.trim() === 'New') ? 'Open' : mode === 'submitted' && (epicTaskValues?.status?.trim() !== 'New' || epicTaskValues?.status?.trim() !== 'Open') ? epicTaskValues?.status : 'New',
      documents: docsInfo && Array.isArray(docsInfo) && docsInfo?.length > 0 ? docsInfo : null,
      estimated_efforts: epicTaskValues?.estimated_effort || 0,
      total_efforts: epicTaskValues?.estimated_effort || 0,
      mode,
      preceding_dependent_epic_tasks: dependentEpicTask && Array.isArray(dependentEpicTask) && dependentEpicTask?.length > 0 ? dependentEpicTask : [],
    };
    if (!editMode) {
      delete finalData.id;
      delete finalData.unique_id;
      submitForm(finalData);
    } else {
      submitForm(finalData);
    }
  };

  // Return Starts
  return (
    <div className="content-wrapper">
      {(res?.isError) && <ErrorModal />}
      {(res?.isLoading) && (
      <LoaderComp />
      )}
      {successMessage && (
        <AlertMessage message={successMessage} setter={setSuccessMessage} />
      )}

      <div className="card">
        <div className="card-header">
          <h1>
            {!editMode ? t('epic_task.create_new_epic_task') : `Work Package Task: ${epicTaskValues?.name}`}
          </h1>
        </div>

        <div className="card-body">
          <div className="step-count-wrapper">
            <ul className="list-unstyled mb-0 steps-list d-flex align-items-center justify-content-center">
              {steps.map((step, i) => (
                <li
                  key={step.key}
                  className={`d-flex align-items-center ${
                    activeStep.key === step.key ? 'active' : ''
                  } ${step.isDone ? 'success' : ''}`}
                >
                  <span className="step-count">
                    <span className="icon-progress" />
                    {i + 1}
                  </span>
                  <p>{step.label}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="form-wrapper">

            {activeStep.key === 'step1' && (
              <FormBuilder
                steps={steps}
                label="epic:epic_task"
                activeStep={activeStep}
                handleBack={handleBack}
                template={editMode ? editExtMtData : jsonTemplate}
                onNext={onSubmit}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                dfVals={epicTaskValues}
                editMode={editMode}
                cancelTitle={cancelTitle}
              />
            )}
            {/* {activeStep.key === 'step2'
            && (
              <Capabilities
                label="epicTask"
                t={t}
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                cancelTitle={cancelTitle}
              />
            )} */}

            {activeStep.key === 'step2' && (
              <TaskPeople
                label="epicTask"
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleBack={handleBack}
                handleNext={handleNext}
                edit={editMode}
                template={jsonTemplate}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                fields={{
                  owner: false,
                  manager: false,
                  stCom: false,
                  teams: false,
                  sponsor: false,
                  collaborator: false,
                  resp_person: false,
                  assignee: true,
                }}
                cancelTitle={cancelTitle}
              />
            )}

            {activeStep.key === 'step3' && (
              <Review
                review="epicTask"
                label="epic:epic_task"
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                modalShow={modalShow}
                setModalShow={setModalShow}
                stepReset={stepReset}
                submit={saveEpicTask}
                cancelTitle={cancelTitle}
                isLoading={res?.isLoading}
                isError={res?.isError}
                peopleView={{
                  owner: false,
                  manager: false,
                  stCom: false,
                  teams: false,
                  sponsor: false,
                  collaborator: false,
                  resp_person: false,
                  assignee: true,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const EpicTask = React.memo(CreateEpicTask);

export default EpicTask;
