import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  programChildren: [],
  selectedInitProgram: null,
};

export const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    addProgramChildren: (state, { payload }) => {
      state.programChildren = payload;
    },
    addInitProgram: (state, { payload }) => {
      state.selectedInitProgram = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addProgramChildren, addInitProgram,
} = treeSlice.actions;

export default treeSlice.reducer;
