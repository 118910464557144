import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  epicVals: {},
  epicChange: {},
  epicTaskId: null,
  epicChangeReadOnly: false,
  epicInputDisable: false,
};

export const epicSlice = createSlice({
  name: 'epicSlice',
  initialState,
  reducers: {
    addEpicVals: (state, { payload }) => {
      state.epicVals = payload;
    },
    addEpicTaskId: (state, { payload }) => {
      state.epicTaskId = payload;
    },
    setEpicChangeMode: (state, { payload }) => {
      state.epicChangeReadOnly = payload;
    },
    setEpicInputDisabled: (state, { payload }) => {
      state.epicInputDisable = payload;
    },
  },
  addEpicChange: (state, { payload }) => {
    state.epicChange = payload;
  },
});

export const {
  addEpicVals, addEpicChange, addEpicTaskId, setEpicChangeMode,
  setEpicInputDisabled,
} = epicSlice.actions;

export default epicSlice.reducer;
