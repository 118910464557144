import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jsonData: [],
  sectionData: [],
  pathData: [],
  selectedProgram: {},
  initValues: {},
  // peopleVals: {
  //   owner: [], manager: [], member: [],
  // },
  // costCenter: [],
  // valueChains: [],
  // docsInfo: [],
  // allAssets: [],
  allInitiative: [],
};

export const initSlice = createSlice({
  name: 'initSlice',
  initialState,
  reducers: {
    addPaths: (state, { payload }) => {
      if (!state.pathData.includes(payload) && !Array.isArray(payload)) {
        state.pathData = [...state.pathData, payload];
      }
      if (Array.isArray(payload)) {
        state.pathData = payload;
      }
    },
    clearPaths: (state) => {
      state.pathData = [];
    },
    addInitVals: (state, { payload }) => {
      state.initValues = payload;
    },
    addValChains: (state, { payload }) => {
      state.valueChains = payload;
    },
    // addPeople: (state, { payload }) => {
    //   state.peopleVals = payload;
    // },
    // addAssets: (state, { payload }) => {
    //   state.allAssets = payload;
    // },
    addSelProgram: (state, { payload }) => {
      state.selectedProgram = payload;
    },
    addJsonData: (state, { payload }) => {
      state.jsonData = payload;
    },
    addSectionData: (state, { payload }) => {
      state.sectionData = payload;
    },
    getAllInit: (state, { payload }) => {
      state.allInitiative = payload;
    },

  },
});

export const {
  addPaths, addInitVals, addValChains, addPeople, addAssets, addSelProgram,
  addJsonData, addSectionData, clearPaths, getAllInit,
} = initSlice.actions;

export default initSlice.reducer;
