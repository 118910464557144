/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

function ReadMoreLess({ text }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      {!showMore && text && text?.length > 26 ? `${text.substring(0, 26)}...` : text }
      {text?.length > 26 && showMore && <Button className="text-decoration-none" variant="link" type="button" onClick={() => setShowMore(!showMore)}>less</Button>}
      {text?.length > 26 && !showMore && <Button className="text-decoration-none" variant="link" type="button" onClick={() => setShowMore(!showMore)}>more</Button>}
    </>
  );
}

export default ReadMoreLess;
